<script>
  import set from 'lodash/set';
  import get from 'lodash/get';
  import {Google} from 'root/shared/tools/google/googleProvider'
  import {onMount} from 'svelte';
  import InputText from './InputText.svelte';

  export let
    ids = {
      lat: 'location.coordinates.lat',
      lng: 'location.coordinates.lng'
    },
    values = {},
    errors = {};

  const
    defaultCoordinates = {lat: 37.2588406, lng: -104.6465283},
    zoomedInValue = 17,
    zoomedOutValue = 4;

  let
    mapElement,
    markerElement,
    map,
    marker,
    markerPosition,
    coordinates = defaultCoordinates,
    zoom = zoomedOutValue;

  $: updateMap(values);

  function updateMap(v = {}) {
    const lat = Number.parseFloat(get(v, ids.lat)),
      lng = Number.parseFloat(get(v, ids.lng));

    if (isFinite(lat) && isFinite(lng) && (lat !== coordinates.lat || lng !== coordinates.lng)) {
      coordinates = {lat, lng};
      if (map && marker) {
        map.panTo({...coordinates});
        if (markerPosition.lat !== lat || markerPosition.lng !== lng) {
          marker.setPosition({...coordinates});
          map.setZoom(zoomedInValue);
        }
      }
    }
  }

  onMount(async () => {
    const g = await Google();
    const lat = get(values, ids.lat);
    const lng = get(values, ids.lng);

    if ((lat || lat === '0') && (lng || lng === '0')) {
      coordinates = {lat, lng};
      zoom = zoomedInValue;
    }

    map = new g.maps.Map(mapElement, {
      center: {...coordinates},
      zoom,
      mapTypeId: 'terrain'
    });

    markerPosition = {...coordinates};
    marker = new g.maps.Marker({
      map,
      position: markerPosition,
      draggable: true,
    });

    marker.addListener('dragend', event => {
      markerPosition = event.latLng.toJSON();
      const v = {...values};
      set(v, ids.lat, markerPosition.lat);
      set(v, ids.lng, markerPosition.lng);
      values = v;
    })
  });
</script>

<div class="Map">
  <div class="InputMap" bind:this={mapElement}>
    <div bind:this={markerElement}></div>
  </div>

  <div class="CoordinatesHelp">Drag marker to the correct position</div>

  <div class="CoordinatesColumns">
    <InputText
      id="{ids.lat}"
      label="Latitude"
      asNumber="{true}"
      required="{true}"
      {values}
      {errors}
    />

    <InputText
      id="{ids.lng}"
      label="Longitude"
      asNumber="{true}"
      required="{true}"
      {values}
      {errors}
    />
  </div>
</div>
