import { writable } from 'svelte/store';

const prefix = 'rb.navigation.pin';

export const navStates = {};

export function getState(id){
  return id && localStorage.getItem(applyPrefix(id)) === 'true';
}

export function setState(id, pinned){
  if(!id) return;
  localStorage.setItem(applyPrefix(id), pinned);
}

function applyPrefix(id){
  return `${prefix}.${id}`;
}

export function createNavState(id) {
  if(!navStates[id]) {
    const {subscribe, set} = writable(getState(id));

    const store = {
      subscribe,
      setPinned(pinned) {
        setState(id, pinned);
        set(pinned);
      },
      reset() {
        set(false);
      }
    }
    navStates[id] = store;
  }
  return navStates[id];
}
