import get from 'lodash/get';
import {
  initialize as initializeEmailAddressVerification
} from 'root/public-auth/states/registration/email-address-verification/store/user';
import {EMAIL_ADDRESS_VERIFICATION_PAGE} from 'root/states';
import {$state} from 'root/angular-injector-provider';

export const PENDING_EMAIL_ADDRESS_VERIFICATION = 'PENDING_EMAIL_ADDRESS_VERIFICATION';

export function isEmailAddressVerificationPendingError(error){
  return get(error, 'data.message') === PENDING_EMAIL_ADDRESS_VERIFICATION;
}

export function toEmailAddressVerification(credentials, error){
  initializeEmailAddressVerification({emailAddress: credentials.email, token: get(error, 'data.details.token')}, 'SIGN_IN');
  $state().go(EMAIL_ADDRESS_VERIFICATION_PAGE);
}
