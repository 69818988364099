import {abstractState, svelteState} from 'root/shared/tools/router-helper';

import {
  PAGE__HOTEL_BID,
  PAGE__HOTEL_BID__DECLINE,
  PAGE__HOTEL_BID__RESPOND
} from 'root/states/index';

export default HotelBidStates;

function HotelBidStates($stateProvider){
  $stateProvider
    .state(PAGE__HOTEL_BID, abstractState('/hotel-bid'))

    .state(PAGE__HOTEL_BID__DECLINE,
      svelteState(
        '/decline?token',
        () => import(
          /* webpackChunkName: "TheHotelBidDeclinePage" */
          'root/public-bid/states/hotel/decline/TheHotelDeclineToBidPage.svelte')
      ))

    .state(PAGE__HOTEL_BID__RESPOND,
      svelteState(
        '/respond?token',
        () => import(
          /* webpackChunkName: "TheHotelBidRespondPage" */
          'root/public-bid/states/hotel/respond/TheHotelRespondToBidPage.svelte')
      ))

    .state('FIX_HotelBidRespond', // temporary fix to handle typo in already sent emails [2023-08-02]
      svelteState(
        '/hotels-bid/respond?token',
        () => import(
          /* webpackChunkName: "TheHotelBidRespondPage" */
          'root/public-bid/states/hotel/respond/TheHotelRespondToBidPage.svelte')
      ))

    .state('publicRateLoadedIntoGdsRequest',
      svelteState(
        '/public/bid-rate-loading-request/{bidId}?token',
        () => import(
          /* webpackChunkName: "TheRateLoadedIntoGdsRequestPage" */
          'root/public-bid/states/rate-loaded-into-gds-request/TheRateLoadedIntoGdsRequestPage.svelte')
      ))
}
