<script>
  import Navigation from 'root/components/organisms/SideNavigation/SideNavigation.svelte';
  import RevertReviseResponse from './RevertReviseResponse.svelte';
  import { $state as stateProvider } from 'root/angular-injector-provider.js';
  import { onDestroy } from 'svelte';
  import { createQuestionnaireNavigation  } from 'root/components/organisms/SideNavigation/MenuItemFactory.js';

  export let id = '';
  export let title = '';
  export let subtitle = '';
  let menu = createMenu();

  function createMenu(){
    const currentStateName = stateProvider().current.name;

    return [
      createQuestionnaireNavigation(currentStateName, currentStateName, reloadMenu, onDestroy),
    ];
  }


  function reloadMenu(){
    menu = [...menu];
  }
</script>

<Navigation {id} {title} {subtitle} {menu} >
  <div slot="after" let:maximized>
    {#if maximized}
      <RevertReviseResponse />
    {/if}
  </div>
</Navigation>
