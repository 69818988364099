import {Dialog as VueDialog, SvelteDialog} from 'root/v-app/rbServices';
import ProgressLoader from './AttachmentProgressLoader.notification.svelte';
import NotificationDialog from 'vRoot/_core/notifications/NotificationDialog.vue';
import noop from 'lodash/noop';

export default function showProgressAndHandleErrors(fn, title){
  const closeNotification = SvelteDialog.showNotification(ProgressLoader, {title: `${title}ing...`});
  fn()
    .then(() => closeNotification())
    .catch(() => {
      closeNotification();
      displayErrorNotification(title);
    });
}

function displayErrorNotification(title){
  VueDialog.show(NotificationDialog, {
    type: 'error',
    title: `${title} failed!`,
    subTitle: 'Error',
    message: 'There was an error, please try again.',
  }).catch(noop);
}

