import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
import noop from 'lodash/noop';

export default function CreateAcknowledgeRefusedCompetitiveBid(bidManagerService){
  return function AcknowledgeRefusedCompetitiveBid(report){
    return {
      label: 'Remove',
      icon: 'close',
      isAvailable: () => !report.bids.find(b => b.state.status !== 'COMPETITIVE_REFUSED'),
      action: () => {
        notificationUtils().onSave(() => mainAPI().hotelRfpCompetitiveBidAcknowledgeRefused(report.bids.map(b => b._id)))
          .then(actionReport => bidManagerService.handleActionResult( actionReport, report.bids))
          .catch(noop);
      }
    }
  }
}