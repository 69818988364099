/**
 * Created by DejanK on 11/21/2016.
 */
import User from './user.factory'
import UserAccount from './user-account.factory'

import { find } from 'lodash'
import {PAGE_TOKEN_USER_ACCOUNT_MANAGER} from 'root/states';

const CURRENT_USER_CHANGED_EVENT = 'rbCurrentUserChanged'

export { CurrentUserService as default, CURRENT_USER_CHANGED_EVENT }

CurrentUserService.$inject = ['AuthorizationStorageService', 'UserRepository', '$rootScope', '$q', '$state'];
function CurrentUserService(authorizationStorageService, userRepository, $rootScope, $q, $state) {
  const $service = this
  let refreshUserPromise = null
  const listeners = [];
  /**
   * !!! TODO: this will prevent user from having different curUsers on diff browsers !!!
   */
  // refreshCurrentUser();

  $service.isSignedIn = isSignedIn;
  $service.get = getCurrentUser;
  $service.listAllUserAccounts = userRepository.listAllUserAccounts;
  $service.activateUserAccount = userRepository.activateUserAccount;
  $service.refresh = refreshCurrentUser;
  $service.forget = forgetAuthData;
  $service.getActiveUserAccount = getActiveUserAccount;
  $service.getUserAccount = getUserAccount;
  $service.listUserAccounts = userRepository.listActiveCurrentUserUserAccounts
  $service.switchToAccount = switchToAccount
  $service.watchUser = watchUser
  $service.watch = watch

  $service.disableTutorial = userRepository.disableTutorial
  $service.enableTutorial = userRepository.enableTutorial
  $service.upateBasicUserInformation = updateBasicUserInformation
  $service.updateJobTitle= updateJobTitle
  $service.updatePassword= updateUserPassword
  $service.updateEmailAddress = updateUserEmailAddress
  $service.updateProfilePicture = updateProfilePicture;
  $service.hasFeature = hasFeature;

  function getActiveUserAccount(){
    return getCurrentUser()
      .then(user => user && user.currentUserAccount ? user.currentUserAccount : $q.reject());
  }

  function isSignedIn(){
    return !!authorizationStorageService.getToken();
  }

  function getCurrentUser(){
    const currentUser = authorizationStorageService.getUser();
    return (currentUser ? $q.resolve(User(currentUser)) : refreshCurrentUser());
  }

  function forgetAuthData(){
    authorizationStorageService.forget();
    broadcastCurrentUser(null);
  }

  function refreshCurrentUser(){
    if(!refreshUserPromise) {
      refreshUserPromise = userRepository.getCurrentUser()
        .then(saveUser)
        .then(User)
        .then(broadcastCurrentUser, handleError)
        .finally(() => { refreshUserPromise = null })
    }
    return refreshUserPromise;

    function saveUser(response){
      if(!response.data) return $q.reject(null);

      const currentUser = response.data;
      authorizationStorageService.saveUser(currentUser);
      return currentUser;
    }

    function handleError(error){
      forgetAuthData();

      // noinspection EqualityComparisonWithCoercionJS
      if(error.status == 409 && error.data.message === 'NO_USER_ACCOUNT') {
        $state.go(PAGE_TOKEN_USER_ACCOUNT_MANAGER, {token: error.data.details.token});
      }

      return $q.reject(null);
    }
  }

  function broadcastCurrentUser(user){
    $rootScope.$broadcast(CURRENT_USER_CHANGED_EVENT, user);
    listeners.forEach(l => l(user));
    return user;
  }

  function switchToAccount(accountId) {
    return userRepository.switchToAccount(accountId)
      .then(refreshCurrentUser)
  }

  function watchUser($scope, vm, userKey){
    registerScopeToListenToUserChanges()
    return getCurrentUser().then( user => { vm[userKey || 'user'] = user } )

    function registerScopeToListenToUserChanges(){
      $scope.$on(CURRENT_USER_CHANGED_EVENT, (event, user) => { vm[userKey || 'user'] = user })
    }
  }

  /* POSSIBLE MEMORY LEAK - NO WAY TO UNREGISTER (not needed for now) */
  function watch(listenerFn){
    listeners.push(listenerFn);
    getCurrentUser().then(user => listenerFn(user));
    return () => {
      listeners.splice(listeners.indexOf(listenerFn), 1);
    }
  }

  function getUserAccount(userAccountId){
    return listCurrentUserUserAccounts()
      .then((httpResponse)=>{
        const userAccount = find(httpResponse.data, {id: userAccountId});
        return userAccount ? userAccount : $q.reject();
      })
  }

  function listCurrentUserUserAccounts(){
    return userRepository.listActiveCurrentUserUserAccounts()
      .then((httpResponse)=>{
        for(let i=0, l = httpResponse.data.length; i<l; i++){
          httpResponse.data[i] = UserAccount(httpResponse.data[i]);
        }
        return httpResponse;
      })
  }

  function updateBasicUserInformation(data){
    return userRepository.updateBasicProfile(data)
      .then(refreshCurrentUser);
  }

  function updateJobTitle(data){
    return getActiveUserAccount()
      .then(ua => userRepository.updateJobTitle({userAccountId: ua.id, jobTitle: data.jobTitle}))
      .then(refreshCurrentUser);
  }

  function updateProfilePicture(data){
    const fd = new FormData();
    fd.append('file', data.file);
    return userRepository.updateProfilePicture(fd)
      .then(refreshCurrentUser);
  }

  function updateUserEmailAddress(data){
    return userRepository.updateUserEmailAddress(data)
      .then(refreshCurrentUser)
  }

  function updateUserPassword(data){
    return userRepository.updateUserPassword(data)
      .then(refreshCurrentUser)
  }

  function hasFeature(featureName){
    return getCurrentUser()
      .then(cu => cu.accountFeatures && cu.accountFeatures.indexOf(featureName) !== -1);
  }
}
