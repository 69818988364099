<div class="Component">
  <div class="Container">
    <div class="View" data-icon-after="{dropDownIcon}"
         on:click={() => menuOpened = true}>
      <div class="Text">
        <div class="Label">BID MANAGER VIEW:</div>
        <div class="Ellipsis">{selectedView.name}</div>
      </div>
    </div>

    {#if menuOpened}
      <div class="Backdrop" on:click={() => menuOpened = false}></div>

      <div class="Menu">
        {#each groupsForDisplay as group}
          <div class="MenuItem {group.selected ? 'Active': ''} {group.opened ? 'Opened': ''}"
               data-icon-after="{group.isView? '': (group.opened ? iconOpened : iconClosed)}"
               data-icon-before="{group.selected ? iconChecked : iconUnchecked}"
               on:click={() => !group.isView? group.opened = !group.opened: openBidManager(group.id)}>
            <span class="MenuItemText Ellipsis">{group.name}</span>
          </div>

          {#if group.opened && group.views.length > 0}
            <div class="SubMenu">
              {#each group.views as view}
                <div class="SubMenuItem Ellipsis {view.selected ? 'Active': ''}"
                     data-icon-before="{view.selected ? iconChecked : iconUnchecked}"
                     on:click={() => openBidManager(view.id)}>
                  {view.name}
                </div>
              {/each}
            </div>
          {/if}
        {/each}
      </div>
    {/if}
  </div>

  {#if selectedView.side === 'BUYER'}
    <div>
      {#if selectedView.type === 'RFP'}
        <button class="aBtn asLarge atDark CustomButton" data-icon="edit"
                on:click="{() => openInNew(HOTEL_RFP_SPECIFICATIONS, {rfpId: selectedView.rfpId})}">
          Edit RFP
        </button>
      {/if}

      {#if displayCreateRfp}
        <button class="aBtn asLarge atDark asMain CustomButton" data-icon="create_new_folder"
                on:click="{() => openInNew(PAGE_SELECT_RFP_TEMPLATE)}">
          Create RFP
        </button>
      {/if}
    </div>
  {/if}
</div>
<script>
  import { $state as stateProvider, currentUser as currentUserProvider  } from 'root/angular-injector-provider';
  import { onMount, onDestroy } from 'svelte';
  import { PAGE_SELECT_RFP_TEMPLATE, HOTEL_RFP_SPECIFICATIONS } from 'root/states';

  export let views, selectedView;

  const
    iconOpened = 'keyboard_arrow_up',
    iconClosed = 'keyboard_arrow_down',
    iconChecked = 'radio_button_checked',
    iconUnchecked = 'radio_button_unchecked';

  let
    dropDownIcon = iconClosed,
    menuOpened = false,
    groups = groupViews(),
    displayCreateRfp = false,
    unwatchUser,
    groupByLength,
    groupsForDisplay;

  $: dropDownIcon = menuOpened ? iconOpened : iconClosed;
  $: groupByLength = determineGroupByLength(selectedView.side, views);
  $: groupsForDisplay = groups.map(group => {
    if(group.views.length <= groupByLength) {
      return {
        ...group.views[0],
        isView: true,
        views: [],
        name: group.name
      };
    } else {
      return group;
    }
  });

  onMount(() => { unwatchUser = currentUserProvider().watch(u => {
    displayCreateRfp = u && u.currentUserAccount.account.type !== 'TRAVEL_AGENCY';
  })});
  onDestroy(() => { if(unwatchUser) unwatchUser() });

  function groupViews(){
    const groups = views.reduce((acc, view) => {
      if(view.rfpType !== 'HOTEL') return acc;

      let group = acc.find(g => g.id === view.accountId);
      if(!group){
        group = createGroup(view);
        acc.push(group);
      }

      const v = createView(view);
      group.views.push(v);
      group.isOwn = group.isOwn || v.type === 'ALL';
      group.selected = group.selected || v.selected;
      group.opened = group.selected;

      return acc;
    }, []);

    groups.forEach(g => g.views.sort((v1, v2) => v1.isAll === v2.isAll ? v1.name.localeCompare(v2.name) : (v1.isAll ? 1 : -1)));
    groups.sort((g1, g2) => g1.isOwn === g2.isOwn ? g1.name.localeCompare(g2.name) : (g1.isOwn ? 1 : -1));
    return groups;
  }

  function createGroup(view){
    return {
      id: view.accountId,
      name: view.accountName || '',
      views: [],
      isOwn: false,
      selected: false,
      opened: false,
    };
  }

  function createView(view){
    return {
      ...view,
      selected: view.id === selectedView.id,
      isAll: view.type === 'ALL' || view.type === 'MANAGED_COMPANY_VIEW'
    };
  }

  function openInNew(stateName, stateParams){
    stateProvider().inNew(stateName, stateParams);
  }

  function openBidManager(viewId){
    stateProvider().go('bidManager', {viewId});
  }

  function determineGroupByLength(side, viewList = []){
    if(side === 'SUPPLIER') return 1;
    return viewList.find(v => v.type === 'ALL') ? 2 : 1;
  }
</script>

<style lang="stylus">.Component {
  display: flex;
  position: relative;
}
.Container {
  margin-right: 10px;
}
.View {
  display: flex;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  height: 36px;
  min-width: 340px;
  max-width: 340px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  text-align: left;
  overflow: hidden;
  cursor: pointer;
}
.Text {
  flex: 1 1 100%;
  margin-left: 10px;
  overflow: hidden;
  padding-right: 30px;
  color: rgba(255,255,255,0.8);
}
.Label {
  font-size: 11px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(255,255,255,0.5);
}
.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.Menu {
  position: absolute;
  left: -10px;
  top: 38px;
  min-width: 340px;
  max-width: 400px;
  max-height: 500px;
  z-index: 101;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.5);
  overflow-y: auto;
  overflow-x: hidden;
  background: #37474f;
  border: 1px solid #16272b;
  border-radius: 5px;
  color: #90a4ae;
}
.MenuItem {
  height: 50px;
  font-size: 14px;
  line-height: 16px;
  transition: all 0ms linear, background 0.2s ease-in-out, color 0.2s ease-in-out;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}
.MenuItem.Active,
.MenuItem.Active:hover {
  background: #00a99d;
  color: #fff !important;
}
.MenuItem.Opened,
.MenuItem.Opened:hover {
  color: #fff !important;
}
.MenuItem:hover {
  background: #37474f;
  color: #fff !important;
}
.MenuItemText {
  flex: 1;
  padding-left: 10px;
}
.SubMenu {
  background: #263238;
}
.SubMenuItem {
  height: 42px;
  font-size: 13px;
  line-height: 42px;
  transition: all 0ms linear, background 0.2s ease-in-out, color 0.2s ease-in-out;
  width: 100%;
  padding: 0 10px 0 25px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}
.SubMenuItem.Active,
.SubMenuItem.Active:hover {
  color: #00b8ab;
}
.SubMenuItem:not(.Active):hover {
  color: #fff !important;
}
.SubMenuItem:before {
  padding-right: 10px;
}
.aBtn.CustomButton {
  border-radius: 2px;
  background: #313c42;
}
</style>
