/**
 * Created by DejanK on 7/6/2017.
 *
 */
import DynamicRate from 'rfp/bid-manager/main/core/negotiations/dynamic-rate.factory';
import Amenity from 'rfp/bid-manager/main/core/negotiations/amenity.factory';

export default NegotiationFactory;

NegotiationFactory.$inject = ['RateFactory', 'TaxesFactory', 'TcosFactory'];
function NegotiationFactory(RateFactory, TaxesFactory, TcosFactory){

  function Negotiation(negotiationData, negotiations){
    Object.assign(this, negotiationData);
    this.negotiations = negotiations;
    const values = this.values;

    loadRates(values.rates, this);
    loadAmenities(values.amenities, this);
    //loadAmenitiesTotal(values.totalAmenities, this, negotiations);

    values.analytics = {};
    loadTaxes(this);
    loadTcos(this);

    function loadRates(negotiationRates, negotiation) {
      const rateKeys = Object.keys(negotiationRates);
      for(let i=0, l=rateKeys.length; i<l; i++){
        const key = rateKeys[i], rate = negotiationRates[key];
        if(key === 'dyn') {
          negotiationRates[key] = new DynamicRate(rate, negotiation, negotiations);
        } else {
          negotiationRates[key] = RateFactory.create(key, rate, negotiation, negotiations);
        }
      }
    }

    function loadAmenities(negotiationAmenities, negotiation){
      const amenitiesInView = negotiations.getAmenities();

      for(let i=0,l=amenitiesInView.length; i<l; i++){
        const amenityId = amenitiesInView[i];
        negotiationAmenities[amenityId] = new Amenity(amenityId, negotiationAmenities[amenityId], negotiation, negotiations);
      }
    }

    function loadTaxes(negotiation){
      values.taxes.total = values.taxesTotal;
      values.analytics.taxes = TaxesFactory.create(values.taxes, negotiation, negotiations);
    }

    function loadTcos(negotiation){
      values.analytics.tcos = TcosFactory.create(values.totalCosts, negotiation, negotiations);
    }
  }

  Negotiation.prototype.getCompanyName = function(){
    return this.from.person.accountName;
  };

  Negotiation.prototype.getContactName = function(){
    return `${this.from.person.firstName} ${this.from.person.lastName}`
  };

  Negotiation.prototype.getFormattedDateTime = function(){
    return this.negotiations.dateTimeFormatter.format(this.at);
  };

  Negotiation.prototype.getRate = function(ratePrefix, season, roomType){
    let rateId = ratePrefix;
    if(arguments.length > 1){ rateId += `_s${season}_rt${roomType}` }
    return this.values.rates[rateId] || RateFactory.create(rateId, { type: 'UNAVAILABLE', valid: true, value: 0}, this, this.negotiations);
  };

  Negotiation.prototype.getPrimaryRateValue = function(){
    return this.getRate(this.negotiations.config.rates[0], 1, 1).value;
  };

  Negotiation.prototype.getAmenity = function(amenityName){
    return this.values.amenities[amenityName]
  };

  Negotiation.prototype.getTaxesSummary = function(){
    return this.values.analytics.taxes
  };

  Negotiation.prototype.getTcos = function(){
    return this.values.analytics.tcos
  };


  Negotiation.prototype.getAllValues = function(){
    const values = {}, groupNames = ['rates', 'amenities', 'analytics'];

    for(let i = 0, l = groupNames.length; i<l; i++){
      const groupName = groupNames[i], group = this.values[groupName],
        valueGroup = {}, keys = Object.keys(group);

      for(let j = 0, lj = keys.length; j<lj; j++){
        const key = keys[j], item = group[key];

        if (item && item.getValue){
          valueGroup[key] = item.getValue();
        }
      }

      values[groupName] = valueGroup;
    }

    return values;
  };

  return {
    create: (negotiationData, negotiations)=>{
      return new Negotiation(negotiationData, negotiations);
    }
  }
}
