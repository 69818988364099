/**
 * Created by DejanK on 10/12/2016
 * Forces form to show errors when user mouses over disabled element
 * Makes all form controls with errors dirty
 */



module.exports = rbShowFormErrors;

rbShowFormErrors.$inject = ['$timeout'];
function rbShowFormErrors($timeout) {
  return {
    restrict: 'A',
    require: '^form',
    link: function linkFn($scope, element, attributes, form){
      const divCatcher = wrapInDiv(element);
      showErrorsOnMouseOver(divCatcher, form);
      attributes.$observe('disabled', function(val){
        val ? divCatcher.css({'z-index': 100}) : divCatcher.css({'z-index': -10});
      });
    }
  };

  /**
   * Wraps (button) element in div to allow mouse event on disabled buttons
   * @param element button element to wrap
   * @returns {Object} div that masks button
   */
  function wrapInDiv(element){
    const divWrapper = angular.element('<div style="position: relative"></div>');
    const divCatcher = angular.element('<div style="position: absolute; top:0; bottom:0; left: 0; right:0; z-index: 100; cursor: not-allowed"></div>');
    element.replaceWith(divWrapper);
    divWrapper.append(divCatcher);
    divWrapper.append(element);
    return divCatcher;
  }

  function showErrorsOnMouseOver(element, form) {
    element.on('mouseover', ()=>{
      $timeout(setModelsWithErrorAsDirty(form));
    });
  }
}

function setModelsWithErrorAsDirty(form) {
  angular.forEach(form.$error, (errors)=>{
    for (let i = 0, l = errors.length; i < l; i++) {
      const model = errors[i];
      model && (isForm(model, form) ? setModelsWithErrorAsDirty(model) : model.$setDirty());
    }
  });

  function isForm (model, form){
    return model.__proto__ === form.__proto__;
  }
}
