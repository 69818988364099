<div class="Container">
  <div class="Title">Attachments:</div>

  {#each nullSafeAttachments as a}
    <div class="aBtn asLarge atLight AttachmentButton"
         on:click={() => download(a.id)}>
      <span>{a.name}</span>
      <button class="aBtn asSmall asIcon atLight RemoveAttachmentButton" data-icon="delete"
              on:click|preventDefault|stopPropagation={() => remove(a.id)}></button>
    </div>
  {/each}

  <button class="aBtn atLight asLarge asMain AddAttachmentButton" data-icon="add_circle_outline"
          on:click={() => fileUploadInput.click()}>Add Attachment</button>

  <input type="file"
         class="hidden"
         bind:this="{fileUploadInput}"
         on:change={e => upload(e.target.files)}
  />
</div>


<script>
  import uploadAttachment from 'root/rfp-hotel/attachments/upload-attachment.service';
  import downloadAttachment from 'root/rfp-hotel/attachments/download-attachment.service';
  import showProgressAndHandleErrors from './show-progress-and-handle-errors.js';

  export let
    target,
    targetId,
    addAttachmentFunction,
    removeAttachmentFunction,
    attachments = [];

  let fileUploadInput, nullSafeAttachments, progress = 0;

  $: nullSafeAttachments = attachments || [];

  function upload(files) {
    showProgressAndHandleErrors(
      () => uploadAttachment(files[0], addAttachmentFunction, target, targetId),
      'Upload'
    );
  }

  function download(attId){
    showProgressAndHandleErrors(() => downloadAttachment(attId), 'Download');
  }

  async function remove(attId){
    removeAttachmentFunction(attId)
  }
</script>

<style lang="stylus">.Container {
  border: 1px dashed #cfd8dc;
  border-left: 0;
  border-right: 0;
  padding: 12px;
}
.Title {
  font-weight: bold;
  font-size: 14px;
  color: #455a64;
  height: 20px;
  line-height: 20px;
}
.AttachmentButton {
  display: inline-flex;
  align-items: center;
  padding-right: 4px !important;
  margin: 5px 5px 0 0;
}
.AttachmentButton>span {
  text-decoration: underline;
}
.AttachmentButton>span:hover {
  text-decoration: none;
}
.RemoveAttachmentButton {
  margin-left: 8px;
  background: #e1e4e6 !important;
  border-color: #e1e4e6 !important;
  color: #78909c !important;
}
.RemoveAttachmentButton:hover {
  background: #cfd8dc !important;
  border-color: #cfd8dc !important;
  color: #546e7a !important;
}
.AddAttachmentButton {
  margin: 5px 5px 0 0;
}
</style>