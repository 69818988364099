<script>
  import get from 'lodash/get';
  import {numericInputFilter} from 'root/regex-library';

  export let
    id = 'id',
    label = 'Text',
    values = {},
    errors = {},
    required = null,
    maxLength = 100,
    asNumber = false;

  let inputElement, error, value;

  $: value = get(values, id, '');
  $: error = errors ? errors[id] : '';

  function toNumber(ev) {
    if(asNumber)
      ev.target.value = numericInputFilter(ev.target.value);
  }

</script>


<div class="Input">
  <input type="text"
         id="{id}"
         name="{id}"
         placeholder="{label}"
         value="{value}"
         autocomplete="off"
         required="{required}"
         maxlength="{maxLength}"
         on:input={toNumber}
         bind:this={inputElement}
  />
  <label>{label}</label>
  <div class="Error">{error || ''}</div>
</div>
