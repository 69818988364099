<script>
  export let noIcon = null,
    noLabel = "Cancel",
    noAction = () => {},
    yesIcon = "save",
    yesLabel = "Save",
    yesAction = () => {},
    disabled = false,
    atLight = true,
    yesAsMain = true,
    wide = false;
</script>

<div class="Buttons" class:Wide={wide}>
  <button
    class="aBtn asLarge"
    data-icon={noIcon}
    class:atLight
    class:atDark={!atLight}
    on:click={() => noAction()}>
    {noLabel}
  </button>

  <button
    class="aBtn asLarge"
    class:atLight
    class:atDark={!atLight}
    class:asMain={yesAsMain}
    data-icon={yesIcon}
    on:click={() => yesAction()}
    disabled={disabled ? 'disabled' : null}>
    {yesLabel}
  </button>
</div>

<style lang="stylus">.Buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 20px;
}
.Buttons.Wide {
  justify-content: space-between;
}
</style>
