<template>
  <div>

    <a href="#" class="rbvChainsFilterMasterChain" @click="toggleExpanded($event)" :class="masterClasses">
      <i class="material-icons" @click="toggleSelected($event)">{{checkboxIcon}}</i>
      <span class="rbvChainsFilterMasterChainLabel">{{chain.name}}</span>
      <i class="material-icons arrow">arrow_drop_down</i>
    </a>

    <transition name="tdMapBrandsShow">
      <div v-if="chain.expanded">
        <brand v-for="brand in chain.brands" :key="brand.id" :brand="brand" />
      </div>
    </transition>

  </div>
</template>

<script>
  import Brand from './Brand.vue';
  import {stopEvent} from "root/shared/tools/view-utils";

  const CHECKBOX_ICONS = ['check_box_outline_blank', 'check_box', 'indeterminate_check_box']

  export default {
    name: 'rbv-travel-destinations-map-filter-bar-master-chain',
    props: ['chain', 'listExpanded'],
    components: { Brand },
    computed: {
      checkboxState() {
        return this.chain.selected
          ? (this.chain.selected === this.chain.$brands.length ? 1 : 2)
          : 0
      },
      checkboxIcon () { return CHECKBOX_ICONS[this.checkboxState] },
      masterClasses () {
        return {
          expanded: this.chain.expanded,
          someExpanded: !this.chain.expanded && this.listExpanded,
          selected: this.checkboxState
        }
      }
    },
    methods: {
      toggleExpanded($event) {
        stopEvent($event)
        this.chain.expanded = !this.chain.expanded
      },
      toggleSelected($event) {
        stopEvent($event)
        const selectAll = this.checkboxState !== 1
        this.chain.$brands.forEach( b => { b.selected = selectAll })
        this.chain.selected = selectAll ? this.chain.$brands.length : 0
      }
    }
  }
</script>

<style lang="stylus">

  .rbvChainsFilterMasterChain {
    display: block
    box-sizing border-box
    height: 37px
    line-height: 34px;
    width 100%
    padding: 0 10px
    background: transparent
    white-space nowrap

    cursor: pointer;

    color: #78909C !important
    border-bottom: 1px solid #ECEFF1;
    border-top: 1px solid #ECEFF1;
    margin-bottom -1px

    font-size 13px
    text-decoration none !important
    transition all .2s linear

    .material-icons {
      vertical-align middle
    }

    &.expanded, &.selected.expanded {
      background #90A4AE
      color white !important
      position sticky
      top: 0

      .material-icons.arrow {
        transform rotateX(180deg)
      }
    }

    &.someExpanded {
      background #ECEFF1
      border-color: #D7DEE2
    }

    &.selected {
      color #455A64 !important
    }
  }

  .rbvChainsFilterMasterChainLabel {
    display inline-block
    vertical-align middle
    width calc(100% - 50px)
  }

  .tdMapBrandsShow-enter-active {
    transition: all .3s ease;
  }

  .tdMapBrandsShow-leave-active {
    max-height 200px
    transition: all .2s ease
  }

  .tdMapBrandsShow-enter {
    transform: translateX(10px);
    opacity: 0;
  }

  .tdMapBrandsShow-leave-to {
    max-height 0
    transform: translateY(-10px);
    overflow hidden
  }
</style>
