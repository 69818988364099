<script>
  import NotificationDialogTemplate from 'vRoot/_core/templates/NotificationDialogTemplate.vue';
  import VButton from 'vRoot/_core/buttons/Button.vue';
  import VCheckbox from 'vRoot/_core/rbv-input/RbCheckbox.vue';
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'


  export default {

    name: 'FinalAgreementEmailNotificationTutorialDialog',


    components: { NotificationDialogTemplate, VButton, VCheckbox },


    mixins: [ DialogMixin ],

    props: {
      user: {
        type: String,
        default: ''
      },

      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        dontShowThisAgain: false
      }
    },

    computed: {
      subtitle () {
        return `Dear ${this.user},`;
      }
    },

    mounted () {
      this.dontShowThisAgain = this.disabled;
    },

    methods: {
      onClose () {
        this.hide(this.dontShowThisAgain)
      }
    }
  }
</script>


<template>
  <notification-dialog-template
   :class="$style.dialog"
   icon="exclamation"
   @cancel="cancel()"
  >
    <template slot="subTitle">
      {{ subtitle }}
    </template>

    <template slot="title">
      Please Read!
    </template>

    <template slot="message">
      <p :class="$style.text">Email notifications for new final agreements are sent once a day as an aggregated, single report to your Chain Partner.</p>
      <p :class="$style.text">Keep in mind, direct-to-property final agreement email notifications are sent immediately to hotel representatives.</p>
      <p :class="$style.text">In either case their Bid Managers will reflect final agreement status immediately.</p>
    </template>

    <template slot="footer">
      <div :class="$style.footer">
        <div v-if="disabled"></div>

        <div
         v-else
         :class="$style.checkbox"
        >
          <v-checkbox
           :checked="dontShowThisAgain"
           @change="e => dontShowThisAgain = e"
          /> Don't show this again
        </div>

        <div>
          <v-button
           type="main"
           @click="onClose()"
          >
            OK
          </v-button>
        </div>
      </div>
    </template>
  </notification-dialog-template>
</template>


<style lang="stylus" module>

  .dialog {
    width: 550px;
  }

  .text {
    font-size : 13px;
    color : #546E7A;
    line-height: 16px;
    text-align: left;
    margin-bottom:16px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkbox {
    display: flex;
    font-size: 13px;
    color: #546E7A;
    align-items: center;
  }

</style>
