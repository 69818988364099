import {$state} from 'root/angular-injector-provider';
import {HOTEL_RFP_COMPETITIVE_BIDS_RESPONSE} from 'root/states';


export default function CreateEditCompetitiveBidResponse(){
  return function EditCompetitiveBidResponse(report){
    return {
      label: 'Edit Response',
      icon: 'edit',
      action: () => {
        $state().inNew(HOTEL_RFP_COMPETITIVE_BIDS_RESPONSE, {bidId: report.bids[0]._id})
      }
    }
  }
}