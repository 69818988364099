<template>
  <div>
    <VirtualListTiny
      v-if="showList"
      :height="height"
      :width="'auto'"
      :item-count="itemsHeights.length"
      :item-size="itemsHeights"
      :scroll-to-index="scrollToIndex"
      :overscan-count="10"
      :class="$style.list"
    >
      <template v-slot:header>
        <slot />
        <div
          v-if="!previewMode && !filtered"
          :class="$style.toggler"
        >
          <bulk-check-box
            :ids="allIds"
            :view="view"
            :updating-list="updatingList"
            @toggle="$emit('toggle-bulk', allIds)"
          />
          Include/Exclude All Hotels
        </div>
      </template>

      <template v-slot="{index}">
        <ListItem
          :index="index"
          :item="hotelsList[index]"
          :preview-mode="previewMode"
          :group-by="groupBy"
          :view="view"
          :updating-list="updatingList"

          @toggle="$emit('toggle', $event)"
          @toggle-bulk="$emit('toggle-bulk', $event)"
          @resized="handleResizedElement"
        />
      </template>
    </VirtualListTiny>
  </div>
</template>
<script>
import sampleHotels from './samples';
import BulkCheckBox from './BulkCheckbox.vue';
import VirtualListTiny from 'root/components/molecules/VirtualListTiny/VirtualListTiny.vue';
import Vue from 'vue';
import {ALIGNMENT} from 'root/components/molecules/VirtualListTiny/constants';
import ListItem from './hotels-list-fs-row.vue'

export default {
  name: 'HotelDirectoryHotelList',

  components: {ListItem, BulkCheckBox, VirtualListTiny},

  props: {
    hotels: {
      type: Array,
      required: true
    },
    view: {
      type: Object,
      required: true
    },
    groupBy: {
      type: String,
      required: true
    },
    previewMode: Boolean,
    updatingList: {
      type: Array,
      required: true
    },
    filtered: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      scrollToIndex: 0,
      height: 100,
      itemsHeights: this.calculateItemsHeights(this.hotelsList),
      showList: false,
    }
  },

  computed: {
    ALIGNMENT() {
      return ALIGNMENT
    },
    hotelsList() {
      return this.hotels && this.hotels.length ? this.hotels : sampleHotels;
    },
    allIds() {
      const bidIds = this.hotels ? this.hotels.reduce((acc, {ids}) => ids ? ([...acc, ...ids]) : acc, []) : [];
      return this.hotels.length > 0 && bidIds.length === 0 ? this.hotels.map(({bidId}) => bidId) : bidIds;
    },
  },

  watch: {
    hotelsList(newValue) {
      this.scrollToIndex = 0;
      this.itemsHeights = this.calculateItemsHeights(newValue);
      this.redrawList()
    }
  },

  mounted() {
    const vm = this;
    this.observer = new ResizeObserver(obs => {
      Vue.set(vm, 'height', obs[0].contentRect.height);
    });

    this.observer.observe(this.$el);
  },

  beforeDestroy() {
    if(this.observer){
      this.observer.disconnect();
      this.observer = null;
    }
  },

  methods: {
    calculateItemsHeights(hotelsList){
      return (hotelsList || []).map(i => i.type === 'group' ? 30 : 100);
    },

    handleResizedElement(ev){
      const MARGIN = 6;
      const
        oldH = this.itemsHeights[ev.index],
        newH = ev.height + MARGIN;

      if (Math.abs(oldH - newH) > 1) {
        this.scrollToIndex = ev.index;
        Vue.set(this.itemsHeights, ev.index, newH);
      }
    },

    redrawList(){
      this.showList = false;
      Vue.nextTick().then(() => {
        this.showList = true;
      });
    }
  }
};
</script>

<style lang="stylus" module>

.list {
  padding: 0 50px;
}

.grouper {
  padding: 0 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  box-sizing: border-box;
  height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size 14px;
}

.editModeGrouper {
  composes grouper;
  padding-left 1px;
}

.grouper > div {
  height: 20px;
  overflow: hidden;
}

.toggler {
  composes editModeGrouper;
  width: fit-content;
  margin 5px 0;
}
</style>
