import {checkAndExecute, checkAndExecuteToActionReport} from 'root/api/helpers';

export default function HotelBidAPI(api) {
  api.hotelBid = {

    getRespondToBidFormData: token => checkAndExecute(
      () => token,
      () => api.server.get('hotel-bid/respond', {token})
    ),

    saveDraftResponse: (token, response, person) => checkAndExecuteToActionReport(
      () => token && response && person,
      () => api.server.post('hotel-bid/respond', {response, person, asDraft: true}, {token})
    ),

    saveResponse: (token, response, person) => checkAndExecuteToActionReport(
      () => token && response && person,
      () => api.server.post('hotel-bid/respond', {response, person, ignoreErrors: true}, {token}) // TODO ignoreErrors
    ),

    checkPersonalDetails: (token, personalDetails) => checkAndExecute(
      () => token && personalDetails,
      () => api.server.post('hotel-bid/person-details', personalDetails, {token})
    ),

    signUp: (token, registrationDetails) => checkAndExecute(
      () => token && registrationDetails,
      () => api.server.post('hotel-bid/sign-up', registrationDetails, {token})
    ),
  };
}
