import {get, writable} from 'svelte/store';

const store = writable(null, () => () => store.set(null)),
  {subscribe, set, update} = store;

export default {
  subscribe,

  initialize (questionnaire, onRevert) {
    set({
      questionnaire,
      onRevert,
      changes: [],
      changesCount: 0,
    });
  },

  processResponseChanges (answers){
    update($s => {
      if($s === null) return $s;

      const changes = listChangedAnswersIds($s.questionnaire.response.answers, answers);
      return {
        ...$s,
        changes,
        changesCount: changes.length,
      };
    });

    function listChangedAnswersIds(initialAnswers, newAnswers){
      const ids = new Set(Object.keys(initialAnswers));
      const compareFn = getCompareAnswersFn(initialAnswers, newAnswers);
      Object.keys(newAnswers).forEach(naId => ids.add(naId));
      return [...ids].filter(compareFn);
    }
  },

  hasChanged(id){
    const $s = get(store);
    return $s.changes.includes(id);
  },

  revertChanges () {
    const $s = get({subscribe});
    if($s) $s.onRevert($s.questionnaire)
  }
}

export function getCompareAnswersFn(originalAnswers, newAnswers){
  return id => {
    const na = newAnswers[id];
    const oa = originalAnswers[id];
    return (na == null ? '' : na) !== (oa == null ? '' : oa);
  }
}
