<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header">
      <div>Create Company</div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody"
    >
      <rb-input
        :for-id="'accountId'"
        :error="$showError('name')"
        :class="$style.searchInputContainer"
      >
        <span slot="label">Enter Company Name</span>
        <input
          id="name"
          v-model="name"
          v-validate="'required'"
          class="rbInput"
          :class="$style.searchInput"
          name="name"
          data-vv-as="Company Name"
          autocomplete="off"
          autofocus
          @input="debounceSearch"
        >
        <i
          :class="$style.searchIcon"
          class="material-icons"
        >
          search
        </i>
      </rb-input>

      <div>
        <label
          v-if="companies.length > 0"
          class="rbInputLabel"
          :class="$style.companiesLabel"
        >
          <span>Select your company from the list below</span>
          <span><b>{{ resultCount }}</b> results found</span>
        </label>
        <div
          v-if="companies.length > 0"
          :class="$style.companies"
        >
          <div
            v-for="(company, index) in companies"
            :key="company.id+index"
            :class="{[$style.company]: true, [$style.selected]: data.companyId === company.id}"
            @click="toggle(company.id)"
          >
            <i class="material-icons">business</i>
            <div>
              <div>{{ company.name }}</div>
              <span>{{company.fullAddress}}</span>
            </div>
            <i class="material-icons">keyboard_arrow_right</i>
          </div>

          <div
            v-if="companies.length > 0 && companies.length < resultCount"
            :class="$style.loadMore"
          >
            <button
              class="rbButton mainButton"
              type="button"
              @click="loadMore"
            >
              Load More
            </button>
          </div>
        </div>

        <div
          v-if="loading"
          class="layout-column flex-center"
        >
          <rbv-loading label="Searching for your company..." />
        </div>

        <div
          v-if="name && !selectedCompany"
          :class="$style.message"
        >
          <div :class="$style.title">
            Can't find what your are looking for?
          </div>
          <span>
            Please check your spelling and change or remove some of the keywords.
            You can search by name, address, city, brand or chain. If you are still unable to find it,
            you can add it by clicking on the button below.
          </span>
        </div>
      </div>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button
        v-if="selectedCompany"
        class="rbButton mainButton"
        :disabled="!name"
        @click="action = 'existing'"
      >
        <i class="material-icons">save</i>
        Continue
      </button>
      <button
        v-if="name"
        class="rbButton"
        :class="selectedCompany? $style.left: ''"
        @click="action = 'new'"
      >
        <i class="material-icons">create_new_folder</i>
        Create New Company
      </button>
    </div>
  </modal-form>
</template>

<script>
  import {debounce} from 'lodash';
  import ModalForm from '../../../../_core/RbModalForm.vue'
  import DialogMixin from '../../../../_mixins/DialogMixin.vue'
  import RbFormMixin from '../../../../_mixins/RbFormMixin.vue'
  import RbInput from '../../../../_core/RbInput.vue'
  import accountManagerService from '../services/manage-company.service';
  import RbvLoading from '../../../../_core/RbvLoading.vue';

  export default {
    name: 'CreateCompanyDialog',
    components: { ModalForm, RbInput, RbvLoading },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      accounts: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        action: 'existing',
        name: '',
        data: {
          companyId: null
        },
        loading: false,
        currentPage: 1,
        companies: [],
        resultCount: 1
      }
    },
    computed: {
      selectedCompany() {
       return this.companies.find(company => company.id === this.data.companyId)
      }
    },
    methods: {
      onSubmit(){
        return { action: this.action, data: {...this.selectedCompany, searchTerm: this.name} };
      },
      debounceSearch: debounce(function () {
        //eslint-disable-next-line
        this.searchCompanies();
      }, 500),
      searchCompanies(page = 1) {
        if(page === 1){
          this.companies = [];
          this.resultCount = 0;
        }

        this.loading = true;

        return accountManagerService.searchCompany(this.name, page).then(({count, data}) => {
          this.currentPage = page;
          const companies = data.filter(entity => !this.accounts.find(company => company.entityId === entity.id));
          this.companies = [...this.companies, ...companies];
          this.resultCount = count;
          this.loading = false;
        });
      },
      toggle(id) {
        if(this.data.companyId === id){
          this.data.companyId = null
        }else{
          this.data.companyId = id;
        }
      },
      loadMore() {
        this.searchCompanies(this.currentPage+1);
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 500px;
    overflow visible
  }

  .companies{
    color: #7f909c;
    border: 1px solid;
    max-height 270px;
    overflow auto;
  }

  :global .vModalDefaultBody{
    margin-bottom 15px;
  }

  .company{
    display flex;
    align-items center;
    justify-content flex-start;
    padding 10px 5px;
    cursor pointer;
    border-bottom 1px solid rgba(0,0,0,0.2);
    height 48px;
    box-sizing border-box;
  }

  .company > div{
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex 1;
    line-height 1;
  }

  .company > div > *{
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size 12px;
    color: #546E7A;
  }

  .company > div > div{
    font-size 14px;
    color #546E7A;
  }

  .company:hover, .company.selected{
    background rgb(255, 251, 223);
  }

  .company i{
    margin-right 10px;
  }

  .company.selected i{
    font-weight bold;
  }

  .message {
    text-align center;
    margin: 10px 0;
    color #3d4d55;
  }

  .message .title{
    font-size 14px;
  }

  .message span{
    display block;
    font-size 12px;
  }

  .companiesLabel{
    display: flex;
    font-size: 12px;
    justify-content: space-between;
  }

  .loadMore{
    padding: 5px;
    text-align: center;
  }

  .searchInputContainer{
    position relative;
  }

  .searchIcon{
    position: absolute;
    top: 25px;
    right: 0;
    color: #3d4d55;
  }

  .searchInput{
    padding-right 25px;
  }

  .searchInput:focus{
    border-color: #09c1b2;
  }

  .searchInput:focus + .searchIcon{
    color #09c1b2;
  }

  .left{
    float left;
    margin-left 0 !important;
  }
</style>
