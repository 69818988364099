import { checkAndExecute } from 'root/api/helpers';

export default function ExchangeRatesApi(api) {

  Object.assign(api, {
    getExchangeRatesForCurrency: base => checkAndExecute(
      () => base,
      () => api.server.get(`exchange-rates/${base}`)
    ),
  });
}
