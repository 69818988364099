/**
 * Created by DejanK on 3/17/2017.
 */
import './rb-input/rb-input.scss'
import angular from 'angular'
import Servers from '../../shared-ng/servers'
import rbInputPassword from './rb-input/password/rb-input-password.cmp'
import rbInputCaptcha from './rb-input/captcha/captcha.cmp'
import rbAddressAutocomplete from './rb-input/rb-address-autocomplete/rb-address-autocomplete.cmp'
import rbInputAddressWithCoordinates from './rb-input/address-with-coordinates/rb-input-address-with-coordinates.cmp'
import rbInputAddress from './rb-input/address/rb-input-address.cmp'
import rbInputPhoneText from './rb-input/phone-text/rb-input-phone-text.cmp'
import rbInputText20 from './rb-input/text-20/rb-text-input-20.cmp'
import rbInputText50 from './rb-input/text-50/rb-text-input-50.cmp'
import rbInputText100 from './rb-input/text-100/rb-text-input-100.cmp'
import rbInputText250 from './rb-input/text-250/rb-text-input-250.cmp'
import rbConfirmAuthorization from './rb-input/confirm-authorization/rb-confirm-authorization.cmp'
import RbInputTemplateService from './rb-input/rb-input-template.service'
import rbInputCountry from './rb-input/country/rb-input-country.cmp'
import rbInputState from './rb-input/state/rb-input-state.cmp'

import inputDate from './rb-input/date/input-date.cmp.js';
import rbInputDate from './rb-input/date/rb-input-date.cmp.js';
import EmailAddressDeliverableDir
  from './rb-input/email-address-deliverable/email-address-deliverable.dir.js';

export default angular.module('rb.ui-form.components', [
  Servers
])
  .component('rbInputEmailAddress', require('./rb-input/email-address/rb-input-email-address.cmp'))
  .component('rbInputPassword', rbInputPassword)
  .service('RbInputTemplate', RbInputTemplateService)
  .component('rbInputInteger', require('./rb-input/integer/rb-input-integer.cmp'))
  .component('rbInputDouble', require('./rb-input/double/rb-input-double.cmp'))
  .component('rbInputText20', rbInputText20)
  .component('rbInputText50', rbInputText50)
  .component('rbInputText100', rbInputText100)
  .component('rbInputText250', rbInputText250)
  .component('rbInputState', rbInputState)
  .component('rbInputCountry', rbInputCountry)
  .component('rbInputCounty', require('./rb-input/county/rb-input-county.cmp'))
  .component('rbInputCaptcha', rbInputCaptcha)
  .component('rbInputAddress', rbInputAddress)
  .component('rbInputAddressWithCoordinates', rbInputAddressWithCoordinates)
  .component('inputDate', inputDate)
  .component('rbInputDate', rbInputDate)
  .directive('rbInputRepeatPassword', require('./rb-input/repeat-password/rb-input-repeat-password.cmp'))
  .component('rbInputPhoneText', rbInputPhoneText)

  .component('rbSelect', require('./rb-select/rb-select.cmp'))
  .component('rbOption', require('./rb-select/rb-option.cmp'))
  .service('rbSelectMenuPositioningService', require('./rb-select/rb-select-menu-positioning.service'))

  .component('rbAddressAutocomplete', rbAddressAutocomplete)

  .component('rbTextarea', require('./rb-textarea/rb-textarea.cmp'))
  .component('rbConfirmAuthorization', rbConfirmAuthorization)
  .directive('rbDeliverable', EmailAddressDeliverableDir)
