/**
 * Created by DejanK on 10/8/2016.
 *
 * should have placeholder option
 */

'use strict';

require("./rb-select.scss");

let lodash = require("lodash");

let template =`<a href="#" tabindex="0" ng-click="$ctrl.onClick($event)" ng-keydown="$ctrl.onKey($event)" ng-bind-html="$ctrl.html" ng-blur="$ctrl.onBlur()"></a>
<div class="rb-hidden rb-select-overlay" ng-click="$ctrl.onOverlayClick()">
  <div class="rb-select-menu" ng-transclude></div>
</div>`;

module.exports = {
  template: template,
  transclude: true,
  require: {
    ngModelController: "ngModel"
  },
  bindings: {
    rbReadonly: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$sce', '$element', '$scope', '$document', 'rbSelectMenuPositioningService'];
function Ctrl($sce, $element, $scope, $document, rbSelectMenuPositioningService){
  var vm = this, elementClasses, modelValue, options = [], selectedOption, focusedOption = null, singleCharRegexp = new RegExp('^[a-zA-Z0-9]$'),
    menuOverlayElement, menuContainerElement, isMenuShown = false, searchQuery = '',
    debouncedUpdateView = lodash.debounce(updateView, 5),
    debouncedSearch = lodash.debounce(search, 300);

  vm.registerOption = registerOption;
  vm.deregisterOption = deregisterOption;

  vm.$onInit = onInit;
  vm.$doCheck = doCheck;
  vm.$onDestroy = () => { menuOverlayElement && menuOverlayElement.remove() };
  vm.onClick = ($event)=>{stop($event); showMenu();};
  vm.onKey = onKey;
  vm.onSelect = onSelect;
  vm.onOverlayClick = close;
  vm.onBlur = setTouched;
  $scope.$on('RbWindowResized', () => {
    menuContainerElement && rbSelectMenuPositioningService.setMenuPosition(menuContainerElement, $element, menuOverlayElement);
  });

  function onInit(){
    elementClasses = getElementClasses($element.attr('class')).join(' ');

    function getElementClasses(classesString){
      let rawClasses = classesString.split(' '), classes = [],
        classesCount = rawClasses.length, i=0, startsWith = lodash.startsWith;
      while(i<classesCount){
        let c = rawClasses[i];
        if(!startsWith(c, 'ng-')){
          classes.push(c);
        }
        i++;
      }
      return classes;
    }
  }

  function close(){
    isMenuShown && menuOverlayElement.addClass('rb-hidden');
    isMenuShown = false;
    $element.append(menuOverlayElement)
  }

  function doCheck(){
    if(modelValue !== vm.ngModelController.$viewValue) {
      modelValue = vm.ngModelController.$viewValue;
      updateView();
    }
  }

  function updateView(){
    selectedOption = null;
    for(let i=0, l=options.length; i<l; i++){
      let option=options[i];
      if(!selectedOption && option.value === modelValue) {
        selectedOption = option;
        vm.html = $sce.trustAsHtml(option.select());
        vm.isMenuOpened = false;
      } else {
        option.deselect();
      }
      option.removeFocus();
    }
    $scope.$applyAsync();
  }

  function showMenu(){
    if(vm.rbReadonly) return;

    menuOverlayElement || createMenuOverlayElement();
    showMenuOverlayElement();

    function createMenuOverlayElement(){
      let parentWidth = $element && lodash.get($element[0], 'clientWidth');
      menuOverlayElement = angular.element($element.children()[1]);
      menuContainerElement = angular.element(menuOverlayElement.children()[0]);
      menuContainerElement.addClass(elementClasses ? elementClasses : '');

      parentWidth && menuContainerElement.css('min-width', parentWidth-1 + 'px');
      $document.find('body').eq(0).append(menuOverlayElement);
    }

    function showMenuOverlayElement(){
      menuContainerElement.css({top: '', left: '', right: '', bottom: ''});
      menuOverlayElement.removeClass('rb-hidden');
      rbSelectMenuPositioningService.setMenuPosition(menuContainerElement, $element, menuOverlayElement);
      selectedOption && selectedOption.setFocus();
      focusedOption = selectedOption || options[0];
      focusOnOption(focusedOption);
      isMenuShown = true;
    }
  }

  function onSelect(selectedValue){
    vm.ngModelController.$setViewValue(selectedValue);
    close();
  }

  function onKey($event){
    if(vm.rbReadonly || $event.altKey || $event.ctrlKey) return;
    if(isMenuShown){
      stop($event);
      switch ($event.key){ // I can catch arrow clicks when menu is shown here
        case 'ArrowUp':
        case 'Up':
          focusOptionByIndex(options.indexOf(focusedOption) -1);
          return;
        case 'ArrowDown':
        case 'Down':
          focusOptionByIndex(options.indexOf(focusedOption) +1);
          return;
        case 'PageUp':
          focusOptionByIndex(options.indexOf(focusedOption) -6);
          return;
        case 'PageDown':
          focusOptionByIndex(options.indexOf(focusedOption) +6);
          return;
        case 'Home':
          focusOnOption(options[0]);
          return;
        case 'End':
          focusOnOption(options[options.length-1]);
          return;
        case 'Escape':
        case 'Esc':
          close();
          return;
        case 'Enter':
        case ' ':
        case 'Spacebar':
          vm.onSelect(focusedOption.value);
          return;
      }
    } else {
      if($event.key === 'ArrowDown' || $event.key === 'Down'){
        stop($event);
        showMenu()
      }
    }

    if(singleCharRegexp.test($event.key)){
      searchQuery += $event.key;
      debouncedSearch();
    }

    function focusOptionByIndex(index){
      focusOnOption(options[index < 0 ? 0 : index >= options.length ? options.length-1 : index])
    }
  }

  function stop($event){
    $event.stopPropagation();
    $event.preventDefault();
  }

  function focusOnOption(option){
    focusedOption.removeFocus();
    focusedOption = option;
    focusedOption.setFocus();
  }

  function search(){
    searchQuery = searchQuery.toLowerCase();
    let foundOption = lodash.find(options, (o)=>{
      return lodash.startsWith(o.getText().toLowerCase(), searchQuery)
    });
    searchQuery = '';
    foundOption && (isMenuShown ? focusOnOption(foundOption) : onSelect(foundOption.value));
  }

  function registerOption(option){
    options.push(option);
    debouncedUpdateView();
  }

  function deregisterOption(option){
    let index = options.indexOf(option);
    index !== -1 && options.splice(index, 1);
  }

  function setTouched(){
    vm.ngModelController.$setTouched();
  }
}
