/**
 * Created by DejanK on 3/24/2017.
 */
import { merge, get } from 'lodash'
import UserAccount from './user-account.factory'

export default (userData) => { return new User(userData) }

function User(data){
  this.tutorials = []
  merge(this, data && data.data ? data.data : data); // if data is actually http response, it will have data object

  if(this.currentUserAccount){
    this.currentUserAccount = UserAccount(this.currentUserAccount);
  }
}

User.prototype.getName = function(){
  return `${this.firstName} ${this.lastName}`;
};

User.prototype.isBuyer = function(rfpType){
  return this.currentUserAccount && this.currentUserAccount.isBuyer(rfpType);
};

User.prototype.hasEmailAddress = function hasEmailAddress(emailAddress){
  return emailAddress && this.emailAddress && this.emailAddress.toUpperCase() === emailAddress.toUpperCase()
};

User.prototype.isCurrentAccountId = function isCurrentAccountId(accountId){
  const currentAccountId = get(this, 'currentUserAccount.account.id');
  return currentAccountId === accountId;
};

User.prototype.getAccount = function getAccount(){
  return this.currentUserAccount ? this.currentUserAccount.getAccount() : undefined
};

User.prototype.getAccountId = function getAccountId(){
  return this.currentUserAccount ? this.currentUserAccount.getAccountId() : undefined
};

User.prototype.getAccountType = function getAccountType(){
  return this.currentUserAccount ? this.currentUserAccount.getAccountType() : undefined
};

User.prototype.isAccountType = function isAccountType(type){
  const accType = this.getAccountType()
  return type === accType
};

User.prototype.getProfilePictureHtml = function getProfilePictureHtml(){
  return `<div class="user-profile-picture"><i class="material-icons">person</i></div>`
}

User.prototype.getPowerUser = function isPowerUser() {
  return this.powerUser;
}

User.prototype.isPowerUser = function isPowerUser() {
  return !!this.powerUser;
}

User.prototype.isOfType = function (...types){
  return this.currentUserAccount ? this.currentUserAccount.isOfType(...types) : false
};


User.prototype.hasPermission = function (feature, ...accountTypes){
  if(accountTypes && accountTypes.length > 0 && !this.isOfType(...accountTypes)) {
    return true;
  }

  return this.featuresPermissions && this.featuresPermissions[feature];
};
