/**
 * Created by DejanK on 9/1/2016.
 */
import angular from 'angular'
import ui from '../../core/ui'


import BidManagerMain from './main/rfp.bid-manager.main.module'

export default angular.module('rb.rfp.bidManager', [
  ui,
  BidManagerMain.name
])
