import HTML from './rb-responded-nag.html';
import '../nag.scss';
import Ctrl from '../nag.ctrl';


export default {
  bindings: {
    rbClick: '&',
    supplier: '<'
  },
  template: HTML,
  controller: Ctrl
}