import noop from 'lodash/noop';
import {SvelteDialog} from 'root/v-app/rbServices';
import DeclineCompetitiveBidDialog
  from 'root/rfp-hotel/competitive-bids/decline-to-bid/HotelRfpCompetitiveBidDeclineToBidDialog.svelte';

export default function CreateDeclineCompetitiveBid(bidManagerService){
  return function DeclineCompetitiveBid(report){
    return {
      label: 'Decline to Bid',
      icon: 'delete',
      action: () => {
        SvelteDialog.show(DeclineCompetitiveBidDialog, {bid: report.bids[0]})
          .then(actionReport => bidManagerService.handleActionResult( actionReport, report.bids))
          .catch(noop)
      }
    }
  }
}