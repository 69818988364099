<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import {URL_AUTOPLAY} from './VideoUrl';

  export let cancel;
</script>

<ClosableModal close="{cancel}" width="{720}">
  <iframe id="ytplayer" type="text/html" width="720" height="405" title="Competitive Bids"
          src="{URL_AUTOPLAY}"
          frameborder="0"></iframe>
</ClosableModal>
