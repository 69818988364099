/**
 * Created by DejanK on 7/6/2017.
 *
 */

const amenitiesConfig = {
  wf: { name: 'WiFi', abbr: 'WF', cat: 'FIXED', defaultType: 'FIXED'},
  bf: { name: 'Breakfast', abbr: 'BF', cat: 'MOCKED', defaultType: 'FIXED'},
  prk: { name: 'Parking', abbr: 'PRK', cat: 'FIXED', defaultType: 'FIXED'},
  ft: { name: 'Fitness', abbr: 'FT', cat: 'FIXED', defaultType: 'FIXED'},
  ec: { name: 'Early Checkout', abbr: 'EC', cat: 'MIXED', defaultType: 'FIXED'},
  as: { name: 'Airport Shuttle', abbr: 'AS', cat: 'FIXED', defaultType: 'FIXED'},
  ia: { name: 'High Speed Internet Access', abbr: 'IA', cat: 'FIXED', defaultType: 'FIXED'},
  cp: { name: 'Cancellation Policy', abbr: 'CP', cat: 'TEXT', defaultType: 'TEXT'},
  cm: { name: 'Commission', abbr: 'COM', cat: 'PERCENTAGE', canInclude: false, defaultType: 'PERCENTAGE'},
};

function Amenity(amenityId, amenityData, negotiation, negotiations){
  Object.assign(this, amenityData, amenitiesConfig[amenityId]);
  this.id = amenityId;
  this.negotiation = negotiation;
  this.negotiations = negotiations;
}

Amenity.prototype.getValue = function(){
  return {
    value: this.value,
    included: this.included,
    type: this.type,
    defaultType: this.defaultType,
    valid: this.valid,
    mocked: this.mocked,
    mockPercentage: this.mockPercentage,
    amount: this.amount
  };
};

Amenity.prototype.getFormattedValue = function(){
  const value = this.type === 'UNAVAILABLE' || !this.valid ? null : this.value;
  switch (this.type) {
    case 'PERCENTAGE':
      return this.negotiations.percentageFormatter.format(value);
    case 'TEXT':
      return value;
    default:
      return this.negotiations.currencyFormat.format(value);
  }
};

Amenity.prototype.getFormattedSecondaryValue = function(){
  const primaryRateValue = this.negotiation.getPrimaryRateValue(),
    value = this.type === 'UNAVAILABLE' || !this.valid ? null : this.value;
  switch (this.type) {
    case 'PERCENTAGE':
      return this.negotiations.currencyFormat.format(primaryRateValue && primaryRateValue*value);
    case 'TEXT':
      return value;
    default:
      return this.negotiations.percentageFormatter.format(primaryRateValue && value/primaryRateValue);
  }
};

export default Amenity;
