export default function scrollToAttachments() {
  let attempts = 0;

  const intervalID = window.setInterval(() => {
    attempts++;
    const e = document.getElementById('ViewAttachments');
    if(e) {
      e.scrollIntoView();
      window.clearInterval(intervalID)
    } else if(attempts >= 20) {
      window.clearInterval(intervalID)
    }
  }, 200);
}