import { fileToJson } from 'root/shared/tools/xls.service'
import { FIELD_ID, default as makeTravelDestinationUpload } from 'root/rfp-hotel/main/rfp/destinations/upload-destinations.factory'

export default uploadDestinations

function uploadDestinations(file, progressCallback, rfp){
  const destinations = [], fileLoadedProgressValue = 10
  let actionsToBePerformed = 0, actionsPerformed = 0

  return fileToJson(file, { sheetName: 'Travel Destinations' })
    .then(markFileProgress)
    .then(parseDestinations)

  function parseDestinations(fileDestinations){
    const rawDestinations = fileDestinations.filter( destination => destinationIsNotEmpty(destination)),
      chain = buildParseChain()
    actionsToBePerformed = rawDestinations.length * 3
    actionsPerformed = 0
    return chain.then(() => destinations)

    function buildParseChain() {
      let chainPromise = Promise.resolve()
      rawDestinations.forEach(rd => {
        chainPromise = chainPromise
          .then(() => createDestination(rd))
          .then(geocodeDestination)
          .then(td => validateDestination(td, rfp.specifications))
          .then(saveDestination)
      })
      return chainPromise
    }

    function destinationIsNotEmpty(td){
      return Object.keys(td).some( field => field !== FIELD_ID && td[ field ].length > 0)
    }
  }

  function markFileProgress(data){
    progressCallback( fileLoadedProgressValue )
    return Promise.resolve(data)
  }

  function markTravelDestinationProgress(data){
    progressCallback( fileLoadedProgressValue + ( ++actionsPerformed / actionsToBePerformed * (100 - fileLoadedProgressValue) ))
    return Promise.resolve(data)
  }

  function createDestination(rd){
    return Promise.resolve(makeTravelDestinationUpload(rd, rfp))
      .then(markTravelDestinationProgress)
  }

  function geocodeDestination(td){
    return td.geocode()
      .then(markTravelDestinationProgress)
  }

  function validateDestination(td){
    td.validate()
    return Promise.resolve(td)
      .then(markTravelDestinationProgress)
  }

  function saveDestination(td){
    destinations.push(td)
    return Promise.resolve()
  }
}
