/* eslint-disable no-irregular-whitespace */
export default [
  { id:'AED',value:'AED', label:'United Arab Emirates dirham' },
  { id:'AFN',value:'AFN', label:'Afghan afghani' },
  { id:'ALL',value:'ALL', label:'Albanian lek' },
  { id:'AMD',value:'AMD', label:'Armenian dram' },
  { id:'ANG',value:'ANG', label:'Netherlands Antillean guilder' },
  { id:'AOA',value:'AOA', label:'Angolan kwanza' },
  { id:'ARS',value:'ARS', label:'Argentine peso' },
  { id:'AUD',value:'AUD', label:'Australian dollar' },
  { id:'AWG',value:'AWG', label:'Aruban florin' },
  { id:'AZN',value:'AZN', label:'Azerbaijani manat' },
  { id:'BAM',value:'BAM', label:'Bosnia and Herzegovina convertible mark' },
  { id:'BBD',value:'BBD', label:'Barbados dollar' },
  { id:'BDT',value:'BDT', label:'Bangladeshi taka' },
  { id:'BGN',value:'BGN', label:'Bulgarian lev' },
  { id:'BHD',value:'BHD', label:'Bahraini dinar' },
  { id:'BIF',value:'BIF', label:'Burundian franc' },
  { id:'BMD',value:'BMD', label:'Bermudian dollar' },
  { id:'BND',value:'BND', label:'Brunei dollar' },
  { id:'BOB',value:'BOB', label:'Boliviano' },
  { id:'BRL',value:'BRL', label:'Brazilian real' },
  { id:'BSD',value:'BSD', label:'Bahamian dollar' },
  { id:'BTN',value:'BTN', label:'Bhutanese ngultrum' },
  { id:'BWP',value:'BWP', label:'Botswana pula' },
  { id:'BYN',value:'BYN', label:'Belarusian ruble' },
  { id:'BZD',value:'BZD', label:'Belize dollar' },
  { id:'CAD',value:'CAD', label:'Canadian dollar', ord: 2 },
  { id:'CDF',value:'CDF', label:'Congolese franc' },
  { id:'CHF',value:'CHF', label:'Swiss franc', ord: 6 },
  { id:'CLP',value:'CLP', label:'Chilean peso' },
  { id:'CNY',value:'CNY', label:'Renminbi (Chinese) yuan' },
  { id:'COP',value:'COP', label:'Colombian peso' },
  { id:'CRC',value:'CRC', label:'Costa Rican colon' },
  { id:'CUC',value:'CUC', label:'Cuban convertible peso' },
  { id:'CUP',value:'CUP', label:'Cuban peso' },
  { id:'CVE',value:'CVE', label:'Cape Verde escudo' },
  { id:'CZK',value:'CZK', label:'Czech koruna' },
  { id:'DJF',value:'DJF', label:'Djiboutian franc' },
  { id:'DKK',value:'DKK', label:'Danish krone' },
  { id:'DOP',value:'DOP', label:'Dominican peso' },
  { id:'DZD',value:'DZD', label:'Algerian dinar' },
  { id:'EGP',value:'EGP', label:'Egyptian pound' },
  { id:'ERN',value:'ERN', label:'Eritrean nakfa' },
  { id:'ETB',value:'ETB', label:'Ethiopian birr' },
  { id:'EUR',value:'EUR', label:'Euro', ord: 4 },
  { id:'FJD',value:'FJD', label:'Fiji dollar' },
  { id:'FKP',value:'FKP', label:'Falkland Islands pound' },
  { id:'GBP',value:'GBP', label:'Pound sterling', ord: 5 },
  { id:'GEL',value:'GEL', label:'Georgian lari' },
  { id:'GGP',value:'GGP', label:'Guernsey pound' },
  { id:'GHS',value:'GHS', label:'Ghanaian cedi' },
  { id:'GIP',value:'GIP', label:'Gibraltar pound' },
  { id:'GMD',value:'GMD', label:'Gambian dalasi' },
  { id:'GNF',value:'GNF', label:'Guinean franc' },
  { id:'GTQ',value:'GTQ', label:'Guatemalan quetzal' },
  { id:'GYD',value:'GYD', label:'Guyanese dollar' },
  { id:'HKD',value:'HKD', label:'Hong Kong dollar' },
  { id:'HNL',value:'HNL', label:'Honduran lempira' },
  { id:'HRK',value:'HRK', label:'Croatian kuna' },
  { id:'HTG',value:'HTG', label:'Haitian gourde' },
  { id:'HUF',value:'HUF', label:'Hungarian forint' },
  { id:'IDR',value:'IDR', label:'Indonesian rupiah' },
  { id:'ILS',value:'ILS', label:'Israeli new shekel' },
  { id:'IMP',value:'IMP', label:'Isle of Man pound' },
  { id:'INR',value:'INR', label:'Indian rupee' },
  { id:'IQD',value:'IQD', label:'Iraqi dinar' },
  { id:'IRR',value:'IRR', label:'Iranian rial' },
  { id:'ISK',value:'ISK', label:'Icelandic króna' },
  { id:'JEP',value:'JEP', label:'Jersey pound' },
  { id:'JMD',value:'JMD', label:'Jamaican dollar' },
  { id:'JOD',value:'JOD', label:'Jordanian dinar' },
  { id:'JPY',value:'JPY', label:'Japanese yen', ord: 7 },
  { id:'KES',value:'KES', label:'Kenyan shilling' },
  { id:'KGS',value:'KGS', label:'Kyrgyzstani som' },
  { id:'KHR',value:'KHR', label:'Cambodian riel' },
  { id:'KMF',value:'KMF', label:'Comoro franc' },
  { id:'KPW',value:'KPW', label:'North Korean won' },
  { id:'KRW',value:'KRW', label:'South Korean won' },
  { id:'KWD',value:'KWD', label:'Kuwaiti dinar' },
  { id:'KYD',value:'KYD', label:'Cayman Islands dollar' },
  { id:'KZT',value:'KZT', label:'Kazakhstani tenge' },
  { id:'LAK',value:'LAK', label:'Lao kip' },
  { id:'LBP',value:'LBP', label:'Lebanese pound' },
  { id:'LKR',value:'LKR', label:'Sri Lankan rupee' },
  { id:'LRD',value:'LRD', label:'Liberian dollar' },
  { id:'LSL',value:'LSL', label:'Lesotho loti' },
  { id:'LYD',value:'LYD', label:'Libyan dinar' },
  { id:'MAD',value:'MAD', label:'Moroccan dirham' },
  { id:'MDL',value:'MDL', label:'Moldovan leu' },
  { id:'MGA',value:'MGA', label:'Malagasy ariary' },
  { id:'MKD',value:'MKD', label:'Macedonian denar' },
  { id:'MMK',value:'MMK', label:'Myanmar kyat' },
  { id:'MNT',value:'MNT', label:'Mongolian tögrög' },
  { id:'MOP',value:'MOP', label:'Macanese pataca' },
  { id:'MRU',value:'MRU', label:'Mauritanian ouguiya' },
  { id:'MUR',value:'MUR', label:'Mauritian rupee' },
  { id:'MVR',value:'MVR', label:'Maldivian rufiyaa' },
  { id:'MWK',value:'MWK', label:'Malawian kwacha' },
  { id:'MXN',value:'MXN', label:'Mexican peso', ord: 3 },
  { id:'MYR',value:'MYR', label:'Malaysian ringgit' },
  { id:'MZN',value:'MZN', label:'Mozambican metical' },
  { id:'NAD',value:'NAD', label:'Namibian dollar' },
  { id:'NGN',value:'NGN', label:'Nigerian naira' },
  { id:'NIO',value:'NIO', label:'Nicaraguan córdoba' },
  { id:'NOK',value:'NOK', label:'Norwegian krone' },
  { id:'NPR',value:'NPR', label:'Nepalese rupee' },
  { id:'NZD',value:'NZD', label:'New Zealand dollar' },
  { id:'OMR',value:'OMR', label:'Omani rial' },
  { id:'PAB',value:'PAB', label:'Panamanian balboa' },
  { id:'PEN',value:'PEN', label:'Peruvian sol' },
  { id:'PGK',value:'PGK', label:'Papua New Guinean kina' },
  { id:'PHP',value:'PHP', label:'Philippine peso' },
  { id:'PKR',value:'PKR', label:'Pakistani rupee' },
  { id:'PLN',value:'PLN', label:'Polish złoty' },
  { id:'PYG',value:'PYG', label:'Paraguayan guaraní' },
  { id:'QAR',value:'QAR', label:'Qatari riyal' },
  { id:'RON',value:'RON', label:'Romanian leu' },
  { id:'RSD',value:'RSD', label:'Serbian dinar' },
  { id:'RUB',value:'RUB', label:'Russian ruble' },
  { id:'RWF',value:'RWF', label:'Rwandan franc' },
  { id:'SAR',value:'SAR', label:'Saudi riyal' },
  { id:'SBD',value:'SBD', label:'Solomon Islands dollar' },
  { id:'SCR',value:'SCR', label:'Seychelles rupee' },
  { id:'SDG',value:'SDG', label:'Sudanese pound' },
  { id:'SEK',value:'SEK', label:'Swedish krona/kronor' },
  { id:'SGD',value:'SGD', label:'Singapore dollar' },
  { id:'SHP',value:'SHP', label:'Saint Helena pound' },
  { id:'SLL',value:'SLL', label:'Sierra Leonean leone' },
  { id:'SOS',value:'SOS', label:'Somali shilling' },
  { id:'SRD',value:'SRD', label:'Surinamese dollar' },
  { id:'SSP',value:'SSP', label:'South Sudanese pound' },
  { id:'STN',value:'STN', label:'São Tomé and Príncipe dobra' },
  { id:'SVC',value:'SVC', label:'Salvadoran colón' },
  { id:'SYP',value:'SYP', label:'Syrian pound' },
  { id:'SZL',value:'SZL', label:'Swazi lilangeni' },
  { id:'THB',value:'THB', label:'Thai baht' },
  { id:'TJS',value:'TJS', label:'Tajikistani somoni' },
  { id:'TMT',value:'TMT', label:'Turkmenistan manat' },
  { id:'TND',value:'TND', label:'Tunisian dinar' },
  { id:'TOP',value:'TOP', label:'Tongan paʻanga' },
  { id:'TRY',value:'TRY', label:'Turkish lira' },
  { id:'TTD',value:'TTD', label:'Trinidad and Tobago dollar' },
  { id:'TWD',value:'TWD', label:'New Taiwan dollar' },
  { id:'TZS',value:'TZS', label:'Tanzanian shilling' },
  { id:'UAH',value:'UAH', label:'Ukrainian hryvnia' },
  { id:'UGX',value:'UGX', label:'Ugandan shilling' },
  { id:'USD',value:'USD', label:'United States dollar', ord: 1 },
  { id:'UYU',value:'UYU', label:'Uruguayan peso' },
  { id:'UZS',value:'UZS', label:'Uzbekistan som' },
  { id:'VES',value:'VES', label:'Venezuelan bolívar soberano' },
  { id:'VND',value:'VND', label:'Vietnamese đồng' },
  { id:'VUV',value:'VUV', label:'Vanuatu vatu' },
  { id:'WST',value:'WST', label:'Samoan tala' },
  { id:'XAF',value:'XAF', label:'CFA franc BEAC' },
  { id:'XCD',value:'XCD', label:'East Caribbean dollar' },
  { id:'XOF',value:'XOF', label:'CFA franc BCEAO' },
  { id:'XPF',value:'XPF', label:'CFP franc (franc Pacifique)' },
  { id:'XXX',value:'XXX', label:'No currency' },
  { id:'YER',value:'YER', label:'Yemeni rial' },
  { id:'ZAR',value:'ZAR', label:'South African rand' },
  { id:'ZMW',value:'ZMW', label:'Zambian kwacha' },
  { id:'ZWL',value:'ZWL', label:'Zimbabwean dollar' },
]
