/**
 * Created by DejanK on 4/3/2017.
 */
import angular from 'angular'
import DestinationsModule from './destinations/rfp-hotel.rfp.destinations.module'
import RfpHotelRfpRepository from './rfp-hotel.rfp.repo'
import rbsRfpDetailsNavigation from './rbs-rfp-details-navigation/rbs-rfp-details-navigation.cmp.js'
import rbsEditQuestionnaireNavigation from './rbs-rfp-details-navigation/rbs-edit-questionnaire-navigation.cmp.js'
import rbsDocumentHeadingTitle from './rbs-document-heading-title/rbs-document-heading-title.cmp.js'
import HotelDirectoriesModule from './directories/rfp-hotel.rfp.directories.module';
import rbHotelRfpViewRfpViewStandardCoverLetter from './pages/cover-letter/view-standard-cover-letter/view-standard-cover-letter.cmp';
import rbHotelRfpViewRfpViewRateQuickCoverLetter from './pages/cover-letter/view-rate-quick-cover-letter/view-rate-quick-cover-letter.cmp';
import rbHotelRfpViewRfpViewNamStandardCoverLetter from './pages/nam-cover-letter/view-standard-cover-letter/view-nam-standard-cover-letter.cmp';
import rbNgSvelteRfpCompetitiveBids from './pages/competitive-bids/rb-ng-svelte-rfp-competitive-bids.cmp';

export default angular.module('rb.rfpHotel.main.rfp', [
  DestinationsModule.name,
  HotelDirectoriesModule.name
])
  .service('RfpHotelRfpRepository', RfpHotelRfpRepository)
  .component('rbsRfpDetailsNavigation', rbsRfpDetailsNavigation)
  .component('rbsEditQuestionnaireNavigation', rbsEditQuestionnaireNavigation)
  .component('rbsDocumentHeadingTitle', rbsDocumentHeadingTitle)
  .component('rbHotelRfpViewRfpViewStandardCoverLetter', rbHotelRfpViewRfpViewStandardCoverLetter)
  .component('rbHotelRfpViewRfpViewRateQuickCoverLetter', rbHotelRfpViewRfpViewRateQuickCoverLetter)
  .component('rbHotelRfpViewRfpViewNamStandardCoverLetter',  rbHotelRfpViewRfpViewNamStandardCoverLetter)
  .component('rbNgSvelteRfpCompetitiveBids',  rbNgSvelteRfpCompetitiveBids)
;
