import Vue from 'vue';
import TDList from './travel-destinations-list.vue';

export default {
  template: '<div></div>',
  controller: Ctrl
}

Ctrl.$inject = ['$element']
function Ctrl($element){
  const
    vm = this;

  let vueComponent;

  vm.$onInit = () => { initVueComponent() }
  vm.$onDestroy = () => { vueComponent && vueComponent.$destroy() }


  function initVueComponent(){
    !vueComponent && initVue();
    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        render: function (createElement) {
          return createElement(TDList, {})
        }
      })
    }
  }
}
