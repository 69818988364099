{#if user}
  <RbHeader />
{:else}
  <RbGuestHeader />
{/if}

<script>
  import RbHeader from './RbHeader.svelte';
  import RbGuestHeader from "./RbGuestHeader.svelte";
  import { currentUser as currentUserProvider } from 'root/angular-injector-provider';
  import { onMount, onDestroy } from 'svelte';

  let
    unwatchUser,
    user = null;

  onMount(() => { unwatchUser = currentUserProvider().watch(u => user = u) });
  onDestroy(() => { if(unwatchUser) unwatchUser() });
</script>
