<script>
  import {store, initialize, updateSettings} from 'rfp/bid-manager/main/pages/dashboard/bids-sources';
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import BidsAsCards from './BidsAsCards.svelte';
  import BidsAsTable from './BidsAsTable.svelte';
  import BidsSourceMenu from './BidsSourceMenu.svelte';
  import formatBid from './format-bid';
  import {tick} from 'svelte';
  import noop from 'lodash/noop';
  import {SvelteDialog} from 'root/v-app/rbServices';
  import TheExportsDialog from './TheExportsDialog.svelte';

  let
    state = 'LOADING',
    bids = [],
    viewCards = true,
    hasMore = false,
    loadingMore = false,
    page = 0,
    pagination = {},
    firstRequestTimestamp = new Date().getTime(),
    currentSource = null;

  initialize();

  $: pagination = { hasMore, loadingMore, loadMoreFn };
  $: onSourceChange($store.source);

  function onSourceChange(newSource){
    if(newSource && (currentSource === null || currentSource.id !== newSource.id)){
      currentSource = newSource;
      reload();
    }
  }

  function reload(){
    state = 'LOADING';
    bids = [];
    page = 0;
    firstRequestTimestamp = new Date().getTime();
    fetch();
  }

  async function fetch(){
    if(!currentSource) return;
    try {
      const result = await currentSource.source({page: ++page, ts: firstRequestTimestamp});

      bids = groupBids(bids, formatBids(result.data.data));
      hasMore = result.data.more;
      state = bids.length ? 'LOADED' : 'EMPTY';
      await tick();
    } catch {
      state = 'ERROR';
    }
  }

  function formatBids(rawBids){
    return rawBids.map(b => ({
      ...b,
      ...formatBid(b)
    }));
  }

  function groupBids(result = [], fBids){
    let lastDateGroup = bids.length ? bids[bids.length-1] : { date: null, bids: []};

    for(let i=0, l=fBids.length; i<l; i++){
      const bid = fBids[i];
      if(lastDateGroup.date !== bid.stateDate){
        lastDateGroup = {
          date: bid.stateDate,
          bids: []
        };
        result.push(lastDateGroup);
      }

      lastDateGroup.bids.push(bid);
    }

    return result;
  }

  async function loadMoreFn(){
    if(!loadingMore){
      loadingMore = true;
      await fetch();
      loadingMore = false;
    }
  }

  function switchViewType(newViewType){
    updateSettings({viewType: newViewType});
  }

  function showExportsDialog(){
    SvelteDialog.show(TheExportsDialog)
      .catch(noop);
  }
</script>

<div class="Component">
  <div class="Header">
    <BidsSourceMenu />

    <div class="HeaderAction" data-icon-after="refresh" on:click={() => reload()}>
      <div class="rbTooltip">Refresh</div>
    </div>

    <div class="HeaderAction" data-icon-after="file_download" on:click={showExportsDialog}>
      <div class="rbTooltip">Exports</div>
    </div>

    <div class="HeaderAction"
         data-icon-after="{$store.viewType === 'TABLE' ? 'grid_view' : 'view_list'}"
         on:click={() => switchViewType($store.viewType === 'TABLE' ? 'CARDS' : 'TABLE')}>
      <div class="rbTooltip">Change View</div>
    </div>
  </div>

  {#if state === 'LOADING'}
    <div class="FillAndCenter">
      <Spinner />
    </div>

  {:else if state === 'LOADED'}

    {#if $store.viewType === 'TABLE'}
      <BidsAsTable {bids} {pagination} />
    {:else }
      <BidsAsCards {bids} {pagination} />
    {/if}

  {:else if state === 'EMPTY'}
    <div class="FillAndCenter">
      <div class="EmptyTitle">No Bids Available!</div>
      <div>
        {#if $store.source.id === 'personal'}
          There are no bids assigned to you.
        {:else if $store.source.id === 'bookmarked'}
          You may not have bookmarked any RFP yet. Try to bookmark some and then reload bids.
        {/if}
      </div>
    </div>

  {:else if state === 'ERROR'}
    <div class="FillAndCenter">
      System Encountered an Error!
    </div>
  {/if}
</div>


<style lang="stylus">.Component {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow: hidden;
}
.Component :global(.Gray) {
  background: #68808c;
}
.Component :global(.Blue) {
  background: #096096;
}
.Component :global(.Yellow) {
  background: #ffbb36;
}
.Component :global(.Green) {
  background: #8ab923;
}
.Component :global(.Purple) {
  background: #9c0d66;
}
.Component :global(.Red) {
  background: #e32e40;
}
.Component :global(.rbTooltip) {
  background: #fff;
  color: #546e7a;
  font-size: 13px;
  line-height: 1.3;
  box-shadow: 1px 1px 5px 3px rgba(0,0,0,0.2);
}
.Header {
  color: #546e7a;
  padding: 10px 10px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.HeaderAction {
  cursor: pointer;
}
.HeaderAction:hover {
  color: #00a99d;
}
.FillAndCenter {
  flex-direction: column;
  color: #546e7a;
}
.EmptyTitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>