<script>
  import { createEventDispatcher } from 'svelte';
  import { SvelteDialog } from 'root/v-app/rbServices';
  import SelectTravelManagerDialog from './RfpSpecificationsTravelManagerSelect.svelte';
  import defaultImage from 'images/user-image.png';

  export let
    manager = {},
    rfpId,
    readonly;
  export let showEmailAddress = true;

  const dispatch = createEventDispatcher();

  async function changeTravelManager(){
    try {
      let result = await SvelteDialog.show(SelectTravelManagerDialog, {rfpId, selected: manager.id});
      dispatch('change', result);
    } catch (e) {}
  }
</script>

<div>
  <div class="Container">
    <div class="PictureContainer">
      <img class="Picture" src="{manager.profilePicture ? `/images/users/${manager.profilePicture}` : defaultImage }" alt="User">
    </div>

    <div class="TextContainer">
      <div class="Title">{manager.fullName}</div>
      <div class="Text">
        {manager.jobTitle} at
        {#if manager.company.website}
          <a class="Link" href="{manager.company.website}" target="_blank">{manager.company.name}</a>
        {:else}
          {manager.company.name}
        {/if}
      </div>
      <div class="Text">{showEmailAddress? manager.emailAddress: ''}</div>
    </div>
  </div>

  {#if !readonly}
    <div>
      <button class="aBtn atLight asSmall" data-icon="person" on:click={() => changeTravelManager()}>Change RFP Issuer</button>
    </div>
  {/if}
</div>


<style lang="stylus">.Container {
  display: flex;
}
.PictureContainer {
  flex: 0 0 auto;
}
.Picture {
  height: 64px;
  border-radius: 6px;
}
.TextContainer {
  flex: 1 1 auto;
  height: 64px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
}
.Title {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #455a64;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Text {
  font-size: 13px;
  color: #78909c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Link {
  font-size: 13px;
  color: #78909c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
