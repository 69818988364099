import {checkAndExecute} from 'root/api/helpers';

export default function HotelRfpBidResponseAPI(api) {

  function exportDataWithAutoSave(exportData){
    return api.server.postAndAutoSaveFile(`rfps/hotel/bids/export`, exportData)
  }

  Object.assign(api, {

    hotelRfpExportFinalAgreementsToExcel: (bidsIds, includeUnsent) => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:FINAL_AGREEMENT:EXCEL', bids: bidsIds, options: { includeUnsent: !!includeUnsent }})),

    hotelRfpExportFinalAgreementsWithStatusToExcel: (bidsIds, includeUnsent) => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:FINAL_AGREEMENT_WITH_STATUS:EXCEL', bids: bidsIds, options: { includeUnsent: !!includeUnsent }})),

    hotelRfpExportBidManagerToExcel: (bidsIds, columns) => checkAndExecute(
      () => bidsIds && bidsIds.length && columns && columns.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:BID_MANAGER:EXCEL', bids: bidsIds, options: {columns: columns}})),

    hotelRfpExportGbtaToExcel: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:GBTA:EXCEL', bids: bidsIds})),

    hotelRfpExportGbtaToCsv: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:GBTA:CSV', bids: bidsIds})),

    hotelRfpExportGbtaWithStatusToCsv: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:GBTA_WITH_STATUS:CSV', bids: bidsIds})),

    hotelRfpExportGbtaLastOfferWithStatusToCsv: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:GBTA_LAST_OFFER_WITH_STATUS:CSV', bids: bidsIds})),

    exportBidManagerToExcel: (bidsIds, name) => checkAndExecute(
      () => bidsIds && bidsIds.length && name,
      () => exportDataWithAutoSave({name, bids: bidsIds})),

    exportBidManagerToFile: (bidsIds, name) => checkAndExecute(
      () => bidsIds && bidsIds.length && name,
      () => exportDataWithAutoSave({name, bids: bidsIds})),

    exportBidPackage: bidId => checkAndExecute(
      () => bidId,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:BID_PACKAGE:EXCEL', bids: [bidId]})),

    exportNegotiationRequestsWithHistory: (bidsIds, exportName) => checkAndExecute(
      () => bidsIds && bidsIds.length && exportName,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:NEG_REQUESTS_HISTORY:EXCEL', bids: bidsIds, options: {name: exportName}})),

    exportNegotiationRequestsOnly: (bidsIds, exportName) => checkAndExecute(
      () => bidsIds && bidsIds.length && exportName,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:NEG_REQUESTS_ONLY:EXCEL', bids: bidsIds, options: {name: exportName}})),
  })
}
