import {STRIPE_KEY} from 'config/app.config';
let stripePromise;

export default function stripeProvider(){
  if(stripePromise) return stripePromise;
  stripePromise = new Promise(resolve => {
    const id = setInterval(() => {
      if(window.Stripe){
        clearInterval(id);
        resolve(window.Stripe(STRIPE_KEY));
      }
    }, 200);
  });

  return stripePromise;
}