import HTML from './view-nam-standard-cover-letter.html';
import get from 'lodash/get';
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
import noop from 'lodash/noop';

export default {
  template: HTML,
  bindings: {
    rfp: '<'
  },
  controller: RfpNamCoverLetterViewController,
}

function RfpNamCoverLetterViewController(){
  const 
    vm = this;

  Object.assign(this, {
    $onChanges ( changes ) {
      vm.rfp = get(changes, 'rfp.currentValue');
      vm.rfpId = get(vm.rfp, 'id');
      vm.isChainEnabled = true;
    },

    addAttachment ( id ) {
      return mainAPI()
        .addHotelRfpNamCoverLetterAttachment(vm.rfpId, { id })
        .then(res => updateAttachments(res.data));
    },

    removeAttachment ( id ) {
      notificationUtils().onSave(() => mainAPI().removeHotelRfpNamCoverLetterAttachment(vm.rfpId, id))
        .then(res => updateAttachments(res.data))
        .catch(noop);
    }
  });

  function updateAttachments(attachments){
    vm.rfp = { ...vm.rfp, namCoverLetter: { ...vm.rfp.namCoverLetter, attachments}};
  }
}
