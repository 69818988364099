export function statusMatches(selectedBidsStatuses, requiredStatuses){
  if(selectedBidsStatuses.length > requiredStatuses.length) {
    return false;
  }

  // all selected bids must have one of action required statuses
  for(let i=0, l = selectedBidsStatuses.length; i<l; i++){
    if( requiredStatuses.indexOf(selectedBidsStatuses[i]) === -1 ) {
      return false;
    }
  }
  return true;
}

export function isSingleBid(report) {
  return report.bids.length === 1;
}

export function getBidsIds(bids){
  return bids.map( b => b._id );
}

export function allBidsAreUniqueByHotel(bids){
  const hotelsIds = [];
  for(let i =0, l = bids.length; i<l; i++){
    const hotelId = bids[i].supplier.company.entityId;
    if(hotelsIds.indexOf(hotelId) === -1){
      hotelsIds.push(hotelId);
    } else {
      return false;
    }
  }
  return true;
}
