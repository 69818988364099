<template>
  <button
    type="button"
    class="rbvCheckBox material-icons"
    @click.prevent.stop="onClick"
  >
    {{ stateIcon }}
  </button>
</template>

<script>

  const ICONS = ['check_box_outline_blank', 'check_box', 'indeterminate_check_box' ]

  export default {
    name: 'RbvCheckbox',

    props: {
      checked: Boolean,
      indeterminate: Boolean,
      readonly: Boolean
    },

    data() {
      return {
        viewValue: false
      }
    },
    computed: {
      stateIcon () { return ICONS[this.viewValue] }
    },
    watch: {
      checked: {
        immediate: true,
        handler(val) {
          this.viewValue = val ? 1 : 0
        }
      },
      indeterminate: {
        immediate: true,
        handler(val){
          this.viewValue = val ? 2 : ( this.checked ? 1 : 0 )
        }
      }
    },
    methods: {
      onClick () {
        if(!this.readonly) {
          this.viewValue = this.viewValue === 1 ? 0 : 1
          // noinspection PointlessBooleanExpressionJS
          this.$emit('change', !!this.viewValue)
        }
      }
    }
  }
</script>

<style lang="stylus">

  .rbvCheckBox {
    background: transparent;
    border: 0;
    color: #546E7A;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    transition: none;
    line-height: 1;

    &:focus {
      outline 1px solid #00B8AB
    }

    &.error {
      outline 1px solid #f15a25
    }
  }
</style>
