/**
 * Created by DejanK on 12/21/2017
 */
import Popper from 'popper.js'
import './rb-abs-tooltip.scss'
import { rbParseAttribute } from 'root/shared/tools/utils'

export default function (){
  return {
    restrict: 'A',
    link: LinkFn
  }
}

function LinkFn(scope, element, attributes){
  const parent = element.parent(), config = setupConfig()
  let popper = null
  init()

  function setupConfig(){
    let c = Object.assign({
      placement: 'top',
      margin: 5
    }, rbParseAttribute(attributes.rbAbsTooltip))

    if(c.margin){
      c = Object.assign(c, { modifiers: { offset: { offset: `0, ${c.margin}` }}})
    }

    return c
  }

  function init() {
    element.addClass('rbAbsTooltip')

    parent.on('mouseenter', showTooltip)
    parent.on('mouseleave', hideTooltip)
    element.on('mouseenter', hideTooltip)

    scope.$on('$destroy', () => {
      hideTooltip()
      parent && parent.off('mouseenter', showTooltip)
      parent && parent.off('mouseleave', hideTooltip)
      element.off('mouseenter', hideTooltip)
      popper && popper.destroy()
    })
  }

  function showTooltip() {
    if (popper) {
      popper.scheduleUpdate()
    } else {
      popper = new Popper(parent[0], element[0], config)
    }

    element.addClass('show')
  }

  function hideTooltip(){ element.removeClass('show') }
}
