<template>
  <div
    :id="id"
    ref="viewport"
    class="RbVLFS_Viewport"
    :style="{height: height}"
    @scroll="handleScroll($event)"
  >
    <div class="heading">
      <slot name="heading" />
    </div>

    <div
      ref="contents"
      class="contents"
      :style="{'padding-top': top + 'px', 'padding-bottom': bottom + 'px'}"
    >
      <template v-for="row in visible">
        <div
          :key="row.index"
          class="listRow"
        >
          <slot :row="row.data">Missing Template</slot>
        </div>
      </template>
    </div>

    <div class="footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'VueListFixed',

  props: {
    id: {
      type: String,
      required: false,
      default: 'vListFixed'
    },
    items: {
      type: Array,
      required: false,
      default () {
        return [];
      },
    },
    height: {
      type: String,
      required: false,
      default: '100%',
    },
    itemHeight: {
      type: Number,
      required: false,
      default: 40,
    },
  },

  data: function(){
    return {
      start: 0,
      end: 0,
      height_map: [],
      rows: [],
      viewport_height: 0,
      mounted: false,
      top: 0,
      bottom: 0,
      average_height: undefined,
      unobserve: null,
    }
  },

  computed: {
    visible() {
      return this.items.slice(this.start, this.end).map((data, i) => {
        return {index: i + this.start, data};
      });
    },
  },


  watch:{
    items () {
      this.refresh();
    },
    viewport_height(){
      this.refresh();
    },
    itemHeight(){
      this.refresh();
    },
  },

  mounted: function(){
    this.rows = this.$refs.contents.getElementsByClassName('listRow');

    this.viewport_height = this.$refs.viewport.offsetHeight

    const resizeObserver = new ResizeObserver((entries) => {
      this.viewport_height = entries[0].target.offsetHeight
    })

    const vpEl = this.$refs.viewport;
    resizeObserver.observe(vpEl);
    this.unobserve = function () {
      resizeObserver.unobserve(vpEl);
      resizeObserver.disconnect();
    }

    this.mounted = true;
  },

  beforeDestroy: function () {
    if(this.unobserve) this.unobserve();
  },

  methods: {
    refresh: async function (){
      if(!this.mounted) return;

      const { scrollTop } = this.$refs.viewport;
      await Vue.nextTick(); // wait until the DOM is up to date
      let content_height = this.top - scrollTop;
      let i = this.start;

      while (content_height < this.viewport_height && i < this.items.length) {
        let row = this.rows[i - this.start];
        if (!row) {
          this.end = i + 1;
          // eslint-disable-next-line no-await-in-loop
          await Vue.nextTick(); // render the newly visible row
          row = this.rows[i - this.start];
        }
        const row_height = this.height_map[i] = this.itemHeight || row.offsetHeight;
        content_height += row_height;
        i += 1;
      }

      this.end = i;
      const remaining = this.items.length - this.end;
      this.average_height = (this.top + content_height) / this.end;
      this.bottom = remaining * this.average_height;
      this.height_map.length = this.items.length;
    },

    handleScroll: async function() {
      const { scrollTop } = this.$refs.viewport;
      const old_start = this.start;
      for (let v = 0; v < this.rows.length; v += 1) {
        this.height_map[this.start + v] = this.itemHeight || this.rows[v].offsetHeight;
      }

      let i = 0;
      let y = 0;
      while (i < this.items.length) {
        const row_height = this.height_map[i] || this.average_height;
        if (y + row_height > scrollTop) {
          this.start = i;
          this.top = y;
          break;
        }
        y += row_height;
        i += 1;
      }

      while (i < this.items.length) {
        y += this.height_map[i] || this.average_height;
        i += 1;
        if (y > scrollTop + this.viewport_height) break;
      }

      this.end = i;
      const remaining = this.items.length - this.end;
      this.average_height = y / this.end;
      while (i < this.items.length) this.height_map[i++] = this.average_height;
      this.bottom = remaining * this.average_height;
      // prevent jumping if we scrolled up into unknown territory

      if (this.start < old_start) {
        await Vue.nextTick();
        let expected_height = 0;
        let actual_height = 0;
        for (let j = this.start; j < old_start; j +=1) {
          if (this.rows[j - this.start]) {
            expected_height += this.height_map[j];
            actual_height += this.itemHeight || this.rows[j - this.start].offsetHeight;
          }
        }
        const d = actual_height - expected_height;
        this.$refs.viewport.scrollTo(this.$refs.viewport.scrollLeft, scrollTop + d);
      }
      // TODO if we overestimated the space these
      // rows would occupy we may need to add some
      // more. maybe we can just call handle_scroll again?
    },
  },
}
</script>

<style lang="stylus">

.RbVLFS_Viewport {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling:touch;
  display: block;

  .heading {
    display: block;
    position: sticky;
    top: 0;
    z-index: 9;
  }

  .contents {
    display: block;
  }

  .listRow {
    display: block;
    overflow: hidden;
  }

  .footer {
    display: block;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
</style>
