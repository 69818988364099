/**
 * Created by DejanK on 3/6/2017.
 */
import HTML from './edit-cover-letter.html';
import get from 'lodash/get';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

export {HTML, BidCoverLetterEditController as Controller};

BidCoverLetterEditController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state', '$q', 'rbDialog'];
function BidCoverLetterEditController(notificationUtils, RfpHotelBidRepository, $state, $q, dialog){
  const vm = this;
  let originalCoverLetter;

  vm.save = saveCoverLetter;
  vm.onCoverLetterChanged = onCoverLetterChanged;

  $onInit();

  function $onInit(){
    vm.bidId = $state.params.bidId;
    vm.coverLetterModel = undefined;

    notificationUtils.onLoad(() => {
      return $q.all({
        template: RfpHotelBidRepository.getBidCoverLetterTemplate(vm.bidId)
          .then(httpResponse => {
            originalCoverLetter = get(httpResponse, 'data.letter');
            return clone(originalCoverLetter)
          }).catch(noop),
        placeholders: RfpHotelBidRepository.getHotelRfpCoverLetterPlaceholders()
      });
    }, vm, 'coverLetterModel');
  }

  function saveCoverLetter(){
    if(isEqual(originalCoverLetter, vm.coverLetter)){
      viewLetter();
    } else if (vm.coverLetterModel.template.length > 1000000) {
      dialog.showDialog('error', { locals: { Error: {
          heading: 'Saving Template',
          title: 'Failed!',
          message: 'Letter template is too big.'
        }}}
      ).catch(noop)
    } else {

      notificationUtils.onSave(()=> RfpHotelBidRepository.updateBidCoverLetterTemplate(vm.bidId, vm.coverLetterModel.template))
        .then(viewLetter)
        .catch(noop);
    }
  }

  function onCoverLetterChanged($event){
    vm.coverLetterModel.template = $event.letter;
  }

  function viewLetter(){
    utils.go('hotelRfp.bid.coverLetter.view');
  }
}
