<script>
  import Item from './NavigationMinimizedLevel1MenuItem.svelte';

  export let menu = [], minimized, before;
</script>

<div class="Heading">
  <div>
    <button class="aBtn atDark asLarge asIcon PinButton" data-icon="play_for_work" data-icon-rotate="270"
            on:click={() => minimized = false}></button>
  </div>
</div>

{#if before}
  <svelte:component this={before} minimized="{true}"/>
{/if}

<div class="Menu">
  {#each menu as item}
    <Item {item} />
  {/each}
  <slot name="after" />
</div>


<style lang="stylus">.Heading {
  height: 96px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  flex: 0 0 auto;
}
.PinButton {
  margin: 10px 5px;
  background: transparent !important;
  color: #fff !important;
  border-color: transparent !important;
}
.PinButton:hover {
  opacity: 0.8;
}
.Menu {
  flex: 1 1 100%;
}
</style>
