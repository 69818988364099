/**
 * Created by DejanK on 11/4/2016.
 */

import { assign } from 'lodash'

export default RbInputTemplateService

function RbInputTemplateService(){
  const $service = this;

  $service.readDefaultAttributes = readDefaultAttributes;
  $service.textLengthTemplate = textLengthTemplate;
  $service.ngRequiredTextTemplate = ngRequiredTextTemplate;
  $service.urlTemplate = urlTemplate;
  $service.selectTemplate = selectTemplate;
  $service.ngRequiredSelectTemplate = ngRequiredSelectTemplate;
  $service.integerTemplate = integerTemplate;
  $service.doubleTemplate = doubleTemplate;

  // eslint-disable-next-line complexity
  function readDefaultAttributes(attributes, modifiers){
    const options = assign({}, attributes, modifiers)
    return {
      name: options.name || 'noName',
      label: !!options.rbLabel ? '{{$ctrl.rbLabel}}' : options.label || '',
      placeholder: options.placeholder || options.label || '',
      required: options.required === 'required' ? 'required="required"' : '',
      rbReadonly: !!options.rbReadonly ? 'rb-readonly="$ctrl.rbReadonly"' : '',
      hint: options.hint ? `[${options.hint}]` : '',
      minlength: options.minlength ? `ng-minlength="${options.minlength}"` :'',
      maxlength: options.maxlength ? `ng-maxlength="${options.maxlength}"` :'',
      pattern: options.pattern ? `ng-pattern="${options.pattern}"` :'',
      rbMax: options.rbMax ? `rb-max="${options.rbMax}"` : '',
      rbMin: options.rbMin ? `rb-min="${options.rbMin}"` : ''
    }
  }

  function textLengthTemplate(options){
    return `
<div class="rb-input" ng-form="${options.name}" ng-class="{'focused':focused, 'touched': ${options.name}.${options.name}.$touched}">
  <label>${options.label}</label>
  <div class="hint">${options.hint}</div>
  <input type="text" name="${options.name}" placeholder="${options.placeholder}" ng-model="$ctrl.model" ${options.required}
  ${options.maxlength} ${options.minlength} ${options.pattern}
  ng-focus="focused=1" ng-blur="focused=0">
  <div class="errors-container">
    <div ng-messages="${options.name}.${options.name}.$error" class="errors">
      <div ng-message="required">${options.label} is required!</div>
      <div ng-message="minlength">${options.label} is too short!</div>
      <div ng-message="maxlength">${options.label} is too long!</div>
      <div ng-message="pattern">${options.label} is in invalid format!</div>
    </div>
  </div>
</div>`
  }

  function ngRequiredTextTemplate(options){
    return `
<div class="rb-input" ng-form="${options.name}" ng-class="{'focused':focused, 'touched': ${options.name}.${options.name}.$touched}">
  <label>${options.label}</label>
  <div class="hint">${options.hint}</div>
  <input type="text" name="${options.name}" placeholder="${options.placeholder}" ng-model="$ctrl.model" ng-required="$ctrl.required"
  ng-maxlength="${options.maxlength}" ${options.minlength} ${options.pattern}
  ng-focus="focused=1" ng-blur="focused=0">
  <div class="errors-container">
    <div ng-messages="${options.name}.${options.name}.$error" class="errors">
      <div ng-message="required">${options.label} is required!</div>
      <div ng-message="minlength">${options.label} is too short!</div>
      <div ng-message="maxlength">${options.label} is too long!</div>
    </div>
  </div>
</div>`
  }

  function urlTemplate(options){
    return `
<div class="rb-input" ng-form="${options.name}" ng-class="{'focused':focused, 'touched': ${options.name}.${options.name}.$touched}">
  <label>${options.label}</label>
  <div class="hint">${options.hint}</div>
  <input type="url" name="${options.name}" placeholder="${options.placeholder}" ng-model="$ctrl.model" ${options.required}
  rb-url-input-helper rb-url-validator 
  ng-focus="focused=1" ng-blur="focused=0">
  <div class="errors-container">
    <div ng-messages="${options.name}.${options.name}.$error" class="errors">
      <div ng-message="required">${options.label} is required</div>
      <div ng-message="minlength">${options.label} is too short</div>
      <div ng-message="maxlength">${options.label} is too long</div>
      <div ng-message="url">Invalid URL</div>
      <div ng-message="rbUrlValidator">Invalid URL</div>
    </div>
  </div>
</div>`
  }

  function selectTemplate(options){
    return `
<div class="rb-input" ng-form="${options.name}" ng-class="{'focused':focused, 'touched': ${options.name}.${options.name}.$touched}">
  <label>${options.label}</label>
  <div class="hint">${options.hint}</div>
  <rb-select ng-model="$ctrl.model" name="${options.name}" ${options.required} rb-readonly="$ctrl.rbReadonly" ng-change="$ctrl.checkValue()">
    <rb-option ng-repeat="${options.repeat}" value="${options.value}">
      ${options.option}
    </rb-option>
  </rb-select>
  <div class="errors-container">
    <div ng-messages="${options.name}.${options.name}.$error" class="errors">
      <div ng-message="required">${options.label} is required!</div>
    </div>
  </div>
</div>
`;
  }

  function ngRequiredSelectTemplate(options){
    return `
<div class="rb-input" ng-form="${options.name}" ng-class="{'focused':focused, 'touched': ${options.name}.${options.name}.$touched}">
  <label>${options.label}</label>
  <div class="hint">${options.hint}</div>
  <rb-select ng-model="$ctrl.model" name="${options.name}" ${options.required} ng-required="$ctrl.required" rb-readonly="$ctrl.rbReadonly">
    <rb-option ng-repeat="${options.repeat}" value="${options.value}">
      ${options.option}
    </rb-option>
  </rb-select>
  <div class="errors-container">
    <div ng-messages="${options.name}.${options.name}.$error" class="errors">
      <div ng-message="required">${options.label} is required!</div>
    </div>
  </div>
</div>
`;
  }

  function integerTemplate(options){
    return `
<div class="rb-input" ng-form="${options.name}" ng-class="{'focused':focused, 'touched': ${options.name}.${options.name}.$touched}">
  <label>${options.label}</label>
  <div class="hint">${options.hint}</div>
  <input type="text" name="${options.name}" placeholder="${options.placeholder}" ng-model="$ctrl.model"
  ${options.required} ${options.rbMax} ${options.rbMin} ng-pattern=/^-?[0-9]+$/
  ${options.minlength} ${options.maxlength}
  ng-focus="focused=1" ng-blur="focused=0">
  <div class="errors-container">
    <div ng-messages="${options.name}.${options.name}.$error" class="errors">
      <div ng-message="required">${options.label} is required!</div>
      <div ng-message="rbMin">${options.label} is below minimum!</div>
      <div ng-message="minlength">${options.label} is below minimum!</div>
      <div ng-message="rbMax">${options.label} is above maximum!</div>
      <div ng-message="maxlength">${options.label} is above maximum!</div>
      <div ng-message="pattern">${options.label} is not a valid number!</div>
    </div>
  </div>
</div>`
  }

  function doubleTemplate(options){
    return `
<div class="rb-input" ng-form="${options.name}" ng-class="{'focused':focused, 'touched': ${options.name}.${options.name}.$touched}">
  <label>${options.label}</label>
  <div class="hint">${options.hint}</div>
  <input type="text" name="${options.name}" placeholder="${options.placeholder}" ng-model="$ctrl.model"
  ${options.required} ${options.rbMax} ${options.rbMin} ng-pattern=/^-?[0-9]+[.]?[0-9]*$/
  ${options.minlength} ${options.maxlength}
  ng-focus="focused=1" ng-blur="focused=0">
  <div class="errors-container">
    <div ng-messages="${options.name}.${options.name}.$error" class="errors">
      <div ng-message="required">${options.label} is required!</div>
      <div ng-message="rbMin">${options.label} is below minimum!</div>
      <div ng-message="minlength">${options.label} is below minimum!</div>
      <div ng-message="rbMax">${options.label} is above maximum!</div>
      <div ng-message="maxlength">${options.label} is above maximum!</div>
      <div ng-message="pattern">${options.label} is not a valid number!</div>
    </div>
  </div>
</div>`
  }
}
