/**
 * Created by DejanK on 9/29/2017.
 */
import HTML from './edit-final-agreement.html'
import { get, clone, isEqual, noop } from 'lodash'

export { HTML, RfpFinalAgreementEditController as Controller }

RfpFinalAgreementEditController.$inject = ['NotificationUtils', 'RfpHotelRfpRepository', 'DataServer', '$state', '$q', 'rbDialog'];
function RfpFinalAgreementEditController(notificationUtils, rfpHotelRfpRepository, dataServer, $state, $q, dialog){
  const vm = this
  let originalTemplate;

  vm.save = save;
  vm.cancel = toView;
  vm.onModelChanged = onModelChanged;

  $onInit();

  function $onInit(){
    vm.rfpId = $state.params.rfpId;

    notificationUtils.onLoad(()=>{
      return $q.all({
        template: rfpHotelRfpRepository.getRfpFinalAgreementTemplate(vm.rfpId)
          .then(httpResponse => {
            originalTemplate = get(httpResponse, 'data.letter');
            return clone(originalTemplate)
          }),
        placeholders: dataServer.getHotelRfpFinalAgreementPlaceholders()
      });
    }, vm, 'model' )
  }


  function save(){
    const modelTemplate = vm.model.template;

    if (modelTemplate.length > 1000000) {
      showTemplateTooBigError()
    } else if(isEqual(originalTemplate, modelTemplate)){
      toView()
    } else {
      saveTemplate()
    }
  }

  function onModelChanged($event){ vm.model.template = $event.letter }

  function toView(){ $state.go('hotelRfp.rfp.finalAgreement.view') }

  function showTemplateTooBigError() {
    dialog.show('error', {
        locals: {
          Error: {
            heading: 'Saving Template',
            title: 'Failed!',
            message: 'Letter template is too big.'
          }
        }
      }
    ).catch(noop)
  }

  function saveTemplate() {
    notificationUtils.onSave(() => {
      return rfpHotelRfpRepository.updateRfpFinalAgreementTemplate(vm.rfpId, vm.model.template)
    }).then(toView, noop);
  }
}
