/**
 * Created by DejanK on 7/21/2017.
 */
import angular from 'angular';
import Negotiations from './negotiations';

import rbBidManagerCustomActions from './rb-bid-manager-custom-actions/rb-bid-manager-custom-actions.cmp';
import rbBidManagerFilterBy from './rb-bid-manager-filter-by/rb-bid-manager-filter-by.cmp';
import RbSvBidManagerPanel from './rb-bid-manager-with-negotiations-new/rb-sv-bid-manager-panel.cmp';
import rbBidManagerActions from './rb-bid-manager-actions/rb-bid-manager-actions.cmp';
import rbBidManagerQuickFilter from './rb-bid-manager-quick-filter/rb-sv-bid-manager-quick-filter.cmp';
import rbBidManagerGroupBy from './rb-bid-manager-group-by/rb-bid-manager-group-by.cmp';
import rbBidManagerActionsList from './rb-bid-manager-actions-list/rb-bid-manager-actions-list.cmp';

export default angular.module('rb.rfp.bidManager.main.ui', [ Negotiations ])
    .component('rbSvBidManagerPanel', RbSvBidManagerPanel)
    .component('rbBidManagerGroupBy', rbBidManagerGroupBy)
    .component('rbBidManagerFilterBy', rbBidManagerFilterBy)
    .component('rbBidManagerActions', rbBidManagerActions)
    .component('rbBidManagerQuickFilter', rbBidManagerQuickFilter)
    .component('rbBidManagerActionsList', rbBidManagerActionsList)
    .component('rbBidManagerCustomActions', rbBidManagerCustomActions)
