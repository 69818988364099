<template>
  <select-rates :bidId="bidId" @cancel="cancel" @hide="hide" @submit="hide">
    <div slot="header">
      <h1 :class="$style.headerMain">Edit Accepted Rates</h1>
    </div>
  </select-rates>
</template>

<script>
import selectRates from './SelectFinalAgreementRates.vue'

export const EDIT = 'EDIT';

export default {
  name: 'rbv-hotel-rfp-edit-selected-rates-dialog',
  components: { selectRates },
  props: {
    'bidId': {
      required: true,
      type: String
    },
  },
  methods: {
    cancel ( reason ) { this.$emit('cancel', reason) },
    hide ( reason ) { this.$emit('hide', reason) }
  }
}
</script>
<style lang="stylus" module="">
.headerMain {
  color : #546E7A;
  text-shadow : 1px 0 0 rgba(0, 0, 0, 0.15);
  font-weight: normal;
  line-height 1
  height 30px;
  font-size : 16px;
  width: 200px;
}

</style>
