import angular from 'angular'

import BidManager from './bid-manager/rfp.bid-manager.module'
import Auth from '../auth'
import RfpManager from './rfp-manager/main/rfp.rfp-manager.module';

export default angular.module('rb.rfp', [
  BidManager.name,
  Auth,
  RfpManager.name
])

