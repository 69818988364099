<template>
  <div class="questionnaire">
    <questionnaire-form-module
      v-for="module in modelCells" :key="module.id"
      :config = "questionnaire.config"
      :globals = "questionnaire.globals"
      :model="module"
      :response="questionnaire.response"
      :template="getTemplate(module.id)"
      @change="$rbEmit"
      @stateChange="$rbEmit"
    />
  </div>
</template>

<script>
  import RbEventMixin from '../../_mixins/RbEventsMixin.vue'
  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  import QuestionnaireFormModule from './QuestionnaireFormModule.vue'

  export default {
    name: 'rb-questionnaire-form',

    mixins: [ RbEventMixin, QuestionnaireMixin ],

    components: { QuestionnaireFormModule },

    props: [ 'questionnaire' ],

    computed: {
      modelCells () {
        return this.questionnaire.model.cells
      }
    },

    mounted() {
      const event = new Event('QuestionnaireReady');
      document.dispatchEvent(event);
    },
  }
</script>

<style lang="stylus">

  .questionnaire {
    background: white;
    box-shadow: 0 0 9px rgba(35, 31, 32, 0.3)
    overflow: auto;
  }

</style>
