<template>
  <div v-if="!preview || model" class="questionnaireEditQuestion" :class="classes">
    <div
      @click.prevent="leftClickHandler()"
      @contextmenu.prevent="rightClickHandler()">

      <label class="questionnaireEditQuestionLabel">
        <span class="questionnaireEditQuestionLabelOrd" :class="{ 'locked': updatedTemplate.locked && !preview }">{{updatedTemplate.ord}}.</span>
        <span>{{updatedTemplate.name}}</span>
      </label>

      <div v-if="isList">
        <template v-for="option in updatedTemplate.options">
          <label class="rbCheckbox">
          <span class="rbCheckboxLabel questionnaireEditQuestionListCheckbox">
           {{option.label}} <span v-if="isUserDefined">({{option.value}})</span>
          </span>
          </label>
        </template>
      </div>

      <input v-else="" class="questionnaireEditQuestionInput" readonly="readonly" />

      <tooltip
        :ord="updatedTemplate.ord"
        :title="updatedTemplate.name"
        :description="updatedTemplate.description"
        :canEdit="!preview && !isUserDefined"
        :isEditUserDefined="!preview && isUserDefined"
        :locked="!!updatedTemplate.locked"
        :required="!!isRequired"
        :added="isAdded"
      />
    </div>
  </div>
</template>

<script>
  import { noop } from 'lodash'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import Tooltip from '../tooltips/QuestionnaireTooltip.vue'
  import { Dialog } from "root/v-app/rbServices";
  import EditUserDefinedQuestionsDialog from 'vRoot/questionnaire/edit/QuestionnaireEditUserDefinedQuestionDialog.vue'

  export default {
    name: 'rb-questionnaire-edit-question-default',

    components: { Tooltip },

    mixins: [ RbEventsMixin ],

    props: [ 'model', 'preview', 'template' ],

    computed: {
      classes () {
        return Object.assign( this.templateClasses, {
          required: this.isRequired,
          "question-type-list": this.isList,
          removed: !this.isAdded,
          locked: this.template.locked,
          preview: this.preview
        } )
      },

      isAdded () { return !!this.model },

      isList () { return this.updatedTemplate.type === 'LIST' },

      isUserDefined () { return this.template.type === 'USER_DEFINED' },

      isRequired () { return this.isAdded && (this.updatedTemplate.req || this.model.req || (this.model.validations && this.model.validations.required) || false) },

      templateClasses () {
        return this.updatedTemplate.classes ? toObject(this.updatedTemplate.classes) : {}

        function toObject(classes) {
          const cl = {}
          classes.split(' ').forEach( (key) => { cl[key] = true } )
          return cl
        }
      },

      updatedTemplate () { return this.isUserDefined ? Object.assign({}, this.template, this.model) : this.template }
    },

    methods: {
      leftClickHandler () {
        if(!(this.updatedTemplate.locked || this.preview)){
          if(this.isUserDefined){
            Dialog.show(EditUserDefinedQuestionsDialog, { template: this.template, model:this.model })
              .then( reason => {
                if(reason === 'REMOVED') {
                  this.$rbEmit({
                    name: 'userDefinedRemoved',
                    questionId: this.template.id,
                    questionTemplate: this.template
                  })
                } else {
                  this.$rbEmit(reason, { name: 'userDefinedChanged' })
                }
              })
              .catch( noop )
          } else {
            this.$rbEmit({
              name: this.isAdded ? 'questionRemoved' : 'questionAdded',
              questionId: this.template.id,
              questionTemplate: this.template
            })
          }
        }
      },
      rightClickHandler () {
        if(!(this.template.locked || this.preview) && this.isAdded ) {
          this.$rbEmit({
            name: 'questionRequiredChanged',
            questionId: this.template.id,
            questionTemplate: this.template,
            value: !this.isRequired
          })
        }
      },
    }
  }
</script>

<style lang="stylus">

  .questionnaireEditQuestion {
    padding: 3px 3px 2px;
    font-size: 10px;
    font-weight: 400;
    color: #78909c;

    min-width: 0;
    min-height: 43px;
    box-sizing: border-box;
    border: 1px solid #cfd8dc;
    margin: 2px;

    background white

    flex: 17 1 17%;

    cursor pointer

    &.question-size-1 {
      flex: 10 1 10%;
    }

    &.question-size-2 {
      flex: 17 1 17%;
    }

    &.question-size-3 {
      flex: 25 1 25%;
    }

    &.question-size-4 {
      flex: 33 1 33%;
    }

    &.question-type-list {
      flex: 1 1 auto;

      .questionnaireEditQuestionLabel {
        max-width 200px;
      }
    }

    &.required {
      background-color: #fefcd3;
    }

    &.removed {
      background-color: #eceff1
      color: #b0bec5

      .questionnaireEditQuestionInput {
        border-color: #cfd8dc !important;
        background: #cfd8dc !important;
      }

      .questionnaireEditQuestionListCheckbox:before {
        border-color: #cfd8dc !important;
        background: #cfd8dc !important;
      }
    }

    &.locked, &.locked .questionnaireEditQuestionListCheckbox {
      cursor not-allowed !important
    }

    &.preview, &.preview .questionnaireEditQuestionListCheckbox {
      cursor default !important
    }
  }

  .questionnaireEditQuestionLabel {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 12px;
    margin: 2px 0;
    cursor inherit
  }

  .questionnaireEditQuestionLabelOrd {
    padding: 0 2px 0 3px;
    border-radius: 3px;
    background transparent


    &.locked {
      background: #546e7a;
      color: #fff;
    }
  }

  .questionnaireEditQuestionInput {
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #dcdee0;
    border-radius: 2px;
    width: 100%;
    height: 17px;
    padding: 0 3px;
    font-size: 11px;
    line-height: 15px;
    background: #fff;
    color: #b0bec5;
    cursor inherit
  }

  .questionnaireEditQuestionListCheckbox {
    cursor inherit !important
  }

</style>

