/**
 * Created by DejanK on 1/11/2017.
 *
 * rbMax
 *
 * Validates that number is not greater of given value
 */

'use strict';

module.exports = rbMax;

function rbMax() {
  return {
    require: '?ngModel',
    link: (s, e, attrs, ngModelCtrl)=>{
      let max = parseFloat(attrs.rbMax);
      if(!isNaN(max)){
        if(ngModelCtrl){
          ngModelCtrl.$validators.rbMax = (modelValue, viewValue)=>{
            let v = parseFloat(modelValue || viewValue);
            return isNaN(v) || v <= max;
          }
        }
      } else {
        throw `RbMax requires a number but got ${attrs.rbMax}`;
      }
    }
  }
}
