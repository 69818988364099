export default function EmailApi(api) {

  Object.assign(api, {
    emailAddress: {
      isDeliverable(emailAddress) {
        if(!emailAddress) return Promise.resolve(false);

        return api.server.post('email-address/is-deliverable', {emailAddress});
      },

      isAvailable(emailAddress){
        if(!emailAddress) return Promise.resolve(false);

        return api.server.post('email-address/is-available', {emailAddress});
      },

      setFixServiceSubscription(subscriptionEnabled, comment){
        return api.server.put('email-address/fix-email-address-subscription', {value: subscriptionEnabled, comment})
      }
    }});
}
