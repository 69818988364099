/**
 * Created by DejanK on 11/15/2016.
 */

export default {
  template: templateFn,
  bindings: {
    model: '=',
    rbLabel: '<'
  }
}

templateFn.$inject = ['$attrs', 'RbInputTemplate'];
function templateFn($attrs, rbInputTemplate){
  return rbInputTemplate.textLengthTemplate(rbInputTemplate.readDefaultAttributes($attrs, {
    maxlength: 250
  }))
}
