/**
 * Created by DejanK on 7/6/2017.
 *
 */

export default TaxesFactory;

TaxesFactory.$inject = [];
function TaxesFactory(){

  const taxesConfig = { name: 'Taxes Breakdown', abbr: 'TX'};

  function TaxesSummary(taxesData, negotiation, negotiations){
    Object.assign(this, taxesData, taxesConfig);
    this.negotiation = negotiation;
    this.negotiations = negotiations;
  }

  TaxesSummary.prototype.getTaxes = getTaxes;

  TaxesSummary.prototype.getValue = function(){
    const taxes = {}, taxesArray = this.getTaxes();
    for(let i=0, il=taxesArray.length; i<il; i++){
      const tax = taxesArray[i], taxValue = this[tax.id];
      if(taxValue) {
        taxes[tax.id] = taxValue;
      }
    }
    return taxes;
  };

  TaxesSummary.prototype.getFormattedValue = function(tax){
    return tax.type === 'PERCENTAGE'
      ? this.negotiations.percentageFormatter.format(tax.value)
      : this.negotiations.currencyFormat.format(tax.value);
  };

  TaxesSummary.prototype.getFormattedValueById = function(taxId){
    const tax = this[taxId] || {};
    return tax.type === 'PERCENTAGE'
      ? this.negotiations.percentageFormatter.format(tax.value)
      : this.negotiations.currencyFormat.format(tax.value);
  };

  TaxesSummary.prototype.getFormattedAmountById = function(taxId){
    const tax = this[taxId] || {};
    return `(${this.negotiations.currencyFormat.format(tax.amount)})`;
  };

  TaxesSummary.prototype.isAvailable = function(taxId){
    const tax = this[taxId];
    return tax && tax.valid && tax.type !== 'UNAVAILABLE';
  };

  TaxesSummary.prototype.isIncluded = function(taxId){
    const tax = this[taxId];
    return tax && tax.included;
  };

  TaxesSummary.prototype.getFormattedTotal = function(){
    return this.getFormattedValue(this.total || {});
  };

  function getTaxes(){
    return [
      {id: 'lodging', name: 'Lodging Tax'},
      {id: 'state', name: 'State Tax'},
      {id: 'city', name: 'City Tax'},
      {id: 'vatGstRm', name: 'VAT/GST'},
      {id: 'vatGstFb', name: 'VAT/GST on food and beverage'},
      {id: 'service', name: 'Service Fee'},
      {id: 'occupancy', name: 'Occupancy Tax'},
      {id: 'other', name: 'Other Tax/Fee'}
    ];
  }

  return {
    create: (taxesData, negotiation, negotiations)=>{
      return new TaxesSummary(taxesData, negotiation, negotiations);
    },
    list: getTaxes
  }
}
