<template>
  <div class="questionnaireTableCellInputContainer" :class="classes">

    <question-date v-if="template.type === 'DATE'"
                   class="questionnaireTableCellInput"

                   :id="template.id"
                   :name="template.id"
                   :placeholder="template.placeholder"

                   :value="vModel"
                   :readonly="!!template.readonly"
                   v-validate="validations"

                   @blur="onChange"
    />

    <question-text v-else=""
                   class="questionnaireTableCellInput"

                   :id="template.id"
                   :name="template.id"
                   :placeholder="template.placeholder"

                   :value="vModel"
                   :readonly="!!template.readonly"
                   v-validate="validations"

                   @input="onChange"
    />

    <tooltip
      :ord="template.ord"
      :title="template.name"
      :description="template.description"
      :required="!!isRequired"
      :error="showErrors && errors.first(template.id)"
    />

  </div>
</template>

<script>
  import QuestionnaireQuestionValidationMixin from '../mixins/QuestionnaireQuestionValidationMixin.vue'
  import QuestionDate from './QuestionnaireFormQuestionDate.vue'
  import QuestionText from './QuestionnaireFormQuestionText.vue'
  import Tooltip from '../tooltips/QuestionnaireTooltip.vue'

  export default {
    name: 'rb-questionnaire-form-question',

    mixins: [ QuestionnaireQuestionValidationMixin ],

    components: {
      QuestionDate,
      QuestionText,
      Tooltip
    },

    props: [ 'globals', 'model', 'response', 'template' ],

    computed: {
      updatedTemplate () { return this.template }
    }
  }
</script>

<style lang="stylus">

  .questionnaireTableCellInput {
    width: 75px;
    min-width: 100%;
    height: 26px;
    background: white;
    border: 1px solid #DCDEE0;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 26px;
    padding: 0 3px;
    color: #546E7A;
    font-family: "Noto Sans";

    &::-webkit-input-placeholder {
      color: #B0BEC5;
    }

    &:-moz-placeholder {
      color: #B0BEC5;
    }

    &:-ms-input-placeholder {
      color: #B0BEC5;
    }
  }

  .questionnaireTableCellInputContainer {
    padding 3px

    &.required {
      background-color: #fefcd3
    }

    &.error {
      background-color: #f15a24;
    }
  }

</style>
