import Moment from 'moment';

export default function formatDate( s = '', format = 'MM/DD/YYYY'){
  const m = Moment(s);
  return m.isValid() ? m.format(format) : s;
}

export function toISO(date = new Date()){
  return date.toISOString().slice(0, 10);
}

export function getGsaStartDate() {
  const 
    currentDate = new Date(),
    currentYear = currentDate.getFullYear(),
    cutoffDate = new Date('8/01/' + currentYear);

  if (currentDate >= cutoffDate) {
    return currentYear + '-10-01';
  }

  return (currentYear - 1) + '-10-01';
}

export function getGsaEndDate() {
  const 
    currentDate = new Date(),
    currentYear = currentDate.getFullYear(),
    cutoffDate = new Date('8/01/' + currentYear);

  if (currentDate >= cutoffDate) {
    return (currentYear + 1) + '-09-30';
  }

  return currentYear + '-09-30';
}