<template>
  <rbv-dropdown
    :is-open="opened"
    backdrop-style="transparent"
    @close="opened = false"
    :class="$style.dropdown"
  >
    <div slot="toggler">
      <rb-input
        for-id="search_address"
        :class="$style.searchBar"
      >
        <label slot="label">
          Address Search
        </label>
        <div :class="$style.searchBar">
          <input
            id="search_address"
            class="rbInput"
            :class="$style.searchBarInput"
            autocomplete="off"
            placeholder="Search for Address"
            :value="viewValue"
            @focus="opened = true"
            @blur="opened = false"
            @input="onQueryChange"
          >

          <div :class="$style.searchBarIconContainer">
            <rb-loading
              v-if="loading"
              class="small"
              :class="$style.searchBarLoading"
            />
            <i
              v-else
              class="material-icons"
              :class="$style.searchBarIcon"
            >search</i>
          </div>
        </div>
      </rb-input>
    </div>

    <div
      v-show="opened && viewValue && viewValue.length"
      slot="dropdown"
      class="dropdownContainer dropdown-content"
      :class="$style.dropdownContainer"
    >
      <div :class="$style.scrollContainer">
        <div
          v-for="prediction in predictions"
          :key="prediction.id"
          :class="$style.address"
          @mousedown="onSelected(prediction)"
        >
          <i
            class="material-icons"
            :class="$style.addressIcon"
          >location_on</i> {{ prediction.description }}
        </div>
      </div>

      <div
        v-if="showForm === 0"
        :class="$style.addManuallyBox"
      >
        <div :class="$style.addManuallyText">
          <i
            class="material-icons"
            :class="$style.addManuallyIcon"
          >info</i>
          If you are unable to find the address you can always add it manually
        </div>
        <button
          class="rb-button primary"
          @mousedown="onAddManually"
        >
          <i class="material-icons">add_box</i>Add Manually
        </button>
      </div>

      <div
        v-else-if="!(predictions && predictions.length)"
        :class="$style.noResultsBox"
      >
        <div :class="$style.noResultsText">
          <i
            class="material-icons"
            :class="$style.noResultsIcon"
          >info</i>
          No results!
        </div>
      </div>
    </div>
  </rbv-dropdown>
</template>

<script>
  import {GoogleMapsGeocode, debouncedGooglePlacesAutocomplete} from 'root/shared/tools/google/googleProvider';
  import {parseGooglePlaces} from 'root/shared/tools/google/googlePlaceParser';
  import { clone, noop } from 'lodash';
  import RbEventsMixin from 'root/v-components/_mixins/RbEventsMixin.vue'
  import RbvDropdown from 'vRoot/_core/RbDropdown.vue';
  import RbLoading from 'vRoot/_core/RbvLoading.vue';
  import RbInput from 'vRoot/_core/RbInput.vue';

  export default {
    name: 'RbvAddressAutoComplete',

    components: { RbvDropdown, RbLoading, RbInput },
    mixins: [ RbEventsMixin],

    props: {
      query: {
        type: String,
        default: ''
      },
      showForm: {}
    },
    data(){
      return {
        opened: false,
        viewValue: undefined,
        modelValue: undefined,
        selected: undefined,
        loading: false,
        getPredictionsPromise: null
      }
    },
    asyncComputed: {
      predictions () {
        if(this.viewValue && this.viewValue.length){
          this.loading = true
          return this.getPredictions({ input: this.viewValue, types: [ 'address' ]})
            .finally(() => { this.loading = false })
        } else {
          return Promise.resolve([])
        }
      }
    },
    watch: {
      query: {
        immediate: true,
        handler (val) {
          this.modelValue = val
          this.modelFormat()
          this.modelRender()
        }
      }
    },
    methods: {
      // model methods
      modelFormat() {},
      modelRender() { this.viewValue = clone(this.modelValue) },
      modelParse() {
        this.loading = true
        return GoogleMapsGeocode({'placeId': this.selected.place_id})
          .then( parseGooglePlaces )
          .then( results => { this.modelValue = results } )
          .finally( () => { this.loading = false })
      },
      modelEmit() {
        this.$emit('input', this.modelValue);
        this.$emit('change', this.modelValue);
      },

      onQueryChange(e){
        this.viewValue = e.target.value;
      },
      onSelected(prediction){
        this.selected = prediction
        this.modelParse()
          .then(this.modelEmit, noop)
          .finally( () => { this.opened = false } )
      },
      onAddManually(){
        this.$emit('addManually')
      },
      getPredictions: debouncedGooglePlacesAutocomplete(300)
    },
  }
</script>

<style lang="stylus" module="">

  .searchBar {
    position relative
  }

  .searchBarInput {
    padding-right: 40px !important;
  }

  .searchBarIconContainer {
    position: absolute;
    right: 20px;
    top: 0;
    height: 36px;
    line-height: 34px;
  }

  .searchBarIcon {
    font-size: 20px;
    vertical-align: middle;
    color: #455a64
  }

  .searchBarLoading {
    transform: scale(1.5);
  }

  .dropdownContainer {
    background #fff;
    border: 1px solid #CFD8DC;
    border-radius: 3px;
    margin-top: -12px;
    box-shadow : 0 0 3px rgba(0, 0, 0, 0.18);
  }

  .address {
    border-bottom: 1px solid #ECEFF1;
    cursor: pointer;
    font-size : 12px;
    line-height : 32px;
    color : #546E7A;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;

    &:hover {
      background-color: #b7e7e3;
    }
  }

  .addressIcon {
    vertical-align: middle;
    font-size: 18px;
    color: #B0BEC5;
    margin: 0 8px;
  }

  .addManuallyBox {
    padding: 5px 15px 5px 5px;
    display flex
  }

  .addManuallyText {
    font-size: 12px;
    line-height: 36px;
    color: #90A4AE;
    flex: 1
  }

  .addManuallyIcon {
    vertical-align: middle;
    font-size: 18px;
    margin: 0 8px;
  }

  .noResultsBox {
    composes addManuallyBox
  }

  .noResultsText {
    composes addManuallyText
  }

  .noResultsIcon {
    composes addManuallyIcon
  }
</style>
