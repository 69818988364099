/**
 * Created by DejanK on 7/10/2017.
 */
import './rb-negotiations-edit.scss';
import HTML from './rb-negotiations-edit.html';
import { cloneDeep, merge } from 'lodash';
import angular from 'angular';
import NegotiationsFormService from 'vRoot/rfp-hotel/negotiations/NegotiationsFormService.js';

export default {
  template: HTML,
  bindings: {
    config: '<'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', '$scope', 'NegotiationsHtmlFactory', '$rootScope'];
function Ctrl($element, $scope, NegotiationsHtmlFactory, $rootScope)
{
  const vm = this;
  this.$onChanges = onChanges;

  vm.formatDate = formatDate;
  vm.onMouseEnter = onMouseEnter;
  vm.onMouseLeave = onMouseLeave;

  function onChanges(changes) {
    changes.config && vm.config.mode && vm.config.side && vm.config.negotiations && init();
  }

  function formatDate(at) {
    return vm.negotiations.dateTimeFormatter.format(at)
  }

  function onMouseLeave(event) {
    const target = angular.element(event.target);
    target.removeClass('first');
    target.removeClass('second');
    $element.removeClass('highlight-first');
    $element.removeClass('highlight-second');
  }

  function onMouseEnter(event, index) {
    const elementIndex = index === 0 ? 'first' : 'second';
    angular.element(event.target).addClass(elementIndex);
    $element.addClass('highlight-'+elementIndex);
  }

  function init() {
    vm.mode = vm.config.mode;
    vm.negotiations = cloneDeep(vm.config.negotiations);
    vm.side = vm.config.side;
    let lastCommunication, editModel;

    switch(vm.mode){

      case 'CREATE': {
        lastCommunication = vm.negotiations.getLastCommunication(2);
        vm.model = lastCommunication[lastCommunication.length-1].getAllValues();
        break;
      }

      case 'EDIT': {
        editModel = vm.negotiations.removeLastCommunication();

        lastCommunication = vm.negotiations.getLastCommunication(2);
        vm.model = lastCommunication[lastCommunication.length-1].getAllValues();
        break;
      }

      default:
    }

    NegotiationsHtmlFactory.buildNegotiationsEditor(vm.negotiations, lastCommunication, $scope, vm.side)
      .then(html => {
        angular.element($element.children()[0]).replaceWith(html);
        $scope.$applyAsync();

        if(editModel){
          merge(vm.model, editModel.getAllValues());
          vm.model.message = editModel.message;
          vm.model.id = editModel._id;
          $rootScope.$broadcast('RB_NEGOTIATION_CHANGED');
        }

        NegotiationsFormService.setSavedModel(vm.model);
        $scope.$watch(() => vm.model, ( newValue ) => {
          NegotiationsFormService.onFormModelChange(newValue);
        }, true);
      });

    vm.primaryRatePrefix = vm.negotiations.config.rates[0];
    vm.primaryRate = vm.model.rates[vm.primaryRatePrefix+'_s1_rt1'];
    vm.communication = lastCommunication;
  }
}

