<div class="Modal" class:Overflow="{overflow}" style="--width: {width}">
  <slot />
</div>


<script>
  export let
    width = '500px',
    overflow = false;
</script>


<style lang="stylus">.Modal {
  border-radius: 3px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  width: var(--width);
  min-width: var(--width);
  max-height: 95vh;
  max-width: 95vh;
}
.Overflow {
  overflow: visible;
}
</style>