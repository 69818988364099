/**
 * Created by DejanK on 11/4/2016.
 */
import { clone, find } from 'lodash'

export default {
  template: templateFn,
  bindings: {
    model: '='
  },
  controller: Ctrl
};

templateFn.$inject = ['$attrs', 'RbInputTemplate'];
function templateFn($attrs, RbInputTemplate){
  const options = Object.assign(RbInputTemplate.readDefaultAttributes($attrs), {
    repeat: 'country in $ctrl.countries track by country.id',
    value: '::country.id',
    option: '{{::country.name}}'
  })

  return RbInputTemplate.selectTemplate(options)
}

Ctrl.$inject = ['DataServer'];
function Ctrl(dataServer){
  const vm = this
  vm.checkValue = checkValue

  dataServer.getCountries()
    .then( countries => {
      const c = clone(countries)
      c.unshift({id: undefined, name:'-'})
      vm.countries = c
      checkValue()
    })

  function checkValue(){
    const country = find(vm.countries, c => c.id === vm.model )
    if(!country){ vm.model = undefined }
  }
}
