<template functional>
  <div
    :class="$style.message"
    @click="$options.focus()"
  >
    <label for="message">Create Invitation Message</label>
    <div :class="$style.box">
      <div>Hi <b>{{ props.user? `${props.user.firstName}`: '[user]' }}</b>,</div>
      <div
        :class="$style['grow-wrap']"
        :data-replicated-value="props.value"
      >
        <textarea
          id="editEmailMessageTextArea"
          ref="textarea"
          name="message"
          :value="props.value"
          @input="listeners.input($event.target.value)"
          @click.stop
        />
      </div>
      <div :class="$style.footer">
        <div>Thank You,</div>
        <div>{{ props.userAccount.firstName }} {{ props.userAccount.lastName }}</div>
        <div>{{ props.userAccount.getAccount().name }}</div>
        <div>{{ props.userAccount.emailAddress }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditUserEmailMessage',
  functional: true,
  props: {
    value: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: () => null,
    },
    userAccount: {
      type: Object,
      default: () => null,
    }
  },
  focus() {
    document.getElementById('editEmailMessageTextArea').focus();
  }
};
</script>

<style lang="stylus" module>
.message{
  position: relative;
  font-size: 12px;
  margin: 0 !important;
}

.message label{
  position: absolute;
  background: #fff;
  top: -10px;
  left: 5px;
  padding: 0 5px;
  font-size: 13px;
  color: #90a3ad;
}

.box {
  width: 100%;
  border: 1px solid #91a4af;
  font-size: 13px;
  border-radius: 5px;
  margin: 10px 0 !important;
  display: block;
  padding: 15px 0;
  color: #546e7a;
  line-height: 1.7;
  box-sizing: border-box;
  text-align justify;
  resize: none;
  min-height: 300px;
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.box > * {
  padding 0 15px;
}

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
  background rgba(0,0,0,0.04)
  margin: 5px 0;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  font: inherit;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;

  width 100%;
  border: none;
  resize none;
  color inherit;
  font-family inherit;
  min-height: 200px
  background: transparent;
  padding: 5px 0;
}
</style>
