<script>
  /**
   * Textarea will auto-grow to contain input
   */
  import { createEventDispatcher } from 'svelte';

  export let
    value = '',
    placeholder = '',
    readonly;

  const dispatchEvent = createEventDispatcher();

  let visibleText = '';

  $: visibleText = value + '\r\n';

  function dispatch(eventName, value){
    if(!readonly) {
      dispatchEvent(eventName, value)
    }
  }

</script>

<div class="TextAreaInputContainer">
  <div class="TextAreaInputClone">
    { visibleText }
  </div>

  <textarea
    class="TextAreaInput"
    bind:value
    {placeholder}
    readonly="{readonly ? 'readonly' : null}"
    on:input={e => dispatch('input', e.target.value)}
    on:change={e => dispatch('change', e.target.value)}
  />
</div>


<style lang="stylus">.TextAreaInputContainer {
  position: relative;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  min-height: inherit;
  background: transparent;
}
.TextAreaInput {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: 0;
  display: inline-block;
  white-space: pre-wrap;
  overflow: hidden;
  word-break: break-all;
  overflow-wrap: anywhere;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  resize: none;
  padding: inherit;
}
.TextAreaInput::placeholder {
  color: #b0bec5;
}
.TextAreaInputClone {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: 0;
  display: inline-block;
  white-space: pre-wrap;
  overflow: hidden;
  word-break: break-all;
  overflow-wrap: anywhere;
  color: transparent;
}
</style>
