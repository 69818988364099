/**
 * Created by DejanK on 12/22/2016.
 */
import * as Captcha from './captcha/captcha.ctrl'
import * as AskToSignOut from './ask-to-sign-out/ask-to-sign-out.ctrl'
import * as AskToSignUp from './ask-to-sign-up/ask-to-sign-up.ctrl'
import * as AskToSignIn from './ask-to-sign-in/ask-to-sign-in.ctrl'
import * as AskToSwitchAccount from './ask-to-switch-account/ask-to-switch-account.ctrl'
import * as AskToAddAccount from './ask-to-add-account/ask-to-add-account.ctrl'
import * as AskForNewEmail from './ask-for-new-email/ask-for-new-email.ctrl';

export { Dialogs as default }

Dialogs.$inject = ['rbDialogProvider'];
function Dialogs(rbDialogProvider) {
  rbDialogProvider
    .$new( Captcha.NAME, Captcha.HTML, Captcha.Controller )
    .$new( AskToSignOut.NAME, AskToSignOut.HTML, AskToSignOut.Controller )
    .$new( AskToSignUp.NAME, AskToSignUp.HTML, AskToSignUp.Controller )
    .$new( AskToSignIn.NAME, AskToSignIn.HTML, AskToSignIn.Controller )
    .$new( AskToSwitchAccount.NAME, AskToSwitchAccount.HTML, AskToSwitchAccount.Controller )
    .$new( AskToAddAccount.NAME, AskToAddAccount.HTML, AskToAddAccount.Controller )
    .$new( AskForNewEmail.NAME, AskForNewEmail.HTML, AskForNewEmail.Controller )
}

