import {cloneDeep, isEqual, noop} from 'lodash';
import HTML from './edit-cover-letter.html';

export { HTML, RfpNamCoverLetterEditController as Controller }

RfpNamCoverLetterEditController.$inject = ['$state', 'rbDialog', 'NotificationUtils', 'MainAPI', '$q'];
function RfpNamCoverLetterEditController($state, dialog, utils, mainAPI, $q){
  const vm = this;
  let originalCoverLetter;

  vm.save = saveCoverLetter;
  vm.onCoverLetterChanged = onCoverLetterChanged;

  $onInit();

  function $onInit(){
    vm.rfpId = $state.params.rfpId;
    vm.loading = true;

    $q.all({
      template: utils.onLoad( () => mainAPI.getHotelRfpNamCoverLetterTemplate(vm.rfpId) ),
      placeholders: utils.onLoad( () => mainAPI.getHotelRfpNamCoverLetterPlaceholders() )
    })
      .then( items => {
        originalCoverLetter = items.template.data.template;
        vm.coverLetterModel = {
          template: cloneDeep(originalCoverLetter),
          placeholders: items.placeholders
        };
        vm.loading = false;
      })
      .catch(noop);
  }

  function saveCoverLetter(){
    if(isEqual(originalCoverLetter, vm.coverLetterModel.template)){
      goToView();

    } else if (vm.coverLetterModel.template.length > 1000000) {
      displayError();

    } else {
      utils.onSave(() => mainAPI.setHotelRfpNamCoverLetterTemplate(vm.rfpId, vm.coverLetterModel.template))
        .then(goToView)
        .catch(noop);
    }

    function goToView(){
      $state.go('hotelRfp.rfp.namCoverLetter.view');
    }

    function displayError(){
      dialog
        .show('error', { locals: { Error: {
              heading: 'Saving Template',
              title: 'Failed!',
              message: 'Letter template is too big.'
            }}}
        )
        .catch(noop);
    }
  }

  function onCoverLetterChanged($event){
    vm.coverLetterModel.template = $event.letter;
  }
}
