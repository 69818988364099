import {get, startCase, orderBy, isFunction} from 'lodash';

export function groupAndFilter(hotels, grouper, filter, canGroup, bestRateFn, getGroupKey) {
  const includeHotelFn = createIncludeHotelFn();

  if (canGroup) {
    const groups = hotels.reduce((acc, hotel) => {
      if (included(hotel)) {
        const key = getGroupKey(hotel);
        if (acc[key]) {
          acc[key].push(hotel);
        } else {
          acc[key] = [hotel];
        }
      }
      return acc;
    }, {});

    return Object.keys(groups)
      .sort()
      .reduce((acc, key) => {
        const group = groups[key];
        acc.push({key, type: 'group', ids: group.map(({bidId}) => bidId), });
        acc.push(...orderBy(group, ['company.name'], ['asc']))
        return acc;
      }, []);
  } else {
    return orderBy(hotels.filter(included), ['company.name'], ['asc']);
  }

  function included(hotel) {
    return !filter || filter.id == null || includeHotelFn(hotel);
  }

  function createIncludeHotelFn() {
    switch (grouper.id) {
      case 'bestRate':
        return hotel => bestRateFn(hotel) === filter.value;
      case 'distance':
        return hotel => roundRate(get(hotel, grouper.key)) === filter.value;
      default: {
        const fn = isFunction(grouper.key) ? grouper.key : get;
        return hotel => fn(hotel, grouper.key) === filter.value;
      }
    }
  }
}

export function getFilters(hotels, grouper, hotelTypes, bestRateFn) {
  if (grouper.id === null) return [];

  const collection = hotels.reduce((acc, hotel, index) => {
      const {value, text} = makeFilter(hotel, grouper, hotelTypes, bestRateFn);

      if(!acc[text]) {
        acc[text] = {id: `${value}${index}`, text, value};
      }

      return acc;
    }, {});

  return [{ id: null, text: 'None' }, ...orderBy(Object.values(collection), 'text') ];
}

function roundRate(rate) {
    return (Math.round(rate * 100) / 100).toFixed(2)
}

function makeFilter(hotel, grouper, hotelTypes, bestRate) {
    let value = isFunction(grouper.key) ? grouper.key(hotel) : get(hotel, grouper.key),
        text = value? (typeof value === 'string'? startCase(value.toLowerCase()): value): '---';
    switch (grouper.id) {
        case 'distance':
            value = roundRate(value);
            text = `${ value } Mi`;
            break;
        case 'rating': {
            const type = hotelTypes.find(hotelType => hotelType.id === value);
            text = type ? type.name : 'Hotel Property';
            break;
        }
        case 'bestRate':
            text = value = bestRate(hotel);
            break;
        default: break;
    }

    return {text, value};
}
