import HTML from './rb-address-autocomplete.html'
import { noop } from 'lodash'
import {stopEvent} from 'root/shared/tools/view-utils';
import './rb-address-autocomplete.scss'
import {GoogleMapsGeocode, GooglePlacesAutocomplete} from 'root/shared/tools/google/googleProvider';

export default {
  template: HTML,
  bindings: {
    onSelect: '&',
    onAddManually: '&',
    types: '<',
    value: '<',
    showAddManually: '<'
  },
  controller: Controller
}

Controller.$inject = ['NotificationUtils', '$scope']
function Controller(notificationUtils, $scope){
  const vm = this

  vm.config = {
    toggler: {
      element: 'input',
      events: { open: ['focus', 'keydown'], close: 'blur'}
    }
  }

  vm.$onChanges = $onChanges
  vm.stopEvent = stopEvent
  vm.onQueryChange = onQueryChange
  vm.onSelected = onSelected
  vm.addManually = () => { vm.onAddManually() }

  function $onChanges(changes){
    if(changes.value){
      /**
       * if type and value are changed in the same cycle,
       * $timeout delays setting query to after it was deleted by type change
       */
      $scope.$timeout( () => {
        vm.onQueryChange = noop
        vm.query = changes.value.currentValue
        vm.onQueryChange = onQueryChange
      } )
    }

    if(changes.types){
      vm.types = getTypes(changes.types.currentValue)
      vm.query = ''
    }
  }

  function onQueryChange() {
    const query = vm.query;
    if(query && query.length){
      vm.loading = true
      GooglePlacesAutocomplete({ input: query, types: [ vm.types ]})
        .then( (predictions) => {
          $scope.$timeout(()=> {
            if(vm.query === query){
              vm.predictions = predictions || [];
              vm.loading = false;
            }
          })
        }, noop)
    } else {
      vm.predictions = [];
    }
  }

  function onSelected($event, placeId){
    stopEvent($event)
    notificationUtils.onSave( () => {
      return GoogleMapsGeocode({'placeId': placeId})
    })
      .then(results => {
        $scope.$timeout( () => {
          vm.onSelect({$event: { place: results[0] }})
        })
      }, noop)
  }

  function getTypes(types){
    switch(types){
      case 'CITY':
        return '(cities)'
      case 'ADDRESS':
      default:
        return 'address'
    }
  }
}
