<script>
  import RfpsComponent from './RfpsComponent.svelte';
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import formatDate from 'utils/formatDate';
  import {CHAIN_PORTAL_STATE} from 'root/states';
  import {onMount} from 'svelte';
  import {
    $state as stateProvider,
    currentUser,
    httpErrorHandler,
    mainAPI
  } from 'root/angular-injector-provider';
  import noop from 'lodash/noop';

  let state = 'LOADING', rfps = [], formattedRfps = [], rfpsView = [], queryString = '', hrefFn = stateProvider().href;

  $: formattedRfps = format(rfps);
  $: rfpsView = sortAndFilter(formattedRfps, queryString);

  onMount(() => {
    mainAPI().dashboard.listRfps()
      .then(response => {
        rfps = response.data;
        state = rfps.length ? 'READY' : 'EMPTY';
      })
      .catch(() => {
        state = 'ERROR';
      });
  });

  function format(rawRfps = []){
    return rawRfps.map( rfp => ({
      ...rfp,
      ...formatRfp(rfp)
    }))
  }

  function formatRfp(rfp){
    return {
      dueDateF: formatDate(rfp.dueDate, 'l'),
      bookmarkAndYearCompareString: `${rfp.bookmark ? 1 : 0} ${rfp.programYear}`,
      nameAndCompanyCompareString: `${rfp.name} ${rfp.buyerCompanyName}`,
      filterString: `${rfp.programYear} ${rfp.name} ${rfp.buyerCompanyName}`,
      href: hrefFn(CHAIN_PORTAL_STATE, {steps: [`COMPANY-${rfp.buyerCompanyAccountId}`, `RFP-${rfp._id}`], chain:'CHAIN'})
    };
  }

  function sortAndFilter(rawRfps = [], filterQuery = ''){
    const filtered = filter(rawRfps, filterQuery);
    filtered.sort(sortFn)
    return filtered;
  }

  function sortFn(a, b) {
    if(a.bookmarkAndYearCompareString < b.bookmarkAndYearCompareString){
      return 1; // descending
    } else if (a.bookmarkAndYearCompareString > b.bookmarkAndYearCompareString){
      return -1; // descending
    } else if (a.nameAndCompanyCompareString < b.nameAndCompanyCompareString){
      return -1; // ascending
    } else if (a.nameAndCompanyCompareString < b.nameAndCompanyCompareString){
      return 1; // ascending
    } else {
      return 0;
    }
  }

  function filter(rawRfps, filterQuery = ''){
    if(filterQuery === '') return [...rawRfps];
    const rx = new RegExp(filterQuery, 'i');
    return rawRfps.filter(r => rx.test(r.filterString));
  }

  function toggleBookmark(rfpId){
    formattedRfps = formattedRfps.map(rfp => {
      if(rfp._id === rfpId) {
        rfp.bookmark = !rfp.bookmark;
        saveBookmarkChange(rfpId, rfp.bookmark);
        return {...rfp, ...formatRfp(rfp)};
      }
      return rfp;
    })
  }

  function saveBookmarkChange(rfpId, isBookmarked){
    currentUser().get()
      .then(user => {
        const fn = isBookmarked ? mainAPI().rfpBookmarks.add : mainAPI().rfpBookmarks.remove;
        return fn(user.currentUserAccount.id, {rfpId})
      })
      .catch(err => httpErrorHandler().handle(err))
      .catch(noop);
  }
</script>

{#if state === 'LOADING'}
  <div class="FillAndCenter">
    <Spinner />
  </div>
{:else if state === 'ERROR'}
  <div class="FillAndCenter">System encountered an error</div>
{:else if state === 'EMPTY'}
  <div class="FillAndCenter">No RFPs yet</div>
{:else}
  <RfpsComponent rfps="{rfpsView}" bind:queryString {toggleBookmark} />
{/if}

<style lang="stylus">.FillAndCenter {
  color: #78909c;
}
</style>