import Dialog from './Dialog'

class RbvDialogService {
  constructor() {
    this.$$shownDialogs = [];
    this.containerElementId = 'rbv-dialog-container';
    this.containerElement = document.getElementById(this.containerElementId);
    document.addEventListener('STATE_TRANSITION', () => this.cancelAll());
  }

  show(dialogComponent, props, options){
    const dialog = new Dialog( this.containerElement, dialogComponent, props, options );
    this.$$shownDialogs.push(dialog);
    return dialog.promise
  }

  cancel(reason){
    const dialog = this.$$shownDialogs.pop();
    return dialog.cancel(reason)
  }

  cancelAll(){
    while(this.$$shownDialogs.length){
      this.cancel();
    }
  }

  hide(reason){
    const dialog = this.$$shownDialogs.pop();
    return dialog.hide(reason)
  }
}

export default RbvDialogService
