<script>
  import {default as searchData, getDetails, search} from './addressSearch';

  export let
    id = 'locationId',
    label = 'Search for Address',
    error = '',
    setValues = () => {},
    allowAddManually = true,
    onAddManually = () => {},
    required = true,
    requiredErrorMessage = 'Please select address.';

  let
    searchString = '',
    selectedLocation,
    selectedLocationFullAddress = '',
    showLocationForm = false;

  $: search(searchString);

  async function selectAddress(suggestion) {
    if (suggestion) {
      required = null;
      searchString = suggestion.address;

      selectedLocation = await getDetails(suggestion);
      searchString = selectedLocation.fullAddress;
      selectedLocationFullAddress = selectedLocation.fullAddress;
      setLocation(selectedLocation)
    } else {
      required = true;
      selectedLocation = undefined;
    }
  }

  function setLocation(location = {}){
    setValues({
      address: {
        country: 'US',
        state: '',
        region: '',
        county: '',
        city: '',
        zip: '',
        address1: '',
        address2: '',
        ...location.address
      },
      coordinates: {
        lat: '',
        lng: '',
        ...location.coordinates
      },
      fullAddress: location.fullAddress || ''
    });
  }

  function addManually() {
    required = null;
    setLocation();
    onAddManually()
  }
</script>

<div class="Input Search">
  <input type="text"
         class="WithIcon"
         id="{id}"
         name="{id}"
         placeholder="{label}"
         autocomplete="off"
         bind:value={searchString}
         data-value="{selectedLocation}"
         required="{required}"
         required-data-value="{required}"
         required-data-value-message="{requiredErrorMessage}"
  />
  <label>{label}</label>
  <div class="Icon" data-icon-after="search"></div>
  {#if searchString}
    <div class="Menu" >
      {#if !$searchData.results[searchString] || $searchData.results[searchString].loading}
        <div class="Option" >
          <div class="Title">Loading...</div>
        </div>
      {:else}
        <div class="Options">
          {#if $searchData.results[searchString].suggestions}
            {#each $searchData.results[searchString].suggestions as r}
              <div class="Option"
                   class:Selected={selectedLocationFullAddress === r.address}
                   on:mousedown={() => selectAddress(r)}
              >
                <div class="Title">{r.address}</div>
              </div>
            {/each}
          {/if}

          {#if $searchData.results[searchString].loadingMore}
            <div class="Option" >
              <div class="Title">Loading...</div>
            </div>
          {/if}
        </div>
      {/if}

      {#if allowAddManually && !selectedLocation}
        <div class="NotFound">
          <div>
            <div class="Title" data-icon-before="help">Can't find address?</div>
            <div>
              Click on the "Add Manually" button to add address.</div>
          </div>

          <div>
            <button class="aBtn asLarge atDark AddManually" on:mousedown={addManually} >
              Add Manually
            </button>
          </div>
        </div>
      {/if}
    </div>
  {/if}

  <div class="Error">{error || ''}</div>
</div>
