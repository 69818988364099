<script>
  export let item;
  let opened = item.active || item.alwaysOpened;
</script>

{#if item.submenu}
  <button class="MenuItem {item.active ? 'Active': ''}"
    data-icon-before="{item.icon}"
    data-icon-after="{ item.iconAfter || 'keyboard_arrow_' + (opened ? 'up' : 'down') }"
    on:click={() => opened = !opened}>{item.label}</button>

    <div class="Menu {opened ? '' : 'Closed'}">
      {#each item.submenu as subitem}
        <button class="MenuItem {subitem.active ? 'Active': ''} SubMenuItem"
          data-icon-before="{subitem.icon}"
          data-icon-after="{subitem.iconAfter}"
          on:click={() => subitem.action()}>{item.label}</button>
      {/each}
    </div>
{:else}
  <button class="MenuItem {item.active ? 'Active': ''}"
    data-icon-before="{item.icon}"
    data-icon-after="{item.iconAfter}"
    on:click={() => item.action()}>{item.label}</button>
{/if}

<style lang="stylus">.MenuItem {
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: 0;
  font-size: 13px;
  color: #b0bec5;
  opacity: 0.5;
  background: transparent;
  border-color: transparent;
  box-sizing: border-box;
  padding: 0 14px;
  margin: 0;
  border-left: 0;
  border-right: 0;
  white-space: nowrap;
  height: 36px;
  opacity: 0.7;
  padding: 0 14px 0 32px;
}
.MenuItem:before {
  margin-right: 15px;
}
.MenuItem:after {
  margin-left: auto;
}
.MenuItem:hover {
  opacity: 0.7;
}
.MenuItem:focus {
  border-color: #b0bec5;
}
.MenuItem.Active {
  background: #00b8ab;
  color: #fff;
  opacity: 1;
}
.MenuItem.Active:hover {
  opacity: 1;
}
.MenuItem:before {
  margin-right: 10px;
  font-size: 20px;
}
.MenuItem:after {
  font-size: 20px;
}
.MenuItem:hover {
  opacity: 0.8;
}
.MenuItem.Active {
  background: #313c42;
  opacity: 1;
}
.MenuItem.Active:before {
  color: #00b8ab;
}
.MenuItem.Active:hover {
  opacity: 1;
}
.SubMenuItem {
  padding-left: 60px;
  font-size: 12px;
  height: 35px;
}
.Closed {
  max-height: 0;
  overflow: hidden;
}
</style>
