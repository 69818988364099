import Vue from 'vue'
import { noop } from 'lodash'

import editorService from './edit-questionnaire-service.cmp'
import QuestionnaireEdit from './QuestionnaireEdit.vue'

export default {
  template: '<div></div>',
  bindings: {
    questionnaire: '<',
    preview: '<',
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'DataServer']
function Ctrl($element, dataServer){
  const vm = this,
    getTemplatePromise = dataServer.getQuestionnaire(),
    questionnaire = { config: {}, model: {}, template: {}, preview: false }

  let vueComponent

  this.$onDestroy = () => { vueComponent && vueComponent.$destroy() }

  this.$onChanges = ( changes ) => {
    if(changes.questionnaire && changes.questionnaire.currentValue) {
      vm.questionnaire = changes.questionnaire.currentValue
      questionnaire.config = vm.questionnaire.config
      questionnaire.model = vm.questionnaire.model

      getTemplatePromise
        .then(initVueComponent, noop)
    }

    if(changes.preview) {
      vm.preview = !!changes.preview.currentValue
      questionnaire.preview = vm.preview
    }
  }

  function initVueComponent(template){
    !vueComponent && initVue()

    function initVue(){
      questionnaire.template = template

      vueComponent = new Vue({
        el: $element[0],
        data: {
          questionnaire: questionnaire,
          preview: questionnaire.preview
        },
        render: function (createElement) {
          return createElement(QuestionnaireEdit, {
            props: { questionnaire: questionnaire, preview: questionnaire.preview },
            on: {
              questionAdded (data) { editorService.questionAdded(questionnaire.model, data) },
              questionRemoved (data) { editorService.questionRemoved(questionnaire.model, data) },
              sectionAdded (data) { editorService.sectionGroupAdded(questionnaire.model, data) },
              sectionRemoved (data) { editorService.sectionGroupRemoved(questionnaire.model, data) },
              moduleAdded (data) { editorService.moduleAdded(questionnaire.model, questionnaire.config, data) },
              moduleRemoved (data) { editorService.moduleRemoved(questionnaire.model, data) },
              questionRequiredChanged (data) { editorService.questionRequiredChanged(questionnaire.model, data) },
              tableAdded (data) { editorService.tableAdded(questionnaire.model, questionnaire.config, data) },
              tableRemoved (data) { editorService.tableRemoved(questionnaire.model, data) },
              tableConfigure ( data ) { editorService.tableConfigure(questionnaire.config, data) },
              userDefinedChanged ( data ) { editorService.userDefinedChanged(questionnaire.model, data) },
              userDefinedRemoved ( data ) { editorService.userDefinedRemoved(questionnaire.model, data) },
              specialsChanged ( data ) { editorService.handleSpecialsChanged(questionnaire, data) }
            },
          })
        }
      })
    }
  }
}
