/**
 * Created by DejanK on 10/13/2016.
 */
import './terms.scss'
import HTML from './terms.html'
import {stopEvent} from "../../../shared/tools/view-utils";

export { TermsController as Controller, HTML }

TermsController.$inject = ['$location', '$anchorScroll'];
function TermsController($location, $anchorScroll) {
  const vm = this;
  vm.scroll = ($event, id) => {
    stopEvent($event)
    $location.hash(id)
    $anchorScroll()
  }
}
