export default [
  {"country": "US", "id": "AL", "label": "Alabama"},
  {"country": "US", "id": "AK", "label": "Alaska"},
  {"country": "CA", "id": "AB", "label": "Alberta"},
  {"country": "US", "id": "AS", "label": "American Samoa"},
  {"country": "US", "id": "AZ", "label": "Arizona"},
  {"country": "US", "id": "AR", "label": "Arkansas"},
  {"country": "CA", "id": "BC", "label": "British Columbia"},
  {"country": "US", "id": "CA", "label": "California"},
  {"country": "US", "id": "CO", "label": "Colorado"},
  {"country": "US", "id": "CT", "label": "Connecticut"},
  {"country": "US", "id": "DE", "label": "Delaware"},
  {"country": "US", "id": "DC", "label": "District of Columbia"},
  {"country": "US", "id": "FM", "label": "Federated States of Micronesia"},
  {"country": "US", "id": "FL", "label": "Florida"},
  {"country": "US", "id": "GA", "label": "Georgia"},
  {"country": "US", "id": "GU", "label": "Guam"},
  {"country": "US", "id": "HI", "label": "Hawaii"},
  {"country": "US", "id": "ID", "label": "Idaho"},
  {"country": "US", "id": "IL", "label": "Illinois"},
  {"country": "US", "id": "IN", "label": "Indiana"},
  {"country": "US", "id": "IA", "label": "Iowa"},
  {"country": "US", "id": "KS", "label": "Kansas"},
  {"country": "US", "id": "KY", "label": "Kentucky"},
  {"country": "US", "id": "LA", "label": "Louisiana"},
  {"country": "US", "id": "ME", "label": "Maine"},
  {"country": "CA", "id": "MB", "label": "Manitoba"},
  {"country": "US", "id": "MH", "label": "Marshall Islands"},
  {"country": "US", "id": "MD", "label": "Maryland"},
  {"country": "US", "id": "MA", "label": "Massachusetts"},
  {"country": "US", "id": "MI", "label": "Michigan"},
  {"country": "US", "id": "MN", "label": "Minnesota"},
  {"country": "US", "id": "MS", "label": "Mississippi"},
  {"country": "US", "id": "MO", "label": "Missouri"},
  {"country": "US", "id": "MT", "label": "Montana"},
  {"country": "US", "id": "NE", "label": "Nebraska"},
  {"country": "US", "id": "NV", "label": "Nevada"},
  {"country": "CA", "id": "NB", "label": "New Brunswick"},
  {"country": "US", "id": "NH", "label": "New Hampshire"},
  {"country": "US", "id": "NJ", "label": "New Jersey"},
  {"country": "US", "id": "NM", "label": "New Mexico"},
  {"country": "US", "id": "NY", "label": "New York"},
  {"country": "CA", "id": "NL", "label": "Newfoundland"},
  {"country": "US", "id": "NC", "label": "North Carolina"},
  {"country": "US", "id": "ND", "label": "North Dakota"},
  {"country": "US", "id": "MP", "label": "Northern Mariana Islands"},
  {"country": "CA", "id": "NT", "label": "Northwest Territories"},
  {"country": "CA", "id": "NS", "label": "Nova Scotia"},
  {"country": "CA", "id": "NU", "label": "Nunavut"},
  {"country": "US", "id": "OH", "label": "Ohio"},
  {"country": "US", "id": "OK", "label": "Oklahoma"},
  {"country": "CA", "id": "ON", "label": "Ontario"},
  {"country": "US", "id": "OR", "label": "Oregon"},
  {"country": "US", "id": "PW", "label": "Palau"},
  {"country": "US", "id": "PA", "label": "Pennsylvania"},
  {"country": "CA", "id": "PE", "label": "Prince Edward Island"},
  {"country": "US", "id": "PR", "label": "Puerto Rico"},
  {"country": "CA", "id": "QC", "label": "Quebec"},
  {"country": "US", "id": "RI", "label": "Rhode Island"},
  {"country": "CA", "id": "SK", "label": "Saskatchewan"},
  {"country": "US", "id": "SC", "label": "South Carolina"},
  {"country": "US", "id": "SD", "label": "South Dakota"},
  {"country": "US", "id": "TN", "label": "Tennessee"},
  {"country": "US", "id": "TX", "label": "Texas"},
  {"country": "US", "id": "UT", "label": "Utah"},
  {"country": "US", "id": "VT", "label": "Vermont"},
  {"country": "US", "id": "VI", "label": "Virgin Islands"},
  {"country": "US", "id": "VA", "label": "Virginia"},
  {"country": "US", "id": "WA", "label": "Washington"},
  {"country": "US", "id": "WV", "label": "West Virginia"},
  {"country": "US", "id": "WI", "label": "Wisconsin"},
  {"country": "US", "id": "WY", "label": "Wyoming"},
  {"country": "CA", "id": "YT", "label": "Yukon Territory"}
]

// var states = {
//   "AL": "Alabama",
//   "AK": "Alaska",
//   "AB": "Alberta",
//   "AS": "American Samoa",
//   "AZ": "Arizona",
//   "AR": "Arkansas",
//   "BC": "British Columbia",
//   "CA": "California",
//   "CO": "Colorado",
//   "CT": "Connecticut",
//   "DE": "Delaware",
//   "DC": "District of Columbia",
//   "FM": "Federated States of Micronesia",
//   "FL": "Florida",
//   "GA": "Georgia",
//   "GU": "Guam",
//   "HI": "Hawaii",
//   "ID": "Idaho",
//   "IL": "Illinois",
//   "IN": "Indiana",
//   "IA": "Iowa",
//   "KS": "Kansas",
//   "KY": "Kentucky",
//   "LA": "Louisiana",
//   "ME": "Maine",
//   "MB": "Manitoba",
//   "MH": "Marshall Islands",
//   "MD": "Maryland",
//   "MA": "Massachusetts",
//   "MI": "Michigan",
//   "MN": "Minnesota",
//   "MS": "Mississippi",
//   "MO": "Missouri",
//   "MT": "Montana",
//   "NE": "Nebraska",
//   "NV": "Nevada",
//   "NB": "New Brunswick",
//   "NH": "New Hampshire",
//   "NJ": "New Jersey",
//   "NM": "New Mexico",
//   "NY": "New York",
//   "NL": "Newfoundland",
//   "NC": "North Carolina",
//   "ND": "North Dakota",
//   "MP": "Northern Mariana Islands",
//   "NT": "Northwest Territories",
//   "NS": "Nova Scotia",
//   "NU": "Nunavut",
//   "OH": "Ohio",
//   "OK": "Oklahoma",
//   "ON": "Ontario",
//   "OR": "Oregon",
//   "PW": "Palau",
//   "PA": "Pennsylvania",
//   "PE": "Prince Edward Island",
//   "PR": "Puerto Rico",
//   "QC": "Quebec",
//   "RI": "Rhode Island",
//   "SK": "Saskatchewan",
//   "SC": "South Carolina",
//   "SD": "South Dakota",
//   "TN": "Tennessee",
//   "TX": "Texas",
//   "UT": "Utah",
//   "VT": "Vermont",
//   "VI": "Virgin Islands",
//   "VA": "Virginia",
//   "WA": "Washington",
//   "WV": "West Virginia",
//   "WI": "Wisconsin",
//   "WY": "Wyoming",
//   "YT": "Yukon Territory"
// }
