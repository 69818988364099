
import {simpleState} from "../../../shared/tools/router-helper";

export default States;

States.$inject = ['$stateProvider', '$urlRouterProvider'];
function States($stateProvider, $urlRouterProvider) {

  $stateProvider
    .state('error', simpleState('/error?id', require('./error/error.html'),
      require('./error/error.ctrl'), [], { resolve: { errorId: getIdFromStateParam }}))
    .state('success', simpleState('/success?id&data', require('./success/success.html'),
      require('./success/success.ctrl')))
  ;
  $urlRouterProvider.otherwise('/');
}

getIdFromStateParam.$inject = ['$stateParams'];
function getIdFromStateParam($stateParams){
  return $stateParams.id || '404';
}
