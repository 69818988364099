<script>
  import DEFAULT_IMAGE from 'images/ui/no-hotel-photo-available.gif';

  export let url, alt;
  export let placeholder;

  let
    defaultImage = placeholder || DEFAULT_IMAGE,
    src = defaultImage;

  $: defaultImage = placeholder || DEFAULT_IMAGE;
  $: checkImageUrl(url);

  function checkImageUrl(lUrl){
    if(!lUrl) {
      src = defaultImage;
    }

    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = lUrl;
      img.onload = () => resolve(lUrl);
      img.onerror = reject;
    })
      .then(resolved => {
        src = resolved;
      })
      .catch(() => {
        src = defaultImage;
      })
  }
</script>

<img {src} {alt} loading="lazy" />
