<RbEmptyHeader homeState="bidManager" homeStateParams="{{viewId: null}}">
  <div slot="additionalControls"><slot name="additionalControls" /></div>

  <div class="Controls" slot="mainControls">
    <div>
      <slot name="mainControls" />
    </div>

    <CurrentUser />
  </div>
</RbEmptyHeader>


<script>
  import CurrentUser from 'root/user-account/ui/rbs-current-user/CurrentUser.svelte';
  import RbEmptyHeader from "./RbEmptyHeader.svelte";
</script>


<style lang="stylus">.Controls {
  display: flex;
  align-items: center;
  position: relative;
}
</style>