import SDialog from './Dialog.svelte';

const config = {
  closeOnOutsideClick: false,
};

class Dialog {

  constructor(containerElement, dialogComponent, dialogData = {}, options = {}) {
    this.promise = new Promise((resolve, reject) => {
      this.$$promiseResolve = resolve;
      this.$$promiseReject = reject;
    });

    this.$$instance = new SDialog({
      target: containerElement,
      props: {
        dialog: {
          component: dialogComponent,
          props: dialogData,
        },
        options: { ...config, ...options},
        ord: containerElement.childElementCount+1
      }
    });
  }

  cancel (reason) { return this.$destroy( () => { this.$$promiseReject(reason) } ) }

  hide (reason) { return this.$destroy( () => { this.$$promiseResolve(reason) } ) }

  $destroy( action ){
    if(this.$$instance) this.$$instance.$destroy();
    if(action) action();
    return this.promise;
  }
}

export default Dialog
