/**
 * Created by DejanK on 9/1/2016.
 */
import RbLog from 'root/log/log';

export default DataServer;

DataServer.$inject = ['$q'];
function DataServer($q) {

  this.getAmenities = () => load(import('../../assets/data/hotel-amenities'));
  this.getAccountTypes = () => load(import('../../assets/data/account-types'));
  this.getMessages = () => load(import('../../assets/data/messages'));
  this.getIndustries = () => load(import('../../assets/data/industries'));
  this.getCountries = () => load(import('../../assets/data/countries'));
  this.getStates = () => load(import('../../assets/data/states'));
  this.getHotelRfpCoverLetterPlaceholders = () => load(import('../../assets/data/letter-placeholders-hotel-rfp-cover-letter'));
  this.getHotelRfpNamCoverLetterPlaceholders = () => load(import('../../assets/data/letter-placeholders-hotel-rfp-nam-cover-letter'));
  this.getHotelRfpFinalAgreementPlaceholders = () => load(import('../../assets/data/letter-placeholders-hotel-rfp-final-agreement'));
  this.getBidManagerHeadings = () => load(import('../../assets/data/view-headings'));
  this.getQuestionnaire = () => load(import('../../assets/data/hotel-questionnaire' ));

  function load(imported){
    const when = $q ? $q.when : Promise.resolve;
    return when(imported.then(i => i.default, log));
  }

  function log(error) {
    RbLog.fatal(error);
    return $q ? $q.reject(error) : Promise.reject(error)
  }
}
