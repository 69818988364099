/**
 * Created by DejanK on 4/5/2017.
 */
import { cloneDeep, isEqual, noop } from 'lodash'
import HTML from './edit-questionnaire.html'

export { HTML, BidQuestionnaireEditController as Controller }

BidQuestionnaireEditController.$inject = [ 'NotificationUtils', 'RfpHotelBidRepository', '$state'];
function BidQuestionnaireEditController( notificationUtils, rfpHotelBidRepository, $state){
  const vm = this
  let pristineQuestionnaire

  vm.isPreview = false
  vm.preview = preview
  vm.closePreview = closePreview
  vm.save = saveQuestionnaire

  $onInit();

  function $onInit(){
    vm.bidId = $state.params.bidId

    notificationUtils.onLoad( () => rfpHotelBidRepository.getQuestionnaireModel(vm.bidId), vm, 'questionnaire' )
      .then( response => { pristineQuestionnaire = cloneDeep(response.data) }, noop )
  }

  function preview(){ vm.isPreview = true }

  function closePreview(){ vm.isPreview = false }

  function saveQuestionnaire(){
    const model = vm.questionnaire.data

    if(isEqual(pristineQuestionnaire, model)){
      goToView()
    } else {
      notificationUtils.onSave( () => rfpHotelBidRepository.updateQuestionnaireModel(vm.bidId, model) )
        .then(goToView, noop)
    }

    function goToView(){ $state.go('hotelRfp.bid.questionnaire.view') }
  }
}
