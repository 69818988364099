/**
 * Created by DejanK on 9/5/2016.
 */
import { merge, identity } from 'lodash'
import autoSave from 'root/shared/tools/file-autosave';
import { generateCsv } from 'root/shared/tools/csv.service';

const
  BLOB = 'blob',
  TIMEOUT = 10*60*1000;

export default function($http, serverUrl){
  return new Server($http, serverUrl)
}

function Server($http, serverUrl) {
  this.$http = $http;
  this.url = serverUrl;
}

Server.prototype.get = function get(url, params, options){
  return this.$http.get(this.buildUrl(url), merge({params: params}, options)).then(returnData);
}

Server.prototype.post = function post(url, data, params, options){
  return this.$http.post(this.buildUrl(url), data, merge({params: params}, options)).then(returnData);
}

Server.prototype.put = function put(url, data, params, options){
  return this.$http.put(this.buildUrl(url), data, merge({params: params}, options)).then(returnData);
}

Server.prototype.delete = function httpDelete(url, params, options){
  return this.$http.delete(this.buildUrl(url), merge({params: params}, options)).then(returnData);
}

Server.prototype.getFile = function getFile(url, params, options){
  return this.$http(merge ( {
    method: 'GET',
    url: this.buildUrl(url),
    params: params || {},
    responseType: BLOB,
    timeout: TIMEOUT
  }, options )).then(returnData)
}

Server.prototype.getAndAutoSaveFile = function getAndAutoSaveFile(url, params, options) {
  return this.$http(merge({
    method: 'GET',
    url: this.buildUrl(url),
    params: params || {},
    responseType: BLOB,
    timeout: TIMEOUT
  }, options))
    .then(autoSaveResponse)
}

function autoSaveResponse(response) {
  const headers = response.headers()
  autoSave(response.data, headers['content-type'], headers['rb-filename'])
}

Server.prototype.postAndAutoSaveFile = function getFile(url, data, params, options, save = autoSaveResponse){
  return this.$http(merge ( {
    method: 'POST',
    url: this.buildUrl(url),
    params: params || {},
    data: data,
    responseType: BLOB,
    timeout: TIMEOUT*3 // 30 minutes
  }, options ))
    .then(save)
}

Server.prototype.sendFile = function sendFile(url, formData, progressCallback){
  return this.$http.post(this.buildUrl(url), formData, {
    transformRequest: identity,
    headers: {'Content-Type': undefined},
    uploadEventHandlers: {
      'progress': function(e) {
        if (e.lengthComputable) {
          progressCallback && progressCallback(Math.round((e.loaded * 100) / e.total), e)
        } else {
          progressCallback && progressCallback(0, e)
        }
      }
    }
  }).then(returnData)
}

Server.prototype.buildUrl = function buildUrl(url) {
  return this.url + url;
}

Server.prototype.asCsvFileDownload = function(filename, headers = [], data){
  autoSave(generateCsv(headers, data), 'text/csv', `${filename}.csv`);
  return Promise.resolve();
}

function returnData(response){ return response.data }
