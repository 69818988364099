<template>
  <div :class="$style.counter" v-html="counterText"></div>
</template>

<script>
  export default {
    name: "rbv-travel-destinations-map-properties-counter",
    props: ['count'],
    computed: {
      counterText () {
        if(!this.count) {
          return 'No Properties found'
        } else {
          return `<b>${this.count}</b> ${this.count > 1 ? 'Properties': 'Property'} found`
        }
      }
    }
  }
</script>

<style lang="stylus" module >

  .counter {
    color #455A64
  }

</style>
