/**
 * Created by DejanK on 4/12/2017.
 *
 * View Travel Destination Component
 *
 */
import './rb-preview-travel-destination.scss'
import HTML from './rb-preview-travel-destination.html'
import { isNil } from 'lodash'
import { CurrencyFormatter } from "../../../../../internationalization/internationalization.service";

export default {
  template: HTML,
  bindings: {
    model: '<',
    distanceKm: '<',
    distanceMi: '<'
  },
  controller: Ctrl
}

Ctrl.$inject = [];
function Ctrl() {
  const vm = this, currencyFormatter = CurrencyFormatter('USD', 'Not Provided')

  vm.model = {}

  vm.printEstimatedRoomNights = () => { return isNil(vm.model.estimatedRoomNights) ? 'Not Provided' : vm.model.estimatedRoomNights }
  vm.printEstimatedSpend = () => { return currencyFormatter.format(vm.model.estimatedSpend) }
}
