/**
 * Created by DejanK on 1/18/2017
 *
 * Travel Destination Manager Filters service
 */
export default TravelDestinationManagerFiltersService

TravelDestinationManagerFiltersService.$inject = ['DataServer', 'RfpHotelRfpDestinationsRepository'];
function TravelDestinationManagerFiltersService(dataServer, repository){
  const $service = this;

  $service.saveFilter = repository.saveDestinationFilter;
  $service.setDefaultFilters = repository.setRfpTravelDestinationsDefaultFilter;
}
