<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import TextArea from 'root/components/molecules/inputs/TextArea.labeled.svelte';
  import {mainAPI} from 'root/angular-injector-provider';
  export let cancel, hide;

  let
    agreed = false,
    touched = false,
    comment = '';


  function setComment(e){
    comment = e.detail;
  }


  function subscribe(){
    touched = true;
    if(agreed){
      mainAPI()
        .emailAddress.setFixServiceSubscription(true, comment)
        .then(hide);
    }
  }
</script>

<ClosableModal close="{cancel}" width="500px">
  <div slot="heading">
    <div class="Header">
      "Fix My Emails" Terms of Service
    </div>
  </div>

  <div class="Content">
    <div class="Text">
      Please read the following text carefully before clicking “Subscribe to Service”
    </div>

    <div class="Text Margin">
      ReadyBid support team would be pleased to call any hotel whose email failed and correct the problem. We cannot, of course, guarantee a response but we will call until the hotel has received the RFP.
    </div>
    <div class="Text">
      If we are unsuccessful in obtaining the correct email information, you will not be charged. The following rules apply:
    </div>
    <ul class="Text">
      <li>$5.00 per Domestic Hotel</li>
      <li>$7.50 per International Hotel</li>
      <li>Includes up to 5 attempts to contact the hotel</li>
      <li>You will only be charged if we acquire the correct email</li>
      <li>ReadyBid will invoice monthly for any email efforts or, if preferable, ReadyBid will simply add service fee to client quarterly, semi-annual or annual invoice</li>
      <li>You may cancel the service at any time.</li>
    </ul>
    <div class="Text">
      Additionally, I hereby grant ReadyBid personnel the ability to resend any RFP for which they have worked to repair the email as well as to use our corporate name in its efforts to find a new hotel contact. The use of our corporate identity however will be used only and expressly for the purposes of sourcing Hotel RFP information.
    </div>
    <div class="Text Margin">
      <div class="Checkbox"
           class:CheckboxError={touched && !agreed}
           data-icon-before="{agreed ? 'check_box' : 'check_box_outline_blank'}"
           on:click={() => {agreed = !agreed}}>
        I agree to the Terms of Service
      </div>
    </div>

    <div class="Margin">
      <TextArea
        placeholder="Write here..."
        maxLength="500"
        on:input={setComment}
        on:change={setComment}
      >
        Your comment
      </TextArea>
    </div>
  </div>


  <div class="Actions Margin">
    <div>
<!--      <button type="button"-->
<!--              class="aBtn atLight asLarge"-->
<!--              data-icon-before="help_outline"-->
<!--              on:click={cancel}>-->
<!--        More Info-->
<!--      </button>-->
    </div>

    <div class="layout-row">
      <button type="button"
              class="aBtn atLight asLarge"
              on:click={cancel}>
        Close
      </button>

      <button type="button"
              class="aBtn atLight asLarge NoTransition"
              class:asMain={agreed}
              class:asError={touched && !agreed}
              data-icon-before="alternate_email"
              on:click={subscribe}>
        Subscribe to Service
      </button>
    </div>
  </div>

</ClosableModal>

<style lang="stylus">.Header {
  padding: 16px 13px 0;
  color: #37474f;
  font-size: 16px;
  font-weight: bold;
}
.Text {
  font-size: 13px;
  color: #78909c;
}
.Margin {
  margin-top: 20px;
}
.Content {
  padding: 0 13px;
  color: #546e7a;
  margin: 0 0 20px;
  max-height: 650px;
  overflow: auto;
}
.Actions {
  padding: 0 18px 21px;
  text-align: right;
  display: flex;
  justify-content: space-between;
}
.aBtn[data-icon-before] {
  padding-left: 5px;
}
.aBtn[data-icon-before]:before {
  padding-right: 5px;
}
.Checkbox {
  cursor: pointer;
}
.Checkbox:hover {
  color: #37474f;
}
.Checkbox:before {
  padding-right: 10px;
}
.CheckboxError {
  color: #f15a24 !important;
}
.NoTransition {
  transition: all linear 0s;
}
</style>
