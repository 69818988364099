<script>
  import Spinner from "root/components/atoms/Spinner.svelte";
  import ClosableModal from "root/services/modals/Closable.modal.svelte";
  import {
    mainAPI,
    currentUser,
    notificationUtils,
    hotelRfpBidManagerService
  } from "root/angular-injector-provider";
  import NotesComponent from "root/rfp/bid-manager/main/dialogs/BidNotes/BidNotesComponent.svelte";
  import get from "lodash/get";
  import PHOTO_NOT_AVAILABLE from "images/hotel_placeholder.png";
  import SetSupplierContactComponent from "./SetSupplierContactComponent.svelte";

  export let hide,
    cancel,
    bids,
    side,
    activeTab = "contacts";

  let user;

  function loadUser() {
    currentUser()
      .get()
      .then(u => (user = u));
  }

  loadUser();


  $: isBuyer = side === 'BUYER';
  $: singleBid = bids.length === 1;
  $: tabs = [
    {
      id: "contacts",
      show: isBuyer,
      label: "Representatives",
      icon: "people"
    },
    {
      id: "notes",
      show: singleBid,
      label: "Bid Notes",
      icon: "sticky_note_2"
    }
  ].filter(({ show }) => show);
  $: activeTab = activeTab || tabs[tabs.length - 1].id;
  $: bid = bids[bids.length - 1];
  $: logo = getLogo(bid);
  $: supplierName = bid.supplier.company.name;
  $: rfpName = bid.rfp.specifications.name;

  function getLogo({ supplier }) {
    const image = get(supplier, "company.logo");
    return image ? `images/logos/${image}` : PHOTO_NOT_AVAILABLE;
  }
</script>

<style lang="stylus">.Heading img {
  height: 70px;
  border-bottom-right-radius: 10px;
  margin-right: 15px;
  border: 1px solid #fff;
  object-fit: contain;
  background: #fff;
  width: 110px;
}
.Heading {
  display: flex;
  align-items: center;
}
.Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  line-height: 1;
  color: #546e7a;
}
.HeaderTitle {
  font-weight: bold;
  font-size: 13px;
  color: #455a64;
}
.tab {
  height: 35px;
  min-width: 140px;
  border-radius: 15px 15px 0px 0px;
  background-color: #cfd8dc;
  position: relative;
  display: inline-block;
  z-index: 2;
  text-align: center;
  line-height: 3;
  font-size: 13px;
  cursor: pointer;
  transition: transform 0.3s;
  border-top: 1px solid;
  border-color: #fff;
  margin-left: 10px;
}
.tab:hover {
  transform: scaleY(1.04) translateY(-1px);
}
.tab i.material-icons {
  font-size: 18px;
  height: 18px;
  width: 18px;
  vertical-align: text-bottom;
}
.tab:before,
.tab:after {
  height: 35px;
  width: 40px;
  border-radius: 10px 10px 0px 0px;
  background-color: #cfd8dc;
  content: "";
  position: absolute;
  top: 0px;
  z-index: -1;
  border-color: #fff;
}
.tab:before {
  transform: skewX(-20deg);
  left: -2px;
  border-left: 1px solid #fff;
}
.tab:after {
  transform: skewX(20deg);
  right: -2px;
  border-right: 1px solid #fff;
}
.tab.active {
  background-color: #fff;
  z-index: 3;
  border-color: #cfd8dc;
}
.tab.active:before,
.tab.active:after {
  border-color: #cfd8dc;
  background-color: #fff;
}
.tab div {
  color: #455a63;
  padding: 0 10px;
  min-width: max-content;
}
.tabs {
  padding: 20px 0 0;
  background: #ebeff2;
  position: relative;
}
.tabs hr {
  background: #d2d9dc;
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  border: none;
  height: 1px;
}
.Dialog :global(div > div.Heading) {
  background: #ebeff2;
}
.Dialog :global(div > div.Heading button.asLarge) {
  background: transparent;
}
.Dialog :global(.Banner) {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-height: 20px;
}
.Dialog :global(.Banner > span) {
  font-size: 20px;
  color: #556f7b;
  margin-right: 10px;
}
.Dialog :global(.Banner):before {
  content: "";
  display: block;
  height: 100%;
  background: #07a79d;
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  border-radius: 0 5px 5px 0;
}
.onlyContacts {
  display: flex;
  padding: 10px;
}
</style>

{#if !user}
  <Spinner />
{:else}
  <div class="Dialog">
    <ClosableModal close={() => cancel()} width="650px">
      <div slot="heading" class="Heading">
        {#if singleBid}
          <img src={logo} alt={supplierName} />
          <div class="Header">
            <div class="HeaderTitle">{supplierName}</div>
            <p>{rfpName}</p>
          </div>
        {:else}
          <div class="HeaderTitle onlyContacts">Hotel Sales Contact</div>
        {/if}
      </div>
      {#if singleBid}
        <nav class="tabs">
          <hr />
          {#each tabs as tab}
            <div
              class="tab"
              class:active={activeTab === tab.id}
              on:click={() => (activeTab = tab.id)}>
              <div>
                <i class="material-icons">{tab.icon}</i>
                {tab.label}
              </div>
            </div>
          {/each}
        </nav>
      {/if}
      <div hidden={activeTab !== 'notes'}>
        <NotesComponent {cancel} {user} {bid} />
      </div>
      <div hidden={activeTab !== 'contacts'}>
        <SetSupplierContactComponent {cancel} {bids} {hide} />
      </div>
    </ClosableModal>
  </div>
{/if}
