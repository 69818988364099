import { checkAndExecute, checkAndExecuteToActionReport } from 'root/api/helpers';

export default function RfpAutomatedReminderApi(api) {
  Object.assign(api, {
    getOrCreateReminders: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/${rfpId}/reminder/get-or-create`)
    ),
    saveReminder: (rfpId, reminder) => checkAndExecute(
      () => rfpId && reminder,
      () => api.server.put(`rfps/${rfpId}/reminder/update`, reminder)
    ),
    sendReminders: bids => checkAndExecuteToActionReport(
      () => bids && bids.length > 0,
      () => api.server.put('send-automated-reminders', {bids})
    ),
    getSends: id => checkAndExecute(
      () => id,
      () => api.server.get(`rfp-automated-reminders/${id}/sends`)
    ),
    unsubscribeFromEmails: (token, rfpId) => checkAndExecute(
      () => token && rfpId,
      () => api.server.put(`unsubscribe-rfp-reminders/${rfpId}/${token}`)
    ),
    sendReminderLetterPreview: (rfpId) => checkAndExecute(
      () => rfpId,
      () => api.server.put(`reminders-send-preview/${rfpId}`)
    )
  })
}
