/**
 * Created by DejanK on 1/23/2017
 *
 * Creates Supplier Profile Window on TravelDestination Manager Map
 */
import {get} from 'lodash';
import HTML from './rb-destinations-manager-supplier-profile-window.html'
import PHOTO_NOT_AVAILABLE from 'images/ui/no-hotel-photo-available.gif'

export default {
  template: HTML,
  controller: Ctrl
};

Ctrl.$inject = ['RbGoogle', '$element', 'TravelDestinationManagerMapService', 'TravelDestinationManagerService']
function Ctrl(RbGoogle, $element, mapService, managerService){
  const vm = this
  let overlayWindow

  this.$onInit = ()=>{
    $element.remove();
    RbGoogle.getMap()
    .then(createWindow);
  }

  this.$doCheck = ()=>{
    if(managerService.focusedSupplierId !== vm.profileToShow){
      vm.profileToShow = managerService.focusedSupplierId;
      updateShownProfile(vm.profileToShow);
    }
  };

  function createWindow(){
    $element.css('position', 'absolute');
    overlayWindow = new RbGoogle.CustomWindow($element[0], [0,0]);
  }

  function updateShownProfile(supplierId){
    supplierId ? showWindow(supplierId) : hideWindow();
  }

  function showWindow(supplierId){
    const supplierWithMarker = mapService.getSupplierWithMarker(supplierId),
      map = mapService.getMap();
    if(map && supplierWithMarker){
      vm.supplier = supplierWithMarker.data;
      vm.supplierImageUrl = getImage(get(vm.supplier, 'image.url'));
      overlayWindow.open(map, supplierWithMarker.marker);
    }
  }

  function getImage(image) {
    return image ? (image.startsWith('http') ? image : `/images/entities/${image}`): PHOTO_NOT_AVAILABLE;
  }

  function hideWindow(){
    overlayWindow && overlayWindow.close();
  }
}


