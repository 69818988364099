import {checkAndExecute} from 'root/api/helpers';
import autoSave from 'root/shared/tools/file-autosave';

export default function(api) {
  Object.assign(api, {
    getNegotiatedSavingsReport: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`reports/${rfpId}/negotiation-savings`)
    ),
    exportToExcel: (rfpId, data) => checkAndExecute(
      () => rfpId && data,
      () => api.server.postAndAutoSaveFile(`reports/${rfpId}/negotiation-savings/excel`, data)
    ),
    exportToPdf: (rfpId, data) => checkAndExecute(
      () => rfpId && data,
      () => api.server.postAndAutoSaveFile(`reports/${rfpId}/negotiation-savings/pdf`, data, null, null, r => autoSave(r.data, 'application/pdf', `Negotiated Savings Report.pdf`))
    ),
    // chain portal apis
    fetchChainPortalData: (groups, sortBy, currentPage, bidTypes, onlyAssignments, loadRecent) => checkAndExecute(
      () => groups && sortBy && currentPage,
      () => api.server.put(`chain-portal`, {groups, sortBy, currentPage, bidTypes, onlyAssignments, loadRecent})
    ),

    exportChainPortalData: (filters, type, columns) => checkAndExecute(
      () => filters && type && columns,
      () => api.server.postAndAutoSaveFile(`chain-portal/export/data`, { filters, type, columns})
    ),

    chainPortalExport: (exportName, config) => checkAndExecute(
      () => exportName,
      () => api.server.postAndAutoSaveFile(`chain-portal/exports/${exportName}`, config)
    ),

    loadAllGroupHotels: () => api.server.get('chain-portal/hotels'),
    loadRequestedHotels: () => api.server.get('chain-portal/hotels/requested'),
    loadRequestedHotel: (id) => api.server.get(`chain-portal/hotels/requested/${id}`),
    fetchOverDueBidsCount: () => api.server.get('chain-portal/bids/almost-due/count'),
    loadBrands: () => api.server.get('chain-portal/chain-brands'),
    setDefaultViewAccountIds: (ids) => checkAndExecute(
      () => ids,
      () => api.server.put('chain-portal/set-default-chain-portal-account-ids', ids)
    ),
    getNamPreview: (rfpId) => checkAndExecute(
      () => rfpId,
      () => api.server.get('rfps/bids/nam-preview/', {rfpId})
    ),
    chainPortalSearchHotels: (params) => checkAndExecute(
      () => params,
      () => api.server.put('chain-portal/add-hotel/search', params)
    ),
    chainPortalUpdateCodeAndManager: (id, request) => checkAndExecute(
      () => id && request,
      () => api.server.post(`chain-portal/chain/${id}/update-code-and-manager`, request)
    ),
    getTotalGroupRnvForRfp: (rfpId) => checkAndExecute(
      () => rfpId,
      () => api.server.get(`chain-portal/rfp/${rfpId}/total-rnv`)
    ),
    exportHotels: (type) => checkAndExecute(
      () => type,
      () => api.server.postAndAutoSaveFile(`chain-portal/export/hotels/${type}`)
    ),
    loadHotelsForCurrentUserAccountBySabreCode: (sabreCodes) => checkAndExecute(
      () => sabreCodes.length > 0,
      () => api.server.put('chain-portal/upload/load-by-sabre/', sabreCodes)
    ),
    uploadHotelsDataInBulk: data => checkAndExecute(
      () => data.length > 0,
      () => api.server.post('chain-portal/upload/bulk/', data)
    )
  });
}
