<template>
  <div />
</template>

<script>
  import SvelteVueComponent from 'root/svelte-vue-components/SvelteVueComponentMixin.vue';
  import SvelteComponent from 'root/rfp/reports/chain-portal/NavigationSidebar.svelte'

  export default {

    name: 'SideNav',

    mixins: [ SvelteVueComponent ],

    created() {
      this.component = SvelteComponent;
    }
  }
</script>
