import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';
import Component from './RfpDetailsNavigation.svelte';
import get from 'lodash/get';

export default {
  template: '<div></div>',
  bindings: {
    rfp: '<',
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(this, SvelteNgComponent(Component, $element[0], () => ({
    rfpId: get(vm.rfp, 'id'),
    chainSupport: get(vm.rfp, 'specifications.chainSupport', false),
    type: get(vm.rfp, 'specifications.subType'),
    attachments: {
      direct: !!get(vm.rfp, 'coverLetter.attachments.length' , 0),
      chain: !!get(vm.rfp, 'namCoverLetter.attachments.length' , 0),
      finalAgreement: !!get(vm.rfp, 'finalAgreement.attachments.length' , 0),
    }
  }), {}));
}
