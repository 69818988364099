import { noop, merge } from 'lodash'
import {NAME as workingDialog, SAVING} from '../../ui-ng/notifications/dialogs/working/working.ctrl';
import {NAME as successDialog} from '../../ui-ng/notifications/dialogs/success/success.ctrl';
import {WORKING} from 'root/ui-ng/notifications/dialogs/working/working.ctrl';
import {NAME as errorDialog} from '../../ui-ng/notifications/dialogs/error/error-notification.ctrl';

export default NotificationUtilsService

NotificationUtilsService.$inject = ['rbDialog', 'HttpErrorHandler', '$state'];
function NotificationUtilsService(dialog, httpErrorHandler, $state){
  this.onSave = onSave;
  this.onLoad = onLoad;
  this.onLoadWithNotification = onLoadWithNotification;
  this.showSuccess = showSuccess;
  this.showSuccessDialog = showSuccessDialog;
  this.showErrorDialog = showErrorDialog;
  this.showError = showError;
  this.handleErrors = httpErrorHandler.handle;
  this.goToEncodedState = goToEncodedState;

  /**
   * Wraps save requests sent to server with working notification and error handling
   * @param {Function} saveFunction - must return promise, usually $http call
   * @param {Object} options - dialog data overrides:
   *  workingDialogName: name of the dialog to show while waiting for promise to resolve [default: working]
   *  workingDialogLabel: [saving, loading, working, checking, any string] [default: saving] !! previously: 'workingLabelType'
   *  customErrorHandlers: custom http error handlers. Check HttpErrorHandler for list of errors
   *  @returns {Promise} - dialog result
   */
  function onSave(saveFunction, options = {}){
    const o = merge({
      workingDialogName: workingDialog,
      workingDialogLabel: SAVING,
      customErrorHandlers: {}
    }, options );

    dialog.show(o.workingDialogName, {locals: {type: o.workingDialogLabel}});

    return saveFunction()
      .then(onSuccess, onFailure);

    function onSuccess(results){
      return dialog
        .hide()
        .then(() => results, noop);
    }

    function onFailure(error){
      return dialog
        .hide()
        .then( () => httpErrorHandler.handle(error, o.customErrorHandlers), noop );
    }
  }

  function onLoad(loadFunction, model, key, options){
    const m = model || {}, k = key || 'load',
      o = merge({ customErrorHandlers: {} }, options);

    m[k] = 'loading';
    return loadFunction()
      .then(onSuccess, onFailure);

    function onSuccess(results){
      m[k] = results;
      return results;
    }

    function onFailure (error) {
      m[k] = 'error';
      return httpErrorHandler.handle(error, o.customErrorHandlers);
    }
  }

  /**
   * Wraps requests sent to server with working notification and error handling
   * @param {Function} loadFunction - must return promise, usually $http call
   * @param {Object} options - dialog data overrides:
   *  workingDialogName: name of the dialog to show while waiting for promise to resolve [default: working]
   *  workingDialogLabel: [saving, loading, working, checking, any string] [default: working]
   *  customErrorHandlers: custom http error handlers. Check HttpErrorHandler for list of errors
   *  @returns {Promise} - dialog result
   */
  function onLoadWithNotification(loadFunction, options = {}){
    return onSave(loadFunction, merge( {
      workingDialogLabel: WORKING
    }, options ));
  }

  function showSuccess(successId, successData){
    $state.go('success', {
      id: successId,
      data: successData ? btoa(JSON.stringify(successData)) : undefined
    });
  }

  function showSuccessDialog(options){
    const o = merge({ dialogName: successDialog, dialogData: undefined }, options);
    return dialog
      .show(o.dialogName, {locals: {Success: o.dialogData}})
      .catch(noop);
  }

  function showErrorDialog(options) {
    const o = merge({ dialogName: errorDialog, dialogData: undefined }, options);
    return dialog
      .show(o.dialogName, {locals: {Error: o.dialogData}})
      .catch(noop);
  }

  function showError(errorId, errorData){
    $state.go('error', {
      id: errorId,
      data: errorData ? btoa(JSON.stringify(errorData)) : undefined
    });
  }

  function encodeParam(obj){
    return encodeURIComponent(btoa(JSON.stringify(obj)));
  }

  function encodeCurrentState(){
    return encodeParam({state: $state.current.name, params: $state.params});
  }

  function decodeParam(s){
    return JSON.parse(atob(decodeURIComponent(s)))
  }

  function goToEncodedState(s){
    const savedState = decodeParam(s);
    $state.go(savedState.state, savedState.params);
    return true;
  }
}
