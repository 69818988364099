import {get, noop} from 'lodash';
import {Dialog} from 'root/v-app/rbServices';
import {updateBids} from 'root/rfp/bid-manager/main/stores/bids-raw';
/**
 * Created by DejanK on 4/5/2017.
 */
export default BidManagerService;

BidManagerService.$inject = ['BidManagerRepository'];
function BidManagerService(bidManagerRepository){
  const $service = this;

  $service.updateBids = actionReport => updateBids(actionReport);
  $service.handleActionResult = handleActionResult;
  $service.getBidContactLastActive = getBidContactLastActive;

  function getBidContactLastActive(bidId){
    return bidManagerRepository.getBidContactLastActive(bidId).then(({data}) => data);
  }

  function handleActionResult(actionReport, bids, actionReportDialog, successDialog, props = {}){
    updateBids(actionReport);
    updateActionReport(actionReport && actionReport.children, bids);

    const dialogToShow = actionReport.containsErrors() ? actionReportDialog : successDialog;
    if(dialogToShow){
      Dialog
        .show( dialogToShow, { report: actionReport, ...props })
        .catch(noop);
    }

    function updateActionReport(reports = []){
      reports.forEach(r => {
        const reportBid = r.getData(),
          reportBidId = reportBid._id,
          bid = bids.find(b => b._id === reportBidId );

        r.name = get(bid, 'supplier.company.name', get(reportBid, 'supplier.company.name', ''))
      });
    }
  }
}
