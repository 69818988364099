import {$state} from 'root/angular-injector-provider';
import {HOTEL_RFP_BID_UPDATE_RESPONSE} from 'root/states';

export default function CreateEditCompetitiveBidResponse(){
  return function EditCompetitiveBidResponse(report){
    return {
      label: 'Update Response',
      icon: 'edit',
      action: () => {
        $state().inNew(HOTEL_RFP_BID_UPDATE_RESPONSE, {bidId: report.bids[0]._id})
      }
    }
  }
}