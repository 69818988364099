<template>
  <div class="rbCheckboxesContainer">
    <label class="rbCheckbox rbCheckboxesCheckbox" v-for="option in options" :key="option.value">
      <input type="checkbox"
             class="rbCheckboxInput"
             :id="name"
             :value="option.value"
             v-model="vModel"
             @change="changeHandler()"
      />
      <span class="rbCheckboxLabel rbCheckboxesLabel"
            :class="{ 'checked' : isSelected(option.value) }">
                {{ option.label }}
       </span>
    </label>
  </div>
</template>

<script>
  import { isNil, isEqual, cloneDeep } from 'lodash'

  export default {
    name: 'rb-checkboxes',

    props: {
      field: { required: true },
      name: { required: true },
      value: { "default": undefined },
    },

    data () {
      return {
        vModel: [],
        options: []
      }
    },

    methods: {
      isSelected ( optionId ) { return this.vModel && this.vModel.indexOf( optionId ) !== -1 },

      loadOptionsFromElements (slot) {
        const options = []
        slot && slot.forEach(vNode => {
          if (vNode.tag === 'rb-checkbox') {
            const attrs = vNode.data && ( vNode.data.domProps || vNode.data.attrs )
            options.push({
              value: attrs && !isNil(attrs.value) ? attrs.value : undefined,
              label: vNode.children[0].text.trim()
            })
          }
        })
        return options
      },

      changeHandler () {
        this.field.touched = true
        this.field.untouched = false

        const sortedOptions = []
        this.vModel && this.options.forEach( o => { this.vModel.indexOf(o.value) !== -1 && sortedOptions.push(o.value) })

        this.$emit('input', sortedOptions.length ? sortedOptions : undefined)
      },

      updateModel () { this.vModel = this.value ? cloneDeep(this.value) : [] },

      updateOptions () {
        if (!this.priorSlot || !isEqual(this.priorSlot, this.$slots.default)) {
          this.priorSlot = this.$slots.default
          this.options = this.loadOptionsFromElements(this.$slots.default)
        }
      },
    },

    watch: { value () { this.updateModel() } },

    created () {
      this.updateOptions()
      this.updateModel()
    }
  }
</script>

<style lang="stylus">
  .rbCheckboxesContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 10px 0 5px;
  }

  .rbCheckboxesCheckbox {
    line-height: 13px;
  }

  .rbCheckboxesLabel {
    font-size: 11px;
    font-weight: 600;
    color: #78909c;
    cursor: pointer;
    margin: 0 10px;

    &:before {
      width: 14px;
      height: 14px;
    }

    &:after {
      width: 8px;
      top: 6px;
    }
  }
</style>
