/**
 * Stores bids that are visible in the view (filtered, grouped, sorted)
 */

import {default as bidsRawStore} from 'rfp/bid-manager/main/stores/bids-raw';
import {default as viewStateStore} from 'rfp/bid-manager/main/stores/view-state';
import {writable} from 'svelte/store';
import {get, orderBy} from 'lodash';

const store = writable({}, () => {
  const brsUnsubscribe = bidsRawStore.subscribe($brs => {
    if(!$brs.initialized) return;
    store.update($s => ({
      ...$s,
      $bidsRaw: $brs,
      bidsLoaded: $brs.initialized,
      bids: applyViewState($brs.bids, $s.$viewState),
    }));
  });

  const vssUnsubscribe = viewStateStore.subscribe($vss => {
    if(!$vss.initialized) return;
    const newViewStateHash = createHash($vss);
    store.update($s => ({
      ...$s,
      $viewState: $vss,
      bids: newViewStateHash === $s.viewStateHash ? $s.bids : applyViewState($s.$bidsRaw.bids, $vss),
      viewStateHash: newViewStateHash,
      initialized: true,
    }));
  });

  return () => {
    brsUnsubscribe();
    vssUnsubscribe();
    store.set({});
  }
});

export default store;

function applyViewState(bids = [], viewState = {}){
  return applyStateToBids(bids, viewState);
}

function applyStateToBids(bids, {tableFilteredBy, tableGroupedBy, tableSortedBy, quickFilterQuery = ''}){
  const queries = parseQuery(quickFilterQuery);
  const filteredBids = queries.length ? bids.filter(b => queries.every(q => b.$$quickSearchString.includes(q))) : bids;

  let result = applyFilter(
    tableFilteredBy,
    tableGroupedBy && tableGroupedBy.$filter ? tableGroupedBy.$filter.by : undefined,
    filteredBids);
  result = (tableGroupedBy || tableSortedBy) ? applySortBy(result, tableSortedBy, tableGroupedBy) : result;
  result = tableGroupedBy ? applyGroupBy(result, tableGroupedBy) : result;
  return result;

  function parseQuery(q = ''){
    const matches = q.match(/\w+|"[^"]*"/g) || [];
    return matches
        .map(s => s
          .replace(/^"|"$/g, '')
          .trim()
          .toLowerCase()
        )
        .filter(s => s.length);
  }
}

function applyFilter(filterValue, filterKey, bids){
  if(filterValue !== undefined && filterKey){ // apply filter and clone bids
    const br = [];
    for(let i=0, l=bids.length; i<l; i++){
      const b = bids[i];
      // noinspection EqualityComparisonWithCoercionJS
      if(get(b, filterKey, undefined) == filterValue){
        br.push({...b});
      }
    }
    return br;

  } else { // just clone bids
    const br = [];
    for(let i=0, l=bids.length; i<l; i++){
      br.push({...bids[i]});
    }
    return br;
  }
}

function applySortBy(bidsData, sortBy, groupedBy){
  const keys = [], directions = [];
  if(groupedBy) addGroupByToSort(groupedBy);
  if(sortBy) addSortByToSort(sortBy);
  return orderBy(bidsData, keys, directions);

  function addGroupByToSort(grouper){
    const
      groupByKey = get(grouper, '$groupable.by'),
      groupByText = get(grouper, '$groupable.text');

    if(groupByText) addToSort(groupByText);
    if(groupByKey) addToSort(groupByKey);
  }

  function addSortByToSort(sorter){
    const
      sortByKeys = get(sorter, 'by.$sortable', []),
      direction = sorter.direction;

    for(let i=0, l = sortByKeys.length; i<l; i++){
      addToSort(sortByKeys[i], direction);
    }
  }

  function addToSort(field, direction){
    keys.push(field);
    directions.push(direction === -1 ? 'desc' : 'asc');
  }
}

/*
 * data must be sorted by groupByKey first in order to this method to work properly.
 */
function applyGroupBy(bidsData, groupedBy){
  const
    groupByKey = get(groupedBy, '$groupable.by'),
    groupByText = get(groupedBy, '$groupable.text') || groupByKey,
    groupedData = [];
  let lastGroupByValue = null;

  for(let i=0, il = bidsData.length; i<il; i++){
    const bid = bidsData[i], currentBidGroupByValue = get(bid, groupByKey);

    if(currentBidGroupByValue != lastGroupByValue){
      lastGroupByValue = currentBidGroupByValue;
      groupedData.push({
        header: 'header',
        title: (groupByText && get(bid, groupByText, 'Undefined')) || currentBidGroupByValue
      });
    }
    groupedData.push(bid);
  }
  return groupedData;
}

function createHash({tableFilteredBy, tableGroupedBy, tableSortedBy, quickFilterQuery}){
  const o = JSON.stringify({tableFilteredBy, tableGroupedBy, tableSortedBy, quickFilterQuery});
  let hash = 0;
  for (let i = 0; i < o.length; i++) {
    const character = o.charCodeAt(i);
    hash = ((hash<<5)-hash)+character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
