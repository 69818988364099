import get from 'lodash/get';
import HTML from '../../main/bid/pages/response/bid-response.html';
import saveResponseService from 'root/rfp-hotel/main/bid/pages/response/save-response.service';

const
  // states
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR';

export { HTML, BidResponsePreloadController as Controller }

BidResponsePreloadController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state', 'MainAPI'];
function BidResponsePreloadController(utils, rfpHotelBidRepository, $state, mainAPI){
  const
    vm = this;

  vm.isLoading = () => vm.state === LOADING;
  vm.isLoaded = () => vm.state === LOADED;

  $onInit();

  function $onInit(){
    vm.state = LOADING;

    utils.onLoad( () => rfpHotelBidRepository.getBidResponseDraft($state.params.bidId) )
      .then( httpResponse  => {
        const bid = httpResponse.data;
        vm.options = getOptions(bid);
        vm.bid = bid;
        vm.state = LOADED;
      })
      .catch(() => {
        vm.state = ERROR;
      });
  }

  function getOptions(bid){
    return {
      header: {
        pretitle: get(bid, 'supplierCompany.name', ''),
        title: 'Respond to Competitive Bid',
        subtitle: get(bid, 'specifications.name', ''),
      },
      buttons: {
        cancel: {
          label: 'Cancel',
          action: saveResponseService.toBidManager
        },
        draft: {
          label: 'Save as Draft',
          action: answers => saveResponseService.saveDraftResponse(
            () => utils.onSave(() => mainAPI.saveCompetitiveBidDraftResponse(bid.id, answers))
          )
        },
        submit: {
          label: 'Send Response',
          action: response => saveResponseService.saveResponse(response, r => {
            const ignoreErrors = true;
            return utils.onSave(() => mainAPI.setCompetitiveBidResponse(bid.id, r.answers, ignoreErrors));
          })
        },
      }
    };
  }
}
