<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalYesNoButtons from "root/services/modals/ModalButtons.YesNo.svelte";
  import {notificationUtils, authAPI} from 'root/angular-injector-provider';
  import { currentUser } from 'root/rfp/reports/chain-portal/stores/group-sort-filter.store';

  export let hide, cancel;

  let message = '', loading, closing;

  async function submit() {
    loading = true;
    const payload = {
      emailAddress: $currentUser.emailAddress,
      name: `${$currentUser.firstName} ${$currentUser.lastName}`,
      message,
      job: $currentUser.currentUserAccount.jobTitle,
      corporateName: $currentUser.getAccount().name
    };
    await notificationUtils().onSave(() => authAPI().sendRequestAccessMessage(payload));
    closing = true;
    setTimeout(hide, 3000);
  }
</script>

<ClosableModal width="500px" close="{() => cancel()}">
  <div slot="heading">
    <div class="Title">Request Access</div>
  </div>

  <div class="ModalContent">
    <div class="Text">
      The functionality you tried is access is not available for your subscription tier. Please send us a message in the field below to request access.
    </div>

    {#if closing}
      <div class="Text Submitted"> Your request has been submitted. We will reach out to you shortly.</div>
    {:else}
      <textarea class="TextArea Text" placeholder="Please enter your message here..."  disabled={loading} bind:value={message}></textarea>
    {/if}
  </div>

  <ModalYesNoButtons
    noAction={() => cancel()}
    yesLabel="Request Access"
    disabled={!message || loading}
    yesIcon="send"
    yesAction={submit} />
</ClosableModal>

<style lang="stylus">.Title {
  font-weight: bold;
  font-size: 15px;
  color: #37474f;
  line-height: 24px;
  padding: 15px 15px 0;
}
.ModalContent {
  display: flex;
  padding: 0 15px;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.4;
  color: #000;
}
.Text {
  margin-bottom: 20px;
}
.TextArea {
  outline: none;
  border-radius: 5px;
  min-height: 200px;
  font-family: inherit;
  padding: 10px;
  box-sizing: border-box;
}
.Submitted {
  background: #00b8ab;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
</style>
