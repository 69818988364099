/**
 * All states should eventually be listed here
 */

import { abstractState, simpleState } from 'root/shared/tools/router-helper';
import { USER } from 'root/auth/auth-roles';
import noop from 'lodash/noop';
import PublicStates from './public.states';
import PublicAuthStates from './public-auth.states';
import AccountStates from './account.states';
import HotelRfpStates from './hotel-rfp.states';
import PublicHotelBidStates from './hotel-bids.states';
import {
  PAGE_COMPANY_PROFILE,
  PAGE_CURRENT_USER_PROFILE,
  PAGE_DUPLICATE_RFP,
  PAGE_HOTEL_DIRECTORY,
  PAGE_RFP_MANAGER_COMPANY_PROFILE,
  PAGE_SELECT_RFP_TEMPLATE
} from 'root/states';

export default States

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  PublicStates($stateProvider);
  PublicAuthStates($stateProvider);
  AccountStates($stateProvider);
  HotelRfpStates($stateProvider);
  PublicHotelBidStates($stateProvider);

  $stateProvider
    .state( 'user', abstractState('/user', USER))
    .state( PAGE_CURRENT_USER_PROFILE, simpleState('/profile', '<rbs-header /><rbv-ng-user-profile />', noop, USER))
    .state( PAGE_COMPANY_PROFILE, simpleState('/company-profile?module,accountId', '<rbs-header /><rbv-ng-account-profile />', noop, USER ) )
    .state( PAGE_RFP_MANAGER_COMPANY_PROFILE, simpleState('/rfp-manager/company-profile?module,accountId', '<rbs-header /><rbv-ng-rfp-manager-account-profile />', noop, USER ) )
    .state( PAGE_HOTEL_DIRECTORY, simpleState('/hotel-directory/{accountId}?submodule,view', '<rbs-header /><rbv-ng-hotel-directory />', noop, USER ) )

    .state('createRfp', abstractState('/create-rfp', USER))
    .state( PAGE_SELECT_RFP_TEMPLATE, simpleState('/select-template?accountId', '<rbs-header /><rbv-ng-select-rfp-template />', noop, USER))

    .state('duplicateRfp', abstractState('/duplicate-rfp', USER))
    .state(PAGE_DUPLICATE_RFP, simpleState('/select-template?rfpId,accountId' , '<rbs-header /><rbv-ng-duplicate-rfp />'));
}
