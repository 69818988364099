import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';
import Component from './RbBidManagerHeader.svelte';

export default {
  template: '<div></div>',
  controller: Ctrl,
  bindings: {
    views: '<',
    currentView: '<',
  }
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;
  Object.assign(this, SvelteNgComponent(Component, $element[0],
    () => ({
      views: vm.views,
      selectedView: vm.currentView,
    })));
}