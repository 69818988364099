import {
  notificationUtils,
  // httpErrorHandler,
  $state,
  AccountRepository,
  mainAPI,
  ngDialog
} from 'root/angular-injector-provider';

import {Dialog} from 'root/v-app/rbServices';
import {PAGE_COMPANY_PROFILE, PAGE_SELECT_RFP_TEMPLATE, PAGE_HOTEL_DIRECTORY, PAGE_RFP_MANAGER} from 'root/states';

import CreateCompanyDialog from '../dialogs/CreateCompanyDialog.vue';
import CompanyCreatedDialog from '../dialogs/CompanyCreatedDialog.vue';
import CreateNewCompanyDialog from '../dialogs/CreateNewCompanyDialog.vue';
import {ACCOUNT_BASIC_INFORMATION} from 'vRoot/account-profile/modules/basic-information/AccountBasicInformationModule';
import UpdateProfilePictureDialog from 'vRoot/_core/dialogs/UploadPhotoDialog.vue';
import ManageCompanyAssignmentsDialog from '../dialogs/CompanyAssignmentsDialog.vue';

import manageUsersService from 'vRoot/account-profile/modules/manage-users/services/account-manage-users-list.service';
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';
import EditPrimaryContactDialog from 'vRoot/account-profile/modules/manage-companies/dialogs/EditPrimaryContactDialog.vue';
import rfpManagerService from 'vRoot/rfp-manager/services/rfp-manager-actions.service';
import { ACCOUNT_COMPANY_PROFILE } from 'vRoot/account-profile/modules/company-profile/AccountCompanyProfileModule';
import CompanyAssignedDialog from 'vRoot/account-profile/modules/manage-companies/dialogs/CompanyAssignedDialog.vue';
import { NAME as EditRateLoadingInformationDialog } from 'root/entity/rate-loading-information/dialogs/rate-loading-information/rate-loading-information.ctrl'

export const COMPANY_TYPE = 'COMPANY';
export const TRAVEL_AGENCY_TYPE = 'TRAVEL_AGENCY';
export const TRAVEL_CONSULTANCY_TYPE = 'TRAVEL_CONSULTANCY';

function createCompany(accounts, state) {
  return new Promise((resolve, reject) => {
    Dialog.show(CreateCompanyDialog, {accounts}).then(
      ({action, data}) => {
        if(action === 'existing'){
          return createCompanyFromExistingEntity(data.id);
        }else{
          return showNewCompanyDialog(data.searchTerm);
        }
      }
    ).then(({data}) => {
      if(data.accountId) {
        showCompanyCreatedDialog(data, state);
        resolve(data);
      }else{
        Dialog.show(WarningDialog, {
          subTitle: `The customer you just tried to add is already being managed by another agency. Please contact your customer.`,
          title: 'Unable to add Customer',
          showCancel: true,
          showMainButton: false
        });
        reject(data);
      }
    }).catch(reject);
  });
}

function showNewCompanyDialog(name) {
  return new Promise((resolve, reject) => showManageCompanyDetailsDialog({name}).then((data) => {
      notificationUtils().onSave(() => AccountRepository().createAccountForCompany(data)).then(resolve).catch(reject);
    })
  );
}

function showManageCompanyDetailsDialog(company) {
  return getIndustries()
    .then(industries => Dialog.show(CreateNewCompanyDialog, {industries, company}));
}

function createCompanyFromExistingEntity(entityId) {
  return notificationUtils().onSave(() => AccountRepository()
    .addAccountForEntity({
      entityId,
      entityType: COMPANY_TYPE,
      jobTitle: 'NONE'
    }))
}

function showCompanyCreatedDialog({name, accountId}, state = PAGE_COMPANY_PROFILE) {
  Dialog.show(CompanyCreatedDialog, {name}).then(({action}) => {
    if(action === 'createRfp') {
      $state().inNew(PAGE_SELECT_RFP_TEMPLATE, {accountId});
    }else{
      $state().go(state, {module: ACCOUNT_COMPANY_PROFILE, accountId});
    }
  });
}

function searchCompany(term, page) {
  return AccountRepository().searchEntities(COMPANY_TYPE, term, page);
}

function getIndustries() {
  return AccountRepository().getIndustries();
}

function editCompanyProfile(account) {
  account.location = {
    ...account.location,
    coordinates: account.location && {
      lat: account.location.coordinates && account.location.coordinates.latitude,
      lng: account.location.coordinates && account.location.coordinates.longitude,
    }
  };

  return showManageCompanyDetailsDialog(account)
    .then(({entity}) => notificationUtils()
      .onSave(() => mainAPI().updateEntityDetails(account.type, account.entityId, entity)));
}

function editMyCompanyProfile() {
  return $state().go(PAGE_COMPANY_PROFILE, {module: ACCOUNT_BASIC_INFORMATION});
}

function editLogo(account) {
  const options = {
    croppieOptions: {
      viewport: { width: 260, height: 150 },
      boundary: { width: 490, height: 330 },
      enableResize: true,
      showZoomer: true
    }
  };

  return Dialog.show( UpdateProfilePictureDialog, { options, title: 'Upload Logo' }, { closeOnOutsideClick: false })
    .then( data => Promise.all( [data.file, account] ) )
    .then( data => notificationUtils().onSave(() => mainAPI().updateEntityLogo(data[1].type, data[1].entityId, data[0])))
}

function manageAssignments(accountId, account) {
  return manageUsersService.loadUsers()
    .then(users => Dialog.show(ManageCompanyAssignmentsDialog, {account, users}))
    .then(({user}) => mainAPI().assignAgentToCompany(accountId, user).then(() => {
      showAssignmentsChanged({...user, formerPrimaryAgentId: account.primaryAgent.userId});
      return Promise.resolve();
    }));
}

function showAssignmentsChanged(request) {
  return Dialog.show(CompanyAssignedDialog).then(
    () => notificationUtils().onSave(() => mainAPI().sendEmailNotificationForAccountPrimaryChange(request))
  )
}

function deleteTarget(accountId, account) {
  return Dialog.show(WarningDialog, {
    subTitle: `You are about to delete ${account.name}, This action will delete all RFPs and bids associated with the account and cannot be undone.`,
    showCancel: true
  }).then(() => notificationUtils().onSave(() => mainAPI().deleteCompany(accountId, account.id)));
}

function editPrimaryContact(account) {
  return Dialog.show(EditPrimaryContactDialog, {user: account.primaryContact || {}})
    .then(({data}) => notificationUtils().onSave(() => mainAPI().updatePrimaryContact(account.id, data)));
}

function openRfpManager() {
  $state().go(PAGE_RFP_MANAGER);
}

function openUsersAndPermissions() {
  return rfpManagerService.openUsersAndPermissions();
}

function openCompanyProfile(accountId, state = PAGE_COMPANY_PROFILE) {
  $state().go(state, {module: ACCOUNT_COMPANY_PROFILE, accountId});
}

function getAccountForForCurrentUrl(agencyId) {
  const accountId = $state().params.accountId;
  return agencyId && accountId?
    notificationUtils()
      .onLoad(() => mainAPI().findAccountManagedByAgency(agencyId, accountId)).then(({data}) => data):
    Promise.resolve(null);
}

function openHotelDirectory(accountId) {
  return $state().go(PAGE_HOTEL_DIRECTORY, {accountId})
}

function manageRateLoadingInformation(entityId) {
  ngDialog().show(EditRateLoadingInformationDialog, {locals: {entityId}}).catch(() => {});
  return entityId;
}

export default {
  createCompany,
  searchCompany,
  getIndustries,
  editCompanyProfile,
  editMyCompanyProfile,
  editLogo,
  manageAssignments,
  deleteTarget,
  editPrimaryContact,
  openRfpManager,
  openUsersAndPermissions,
  openCompanyProfile,
  getAccountForForCurrentUrl,
  openHotelDirectory,
  manageRateLoadingInformation
}
