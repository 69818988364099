/**
 * Created by DejanK on 7/6/2017.
 *
 */
export default NegotiationsHtmlFactory;

NegotiationsHtmlFactory.$inject = ['$compile'];
function NegotiationsHtmlFactory($compile){

  const divClose = '</div>',
    historyCell = '<div class="flex-hold rate">',
    NEG_VALUE_CHANGES = ['INC', 'DESC', 'CHANGE'];

  return {
    printRateHistory: printRateHistory,
    printAmenityHistory: printAmenityHistory,
    printTcosHistory: printTcosHistory,


    addColumnWithHeaderDiv: addColumnWithHeaderDiv,
    columnWithHeaderDiv: columnWithHeaderDiv,

    addCellDiv: addCellDiv,
    cellDiv: cellDiv,


    addCellDivWithInclude: addCellDivWithInclude,
    cellDivWithInclude: cellDivWithInclude,

    addDivClose: addDivClose,
    divClose: divClose,

    buildTooltip: buildTooltip,
    buildChangeNotificationClass: buildChangeNotificationClass,

    addHistoryRow: addHistoryRow,
    addNegotiatorCell: addNegotiatorCell,
    addHistoryCell: addHistoryCell,


    buildNegotiationsEditor: buildNegotiationsEditor
  };

  function addColumnWithHeaderDiv(html, label, classes){
    html.push(`<div class="flex-hold layout-column"><div class="neg-header ${classes || ''}">${label}</div>`);
  }

  function columnWithHeaderDiv(label, classes){
    return `<div class="flex-hold layout-column"><div class="neg-header ${classes || ''}">${label}</div>`;
  }

  function addCellDiv(html, text, classes, tooltipHtml){
    html.push(`<div class="neg-cell ${classes || ''}">
      <span>${text}</span>${tooltipHtml || ''}</div>`);
  }

  function cellDiv(text, classes, tooltipHtml){
    return `<div class="neg-cell ${classes || ''}"><span>${text}</span>${tooltipHtml || ''}</div>`
  }

  function addCellDivWithInclude(html, text, classes, included, tooltipHtml){
    html.push(`<div class="neg-cell ${classes}"><span>${text}</span>${tooltipHtml || ''}${included ? '<div class="included-notification">inc</div>' : ''}</div>`);
  }

  function cellDivWithInclude(text, classes, included, tooltipHtml){
    return `<div class="neg-cell ${classes}"><span>${text}</span>${tooltipHtml || ''}${included ? '<div class="included-notification">inc</div>' : ''}</div>`;
  }

  function buildChangeNotificationClass(change){
    const hasChange = NEG_VALUE_CHANGES.indexOf(change) !== -1;
    return (`${hasChange ? 'change-notification' : ''} change-notification-${change || 'none'}`).toLowerCase();
  }

  function buildTooltip(text){
    return `<rb-tooltip>${text}</rb-tooltip>`;
  }

  function addHistoryRow(html, isActive){
    html.push(`<div class="layout-row ${isActive ? 'active' : ''}">`);
  }

  function historyRow(isActive){
    return `<div class="layout-row ${isActive ? 'active' : ''}">`;
  }

  function addNegotiatorCell(html, negotiation){
    html.push(
      `<div class="negotiator">
  <div class="material-icons">forum</div>
  <div class="contact">
    ${negotiation.getContactName()} @ ${negotiation.getCompanyName()}
  </div>
  <div class="date">
    ${negotiation.getFormattedDateTime()}
  </div>
</div>`);
  }

  function negotiatorCell(contactName, companyName, dateTime){
    return `<div class="negotiator">
  <div class="material-icons">forum</div>
  <div class="contact">${contactName} @ ${companyName}</div>
  <div class="date">${dateTime}</div>
</div>`;
  }

  function addHistoryCell(html){
    html.push(`<div class="flex-hold rate">`);
  }

  function printRateHistory(communication, rateId, activeNegotiation){
    let html = '';

    for(let i=0,l=communication.length; i<l;i++) {
      const com = communication[i], rate = com.getRate(rateId);

      html += historyRow(com === activeNegotiation);
      html += negotiatorCell(com.getContactName(), com.getCompanyName(), com.getFormattedDateTime());
      html += historyCell;

      html += cellDiv(rate.getFormattedValue(), buildChangeNotificationClass(rate.change));

      html += divClose;
      html += divClose;
    }

    return html;
  }

  function printAmenityHistory(communication, amenityName, activeNegotiation, isMixed){
    let html = '';
    for(let i=0,l=communication.length; i<l;i++){
      const com = communication[i], amenity = com.getAmenity(amenityName);

      html += historyRow(com === activeNegotiation);
      html += negotiatorCell(com.getContactName(), com.getCompanyName(), com.getFormattedDateTime());
      html += historyCell;

      html += cellDivWithInclude(amenity.getFormattedValue(),  buildChangeNotificationClass(amenity.change), amenity.included);

      html += divClose;

      if(isMixed){
        html += historyCell;
        html += cellDiv(`(${amenity.getFormattedSecondaryValue()})`, 'secondary');
        html += divClose;
      }

      html += divClose;
    }
    return html;
  }

  function printTcosHistory(communication, tcosId, activeNegotiation){
    let html = '';

    for(let i=0,l=communication.length; i<l;i++){
      const com = communication[i], tcosList = com.getTcos(), tcos = tcosList[tcosId] || {};

      html += historyRow(com === activeNegotiation);
      html += negotiatorCell(com.getContactName(), com.getCompanyName(), com.getFormattedDateTime());
      html += historyCell;

      html += cellDiv(tcosList.formatValue(tcos.value), buildChangeNotificationClass(tcos.change));

      html += divClose;
      html += divClose;
    }

    return html;
  }

  function addDivClose(html){
    html.push('</div>');
  }

  function buildNegotiationsEditor(negotiations, lastNegotiations, $scope, userSide){
    return new Promise( resolve => {
      const seasons = negotiations.seasons,
        rates = negotiations.config.rates,
        amenities = negotiations.config.amenities;

      let html = '<div class="container" rb-fixed-on-scroll-container>';
      for(let seasonIndex=0, seasonsLength=seasons.count(); seasonIndex<seasonsLength; seasonIndex++) {
        html += headings(seasonIndex, seasons, negotiations.config.occupancies, rates, amenities);
        html += seasonNegotiations(seasons.seasons[seasonIndex], negotiations.config.roomTypes, rates, amenities);
      }
      html += '</div>';

      html = angular.element(html);
      $compile(html)($scope);

      resolve(html);
    });

    function seasonNegotiations(season, roomTypes, rates, amenities)
    {
      let html = '';
      for (let roomTypeIndex = 0, roomTypesLength = roomTypes.length; roomTypeIndex < roomTypesLength; roomTypeIndex++) {
        const roomType = roomTypes[roomTypeIndex];

        html += '<div class="negotiation-row">';

        html += roomTypeHtml(roomType.id);
        html += negotiators();
        html += ratesHtml(rates, season.id, roomType.id);
        html += amenitiesHtml(amenities);
        html += tcosHtml(season.id, roomType.id);
        html += '</div>';
      }
      return html;
    }

    function headings(seasonIndex, seasons, occupancies, rates, amenities)
    {
      let headingHtml = '<div class="headings" >',
        subheadingHtml = '<div class="sub-headings">';


      { // meta
        headingHtml += `<div class="season">
                          <div class="label"><i class="fa fa-calendar" aria-hidden="true"></i> Season ${seasons.getLabelByIndex(seasonIndex)}</div>
                          <div class="period">${seasons.getFormattedPeriodByIndex(seasonIndex)}</div>
                        </div>`;

        subheadingHtml += '<div class="room-type sub-heading">RT</div><div class="negotiators sub-heading">Negotiators</div>';
      }


      { // rates
        let lastRate = null;

        for(let rateIndex=0, ratesLength=rates.length; rateIndex<ratesLength; rateIndex++){
          const ratePrefix = rates[rateIndex];
          if(ratePrefix === 'dyn'){
            headingHtml += `<div class="rate span-1">${ratePrefix.toUpperCase()}</div>`;
            subheadingHtml += `<div class="rate"></div>`;

          } else {
            const rate = ratePrefix.slice(0, -1).toUpperCase();
            if(rate !== lastRate) {
              headingHtml += `<div class="rate span-${occupancies}">${rate}</div>`;
              lastRate = rate;
            }

            subheadingHtml += `<div class="rate">${ratePrefix.slice(-1) === 'S' ? 'Single': 'Double'}</div>`;
          }
        }
      }


      { // amenities
        const amenitiesLength = amenities.length;
        headingHtml += `<div class="amenity span-${amenitiesLength+1}"><span class="material-icons">local_offer</span> <span>Amenities</span></div>`;
        for(let amenityIndex=0; amenityIndex<amenitiesLength; amenityIndex++) {
          subheadingHtml += `<div class="amenity ">${amenities[amenityIndex].toUpperCase()}</div>`;
        }
        subheadingHtml += `<div class="amenity"><i class="material-icons">info</i> <span>TX</span></div>`;
      }

      { //tcos
        headingHtml += '<div class="tcos">TCOS</div>';
        subheadingHtml += '<div class="tcos"><i class="material-icons">info</i></div>';
      }

      headingHtml += '</div>';
      subheadingHtml += '</div>';

      return `<div class="headings-container">${ headingHtml }${ subheadingHtml }</div>`;
    }

    function roomTypeHtml(roomTypeId)
    {
      return `<div class="room-type">${roomTypeId}</div>`;
    }

    function negotiators()
    {
      let html = '<div class="negotiators">';
      for (let negotiationsIndex = 0, negotiationsLength = lastNegotiations.length; negotiationsIndex < negotiationsLength; negotiationsIndex++) {
        const com = lastNegotiations[negotiationsIndex], side = com.from.type === userSide ? 'mine' : 'other';
        html += `<div class="negotiator ${side}">
                      <div class="material-icons">forum</div>
                      <div class="contact">${com.getCompanyName()}</div>
                      <div class="date">${com.getFormattedDateTime()}</div>
                    </div>`;
      }
      html += '<div><div class="negotiator mine input"><div class="material-icons">forum</div><div class="contact centered" ng-bind="$ctrl.negotiations.company"></div></div></div>';
      html += '</div>';

      return html;
    }

    function ratesHtml(rates, seasonId, roomTypeId) {
      const rateSuffix = `_s${seasonId}_rt${roomTypeId}`;
      let html = '';

      for (let rateIndex = 0, ratesLength = rates.length; rateIndex < ratesLength; rateIndex++) {
        const r = rates[rateIndex];
        if(r === 'dyn'){
          if(seasonId === 1 && roomTypeId === 1) {
            generateHtml(r, 'rb-negotiations-input-dynamic-rate');
          } else {
            generateEmptyHtml();
          }
        } else {
          generateHtml(r + rateSuffix, 'rb-negotiations-input-rate');
        }
      }

      return html;

      function generateHtml(rateId, rateComponent){
        html += '<div class="rate">';

        for (let negotiationsIndex = 0, negotiationsLength = lastNegotiations.length; negotiationsIndex < negotiationsLength; negotiationsIndex++) {
          const rate = lastNegotiations[negotiationsIndex].getRate(rateId);
          html += cellDiv(rate.getFormattedValue(), buildChangeNotificationClass(rate.change) + (negotiationsIndex === 0 ? ' first' : ' second'));
        }
        html += `<div class="neg-cell input"><${rateComponent} class="rb-negotiations-input" negotiations="$ctrl.negotiations" model="$ctrl.model.rates.${rateId}"></${rateComponent}></div>`;
        html += `<rb-negotiations-input-rate-tooltip class="hidden" negotiations="$ctrl.negotiations" rate-id="${rateId}"></rb-negotiations-input-rate-tooltip>`;
        html += '</div>';
      }

      function generateEmptyHtml(){
        html += '<div class="rate">';
        for (let negotiationsIndex = 0, negotiationsLength = lastNegotiations.length; negotiationsIndex < negotiationsLength; negotiationsIndex++) {
          html += cellDiv('', '');
        }
        html += `<div class="neg-cell input"></div>`;
        html += '</div>';
      }
    }

    function amenitiesHtml(amenities) {
      // eslint-disable-next-line prefer-template
      return '<div class="group-amenities blurred" rb-opacity-on-scroll-y>'
        + otherAmenities(amenities)
        + taxes()
        + '</div>';
    }


    function taxes()
    {
      let html = '<div class="amenity">';

      for (let negotiationsIndex = 0, negotiationsLength = lastNegotiations.length; negotiationsIndex < negotiationsLength; negotiationsIndex++) {
        const com = lastNegotiations[negotiationsIndex], taxesSummary = com.getTaxesSummary(), total = taxesSummary.total || {};
        html += cellDiv(taxesSummary.getFormattedTotal(), buildChangeNotificationClass(total.change) + (negotiationsIndex === 0 ? ' first' : ' second'),
          `<rb-negotiations-taxes-summary negotiations="$ctrl.negotiations" negotiation-id="${com._id}"></rb-negotiations-taxes-summary>`
        );
      }
      html += '<div class="neg-cell input"><rb-negotiations-input-taxes class="rb-negotiations-input readonly no-highlight" negotiations="$ctrl.negotiations" model="$ctrl.model" primary-rate="$ctrl.primaryRate"></rb-negotiations-input-taxes></div>';
      html += '</div>';

      return html;
    }

    function otherAmenities(amenities)
    {
      let html = '';

      for (let amenityIndex = 0, amenitiesLength = amenities.length; amenityIndex < amenitiesLength; amenityIndex++) {
        html += '<div class="amenity">';
        const amenityId = amenities[amenityIndex];

        for (let negotiationsIndex = 0, negotiationsLength = lastNegotiations.length; negotiationsIndex < negotiationsLength; negotiationsIndex++) {
          const amenity = lastNegotiations[negotiationsIndex].getAmenity(amenityId);
          html += cellDivWithInclude(amenity.getFormattedValue(), buildChangeNotificationClass(amenity.change) + (negotiationsIndex === 0 ? ' first' : ' second'), amenity.included);
        }

        html += `<div class="neg-cell input"><rb-negotiations-input-amenity class="rb-negotiations-input"
          negotiations="$ctrl.negotiations"
          amenity-id="${amenityId}"
          side="$ctrl.side"
          model="$ctrl.model.amenities.${amenityId}"></rb-negotiations-input-amenity></div>`;

        html += `<rb-negotiations-input-amenity-tooltip class="hidden"
                  negotiations="$ctrl.negotiations"
                  amenity-id="${amenityId}"
                  primary-rate="$ctrl.primaryRate"
                  side="$ctrl.side"
                  model="$ctrl.model.amenities.${amenityId}"></rb-negotiations-input-amenity-tooltip>`;
        html += '</div>';
      }

      return html;
    }

    function tcosHtml(seasonId, roomTypeId) {
      const tcosId = `tcos_s${seasonId}_rt${roomTypeId}`;
      let html = '<div class="tcos">';

      for (let negotiationsIndex = 0, negotiationsLength = lastNegotiations.length; negotiationsIndex < negotiationsLength; negotiationsIndex++) {
        const tcosObj = lastNegotiations[negotiationsIndex].getTcos(), tcos = tcosObj[tcosId] || {};
        html += cellDiv( tcosObj.formatValue(tcos.value), buildChangeNotificationClass(tcos.change) + (negotiationsIndex === 0 ? ' first' : ' second'));
      }
      html += `<div class="neg-cell input"><rb-negotiations-input-tcos class="rb-negotiations-input" negotiations="$ctrl.negotiations" model="$ctrl.model" season="${seasonId}" room-type="${roomTypeId}"></rb-negotiations-input-tcos></div>`;
      html += `<rb-negotiations-input-tcos-tooltip class="hidden" negotiations="$ctrl.negotiations" season="${seasonId}" room-type="${roomTypeId}"></rb-negotiations-input-tcos-tooltip>`;
      html += '</div>';
      return html;
    }
  }
}
