/**
 * Created by DejanK on 8/11/2016.
 */
import './sign-in.scss'
import HTML from './sign-in.html'
import {BAD_CREDENTIALS, UNKNOWN_USER} from '../../shared/sign-in.service';

export { HTML , SignInController as Controller }

SignInController.$inject = ['SignInService', 'CurrentUser', 'AuthSession'];
function SignInController(SignInService, currentUser, authSession) {
  const vm = this;
  vm.submit = submit;

  function submit() {
    SignInService.signIn(vm.credentials)
      .then(currentUser.refresh)
      .then(authSession.continue, (error) => {
        switch (error){
          case BAD_CREDENTIALS:
            vm.authError = 'Your email or password is incorrect. Please try again.';
            break;
          case UNKNOWN_USER:
            vm.authError = `User <${vm.credentials.email}> does not exist!`;
            break;
          default:
            vm.authError = ''
        }
      });
  }
}
