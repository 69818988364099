/**
 * Created by DejanK on 11/21/2016.
 */
export default AccountRepository

AccountRepository.$inject = ['MainServer', 'DataServer'];
function AccountRepository(MainServer, DataServer){
  const repo = this;

  repo.getIndustries = DataServer.getIndustries;
  repo.searchEntities = searchEntities;
  repo.addAccount = (accountData) => { return MainServer.post('user-accounts/add', accountData)};
  repo.addAccountForEntity = (accountData) => { return MainServer.post('user-accounts/add-account-only', accountData)};
  repo.createAccountForCompany = (accountData) => { return MainServer.post('user-accounts/create-account', accountData) };
  repo.getAccount = (accountId) => { return MainServer.get(`account/${accountId}`) };
  repo.loadAccountUsers = () => MainServer.get('account-users/list');
  repo.loadAuthorizations = () => MainServer.get('current-user/with-authorizations');
  repo.sendAgentInvitation = (accountId, data) => MainServer.post(`account-users/create-invite/${accountId}`, data);
  repo.updateUser = (accountId, data) => MainServer.post(`account-users/edit-user/${accountId}`, data);
  repo.confirmUser = (accountId, userId) => MainServer.post(`account-users/confirm-user/${accountId}/${userId}`);
  repo.deactivateUser = (accountId, userId) => MainServer.post(`account-users/deactivate-user/${accountId}/${userId}`);
  repo.deleteUser = (accountId, data) => MainServer.post(`account-users/delete-user/${accountId}/`, data);
  repo.updateAssignments = (accountId, data) => MainServer.post(`account-users/update-permissions/${accountId}`, data);
  repo.sendAssignmentsChangedEmails = data => MainServer.post(`account-users/send-assignment-notifications/`, data);
  repo.resendUserInvitation = (accountId, invitationId) => MainServer.put(`account-users/resend-invite/${accountId}/${invitationId}`);
  repo.removeInvitation = (accountId, invitationId) => MainServer.delete(`account-users/${accountId}/remove-invite/${invitationId}`);
  repo.changeAccountType = (accountId, data) => MainServer.post(`account-users/update-account-type/${accountId}`, data);


  function searchEntities(type, query, page){
    return MainServer.get(getUrlForType(type), {query: query, page: page || 1});

    function getUrlForType(entityType){
      switch(entityType){
        case 'HOTEL':
          return 'hotels/search';
        case 'COMPANY':
          return 'companies/search';
        case 'CHAIN':
          return 'hotel-chains/search';
        case 'HMC':
          return 'hotel-management-companies/search';
        case 'TRAVEL_AGENCY':
          return 'travel-agencies/search';
        case 'TRAVEL_CONSULTANCY':
          return 'travel-consultancies/search';
        default:
          throw new Error(`Unknown type: ${entityType}`)
      }
    }
  }
}
