/**
 * Created by DejanK on 8/17/2016.
 */
import '../dialogs.scss'
import '../notifications.scss'
import HTML from './not-implemented-notification.html'

const NAME = 'notImplementedNotification'

export { NAME, HTML, NotImplementedNotificationController as Controller }

NotImplementedNotificationController.$inject = ['rbDialog']
function NotImplementedNotificationController(dialog) {
  const vm = this
  vm.cancel = dialog.cancel
}
