<div class="Component {$state ? 'Minimized' : ''}">
  {#if $state}
    <Minimized {menu} bind:minimized="{minimized}" {before}>
      <span slot="after"><slot minimized={true} name="after"/></span>
    </Minimized>
  {:else}
    <Maximized {menu} {title} {subtitle} bind:minimized="{minimized}" {before}>
      <span slot="after"><slot maximized={true} name="after"/></span>
    </Maximized>
  {/if}
</div>


<script>
  import Maximized from './NavigationMaximized.svelte';
  import Minimized from './NavigationMinimized.svelte';
  import { createNavState } from 'root/components/organisms/SideNavigation/SideNavigationPinService.js';

  export let id;
  export let title = '';
  export let subtitle = '';
  export let menu = [];
  export let before;

  let state = createNavState(id);
  let minimized = $state;
  $: updatePinned(minimized);

  function updatePinned(value){
    state.setPinned(value);
  }
</script>

<style lang="stylus">.Component {
  width: 280px;
  margin-top: -8px;
  height: calc(100% + 8px);
  background: #313c42;
  border-radius: 0 10px 0 0;
  color: #b0bec5;
  font-size: 13px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
  position: relative;
  display: flex;
  flex-direction: column;
}
.Component.Minimized {
  max-width: 48px;
}
</style>
