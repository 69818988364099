/**
 * Created by DejanK on 3/20/2017.
 */
export default UserRepository

UserRepository.$inject = ['MainServer'];
function UserRepository(MainServer){
  const $repo = this;

  $repo.getCurrentUser = () => { return MainServer.get('current-user') };
  $repo.listActiveCurrentUserUserAccounts = () => { return MainServer.get('current-user/user-accounts/active')};
  $repo.listAllUserAccounts = () => { return MainServer.get('current-user/user-accounts/all')};
  $repo.disableTutorial = (tutorialName) => { return MainServer.delete(`current-user/tutorials/${tutorialName}`)};
  $repo.enableTutorial = (tutorialName) => { return MainServer.put(`current-user/tutorials/${tutorialName}`)};
  $repo.switchToAccount = (accountId) => { return MainServer.post('current-user/account', {accountId: accountId})};
  $repo.updateBasicProfile = (data) => { return MainServer.put('current-user/basic-info', data)}
  $repo.updateJobTitle = (data) => { return MainServer.put('current-user/job-title', data)}
  $repo.updateUserEmailAddress = data => MainServer.put('current-user/email-address', data)
  $repo.updateUserPassword=(data) =>{return MainServer.put('current-user/password',data)}
  $repo.updateProfilePicture = fd => MainServer.sendFile('current-user/profile-picture', fd)
  $repo.activateUserAccount = userAccountId => MainServer.post(`current-user/user-accounts/${userAccountId}/activate`)
}
