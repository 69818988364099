<template>
  <rb-modal @cancel="cancel()" @hide="submit()" :options="modalOptions" :class="$style.dialog">
    <span slot="header">Send Final Agreements</span>
    <div slot="body">
      <p :class="$style.paragraph">
        You have chosen to send Final Agreements to {{ report.bids.length }} bids as a bulk action.
      </p>

      <p :class="$style.paragraph">
        This operation will set all rates as Accepted in selected bids that do not have accepted bids set.
      </p>

      <p :class="$style.paragraph">
        Are you sure you want to continue?
      </p>
    </div>

    <div slot="footer">
      <button v-if="allowSkipEmails" class="rbButton" @click="submit({skipEmails: true})">Yes (NO EMAILS)</button>

      <button class="rbButton" @click="cancel()">No</button>
      <button class="rbButton mainButton" @click="submit()">Yes</button>
    </div>
  </rb-modal>
</template>

<script>

  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbModal from 'vRoot/_core/RbModal.vue'
  import {notificationUtils, mainAPI, currentUser} from 'root/angular-injector-provider';

  export default {
    name: 'RbvHotelRfpBidManagerActionSendFinalAgreements',

    components: { RbModal },

    mixins: [ DialogMixin, RbEventsMixin ],

    props: { 'report': {
        required: true,
        type: Object
      } },

    data () {
      return {
        modalOptions: {}
      }
    },

    asyncComputed: {
      async allowSkipEmails () {
        const bidsStatuses = this.report.statuses;
        if(bidsStatuses.length === 1 && bidsStatuses[0] === 'BUYER_DRAFT'){
          const cu = await currentUser().get();
          return Promise.resolve(cu.isPowerUser());
        } else {
          return Promise.resolve(false);
        }
      }
    },

    methods: {
      submit (options = {}) {
        notificationUtils().onSave( () => mainAPI().sendFinalAgreements(this.report.bidsIds, options.skipEmails) )
          .then( report => this.hide(report), err => this.cancel(err) )
      }
    }
  }

</script>
<style lang="stylus" module="">
  .dialog {
    max-width: 400px;
    font-size: 12px;
    color: #263238;
  }

  .paragraph {
    margin-bottom: 13px;
  }
</style>
