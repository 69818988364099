import { checkAndExecute } from 'root/api/helpers';

export default function EmailApi(api) {

  Object.assign(api, {
    email: {
      byId: id => checkAndExecute(
        () => id,
        () => api.server.get(`emails/${id}`)
      ),
      byBidId: bidId => checkAndExecute(
        () => bidId,
        () => api.server.get(`emails`, {bid:bidId})
      )
    }});
}
