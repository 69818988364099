import {isEqual, isUndefined, omitBy, each, isObject } from 'lodash'

export { hasNotChanged, rbParseAttribute }

function hasNotChanged(val1, val2){
  return isEqual(deepOmitBy(val1, isUndefined), deepOmitBy(val2, isUndefined));

  function deepOmitBy(obj, predicate) {
    // basic _.omit on the current object
    const r = omitBy(obj, predicate)

    //transform the children objects
    each(r, (val, key) => { if (isObject(val)){ r[key] = deepOmitBy(val, predicate) } })

    return r
  }
}

/**
 * Parses HTML element attribute string into Object
 * String should be like attr="prop: value, prop2: value"
 */
function rbParseAttribute(attributeValue){
  const result = {}

  if(attributeValue) {
    attributeValue.split(',').forEach((pair) => {
      const p = pair.split(':')
      result[p[0].trim()] = p[1].trim()
    })
  }

  return result
}

