/**
 * Stores selections and actions that can be performed with bids
 */

import {default as bidsViewStore} from 'rfp/bid-manager/main/stores/bids-view';
import {default as viewConfigStore} from 'rfp/bid-manager/main/stores/view-config';
import {writable, get} from 'svelte/store';
import {BidManagerBidActionsService} from 'root/angular-injector-provider';

const store = writable({}, () => {
  const bvsUnsubscribe = bidsViewStore.subscribe($bvs => {
    if(!$bvs.initialized) return;

    store.update($s => {
      const bids = $bvs.bids.filter(bid => !bid.header),
        newSelected = filterOldSelectionWithNewBids($s.selected, bids)

      return {
        ...$s,
        bids,
        ...createSelectionReport(newSelected, bids),
        ...createActionsReport(newSelected),
        initialized: true,
      };
    });
  });

  return () => {
    bvsUnsubscribe();
    store.set({});
  };

  function filterOldSelectionWithNewBids(oldSelection, bids){
    const oldSelectionIds = oldSelection ? Object.keys(oldSelection) : [];
    if(oldSelectionIds.length){
      return bids.reduce((acc, bid) => {
        if(oldSelectionIds.indexOf(bid._id) !== -1) acc[bid._id] = bid;
        return acc;
      }, {});
    } else {
      return {};
    }
  }
});

export default store;

export function toggleAllBidsSelected(){
  store.update($s => {
    const newSelected = $s.selectedCount === $s.bids.length ? {} : $s.bids.reduce((acc, bid) => {
      acc[bid._id] = bid;
      return acc;
    }, {});

    return getUpdatedStoreObject($s, newSelected);
  });
}

export function unselectAllBids(){
  store.update($s => getUpdatedStoreObject($s, {}));
}

export function toggleBidSelected(event, bidId){
  store.update($s => {
    const newSelected = $s.selected[bidId]
      ? removeSelected(bidId, $s.selected)
      : setSelected(event.shiftKey, bidId, $s.selected, $s.bids);

    return getUpdatedStoreObject($s, newSelected);
  });
}

function getUpdatedStoreObject(currentStore, newSelected){
  return {
  ...currentStore,
  ...createSelectionReport(newSelected, currentStore.bids),
  ...createActionsReport(newSelected)
  }
}

function removeSelected(bidId, selected){
  delete selected[bidId];
  return selected;
}

function setSelected(isMultiSelect, bidId, selected, bids){
  const bidsToAdd = isMultiSelect
    ? getBidsToSelect()
    : {[bidId]: bids.find(bid => bid._id === bidId)}
  return {...selected, ...bidsToAdd}

  function getBidsToSelect(){
    return bids.reduce((acc, bid) => {
      if(acc.done) { // we have added all that was needed
        return acc;
      }
      else if(bid._id === bidId) { // this is final bid to add
        acc.selected[bidId] = bid;
        acc.done = true
        return acc;
      } else if(selected[bid._id]){ // this bid was selected, so either empty previously selected or start adding
        return acc.shouldAdd ? {...acc, selected: {}} : {...acc, shouldAdd: true}
      } else { // this bid was not selected, so either set it in selection or skip
        return acc.shouldAdd
          ? {...acc, selected: {...acc.selected, [bid._id]: bid}}
          : acc
      }
    }, {selected: {}, done: false, shouldAdd: false}).selected
  }
}

function createSelectionReport(selected = {}, bids = []){
  const selectedCount = Object.keys(selected).length;
  return {
    selected,
    selectedCount,
    allSelected: selectedCount === bids.length,
    noneSelected: selectedCount === 0
  };
}

function createActionsReport(selectedBids = {}) {
  const selectedBidsArray = Object.values(selectedBids),
    actions = selectedBidsArray.length
      ? BidManagerBidActionsService().getActions(selectedBidsArray, get(viewConfigStore))
      : {},
    actionsKeys = Object.keys(actions || {});

  return {
    actions,
    actionsCount: actionsKeys.reduce((acc, key) => {
      const actionCount = actions[key].length;
      acc.all += actionCount;
      if (key !== 'primary') acc.more += actionCount;
      return acc;
    }, {all: 0, more: 0}),
  };
}
