/**
 * Created by stefan on 9/2/2016.
 */


'use strict';
import {isValidToken} from "./token";

export default AuthService

AuthService.$inject = ['$q', 'CurrentUser', 'AuthorizationStorageService'];
function AuthService($q, CurrentUser, AuthStorageService) {
  const $service = this;

  $service.isAuthenticated = isAuthenticated;
  $service.isAuthorized = isAuthorized;

  init();

  function init(){
    isAuthenticated()
      .then(CurrentUser.refresh);
  }

  function isAuthenticated(){
    return isValidToken(AuthStorageService.getToken()) ? $q.resolve() : cleanStorageAndReject();

    function cleanStorageAndReject(){
      CurrentUser.forget()
      return $q.reject();
    }
  }

  function isAuthorized(roles){
    return isAuthenticated()
      .then(CurrentUser.get)
      .then(checkAuthorizations, checkAuthorizations);

    function checkAuthorizations(currentUser){

      for(let i=0, il=roles.length; i<il; i++){
        if(roles[i](currentUser)) {
          return $q.resolve()
        }
      }
      return $q.reject();
    }
  }
}


