<template>
  <div :class="$style.page">
    <div :class="$style.container">
      <rbv-loading label="Checking, please wait..." />
    </div>
  </div>
</template>

<script>
    const URL_HASH = '#!';

    import RbvLoading from 'vRoot/_core/RbvLoading.vue';
    import invitationsService from '../invitations-handler.service';
    import {$state} from 'root/angular-injector-provider';

    export default {

        name: 'InvitationCheck',

        components: { RbvLoading },

        mounted() {
            this.handle();
        },

        methods: {
            handle() {
                invitationsService.handleInvitation()
                 .then(() => {
                     window.location.href = window.location.href.replace(new RegExp(`${URL_HASH}.*$`), `${URL_HASH}/${$state().params.target}`);
                 })
                 .catch(err => invitationsService.exit(this.handle, err));
            },
        },
    }
</script>

<style lang="stylus" module>
    .page {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container {
        background: #fff;
        box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 5px;
        color: #546E7A;
    }
</style>
