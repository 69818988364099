/**
 * Created by DejanK on 1/16/2017
 *
 * rb-google-service
 * Provides Google objects
 */

'use strict';

let lodash = require('lodash');

module.exports = RbGoogleService;

RbGoogleService.$inject = ['$interval', '$q', '$window'];
function RbGoogleService($interval, $q, $window){
  let $service = this, GoogleMaps;

  $service.get = getGoogle;
  $service.getMap = getGoogleMap;
  $service.newAnimation = newAnimation;
  $service.geocode = geocode;

  function getGoogle(){
    const defer = $q.defer()
    const interval = $interval(()=>{
      if($window.google){
        $interval.cancel(interval);
        defer.resolve($window.google);
      }
    }, 20);

    return defer.promise;
  }

  function getGoogleMap(){
    let defer = $q.defer(), getMapsInterval ;

    getMapsInterval = $interval(()=>{
      GoogleMaps = lodash.get($window , 'google.maps');
      if(GoogleMaps){
        $interval.cancel(getMapsInterval);
        $service.CustomWindow = getCustomWindow(GoogleMaps);
        defer.resolve(GoogleMaps);
      }
    }, 20);

    return defer.promise;
  }

  function newAnimation(animation){
    if(animation && GoogleMaps){
      switch (animation.toLowerCase()) {
        case 'bounce':
          return GoogleMaps.Animation.BOUNCE;
        case 'drop':
          return GoogleMaps.Animation.DROP;
      }
    }
  }

  function geocode(address){
    return getGoogleMap()
      .then((GoogleMaps) => {
        let defer = $q.defer(),
          geocoder = new GoogleMaps.Geocoder();
        geocoder.geocode({address:address}, (results, status)=>{
          if (status === 'OK') {
            let loc = results[0].geometry.location;
            defer.resolve({lat: loc.lat(), lng: loc.lng()});
          } else {
            defer.reject(status);
          }
        });
        return defer.promise;
      });
  }



  function getCustomWindow(GoogleMaps) {

    /**
     * Create a custom overlay for our window marker display, extending google.maps.OverlayView.
     * This is somewhat complicated by needing to async load the google.maps api first - thus, we
     * wrap CustomWindow into a closure, and when instantiating CustomNativeWindow, we first execute the closure
     * (to create our CustomNativeWindow function, now properly extending the newly loaded google.maps.OverlayView),
     * and then instantiate said function.
     * @type {Function}
     * @see _mapView.onRender
     */

    function CustomWindow(element, offset) {
      this.container = element;
      this.offsetX = offset[0] * 1;
      this.offsetY = offset[1] * 1;
      this.layer = null;
      this.marker = null;
      this.position = null;
    }

    /**
     * Inherit from OverlayView
     * @type {google.maps.OverlayView}
     */
    CustomWindow.prototype = new GoogleMaps.OverlayView();
    //CustomWindow.prototype = function(){}; //new google.maps.OverlayView();
    /**
     * Called when this overlay is set to a map via this.setMap. Get the appropriate map pane
     * to add the window to, append the container, bind to close element.
     * @see CustomWindow.open
     */
    CustomWindow.prototype.onAdd = function () {
      this.layer = this.getPanes().floatPane;
      this.layer.appendChild(this.container);
    };
    /**
     * Called after onAdd, and every time the map is moved, zoomed, or anything else that
     * would effect positions, to redraw this overlay.
     */
    CustomWindow.prototype.draw = function () {
      if(this.getProjection()){
        this.position = this.getProjection().fromLatLngToDivPixel(this.marker.getPosition());
        this.container.style.top = this.position.y + this.offsetY + 'px';
        this.container.style.left = this.position.x + this.offsetX + 'px';
      }
    };
    /**
     * Called when this overlay has its map set to null.
     * @see CustomWindow.close
     */
    CustomWindow.prototype.onRemove = function () {
      this.layer.removeChild(this.container);
    };
    /**
     * Sets the contents of this overlay.
     * @param {string} html
     */
    CustomWindow.prototype.setContent = function (html) {
      this.container.innerHTML = html;
    };
    /**
     * Sets the map and relevant marker for this overlay.
     * @param {google.maps.Map} map
     * @param {google.maps.Marker} marker
     */
    CustomWindow.prototype.open = function (map, marker) {
      this.marker = marker;
      this.setMap(map);
      this.draw()
    };

    /**
     * Close this overlay by setting its map to null.
     */
    CustomWindow.prototype.close = function () {
      this.setMap(null);
    };

    return CustomWindow;
  }
}
