/**
 * Created by DejanK on 4/27/2017.
 *
 * Bid Manager Custom Actions
 */
import {DEMO} from 'config/app.config'
import './rb-bid-manager-custom-actions.scss'
import HTML from './rb-bid-manager-custom-actions.html'
import {PAGE_HOTEL_DIRECTORY} from 'root/states';
import {default as bidManagerStore} from 'rfp/bid-manager/main/stores/bid-manager';
import {SvelteDialog} from 'root/v-app/rbServices';
import AddHotelsDialog from '../rb-bid-manager-add-hotels/BidManagerAddHotelsDialog.svelte'

export default{
  template: HTML,
  controller: RbBidManagerCustomActions
}

RbBidManagerCustomActions.$inject = ['BidManagerRepository', 'NotificationUtils', '$state', '$scope']
function RbBidManagerCustomActions(bidManagerRepository, notificationUtils, $state, $scope) {
  const vm = this,
    bmsUnsubscribe = bidManagerStore.subscribe($bms => {
    vm.bidManager = $bms;
    $scope.$applyAsync();
  });

  vm.$onDestroy = () => {
    bmsUnsubscribe();
  }

  vm.onOpenHotelDirectory = onOpenHotelDirectory;
  vm.shouldShowAddHotels = view => view.rfpType === 'HOTEL' && view.side === 'BUYER';
  vm.onAddHotels = onAddHotels;
  vm.shouldShowRateAudit = view => DEMO && view.side === 'BUYER';
  vm.shouldShowOpenHotelDirectory = view => view.side === 'BUYER';
  vm.shouldShowReports = view => view.side === 'BUYER';

  function onOpenHotelDirectory(){
    notificationUtils
      .onLoadWithNotification(() => bidManagerRepository.getAccountIdFromBidManagerViewId(vm.bidManager.view.id))
      .then(({data: {id}}) => $state.go(PAGE_HOTEL_DIRECTORY, {accountId: id}));
  }

  function onAddHotels(){
    SvelteDialog.show(AddHotelsDialog, {viewType: vm.bidManager.view.type, rfpId: vm.bidManager.view.rfpId});
  }
}
