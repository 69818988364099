<template>
  <div
      class="vModal vModalDefault"
      :class="$style.dialog"
  >
    <div :class="$style.header">
      <img
          src="~images/ui/icon-warning.svg"
          :class="$style.headerIcon"
      >

      <div>
        <h2 :class="$style.headerSub">
          <span :class="$style.headerSubText">Please Note</span>
        </h2>

        <h1 :class="$style.headerMain">
          <span :class="$style.headerMainText">{{ title }}</span>
        </h1>
      </div>

      <button
          class="vModalDefaultCloseButton"
          @click="hide()"
      >
        close
      </button>
    </div>

    <div :class="$style.modalBody">
      <div
          :class="$style.bodyText"
          v-html="message"
      />

      <div :class="$style.list" style="flex: 1 0 auto; margin-bottom: 15px">
        <div :class="$style.listHeading">
          <div :class="$style.listHeadingName">
            Bid - Supplier Name
          </div>

          <div :class="$style.listHeadingStatus">
            Bid Status
          </div>
        </div>

        <report-item
            v-for="item in items"
            :key="item.tObject._id"
            :report="item"
            :messages="messages"
        />
      </div>

      <div
          v-if="side === 'SUPPLIER'"
          :class="$style.bodyText"
      >
        <h2 style="color:red; margin: 10px 0">BUT YOU HAVE NOT SENT THE BIDS</h2>

        <div style="line-height: 18px">
          To send bids to customer, you need to select ALL Hotels and Click SEND BIDS. If Bid Status shows "Bid Incomplete" you may want to check the box, then select Edit or Update bid on very top... Then Send.<br />
        </div>

        <div style="color: black; margin-top: 10px;">
          Remember This... IF you can see the Rates (static or dynamic) on the Bid Manager so can your customer
        </div>
      </div>
    </div>

    <div class="vModalDefaultFooter">
      <slot name="footer">
        <div
            v-if="side === 'SUPPLIER'"
            style="text-align: left"
        >
          <div data-icon-before="help">
            <a
                style="font-size: 13px; margin-left: 5px;"
                href="https://invis.io/F6ZMRMAP7ST"
                target="_blank"
            >
              Upload Tutorial
            </a>
          </div>
        </div>
        <button
            v-else
            class="rbButton mainButton"
            :class="$style.okButton"
            @click="hide()"
        >
          OK
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
import ActionReportMixin from './ActionReportMixin.vue'

export default {
  name: 'rbv-hotel-rfp-bid-manager-upload-responses-action-report',
  mixins: [ ActionReportMixin ],
  props: {
    side: {
      type: String,
      default: 'SUPPLIER'
    }
  },
  data () {
    return {
      messages: {
        'OK': 'Upload Successful',
        'NOT_FOUND': 'Upload Failed - Bid Deleted',
        'STATUS_CHANGED': 'Upload Failed - Bid Changed',
        'ERROR': 'Upload Failed - Unable to match Bid with Response'
      },
    }
  },
  computed: {
    title () { return this.items.length === 1 ? "Response upload failed!" : "Response upload for some bids have failed!" },
    message () {
      return this.items.length === 1
          ? "We were not able to upload response to a bid because of the reason listed below:"
          : `${this.items.length - this.failures} of ${this.items.length} bids responses were successfully uploaded.<br /> We were not able to upload responses to all bids because of the reasons listed below:`
    }
  }
}
</script>
