<template>
  <div
      class="vModal vModalDefault"
      :class="$style.dialog"
  >
    <div :class="$style.header">
      <img
          src="~images/ui/icon-warning.svg"
          :class="$style.headerIcon"
      >
      <div>
        <h2 :class="$style.headerSub">
          <span :class="$style.headerSubText">You have uploaded {{uploadedCountText}}</span>
        </h2>

        <h1 :class="$style.headerMain">
          <span :class="$style.headerMainText">{{ errorsCountText }}</span>
        </h1>
      </div>
      <button
          class="vModalDefaultCloseButton"
          @click="hide()"
      >
        close
      </button>
    </div>
    <div :class="$style.modalBody">
      <div
          v-if="side === 'SUPPLIER'"
          :class="$style.bodyText"
      >
        <h2 style="color:red; margin: 10px 0">BUT YOU HAVE NOT SENT THE BIDS</h2>

        <div style="line-height: 18px">
          To send bids to customer, you need to select ALL Hotels and Click SEND BIDS. If Bid Status shows "Bid Incomplete" you may want to check the box, then select Edit or Update bid on very top... Then Send.<br />
        </div>

        <div style="color: black; margin-top: 10px;">
          Remember This... IF you can see the Rates (static or dynamic) on the Bid Manager so can your customer
        </div>
      </div>
    </div>
    <div class="vModalDefaultFooter">
      <slot name="footer">
        <div
            v-if="side === 'SUPPLIER'"
            style="text-align: left"
        >
          <div data-icon-before="help">
            <a
                style="font-size: 13px; margin-left: 5px;"
                href="https://invis.io/F6ZMRMAP7ST"
                target="_blank"
            >
              Upload Tutorial
            </a>
          </div>
        </div>
        <button
            v-else
            class="rbButton mainButton"
            :class="$style.okButton"
            @click="hide()"
        >
          OK
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
import ActionReportMixin from './ActionReportMixin.vue'

export default {
  name: 'RbvHotelRfpBidManagerUploadSuccessfulActionReport',
  mixins: [ ActionReportMixin ],
  props: {
    side: {
      type: String,
      default: 'SUPPLIER'
    }
  },
  computed: {
    uploadedCountText () {
      return this.report.children.length === 1 ? '1 Hotel' : `${this.report.children.length} Hotels`;
    },
    questionnaireErrorsCount () {
      return this.report.children.filter( r => !r.tObject.state.properties.isValid).length
    },
    errorsCountText () {
      switch (this.questionnaireErrorsCount) {
        case 0: return 'All Hotels were successfully uploaded!';
        case 1: return `1 Hotel has errors!`
        default:
          return `${this.questionnaireErrorsCount} Hotels have errors!`
      }
    },
    successfulCount () {
      return this.report.children.length - this.questionnaireErrorsCount;
    },
    label() {
      return this.side === 'SUPPLIER'? 'Bid Incomplete': 'Draft Saved';
    }
  }
}
</script>
