export const
  ICON_SELECTED = 'check_box',
  ICON_INDETERMINATE = 'indeterminate_check_box',
  ICON_NOT_SELECTED = 'check_box_outline_blank';

export default function getSelectionIcon(value){
  if(value === 1 || value === true){
    return ICON_SELECTED;
  } else if(value === 0 || value === false){
    return ICON_NOT_SELECTED;
  } else {
    return ICON_INDETERMINATE;
  }
}