<template>
  <div class="pageWithHeaderBody userBackground layout-column">
    <div
      class="DocumentView Vertical"
      :class="$style.documentView"
    >
      <SideNav v-if="isChainOrTmc" />
      <div class="DocumentViewContainer">
        <div :class="$style.accountProfileContainer">
          <component
            :is="activeModuleComponent"
            v-if="activeModuleComponent"
            :state="state"
            :class="{[$style.accountProfileContent]: true, [$style.withNav]: isChainOrTmc}"
            :user-account="userAccount"
            :is-admin="isAdmin"
            :account="account"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import accountProfileService from 'vRoot/account-profile/accountProfileService';
  import SideNav from './HotelGroupChainPortalSidenav.vue';

  import {PAGE_RFP_MANAGER_COMPANY_PROFILE} from 'root/states';
  import listUsersService, { ACCOUNT_ADMIN } from 'root/v-components/account-profile/modules/manage-users/services/account-manage-users-list.service';

  export default {
    name: 'RbvNgRfpManagerUserProfileComp',
    components: {SideNav},
    props: {
      accountId: {
        required: true,
        type: String
      },
      accountType: {
        required: true,
        type: String
      }
    },
    data() {
      return {
        state: PAGE_RFP_MANAGER_COMPANY_PROFILE
      }
    },
    computed: {
      role() { return this.userAccount && this.userAccount.role && this.userAccount.role.permission; },
      isAdmin() { return this.role === ACCOUNT_ADMIN },
      isChainOrTmc() { return this.userAccount && ['CHAIN', 'HMC'].includes(this.userAccount.accountType); }
    },
    asyncComputed:{
      activeModuleComponent () {
        return accountProfileService.initialize(this.accountType, this.accountId)
          .then( module => module && module.component )
      },
      userAccount() { return listUsersService.loadAuthorization().then(({data}) => data)},
      account() { return accountProfileService.getAccount() }
    }
  }
</script>

<style lang="stylus" module>

  .accountProfileContainer {
    box-sizing: border-box;
    flex: 1 1 100%;
    margin: 20px auto;
    display: flex;
    max-width: 95%;
  }

  .accountProfileContent {
    background: white;
    min-width : 800px;
    border-radius : 6px;
    box-shadow : 0 0 7.53px rgba(0, 0, 0, 0.18);
  }

  .accountProfileContent.withNav {
    max-height: calc(100vh - 180px);
  }

  div.documentView {
    background: transparent;
  }

</style>
