import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';
import Component from './RbTravelDestinationMapHeader.svelte';

export default {
  template: '<div></div>',
  controller: Ctrl,
  bindings: {
    linkClicked: '&',
    tableShowing: '<',
    destination: '<',
    rfpId: '<',
  }
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;
  Object.assign(this, SvelteNgComponent(Component, $element[0],
    () => ({
      tableShowing: vm.tableShowing,
      destination: vm.destination,
      rfpId: vm.rfpId,
    }),
    { 'toggle': e => vm.linkClicked({ event: { state: e.detail } })}));
}
