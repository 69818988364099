<template>
  <modal-form @cancel="$formCancelHandler" @submit="$formSubmitHandler" :class="$style.dialog">
    <span slot="header">Change Job Title</span>

    <div slot="body" class="vModalDefaultBody">
      <rb-input forId="jobTitle"
                :error="$showError('jobTitle')">
        <span slot="label">Job Title</span>
        <input id="jobTitle" type="text" name="jobTitle" class="rbInput"
               v-model="data.jobTitle"
               v-validate="{required: true, max: 100}"
               data-vv-as="jobTitle"/>
      </rb-input>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'
  import RbInput from 'vRoot/_core/RbInput.vue'

  export default {
    name: 'rbv-user-profile-edit-job-title-dialog',

    components: { RbInput, ModalForm },

    mixins: [ RbFormMixin, DialogMixin ],

    props: {
      userAccount: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },

    data() {
      return {
        data: {}
      }
    },

    mounted () {
      this.data = {
        userAccountId: this.userAccount.id,
        jobTitle: this.userAccount.jobTitle,
      }
    },

    methods: {
      onSubmit () { return { form: this.data } }
    }
  }
</script>

<style lang="stylus" module="">
  .dialog {
    min-width 400px
  }
</style>

