import {NO_THANK_YOU, statusMatches} from 'rfp/bid-manager/main/core/actions/action/status';
import {SvelteDialog} from 'root/v-app/rbServices';
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
import ViewNoThankYouDialog from './ViewNoThankYouLetterDialog.svelte';
import noop from 'lodash/noop';

export default function ResendFinalAgreements(){
  return function (selectedBidsReport) {
    return {
      label: 'View "No Thank You" Message',
      icon: 'thumb_down',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [NO_THANK_YOU]),
      action: () => {
        notificationUtils()
          .onLoadWithNotification(() => mainAPI().viewNoThankYouMessage(selectedBidsReport.bidsIds[0]))
          .then(({data}) => SvelteDialog.show(ViewNoThankYouDialog, {letter: data, mode: 'VIEW'}))
          .catch( noop )
      }
    }
  }
}

