import { checkAndExecute } from 'root/api/helpers';

export default function UserSecurityAPI(api) {

  Object.assign(api, {
    userSecurity: {
      getUserDetails: () => api.server.get('current-user/security/details'),

      getTotpUrl: () => api.server.get('current-user/security/totp/setup'),
      setTotp: code => api.server.post('current-user/security/totp/setup/activate', {code}),

      removeMfa: type => checkAndExecute(
        () => type,
        () => api.server.delete(`current-user/security/${type}`)
      ),

      sendEmailMfaSetupEmail: () => api.server.post('current-user/security/email/setup/send'),
      activateEmailMfa: code => api.server.post('current-user/security/email/setup/activate', {code}),
    },
  });
}
