<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
  >
    <span slot="header">
      <div>{{ title }}</div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody"
      :class="$style.body"
    >
      <div :class="$style.group">
        <rb-input
          :for-id="'firstName'"
          :error="$showError('firstName')"
        >
          <span slot="label">First Name*</span>
          <input
            id="firstName"
            v-model="data.firstName"
            v-validate="'required'"
            class="rbInput"
            name="firstName"
            data-vv-as="First Name"
            placeholder="First Name"
            autofocus
          >
        </rb-input>
        <rb-input
          :for-id="'lastName'"
          :error="$showError('lastName')"
        >
          <span slot="label">Last Name*</span>
          <input
            id="name"
            v-model="data.lastName"
            v-validate="'required'"
            class="rbInput"
            name="lastName"
            data-vv-as="Last Name"
            placeholder="Last Name"
          >
        </rb-input>
      </div>
      <div :class="$style.group">
        <rb-input
          :for-id="'title'"
          :error="$showError('title')"
        >
          <span slot="label">Title</span>
          <input
            id="title"
            v-model="data.title"
            class="rbInput"
            name="title"
            data-vv-as="Title"
          >
        </rb-input>
        <rb-input
          :for-id="'type'"
          :error="$showError('type')"
        >
          <span slot="label">User Type</span>
          <rb-select-new
            id="industry"
            v-model="data.type"
            v-validate="'required'"
            :options="types"
            class="simple"
            name="type"
            data-vv-as="User Type"
            placeholder="Select..."
          />
        </rb-input>
      </div>
      <rb-input
        :for-id="'email'"
        :error="$showError('email')"
      >
        <span slot="label">Email Address</span>
        <input
          id="email"
          v-model="data.emailAddress"
          v-validate="'required|email'"
          class="rbInput"
          name="email"
          data-vv-as="Email Address"
        >
      </rb-input>
      <rb-input
        :for-id="'phone'"
        :error="$showError('phone')"
      >
        <span slot="label">Phone Number</span>
        <input
          id="phone"
          v-model="data.phone"
          class="rbInput"
          name="phone"
        >
      </rb-input>

      <template
        v-if="!user.id && !isCompany"
      >
        <div
          class="rbInputContainer"
        >
          <div
            class="rbInputLabel"
            :class="$style.companiesHeader"
          >
            <span>Assign Companies To User</span>
          </div>
          <div
            class="rbInput"
            :class="$style.addCompany"
            @click="addingCompanies = !addingCompanies"
          >
            <span :class="$style.selectedCompaniesNames">
              {{ selectedCompaniesNames || 'Select Accounts...' }}
            </span>
            <i class="material-icons">arrow_drop_down</i>
          </div>
        </div>
        <div
          v-if="addingCompanies"
          :class="$style.companies"
        >
          <div
            v-for="account in companies"
            :key="account.id"
            :class="$style.company"
            @click="toggle(account)"
          >
            <check-box
              :value="added(account.id)"
              :check-box-value="true"
              @input="toggle(account)"
              @click.stop.prevent
            />
            <div :class="$style.name">
              {{ account.name }}
            </div>
            <div
              v-if="account.primaryAgent.userDetails"
              :class="$style.primary"
            >
              {{ account.primaryAgent.userDetails.fullName }}
            </div>
            <div
              v-else
              :class="$style.unAssigned"
            >
              Not Assigned
            </div>
          </div>
        </div>
      </template>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button
        class="rbButton mainButton"
        type="button"
        @click="save"
      >
        <i class="material-icons">save</i>
        <template v-if="!user.id">
          Create New User
        </template>
        <template v-else>
          Save User
        </template>
      </button>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'
  import RbInput from 'vRoot/_core/RbInput.vue'
  import RbSelectNew from 'vRoot/_core/rbv-input/RbSelectNew.vue'
  import {ACCOUNT_ADMIN, ACCOUNT_MANAGER, PENDING_USER_TYPE, showPrimaryToBeReplacedDialog} from '../services/account-manage-users-list.service';
  import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';

  export default {
    name: 'ManageUserDialog',
    components: { ModalForm, RbInput, RbSelectNew , CheckBox},
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      title: {
        type: String,
        required: true,
      },
      user: {
        type: Object,
        required: false,
        default: () => ({})
      },
      companies: {
        type: Array,
        default: () => []
      },
      isCompany: {
        type: Boolean,
        default: false
      }
    },
    data() {
      const user = this.user;
      return {
        data: {
          firstName: user.firstName,
          lastName: user.lastName,
          emailAddress: user.emailAddress,
          phone: user.phone,
          type: user.role,
          title: user.jobTitle,
          id: user.id,
          isUser: user.type !== PENDING_USER_TYPE,
          companies: []
        },
        addingCompanies: false
      }
    },
    computed: {
      types() {
        return [
          {
            id: ACCOUNT_ADMIN,
            label: 'Administrator'
          },
          {
            id: ACCOUNT_MANAGER,
            label: 'Account Manager'
          }
        ];
      },
      selectedCompaniesNames() {
        return this.companies.filter(company => this.added(company.id)).map(({name}) => name).join(', ');
      }
    },
    methods: {
      onSubmit() {
        return { data: this.data };
      },
      added(id) {
        return this.data.companies.indexOf(id) >= 0
      },
      toggle(company) {
        const vm = this;

        if(this.added(company.id)){
          this.data.companies = this.data.companies.filter(companyId => companyId !== company.id);
        } else{
          add();
        }
        function add() {
          vm.data.companies = [...vm.data.companies, company.id];
        }
      },
      alreadyAssignedToAnotherUser(company) {
        return company.primaryAgent && company.primaryAgent.userId;
      },
      save() {
        const companies = this.data.companies.map(id => this.companies.find(company => company.id === id));
        const replaced = !!companies.find(company => this.alreadyAssignedToAnotherUser(company));
        return replaced? showPrimaryToBeReplacedDialog().then(this.$formSubmitHandler): this.$formSubmitHandler();
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 620px;
  }

  .body{
    height auto
  }

  .group{
    display flex;
  }

  .group :global .rbInputContainer{
    flex: 1;
  }

  .group > div:last-child{
    margin-left 10px;
  }

  .addCompany{
    display: flex;
    align-items: center;
    color: rgb(120, 144, 156);
    cursor pointer;
    background linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
    justify-content space-between;
  }

  .selectedCompaniesNames{
    flex 1;
    min-width 0;
    white-space nowrap;
    overflow hidden;
    text-overflow ellipsis;
  }

  .companies{
    max-height 210px;
    overflow-y auto;
    border 1px solid #ECEFF1;
    color #546E7A;
  }

  .company{
    padding 10px;
    border-bottom 1px solid #ECEFF1;
    display flex;
    align-items center;
    font-size 12px;
    cursor pointer;
  }

  .company .name{
    flex 1;
  }

  .dialog :global .rbCheckboxOptionButton{
    padding: 0;
    border: none !important;
    margin-right 10px;
  }

  .dialog :global .rbCheckboxOptionIcon.material-icons{
    font-size 20px;
  }

  .unAssigned{
    display flex;
    align-items center;
  }
</style>
