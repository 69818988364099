/**
 * Created by DejanK on 10/02/2017.
 */
import noop from 'lodash/noop';
import HTML from './rate-loading-information.html';
import './rate-loading-information.scss';
const NAME = 'editRateLoadingInformation';

export { NAME, HTML, EditRateLoadingInformationController as Controller };

EditRateLoadingInformationController.$inject = ['NotificationUtils', 'CurrentUser', 'rbDialog', 'RateLoadingInformationRepository'];
function EditRateLoadingInformationController(notificationUtils, currentUser, dialog, rateLoadingInformationRepository){
  const vm = this;
  let entityId;

  vm.submit = submit;
  vm.cancel = dialog.cancel;

  $onInit();

  function $onInit(){
    vm.rateLoadingInformation = 'loading';

    if(vm.entityId) {
      fetchAndSetRateLoadingInformation(vm.entityId);
    }else{
      currentUser.getActiveUserAccount()
        .then( userAccount => fetchAndSetRateLoadingInformation(userAccount.getEntityId()) )
        .catch( noop )
    }
  }

  function fetchAndSetRateLoadingInformation(id) {
    entityId = id;
    return notificationUtils.onLoad(
      () => rateLoadingInformationRepository.get(id),
      vm,
      'rateLoadingInformation'
    );
  }

  function submit($event){
    notificationUtils.onSave( () => rateLoadingInformationRepository.put(entityId, $event.model))
      .then(dialog.hide)
      .catch( noop )
  }
}
