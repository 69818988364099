<template>
  <div :class="$style.container">
    <img v-if="profilePictureUrl" :src="profilePictureUrl" :class="$style.image"/>
    <div v-else :class="$style.noImage">
      <div class="fa fa-image" :class="$style.noImageIcon" aria-hidden="true"></div>
      <div :class="$style.noImageLabel">Your Profile Picture</div>
    </div>
    <button class="rbButton expandButtonToShowText accountProfileUploadImageButton" :class="$style.uploadButton"
            @click="editPicture">
      <i class="material-icons">add_a_photo</i> <span :class="$style.uploadButtonLabel">Upload</span>
    </button>
  </div>
</template>

<script>
  import { Dialog } from "root/v-app/rbServices";
  import UpdateProfilePictureDialog from 'vRoot/_core/dialogs/UploadPhotoDialog.vue';
  import { noop } from 'lodash';
  import {currentUser, notificationUtils} from "root/angular-injector-provider";

  export default {
    name: 'rbv-user-profile-profile-photo',
    data(){
      return {
        updated: 0
      }
    },
    asyncComputed:{
      profilePictureUrl: {
        get () {
          return currentUser().get()
            .then( user => user.profilePicture )
        },
        watch () { return this.updated }
      }
    },
    methods: {
      editPicture () {
        Dialog.show(UpdateProfilePictureDialog, {title: 'Upload Profile Image'}, { closeOnOutsideClick: false })
          .then( data => notificationUtils().onSave(() => currentUser().updateProfilePicture({file: data.file})) )
          .then( () => { this.updated++ })
          .catch(noop);
      }
    }
  }
</script>

<style lang="stylus" module>

  .container {
    background: white;
    border: 1px solid #ECEFF1;
    width : 258px;
    min-height: 258px;
    border-radius : 3px;
    margin: 23px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    min-width: 120px;
    max-width: 258px;
    max-height: 258px;
  }

  .noImage {
    text-align: center;
    padding: 81px 0;
    width: 100%;
    background: #ECEFF1;
  }

  .noImageIcon {
    font-size : 80px;
    text-transform : uppercase;
    color : #B0BEC5;
  }

  .noImageLabel {
    font-size : 11px;
    text-transform : uppercase;
    color : #90A4AE;
  }

  .uploadButton {
    position: absolute;
    right: -1px;
    bottom: -1px;
    height: 29px;
    line-height: 27px;
    border-radius: 3px 0
  }

  .uploadButtonLabel {
    font-size 12px
  }

</style>

<style lang="stylus">

  .rbButton.expandButtonToShowText.accountProfileUploadImageButton {
    max-width: 29px;
    padding: 0 13px;

    &:hover {
      max-width: 1000px
    }
  }

</style>
