<template>
  <div :class="$style.sectionContent">
    <div :class="$style.detailsRow">
      <div :class="$style.detailsLabel">
        Company Name
      </div>
      <div :class="$style.detailsValue">
        {{ entity.name }}
      </div>
    </div>

    <div :class="$style.detailsRow">
      <div :class="$style.detailsLabel">
        Industry
      </div>
      <div :class="$style.detailsValue">
        {{ industry }}
      </div>
    </div>

    <div :class="$style.detailsRow">
      <div :class="$style.detailsLabel">
        Address
      </div>
      <div :class="$style.detailsValue">
        {{ address || '-' }}
      </div>
    </div>

    <div :class="$style.detailsRow">
      <div :class="$style.detailsLabel">
        Company URL
      </div>
      <div
        v-if="website"
        :class="$style.detailsValue"
      >
        <a
          :href="website"
          :class="$style.website"
          target="_blank"
        >
          {{ website }}
        </a>
      </div>
      <div
        v-else
        :class="$style.detailsValue"
      >
        -
      </div>
    </div>
  </div>
</template>

<script>
    import EntityIndustryService from 'entity/EntityIndustryService';

    export default {
      name: 'AccountDetails',
      props: {
        entity: {
          type: Object,
          required: true,
        }
      },
      asyncComputed: {
        industry () { return EntityIndustryService.labelOf(this.entity.industry) },
      },
      computed: {
        address () { return this.entity && this.entity.location && this.entity.location.fullAddress },
        website () { return this.entity && this.entity.website }
      }
    }
</script>

<style lang="stylus" module>
  .sectionContent {
    padding: 26px 20px;
    border-top: 1px solid #ECEFF1;
  }

  .detailsRow {
    display: flex
    font-size : 12px;
    line-height: 18px;
    margin-bottom: 9px;
  }

  .detailsLabel {
    flex: 0 0 130px;
    padding-right: 20px
    color: #78909C;
  }

  .detailsValue {
    flex: 1 0 300px;
    color: #455A64;
    overflow-x: auto;
  }

  .website {
    color: inherit !important;
    font-size: inherit !important;

    &:hover {
      color: #37474F !important;
      text-decoration: none;
    }
  }
</style>
