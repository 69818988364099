/**
 * Created by DejanK on 10/4/2016.
 *
 * rbInputPassword
 */

export default {
  template: templateFn,
  bindings: {
    model: '='
  }
}

templateFn.$inject = ['$element', '$attrs'];
function templateFn($element, $attrs){
  const name = $attrs.name || 'password',
    label = $attrs.label || 'Password',
    placeholder = $attrs.placeholder || label,
    required = $attrs.required === 'required' ? 'required="required"' : '';

  return `
<div class="rb-input" ng-form="${name}" ng-class="{'focused':focused, 'touched': ${name}.${name}.$touched || ${name}.${name}.$dirty}">
  <label>${label}</label>
  <input type="password" name="password" placeholder="${placeholder}" ng-model="$ctrl.model" ${required}
  ng-maxlength="200" ng-minlength="8" rb-has-uppercase rb-has-lowercase rb-has-number ng-pattern="/^(\\S*)$/"
  ng-focus="focused=1" ng-blur="focused=0">
  <div class="errors-container">
    <div ng-messages="${name}.password.$error" class="errors">
      <span ng-message="required">${label} is required!</span>
      <span ng-message="pattern">White space is not allowed!</span>
      <span ng-message="minlength">${label} is too short!</span>
      <span ng-message="maxlength">${label} is too long!</span>
      <span ng-message="rbHasUppercase">Must contain one uppercase letter!</span>
      <span ng-message="rbHasLowercase">Must contain one lowercase letter!</span>
      <span ng-message="rbHasNumber">Must contain one number!</span>
    </div>
  </div>
</div>`
}
