<div class="Modal">
  <div class="Title">{title}</div>
  <div class="ProgressBarContainer">
    <div class="ProgressBar" style="--width: {width}"></div>
  </div>
</div>


<script>
  export let
    progress = 0,
    title = 'Please wait...';

  let width = 0;

  $: width = `${300/100*progress}px`;
</script>

<style lang="stylus">.Modal {
  border-radius: 3px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.5);
  max-height: 95vh;
  max-width: 95vh;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
}
.Title {
  padding-bottom: 10px;
  font-size: 13px;
}
.ProgressBarContainer {
  width: 300px;
  overflow: hidden;
  box-sizing: border-box;
  height: 14px;
  border-radius: 4px;
  border: 1px solid #16272b;
  background: #16272b linear-gradient(0deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 35%, rgba(255,255,255,0.1) 65%, rgba(255,255,255,0.1) 100%);
}
.ProgressBar {
  height: 100%;
  transition: width 50ms linear;
  background: #00b8ab linear-gradient(0deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.1) 35%, rgba(255,255,255,0.2) 65%, rgba(255,255,255,0.2) 100%);
  width: var(--width);
}
</style>