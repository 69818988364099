<script>
  import RbHeader from "./RbHeader.svelte";
  import { currentUser as currentUserProvider } from 'root/angular-injector-provider';
  import { onMount, onDestroy } from 'svelte';

  export let showLogo = false;
  let user, unwatchUser;

  onMount(() => { unwatchUser = currentUserProvider().watch(u => user = u) });
  onDestroy(() => { if(unwatchUser) unwatchUser() });

  $: logo = user && user.getAccount().logo;
</script>

<div class="Root">
  <div>
    <RbHeader showBidManager={false}>
      {#if showLogo}
        {#if user && logo}
          <img
            class="logoImage"
            alt="company logo"
            src={logo}
          />
        {/if}
      {:else}
        <span class="Welcome">Welcomes</span>
      {/if}
    </RbHeader>
  </div>
</div>

<style lang="stylus">.Root {
  background: #263238;
}
.Root > div {
  max-width: 1280px;
  margin: auto;
}
.logoImage {
  height: 40px;
  border-radius: 5px;
  width: 120px;
  object-fit: contain;
}
.Root :global(.Backdrop) {
  max-width: 1280px;
  left: 50%;
  transform: translateX(-50%);
}
.Root :global(.Menu) {
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 5px 2px rgba(0,0,0,0.1);
  overflow: hidden;
}
.Welcome {
  color: #00b8ab;
  position: absolute;
  left: 100px;
  bottom: -18px;
  font-size: 13px;
}
</style>
