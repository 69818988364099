import PreviewModule from './preview/HotelDirectoryPreviewModule';
import manageHotelsModule from './manageHotels/manageHotelsModule';
import manageLocationsModule from './manageLocations/manageLocationsModule';
import manageUsersModule from './manageUsers/manageUsersModule';
import EditTravelPolicyModule from './EditTravelPolicy/EditTravelPolicyModule';


export default {
    loadModules
};

function loadModules(){
    const availableModules = [];

    load(PreviewModule);
    load(manageHotelsModule);
    load(manageLocationsModule);
    load(manageUsersModule);
    load(EditTravelPolicyModule);

    return availableModules;

    function load(module){
        availableModules.push(module);
    }
}
