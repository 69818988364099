<template>
  <div :class="$style.container">
    <img
      v-if="logo"
      :src="'/images/logos/'+logo"
      :class="$style.logoImage"
    >
    <div
      v-else
      :class="$style.noLogo"
    >
      <div
        class="fa fa-image"
        :class="$style.noLogoIcon"
        aria-hidden="true"
      />
      <div :class="$style.noLogoLabel">
        Your logo goes here
      </div>
    </div>
    <button
      v-if="isAdmin"
      class="rbButton expandButtonToShowText accountProfileUploadImageButton"
      :class="$style.uploadButton"
      @click="editLogo"
    >
      <i class="material-icons">add_a_photo</i> <span :class="$style.uploadButtonLabel">Upload</span>
    </button>
  </div>
</template>

<script>
  import { noop } from 'lodash';
  import accountProfileService from 'vRoot/account-profile/accountProfileService';

  export default {
    name: 'RbvLogoEditor',
    props: {
      isAdmin: {
        type: Boolean,
        default: false,
      },
    },
    data () { return { updated: 0 } },
    asyncComputed: {
      logo: {
        get() {
          return accountProfileService.getLogo()
        },
        watch() {
          return this.updated
        }
      }
    },
    methods: {
      editLogo () {
        accountProfileService.editLogo()
          .then( () => { this.updated++ }, noop )
      }
    }
  }
</script>

<style lang="stylus" module>

  .container {
    background: white;
    border: 1px solid #ECEFF1;
    width : 258px;
    min-height: 148px;
    border-radius : 3px;
    margin: 23px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logoImage {
    min-width: 120px;
    max-width: 258px;
    max-height: 200px;
  }

  .noLogo {
    text-align: center;
    padding: 27px 0;
    width: 100%;
    background: #ECEFF1;
  }

  .noLogoIcon {
    font-size : 80px;
    text-transform : uppercase;
    color : #B0BEC5;
  }

  .noLogoLabel {
    font-size : 11px;
    text-transform : uppercase;
    color : #90A4AE;
  }

  .uploadButton {
    position: absolute;
    right: -1px;
    bottom: -1px;
    height: 29px;
    line-height: 27px;
    border-radius: 3px 0
  }

  .uploadButtonLabel {
    font-size 12px
  }

</style>

<style lang="stylus">

  .rbButton.expandButtonToShowText.accountProfileUploadImageButton {
    max-width: 29px;
    padding: 0 13px;

    &:hover {
      max-width: 1000px
    }
  }

</style>
