<template>
  <div>
    <input type="text" :class="$style.distanceInput"
           v-if="focused"
           :value="distance"
           @change="handleChanged($event)"
           @blur="onBlur"
           @keydown="handleKeys" />
    <div v-else :class="$style.distanceInput"
         @click="onFocus">
      <span>{{distance}}</span>
      <span :class="$style.sup">{{subUnit}}</span>
    </div>
  </div>
</template>
<script>
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'

  export default {
    name: 'rb-travel-destinations-map-filter-bar-distance-input',
    mixins: [ RbEventsMixin ],
    data () {
      return { focused: false }
    },
    computed: {
      subUnit () { return this.unit.toLowerCase() }
    },
    props: {
      distance: {
        type: String,
        required: true,
        default: '0.1'
      },
      unit: {
        type: String,
        required: true,
        default: 'mi'
      },
      minValue: {
        type: Number,
        required: true,
        default: 0.1
      },
      maxValue: {
        type: Number,
        required: true,
        default: 30
      }
    },

    methods: {
      handleChanged ( event ) {
        const v = parseFloat(event.target.value)
        event.target.value = isNaN(v) ? this.distance || this.minValue : (v < this.minValue ? this.minValue : ( v > this.maxValue ? this.maxValue : v )).toFixed(1)
        this.$rbEmit({ name: 'changed', value: event.target.value })
      },
      onFocus() {
        this.focused = true
        this.$nextTick( () => {
          const inputElement = this.$el.firstChild
          inputElement.focus()
          inputElement.setSelectionRange(0, ("" + this.distance).length)
        })
      },
      onBlur(e) {
        this.focused = false
        +e.target.value !== +this.distance && this.handleChanged(e)
      },
      handleKeys(e) {
        if(e.keyCode === 13) {
          e.target.blur()
        } else if (e.keyCode === 27) {
          e.target.value = this.distance
          e.target.blur()
        }
      }
    }
  }
</script>
<style lang="stylus" module>

  .distanceInput {
    display inline-block
    height: 36px;
    line-height: 36px;
    width: 100%;
    border: 1px solid #DCDEE0;
    color: #37474F;
    box-sizing: border-box;
    transition: all .5s ease-out;
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    text-align center;
    padding 0 3px
    vertical-align middle
    border-radius: 20px;
    background: #fff;
  }

  .sup {
    font-size: 8px;
    vertical-align: text-top;
    line-height: 8px;
  }

</style>
