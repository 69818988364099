<template>
  <input
         :value="value"
         :readonly="readonly"
         @focus="selectAll"
         @input="$emit('input', $event.target.value)"
         @blur="$emit('blur', $event.target.value)"
         :style="hasChanged ? 'background: rgba(0, 169, 157, 0.1); border: 1px solid rgba(0, 169, 157, 1);' : ''"
  />
</template>

<script>
  import store from 'root/rfp-hotel/main/rfp/rbs-rfp-details-navigation/revert-revise-response.store';

  export default {
    name: 'rb-questionnaire-form-table-question-text',

    props: {
      id: {"default": undefined},
      value: { "default": undefined },
      readonly: {
        "type": Boolean,
        "default": false,
      }
    },

    data: () => ({hasChanged: false}),

    mounted () {
      this.unsubscribe = store.subscribe(this.changesListener)
    },

    destroyed () {
      if(this.unsubscribe) this.unsubscribe();
    },

    methods: {
      selectAll (event) { event.target.select() },
      changesListener ($store) {
        if($store) {
          this.hasChanged = $store.changes.includes(this.id);
        }
      }
    },
  }
</script>
