export default function infiniteScroll(element, {hasMore = true, loadMoreFn = () => {}, threshold = 100}){
  let more = hasMore;
  addListeners();

  function onScroll(){
    if(more) {
      const offset = element.scrollHeight - element.clientHeight - element.scrollTop;
      if (offset < threshold) loadMoreFn();
    }
  }

  function addListeners(){
    if(element){
      element.addEventListener('scroll', onScroll);
      element.addEventListener('resize', onScroll);
    }
  }

  function removeListeners(){
    element.removeEventListener('scroll', onScroll);
    element.removeEventListener('resize', onScroll);
  }

  return {
    update({hasMore}){
      more = hasMore;
    },

    destroy () {
      removeListeners();
    }
  }
}
