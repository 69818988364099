import {simpleState, svelteState} from 'root/shared/tools/router-helper';
import * as Terms from 'root/public/states/terms/terms.ctrl';
import noop from 'lodash/noop';
import {
  PAGE_HOME,
  PAGE_HOME_CORPORATIONS,
  PAGE_HOME_AGENCIES,
  PAGE_HOME_HOTELS,
  PAGE_HOME_CHAINS,
  PAGE_HOME_PRODUCTS_COMPETITIVE_BIDS,
  PAGE_HOME_PRODUCTS_RATE_AUDITING,
  PAGE_HOME_PRODUCTS_PROGRAM_MANAGEMENT,
  PAGE_HOME_ABOUT_US,
  PAGE_TERMS,
  PUBLIC_INVITATIONS_VIEW_PAGE_NAME
} from 'root/states/index';

export default PublicStates;

function PublicStates($stateProvider) {
  $stateProvider

    .state(PAGE_HOME,
      svelteState(
        '/',
        () => import( /* webpackChunkName: "TheHomePage" */'public/states/home/home-page/HomePage.svelte')))
    .state(PAGE_HOME_CORPORATIONS,
      svelteState(
        '/corporations',
        () => import( /* webpackChunkName: "TheHomeCorporationsPage" */'root/public/states/home/home-corporations/HomeCorporationsPage.svelte')))

    .state(PAGE_HOME_AGENCIES,
      svelteState(
        '/agencies',
        () => import( /* webpackChunkName: "TheHomeAgenciesPage" */'root/public/states/home/home-agencies/HomeAgenciesPage.svelte')))

    .state(PAGE_HOME_HOTELS,
      svelteState(
        '/hotels',
        () => import( /* webpackChunkName: "TheHomeHotelsPage" */'root/public/states/home/home-hotels/HomeHotelsPage.svelte')))

    .state(PAGE_HOME_CHAINS,
      svelteState(
        '/chains',
        () => import( /* webpackChunkName: "TheHomeHotelsPage" */'root/public/states/home/home-chains/HomeChainsPage.svelte')))

    .state(PAGE_HOME_PRODUCTS_COMPETITIVE_BIDS,
      svelteState(
        '/product-competitive-bids',
        () => import( /* webpackChunkName: "TheHomeCompetitiveBidsPage" */'root/public/states/products/home-competitive-bids/HomeCompetitiveBidsPage.svelte')))

    .state(PAGE_HOME_PRODUCTS_RATE_AUDITING,
      svelteState(
        '/product-rate-auditing',
        () => import( /* webpackChunkName: "TheHomeRateAuditingPage" */'root/public/states/products/home-rate-auditing/HomeRateAuditingPage.svelte')))

    .state(PAGE_HOME_PRODUCTS_PROGRAM_MANAGEMENT,
      svelteState(
        '/product-program-management',
        () => import( /* webpackChunkName: "TheHomeProgramManagementPage" */'root/public/states/products/home-program-management/HomeProgramManagementPage.svelte')))

    .state(PAGE_HOME_ABOUT_US,
      svelteState(
        '/about-us',
        () => import( /* webpackChunkName: "TheHomeAboutUsPage" */'root/public/states/about-us/AboutUsPage.svelte')))

    .state(PAGE_TERMS, simpleState('/terms-of-service', Terms.HTML, Terms.Controller))

    .state(PUBLIC_INVITATIONS_VIEW_PAGE_NAME,
      simpleState('/invitations/{token}?type,direct,target', '<rbs-empty-header /><rbv-ng-invitations-handler/>', noop))

    .state('declineBidWithInvitation',
      svelteState(
        '/decline-bid/:token',
        () => import( /* webpackChunkName: "TheDeclineBidPage" */'root/public-bid/TheDeclineBidPage.svelte')))

    .state('unsubscribeRfpReminders',
      svelteState(
        '/unsubscribe-rfp-reminders/:rfpId/:token',
        () => import( /* webpackChunkName: "UnsubscribeRfpReminders" */'root/public/UnsubscribeRfpReminders.svelte')));
}

