import { TYPE_CITY, TYPE_OFFICE } from 'root/rfp-hotel/main/rfp/destinations/travel-destination';
import { validateTravelDestination } from 'root/rfp-hotel/main/rfp/destinations/travel-destination-validation';
import {parseGooglePlace} from 'root/shared/tools/google/googlePlaceParser';
import {GoogleMapsGeocode} from 'root/shared/tools/google/googleProvider';
import moment from 'moment';

const FIELD_ID = 'Id',
  FIELD_NAME = 'Travel Destination Name',
  FIELD_ESTIMATED_ROOM_NIGHTS = 'Estimated Room Nights',
  FIELD_ESTIMATED_SPEND = 'Estimated Spend',
  FIELD_CURRENCY = 'Currency',
  FIELD_ADDRESS = 'Address',
  FIELD_CITY = 'City',
  FIELD_STATE = 'State Code',
  FIELD_REGION = 'Region',
  FIELD_COUNTRY = 'Country Code',
  FIELD_ZIP = 'Postal Code';

export { make as default, FIELD_ID }

function make(rawTravelDestination, rfp){
  return new TravelDestinationUpload(rawTravelDestination, rfp)
}

function TravelDestinationUpload(raw, rfp){
  this.$isValid = false;
  this.$errors = null;
  this.$geocoded = false;
  this.rfp = rfp;

  this.id = get(FIELD_ID);
  this.name = get(FIELD_NAME);
  this.currency = get(FIELD_CURRENCY);
  this.estimatedRoomNights = loadNumber(FIELD_ESTIMATED_ROOM_NIGHTS);
  this.estimatedSpend = loadNumber(FIELD_ESTIMATED_SPEND);
  this.location = {
    address: {
      address1: get(FIELD_ADDRESS),
      city: get(FIELD_CITY),
      state: get(FIELD_STATE),
      region: get(FIELD_REGION),
      zip: get(FIELD_ZIP),
      country: get(FIELD_COUNTRY)
    }
  };

  this.caps = loadRates(this.rfp);

  this.type = parseType();
  this.location.fullAddress = getFullAddressFromRaw(this.type, this.location.address);

  function parseType(){
    const addressExists = String(raw[FIELD_ADDRESS] ?? '').trim().length;
    return addressExists ? TYPE_OFFICE : TYPE_CITY
  }

  function getFullAddressFromRaw(type, address){
    const fullAddress = `${address.city}, ${address.state || address.region}, ${address.zip}, ${address.country}`;
    return removeEmpty(type === TYPE_OFFICE ? `${address.address1}, ${fullAddress}` : fullAddress)
  }

  function get(fieldName){
    const a = `${(raw[fieldName]? raw[fieldName]: '')}`.trim(),
      b = String(raw[fieldName] ?? '').trim();
    if(a !== b) console.log(fieldName, raw[fieldName],a ,b)

    return String(raw[fieldName] ?? '').trim();
  }

  function removeEmpty(s){ return s.replace(/, , /g, ', ') }

  function loadNumber(field){
    const value = `${raw[field]}`,
      v = parseInt(value.replace(/[^\d]/g, ''), 10);
      return isNaN(v) ? undefined : v
  }

  function loadRates(/* sourceRfp */) {
    const rates = [];
    let i = 1;
    while(i <= 5) {
      const startDate = get(`Cap Season ${i} Start Date`),
        endDate = get(`Cap Season ${i} End Date`),
        rate = loadNumber(`Cap Season ${i} Rate`);
        if([startDate, endDate, rate].some(c => !!c)){
          rates.push(Rate(startDate, endDate, rate));
        }
        i++;
    }
    return rates;

    function Rate(start, end, rate) {
      return {
        startDate: date(start),
        endDate: date(end),
        rate
      };

      function date(excelDate) {
        if(!excelDate) return excelDate;
        else if(excelDate < 0) return null;

        let d;
        if(isNaN(+excelDate)) {
          d  = moment(excelDate);
        }else{
          d = moment.utc('1900-01-01');
          d.add(+excelDate-2, 'd');
        }

        // d.year(sourceRfp.specifications.programYear);
        return d.isValid()? d.format('YYYY-MM-DD'): null;
      }
    }
  }
}

TravelDestinationUpload.prototype.geocode = function(){
  return new Promise((resolve, reject) => {
    if(this.$geocoded) { return resolve(this) }
    if(!this.location.fullAddress || this.location.fullAddress.length < 10 ) { return resolve(this) }

    setTimeout(() => {
      GoogleMapsGeocode({address: this.location.fullAddress})
        .then(data => {
          const parsedLocation = parseGooglePlace(data[0]);
          if(parsedLocation){
            this.location = parsedLocation;
            this.$geocoded = true
          }
          return resolve(this)
        }, error => {
          if(error === 'OVER_QUERY_LIMIT') {
            return this.geocode()
          } else {
            return reject(error)
          }
        })
    }, 1000);
    return null;
  })
};

TravelDestinationUpload.prototype.validate = function(){
  this.$errors = validateTravelDestination(this, this.rfp.specifications);
  this.$isValid = !Object.keys(this.$errors).some( field => this.$errors[field].invalid );
  return Promise.resolve(this)
};
