import Vue from 'vue'
import AccountProfile from './AccountProfile.vue';

export default {
  template: '<div></div>',
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'CurrentUser'];
function Ctrl($element, currentUser){
  const vm = this;
  let vueComponent;

  vm.$onInit = () => {
    currentUser.get()
      .then( user => { initVueComponent(user.getAccountType(), user.getAccountId()) })
  };

  vm.$onDestroy = () => { vueComponent && vueComponent.$destroy() };

  function initVueComponent(accountType, accountId){
    !vueComponent && initVue();

    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        render: function (createElement) {
          return createElement(AccountProfile, {
            props: { accountType: accountType , accountId: accountId },
          })
        }
      })
    }
  }
}
