<template>
    <div :class="classes">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 100 100" preserveAspectRatio="xMidYMid" :class="{animation: true,classes: true}">
                <circle cx="0" cy="0" r="43" stroke-dasharray="223.83847656827277 74.61282552275759" fill="none"></circle>
            </svg>
        </div>
        <div v-if="label" class="label">{{label}}</div>
    </div>
</template>

<script>
export default {
  name: 'rbv-loading',
  props: ['label', 'rb-class'],
  computed: {
    classes(){
      const classes = {'rbv-loading': true};
      classes[this.rbClass] = true;
      return classes;
    }
  }
}
</script>

<style lang="stylus">
    @keyframes infinite-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.rbv-loading {
  text-align: center;
}
.rbv-loading .animation {
  display: inline-block;
  animation: infinite-spin 1s infinite;
  animation-timing-function: linear;
  height: 50px;
  width: 50px;
  stroke: #00a99d;
  stroke-width: 5px;
}
.rbv-loading .label {
  color: #78909c;
  font-weight: 100;
  margin: 15px auto 0;
  font-size: 11pt;
  padding-left: 11pt;
}
.rbv-loading.medium .animation {
  height: 30px;
  width: 30px;
  stroke-width: 8px;
}
.rbv-loading.medium .label {
  display: inline-block;
  margin: 0;
  font-size: 11px;
  padding-left: 3px;
}
.rbv-loading.small .animation {
  height: 10px;
  width: 10px;
  stroke-width: 13px;
}
.rbv-loading.small .label {
  display: inline-block;
  margin: 0;
  font-size: 12px;
  padding-left: 3px;
}
</style>
