import {
  notificationUtils,
  $state,
  rfpManagerRepository,
  currentUser,
} from 'root/angular-injector-provider';

import {
  PAGE_RFP_MANAGER_COMPANY_PROFILE,
  PAGE_SELECT_RFP_TEMPLATE,
  PAGE_DUPLICATE_RFP,
  BID_MANAGER,
  HOTEL_RFP_SPECIFICATIONS,
  NEGOTIATED_SAVINGS_REPORT
} from 'root/states';
import {Dialog} from 'root/v-app/rbServices';
import CreateRfpDialog from '../dialogs/CreateRfpDialog.vue';
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';
import {ACCOUNT_MANAGE_USERS_MODULE} from 'vRoot/account-profile/modules/manage-users/AccountManageUsersModule';
import {ACCOUNT_MANAGE_COMPANIES_MODULE} from 'vRoot/account-profile/modules/manage-companies/AccountManageCompaniesModule';
import {ACCOUNT_COMPANY_PROFILE} from 'vRoot/account-profile/modules/company-profile/AccountCompanyProfileModule';
import BidManagerViewTypes from 'rfp/bid-manager/bid-manager-view-types';


function openBidManager([rfp, ]) {
  return openManager({targetId: rfp.rfp.id, type: BidManagerViewTypes.RFP});
}

function openAccountBidManager(accountId) {
  return openManager({ targetId: accountId, type: BidManagerViewTypes.ALL});
}

function openManager(params) {
  return notificationUtils().onLoad(() => rfpManagerRepository().getBidManagerView(params)).then(({data}) => {
    return Promise.resolve($state().inNew(BID_MANAGER, {viewId: data.id}))
  });
}

function editRfp([{rfp}, ]) {
  return Promise.resolve($state().inNew(HOTEL_RFP_SPECIFICATIONS, {rfpId: rfp.id}));
}

function archiveRfps(rfps) {
  return notificationUtils()
    .onSave(() => rfpManagerRepository().archiveRfps(rfps.map(({rfp}) => rfp.id)))
}

function completeRfps(rfps) {
  return notificationUtils()
    .onSave(() => rfpManagerRepository().completeRfps(rfps.map(({rfp}) => rfp.id)))
}

function unArchiveRfps(rfps) {
  return notificationUtils()
    .onSave(() => rfpManagerRepository().unArchiveRfps(rfps.map(({rfp}) => rfp.id)));
}

function duplicateRfp(rfpId, accountId) {
  return Promise.resolve($state().inNew(PAGE_DUPLICATE_RFP, {rfpId, accountId}));
}

function createNewRfp(accounts, account, isCompany = false, currentAccountId) {
  return new Promise((resolve, reject) => {
    if(!isCompany) {
      Dialog.show(CreateRfpDialog, {accounts, accountId: account}).then(({action, data: {accountId}}) => {
        switch (action) {
          case 'createRfp':
            resolve(openCreate(accountId));
            break;
          case 'createCompany':
            resolve({action});
            break;
          default:
            break;
        }
      }).catch(reject)
    }else {
      Promise.resolve(openCreate(currentAccountId));
    }
  });

  function openCreate(accountId) {
    return Promise.resolve($state().inNew(PAGE_SELECT_RFP_TEMPLATE, {accountId}));
  }
}

function openUsersAndPermissions() {
  return Promise.resolve($state().go(PAGE_RFP_MANAGER_COMPANY_PROFILE, {module: ACCOUNT_MANAGE_USERS_MODULE}));
}

function openManageCompanies() {
  return Promise.resolve($state().go(PAGE_RFP_MANAGER_COMPANY_PROFILE, {module: ACCOUNT_MANAGE_COMPANIES_MODULE}));
}

function manageAccount(accountId) {
  return Promise.resolve($state().go(PAGE_RFP_MANAGER_COMPANY_PROFILE, {module: ACCOUNT_COMPANY_PROFILE, accountId}));
}

function deleteRfps(rfps) {
  const text = rfps.length > 1? `${rfps.length} RFPs`: 'this RFP';
  return Dialog.show(WarningDialog, {
    title: `Delete ${text} permanently?`,
    subTitle: `You are about to delete ${text} permanently. This will delete all related travel destinations and bids from your bid manager. This action cannot be undone.`
  }).then(() => notificationUtils().onSave(() => rfpManagerRepository().deleteRfps(rfps.map(({rfp}) => rfp.id))))
    .then(currentUser().refresh);
}

function openNegotiatedSavingsReport([{rfp}]) {
  return Promise.resolve($state().inNew(NEGOTIATED_SAVINGS_REPORT, {rfpId: rfp.id}));
}


export default {
  openBidManager,
  openAccountBidManager,
  editRfp,
  archiveRfps,
  unArchiveRfps,
  duplicateRfp,
  createNewRfp,
  openUsersAndPermissions,
  openManageCompanies,
  completeRfps,
  manageAccount,
  deleteRfps,
  openNegotiatedSavingsReport,
}
