/**
 * Created by DejanK on 3/23/2017.
 *
 * Creates
 */
import {NO_BID_MANAGER_VIEW_PAGE_STATE, CHAIN_PORTAL_STATE, BID_MANAGER, DASHBOARD} from 'root/states';


export default BidManagerRedirectService;

BidManagerRedirectService.$inject = ['ControllerUtils', 'CurrentUser'];
function BidManagerRedirectService(utils, currentUser){
  const $service = this;

  $service.toDefaultView = toDefaultView;
  $service.toChainPortalFromBidManagerView = toChainPortalFromBidManagerView;

  function toDefaultView(config = {}){
    return currentUser.getActiveUserAccount()
      .then((account)=>{
        const view = getView(account);
        if(account.isOfType('CHAIN', 'HMC')) {
          if(config && config.rfpId){
            const steps = config.rfpId && `RFP-${config.rfpId}`;
            utils.go(CHAIN_PORTAL_STATE, {assignments: true, steps});
          } else {
            utils.go(DASHBOARD)
          }
        } else if (view) {
          utils.go(BID_MANAGER, {viewId: view, bid: '', 'user-account': ''});
        } else {
          utils.go(NO_BID_MANAGER_VIEW_PAGE_STATE);
        }
      })
      .catch(handleNoAccount);

    function getView(account) {
      return account && (account.lastBidManagerViewId || account.defaultBidManagerViewId);
    }

    function handleNoAccount() {
      utils.go('signIn');
    }
  }

  async function toChainPortalFromBidManagerView(view, negotiations) {
    const user = await currentUser.get();

    if(user.isOfType('CHAIN', 'HMC')) {
      utils.go(CHAIN_PORTAL_STATE, { steps: view.type === 'RFP' ? [`RFP-${view.rfpId}`] : ['COMPANY'], negotiations, assignments: true });
      return true;
    }

    return false;
  }
}
