/**
 * Created by DejanK on 7/14/2017.
 */
'use strict';

module.exports = {
  template: '<input /><div class="included-notification">inc</div>',
  bindings: {
    negotiations: '<',
    amenityId: '@',
    side: '<',
    negotiation: '<',
    model: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', 'NegotiationsModelFactory', '$scope'];
function Ctrl($element, NegotiationsModelFactory, $scope) {
  let vm = this, model;

  vm.$onChanges = initialize;

  function initialize(){
    if(!vm.model || !vm.negotiations || !vm.side || !vm.amenityId || model) return;
    let negotiations = vm.negotiations;

    vm.amenity = negotiations.getAmenity(vm.amenityId);
    model = NegotiationsModelFactory.createAmenityModel(
      vm.model,
      $scope,
      $element,
      negotiations.currencyFormat.format,
      negotiations.percentageFormatter.format,
      {...vm.amenity}
    );

    $scope.$on('RB_REQUESTED_OF_ALL', function(event, data){
      model.resetAndReduceByPercent(data.percentage);
    });
  }
}
