import * as FillResponseOnlyOnce from './fill-response-only-once/fill-response-only-once.ctrl'
import * as AskForRateLoadingInformation from './ask-for-rate-loading-information/ask-for-rate-loading-information.ctrl'
import * as askToConfirmRfpContract from './ask-to-confirm-rfp-contract/ask-to-confirm-rfp-contract';

export { Dialogs as default }

Dialogs.$inject = ['rbDialogProvider'];
function Dialogs(rbDialogProvider) {
  rbDialogProvider
    .add(FillResponseOnlyOnce.NAME, FillResponseOnlyOnce.HTML, FillResponseOnlyOnce.Controller)
    .add(AskForRateLoadingInformation.NAME, AskForRateLoadingInformation.HTML, AskForRateLoadingInformation.Controller)
    .add(askToConfirmRfpContract.NAME, askToConfirmRfpContract.HTML, askToConfirmRfpContract.Controller)
}
