import { get, groupBy, orderBy } from 'lodash';
import { mainAPI, notificationUtils } from 'root/angular-injector-provider.js';
import { derived, writable, get as getStore } from 'svelte/store';
import { filter, groupBy as group, sortBy, quickFilter } from './table-logic.store';

export const allHotelsStore = writable({
  hotels: [],
  loading: true,
  selections: [],
  type: 'all'
});

export const requestedHotelsStore = writable({
  hotels: [],
  loading: true,
  selections: [],
  type: 'requested'
});

export const currentList = writable('all');

export const dataStore = derived(
  [currentList, allHotelsStore, requestedHotelsStore],
  ([current, all, requested]) => (current === 'all' ? all : requested)
);

const sortedHotels = derived([dataStore, sortBy], ([data, $sortBy]) => {
  return orderBy(
    data.hotels,
    [$sortBy.key],
    [$sortBy.direction === 'up' ? 'asc' : 'desc']
  );
});

const filteredHotels = derived(
  [group, filter, quickFilter, sortedHotels],
  ([$group, $filter, $quickFilter, $hotels]) => {
    const hs = applyQuickFilter($hotels, $quickFilter);

    if (!$group || !$filter || [$group, $filter].includes('---')) return hs;
    return hs.filter(hotel => get(hotel, $group.key, null) === $filter);
  }
);

export const allSelected = derived(
  dataStore,
  $store =>
    $store.selections.length > 0 &&
    $store.selections.length === $store.hotels.length
);
export const someSelected = derived(
  dataStore,
  $store =>
    $store.selections.length > 0 &&
    $store.selections.length < $store.hotels.length
);

export const groupedHotels = derived(
  [sortedHotels, group, filter, quickFilter],
  groupHotels
);

function groupHotels([$hotels, $group, $filter, $quickFilter]) {
  const hs = applyQuickFilter($hotels, $quickFilter);

  if ($group && $group.value !== '---') {
    const groups = hs.reduce((acc, hotel) => {
      if (!$filter || $filter === '---' || shouldInclude(hotel)) {
        const key = get(hotel, $group.key, 'undefined');

        if (acc[key]) {
          acc[key].hotels.push(hotel);
        } else {
          const label = get(hotel, $group.labelKey, 'Undefined');
          acc[key] = {
            label,
            id: key,
            hotels: [hotel],
            group: true
          };
        }
      }

      return acc;
    }, {});

    return Object.keys(groups).reduce(
      (acc, key) => [...acc, groups[key], ...groups[key].hotels],
      []
    );
  }

  return hs;

  function shouldInclude(hotel) {
    return get(hotel, $group.key, null) === $filter;
  }
}

export const filters = derived([dataStore, group], ([data, $group]) => {
  if (!$group) return [];

  const groups = groupBy(data.hotels, $group.key);
  const rows = Object.keys(groups).reduce(
    (acc, value) => [
      ...acc,
      { value, label: get(groups[value][0], $group.labelKey) }
    ],
    []
  );
  return [
    {
      value: '---',
      label: 'None'
    },
    ...rows.sort(({ label: a }, { label: b }) => (a > b ? 1 : -1))
  ];
});

export function loadAllHotels() {
  allHotelsStore.update(value => ({ ...value, loading: true, hotels: [] }));
  return notificationUtils().onLoad(() => mainAPI().loadAllGroupHotels()).then(hotels => {
    allHotelsStore.update(value => ({
      ...value,
      loading: false,
      hotels: addQuickFilterString(hotels),
      selections: []
    }));
  });
}

export function loadRequestedHotels() {
  requestedHotelsStore.update(value => ({ ...value, loading: true, hotels: [] }));
  return notificationUtils().onLoad(() => mainAPI().loadRequestedHotels()).then(({data: hotels}) => {
    requestedHotelsStore.update(value => ({
      ...value,
      loading: false,
      hotels: addQuickFilterString(hotels),
      selections: []
    }));

    return hotels;
  });
}

export function select({ id }) {
  const data = getStore(dataStore);
  let selections = data.selections;
  if (data.selections.includes(id)) {
    selections = selections.filter(i => i !== id);
  } else {
    selections.push(id);
  }

  getCurrentStore().update(value => ({ ...value, selections }));
}

export function selectAll() {
  const all = getStore(allSelected);
  const data = getStore(filteredHotels);
  let selections = [];

  if (!all) {
    selections = data.map(({ id }) => id);
  }

  getCurrentStore().update(value => ({ ...value, selections }));
}

export function getCurrentStore() {
  return getStore(currentList) === 'all'? allHotelsStore: requestedHotelsStore;
}

export function resetCurrentStore() {
  if(getStore(currentList) !== 'all' && getStore(requestedHotelsStore).hotels.length === 0) {
    currentList.set('all');
  }
}

function addQuickFilterString(hotels) {
  return hotels.map(h => {
    h.sabreCode = isNaN(h.sabreCode)? '': Number(h.sabreCode);
    h.$$quickFilterSabre = h.sabreCode || undefined;
    h.$$quickFilterName = `${h.name || ''}`.trim().toLowerCase();
    h.$$quickFilterAddress = `${h.location.address.address1 || ''}`.trim().toLowerCase();
    return h;
  })
}

function applyQuickFilter($hotels, $quickFilter = '') {
  if($quickFilter || $quickFilter.length) {
    const qf = $quickFilter.toLowerCase();

    // noinspection EqualityComparisonWithCoercionJS
    return $hotels.filter(h => qf == h.$$quickFilterSabre || h.$$quickFilterName.includes(qf) || h.$$quickFilterAddress.includes(qf));
  } else {
    return $hotels;
  }
}
