/**
 * Created by DejanK on 7/24/2017.
 *
 */
import { cloneDeep, isUndefined } from 'lodash'

export default NegotiationsModelFactory

NegotiationsModelFactory.$inject = ['$rootScope'];
function NegotiationsModelFactory($rootScope){

  function NegotiationModel(model, $scope, elements){
    this.model = model;
    this.initialModel = cloneDeep(model);
    this.elements = elements;
    this.elements.setModel(this);
    this.render();

    $scope.$on('RB_NEGOTIATION_CHANGED', ()=>{
      this.render();
      $rootScope.$applyAsync();
    });

  }

  NegotiationModel.prototype = {
    constructor: NegotiationModel,
    getValue: function (){
      return this.model.value;
    },
    setValue: function(model){
      Object.assign(this.model, model);
      this.onModelChanged();
    },
    update: function (value, type = 'FIXED') {
      if (value) {
        this.model.type = this.model.type === 'UNAVAILABLE' ? type : this.model.type;
      } else {
        this.model.type = 'UNAVAILABLE';
        this.model.value = 0;
      }
      this.model.valid = true;
      this.render();
      this.onModelChanged();
    },
    onModelChanged: function (){
      $rootScope.$broadcast('RB_NEGOTIATION_CHANGED');
    },
    render: function (){
      this.elements.updateView(this.model);
    }
  };

  /*
   * Rate Model
   */
  function RateModel(model, $scope, $element, currencyFormat){
    NegotiationModel.call(this, model, $scope, new ModelElements($element, currencyFormat));
  }

  RateModel.prototype = Object.create(NegotiationModel.prototype, {
    _super: {value: NegotiationModel.prototype},
    constructor: {value: RateModel},
    setValue: {value: function(value){
        this.model.value = value;
        this.model.type = value ? 'FIXED' : 'UNAVAILABLE';
        this.model.valid = true;
        this.render();
        this.onModelChanged();
      }},
    update: { value: function(){ this._super.update.call(this, this.model.value)}},
    resetAndReduceByPercent: { value: function(percent){
        Object.assign(this.model, this.initialModel);
        this.model.value = Math.floor(this.model.value * percent/5)*5;
        this.onModelChanged();
      }}
  });

  function DynamicRateModel(model, $scope, $element, formatter){
    NegotiationModel.call(this, model, $scope, new ModelElements($element, formatter));
  }

  DynamicRateModel.prototype = Object.create(NegotiationModel.prototype, {
    _super: {value: NegotiationModel.prototype},
    constructor: {value: DynamicRateModel},
    setValue: {value: function(value){
        this.model.value = value ? value/100 : 0;
        this.model.type = value ? 'PERCENTAGE' : 'UNAVAILABLE';
        this.model.valid = true;
        this.render();
        this.onModelChanged();
      }},
    update: { value: function(){ this._super.update.call(this, this.model.value/100, 'PERCENTAGE')}},
    resetAndReduceByPercent: { value: function(){}}
  });

  /*
   * Amenity Model
   */
  function AmenityModel(model, $scope, $element, currencyFormat, percentageFormat, amenity){
    NegotiationModel.call(this, model, $scope, new AmenityModelElements($element, currencyFormat, percentageFormat, model.type, amenity));
  }

  AmenityModel.prototype = Object.create(NegotiationModel.prototype, {
    _super: {value: NegotiationModel.prototype},
    constructor: {value: AmenityModel},
    setValue: {value: function(value){
        const model = this.model;
        this._super.setValue.call(this, Object.assign({}, model, {value: model.type === 'PERCENTAGE' ? value/100 : value}));
      }},
    update: { value: function () {
      const
        value = this.model.value,
        defaultType = this.elements.amenity.defaultType;

      if(this.elements.amenity.id === 'cm'){
        if(isNaN(value)){this.model.value = 0}
      } else if(value && this.model.type === 'UNAVAILABLE'){
        this.model.type = defaultType;
      } else if(!value) {
        this.model.type = 'UNAVAILABLE';
        this.model.value = 0;
      }

      this.model.valid = true;
      this.render();
      this.onModelChanged();
    }},

    resetAndReduceByPercent: { value: function(percent){
        Object.assign(this.model, this.initialModel);
        if(this.model.type === 'FIXED' && !this.model.included && this.model.valid)
        {
          this.model.value = Math.floor(this.model.value * percent/5)*5;
          this.model.included = this.model.value <= 20;
        }

        this.onModelChanged();
      }}
  });


  /*
   * ModelElements
   */
  function ModelElements($element, currencyFormat, type, amenity){
    this.amountFormat = currencyFormat;
    this.type = type;
    this.amenity = amenity;
    this.children = $element.children();
    this.self = $element;
    this.input = angular.element(this.children[0]);
  }

  ModelElements.prototype.setFormattedValue = function(rawValue){
    if(!this.focused) {
      const value = parseFloat(rawValue.type === 'UNAVAILABLE' || !rawValue.valid ? null : rawValue.value);
      this.setValue(isNaN(value) ? '---' : this.amountFormat(value));
    }
  };

  ModelElements.prototype.getValue = function(){
    if(this.type === 'TEXT' || (this.defaultType === 'TEXT' && this.type === 'UNAVAILABLE')){
      return this.input[0].value.slice(0,10);
    } else {
      const v = `${this.input[0].value}`.replace(/[^0-9.]/g, ''),
        num = v.slice(0, 15),
        result = parseFloat(num);

      return isNaN(result) ? undefined : parseFloat(result.toFixed(2));
    }
  };

  ModelElements.prototype.setValue = function(v){
    this.input[0].value = isUndefined(v) ? '---' : v;
  };

  ModelElements.prototype.updateView = function(value) {
    this.setFormattedValue(value);
  };

  ModelElements.prototype.setModel = function(model){
    const self = this;
    this.input
      .on('input change', cleanAndUpdateModel)
      .on('focus', ()=> { self.focused = true; parseElementValue()})
      .on('blur', ()=>{ self.focused = false; if(!self.readOnly) model.update(); });

    function cleanAndUpdateModel(){
      model.setValue(self.getValue());
      if(self.defaultType === 'TEXT'){
        self.setValue(`${self.input[0].value}`.slice(0, 10));
      } else {
        self.setValue(`${self.input[0].value}`.replace(/[^0-9.]/g, '').slice(0, 15));
      }
    }

    function parseElementValue(){
      if(!self.readOnly){
        const inputElement = self.input,
          v = self.getValue();
        self.setValue(v || '');
        inputElement.select();
      }
    }
  };

  function AmenityModelElements($element, currencyFormat, percentageFormat, type, amenity){
    ModelElements.call(this, $element, currencyFormat, type, amenity);
    this.includedClass = 'included';
    this.defaultType = amenity.defaultType;
    this.percentageFormatter = percentageFormat;
    this.includedNotification = angular.element(this.children[1]);
  }

  AmenityModelElements.prototype = Object.create(ModelElements.prototype, {
    constructor: {
      value: AmenityModelElements
    },

    setFormattedValue: {
      value: function (viewValue) {
        if(!this.focused) {
          const numValue = parseFloat(viewValue && viewValue.type !== 'UNAVAILABLE' && viewValue.valid && viewValue.value),
            type = viewValue && viewValue.type;
          let val;
          switch (type) {
            case 'PERCENTAGE':
              val = this.percentageFormatter(numValue);
              break;
            case 'TEXT':
              val = viewValue.value;
              break;
            default:
              val = this.amountFormat(numValue);
          }

          this.input.val(val);
        }
      }
    },

    setReadOnly: {
      value: function(){
        this.readOnly = true;
        this.self.addClass('readonly');
        this.input.attr({
          'tabindex': '-1',
          'readonly': true
        })
      }
    },

    setIncluded: {
      value: function () {
        this.readOnly = true;
        this.self.addClass(this.includedClass);
        this.includedNotification.css('display', 'block');
        this.input.attr({
          'tabindex': '-1',
          'readonly': true
        })
      }
    },

    unsetIncluded: {
      value: function () {
        this.readOnly = false;
        this.self.removeClass(this.includedClass);
        this.includedNotification.css('display', 'none');
        this.input.removeAttr('tabindex');
        this.input.removeAttr('readonly');
      }
    },

    updateView: {
      value: function (viewValue) {
        this.setFormattedValue(viewValue);
        if(viewValue.included){
          this.setIncluded();
        } else {
          this.unsetIncluded();
          if(this.amenity.id === 'bf') this.setReadOnly();
        }
      }
    }
  });

  return {
    createAmenityModel: (model, $scope, $element, currencyFormat, percentageFormat, amenity)=>{
      return new AmenityModel(model, $scope, $element, currencyFormat, percentageFormat, amenity);
    },
    createRateModel: (model, $scope, $element, currencyFormat)=>{
      return new RateModel(model, $scope, $element, currencyFormat);
    },
    createDynamicRateModel: (model, $scope, $element, formatter)=>{
      return new DynamicRateModel(model, $scope, $element, formatter);
    }
  }
}
