import { checkAndExecute } from 'root/api/helpers';

export default function DashboardAPI(api) {

  Object.assign(api, {

    dashboard: {
      listRfps: () => checkAndExecute(
        () => true,
        () => api.server.get(`dashboard/rfps`)),

      listPersonalRecentBids: params => checkAndExecute(
        () => true,
        () => api.server.get(`dashboard/bids/personal`, params)),

      listRecentBidsFromFavoriteRfps: params => checkAndExecute(
        () => true,
        () => api.server.get(`dashboard/bids/favorite`, params)),

      listCompanyRecentBids: params => checkAndExecute(
        () => true,
        () => api.server.get(`dashboard/bids/company`, params)),
    }
  });
}
