import PublicContactUs from './public/contact-us'
import EmailAddressVerification from './public/email-address-verification'
import SignInApi from './user/user-security-auth-api'
export default AuthAPI

AuthAPI.$inject = ['AuthServer']
function AuthAPI(AuthServer) {
  this.server = AuthServer
  PublicContactUs(this)
  EmailAddressVerification(this);
  SignInApi(this);
}
