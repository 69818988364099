import HTML from './rb-respond-nag.html';
import '../nag.scss'
import Ctrl from '../nag.ctrl';

export default {
  bindings: {
    rbClick: '&',
    dueDate: '<'
  },
  template: HTML,
  controller: Ctrl
}
