<script>
import { showMissingSubscription } from "../../../services/helpers/subscription";


  export let items, sticky = true;

  let currentOpenIndex = null;

  async function openItem(item, target) {
    if(!item.blocked) {
      item.action();
    } else {
      await showMissingSubscription(target);
    }

    currentOpenIndex = null;
  }

  function expand(index) {
    currentOpenIndex = index === currentOpenIndex? null: index;
  }
</script>

<nav class="Root" class:Sticky={sticky}>
  <ul class="Menu">
    <li class="Item Slot"><slot /></li>
    {#each items as item, index}
      <li
        class="Item"
        class:Active={item.active ||
          (item.submenu && item.submenu.some(({ active }) => active))}
        on:click={({target}) => openItem(item, target)}
        class:hasSubMenu={item.submenu}
      >
        {#if item.icon}
          <div>
            <i class="material-icons">{item.icon}</i>
            {#if item.tooltip}
              <span
                class="rbTooltip simple"
                tooltip="placement:bottom-start"
              >
                {item.tooltip}
              </span>
            {/if}
          </div>
        {/if}
        <span> {item.label} </span>
        {#if item.submenu}
          {#if index === currentOpenIndex}
            <i class="material-icons" on:click|stopPropagation={() => expand(index)}>expand_less</i>
            <div class="Bg" on:click|stopPropagation={() => expand(index)} />
            <ul class="DropDown">
              {#each item.submenu as subItem}
                <li class="Item" class:Active={subItem.active} on:click|stopPropagation={({target}) => openItem(subItem, target)}>
                  {#if subItem.icon}
                    <i class="material-icons">{subItem.icon}</i>
                  {/if}
                  <span>{subItem.label}</span>
                </li>
              {/each}
            </ul>
          {:else}
            <i class="material-icons" on:click|stopPropagation={() => expand(index)}>expand_more</i>
          {/if}
        {/if}
        {#if item.badge}
          <span class="Badge">{item.badge}</span>
        {/if}
      </li>
    {/each}
  </ul>
</nav>

<style lang="stylus">.Root {
  background-color: #fff;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
}
.Root.Sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}
.Menu {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: auto;
  color: #8b9bb5;
  font-size: 14px;
  cursor: pointer;
  max-width: 1280px;
}
.Item {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: relative;
}
.Item.hasSubMenu {
  position: relative;
}
.Item:not(:last-child) {
  margin-right: 40px;
}
.Item.Slot {
  margin-right: 10px;
}
.Item > *:not(:last-child) {
  margin-right: 10px;
}
.Item.Active {
  color: #00a99d;
  position: relative;
}
.Item.Active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 25px;
  border-bottom: 2px solid currentColor;
}
.Item .Badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff4500;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.Bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 998;
}
.DropDown {
  background: #fff;
  border-radius: 5px;
  padding: 0;
  list-style: none;
  position: absolute;
  min-width: 200px;
  top: 100%;
  left: 0;
  box-shadow: 0px 0px 3px -2px #000;
  z-index: 999;
  color: #8b9bb5;
}
.DropDown .Item {
  padding: 5px;
  margin: 0;
  white-space: nowrap;
  text-transform: unset;
}
.DropDown .Item::after {
  display: none;
}
.rbTooltip {
  width: 200px;
}
</style>
