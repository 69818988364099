/**
 * Created by DejanK on 1/24/2017
 *
 * Displays Google Map Marker
 */

'use strict';

let lodash = require("lodash");

module.exports = {
  bindings: {
    position: '<',
    icon: '<',
    iconSize: '<',
    draggable: '<',
    animation: '<'
  },
  require: {
    MapCtrl: '^rbMap'
  },
  controller: Ctrl
};

Ctrl.$inject = ['RbGoogle'];
function Ctrl(RbGoogle){
  let vm = this, gMapsInit, GoogleMaps, parentMap, marker;

  this.$onInit = ()=>{
    gMapsInit = RbGoogle.getMap()
      .then(getParentMap)
      .then(newMarker);
  };

  this.$onChanges = (changes)=>{
    if(gMapsInit) {
      changes.position && gMapsInit.then(onPositionChanged);
      changes.icon && gMapsInit.then(onIconChanged);
      changes.iconSize && gMapsInit.then(onIconSizeChanged);
      changes.draggable && gMapsInit.then(onDraggableChanged);
      changes.animation && gMapsInit.then(onAnimationChanged);
    }
  };

  function onPositionChanged(){
    marker && marker.setPosition(angular.copy(vm.position))
  }

  function onIconChanged(){
    if(marker) {
      let icon = marker.getIcon();
      marker.setIcon({
        url: vm.icon,
        scaledSize: icon.scaledSize
      });
    }
  }

  function onIconSizeChanged(){
    if(marker) {
      let icon = marker.getIcon();
      marker.setIcon({
        url: icon.url,
        scaledSize: vm.iconSize
      });
    }
  }

  function onDraggableChanged(){
    marker && marker.setDraggable(vm.draggable);
  }

  function onAnimationChanged(){
    marker && marker.setAnimation(RbGoogle.newAnimation(vm.animation));
  }

  function getParentMap(googleMaps){
    GoogleMaps = googleMaps;
    return vm.MapCtrl.getMap();
  }

  function newMarker(map){
    parentMap = map;
    if(!marker){
      let markerData = {
        map: parentMap,
        position: angular.copy(vm.position) || {lat:0, lng: 0},
        draggable: angular.isDefined(vm.draggable) ? vm.draggable : true
      };

      if(vm.icon){ markerData.icon = { url: vm.icon }; }
      if(vm.iconSize){ markerData.icon.scaledSize = vm.iconSize; }
      if(vm.animation){ markerData.animation = RbGoogle.newAnimation(vm.animation); }
      marker = new GoogleMaps.Marker(markerData);
    }
  }
}
