<template>
  <div>
    <div
      v-if="open"
      :class="$style.close"
      class="button dark"
      @click="close"
    >
      <i class="material-icons">visibility_off</i> <span>Exit Selection</span>
    </div>
    <div
      v-show="open"
      :style="fullStyle"
      :class="$style.main"
    >
      <color-picker
        :value="color"
        :classes="colorActive ? $style.active : ''"
        label="Solid Color"
        @input="setColor"
      />
      <vue-perfect-scrollbar :class="$style.flipped">
        <div :class="$style.samples">
          <div
            v-for="(sample, index) in sampleImages"
            :key="index"
            :class="{
              [$style.sample]: true,
              [$style.active]: activeImage(sample)
            }"
            @click="setImage(sample)"
          >
            <img
              :src="sample"
              :class="$style.img"
              alt="Sample Background Image"
            >
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import ColorPicker from 'vRoot/_core/RbColorPicker.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'HotelDirectoryViewBackgroundEditor',
  components: { ColorPicker, VuePerfectScrollbar },
  props: {
    value: {
      required: true,
      type: Object
    },
    open: {
      required: true,
      type: Boolean
    },
    full: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      bg: this.value,
      color: this.value.type === 'COLOR' ? this.value.value : '#aac',
      image: this.value.type === 'IMAGE' ? this.value.value : '',
      sampleImages: [
        'https://images.pexels.com/photos/1028600/pexels-photo-1028600.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/611328/pexels-photo-611328.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/1046348/pexels-photo-1046348.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        // 'https://one.walmart.com/content/dam/px/associate_brand_center/zoom_backgrounds/Zoom_BKG_Exploration_4.jpg',
        'https://www.readybid.net/images/Zoom_BKG_Exploration_4.jpg',
        'https://images.pexels.com/photos/879356/pexels-photo-879356.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/988870/pexels-photo-988870.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/1183099/pexels-photo-1183099.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/1027393/pexels-photo-1027393.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/1352196/pexels-photo-1352196.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/47424/pexels-photo-47424.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/956981/milky-way-starry-sky-night-sky-star-956981.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/869258/pexels-photo-869258.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/1526/dark-blur-blurred-gradient.jpg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/1242348/pexels-photo-1242348.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/1210276/pexels-photo-1210276.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/370799/pexels-photo-370799.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/268819/pexels-photo-268819.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/212324/pexels-photo-212324.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/259698/pexels-photo-259698.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/188972/dandelion-inversely-flower-plant-188972.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/315938/pexels-photo-315938.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/983200/pexels-photo-983200.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/247671/pexels-photo-247671.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/545313/pexels-photo-545313.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/158729/rock-pattern-background-texture-158729.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/122990/pexels-photo-122990.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/220177/pexels-photo-220177.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/38132/pexels-photo-38132.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://images.pexels.com/photos/136206/pexels-photo-136206.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
        'https://www.readybid.net/images/Readybidfinal.jpg'
      ]
    };
  },
  computed: {
    colorActive() {
      return this.value.type === 'COLOR';
    },
    fullStyle() {
      return {
        'padding-right': this.full ? `55px` : '285px'
      };
    }
  },
  methods: {
    setColor(value) {
      this.color = value;
      this.$emit('input', {
        type: 'COLOR',
        value
      });
    },
    setImage(value) {
      this.image = value;
      this.$emit('input', {
        type: 'IMAGE',
        value
      });
    },
    activeImage(sample) {
      return this.image === sample && this.value.type === 'IMAGE';
    },
    close() {
      this.$emit('close');
    },
    show() {
      this.$emit('open');
    }
  }
};
</script>

<style lang="stylus" module>
.main{
    width 100%;
    height 85px;
    background-color #263238;
    display flex;
    position fixed;
    bottom 0;
    box-sizing border-box;
    align-items flex-end;
    padding-left: 10px;
}

.color{
    overflow: hidden;
    width: 70px;
    height: 70px;
    box-sizing: border-box;
    border-radius 6px !important;
}

.active{
    border 3px solid #2db8aa !important;
}

.samples{
    height 70px;
    flex 100%;
    display flex;
    margin-top 15px;
}

.sample{
    height: 70px;
    box-sizing: border-box;
    cursor: pointer;
    min-width 70px;
    flex 0;
    margin 0 2.5px;
    border-radius 10px !important;
    overflow hidden;
    margin-top 15px;
}

.sample:last-child{
    margin-right: 280px;
}

.img{
    width: 70px;
    height: 70px;
    object-fit cover;
}

.button i{
  margin 0 !important;
}

.button:hover i{
  margin 0 10px 0 -5px !important;
}

.button span{
  display none;
}

.button:hover span{
  display inline;
}

.close{
    composes button;
    position: fixed;
    right: 0;
    bottom: 80px;
    border-radius 0 !important;
    border-top-left-radius: 18px !important;
}

.flipped{
    overflow-x auto;
    overflow-y hidden;
}

.flipped, .flipped .samples{
    transform:rotateX(180deg);
    -ms-transform:rotateX(180deg); /* IE 9 */
    -webkit-transform:rotateX(180deg);
}
</style>
