import {
  accountRepository,
  notificationUtils,
  mainAPI,
  httpErrorHandler,
  $state
} from 'root/angular-injector-provider';
import { noop } from 'lodash'
import AccountProfileModulesLibrary from 'vRoot/account-profile/modules/AccountProfileModulesLibrary'
import {PAGE_COMPANY_PROFILE} from 'root/states';
import { Dialog } from 'root/v-app/rbServices';
import UpdateProfilePictureDialog from 'vRoot/_core/dialogs/UploadPhotoDialog.vue';

let id,
  account,
  entity,
  availableModules,
  activeModule;

export default {
  initialize: initialize,
  refresh: loadData,
  getModules () { return Promise.resolve(availableModules) },
  getActiveModule () { return activeModule },
  getAccount () { return account },
  getEntity () { return entity },
  getLogo () { return entity.then( e => e.logo ) },
  editLogo: editLogo,
  editImage: editImage,
}

function initialize(accountType, accountId){
  id = accountId;
  availableModules = AccountProfileModulesLibrary.loadModules(accountType);
  activeModule = findActiveModule(availableModules)
    .catch(handleActiveModuleErrors);

  loadData();

  return activeModule
}

function findActiveModule(modules) {
  const currentModule = $state().params.module,
    active = currentModule && modules.find( m => m.id === currentModule );

  return active ? Promise.resolve(active) : Promise.reject('no active modules')
}

function loadData(){
  account = accountRepository().getAccount(id)
    .then(httpResponse => httpResponse.data, httpErrorHandler().handle);

  entity = account.then( a => mainAPI().getEntity(a.type, a.entityId) )
    .then(httpResponse => httpResponse.data , httpErrorHandler().handle);

  return entity
}

async function editLogo(e, cb = loadData) {
  e = e || await entity;
  const options = {
    croppieOptions: {
      viewport: { width: 260, height: 150 },
      boundary: { width: 490, height: 330 },
      enableResize: true,
      showZoomer: true
    }
  };

  return Dialog.show( UpdateProfilePictureDialog, { options, title: 'Upload Logo' }, { closeOnOutsideClick: false })
    .then( data => notificationUtils().onSave(
      () => mainAPI().updateEntityLogo(e.type, e.id, data.file)
        .then( cb )
    ))
}

async function editImage(e, cb = loadData) {
  const ent = e || await entity;
  return Dialog.show( UpdateProfilePictureDialog, {title: 'Upload Image'}, { closeOnOutsideClick: false })
    .then(data => notificationUtils().onSave(
      () => mainAPI().updateEntityImage(ent.type, ent.id, data.file)
        .then( cb )
    ))
}

function handleActiveModuleErrors(){
  if (availableModules.length) {
    $state().go(PAGE_COMPANY_PROFILE, {module: availableModules[0].id}).catch(noop)
  } else {
    $state().go('error', {id: '500'}).catch(noop)
  }
}
