<template>
  <div class="rbSelectWrapper">
    <input
      class="rbSelect"
      readonly="readonly"
      :value="selected.label"
      @click="toggleOptions()"
      @blur="hideOptions()"
      @focus="unselectInputText($event)"
      @keydown="handleKeyDown($event)"
    >

    <div
      class="rbSelectArrow"
      :class="arrowClasses"
    />

    <ul
      v-if="opened"
      class="rbOptions"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        class="rbOption"
        :class="{ 'highlighted' : preselectedInList === index }"
        @mousemove="preselectedInList = index"
        @mousedown.stop.prevent="select( index, $event )"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script>
  import { isNil, isEqual, isEmpty, debounce } from 'lodash'
  import Vue from 'vue'
  // noinspection JSFileReferences
  import Popper from 'popper.js'

  const
    KEY_CODE_ENTER = 13,
    KEY_CODE_SPACE = 32,
    KEY_CODE_PAGE_UP = 33,
    KEY_CODE_PAGE_DOWN = 34,
    KEY_CODE_HOME = 35,
    KEY_CODE_END = 36,
    KEY_CODE_ARROW_UP = 38,
    KEY_CODE_ARROW_DOWN = 40

  export default {
    name: 'RbSelect',

    props: {
      pageSize: {
        'default': 5,
        type: Number
      },
      value: {
        'default': undefined
      },
      disabled: Boolean
    },

    data () {
      return {
        opened: false,
        options: [],
        popper: null,
        preselectedInList: -1,
        searchQuery: '',
        selected: {},
        updating: false
      }
    },

    computed: {
      arrowClasses () {
        return {
          'arrowUp': this.opened,
          'arrowDown': !this.opened
        }
      }
    },

    watch: {
      value (val) { this.selectOptionWithValue(val) }
    },

    created () { this.updateOptions() },

    destroyed () { this.popper && this.popper.destroy() },

    methods: {

      loadOptionsFromElements (slot) {
        const options = []
        slot && slot.forEach(vNode => {
          if (vNode.tag === 'rb-option') {
            const attrs = vNode.data && ( vNode.data.domProps || vNode.data.attrs )
            options.push({
              value: attrs && !isNil(attrs.value) ? attrs.value : undefined,
              label: vNode.children[0].text.trim()
            })
          }
        })
        return options
      },

      handleKeyDown ($event) {
        if ($event.ctrlKey) { return }

        $event.altKey
          ? handleKeyWithAltKey.call(this, $event.keyCode)
          : handleKey.call(this, $event)

        function handleKeyWithAltKey (keyCode) {
          switch (keyCode) {
            case KEY_CODE_ARROW_UP:
              this.hideOptions()
              break;
            case KEY_CODE_ARROW_DOWN:
              this.showOptions()
              break;
            default:
          }
        }

        // eslint-disable-next-line complexity
        function handleKey (event) {
          if (!event.shiftKey) {
            switch (event.keyCode) {
              case KEY_CODE_ENTER:
                event.stopPropagation()
                event.preventDefault()
                if (this.opened) {
                  this.select(this.preselectedInList)
                  this.hideOptions()
                } else {
                  this.showOptions()
                }
                return

              case KEY_CODE_PAGE_UP:
                this.select(this.preselectedInList - this.pageSize)
                return

              case KEY_CODE_PAGE_DOWN:
                this.select(this.preselectedInList + this.pageSize)
                return

              case KEY_CODE_END:
                this.select(this.options.length - 1)
                return

              case KEY_CODE_HOME:
                this.select(0)
                return

              case KEY_CODE_SPACE:
                this.showOptions()
                return

              case KEY_CODE_ARROW_UP:
                this.select(this.preselectedInList - 1)
                return

              case KEY_CODE_ARROW_DOWN:
                this.select(this.preselectedInList + 1)
                return

              default:
            }
          }

          if (/[a-zA-Z0-9-_]/.test(event.key)) {
            this.searchQuery += event.key
            this.searchOptions()
          }
        }
      },

      hideOptions () {
        if (this.opened) {
          this.popper && this.popper.destroy()
          this.popper = null
          this.opened = false
        }
      },

      searchOptions () {
        if (!this.debouncedSearch) {
          this.debouncedSearch = debounce(search, 300)
        }
        this.debouncedSearch()

        function search () {
          const query = this.searchQuery.toLowerCase()
          this.searchQuery = ''

          let foundOptionIndex = -1
          for(let i=0, l=this.options.length; i<l; i++){
            const optionLabel = this.options[i].label
            if(optionLabel.toLowerCase().startsWith(query)){
              if(i > this.preselectedInList) {
                this.select(i)
                return
              } else {
                foundOptionIndex = i
                i = this.preselectedInList
              }
            }
          }
          if(foundOptionIndex > -1) { this.select( foundOptionIndex ) }
        }
      },

      select (index, $event) {
        if ($event && $event.button === 2) { return } // ignore right click
        const validIndex = validateIndex(index, this.options.length),
          selectedOption = this.options[validIndex]

        this.preselectedInList = validIndex
        if (selectedOption !== this.selected) {
          this.selected = selectedOption

          if (this.opened) { assureThatPreselectedOptionIsVisibleInList(this.$el.lastChild, validIndex) }
          this.hideOptions()
          this.$emit('input', selectedOption.value)
          this.$emit('change', selectedOption.value)
        }

        function validateIndex (i, optionsLength) {
          return i < 0 ? 0 : i > optionsLength - 1 ? optionsLength - 1 : i
        }

        function assureThatPreselectedOptionIsVisibleInList (listElement, optionIndex) {
          const selectedElement = listElement.children[optionIndex],
            listElementRect = listElement.getBoundingClientRect(),
            selectedElementRect = selectedElement.getBoundingClientRect()

          if (selectedElementRect.top < listElementRect.top) {
            selectedElement.scrollIntoView(true)
          } else if (selectedElementRect.bottom > listElementRect.bottom) {
            selectedElement.scrollIntoView(false)
          }
        }
      },

      selectOptionWithValue (val) {
        if (this.options.length) {
          const optionIndex = this.options.findIndex( option => isEqual(val, option.value) )
          if ( optionIndex > -1 ) { this.select(optionIndex) }
          isEmpty(this.selected) && this.select(0)
        }
      },

      showOptions () {
        if (!this.opened) {
          this.preselectedInList = this.options.indexOf(this.selected)
          this.opened = true

          Vue.nextTick(() => {
            if (this.popper) {
              this.popper.scheduleUpdate()
            } else {
              this.popper = new Popper(this.$el, this.$el.lastChild, {placement: 'bottom-start'})
            }
          })
        }
      },

      toggleOptions () {
        if(!this.disabled) {
          this.opened ? this.hideOptions() : this.showOptions()
        }
      },

      unselectInputText (event) {
        event.target.selectionStart = 0
        event.target.selectionEnd = 0
      },

      updateOptions () {
        if (!this.priorSlot || !isEqual(this.priorSlot, this.$slots.default)) {
          this.priorSlot = this.$slots.default
          this.options = this.loadOptionsFromElements(this.$slots.default)
          if(this.options.length) { this.selectOptionWithValue(this.value) }
        }
      },
    },
  }
</script>

<style lang="stylus">

  .rbSelectWrapper {
    position: relative;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    margin: 0;
    font: 13.3333px Arial;

    box-sizing: border-box;
    align-items: center;
    white-space: pre;
    -webkit-rtl-ordering: logical;
    color: black;
    background-color: white;
    cursor: default;

    overflow: visible !important;
  }

  .rbSelect {
    border: 1px solid rgb(169, 169, 169);
    border-image: initial;
    border-radius: 0;
    width: 100%

    &:focus {
      outline: 1px solid #2390fd
    }
  }

  .rbSelectArrow {
    position: absolute;
    top: 0;
    right: 0

    width: 0;
    height: 0;

    &.arrowUp {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 5px solid black;
    }

    &.arrowDown {
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;

      border-top: 20px solid #000;
    }
  }

  .rbOptions {
    z-index: 100000;
    background white
    margin: 0;
    list-style: none;
    padding: 0;
    border: 1px solid rgb(169, 169, 169)
    position absolute
    width: 100%

    max-height 150px
    overflow-y: auto
  }

  .rbOption {
    &.highlighted {
      color: white
      background: #2390fd
    }
  }

  .rbSelectWrapper.simple {
    display block

    .rbSelectArrow {
      position: absolute;
      top: 15px;
      right: 10px;

      &.arrowUp {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        border-bottom: 6x solid #37474f;
      }

      &.arrowDown {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        border-top: 6px solid #37474f;
      }
    }

    .rbSelect, .rbOption {
      cursor pointer
      color: #37474f;
      height: 36px;
      line-height: 36px;
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #dcdee0;
      text-indent: 5px;
      transition: all .5s ease-out;
      padding-right: 26px;
      text-align: left;
      font-size: 13px;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none !important;
    }

    .rbOption {
      border: 1px solid transparent;
      border-bottom: 1px solid #dcdee0;

      &.highlighted {
        background: rgba(0, 169, 157, .3);
      }
    }
  }
</style>
