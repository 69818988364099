/**
 * Created by DejanK on 4/5/2017.
 *
 *
 */

export default BidManagerBidActionsService;

BidManagerBidActionsService.$inject = ['BidManagerSingleBidActionsService', 'BidManagerBulkBidActionsService'];
function BidManagerBidActionsService(SingleBidActions, BulkBidActions) {
  const $service = this;

  $service.getActions = getActions;

  function getActions(selectedBids, view){
    if(!selectedBids || selectedBids.length === 0) return null;
    const actions = selectedBids.length === 1
      ? SingleBidActions.getActions(selectedBids[0], view)
      : BulkBidActions.getActions(selectedBids, view);

    return actions && actionsNotEmpty() ? actions : null;

    function actionsNotEmpty(){
      return Object.keys(actions).reduce((acc, key) => acc || !!actions[key].length, false);
    }
  }
}


