/**
 * Created by DejanK on 5/16/2017.
 *
 * Bid Manager Actions Factory
 */
import noop from 'lodash/noop';
import {statusMatches, isSingleBid, getBidsIds} from 'rfp/bid-manager/main/core/actions/bid-manager-actions-factory-helpers';

import {NAME as ForwardToNationalSalesDialog} from 'root/rfp/bid-manager/main/dialogs/forward-to-national-sales/forward-to-national-sales.ctrl';
import {NAME as dueDateExpiredNotification} from 'root/rfp/bid-manager/main/dialogs/due-date-expired/due-date-expired.ctrl';
import {NAME as NotImplementedNotification} from 'root/ui-ng/notifications/dialogs/not-implemented/not-implemented-notification.ctrl';
import ContactAndNotesDialog from 'root/rfp/bid-manager/main/dialogs/set-supplier-contact/ContactAndNotesDialog.svelte';
import {NAME as CreateNewSupplierContact} from 'root/rfp/bid-manager/main/dialogs/create-new-supplier-contact/create-new-supplier-contact.ctrl';

import {NAME as DeleteBidsDialog} from 'root/rfp/bid-manager/main/dialogs/delete-bids/delete-bids.ctrl';
import {NAME as UPLOAD_HOTEL_RFP_RESPONSES_DIALOG} from 'root/rfp/bid-manager/main/dialogs/upload-responses/upload-responses.ctrl';
import SendNotInterestedDialog from 'vRoot/rfp-hotel/bid-manager/actions/SendNotInterestedAction.vue';
import SendNoLongerInterestedDialog from 'vRoot/rfp-hotel/bid-manager/actions/SendNoLongerInterestedAction.vue';
import SendReminderAction from 'vRoot/rfp-hotel/bid-manager/actions/SendReminderAction.vue';
import UploadResponsesActionReport
  from 'vRoot/rfp-hotel/bid-manager/actions/action-report/UploadResponsesActionReport.vue';
import UploadSuccessfulReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/UploadSuccessfulReport.vue';
import DeleteBidsActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/DeleteBidsActionReport.vue';
import ResetBidsActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/ResetBidsActionReport.vue';
import SendBidsActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendBidsActionReport.vue';
import SetContactActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SetContactActionReport.vue';
import SendResponsesActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendResponsesActionReport.vue';
import SendNotInterestedActionReport
  from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendNotInterestedActionReport.vue';
import SendNoLongerInterestedActionReport
  from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendNoLongerInterestedActionReport.vue';
import SendReminderActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendReminderActionReport.vue';

import ForceSendingInvalidResponsesQuestion
  from 'vRoot/rfp-hotel/bid-manager/actions/questions/ForceSendingInvalidResponsesQuestion.vue';
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';
import SendFinalAgreements from './action/SendFinalAgreements.js';
import {viewBidPackage, exportBidPackage} from './action/BidPackage.js';

import createAcceptCompetitiveBid from './action/AcceptCompetitiveBid.js';
import createRefuseCompetitiveBid from './action/RefuseCompetitiveBid.js';
import createAcknowledgeDeclinedCompetitiveBid from './action/AcknowledgeRefusedCompetitiveBid.js';
import createViewCompetitiveBidAction from './action/ViewCompetitiveBid.js';
import createDeclineCompetitiveBidAction from './action/DeclineCompetitiveBid.js';
import createEditCompetitiveBidResponseAction from './action/EditCompetitiveBidResponse.js';
import createSendCompetitiveBidResponseAction from './action/SendCompetitiveBidResponse.js';
import SetNoThankYouPending from './action/SetNoThankYouPending.js';
import SetFinalAgreementPending from './action/SetFinalAgreementPending';
import createUpdateResponseAction from './action/UpdateBidResponse.js';
import ResendFinalAgreement from './action/ResendFinalAgreements';
import NegotiationsUpload from './action/UploadNegotiations';
import SendNoThankYouLetters from './action/SendNoThankYou';
import ViewNoThankYouLetter from './action/ViewNoThankYou';
import ChangeTravelDestination from './action/ChangeTravelDestination.js';
import CopyBidLink from './action/CopyBidLink.js';
import createDeclineBidAction from './action/DeclineBid';
import {MarkRatesAsLoaded, MarkRatesAsNotLoaded, RequestRateLoading} from './action/rate-loading/rate-loading'

import {Dialog as VueDialog, SvelteDialog} from 'root/v-app/rbServices';
import ContactsSetSuccessDialog from 'vRoot/rfp/bid-manager/contacts/ContactsSetSuccessDialog.vue';
import {HOTEL_RFP_BID_SPECIFICATIONS, HOTEL_RFP_SPECIFICATIONS,} from 'root/states';


const
  CREATED = 'CREATED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  RESPONDED = 'RESPONDED',
  NEGOTIATION_SENT = 'NEGOTIATION_SENT',
  NEGOTIATION_RESPONDED = 'NEGOTIATION_RESPONDED',
  NEGOTIATION_FINALIZED = 'NEGOTIATION_FINALIZED',
//  NO_THANK_YOU = 'NO_THANK_YOU',
  NO_THANK_YOU_PENDING = 'NO_THANK_YOU_PENDING',
  FINAL_AGREEMENT_PENDING = 'FINAL_AGREEMENT_PENDING',
  FINAL_AGREEMENT = 'FINAL_AGREEMENT',
  BUYER_DRAFT = 'BUYER_DRAFT',
  BUYER_DELETED = 'BUYER_DELETED';

export default BidManagerActionsFactory;

BidManagerActionsFactory.$inject = ['rbDialog', 'BidManagerRepository', 'BidManagerService', 'NegotiationsPanelMessagingService', '$state', 'MainAPI', 'NotificationUtils'];
function BidManagerActionsFactory(dialog, BidManagerRepository, bidManagerService, NegotiationsPanelMessagingService, $state, mainAPI, notificationUtils) {

  return {
    sendBid: createSendBid,
    sendBids: createSendBids,
    setContact: createSetContact,
    setContacts: createSetContacts,
    previewBid: createPreviewBid,
    editBid: editBid,
    deleteBid: deleteBid,
    deleteBids: deleteBids,
    openNegotiationsPanel: openNegotiationsPanel,
    withdrawBid: withdrawBid,
    withdrawBids: withdrawBids,
    setFinalAgreementPending: SetFinalAgreementPending(bidManagerService.handleActionResult),
    sendFinalAgreement: sendFinalAgreement,
    sendFinalAgreements: SendFinalAgreements(bidManagerService.handleActionResult),
    editFinalAgreement: createEditFinalAgreement,
    viewFinalAgreement: viewFinalAgreement,
    setNoThankYouPending: SetNoThankYouPending(bidManagerService.handleActionResult),
    sendNoThankYouLetters: SendNoThankYouLetters(bidManagerService.handleActionResult),
    viewNoThankYouLetter: ViewNoThankYouLetter(),
    editRfp: editRfp,
    createEditResponse: createEditResponse,
    createManualEntry: createManualEntry,
    sendResponses: sendResponses,
    uploadResponses: createUploadResponses,
    uploadNegotiations: NegotiationsUpload.upload(bidManagerService),
    reUploadNegotiations: NegotiationsUpload.reUpload(bidManagerService),
    sendNotInterested: createDeclineBidAction(bidManagerService, statusMatches),
    sendNotInterestedInBulk: createDeclineBidAction(bidManagerService, statusMatches),
    sendNoLongerInterested: sendNoLongerInterested,
    forwardToNationalSales: createForwardToNationalSales,
    removeDeletedBids,
    resetBids,
    sendReminder,
    viewBidPackage: viewBidPackage,
    exportBidPackage: exportBidPackage,
    acceptCompetitiveBid: createAcceptCompetitiveBid(bidManagerService),
    refuseCompetitiveBid: createRefuseCompetitiveBid(bidManagerService),
    removeRefusedCompetitiveBid: createAcknowledgeDeclinedCompetitiveBid(bidManagerService),
    viewCompetitiveBid: createViewCompetitiveBidAction(),
    declineCompetitiveBid: createDeclineCompetitiveBidAction(bidManagerService),
    editCompetitiveBidResponse: createEditCompetitiveBidResponseAction(),
    sendCompetitiveBidResponse: createSendCompetitiveBidResponseAction(bidManagerService),
    resendFinalAgreements: ResendFinalAgreement(bidManagerService.handleActionResult),
    updateResponse: createUpdateResponseAction(),
    sendTotelDirectory,
    changeTravelDestination: ChangeTravelDestination(bidManagerService.handleActionResult),
    copyBidLink: CopyBidLink(),
    requestRateLoading: RequestRateLoading(bidManagerService.handleActionResult),
    markRatesAsLoaded: MarkRatesAsLoaded(bidManagerService.handleActionResult),
    markRatesAsNotLoaded: MarkRatesAsNotLoaded(bidManagerService.handleActionResult)
  };

  function createSendBid(options = {}){
    return function sendBid(report) {
      return {
        label: options.label || 'Send Bid',
        icon: options.icon || 'send',
        isAvailable () {
          return allSelectedBidsHaveSupplierContact(report.bids);
        },
        action: sendBidsAction(report.bids)
      }
    }
  }

  function createSendBids(options = {}){
    return function sendBids(report){
      return {
        label: options.label || 'Send Bids',
        icon: options.icon || 'send',
        isAvailable () {
          return statusMatches(report.statuses, [ CREATED, SENT, RECEIVED ]) && allSelectedBidsHaveSupplierContact(report.bids);
        },
        action: sendBidsAction(report.bids)
      }
    }
  }

  function allSelectedBidsHaveSupplierContact(bids) {
    return !bids.find( bid => !bid.supplier.contact );
  }

  function sendBidsAction(bids){
    return () => {
      sendBids( getBidsIds(bids) )
        .then(actionReport => bidManagerService.handleActionResult(actionReport, bids, SendBidsActionReport))
        .catch(noop);

      function sendBids(bidsIds, forceSending) {
        return notificationUtils.onSave(() => mainAPI.sendBids(bidsIds, forceSending), {
          customErrorHandlers: {
            400(error) {
              if (error.data && error.data.id === 'DUE_DATE_EXPIRED') {
                return dialog.show(dueDateExpiredNotification, { locals: {
                    expiredDueDate: error.data.value
                  }})
                  .then(() => sendBids(bidsIds, true));
              } else {
                return notificationUtils.handleErrors(error);
              }
            }
          }
        });
      }
    };
  }

  function createSetContact(options = {}){
    return function setContact(report){
      return {
        label: options.label || 'Edit Contact',
        icon: options.icon || 'person',
        isAvailable () {
          return beforeXOrAfter(report)
        },
        action: setContactsAction(report.bids, report.view)
      };
    };
  }

  function beforeXOrAfter({statuses}) {
    const before = statusMatches(statuses, [ CREATED, SENT, RECEIVED, NOT_INTERESTED ]);
    const after = statusMatches(statuses, [ RESPONDED, NEGOTIATION_SENT, NEGOTIATION_RESPONDED, NEGOTIATION_FINALIZED,FINAL_AGREEMENT ]);
    return before? !after: after;

  }

  function createSetContacts(options = {}){
    return function setContact(report){
      return {
        label: options.label || 'Set NAM Contact',
        icon: options.icon || 'contacts',
        isAvailable () {
          return report.chainSupport && report.chain && beforeXOrAfter(report);
        },
        action: setContactsAction(report.bids, report.view)
      };
    };
  }

  function setContactsAction(bids, view){
    return () => {

      SvelteDialog.show(ContactAndNotesDialog, { bids, side: view.side, tab: 'contacts' })
        .then( res => (res && (res.create || res.edit))
          ? createNewContact(res.user) : handleActionResult(res, bids, SetContactActionReport) )
        .catch(noop)
    };

    function createNewContact(contact) {
      return dialog.show(CreateNewSupplierContact, { locals: {Contact: contact, Bids: bids}})
        .then(res => {
          handleActionResult(res, bids, SetContactActionReport);
          const names = res.children.map(({tObject}) => tObject.supplier.company.name);

          if(names.length > 1) {
            return VueDialog.show(ContactsSetSuccessDialog, {
              names,
              bids: res.children.map(({tObject}) => tObject)
            });
          }else{
            return notificationUtils.showSuccessDialog({dialogData: {
                title: 'Success',
                message: `The contact for your ${bids.length > 1? 'bids': 'bids'} to ${names.join(',')} ${bids.length > 1? 'have': 'has'} been set successfully.`,
              }});
          }
        });
    }

    function handleActionResult(actionReport) {
      return bidManagerService.handleActionResult(actionReport, bids, SetContactActionReport);
    }
  }

  function createPreviewBid(options = {}) {
    return function previewBid(report) {
      return {
        label: options.label || 'Preview Bid',
        icon: options.icon || 'remove_red_eye',
        action () {
          $state.inNew('hotelRfp.bid.preview', {bidId: report.bids[0]._id});
        }
      }
    };
  }

  function editBid(report) {
    return {
      label: 'Edit Bid',
      icon: 'edit',
      isAvailable: () => report.bids[0].rfp.specifications.subType === 'STANDARD',
      action: () => {
        $state.inNew(HOTEL_RFP_BID_SPECIFICATIONS, {bidId: report.bids[0]._id});
      }
    };
  }

  function deleteBid(report) {
    return {
      label: 'Delete Bid',
      icon: 'delete',
      isAvailable: () => report.bids.find(b => !b.isCompetitiveOffer && b.state.status !== 'COMPETITIVE_REFUSED'),
      action: deleteBidsAction(report.bids)
    };
  }

  function deleteBids(report){
    return {
      label: 'Delete Bids',
      icon: 'delete',
      isAvailable: () => true,
      action: deleteBidsAction(report.bids)
    };
  }

  function deleteBidsAction(bids){
    return () => {
      dialog.show(DeleteBidsDialog, { locals: { Bids: bids }})
        .then(actionResult => bidManagerService.handleActionResult(actionResult, bids, DeleteBidsActionReport))
        .catch(noop)
    };
  }

  function openNegotiationsPanel(report) {
    return {
      label: 'Negotiate',
      icon: 'forum',
      action () { NegotiationsPanelMessagingService.openNegotiationsPanel(report.bids[0]._id); }
    };
  }

  function withdrawBid() {
    return {
      label: 'Withdraw Bid',
      icon: 'delete',
      action () { dialog.show( NotImplementedNotification ).catch( noop ); }
    };
  }

  function withdrawBids(selectedBidsReport){
    return {
      label: 'Withdraw Bids',
      icon: 'delete',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [ SENT ]),
      action () { dialog.show( NotImplementedNotification ).catch( noop ); }
    };
  }

  function sendFinalAgreement(report) {
    return {
      label: 'View Final Agreement',
      icon: 'thumb_up',
      action: ( ) => { $state.inNew('hotelRfp.bid.finalAgreement.send', { bidId: report.bids[0]._id }); }
    };
  }

  function createEditFinalAgreement(options = {}) {
    return function previewBid(report) {
      return {
        label: options.label || 'Edit Final Agreement',
        icon: options.icon || 'thumb_up',
        action () { $state.inNew('hotelRfp.bid.finalAgreement.send', { bidId: report.bids[0]._id }); }
      };
    }
  }

  function viewFinalAgreement(report) {
    return {
      label: 'View Final Agreement',
      icon: 'thumb_up',
      action () { $state.inNew('hotelRfp.bid.finalAgreement.view', { bidId: report.bids[0]._id }); }
    };
  }

  function editRfp(selectedBidsReport) {
    return {
      label: 'Edit RFP',
      icon: 'folder_shared',
      isAvailable: () => selectedBidsReport.sharedRfp,
      action () {
        $state.inNew(HOTEL_RFP_SPECIFICATIONS, { rfpId: selectedBidsReport.sharedRfp });
      }
    };
  }

  function createEditResponse(options = {}) {
    return function sendResponse(report) {
      return {
        label: options.label || 'Edit Response',
        icon: options.icon || 'edit',
        action: () => {
          $state.inNew('hotelRfp.bid.response', { bidId: report.bids[0]._id });
        }
      };
    };
  }

  function createManualEntry(options = {}) {
    return function sendResponse(report) {
      return {
        label: options.label || 'Manual Entry',
        icon: options.icon || 'edit',
        action: () => {
          $state.inNew('hotelRfp.bid.manualEntry', { bidId: report.bids[0]._id });
        }
      };
    };
  }

  function sendResponses(report){
    return {
      label: isSingleBid(report) ? 'Send Response' : 'Send Responses',
      icon: 'textsms',
      isAvailable: () => statusMatches(report.statuses, [ RECEIVED ]),
      action : sendResponsesAction(report.bids)
    }
  }

  function sendTotelDirectory(report){
    const ids = report.bids.map(({_id}) => _id);
    return {
      label: 'Move to Hotel Directory',
      icon: 'list_alt',
      isAvailable: () => statusMatches(report.statuses, [ RESPONDED ]),
      action : () => notificationUtils.onSave(
        () => mainAPI.sendToHotelDirectory(ids),
        {
          customErrorHandlers: {
            404() {
              VueDialog.show(WarningDialog, {
                title: 'Hotel Directory Not found',
                subTitle: `The hotel directory for ${ids.length > 1? 'these hotels': 'this hotel'} might not have been created yet. Please visit the hotel directory to create it. Then try sending ${ids.length > 1? 'these hotels': 'this hotel'} to the hotel directory again.`,
                showMainButton: false
              });
              return Promise.reject();
            }
          }
        }
      ).then(() => notificationUtils.showSuccessDialog({
        dialogData: {
          title: 'Successfull',
          message: `The bid${ids.length > 1? 's were': ' was'} successfully added to the hotel directory.`,
        }
      }))
    }
  }

  function sendResponsesAction(bids){
    return () => {
      sendResponsesToAPI(bids, bids.map( b => b._id ))
        .then(httpResponse => bidManagerService.handleActionResult(httpResponse, bids, SendResponsesActionReport))
        .catch(noop);

      function sendResponsesToAPI(bidsToSend, bidsIds, forceSending) {
        return notificationUtils.onSave(() => mainAPI.sendResponses(bidsIds, forceSending), {
          customErrorHandlers: {
            400(error) {
              return (error.data && error.data.id === 'RESPONSE_NOT_VALID')
                ? VueDialog.show(ForceSendingInvalidResponsesQuestion, { isBulk: bidsToSend.length > 1 })
                  .then(() => sendResponsesToAPI(bidsToSend, bidsIds, true))

                : notificationUtils.handleErrors(error);
            }
          }
        });
      }
    };
  }


  function createUploadResponses(options = {side: 'SUPPLIER'}){
    const states =  options.side === 'SUPPLIER'? [ SENT, RECEIVED, NOT_INTERESTED ]: [ CREATED, SENT, RECEIVED, BUYER_DRAFT, NOT_INTERESTED ];

    return function sendResponse(report) {
      return {
        label: options.side === 'SUPPLIER'? options.label || isSingleBid(report) ? 'Upload Response' : 'Upload Responses': 'Manual Entry Upload',
        icon: options.icon || 'open_in_browser',
        isAvailable: () => statusMatches(report.statuses, states),
        action: () => {
          dialog.show( UPLOAD_HOTEL_RFP_RESPONSES_DIALOG, { locals: { Bids: report.bids }} )
            .then(actionReport => bidManagerService.handleActionResult(actionReport, report.bids, UploadResponsesActionReport, UploadSuccessfulReport, options))
            .catch(noop)
        }
      };
    }
  }

  function sendNoLongerInterested(selectedBidsReport) {
    return {
      label: 'Decline rebid',
      icon: 'delete',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [NEGOTIATION_SENT, NEGOTIATION_FINALIZED, NO_THANK_YOU_PENDING, FINAL_AGREEMENT_PENDING]),
      action: () => sendAction(selectedBidsReport.bids, SendNoLongerInterestedDialog, SendNoLongerInterestedActionReport)
    };
  }

  function createForwardToNationalSales(options = {}){
    return function forwardToNationalSales(){
      return {
        label: options.label || 'Forward to National Sales',
        icon: options.icon || 'remove_red_eye',
        action: () => { dialog.show(ForwardToNationalSalesDialog) }
      };
    };
  }

  function removeDeletedBids(report) {
    return {
      label: isSingleBid(report) ? 'Delete Bid' : 'Delete Bids',
      icon: 'delete',
      isAvailable: () => statusMatches(report.statuses, [ BUYER_DELETED ]),
      action : () => removeBids(report.bids)
    }
  }

  function removeBids(bids) {
    const text = bids.length === 1? 'this Bid': `these ${bids.length} Bids`;
    return VueDialog.show(WarningDialog, {
      title: `Remove ${text} from bid manager`,
      subTitle: `You are about to remove ${text} from your bid manager. This action can not be reverted.`
    })
      .then(() => notificationUtils.onSave(() => mainAPI.deleteBidsPermanently(getBidsIds(bids))))
      .then(actionResult => bidManagerService.handleActionResult(actionResult, bids, DeleteBidsActionReport))
      .catch(noop)
  }

  function resetBids(report) {
    return {
      label: isSingleBid(report) ? 'Reset Bid' : 'Reset Bids',
      icon: 'autorenew',
      isAvailable() {
        const resetDisabledStates = ['CREATED', 'RECEIVED', 'SENT'];
        return !report.bids.find(b => b.isCompetitive || resetDisabledStates.indexOf(b.state.status) > -1);
      },
      action() {
        const bids = report.bids, text = bids.length === 1? 'this Bid': `these ${bids.length} Bids`;
        return VueDialog.show(WarningDialog, {
          title: `Reset ${text}`,
          subTitle: `Resetting ${text} will place the bid back into SENT status on your Bid Manager and will allow the ${bids.length > 1? 'hotels': 'hotel'} to edit. The hotel will not lose any already filled in data but will lose any prior negotiation activity.`
        })
          .then(() => notificationUtils.onSave(() => mainAPI.resetBidsToSent(getBidsIds(bids))))
          .then(actionResult => bidManagerService.handleActionResult(actionResult, bids, ResetBidsActionReport))
          .catch(noop)
      }
    }
  }

  function sendReminder(report) {
    return {
      label: isSingleBid(report) ? 'Send Reminder' : 'Send Reminders',
      icon: 'autorenew',
      isAvailable: () => statusMatches(report.statuses, [SENT, RECEIVED]),
      action: () => sendAction(report.bids, SendReminderAction, SendReminderActionReport)
    }
  }

  function sendAction(bids, actionDialog, actionReportDialog){
    return VueDialog.show( actionDialog, { bidsIds: getBidsIds(bids) })
      .then( actionReport => bidManagerService.handleActionResult(actionReport, bids, actionReportDialog))
      .catch( noop );
  }
}
