<script>
  import get from 'lodash/get';

  export let
    id = 'emailAddress',
    label = 'Email Address',
    values = {},
    errors = {},
    required = null,
    maxLength = 255;

  let error, value = '';

  $: value = value || get(values, id, '');
  $: error = errors ? errors[id] : '';

</script>


<div class="Input">
  <input type="email"
         id="{id}"
         name="{id}"
         placeholder="{label}"
         bind:value
         autocomplete="off"
         required="{required}"
         maxlength="{maxLength}"
  />
  <label>{label}</label>
  <div class="Error">{error || ''}</div>
</div>
