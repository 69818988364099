<template>
  <rb-modal
    :class="$style.dialog"
    @cancel="cancel()"
    @hide="submit()"
  >
    <span slot="header">Send Reminder Email</span>
    <div slot="body">
      You have selected {{ bidsCountLabel }} to send reminder emails to. Are you sure you want to continue?
    </div>
    <div
      slot="footer"
      class="layout-row"
    >
      <div style="flex: 1;">&nbsp;</div>
      <div class="flex-hold">
        <button
          class="rbButton"
          @click="cancel()"
        >
          Cancel
        </button>
        <button
          class="rbButton mainButton"
          @click="submit()"
        >
          Send
        </button>
      </div>
    </div>
  </rb-modal>
</template>

<script>
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbModal from 'vRoot/_core/RbModal.vue'
  import {notificationUtils, mainAPI} from 'root/angular-injector-provider';

  export default {
    name: 'RbvHotelRfpBidManagerActionSendReminder',
    components: { RbModal },
    mixins: [ DialogMixin, RbEventsMixin ],
    props: { 'bidsIds': {
        required: true,
        type: Array
      } },
    computed: {
      bidsCountLabel() {
        const count = this.bidsIds.length,
          isSingle = count === 1
        return isSingle ? 'a Bid' : `${count} Bids`
      }
    },
    methods: {
      submit () {
        notificationUtils().onSave( () => mainAPI().sendReminders(this.bidsIds) )
          .then( report => this.hide(report), err => this.cancel(err) )
      }
    }
  }
</script>
<style lang="stylus" module="">
  .dialog {
    max-width: 450px;
    font-size: 12px;
    color: #263238;
  }
</style>
