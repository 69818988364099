import { find } from 'lodash'
import RbLog from "../../log/log";

export default messageSvc

messageSvc.$inject = ['DataServer'];
function messageSvc(DataServer){
  return {
    getMessageById (id, type) {
      return DataServer.getMessages().then((messages) => { return find(messages, {id: id, type: type}) }, RbLog.error)
    }
  }
}

