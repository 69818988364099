<template>
  <div
    v-if="loaded"
    :class="$style.directory"
  >
    <preview-bar
      :groupers="groupers"
      :filters="filters"
      :group-by="groupBy"
      :filter-by="filterBy"
      :travel-policy="directory.travelPolicy"
      @groupBy="groupBy = $event"
      @filterBy="filterBy = $event"
    >
      <button
        v-if="!previewMode && selectingHotels"
        class="button dark"
        :disabled="!canSave"
        @click="saveSelectedHotels"
      >
        <i class="material-icons">save</i>
        Save
      </button>
      <button
        v-if="!previewMode && selectingHotels"
        class="button dark"
        @click="cancelSelectingHotels"
      >
        Cancel
      </button>
    </preview-bar>

    <Hotels
      :view="activeView"
      :hotels="sortedAndGrouped"
      :group-by="groupBy || ''"
      :class="$style.background"
      :style="background"
      :preview-mode="previewMode || !selectingHotels"
      :updating-list="updatingList"
      :filtered="!!filterBy"
      @toggle="toggleExclude"
      @toggle-bulk="bulkToggleExclude"
    >
      <hotel-directory-header
        :class="$style.header"
        :header="activeView.header"
        :sub-header="activeView.subHeader"
        :warning="activeView.warning"
        :show-sub-header="activeView.showSubHeader"
        :show-warning="activeView.showWarning"
        :logo="'images/logos/' + account.logo"
        :edit="editAllText"
        :preview-mode="previewMode"
        @update="update"
      />
    </Hotels>

    <background-editor
      v-if="!previewMode"
      :open="editingBg"
      :full="expanded"
      :value="activeView.background"
      @close="toggleBg"
      @open="toggleBg"
      @input="updateBg"
    />
  </div>
</template>

<script>
import HotelDirectoryHeader from './HotelDirectoryHeader.vue';
import BackgroundEditor from './HotelDirectoryViewBackgroundEditor.vue';
import Hotels from './hotels-list-fs.vue';
import PreviewBar from './HotelDirectoryPreviewBar.vue';

import { Dialog } from 'root/v-app/rbServices';
import ManageContentDialog from 'vRoot/hotel-directory/dialogs/ManageContentDialog.vue';
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';
import outgoingMessageDialog from 'vRoot/hotel-directory/modules/manageUsers/upload-dialogs/outgoingMessageDialog.vue';
import DirectoryPreviewDialog from 'vRoot/hotel-directory/dialogs/tutorialDialog.vue';

import SaveUserDialog from 'vRoot/hotel-directory/dialogs/SaveUserDialog.vue';
import hotelDirectoryService from '../../hotel-directory-service';
import {isEqual, get, cloneDeep, isFunction} from 'lodash';
import moment from 'moment';
import { groupAndFilter, getFilters } from './preview.service';
import STATES from 'root/assets/data/states'
import freezeObjectKeys from 'vRoot/hotel-directory/modules/freeze';

function getStateLabel(address) {
  const state = address.state ? STATES.find(s => s.id === address.state) : undefined;
  if(!state) return undefined;

  return `${state.label}, ${address.countryName}`;
}

function getRegionOrCountryLabel(address) {
  const region = address.region || '';
  return region ? `${region} , ${address.countryName}` : address.countryName;
}

import {
  EventBus,
  SEND_PREVIEW_EVENT,
  EDIT_BACKGROUND_EVENT,
  EDIT_HEADER_EVENT,
  EDIT_WARNING_EVENT,
  EDIT_SUBHEADER_EVENT,
  SHOW_SUBHEADER_EVENT,
  SHOW_WARNING_EVENT,
  EDIT_VIEW_EVENT,
  MANAGE_VIEW_LINK,
  VIEW_UPDATED,
  SELECT_HOTELS
} from 'vRoot/hotel-directory/_core/EventBus';

export default {
  name: 'HotelDirectoryPreview',
  components: {
    HotelDirectoryHeader,
    BackgroundEditor,
    Hotels,
    PreviewBar
  },
  props: {
    expanded: {
      type: Boolean
    }
  },
  data() {
    return {
      activeView: null,
      editingBg: false,
      editAllText: '',
      previewMode: this.expanded,
      selectingHotels: false,
      editOptionsDropDownOpen: false,
      hotels: [],
      updatingList: [],
      groupers: [
        {
          id: null,
          text: 'None'
        },
        {
          id: 'td',
          text: 'Travel Destinations',
          key: 'travelDestination.name'
        },
        {
          id: 'city',
          text: 'Hotel City',
          key: 'company.address.city'
        },
        {
          id: 'state',
          text: 'State/Region',
          key: h => {
            const address = h.company.address || {};
            return getStateLabel(address) || getRegionOrCountryLabel(address);
          }
        },
        {
          id: 'country',
          text: 'Country',
          key: 'company.address.countryName'
        },
        {
          id: 'brand',
          text: 'Hotel Brand',
          key: 'company.chain.name'
        },
        {
          id: 'chain',
          text: 'Hotel Chain',
          key: 'company.chain.masterChainName'
        },
        {
          id: 'rating',
          text: 'Hotel Type',
          key: 'rating'
        },
        {
          id: 'distance',
          text: 'Distance',
          key: 'analytics.distanceMi'
        },
        {
          id: 'bestRate',
          text: 'Best Rate',
          key: this.bestRate
        }
      ],
      groupBy: 'td',
      filterBy: '',
      date: new Date().toDateString(),
      hotelTypes: [
        { id: 'E', name: 'Economy Hotels' },
        { id: 'M', name: 'Midscale Hotels' },
        { id: 'UM', name: 'Upper Midscale Hotels' },
        { id: 'U', name: 'Upscale Hotels' },
        { id: 'UU', name: 'Upper Upscale Hotels' },
        { id: 'L', name: 'Luxury Hotels' }
      ]
    };
  },
  asyncComputed: {
    account() {
      return hotelDirectoryService.getAccount().then(account => account);
    },
    directory() {
      return hotelDirectoryService.getDirectory().then(directory => directory);
    },
    user() {
      return hotelDirectoryService.getUserAccount().then(user => {
        this.$nextTick(() => this.showPreviewDialog(user));
        this.$nextTick(() => hotelDirectoryService.showOptInDialog());
        return user;
      });
    }
  },
  computed: {
    loaded() {
      return this.activeView && this.account;
    },
    background() {
      const style = {
        height: 'calc(100vh - 149px)'
      };

      if (this.activeView.background) {
        if (this.activeView.background.type === 'COLOR') {
          style['background-color'] = this.activeView.background.value;
        } else {
          style[
            'background-image'
          ] = `url(${this.activeView.background.value})`;
        }
      }

      return style;
    },
    canSave() {
      return !isEqual(hotelDirectoryService.getActiveView(), this.activeView);
    },
    grouper() {
      return this.groupers.find(group => group.id === this.groupBy);
    },
    filter() {
      const filter = this.filters.find(f => f.id === this.filterBy);
      return (
        filter || {
          id: null,
          text: 'None'
        }
      );
    },
    filters() {
      return getFilters(
        this.hotels,
        this.grouper,
        this.hotelTypes,
        this.bestRate
      );
    },
    sortedAndGrouped() {
      return groupAndFilter(
        this.viewableHotels,
        this.grouper,
        this.filter,
        Boolean(!!this.groupBy),
        this.bestRate,
        this.getGroupKey()
      );
    },
    viewableHotels(){
      return this.selectingHotels
        ? this.hotels
        : (this.activeView.excludedBids.length ? this.hotels.filter(({bidId}) => !this.activeView.excludedBids.includes(bidId)) : this.hotels);
    }
  },
  watch: {
    expanded(value) {
      this.previewMode = value;
    }
  },
  mounted() {
    const view = hotelDirectoryService.getActiveView();
    if (!view) {
      hotelDirectoryService.openView();
    } else {
      this.activeView = cloneDeep(view);
      this.loadHotels();
      this.setupEvents();
      this.togglePreview();
    }
  },
  beforeDestroy() {
    EventBus.$off(SEND_PREVIEW_EVENT);
    EventBus.$off(EDIT_SUBHEADER_EVENT);
    EventBus.$off(EDIT_HEADER_EVENT);
    EventBus.$off(EDIT_WARNING_EVENT);
    EventBus.$off(EDIT_BACKGROUND_EVENT);
    EventBus.$off(SELECT_HOTELS);
    EventBus.$off(SHOW_WARNING_EVENT);
    EventBus.$off(SHOW_SUBHEADER_EVENT);
    EventBus.$off(EDIT_VIEW_EVENT);
    EventBus.$off(MANAGE_VIEW_LINK);
    EventBus.$off(VIEW_UPDATED);
  },
  methods: {
    toggleExclude({bidId}) {
      this.bulkToggleExclude([bidId]);
    },
    updateUpdatingList(bidIds) {
      this.updatingList = this.diff(this.updatingList, bidIds);
    },
    bulkToggleExclude(ids) {
      this.update({excludedBids: this.diff(this.activeView.excludedBids, ids)}, false);
    },
    async saveSelectedHotels() {
      const ids = this.activeView.excludedBids;
      this.updateUpdatingList(ids);
      await this.save();
      this.updateUpdatingList(ids);
      this.selectingHotels = false;
    },
    cancelSelectingHotels() {
      this.selectingHotels = false;
      this.activeView = cloneDeep(hotelDirectoryService.getActiveView());
    },
    diff(sourceIds, newIds) {
      if(newIds.every(id => sourceIds.includes(id))) {
        return sourceIds.filter(id => !newIds.includes(id));
      } else if(newIds.some(id => sourceIds.includes(id))) {
        return sourceIds.filter(id => !newIds.includes(id));
      } else {
        return [...sourceIds, ...newIds];
      }
    },
    setupEvents() {
      EventBus.$on(SEND_PREVIEW_EVENT, this.sendPreview);
      EventBus.$on(EDIT_SUBHEADER_EVENT, () => this.editSection('subHeader'));
      EventBus.$on(EDIT_HEADER_EVENT, () => this.editSection('header'));
      EventBus.$on(EDIT_WARNING_EVENT, () => this.editSection('warning'));
      EventBus.$on(EDIT_BACKGROUND_EVENT, this.editBg);
      EventBus.$on(SELECT_HOTELS, this.selectHotels);
      EventBus.$on(SHOW_WARNING_EVENT, () =>
        this.update({ showWarning: !this.activeView.showWarning })
      );
      EventBus.$on(SHOW_SUBHEADER_EVENT, () =>
        this.update({ showSubHeader: !this.activeView.showSubHeader })
      );
      EventBus.$on(EDIT_VIEW_EVENT, this.editContent);
      EventBus.$on(MANAGE_VIEW_LINK, this.manageLink);
      EventBus.$on(VIEW_UPDATED, this.viewUpdated);
    },
    selectHotels() {
      this.selectingHotels = !this.selectingHotels;
    },
    save() {
      return (
        this.canSave &&
        hotelDirectoryService.updateView(this.activeView).then(this.loadHotels)
      );
    },
    getGroupKey() {
      const groupKey = this.grouper.key;
      switch (this.groupBy) {
        case 'distance':
          return hotel => `${Math.round(get(hotel, this.grouper.key, 0) * 100) / 100} Mi`;
        case 'bestRate':
          return hotel => this.bestRate(hotel);
        default:
          if(groupKey) {
            return isFunction(groupKey) ? groupKey : hotel => get(hotel, groupKey);
          } else {
            return () => undefined;
          }
      }

    },
    rates(hotel) {
      let season =
        hotel.seasons &&
        hotel.seasons.find(ss =>
          moment().isBetween(ss.start, ss.end, null, '[]')
        );
      season = season || (hotel.seasons && hotel.seasons[0]);
      return season && season.rates;
    },
    bestRate(hotel) {
      const rates = this.rates(hotel);
      const values =
        rates &&
        this.activeView.rates.map(rate => +rates[rate]).filter(value => value);
      const best = values && Math.min(...values);
      return best && `${parseFloat(+best).toFixed(2)} ${hotel.currency}`;
    },
    update(data, save = true) {
      this.activeView = {
        ...this.activeView,
        ...data
      };
      return save && this.save();
    },
    async editContent() {
    const rfps = await hotelDirectoryService.loadRfps();
      Dialog.show(ManageContentDialog, {
        view: this.activeView,
        canDelete: hotelDirectoryService.getViews().length > 1,
        rfps,
      }).then(data => {
        if (data.action === 'update') {
          this.update(data.view);
        } else {
          Dialog.show(WarningDialog, {
            title: `Delete ${this.activeView.name} View?`,
            subTitle:
              'Please be aware that deleting this view will eliminate all viewership for this directory and all related functionality.'
          }).then(() => {
            hotelDirectoryService.deleteView(this.activeView);
          });
        }
      });
    },
    togglePreview() {
      this.$emit('preview', this.previewMode);
    },
    toggleBg() {
      this.editingBg = !this.editingBg;
    },
    updateBg(background) {
      this.update({ background });
    },
    toggleEditOptions() {
      this.editOptionsDropDownOpen = !this.editOptionsDropDownOpen;
    },
    editSection(section) {
      if (
        (section === 'warning' && !this.activeView.showWarning) ||
        (section === 'subHeader' && !this.activeView.showSubHeader)
      ) {
        return;
      }
      this.editAllText = section;
      this.toggleEditOptions();
    },
    editBg() {
      if (!this.editingBg) {
        this.toggleBg();
      }
      this.toggleEditOptions();
    },
    loadHotels() {
      hotelDirectoryService.getViewHotels(this.activeView.id)
        .then(hotels => {
          this.hotels = hotels.map(freezeObjectKeys);
        });
    },
    async sendPreview() {
      const users = await hotelDirectoryService.loadViewUsers(this.activeView.id);
      const userAccount = await hotelDirectoryService.getUserAccount();
      const data = await Dialog.show(outgoingMessageDialog, {
        title: 'Send Preview',
        message: this.directory.defaultUserMessage,
        shouldSelectUser: true,
        users,
        userAccount
      });

      if(data.createUser) {
        this.createUser().then(this.sendPreview);
      } else {
        hotelDirectoryService.sendLinkToSelf(this.activeView.id, data);
        if(data.setMessageAsDefault) {
          this.directory.defaultUserMessage = data.message;
        }
      }
    },
    async createUser() {
      const views = await hotelDirectoryService.getViews();
      const data = await Dialog.show(SaveUserDialog, {views, onlySave: true, viewId: hotelDirectoryService.getActiveView().id});
      return hotelDirectoryService.addUsers([data.user], {saveOnly: true});
    },
    showPreviewDialog(user) {
      if (
        this.loaded &&
        !hotelDirectoryService.stillExploring() &&
        !hotelDirectoryService.userHasSeenPreviewDialog(user)
      ) {
        Dialog.show(DirectoryPreviewDialog, {
          user,
          title: 'Hotel Directory Preview',
          message:
            'You are currently in preview mode. This is the view your travelers see. In order to switch to edit mode (which only you have access to), close this dialog and click the arrow on the top, left sidebar.'
        }).then(({ dontShow }) => {
          hotelDirectoryService.addPreviewTutorial(dontShow);
        });
      }
    },
    manageLink() {
      hotelDirectoryService
        .getOrCreateLinkForCurrentUser(this.activeView.id)
        .then(({ data }) => hotelDirectoryService.manageUserLink(data, false));
    },
    viewUpdated() {
      const view = hotelDirectoryService.getActiveView();
      if (view) {
        this.activeView = view;
        this.loadHotels();
      }
    }
  }
};
</script>

<style lang="stylus" module>
.directory {
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.background {
  background-size: cover;
  min-height: calc(100vh - 149px);
  box-sizing: border-box;
}

.header {
  margin-top: 70px;
}

.content {
  min-width: calc(65vw - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.controls {
  display: flex;
  justify-content: flex-end;
}

.rightControls {
  display: flex;
  justify-content: flex-start;
}

.editOptions {
  margin: 0 5px;
}

.editOptionsBackDrop {
  background-color: transparent !important;
}

.editOptionsDropdown {
  background-color: #263238;
  padding: 5px 0;
  margin: 5px 0;
  border-top: 2px solid #16272b;
  color: #fff;
  list-style-type: none;
  max-height: 150px;
  overflow: auto;
}

.disabled {
  color: #b1bec6;
}

.editOptionsItem {
  display: flex;
  justify-content: flex-start;
  overflow: visible;
  padding: 5px 15px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background: #313c41;
  }
}

.editOptionsItem i {
  margin-right: 5px;
}

.exitPreview {
  position: fixed;
  right: 50px;
  top: 160px;
  z-index: 99;
}
</style>
