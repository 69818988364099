<script>
  export let item = {};
  let opened = false;
</script>

<div style="position:relative; z-index: 10000"
     on:mouseenter={() => opened = true}
     on:mouseleave={() => opened = false}>
  <button class="Item {item.active ? 'Active': ''}" data-icon-before="{item.icon}"></button>

  {#if opened}
    {#if item.submenu}
      <div class="ItemExtra">
        <button class="Item Opened MenuHeader {item.active ? 'Active': ''}"  data-icon-before="{item.icon}">{item.label}</button>

        <div class="Menu">
          {#each item.submenu as subItem}
            <button class="MenuItem {subItem.active ? 'Active': ''}" data-icon-before="{subItem.icon}"
                    on:click={()=>subItem.action()}>{subItem.label}</button>
          {/each}
        </div>
      </div>

    {:else}
      <div class="ItemExtra">
        <button class="Item Opened {item.active ? 'Active': ''}"  data-icon-before="{item.icon}"
                on:click={()=>item.action()}>{item.label}</button>
      </div>
    {/if}
  {/if}
</div>

<style lang="stylus">.Item {
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: 0;
  font-size: 13px;
  color: #b0bec5;
  opacity: 0.5;
  background: transparent;
  border-color: transparent;
  box-sizing: border-box;
  padding: 0 14px;
  margin: 0;
  border-left: 0;
  border-right: 0;
  white-space: nowrap;
  background: #313c42;
  border-color: #313c42;
}
.Item:before {
  margin-right: 15px;
}
.Item:after {
  margin-left: auto;
}
.Item:hover {
  opacity: 0.7;
}
.Item:focus {
  border-color: #b0bec5;
}
.Item.Active {
  background: #00b8ab;
  color: #fff;
  opacity: 1;
}
.Item.Active:hover {
  opacity: 1;
}
.Item:hover {
  opacity: 1;
}
.Item.Opened {
  width: 300px;
  opacity: 1;
  border-radius: 0 5px 5px 0;
}
.Item.Opened.MenuHeader {
  text-align: center;
  border-radius: 0 5px 0 0;
}
.ItemExtra {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
}
.Menu {
  width: calc(100% -48px);
  margin-left: 48px;
  background: #263238;
  border-radius: 0 0 5px 0;
}
.MenuItem {
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: 0;
  font-size: 13px;
  color: #b0bec5;
  opacity: 0.5;
  background: transparent;
  border-color: transparent;
  box-sizing: border-box;
  padding: 0 14px;
  margin: 0;
  border-left: 0;
  border-right: 0;
  white-space: nowrap;
  height: 36px;
  color: #72828a;
  opacity: 0.7;
}
.MenuItem:before {
  margin-right: 15px;
}
.MenuItem:after {
  margin-left: auto;
}
.MenuItem:hover {
  opacity: 0.7;
}
.MenuItem:focus {
  border-color: #b0bec5;
}
.MenuItem.Active {
  background: #00b8ab;
  color: #fff;
  opacity: 1;
}
.MenuItem.Active:hover {
  opacity: 1;
}
.MenuItem:before {
  margin-right: 10px;
  font-size: 20px;
}
.MenuItem:hover {
  opacity: 0.9;
}
.MenuItem.Active {
  background: #263238;
}
.MenuItem.Active:before {
  color: #00b8ab;
}
</style>