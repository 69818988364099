<script>
  export let label;
</script>

<div class="ListSeparator">
  <div class="ListSeparatorText">
    {label}
  </div>
</div>

<style lang="stylus">.ListSeparator {
  z-index: 1;
  position: sticky;
  top: 0px;
  background: #fff;
  padding: 10px 10px 0;
  display: flex;
  margin-top: 60px;
}
.ListSeparatorText {
  flex: 1 1 100%;
  color: #546e7a;
  border-bottom: 1px solid #546e7a;
  font-size: 18px;
  line-height: 2;
  filter: drop-shadow(1px 1px 3px rgba(84,110,122,0.6));
  text-align: center;
}
</style>