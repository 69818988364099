import {checkAndExecute} from 'root/api/helpers';

export default function HotelAPI (api) {
  Object.assign(api, {
    hotel: {
      get: hotelId => checkAndExecute(
        () => hotelId,
        () => api.server.get(`hotels/${hotelId}`)),

      update: (hotelId, formData) => checkAndExecute(
        () => hotelId && formData,
        () => api.server.post(`hotels-requests/update-hotel?hotelId=${hotelId}`, formData)),

      rebrand: (hotelId, formData) => checkAndExecute(
        () => hotelId && formData,
        () => api.server.post(`hotels-requests/rebrand-hotel?hotelId=${hotelId}`, formData)),

      remove: ids => checkAndExecute(
        () => ids && ids.length > 0,
        () => api.server.post(`hotels-requests/remove-hotels`, {ids})),

      add: formData => checkAndExecute(
        () => formData,
        () => api.server.post(`hotels-requests/add-hotel`, formData)),
    }
  })
}
