<template>
  <div class="rbInputContainer" :class="errorClasses">
    <label :for="forId" class="rbInputLabel" >
      <slot name="label" />
      <span v-if="$slots.hint" class="rbInputLabelHint"> <slot name="hint" /> </span>
    </label>

    <slot />

    <div class="rbInputError">
      <transition name="rbInputError">
        <div v-if="error"> {{ error }} </div>
      </transition>
    </div>
    <slot name="atEnd" />
  </div>
</template>

<script>
  export default {
    name: 'rb-input',

    props: [ 'error', 'forId' ],

    computed: {
      errorClasses () { return { 'rbInputInvalid': this.error } }
    }
  }
</script>

<style lang="stylus">

  .rbInputContainer {
    margin-top 10px
  }

  .rbInputLabel {
    display: block;
    font-size: 11px;
    margin: 0 0 3px 1px;
    font-weight: 400;
    color: #78909c;
    position: relative;
    transition: color .5s ease-out;
  }

  .rbInputLabelHint {
    position absolute
    right: 0
    bottom: 0
    font-size: 9px;
    transition: color .5s ease-out;
  }

  .rbInput {
    height: 36px;
    width: 100%;
    border: 1px solid #DCDEE0;
    color: #455A64;
    text-indent: 5px;
    box-sizing: border-box;
    transition: all .5s ease-out;
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
  }

  .rbInputInvalid {
    .rbInput {
      border-color: #F15A24
    }

    .rbInputLabelHint, .rbInputLabel {
      color: #F15A24
    }

    .rbInputError{
      background : #F15A24
      color: #fff;
    }
  }

  .rbInputError {
    height: 16px;
    color: #F15A24
    font-size: 10px;
    font-weight: 600;
    padding: 0 4px;
  }

  .rbInputError-enter, .rbInputError-leave-to {
    opacity 0
    transform: scale(.7);
  }

  .rbInputError-enter-active, .rbInputError-leave-active {
    transition all .3s ease-out
  }
</style>
