<template>
  <img
    :src="url"
    loading="lazy"
    @click="$emit('click', $event)"
  />
</template>

<script>
import defaultImage from 'images/hotel_placeholder.png';

export default {
  name: 'HotelImage',
  props: {
    image: {
      type: String,
      default: ''
    },
  },
  asyncComputed: {
    url: {
      get () {
        if(!this.image) return defaultImage;
        const url = this.image && this.image.startsWith('http') ? this.image : `images/entities/${this.image}`;

        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = url;
          img.onload = () => resolve(url);
          img.onerror = reject
        }).catch(() => {
          return defaultImage;
        })
      },
      default: defaultImage,
    },
  },
};
</script>
