<template>
  <input
    :id="id"
    :readonly="readonly"
    @focus="selectAll"
    @input="onInput"
    @blur="onBlur"
    :style="hasChanged ? 'background: rgba(0, 169, 157, 0.1); border: 1px solid rgba(0, 169, 157, 1);' : ''"
  />
</template>

<script>
  import Pikaday from 'pikaday'
  import Moment from 'moment'
  import store from 'root/rfp-hotel/main/rfp/rbs-rfp-details-navigation/revert-revise-response.store';

  export default {
    name: 'rb-questionnaire-form-table-question-date',

    props: {
      id: null,
      value: {
        "default": undefined
      },
      formatView: {
        "default": "MM/DD/YYYY"
      },
      formatData: {
        "default": "YYYY-MM-DD"
      },
      firstDay: {
        // first day of the week (0: Sunday, 1: Monday, etc)
        "default": 0
      },
      readonly: {
        "type": Boolean,
        "default": false,
      }
//      setDefaultDate make the defaultDate the initial selected value
//      firstDay first day of the week (0: Sunday, 1: Monday, etc)
//  minDate the minimum/earliest date that can be selected (this should be a native Date object - e.g. new Date() or moment().toDate())
//  maxDate the m
    },

    data () {
      return {
        pikaday: null,
        actualValue: undefined,
        hasChanged: false,
      }
    },

    watch: {
      value ( val ) {
        this.actualValue = val
        this.updatePikadayDate()
      }
    },

    mounted () {
      const vue = this
      this.pikaday = new Pikaday({
        field: this.$el,
        format: this.formatView,
        position: 'bottom left',
        onSelect () { vue.actualValue = this.toString(vue.formatData) },
        onOpen () { vue.readonly && this.hide() }
      })
      this.actualValue = this.value
      this.updatePikadayDate();

      this.unsubscribe = store.subscribe(this.changesListener);
    },

    destroyed () {
      this.pikaday && this.pikaday.destroy();
      if(this.unsubscribe) this.unsubscribe();
    },

    methods: {
      onBlur (e) {
        if(!this.readonly){
          this.$emit('blur', this.actualValue || e.target.value )
        }
      },
      onInput (e) {
        this.actualValue = e.target.value;
      },
      selectAll ( event ) { event.target.select() },
      updatePikadayDate(){
        if(this.pikaday){
          const m = Moment(this.actualValue, this.formatData)
          if(this.actualValue && m.isValid()){
            this.pikaday.setMoment(m)
          } else {
            this.pikaday.setDate(null)
          }
        }
      },
      changesListener ($store) {
        if($store) {
          this.hasChanged = $store.changes.includes(this.id);
        }
      }
    },
  }
</script>
