import EntityStore from 'root/public-auth/states/registration/sign-up/store/entitySearch';
import {writable} from 'svelte/store';

export default function TravelDestinationHotelSearchStore(){

  const esStore = new EntityStore('HOTEL'),
    store = writable({ initializing: true, searchString: '', selected: [] }, () => {
      const esUnsubscribe = esStore.subscribe($es => {
        store.update($s => ({
          ...$es,
          ...$s,
          results: $es.results[$es.query],
          selected: [],
        }));
      });

      return () => {
        esUnsubscribe();
      }
    });

  store.search = esStore.search;
  store.more = esStore.more;

  store.clearSelected = function(){
    store.update($s => ({
      ...$s,
      selected: []
    }));
  }

  store.toggleSelected = function(hotelId){
    store.update($s => ({
      ...$s,
      selected: $s.selected.indexOf(hotelId) === -1 ? [...$s.selected, hotelId] : $s.selected.filter(s => s !== hotelId)
    }));
  }

  store.removeSelected = function(hotelId){
    store.update($s => ({
      ...$s,
      selected: $s.selected.filter(s => s !== hotelId)
    }));
  }

  return store;
}
