import HTML from '../bid-response.html';
import get from 'lodash/get';
import noop from 'lodash/noop';
import saveResponseService from '../save-response.service'
import {ngDialog} from 'root/angular-injector-provider';
import {Dialog as VueDialog} from 'root/v-app/rbServices';
import SelectFinalAgreementRatesDialog from 'vRoot/rfp-hotel/final-agreement/SelectFinalAgreementRatesDialog.vue';
import {NAME as AskForRateLoadingInformationDialog} from 'root/rfp-hotel/main/bid/dialogs/ask-for-rate-loading-information/ask-for-rate-loading-information.ctrl';

const
  // states
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR';

export { HTML, BidResponsePreloadController as Controller }

BidResponsePreloadController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state', 'MainAPI'];
function BidResponsePreloadController(utils, rfpHotelBidRepository, $state, mainAPI) {
  const
    vm = this;

  vm.isLoading = () => vm.state === LOADING;
  vm.isLoaded = () => vm.state === LOADED;

  $onInit();

  function $onInit() {
    vm.state = LOADING;

    utils.onLoad(() => rfpHotelBidRepository.getBidResponseForManualEntry($state.params.bidId))
      .then(httpResponse => {
        const bid = httpResponse.data;
        vm.options = getOptions(bid);
        vm.bid = bid;
        vm.state = LOADED;
      })
      .catch(() => {
        vm.state = ERROR;
      });
  }

  function getOptions(bid) {
    return {
      header: {
        pretitle: get(bid, 'specifications.name', ''),
        title: 'Manual Entry',
        subtitle: get(bid, 'supplierCompany.name', ''),
      },

      buttons: {
        cancel: {
          label: 'Cancel',
          action: saveResponseService.toBidManager
        },

        draft: {
          label: 'Save as Draft',
          action: answers => saveResponseService.saveDraftResponse(
            () => utils.onSave(() => mainAPI.saveDraftResponse(bid.id, answers))
          )
        },

        submit: {
          label: 'Save as Final Agreement',
          action: response => {
            let finalAgreementSentToSupplier = null;

            return saveResponseService.saveResponse(response,
              r => shouldFinalAgreementBeSentToSupplier(bid)
                .then(fa => {
                  finalAgreementSentToSupplier = fa;
                  return sendResponseAndFinalAgreement(r, bid.id, bid, fa);
                }),
              {
                onSuccess: () => utils.showSuccessDialog({
                  dialogData: {
                    heading: `Finally Agreement ${finalAgreementSentToSupplier ? 'Sent' : 'Saved'}`,
                    title: `${finalAgreementSentToSupplier ? 'Sent' : 'Saved'} Successfully`,
                    message: `Your final agreement was ${ finalAgreementSentToSupplier ? 'sent' : 'saved'} successfully`
                  }})
                  .then(() => $state.go('hotelRfp.bid.finalAgreement.send', { bidId: bid.id }))
              })
          }
        },
      }
    };
  }

  function sendResponseAndFinalAgreement(
    { answers },
    bidId,
    bid,
    sendToSupplier = null
  ) {
    return utils.onSave(() => mainAPI.sendResponseAndFinalAgreement(answers, bidId,true, sendToSupplier), {
      customErrorHandlers: {
        '409'(errorResponse) {
          const message = errorResponse.data.message;
          switch (message) {
            case 'NO_ACCEPTED_RATES':
              return VueDialog
                .show(SelectFinalAgreementRatesDialog, { bidId})
                .then(() => sendResponseAndFinalAgreement({ answers }, bidId, bid, sendToSupplier), noop);

            case 'NO_RATE_LOADING_INFORMATION':
              return ngDialog()
                .show(AskForRateLoadingInformationDialog, {locals:{ EntityId: bid.buyerCompanyEntityId}})
                .then(() => sendResponseAndFinalAgreement({ answers }, bidId, bid, sendToSupplier), noop);

            default:
              return Promise.reject(errorResponse);
          }
        }
      }
    });
  }

  function shouldFinalAgreementBeSentToSupplier(bid = {}) {
    return ( bid.supplierContact && bid.supplierContact.company.type !== 'CHAIN'
      ? saveResponseService.askToSendToContact(bid.supplierContact)
      : Promise.reject() )
      .then( () => true, () => false);
  }
}
