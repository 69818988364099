
<script>
  import RbLoading from '../../_core/RbvLoading.vue';
  import rbEventsMixin from '../../_mixins/RbEventsMixin.vue';

  export default {
    name: "supplier-mixin",
    mixins: [rbEventsMixin],
    components: { RbLoading },
    props: {
      supplier: {
        type: Object,
        required: true,
        default: () =>({})
      },
      filters: {
        type: Object,
        required: false,
        default: ()=> ({})
      },
      sorter: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },
    computed: {
      formattedDistance () { return `${Number(this.supplier.distanceMi).toFixed(2)}mi`},
      isSelected() { return this.supplier.bidState !== 'NA' },
    },
    methods: {
      handleSupplierCheckboxClicked(supplier){
        if (supplier.bidState === 'ADDED') {
          this.removeFromBidManager(supplier)
        } else if (supplier.bidState === 'NA') {
          this.addToBidManager(supplier)
        }
      },
      removeFromBidManager(supplier) {
        this.$rbEmit({
          name: 'onRemoveFromBidManager',
          supplier
        });
      },
      addToBidManager(supplier) {
        this.$rbEmit({
          name: 'onAddToBidManager',
          supplier
        });
      }
    }
  }
</script>
