<template>
  <div class="hotel-type">
    <template v-if="rate">
      <div class="icons">
        <div
          v-for="(type, index) in hotelTypes"
          :key="type.id"
          class="point"
          :class="{active: position >= index}"
        />
      </div>
      <div class="name">
        {{ match.name }}<slot />
      </div>
    </template>
    <div
      v-else
      class="name"
    >
      Hotel Property<slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotelDirectoryRating',
  props: ['rate'],
  data() {
    return {
      hotelTypes: [
        { id: 'E', name: 'Economy Hotel' },
        { id: 'M', name: 'Midscale Hotel' },
        { id: 'UM', name: 'Upper Midscale Hotel' },
        { id: 'U', name: 'Upscale Hotel' },
        { id: 'UU', name: 'Upper Upscale Hotel' },
        { id: 'L', name: 'Luxury Hotel' },
      ],
    };
  },
  computed: {
    position() {
      return this.hotelTypes.indexOf(this.match);
    },
    match() {
      return this.hotelTypes.find(h => h.id === this.rate);
    },
  },
};
</script>

<style lang='stylus'>
  .hotel-type{
    display inline-flex;
    align-items center;
    text-decoration none;
    width: auto;

    .icons {
        text-overflow unset;
        height: 15px;
        display: flex;
    }

    .point{
      width: 5px;
      height: 15px;
      margin: 0px 1px;
      padding: 3px;
      box-sizing: border-box;
      border-radius: 2px;
      background #b1bec6;

      &:nth-child(6) {
        margin-right: 5px;
      }

      &.active{
        background #40b8a9;
      }
    }

    &.dark {
      .point{
        &.active{
          background #77909d;
        }
      }
    }

    .name{
      font-weight: lighter;
      padding: 0 0px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
