/**
 * Created by DejanK on 9/15/2016
 */
import Vue from 'vue';
import RbvLetter from 'vRoot/_core/RbLetter.vue';

export default {
  template: '<div></div>',
  bindings: {
    letter: '<',
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const
    vueData = new Vue({ data: { letter: '' } });

  let
    vueComponent = null;

  Object.assign(this, {

    $onInit () {
      vueComponent = new Vue({
        el: $element[0],
        render ( h ) {
          return h( RbvLetter, {
            props: { letter: vueData.letter },
            class: $element.attr('class').split(' ').filter( c => !c.startsWith('ng-')).join(' '),
          });
        },
      });
    },

    $onChanges ( changes ) {
      if(changes.letter.currentValue){
        Vue.set(vueData, 'letter', changes.letter.currentValue)
      }
    },

    $onDestroy () {
      vueData.$destroy();
      if(vueComponent) {
        vueComponent.$destroy();
      }
    }
  });
}
