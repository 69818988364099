import {
    $state,
    RfpHotelBidRepository,
    notificationUtils
} from 'root/angular-injector-provider';
import { HOTEL_RFP_BID_RESPONSE, CHAIN_PORTAL_STATE } from 'root/states';

export {
    handle,
}

function handle({token}) {
    if(!!$state().params.direct) {
        return notificationUtils().onSave(() => RfpHotelBidRepository().updateContacts(token))
            .then(({data: {targetId}}) => Promise.resolve($state().go(HOTEL_RFP_BID_RESPONSE, {bidId: targetId})))
    }else{
        return notificationUtils().onSave(() => RfpHotelBidRepository().updateContacts(token))
            .then(({data: {targetId}}) => Promise.resolve($state().go(CHAIN_PORTAL_STATE, {steps: [`RFP-${targetId}`], assignments: true})))
    }
}
