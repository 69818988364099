/**
 * Created by DejanK on 10/03/2017.
 */

export default RateLoadingInformationRepo;

RateLoadingInformationRepo.$inject = ['MainServer', '$q'];
function RateLoadingInformationRepo(mainServer, $q){
  const $repo = this;

  $repo.get = entityId => { return entityId
      ? mainServer.get(`entities/${entityId}/rate-loading-information`)
      : $q.reject({status: 404}) };

  $repo.put = (entityId, rateLoadingInformation) => { return entityId
      ? mainServer.put(`entities/${entityId}/rate-loading-information`, rateLoadingInformation)
      : $q.reject({status: 404}) };
}
