<template>
  <div class="preview-dialog">
    <div
      v-if="emails.length === 2"
      class="tabs"
    >
      <button
        class="tab"
        :class="{active: index === 0 }"
        @click="index = 0"
      >
        Before Due Date
      </button>
      <button
        class="tab"
        :class="{active: index === 1 }"
        @click="index = 1"
      >
        After Due Date
      </button>
    </div>
    <div
      class="col"
      v-html="emails[index]"
    />
    <i
      class="material-icons close"
      @click="$emit('hide')"
    > close </i>
  </div>
</template>

<script>
export default {
  props: {
    emails: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      index: 0
    }
  }
}
</script>

<style lang="stylus" scoped>
  .preview-dialog {
    max-height: 95vh;
    max-width: 95vw;
    position relative;
  }

  .close {
    position absolute;
    right: -10px;
    top: 32px;
    background: #262e33;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
  }

  .tabs {
    margin 5px 0;
  }

  .tab.active {
    background: #00B8AB;
    border: #00B8AB;
    color: #fff;
  }
</style>
