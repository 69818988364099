import {SvelteDialog} from 'root/v-app/rbServices';
import MissingSubscription from './MissingSubscription.svelte';
import RequestAccessDialog from './RequestAccessDialog.svelte';

export function showMissingSubscription(target, {subscription} = {}) {
  return SvelteDialog.showTooltip(target, MissingSubscription, {subscription}).then(showRequestAccessDialog);
}

export function showRequestAccessDialog() {
  SvelteDialog.show(RequestAccessDialog);
}
