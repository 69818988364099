import {
  $state,
  InvitationHandlersRepository,
  notificationUtils,
  currentUser,
} from 'root/angular-injector-provider';

import { BID_MANAGER } from 'root/states';

export default {
  handleAgentInvitation,
}

function getInvitationDetails(token) {
  return notificationUtils().onSave(() => InvitationHandlersRepository().getInvitationDetails(token));
}

function handleAgentInvitation({token}) {
  return getInvitationDetails(token)
    .then(({data: { invitation: {id, invitationMeta: {role}}}}) => handle(id, role).then(openRfpManager));
}

function handle(id, role) {
  return notificationUtils()
    .onSave(() => InvitationHandlersRepository().handleAgentInvitation({id, role}))
    .then(currentUser().refresh);
}

function openRfpManager(){
  return $state().go(BID_MANAGER, {viewId: null});
}
