/**
 * Created by DejanK on 6/29/2017.
 */
import './rb-negotiations-rate-history.scss';
import HTML from './rb-negotiations-rate-history.html';

export default {
  template: HTML,
  bindings: {
    negotiations: '<',
    negotiation: '<',
    rate: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', 'NegotiationsHtmlFactory'];
function Ctrl($element, NegotiationsHtmlFactory) {
  const vm = this;
  let tooltipster, htmlContentElement, parentElement, tableElement;

  this.$onInit = () => {
    parentElement = $element.parent();
    htmlContentElement = angular.element($element.children()[0]);
    tableElement = angular.element(htmlContentElement.find('div.table'));

    parentElement.tooltipster({
      theme: ['', 'negotiations-rate-history'],
      content: htmlContentElement,
      contentAsHtml: true,
      distance: 0,
      interactive: true,
      functionBefore: printHistory,
      functionReady: scrollToActive
    });

    tooltipster = parentElement.tooltipster('instance');
  };

  this.$onChanges = () => updateVM();

  this.$onDestroy = () => tooltipster.destroy();

  function updateVM () {
    if(checkRequirements()) {
      vm.seasonPeriod = vm.negotiations.seasons.getFormattedPeriod(vm.rate.season);
      vm.roomType = vm.negotiations.roomTypes.print(vm.rate.roomType);
    }
  }

  function printHistory(){
    if(checkRequirements()){
      const html = NegotiationsHtmlFactory.printRateHistory(vm.negotiations.communication, vm.rate.id, vm.negotiation);
      tableElement.empty();
      tableElement.append(html);
    }
  }

  function checkRequirements(){
    return vm.negotiations && vm.rate;
  }

  function scrollToActive(){
    const activeElements = tableElement.find('div.layout-row.active');
    if(activeElements.length) {
      scrollTo(tableElement, angular.element(activeElements[0]));
    } else {
      tableElement[0].scrollTop = tableElement[0].scrollHeight;
    }

    function scrollTo(container, scrollToElement){
      container.scrollTop(
        scrollToElement.offset().top - container.offset().top + container.scrollTop() - (container.height()/2)
      );
    }
  }
}
