import HTML from './view-questionnaire.html'
import {get, noop} from "lodash"

export { HTML, RfpQuestionnaireViewController as Controller }

RfpQuestionnaireViewController.$inject = ['NotificationUtils', 'RfpHotelRfpRepository', '$state']
function RfpQuestionnaireViewController(utils, RfpHotelRfpRepository, $state){
  const vm = this

  $onInit()

  function $onInit(){
    vm.rfpId = $state.params.rfpId
    utils.onLoad( () => RfpHotelRfpRepository.getRfpPreview(vm.rfpId), vm, 'rfp' )
      .then( rfp => {
        vm.isChainEnabled = get(rfp, 'data.specifications.chainSupport');
        vm.state = 'LOADED'
      })
      .catch(noop)
  }
}
