<Navigation id="{rfpId}" {title} {subtitle} {menu} before="{RfpSpecificationMenuItem}"/>

<script>
  import RfpSpecificationMenuItem from './HotelRfpRfpSpecificationMenuItem.svelte';
  import Navigation from 'root/components/organisms/SideNavigation/SideNavigation.svelte';
  import { createMenuItem, createCoverLetter, createFinalAgreement  } from 'root/components/organisms/SideNavigation/MenuItemFactory.js';
  import { $state as stateProvider } from 'root/angular-injector-provider.js';

  export let rfpId,
    chainSupport = false,
    attachments = {},
    type = '';

  const
    title = 'RFP Details',
    subtitle = 'Manage your RFP',
    PER_DIEM_SUBTYPE = 'PER_DIEM';

  const currentState = stateProvider().current.name;
  let menu;

  $: menu = [
    createCoverLetter({
      currentState,
      label: type === PER_DIEM_SUBTYPE ? 'GSA Per-Diem Letter & Form' : 'RateQuick Letter & Form',
      directLetter: { state: 'hotelRfp.rfp.coverLetter.view', attachments: attachments.direct },
      chainLetter: chainSupport ? { state: 'hotelRfp.rfp.namCoverLetter.view', attachments: attachments.chain } : null
    }),
    createMenuItem('Travel Destinations & Hotels', 'near_me', 'hotelRfp.rfp.destinations.view', currentState),
    createFinalAgreement(currentState, 'hotelRfp.rfp.finalAgreement.view', attachments.finalAgreement),
  ];
</script>