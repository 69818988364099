import { get, merge } from 'lodash'
import { NAME as CAPTCHA_DIALOG } from '../dialogs/captcha/captcha.ctrl';
import {SvelteDialog} from 'root/v-app/rbServices';
import RbAdminLoginDialog from '../dialogs/TheRbAdminLoginPassword.svelte';
import MfaLoginDialog from '../dialogs/multi-factor-authentication/TheMultiFactorAuthenticationLoginDialog.svelte';
import {PAGE_USER_BANNED} from 'root/states';
import {
  PENDING_EMAIL_ADDRESS_VERIFICATION,
  isEmailAddressVerificationPendingError,
  toEmailAddressVerification
} from 'root/public-auth/shared/signInEmailAddressVerificationPendingService';

export default SignInService
export const BAD_CREDENTIALS = 'BAD_CREDENTIALS';
export const UNKNOWN_USER = 'UNKNOWN_USER';

SignInService.$inject = ['NotificationUtils', 'PublicAuth', 'rbDialog', '$q', '$state'];
function SignInService(notificationUtils, publicAuth, dialog, $q, $state) {
  const vm = this;
  vm.signIn = signIn;

  function signIn(credentials) {
    return notificationUtils.onSave(() => { return publicAuth.signIn(credentials) }, {
      customErrorHandlers: {
        '400' (error) {
          if(get(error, 'data.message') === 'INVALID_CAPTCHA_TOKEN'){
            return dialog.show(CAPTCHA_DIALOG).then(
              (dialogResult) => { return signIn(merge({}, credentials, dialogResult)) },
              badCredentials)
          } else {
            notificationUtils.showError('500')
            return $q.reject(error)
          }
        },

        401 (error) {
          switch (error.data.message){
            case 'BANNED':
              $state.go(PAGE_USER_BANNED);
              return badCredentials();
            case PENDING_EMAIL_ADDRESS_VERIFICATION:
              toEmailAddressVerification(credentials, error);
              return badCredentials();
            default:
              return badCredentials();
          }
        },

        404 () {
          return $q.reject(UNKNOWN_USER);
        },

        409(error) {
          if(isEmailAddressVerificationPendingError(error)){
            toEmailAddressVerification(credentials, error);
            return $q.reject(error);
          } else if(error.data.message === 'INCOMPLETE_RB_ADMIN_CREDENTIALS') {
            return SvelteDialog.show(RbAdminLoginDialog, {credentials}, {zIndex: 10001})
              .catch(badCredentials);
          } else if (error.data.message === 'MULTI_FACTOR_AUTHENTICATION_REQUIRED') {
            return SvelteDialog.show(MfaLoginDialog, {credentials, options: error.data.details.options})
              .catch(badCredentials);
          } else {
            return badCredentials();
          }
        }
      }
    });

    function badCredentials() { return $q.reject(BAD_CREDENTIALS) }
  }
}
