<script>
  import {onDestroy} from 'svelte';
  import {maskEmailAddress} from 'utils/email-address';
  import {httpErrorHandler, authAPI} from 'root/angular-injector-provider';

  import CodeInput from './codeInput.svelte';
  export let code = '', error = '', checking = false, credentials = {};

  const RESEND_TIMER = 120; // 2 minutes

  let sent = false, maskedEmailAddress = '', timer = -1, intervalId;

  initialize();

  onDestroy(() => {
    if(intervalId) clearInterval(intervalId);
  });

  function initialize(){
    maskedEmailAddress = maskEmailAddress(credentials.email);
    intervalId = setInterval(() => {
      if(timer > 0) timer--;
    }, 1000);
  }

  function sendCode(){
    authAPI().userSecurity.sendEmailMfaEmail(credentials)
      .then(onEmailSent)
      .catch(httpErrorHandler().handle)
  }

  function onEmailSent(){
    sent = true;
    timer = RESEND_TIMER;
  }
</script>

{#if sent}
  <div class="Title">A verification code has been sent to <b>{maskedEmailAddress}</b>. Enter the code to continue and be redirected.</div>

  {#if timer === 0}
    <section>
      <button class="aBtn atLight asMain" on:click={sendCode}>Send Again</button>
    </section>
  {/if}

  <section>
    <CodeInput bind:code {checking} {error}/>
  </section>

{:else}
  <div class="Title">Send a verification code to <b>{maskedEmailAddress}</b>.</div>

  <section>
    <button class="aBtn atLight asMain" on:click={sendCode}>Send Code</button>
  </section>
{/if}

<style lang="stylus">section {
  margin-top: 20px;
}
</style>




