<template>
  <a :href="href" :class="styles.navItem">
    <i class="material-icons" :class="styles.navIcon">{{module.icon}}</i>
    <span :class="styles.navLabel">{{module.title}}</span>
  </a>
</template>

<script>
  import { $state } from "root/angular-injector-provider";
  import { PAGE_COMPANY_PROFILE } from "root/states";

  export default {
    name: "account-profile-navigation-item",
    props: {
      module: {
        required: true,
        type: Object,
        "default" () { return {} }
      },
      activeModule: {
        required: true,
        type: Object,
        "default" () { return {} }
      }
    },
    computed: {
      styles () {
        return this.isActive
          ? { navItem: this.$style.activeNavItem, navIcon: this.$style.activeNavIcon, navLabel: this.$style.activeNavLabel }
          : { navItem: this.$style.navItem, navIcon: this.$style.navIcon, navLabel: this.$style.navLabel }
      },
      href () { return $state().href(PAGE_COMPANY_PROFILE, { module: this.module.id }) },
      isActive () { return this.activeModule === this.module},
    }
  }
</script>

<style lang="stylus" module>

  .navItem {
    display: block;
    height: 40px;
    line-height: 40px;
    background: #FBFBFC;
    border: 1px solid #ECEFF1;
    border-left: 0;
    border-right: 0;
    text-decoration: none !important;
    padding: 0 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size : 12px;
    color: #546E7A !important;
  }

  .activeNavItem {
    composes navItem
    background: #00B8AB;
    border: 1px solid #00B8AB;
    color: white !important;
  }

  .navIcon {
    font-size: 16px;
    vertical-align: middle;
    padding: 0 5px;
    color: #546E7A;
  }

  .activeNavIcon {
    composes navIcon
    color: white;
  }

  .navLabel {
    vertical-align: middle;
    color: #546E7A;
  }

  .activeNavLabel {
    composes navLabel
    color: white;
  }

</style>
