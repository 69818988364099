/**
 * Created by DejanK on 12/17/2015
 *
 * 2016/03/23
 * Tooltip is removed from parent and added to body to be able to properly display fixed element (tooltip) inside
 * transformed container (md-sidemenu)
 */

'use strict';

let lodash = require("lodash");

module.exports = {
  controller: Ctrl
};

Ctrl.$inject = ['RbTooltipService', '$scope', '$element', '$attrs'];
function Ctrl(RbTooltipService, $scope, $element, $attributes){
  this.$onInit = ()=>{
    let parent = $element.parent();
    parent.on('mouseover', RbTooltipService.getShowFn($scope, $element, lodash.get($attributes, 'rbDirection'), lodash.get($attributes, 'rbParentLeft', false)));
    parent.on('mouseout', RbTooltipService.getHideFn($element));
  };
}
