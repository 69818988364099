import {checkAndExecute, checkAndExecuteToActionReport} from 'root/api/helpers';

export default function HotelRfpCompetitiveBidsAPI(api) {

  Object.assign(api, {

    hotelRfpCompetitiveBidPreviewWithToken: token => checkAndExecute(
      () => token,
      () => api.server.get(`rfps/hotel/competitive-bids/bids/preview/${token}`)
    ),

    hotelRfpCompetitiveBidPeekWithToken: token => checkAndExecute(
      () => token,
      () => api.server.get(`rfps/hotel/competitive-bids/bids/peek/${token}`)
    ),

    hotelRfpCompetitiveBidPreview: bidId => checkAndExecute(
      () => bidId,
      () => api.server.get(`rfps/hotel/competitive-bids/bids/${bidId}/preview`)
    ),

    hotelRfpCompetitiveBidDeclineWithToken: (token, message) => checkAndExecute(
      () => token,
      () => api.server.post(`rfps/hotel/competitive-bids/bids/decline-to-bid/token`, { token, message })
    ),

    hotelRfpCompetitiveBidDecline: (bidId, message) => checkAndExecuteToActionReport(
      () => bidId,
      () => api.server.post(`rfps/hotel/competitive-bids/bids/${bidId}/decline-to-bid`, { message })
    ),

    saveCompetitiveBidDraftResponse: (bidId, response) => checkAndExecuteToActionReport(
      () => bidId && response,
      () => api.server.post(`rfps/hotel/competitive-bids/bids/${bidId}/response/draft`, { response })
    ),

    setCompetitiveBidResponse: (bidId, response, ignoreErrors) => checkAndExecuteToActionReport(
      () => bidId && response,
      () => api.server.post(`rfps/hotel/competitive-bids/bids/${bidId}/response`, { response, ignoreErrors })
    ),

    setCompetitiveBidResponses: (bidsIds, ignoreErrors) => checkAndExecuteToActionReport(
      () => bidsIds,
      () => api.server.post(`rfps/hotel/competitive-bids/bids/responses`, { bidsIds, ignoreErrors })
    ),

    hotelRfpCompetitiveBidOfferAccepted: bids => checkAndExecuteToActionReport(
      () => bids,
      () => api.server.post(`rfps/hotel/competitive-bids/bids/offer/accept`, { bids })
    ),

    hotelRfpCompetitiveBidOfferRefused: bids => checkAndExecuteToActionReport(
      () => bids,
      () => api.server.post(`rfps/hotel/competitive-bids/bids/offer/decline`, { bids })
    ),

    hotelRfpCompetitiveBidAcknowledgeRefused: bids => checkAndExecuteToActionReport(
      () => bids,
      () => api.server.post(`rfps/hotel/competitive-bids/bids/offer/acknowledge-declined`, { bids })
    ),
  });
}
