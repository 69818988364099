<script>
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import SelectMethod from './selectMehodComponent.svelte';
  import Totp from './totpComponent.svelte';
  import Email from './emailComponent.svelte';

  import {httpErrorHandler, $state as state, PublicAuth} from 'root/angular-injector-provider';
  import noop from 'lodash/noop';
  import {PENDING_EMAIL_ADDRESS_VERIFICATION, toEmailAddressVerification}
    from 'root/public-auth/shared/signInEmailAddressVerificationPendingService';

  import {PAGE_USER_BANNED} from 'root/states';
  export let cancel, hide, credentials, options;

  let code = '', error = '', checking = false, pageStatus = 'LOADING';

  $: checkCode(code);

  initialize();

  function initialize(){
    pageStatus = options.length > 1 ? 'SELECT_METHOD' : options[0];
  }

  function checkCode(c){
    if(c){
      check(c)
        .then(hide)
        .catch(noop);
    } else {
      checking = false;
      error = '';
    }
  }

  function check(c) {
    if(!c) return Promise.reject();

    checking = true;
    return PublicAuth().signIn({...credentials, mfaType: pageStatus, mfaCode: c})
      .then(() => (checking && c === code) ? Promise.resolve() : Promise.reject())
      .catch(httpError => {
        if (checking && c === code) {
          checking = false;
          if (`${httpError.status}` === '401') {
            handleUnauthorized(httpError);
          } else {
            httpErrorHandler().handle(httpError);
          }
        }

        return Promise.reject(httpError);
      });

    function handleUnauthorized(httpError){
      switch (httpError.data.message){
        case 'BANNED':
          state().go(PAGE_USER_BANNED);
          return;

        case PENDING_EMAIL_ADDRESS_VERIFICATION:
          toEmailAddressVerification(credentials, error);
          return;

        default:
          checking = false;
          error = 'Invalid Code. Please try again.'
      }
    }
  }
</script>


<ClosableModal width="600px" close="{cancel}">
  <div slot="heading">
    <ModalTitle title="Verify Your Identity">
      Multi-Factor Authentication Required
    </ModalTitle>
  </div>

  <div class="body">
    {#if pageStatus === 'SELECT_METHOD'}
      <SelectMethod bind:selected={pageStatus}/>
    {:else if pageStatus === 'TOTP'}
      <Totp bind:code {checking} {error}/>
    {:else if pageStatus === 'EMAIL'}
      <Email bind:code {checking} {error} {credentials} />
    {/if}
  </div>
</ClosableModal>

<style lang="stylus">.body {
  padding: 0 15px;
  font-size: 13px;
  line-height: 16px;
  color: #78909c;
  margin-top: -20px;
  margin-bottom: 20px;
}
.body :global(.Title) {
  font-size: 13px;
  font-weight: bold;
  margin: 10px 0 10px;
  color: #37474f;
}
</style>
