import { checkAndExecute, checkAndExecuteToActionReport } from 'root/api/helpers';

export default function HotelRfpBidAPI(api) {
  Object.assign(api, {

    hotelRfpNegotiationGet: ( bidId ) => checkAndExecute(
      () => bidId,
      () => api.server.get(`rfps/bids/${bidId}/negotiations`)
    ),

    hotelRfpNegotiationCreate: ( bidId, negotiation ) => checkAndExecuteToActionReport(
      () => bidId && negotiation,
      () => api.server.post(`rfps/bids/${bidId}/negotiations`, negotiation )
    ),

    hotelRfpNegotiationUpdate: ( bidId, negotiationId, negotiation ) => checkAndExecuteToActionReport(
      () => bidId && negotiationId && negotiation,
      () => api.server.put(`rfps/bids/${bidId}/negotiations/${negotiationId}`, negotiation )
    ),

    hotelRfpNegotiationUpdateLast: ( bidId, negotiation ) => checkAndExecuteToActionReport(
      () => bidId && negotiation,
      () => api.server.post(`rfps/bids/${bidId}/negotiations/update-last`, negotiation )
    ),

    hotelRfpNegotiationCreateAndFinalize: ( bidId, negotiation ) => checkAndExecuteToActionReport(
      () => bidId && negotiation,
      () => api.server.post(`rfps/bids/${bidId}/negotiations/finalize`, negotiation )
    ),

    hotelRfpNegotiationUpdateAndFinalize: ( bidId, negotiationId, negotiation ) => checkAndExecuteToActionReport(
      () => bidId && negotiationId &&negotiation,
      () => api.server.put(`rfps/bids/${bidId}/negotiations/${negotiationId}/finalize`, negotiation )
    )

  });
}
