/**
 * Created by DejanK on 3/22/2017.
 */
import HTML from './captcha.html'

const NAME = 'signInCaptcha'

export { NAME, HTML, CaptchaDialogController as Controller }

CaptchaDialogController.$inject = ['rbDialog', '$scope'];
function CaptchaDialogController(dialog, $scope){
  const vm = this;

  vm.cancel = dialog.cancel;

  init();

  function init(){
    $scope.$watch(()=>{return vm.captcha}, ()=>{ vm.captcha && dialog.hide({captcha: vm.captcha}) })
  }
}
