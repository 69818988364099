<template>
  <div>
    <logo-with-image-editor v-if="isHotel" :is-admin="isAdmin" />
    <logo-editor v-else :is-admin="isAdmin"/>
  </div>
</template>

<script>
  import LogoWithImageEditor from './LogoWithImageEditor.vue';
  import LogoEditor from './LogoEditor.vue';
  import accountProfileService from "root/v-components/account-profile/accountProfileService";

  export default {
    name: 'rbv-account-image-editors',
    props: {
      isAdmin: {
        type: Boolean,
        default: false,
      },
    },
    components: { LogoWithImageEditor, LogoEditor },
    asyncComputed: {
      isHotel: {
        lazy: true,
        get() {
          return accountProfileService.getAccount()
            .then( a => a.type === 'HOTEL' )
        }
      }
    },
  }
</script>

<style lang="stylus" module></style>
