<template>

  <div class="rbvQuestionnaireQuestion" :class="questionClasses">
    <label :for="updatedTemplate.id" class="rbvQuestionnaireQuestionLabel" :class="labelClasses">
      <span class="rbvQuestionnaireQuestionLabelOrd" :class="classes">{{updatedTemplate.ord}}.</span>
      <span>{{updatedTemplate.name}}</span>
    </label>

    <question-date v-if="updatedTemplate.type === 'DATE'"
                   class="rbvQuestionnaireQuestionInput"

                   :id="updatedTemplate.id"
                   :name="updatedTemplate.id"
                   :placeholder="updatedTemplate.placeholder"

                   :value="vModel"
                   :readonly="!!updatedTemplate.readonly"
                   v-validate="validations"

                   @blur="onChange" />

    <question-list v-else-if="updatedTemplate.type === 'LIST'"

                   :id="updatedTemplate.id"
                   :name="updatedTemplate.id"
                   :template="updatedTemplate"
                   :isUserDefined="isUserDefined"

                   :value="vModel"
                   :readonly="!!updatedTemplate.readonly"
                   v-validate="validations"

                   @input="onChange" />

    <question-text v-else=""
                   class="rbvQuestionnaireQuestionInput"

                   :id="updatedTemplate.id"
                   :name="updatedTemplate.id"
                   :placeholder="updatedTemplate.placeholder"

                   :value="vModel"
                   :readonly="!!updatedTemplate.readonly"
                   v-validate="validations"

                   @input="onChange" />

    <tooltip
      :ord="updatedTemplate.ord"
      :title="updatedTemplate.name"
      :description="updatedTemplate.description"
      :required="!!isRequired"
      :error="showErrors && errors.first(updatedTemplate.id)"
    />

  </div>
</template>

<script>
  import QuestionnaireQuestionValidationMixin from '../mixins/QuestionnaireQuestionValidationMixin.vue'

  import QuestionDate from './QuestionnaireFormQuestionDate.vue'
  import QuestionList from './QuestionnaireFormQuestionList.vue'
  import QuestionText from './QuestionnaireFormQuestionText.vue'
  import Tooltip from '../tooltips/QuestionnaireTooltip.vue'

  export default {
    name: 'rb-questionnaire-form-question',

    mixins: [ QuestionnaireQuestionValidationMixin ],

    components: {
      QuestionDate,
      QuestionList,
      QuestionText,
      Tooltip
    },

    props: [ 'globals', 'model', 'response', 'template' ],

    computed: {
      updatedTemplate () {
        return this.template.type === 'USER_DEFINED' ? this.model : this.template
      },
      isList () {
        return this.updatedTemplate.type === 'LIST'
      },
      questionClasses () {
        const classes = this.classes
        classes.rbvQuestionnaireQuestionListType = this.isList
        return classes
      },
      labelClasses () {
        return {
          rbvQuestionnaireQuestionListTypeLabel: this.isList
        }
      },
      isUserDefined() {
        const ord = this.template.ord;
        return (ord >= 244 && ord <= 273) || (ord >= 668 && ord <= 697);
      }
    }

  }
</script>

<style lang="stylus">

  .rbvQuestionnaireQuestion, .rbvQuestionnaireQuestionListType {
    flex: 17 1 17%;
    padding: 3px 3px 2px;

    font-size: 10px;
    font-weight: 400;
    color: #78909C;

    min-width: 0;
    min-height: 43px;

    border: 1px solid #eceff1;
    margin: 2px;

    &.question-size-1 {
      flex: 10 1 10%;
    }

    &.question-size-2 {
      flex: 17 1 17%;
    }

    &.question-size-3 {
      flex: 25 1 25%;
    }

    &.question-size-4 {
      flex: 33 1 33%;
    }

    &.required {
      background-color: #fefcd3 !important;
    }
  }


  .rbvQuestionnaireQuestionLabel, .rbvQuestionnaireQuestionListTypeLabel {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 12px;
    margin: 2px 0;
  }

  .rbvQuestionnaireQuestionLabelOrd {
    padding: 0 2px 0 3px;
    border-radius: 3px;

    &.error {
      background-color #f15a24 !important
      color white
    }
  }

  .rbvQuestionnaireQuestionInput {
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #DCDEE0;
    border-radius: 2px;
    width: 100%;
    height: 17px;
    padding: 0 3px;
    font-size: 11px;
    line-height: 15px;
    background: white;
    color: #546E7A;
    transition: all .1s linear;

    &:focus {
      border-color: #78909C;
    }
  }

  .rbvQuestionnaireQuestionListType {
    flex: 1 1 auto;
  }

  .rbvQuestionnaireQuestionListTypeLabel {
    max-width: 200px;
  }

</style>
