<template>
  <rb-snack-bar
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <div slot="body">
      <template v-if="hotels.length == 1">
        <b>{{ hotels[0].company.name }}</b> was just {{ action=='add'? 'added to': 'removed from' }} your hotel directory.
      </template>
      <template v-else>
        <b>{{ hotels.length }} hotels</b> were just {{ action=='add'? 'added to': 'removed from' }} your hotel directory
      </template>
    </div>
    <div
      slot="actions"
      class="snackbar-buttons"
    >
      <button
        class="snackbar-button"
        type="button"
        @click="undo"
      >
        UNDO
      </button>
    </div>
  </rb-snack-bar>
</template>

<script>

  import RbSnackBar from 'vRoot/_core/RbSnackBar.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'

  export default {
    name: 'HotelsToggledNotifications',
    components: { RbSnackBar },
    mixins: [ RbFormMixin, DialogMixin ],
    props: ['hotels', 'action'],
    data() {
      return {
        returnAction: null
      }
    },
    methods: {
      onSubmit(){
        return {action: this.returnAction};
      },
      undo() {
        this.returnAction = 'undo';
        this.$nextTick(() => this.$formSubmitHandler());
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 400px;
    overflow visible
    color #78909c;
    border-radius 1px solid;
  }

  .subHeader{
    font-size 25px;
    line-height 14pt;
    font-weight lighter;
  }

  .body{
    font-size 12px;
  }

  .header{
    display flex;
    font-weight lighter;
  }

  .warning{
    height: 50px;
    width: 50px;
    font-size: 50px;
    color: rgb(0, 185, 170);
    margin-right 10px;
  }

  .texts{
    padding 5px;
    font-size: 18px;
    color: #78909c;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .left{
    float left;
  }

  .hotelsList{
    padding: 5px 0;
    border: 1px solid;
    margin: 5px 0;
    max-height: 190px;
    overflow: auto;
  }

  .hotelsList .hotel{
    padding 5px;
  }

  .hotelsList .hotel:not(:last-child){
    border-bottom 1px solid;
  }
</style>
