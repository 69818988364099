/**
 * Created by DejanK on 9/1/2017.
 */
import HTML from './ask-to-sign-in.html'
import {BAD_CREDENTIALS, UNKNOWN_USER} from '../../shared/sign-in.service';

const NAME = 'askToSignIn'

export { NAME, HTML, AskToSignInDialogController as Controller}

AskToSignInDialogController.$inject = ['rbDialog', 'Invitation', 'SignInService'];
function AskToSignInDialogController(dialog, invitation, SignInService){
  const vm = this;

  vm.submit = submit
  vm.cancel = dialog.cancel
  vm.notYou = notYou;

  $onInit();

  function $onInit(){
    vm.credentials = {
      email: invitation.emailAddress,
      password: ''
    }
  }

  function submit() {
    SignInService.signIn(vm.credentials)
      .then( dialog.hide, (error) => {
        switch (error){
          case BAD_CREDENTIALS:
            vm.authError = 'Your email or password is incorrect. Please try again.';
            break;
          case UNKNOWN_USER:
            vm.authError = `User <${vm.credentials.email}> does not exist!`;
            break;
          default:
            vm.authError = ''
        }
      })
  }

  function notYou() {
    dialog.hide({notYou: true});
  }
}
