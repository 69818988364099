<template>
  <div :class="$style.container">
    <slot
      v-if="$slots.prefix"
      name="prefix"
    />
    <label
      :style="counter"
      :for="name"
      :class="{[$style.counter]: true, [$style.maxed]: data.length >= max}"
    >{{ data.length }}/{{ max }}</label>
    <textarea
      :id="name"
      ref="editor"
      v-model="data"
      :placeholder="placeholder"
      :maxlength="max"
      :class="styles"
      @input="autoexpand"
    />
    <i
      class="material-icons"
      :class="$style.clear"
      @click="clear"
    >delete</i>
    <i
      class="material-icons"
      :class="{[$style.save]: true, [$style.active]: value !== data}"
      @click="save"
    >check_circle</i>
  </div>
</template>


<script>
export default {
    name: 'HotelDirectoryHeaderEditor',
    props: ['value', 'classes', 'max', 'name', 'placeholder'],
    data(){
        return {
            data: this.value
        }
    },
    computed: {
        styles(){
            return {
                [this.classes]: this.classes,
                [this.$style.editor]: true
            }
        },
        counter(){
            return this.$slots.prefix ? {'left': '45px'} : {};
        }
    },
    mounted(){
        this.$refs.editor.focus();
        this.$refs.editor.select();
        this.autoexpand({target: this.$refs.editor});
    },
    methods: {
        save(){
            this.$emit('save', this.data);
        },
        clear(){
            this.$emit('clear')
        },
        autoexpand (e) {
            const field = e.target;
            // Reset field height
            field.style.height = 'inherit';

            // Get the computed styles for the element
            const computed = window.getComputedStyle(field);

            // Calculate the height
            const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                        + parseInt(computed.getPropertyValue('padding-top'), 10)
                        + field.scrollHeight
                        + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                        + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

            field.style.height = `${height  }px`;

        }
    }
}
</script>


<style lang="stylus" module>
    .full{
        flex 1;
    }

    .container{
        composes full;
        position relative;
        background-color #313c41;
        display flex;
        align-items center;
        padding 0 5px;
    }

    .editor{
        background-color transparent;
        border: 0;
        resize: none;
        min-height: 100%;
        max-height 200px;
        width: 100%;
        color: #fff;
        font-size: 13px;
        padding: 10px 0 0 60px;
        box-sizing: border-box;
    }



    .counter{
        position: absolute;
        top: 50%;
        color: #2db8aa;
        transform: translateY(-50%);
        background: #263238;
        padding: 2px;
        font-size: 11px;
        border-radius: 10px;
        left 10px;
    }

    .maxed{
        color #d75539 !important;
    }

    .save, .clear{
        cursor pointer;
        color #90a2ae;
    }

    .active{
        color #fff;
    }
</style>
