<script>
  import {tick, onMount} from 'svelte';
  export let component;
  export let target;
  export let props;
  export let hide, cancel;

  let root;
  let style = {opacity: 0};

  onMount(async () => {
    await tick();
    setTimeout(position, 100);

    window.addEventListener('resize', position);

    return () => window.removeEventListener('resize', position);
  });

  function close(action) {
    return (...e) => {
      style = {...style, opacity: 0};
      action(...e);
    }
  }

  function position() {
    const
      targetRect = target.getBoundingClientRect(),
      xOrient = (window.innerWidth/2 - targetRect.x) > 0 ? 1 : -1,
      yOrient = (window.innerHeight/2 - targetRect.y) > 0 ? 1 : -1;

    style = {};
    if(xOrient < 0) {
      style.right = `${window.innerWidth - targetRect.left - 25}px`
    } else {
      style.left = `${targetRect.left - 25}px`
    }

    if(yOrient < 0) {
      style.bottom = `${window.innerHeight - targetRect.top}px`
    } else {
      style.top = `${targetRect.bottom}px`
    }

    setTimeout(() => {
      style = {...style, opacity: 1};
    }, 100);
  }
</script>

<div bind:this={root}
     class="Root"
     class:Above={style.bottom}
     class:Left={style.right}
     style={Object.keys(style).reduce((acc, key) => (`${acc}${key}:${style[key]};`), '')}
>
  <svelte:component this={component} {...props} hide={close(hide)} cancel={close(cancel)} />
</div>

<style lang="stylus">.Root {
  border-radius: 5px;
  background: #fff;
  position: fixed;
  opacity: 0;
}
.Root:after {
  content: '';
  height: 10px;
  width: 10px;
  box-sizing: border-box;
  border-bottom: solid 7px #fff;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  position: absolute;
  left: 20px;
  top: -10px;
}
.Root.Above:after {
  border-bottom: none;
  border-top: solid 7px #fff;
  top: unset;
  bottom: -10px;
}
.Root.Left:after {
  left: unset;
  right: 20px;
}
</style>
