import get from 'lodash/get';
import HTML from './public-bid-preview.html';

export { HTML, PublicBidPreviewController as Controller };

PublicBidPreviewController.$inject = ['NotificationUtils', 'MainAPI', 'InvitationService', '$state', '$q'];
function PublicBidPreviewController(notificationUtils, mainAPI, Invitation, $state, $q){
  const vm = this;

  vm.token = $state.params.token;
  vm.direct = $state.params.direct;
  if(!vm.token) showInvalidInvitationError();

  vm.loadBidFunction = () => mainAPI.hotelRfpCompetitiveBidPreviewWithToken(vm.token);
  vm.loadBidOptions = {
    customErrorHandlers: {
      '403': error => {
        showInvalidInvitationError();
        return $q.reject(error);
      }
    }
  };
  vm.recipientEmailAddressFn = bid => get(bid, 'supplier.contact.emailAddress');


  function showInvalidInvitationError() {
    notificationUtils.showError('invalid-invitation');
  }
}
