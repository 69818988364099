/* eslint-disable no-console */
import {DEBUG} from 'config/app.config';

export { RbLog as default, logError }

function logFatal(msg) { DEBUG && console.error('FATAL: ', msg) }
function logError(msg) { DEBUG && console.error('Error: ', msg) }
function logWarning(msg) { DEBUG && console.trace('WARNING: ', msg) }
function logMessage(msg) { DEBUG && console.log('Info: ', msg) }

function RbLog() {}

RbLog.fatal = logFatal;
RbLog.error = logError;
RbLog.warning = logWarning;
RbLog.info = logMessage;
