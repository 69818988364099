<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'rbv-hotel-rfp-bid-manager-send-not-interested-action-report',
    mixins: [ ActionReportMixin ],
    data () {
      return {
        messages: {
          'OK': '"Not Interested" Sent',
          'NOT_FOUND': `Bid was not found - "Not Interested" not sent`,
          'STATUS_CHANGED': 'Bid status was changed - "Not Interested" not sent',
          'ERROR': '"Not Interested" not sent due to unknown error'
        }
      }
    },
    computed: {
      title () { return this.items.length === 1 ? '"Not Interested" not sent!' : 'Failed to send some "Not Interested" responses!' },
      message () {
        return this.items.length === 1
          ? 'We were not able to send "Not Interested" because of the reason listed below:'
          : `${this.items.length - this.failures} of ${this.items.length} "Not Interested" were successfully sent.<br /> We were not able to send other because of the reasons listed below:`
      }
    }
  }
</script>
