import BUTTON_TYPE from './button-type';

const buttonConfiguration = {
  YES_NO: {
    type: BUTTON_TYPE.CONFIRMATION,
    cancel: 'No',
    confirm: 'Yes',
  },
};

Object.freeze(buttonConfiguration);

export default buttonConfiguration;
