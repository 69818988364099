import {
    $state,
    notificationUtils,
    RfpDestinationsViewService
} from 'root/angular-injector-provider';
import { BID_MANAGER } from 'root/states';

function getRfpId() {
    return $state().params.rfpId;
}

function bidManager(rfp) {
    $state().go(BID_MANAGER, {viewId: null, rfp});
}

function getTravelDestinations(rfpId) {
    return notificationUtils().onLoad(() => RfpDestinationsViewService().getRfpTravelDestinations(rfpId)).then(({data}) => data);
}

export {
    getRfpId,
    bidManager,
    getTravelDestinations
}
