import {checkAndExecute} from 'root/api/helpers';

export default function HotelRfpCompetitiveBidsAPI(api) {
  Object.assign(api, {

    loadCompetitiveBidsProfiles: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/hotel/${rfpId}/competitive-bids`)),

    saveCompetitiveBidsProfile: (rfpId, destinationId, profile) => checkAndExecute(
      () => rfpId && destinationId && profile,
      () => api.server.put(`rfps/hotel/${rfpId}/competitive-bids/${destinationId}`, profile)),

  })
}
