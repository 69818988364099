<script>
  import infiniteScroll from './infiniteScroll';
  import ListSeparator from './ListSeparator.svelte';
  import LoadingMore from './LoadingMore.svelte';

  export let bids, pagination;

  let p;

  $: p = {...pagination, threshold: 1000};
</script>

<div class="ListContainer" use:infiniteScroll="{p}">
  {#each bids as group}
    <ListSeparator label="{group.date}" />

    {#each group.bids as bid}
      <div class="ListItem">
        <div class="StatusDate">on {bid.stateTime}</div>

        <div class="{bid.stateColor}" class:ListItemHeader={1}>
          <div class="ItemTitle">{bid.stateLabel}</div>
        </div>

        <div class="ListItemDetails">
          <div class="{bid.stateColor}" class:ListItemIcon={1}
               data-icon-before="{bid.stateIcon}">
          </div>

          <div class="TextArea">
            <div class="StatusArea">
              <div class="ItemSubtitle noWrap">from <strong>{bid.rfp.specifications.name}</strong></div>
              <div class="ItemSubtitle">for <strong>{bid.supplier.company.name}</strong></div>
              <div class="ItemSubtitle">sabre code <strong>{bid.sabreCode}</strong></div>
            </div>

            <div class="ActionsArea">
              {#each bid.actions as action}
                <div>
                  <button class="aBtn asSmall asIcon"
                          data-icon="{action.icon}"
                          on:click={action.action} />
                  <div class="rbTooltip" data-tooltip="placement:left">
                    {action.label}
                  </div>
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    {/each}
  {/each}
  <LoadingMore isLoading="{p.loadingMore}" />
</div>


<style lang="stylus">.ListContainer {
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  row-gap: 25px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.ListContainer :global(.ListSeparator),
.ListContainer :global(.LoadingMore) {
  grid-column: span 2;
}
:global(.ListContainer > div:first-of-type) {
  margin-top: 0 !important;
}
.ListItem {
  position: relative;
  margin: 10px;
  display: flex;
}
.ListItemHeader {
  clip-path: polygon(0 0, 75% 0%, 80% 100%, 0% 100%);
  position: absolute;
  height: 40px;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.ListItemDetails {
  margin-top: 20px;
  display: flex;
  flex: 1 1 100%;
  border-radius: 0 0 15% 15%;
  box-shadow: 0 8px 4px -3px rgba(0,0,0,0.3);
}
.TextArea {
  background: #eaebed;
  color: #a7a8ab;
  padding-top: 20px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
}
.StatusArea {
  font-size: 13px;
  padding: 10px;
  flex: 1 1 100%;
}
.ActionsArea {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px 5px;
}
.ItemTitle {
  line-height: 30px;
  padding-left: 20px;
  font-size: 24px;
  color: #fff;
}
.StatusDate {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  line-height: 20px;
  color: #b0bec5;
  font-size: 13px;
}
.ListItemIcon {
  flex: 0 0 auto;
  align-items: flex-start;
  padding-top: 20px;
}
.ListItemIcon:before {
  height: 90px;
  font-size: 64px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.ItemSubtitle {
  font-size: 14px;
  color: #546e7a;
  max-width: 300px;
}
.aBtn {
  border: 0;
  background: transparent;
  margin-bottom: 2px;
}
.aBtn:hover {
  color: #00a99d;
}
</style>
