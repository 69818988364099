/**
 * Created by DejanK 2018-01-08
 */
import HTML from './show-results.html'
import './show-results.scss'
import { noop, orderBy } from 'lodash'
import { NAME as EditUploadedDestinationDialog } from 'root/rfp-hotel/main/rfp/destinations/dialogs/upload-destination/edit-destination/edit-uploaded-destination.ctrl'
import { NAME as DeleteUploadedDestinationDialog } from 'root/rfp-hotel/main/rfp/destinations/dialogs/upload-destination/delete-destination/delete-uploaded-destination.ctrl'

const NAME = 'uploadTravelDestinationShowResults'

export { NAME, HTML, uploadTravelDestinationShowResultsController as Controller }

uploadTravelDestinationShowResultsController.$inject = ['rbDialog', 'NotificationUtils', 'RfpHotelRfpDestinationsRepository', '$stateParams' ]
function uploadTravelDestinationShowResultsController( dialog, notificationUtils, destinationsRepository, $stateParams ) {
  // vm.destinations and vm.filename provided through locals
  const vm = this, ASCENDING = 'asc', DESCENDING = 'desc'

  vm.cancel = dialog.cancel
  vm.save = onSaveDestinations
  vm.onDestinationSortChanged = onDestinationSortChanged
  vm.editDestination = editUploadedDestination
  vm.deleteDestination = deleteUploadedDestination
  vm.onErrorsFirstClicked = onErrorsFirst

  vm.errorsCount = countInvalidTravelDestinations()
  vm.destinationSort = { id: ASCENDING }

  function countInvalidTravelDestinations () {
    return vm.destinations.filter( td => !td.$isValid ).length
  }

  function editUploadedDestination(index){
    dialog.show(EditUploadedDestinationDialog, { locals: {rfp: vm.rfp}, resolve: { Destination () { return vm.destinations[index] } } })
      .then( editedDestination => editedDestination.validate() )
      .then( editedDestination => {
        vm.destinations[index] = editedDestination
        vm.errorsCount = countInvalidTravelDestinations()
      }, noop)
  }

  function deleteUploadedDestination(index){
    dialog.show(DeleteUploadedDestinationDialog, { resolve: { Destination () { return vm.destinations[index] } } })
      .then(() => {
        vm.destinations.splice(index, 1)
        vm.errorsCount = countInvalidTravelDestinations()
      })
      .catch( noop )
  }

  function onDestinationSortChanged(key){
    if( vm.destinationSort[key] === ASCENDING){
      vm.destinationSort[key] = DESCENDING
    } else {
      vm.destinationSort = {}
      vm.destinationSort[key] = ASCENDING
    }

    vm.destinations = orderBy(vm.destinations, [key], [vm.destinationSort[key]])
  }

  function onErrorsFirst(){
    vm.errorsFirst = !vm.errorsFirst
    onDestinationSortChanged( vm.destinationSort.$isValid ? 'id' : '$isValid' )
  }

  function onSaveDestinations(){
    vm.errorsCount = countInvalidTravelDestinations()
    if(vm.errorsCount){
      dialog.show('error', { resolve: { Error: () => { return {
          heading: 'SAVING',
          title: 'Failed',
          message: 'Please fix or delete all uploaded Travel Destinations before saving!'
        }}}})
        .catch( noop )
    } else {
      notificationUtils.onSave( () => destinationsRepository.createRfpTravelDestinations(vm.destinations, $stateParams.rfpId) )
        .then( dialog.hide )
        .catch( noop )
    }
  }
}
