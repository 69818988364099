import { isUndefined } from 'lodash'

const GUEST = currentUser => {return isUndefined(currentUser) || currentUser === null},
  USER = isUser,
  USER_WITHOUT_ACCOUNT = currentUser => { return isUser(currentUser) && !currentUser.account }

export { GUEST, USER, USER_WITHOUT_ACCOUNT }

function isUser(currentUser){
  return !!currentUser && !isUndefined(currentUser.id) && currentUser.id !== null;
}
