import { isFunction, isNil, get } from 'lodash'
import moment from 'moment';

const validators = {
  required ( subject, isRequired ) {
    return isRequired && (subject === undefined || subject === null || (`${subject}`).trim().length === 0)
  },
  maxLength ( subject, maxLength ) { return subject && subject.length > maxLength },
  min ( subject, minValue ) {
    const s = parseFloat(subject)
    return !isNaN(s) && s < minValue
  },
  max ( subject, maxValue ) {
    const s = parseFloat(subject)
    return !isNaN(s) && s > maxValue
  },
  pattern ( subject, patternValue ) {
    return !isNil(subject) && !patternValue.test(subject)
  },
  minDate(subject, minDate) {
    return moment(subject).isBefore(moment(minDate));
  },
  maxDate(subject, maxDate) {
    return moment(subject).isAfter(moment(maxDate));
  },
}

export { getValidationRules, validateTravelDestination, getRatesValidations, validateRates, isFieldValid }

function getValidationRules() {
  return {
    type: {
      required: true,
    },
    name: {
      required: true,
      maxLength: 100
    },
    estimatedRoomNights: {
      required: false,
      min: 0,
      max: 1000000,
      pattern: /^-?\d+$/
    },
    estimatedSpend: {
      required: false,
      min: 0,
      max: 100000000,
      pattern: /^-?\d+$/
    },
    location: {
      country ( location ) { return !get(location, 'address.country')},
      state ( location ) {
        const country = get(location, 'address.country')
        return ['United States', 'Canada'].indexOf(country) !== -1 && !get(location, 'address.state')
      },
      city ( location ) { return !get(location, 'address.city') },
      coordinates ( location ) { return !get(location, 'coordinates') },
    },
  }
}

function getRatesValidations(index, rates, {programStartDate, programEndDate: maxDate}) {
  return {
    startDate: {
      required: true,
      minDate: minDate(),
      maxDate,
    },
    endDate: {
      required: true,
      maxDate,
      minDate: rates[index].startDate
    },
    rate: {
      required: true,
      min: 0,
      max: 100000000,
      pattern: /^-?\d+$/
    },
  }

  function minDate() {
    const d = index > 0? rates[index - 1].endDate: programStartDate;
    return d;
  }
}

function validateTravelDestination(td, {programStartDate, programEndDate}){
  const errors = {}, validationRules = getValidationRules()
  Object.keys(validationRules).forEach( rule => { errors[rule] = validate(td[rule], validationRules[rule], td) })
  errors.caps = validateRates(td.caps, {programEndDate, programStartDate});
  return errors
}

function validateRates(rates, {programStartDate, programEndDate}) {
  const fields = rates.map((rate, index) => {
    const validations = getRatesValidations(index, rates, {programStartDate, programEndDate})
    return Object.keys(validations)
      .map(rule => ({rule, errors: validate(rate[rule], validations[rule])}))
      .reduce((acc, err) => {
        return {
          ...acc,
          [err.rule]: err.errors
        }
      }, {});
  });
  return {
    fields,
    invalid: fields.some(field => field.startDate.invalid || field.endDate.invalid ||field.rate.invalid )
  }
}

function validate(subject, rules, destination) {
  const errorsBox = { invalid: false, errors: [] }

  Object.keys(rules).forEach( ruleName => {
    const rule = rules[ruleName],
      isError = isFunction(rule) ? rule(subject, destination) : validators[ruleName](subject, rule)

    if(isError){
      errorsBox.invalid = isError
      errorsBox.errors.push(ruleName)
    }
  })

  return errorsBox
}

function isFieldValid(field, td) {
  return !(validate(td[field], getValidationRules()[field], td)).invalid;
}
