/**
 * Created by DejanK on 10/11/2016.
 */

'use strict';

module.exports = rbSelectMenuPositioningService;

function rbSelectMenuPositioningService(){
  let service = this;
  service.setMenuPosition = setMenuPosition;

  function setMenuPosition(menuContainerElement, selectElement, overlayElement){
    menuContainerElement.css({top: '', left: '', right: '', bottom: ''});
    let selectRect = selectElement[0].getBoundingClientRect(),
      overlayBoundingRect = overlayElement[0].getBoundingClientRect(),
      containerRect = menuContainerElement[0].getBoundingClientRect(),
      menuPosition = {};

    // horizontal:
    if(containerRect.width > overlayBoundingRect.width - 10) {
      menuPosition.left = '5px';
      menuPosition.right = '5px';
    } else if (selectRect.left + containerRect.width > overlayBoundingRect.width - 5) {
      menuPosition.right = '5px';
    } else {
      menuPosition.left = selectRect.left + 'px';
    }

    // vertical
    if(containerRect.height > overlayBoundingRect.height - 10) {
      menuPosition.top = '5px';
      menuPosition.bottom = '5px';
    } else if (selectRect.top + containerRect.height > overlayBoundingRect.height - 5) {
      menuPosition.bottom = '5px';
    } else {
      menuPosition.top = selectRect.top + 'px';
    }

    menuContainerElement.css(menuPosition);
  }
}
