{#if type === 'RATE_QUICK' || type === 'PER_DIEM'}
  <RateQuickNavigation {rfpId} {chainSupport} {attachments} {type} />
{:else if type === 'STANDARD'}
  <StandardNavigation {rfpId} {chainSupport} {attachments} />
{/if}

<script>
  import StandardNavigation from './RfpStandardNavigation.svelte';
  import RateQuickNavigation from './RfpRateQuickNavigation.svelte';
  
  export let type, rfpId,
    chainSupport = false,
    attachments = {};
</script>
