<template>
  <div class="pageWithHeaderBody userBackground layout-column">
    <div :class="$style.container">
      <div :class="$style.navigationContainer">
        <profile-photo />
        <div>
          <h1 :class="$style.pageTitle">User Profile</h1>
          <a :href="hrefProfile.href" :class="hrefProfile.styles.navItem">
            <i class="material-icons" :class="hrefProfile.styles.navIcon">account_circle</i>
            <span :class="hrefProfile.styles.navLabel">Personal Information</span>
          </a>
        </div>
      </div>
      <personal-info :class="$style.content" :user="user.data"/>
    </div>
  </div>
</template>

<script>
  import ProfilePhoto from './ProfilePhoto.vue';
  import personalInfo from './PersonalInformation.vue';
  import {$state} from "root/angular-injector-provider";
  import {PAGE_CURRENT_USER_PROFILE} from "root/states";

  export default {
    name: 'rbv-user-profile-comp',
    components: { ProfilePhoto, personalInfo },
    props: {
      user: {
        required: true,
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      hrefProfile () {
        return {
          href: this.href( PAGE_CURRENT_USER_PROFILE ),
          styles: this.styles(true)
        }
      }
    },
    methods: {
      href ( stateName, options ) { return $state().href(stateName, options) },
      styles (isActive) {
        return isActive
          ? { navItem: this.$style.activeNavItem, navIcon: this.$style.activeNavIcon, navLabel: this.$style.activeNavLabel }
          : { navItem: this.$style.navItem, navIcon: this.$style.navIcon, navLabel: this.$style.navLabel }
      }
    }
  }
</script>


<style lang="stylus" module="">

  .container {
    box-sizing: border-box;
    flex: 1 1 100%;
    margin: 20px auto;
    display: flex;
    max-width: 95%;
  }

  .navigationContainer {
    width: 304px;
    background: white;
    margin-right: 5px;
    border-radius: 6px 0 0 6px;
    box-shadow : 0 0 3.14px rgba(0, 0, 0, 0.18);
  }

  .content {
    background: white;
    min-width : 624px;
    border-radius : 0 6px 6px 0;
    box-shadow : 0 0 7.53px rgba(0, 0, 0, 0.18);
  }

  .pageTitle {
    font-weight : bold;
    font-size : 14px;
    text-transform : uppercase;
    color : #37474F;
    padding: 5px 23px 15px;
  }

  .navItem {
    display: block;
    height: 40px;
    line-height: 40px;
    background: #FBFBFC;
    border: 1px solid #ECEFF1;
    border-left: 0;
    border-right: 0;
    text-decoration: none !important;
    padding: 0 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size : 12px;
    color: #546E7A !important;
  }

  .activeNavItem {
    composes navItem
    background: #00B8AB;
    border: 1px solid #00B8AB;
    color: white !important;
  }

  .navIcon {
    font-size: 16px;
    vertical-align: middle;
    padding: 0 5px;
    color: #546E7A;
  }

  .activeNavIcon {
    composes navIcon
    color: white;
  }

  .navLabel {
    vertical-align: middle;
    color: #546E7A;
  }

  .activeNavLabel {
    composes navLabel
    color: white;
  }

</style>
