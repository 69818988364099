<template>
  <div class="bar hotel-directory-preview-bar">
    <div class="controls left">
      <hd-select
        :dark="true"
        :items="groupers"
        :value="groupBy"
        @input="emitGroupBy"
      >
        <i
          slot="icon"
          class="material-icons"
        >calendar_view_day</i>
        <span slot="label">Group By</span>
      </hd-select>
      <hd-select
        v-if="groupBy"
        :dark="true"
        :value="filterBy"
        :items="filters"
        :search="true"
        @input="emitFilterBy"
      >
        <i
          slot="icon"
          class="material-icons"
        >calendar_view_day</i>
        <span slot="label">Filter By</span>
      </hd-select>
    </div>
    <div class="controls right"><slot /></div>
  </div>
</template>

<script>
import HdSelect from 'vRoot/hotel-directory/_core/hd-select.vue';

export default {
  name: 'HotelDirectoryPreviewBar',
  components: {HdSelect},
  props: {
    groupers: {
      type: Array,
      required: true,
    },
    groupBy: {
      type: String,
      default: null,
    },
    filters: {
      type: Array,
      required: true,
    },
    filterBy: {
      type: String,
      default: null,
    },
    travelPolicy: {
      type: String,
      default: null,
    },
  },
  methods: {
    emitFilterBy(e) {
      this.$emit('filterBy', e);
    },
    emitGroupBy(e) {
      this.$emit('groupBy', e);
    },
  }
}
</script>

<style lang="stylus">
    .hotel-directory-preview-bar{
        height 90px;
        flex-wrap nowrap !important;
        padding 25px 50px !important;

        .controls{
            display: flex;
            align-items center;

            &.right{
                justify-content flex-end;
            }
        }
    }
</style>


