import AccountManageCompanies from './AccountManageCompanies.vue';
import {TRAVEL_AGENCY_TYPE} from 'vRoot/account-profile/modules/manage-companies/services/manage-company.service';
const ACCOUNT_MANAGE_COMPANIES_MODULE = 'manageCompanies';

export default {
  id: ACCOUNT_MANAGE_COMPANIES_MODULE,
  priority: 12,
  title: 'Customers',
  icon: 'business',
  component: AccountManageCompanies,
  isAvailable (accountType) { return accountType === TRAVEL_AGENCY_TYPE }
}

export {
  ACCOUNT_MANAGE_COMPANIES_MODULE
};
