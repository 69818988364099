import { derived, writable } from 'svelte/store';
import { currentUser } from '../../stores/group-sort-filter.store';

export const group = writable('---');
export const filter = writable(null);
export const sortBy = writable({
  key: 'name',
  direction: 'up'
});
export const quickFilter = writable('');

export const groups = derived(
  currentUser,
  user => {
    return user? [
      {
        value: '---',
        label: 'None'
      },
      {
        value: 'CHAIN',
        label: 'Chains',
        labelKey: 'chain.master.name',
        key: 'chain.master.id',
        icon: 'link',
        hidden: !user.isOfType('HMC')
      },
      {
        value: 'BRAND',
        label: 'Brands',
        labelKey: 'chain.name',
        key: 'chain.id',
        icon: 'groups'
      },
      {
        value: 'HMC',
        label: 'Hotel Management Companies',
        labelKey: 'hotelManagementCompany.name',
        key: 'hotelManagementCompany.id',
        icon: 'business',
        hidden: !user.isOfType('CHAIN')
      },
      {
        value: 'COUNTRY',
        label: 'Countries',
        labelKey: 'location.address.countryName',
        key: 'location.address.country',
        icon: 'location_on'
      },
      {
        value: 'CITY',
        label: 'Cities',
        labelKey: 'location.address.city',
        key: 'location.address.city',
        icon: 'where_to_vote'
      }
    ].filter(({ hidden }) => !hidden): []
  }
);

export const groupBy = derived([group, groups], ([$group, $groups]) => {
  return $groups.find(({ value }) => value === $group);
});
