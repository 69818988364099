<template>
  <div class="account-manage-users-list">
    <user-row
      v-for="user in users"
      :key="user.id"
      :user="user"
      :search-term="searchTerm"
      :is-current-user="user.id === userAccount.id"
      :current-user-is-admin="currentUserIsAdmin"
      :current-account-is-company="currentAccountIsCompany"
      :current-account-is-tmc="currentAccountIsTmc"
      :current-user-account="userAccount"
      @editUser="editUser(user)"
      @confirmUser="confirmUser(user)"
      @deactivateUser="deactivateUser(user)"
      @showAssignments="showAssignments(user)"
      @resendInvitation="resendInvitation(user)"
      @removeInvitation="removeInvitation(user)"
      @changeAccountType="changeAccountType(user)"
    />
  </div>
</template>

<script>
  import userRow from './UserRow.vue';
  export default {
    name: 'UsersList',
    components: {userRow},
    props: {
      users: {
        type: Array,
        required: true
      },
      searchTerm: {
        type: String,
        required: true
      },
      userAccount:{
        type: Object,
        required: true
      },
      currentUserIsAdmin: {
        type: Boolean,
        required: true
      },
      currentAccountIsCompany: {
        type: Boolean,
        required: true
      },
      currentAccountIsTmc: {
        type: Boolean,
        required: true
      },
    },
    methods: {
      editUser(user) {
        this.$emit('editUser', user);
      },
      confirmUser(user) {
        this.$emit('confirmUser', user);
      },
      deactivateUser(user) {
        this.$emit('deactivateUser', user);
      },
      showAssignments(user) {
        this.$emit('showAssignments', user);
      },
      resendInvitation(user) {
        this.$emit('resendInvitation', user);
      },
      removeInvitation(user) {
        this.$emit('removeInvitation', user);
      },
      changeAccountType(user) {
        this.$emit('changeAccountType', user);
      }
    }
  }
</script>

<style lang="stylus">
 .account-manage-users-list {
   flex: 1 1 100%;
   overflow: auto;
 }
</style>
