<template>
  <questionnaire-question-date
    v-if="type === 'DATE'"
    :model="model"
    :template="updatedTemplate"
    :response="response"
  />

  <questionnaire-question-list
    v-else-if="type === 'LIST'"
    :model="model"
    :template="updatedTemplate"
    :response="response"
  />

  <questionnaire-question-text
    v-else=""
    :model="model"
    :template="updatedTemplate"
    :response="response"
  />
</template>

<script>
  import QuestionnaireQuestionDate from './QuestionnaireViewQuestionDate.vue'
  import QuestionnaireQuestionList from './QuestionnaireViewQuestionList.vue'
  import QuestionnaireQuestionText from './QuestionnaireViewQuestionText.vue'

  export default {
    name: 'rb-questionnaire-question',

    components: {
      QuestionnaireQuestionDate,
      QuestionnaireQuestionList,
      QuestionnaireQuestionText
    },

    props: ['model', 'template', 'response'],

    computed: {
      type () { return this.template.type === 'USER_DEFINED' ? this.model.type : this.template.type },
      updatedTemplate () { return this.template.type === 'USER_DEFINED' ? this.model : this.template }
    }
  }
</script>

<style lang="stylus">

  .questionnaireQuestion {
    flex: 8 0 120px;
    padding: 3px 3px 2px;

    font-size: 10px;
    font-weight: 400;
    color: #78909C;

    min-width: 0;
    min-height: 43px;

    border: 1px solid #eceff1;
    margin: 2px;

    &.question-size-1 {
      flex: 10 0 80px;
    }

    &.question-size-2 {
      flex: 8 0 120px;
    }

    &.question-size-3 {
      flex: 7 0 160px;
    }

    &.question-size-4 {
      flex: 6 0 200px;
    }

    &.required {
      background-color: #fefcd3;
    }
  }

  .questionnaireQuestionLabel {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 12px;
    margin: 2px 0;
  }

  .questionnaireQuestionLabelOrd {
    padding: 0 2px 0 3px;
    border-radius: 3px;
  }

  .questionnaireQuestionInput {
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #DCDEE0;
    border-radius: 2px;
    width: 100%;
    height: 17px;
    padding: 0 3px;
    font-size: 11px;
    line-height: 15px;
    background: white;
    color: #546E7A;
    transition: all .1s linear;
  }
</style>
