import angular from 'angular'

import Servers from '../shared-ng/servers'
import Utils from '../shared-ng/utils'
import NgUI from '../ui-ng'

import Dialogs from './dialogs'

export default angular.module('rb.public', [ Servers, Utils, NgUI ])
  .config(Dialogs)
