import { checkAndExecute } from 'root/api/helpers';

export default function RfpAPI(api) {

  Object.assign(api, {

    listRfpTemplates: rfpType => checkAndExecute(
      () => rfpType,
      () => api.server.get(`rfps/templates`, { 'rfp-type': rfpType })),

    getRfpTemplatePreview: ( accountId, templateId ) => checkAndExecute(
      () => accountId && templateId,
      () => api.server.get(`rfps/templates/${templateId}/preview`, { account: accountId })),

    getAccountRfpPreview: ( accountId, rfpId ) => checkAndExecute(
      () => rfpId && accountId,
      () => api.server.get(`rfps/${rfpId}/preview-for-account`, { account: accountId })),

    useRfpTemplate: ( accountId, templateId, programStartDate, programEndDate ) => checkAndExecute(
      () => accountId && templateId && programStartDate && programEndDate,
      () => api.server.post(`rfps`, { templateId, accountId, programStartDate, programEndDate })),
    
    duplicateRfp: (accountId, rfpId, templateId, programStartDate, programEndDate ) => checkAndExecute(
      () => accountId && rfpId && templateId && programStartDate && programEndDate,
      () => api.server.post(`rfps/${rfpId}/duplicate/`, {accountId, templateId, programStartDate, programEndDate })
    ),

    dismissNag: (rfpId, nag ) => checkAndExecute(
      () => rfpId && nag,
      () => api.server.delete(`/rfps/hotel/${rfpId}/nags/${nag}`)
    )
  });
}
