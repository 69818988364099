<template>
  <fieldset>
    <legend>Address / Location</legend>
    <rbv-address-autocomplete
      :query="viewValue && viewValue.fullAddress || ''"
      :show-form="formOpened"
      @addManually="showForm"
      @change="onLocationSelected"
    />

    <div
      v-if="formOpened"
      class="layout-2-columns rbv-map-fields"
    >
      <rbv-input-address
        v-model="viewValue.address"
        v-validate="'required'"
        name="address"
        @change="onAddressChanged"
      />
      <div class="layout-column">
        <rbv-input-coordinates
          v-model="viewValue.coordinates"
          v-validate="'required'"
          class="layout-column flex-100 rbv-map"
          name="coordinates"
          :zoom="zoom"
          @change="onCoordinatesChanged"
        />
        <div :class="$style.mapHint">
          Drag marker to adjust coordinates
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import { cloneDeep } from 'lodash'
  import RbvAddressAutocomplete from './RbvAddressAutocomplete.vue'
  import RbvInputAddress from './RbvInputAddress.vue'
  import RbvInputCoordinates from './RbvInputCoordinates.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'

  export default {
    name: 'RbvInputAddressWithCoordinates',
    components: { RbvAddressAutocomplete, RbvInputAddress, RbvInputCoordinates },
    mixins: [ RbEventsMixin ],
    inject: {
      $validator: 'validator'
    },
    $_veeValidate: {
      value() {
        return this.modelValue
      }
    },

    props: {
      value: {
        type: Object,
        default: () => ({})
      },
      formOpened: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        modelValue: undefined,
        viewValue: undefined,
        zoom: 3
      }
    },
    watch: {
      value: {
        immediate: true,
        handler (val) {
          this.modelValue = val;
          this.modelFormat();
          this.modelRender()
        }
      }
    },

    methods: {
      // model methods
      modelFormat() {},
      modelRender() {
        if(this.isModelValueEmpty()){
          this.viewValue = { address: { country: 'US' }, coordinates: {lat: 40.1451, lng: -99.6680} };
          this.zoom = 3
        } else {
          this.viewValue = cloneDeep(this.modelValue);
          this.showForm();
          this.zoom = 14
        }
      },

      modelParse() {
        this.modelValue = this.viewValue
      },
      modelEmit() {
        this.$emit('input', this.modelValue);
        this.$emit('change', this.modelValue);
      },

      onLocationSelected(event){
        this.viewValue = event;
        this.modelParse();
        this.modelEmit();
        this.showForm();
        this.zoom = 14;

        this.$nextTick()
          .then(() => {
            this.$validator.validateAll()
          })
      },

      onAddressChanged(event){
        Object.assign(this.viewValue, {address: event});
        this.modelParse();
        this.modelEmit()
      },

      onCoordinatesChanged(event){
        Object.assign(this.viewValue, {coordinates: event});
        this.modelParse();
        this.modelEmit()
      },

      isModelValueEmpty(){
        return !(this.modelValue && this.modelValue.address && this.modelValue.coordinates)
      },

      showForm(){
        this.$emit('formOpened')
      }
    },
  }
</script>

<style lang="stylus" module="">

  .mapHint {
    height : 16px;
    padding: 0 3px;
    background : #B0BEC5;
    border: 1px solid #B0BEC5;
    border-radius : 3px;
    font-size : 10px;
    color : #FFFFFF;
    box-shadow : 0 0 9.46px rgba(0, 0, 0, 0.18);
  }

</style>

