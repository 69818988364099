<script>
  import isString from 'lodash/isString';

  export default {
    methods: {
      $rbEmit ( event, eventModifier ) {
        if(isString(event)) {
          this.$emit(event, { name: event, ...eventModifier });
        } else {
          const modifiedEvent = Object.assign({}, event, eventModifier);
          this.$emit(modifiedEvent.name, modifiedEvent);
        }
      },
    },
  }
</script>
