<template>
  <div
    class="row"
    :class="{ selected: added, [$style.row]: true }"
  >
    <check-box
      :value="added"
      :check-box-value="true"
      class="cell  checkboxCell  dark-icon"
      @input="toggle"
    />

    <div
      v-for="column in columns"
      :key="column"
      class="cell"
      :class="{ [$style[column]]: $style[column], [$style.cell]: true }"
    >
      <template
        v-if="is(column, 'category')"
      >
        <column-select
          icon="face"
          label="Select category"
          :value="row.directoryViewId"
          :text="getViewName() || '___'"
          :options="views"
          @input="assignView"
        />
      </template>
      <div v-else-if="is(column, 'type')">
        <span>{{row.type === 'ACTIVE'? 'Power User': 'Passive User'}}</span>
      </div>
      <div v-else>
        <span>{{ getColumnValue(column) || "___" }}</span>
        <template v-if="is(column, 'name') && row.type === 'ACTIVE'">
          <i class="material-icons">offline_bolt</i>
          <div
            class="rbTooltip simple"
            tooltip="placement:top-start"
          >
            A power user can make hotel recommendations
          </div>
        </template>
      </div>
    </div>
    <div
      class="cell cell-xs"
      style="min-width: 50px; max-width: 50px"
    >
      <div :class="$style.actions">
        <i
          v-if="!row.directoryViewId"
          class="material-icons"
          :class="$style.clickable"
          @click="drop"
        > delete </i>
        <i
          v-else
          class="material-icons"
          :class="$style.clickable"
          @click="link"
        > visibility </i>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
import ColumnSelect from './TableRowSelectColumn.vue';
export default {
  name: 'HotelDirectoryManageUsersTableRow',
  components: { CheckBox, ColumnSelect },
  props: {
    row: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    added: Boolean,
    allColumns: {
      type: Array,
      default: () => []
    },
    views: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    getColumn(id) {
      return this.allColumns.find(col => col.id === id);
    },
    is(src, dest) {
      return src === dest;
    },
    getColumnValue(id) {
      const column = this.getColumn(id);
      if (Array.isArray(column.key)) {
        return column.key.map(key => get(this.row, key)).join(' ');
      } else {
        return get(this.row, column.key);
      }
    },
    getViewName() {
      const view = this.views.find(v => v.id === this.row.directoryViewId);
      return view && view.name;
    },
    toggle() {
      this.$emit(this.added ? 'remove' : 'add', this.row);
    },
    drop() {
      this.$emit('drop', this.row);
    },
    link() {
      this.$emit('link', this.row);
    },
    assignView(viewId) {
      if (viewId && this.row.directoryViewId !== viewId) {
        this.$emit('assignView', { id: this.row.id, viewId });
      }
    }
  }
};
</script>

<style lang="stylus" module>
.actions {
  display flex;
}

.cell{
  min-width 100px !important;
}

.clickable{
  cursor pointer;
  border-radius 100%;
  padding 3px;
}

.row:hover .category > div > :first-child{
  display none;
}

.row:hover .category > div > :last-child{
  display block;
}

.row:hover .clickable{
  background #fff;
}

.category{
  padding 0;
  overflow visible !important;
}

.name > div {
  display flex;
  align-items center;
}

.name i {
  margin-left 5px;
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.name > span {
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
}

</style>
