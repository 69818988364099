<template>
  <tr v-if="isCellInModelFilters(template.for)" class="questionnaireTableRow"
      :class="template.classes">
    <template v-for="cell in filteredCells">
      <questionnaire-table-cell
        :template="cell"
        :model="model"
        :response="response"
      />
    </template>
  </tr>
</template>

<script>
  import QuestionnaireTableCell from './QuestionnaireViewSectionTableRowCell.vue'

  export default {
    name: 'rb-questionnaire-view-section-table-row',

    components: { QuestionnaireTableCell },

    props: ['model', 'template', 'response'],

    computed: {
      filteredCells () {
        return this.template.cells.filter( cell => this.isCellInModelFilters(cell.for) )
      }
    },

    methods: {
      isCellInModelFilters ( cellFilters ) {
        return !cellFilters || !Object.keys(cellFilters).find(
          cellFilterKey => this.model.filters[cellFilterKey].indexOf(cellFilters[cellFilterKey]) === -1
        )
      }
    }
  }
</script>

<style lang="stylus">
  .questionnaireTableRow {
    height 27px

    &.row-header {
      background-color: #ECEFF1;
      text-align: center;
      font-weight: bold;
      border: 0;
    }

    &.row-sub-header {
      background-color: #FDFDFE;
    }
  }
</style>
