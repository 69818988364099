<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import MfaAuthenticatorAppSetupDialog from './MfaAuthenticatorAppSetupDialog.svelte'
  import MfaEmailSetupDialog from './MfaEmailSetupDialog.svelte'
  import RemoveMfaDialog from './RemoveMfaDialog.svelte'
  import { SvelteDialog } from 'root/v-app/rbServices';

  import { mainAPI } from 'root/angular-injector-provider.js';
  import noop from 'lodash/noop';

  /**
   * The cancel function.
   * @type {Function}
   */
  export let cancel;

  let user, mfaOptions = [];

  initialize();

  /**
   * Initializes the module.
   */
  async function initialize () {
    const r = await mainAPI().userSecurity.getUserDetails();
    user = r.data;
    mfaOptions = user.mfa || [];
  }

  function setupTotp(){
    SvelteDialog.show(MfaAuthenticatorAppSetupDialog, {user}, {zIndex: 100001})
      .then(updateMfaOptions)
      .catch(noop)
  }
  function setupEmail(){
    SvelteDialog.show(MfaEmailSetupDialog, {user}, {zIndex: 100001})
      .then(updateMfaOptions)
      .catch(noop)
  }

  function removeMfa(type){
    SvelteDialog.show(RemoveMfaDialog, {type}, {zIndex: 100001})
      .then(updateMfaOptions)
      .catch(noop)
  }

  function updateMfaOptions(r){
    mfaOptions = r;
  }

</script>


<ClosableModal width="600px" close="{cancel}">
  <div slot="heading">
    <ModalTitle title="Multi-factor Authentication (MFA) Settings">
      Protect your ReadyBid account with our security measures
    </ModalTitle>
  </div>

  {#if user}
    <div class="body">
      <div class="AuthMethod">
        <span class="title" data-icon-before="lock">Authenticator App</span>

        {#if mfaOptions.includes('TOTP')}
          <div class="active" data-icon-before="check_circle">
            <button class="delete" data-icon-before="delete" on:click={() => removeMfa('TOTP')}></button>
          </div>
        {:else}
          <div>
            <button on:click={setupTotp}>SET UP</button>
          </div>
        {/if}
      </div>

      <div class="AuthMethod">
        <span class="title" data-icon-before="email">Email</span>

        {#if mfaOptions.includes('EMAIL')}
          <div class="active" data-icon-before="check_circle">
            <button class="delete" data-icon-before="delete" on:click={() => removeMfa('EMAIL')}></button>
          </div>
        {:else}
          <div>
            <button on:click={setupEmail}>SET UP</button>
          </div>
        {/if}
      </div>
    </div>
  {:else}
    <div class="loading">
      <Spinner />
    </div>
  {/if}
</ClosableModal>

<style lang="stylus">.body {
  padding: 0 15px;
  font-size: 13px;
  line-height: 16px;
  color: #78909c;
  margin-top: -10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.AuthMethod {
  width: 260px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #b0bec5;
  border-radius: 8px;
  color: #37474f;
  font-size: 14px;
  padding: 0 5px;
}
.AuthMethod .title:before {
  color: #546e7a;
  width: 30px;
}
.AuthMethod .active:before {
  color: #00a99d;
}
.AuthMethod button {
  height: 28px;
  width: 56px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 16px;
  border-radius: 8px;
  border-color: #b0bec5;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.2s linear;
  background: #fff;
}
.AuthMethod button:hover {
  border-color: #90a4ae;
  background: #f5f6f7;
}
.AuthMethod button.delete {
  justify-content: center;
}
.loading {
  padding: 0 15px;
  margin-top: -10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
</style>
