<script>
  import {createEventDispatcher} from 'svelte';

  export let placeholder = 'Filter', value = '';

  const dispatch = createEventDispatcher();

  let focused;
</script>


<div class="QuickSearchContainer"
     class:Focused={focused}
     data-icon-before="filter_list"
>
  <input type="text"
         {placeholder}
         {value}
         on:focusin={() => focused = true}
         on:focusout={() => focused = false}
         on:input={event => dispatch('input', event.target.value)}
  >
</div>


<style lang="stylus">.QuickSearchContainer {
  font-size: 12px;
  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  border: 1px solid #dcdee0;
  border-radius: 20px;
  padding: 0 10px;
  background: #fff;
  display: flex;
}
.QuickSearchContainer:before {
  font-size: 24px;
  color: #78909c;
}
.QuickSearchContainer.Focused {
  border-color: #00a99d;
}
input {
  width: 100%;
  background: transparent;
  border: transparent;
  outline: transparent;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  color: #5546e7 a;
}
input::placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #78909c;
}
</style>
