<template>
  <div class="rfp-manager-group-by toolbox flex-hold">
    <label
      v-if="$slots.label"
      class="toolbox-label"
    >
      <slot name="label" />
    </label>
    <rb-dropdown
      class="toolbox-content"
      :is-open="open"
      :backdrop-style="'backdropStyle'"
      :align="align"
      @close="toggle"
    >
      {{items.length}}
      <div
        slot="toggler"
        class="rfp-manager-select"
        @click="toggle"
      >
        <span><slot name="value" /></span>
        <i
          v-if="open"
          class="material-icons"
        >
          arrow_drop_up
        </i>
        <i
          v-else
          class="material-icons"
        >
          arrow_drop_down
        </i>
      </div>

      <div slot="dropdown">
        <div
          v-for="item in items"
          :key="item.id"
          class="dropdown-menu-item"
          :class="{selected: item.id === value.id}"
          @click="select(item)"
        >
          <i
            v-if="item.icon"
            class="material-icons"
          >
            {{ item.icon }}
          </i>
          <span v-html="item.label"></span>
        </div>

        <div
          v-if="!items.length"
          class="dropdown-menu-empty"
        >
          {{ noItemsMessage }}
        </div>
      </div>
    </rb-dropdown>
  </div>
</template>

<script>
// a Svelte replacement RfpManagerDropdownActions is already created
import rbDropdown from 'vRoot/_core/RbDropdown.vue';

export default {
  name: 'RfpManagerGroupAndFilter',
  components: { rbDropdown },
  props: {
    value: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    align: {
      type: String,
      default: 'right'
    },
    noItemsMessage: {
      type: String,
      default: 'No Options Available!'
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    select(item) {
      this.$emit('input', item);
      this.toggle();
    }
  }
}
</script>

<style lang="stylus">
.rfp-manager-group-by{
  .rfp-manager-select{
    background: rgb(38, 50, 56);
    border: 1px solid #16272b;
    padding: 0 2px;
    box-sizing: border-box;
    cursor pointer;
    display: flex;
    align-items center;
    justify-content space-between;
    height 27px;
    border-radius 2px;

    span{
      line-height: 27px;
      color: white;
      font-size 12px;
      text-decoration none;
      display flex;
      align-items center;
      flex: 1;

      i.material-icons:first-child{
        margin 0 5px;
        font-size: 18px;
      }
    }
  }

  .toolbox-content.rbvDropDownContainer{
    overflow visible !important;
  }

  .rbvDropDown{
    min-width: 179px;
    width max-content;
    color: #90A4AE;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px 0px;
    background: #263238;
    border: 1px solid rgb(22, 39, 43);
    margin-top: 5px;
    top: 100%;
    border-radius 3px;
    font-size 12px;
    max-height: 400px;
    overflow: auto;

    .dropdown-menu-item{
      padding 5px;
      cursor: pointer;
      transition background .3s;
      display: flex;
      align-items center;

      i.material-icons{
        margin-right 10px;
        font-size 20px;
      }

      &:hover, &.selected{
        background #36474f;
        color #fff;
        border-bottom: 1px solid #16272B;
      }
    }

    .dropdown-menu-empty {
      padding: 5px 10px;
      color: #ECEFF1;
    }
  }

  .backdropStyle{
    background transparent;
  }

  &.light{
    .rfp-manager-select{
      background white linear-gradient(0deg, #f5f6f7 10%, white 11.05%, white 100%);
      border: 1px solid #e5e6e8;

      span{
        color: #546E7A;
      }
    }

    .rbvDropDown{
      color: #546E7A;
      background: #fff;
      border: 1px solid #b0bec5;

      .dropdown-menu-item{
        border-bottom 1px solid #e5e6e8;

        &:hover, &.selected{
          background #ECEFF1;
          color #546E7A;
        }
      }
    }
  }
}
</style>
