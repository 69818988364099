import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { notificationUtils, mainAPI, hotelRfpBidManagerService, hotelRfpNegotiationsPanelMessagingService } from 'root/angular-injector-provider';

let
  config = {},
  formModel = null;

const
  observables = [],
  service = {

    init (negotiations, side, mode, closeForm) {
      const
        bidId = negotiations.bidId,
        lastCommunication = negotiations.getLastCommunication(2),
        lastOffer = getLastOffer(lastCommunication),
        lastRequest = getLastRequest(lastCommunication),
        lastReply = side === 'BUYER' ? lastOffer : lastRequest,
        lastReplyModel = lastReply.getAllValues(),
        savedModel = null,
        isRequestAccepted = !['rates', 'amenities'].find( groupKey => {
          const values = lastOffer.values[groupKey];
          return Object.keys(values).find(key => values[key].change !== 'NONE');
        });

      config = {
        bidId,
        lastReply,
        lastReplyModel,
        lastOffer,
        lastRequest,
        savedModel,
        isRequestAccepted,
        negotiations,
        side,
        mode,
        closeForm
      };
    },

    registerObservable ( observable ) {
      observables.push(observable);
    },

    deregisterObservable ( observable ) {
      const index = observables.indexOf(observable);
      observables.splice(index, 1);
    },

    setSavedModel ( model ) {
      config.savedModel = cloneDeep(model);
    },

    onFormModelChange ( model ) {
      const changes = calculateChanges(config, model);
      observables.forEach( o => Object.assign( o, changes) );
      formModel = cloneDeep(model)
    },


    cancel () {
      config.closeForm();
    },

    submit () {
      save( () => mainAPI().hotelRfpNegotiationCreate(config.bidId, formModel) )
    },

    update () {
      save( () => mainAPI().hotelRfpNegotiationUpdate(config.bidId, formModel.id, formModel) )
    },

    acceptOffer () {
      return saveAndFinalize( mergeModels( formModel, config.lastOffer.getAllValues()) );
    },

    acceptRequest () {
      const model = mergeModels(formModel, config.lastRequest.getAllValues());
      save(
        config.mode === 'EDIT'
          ? () => mainAPI().hotelRfpNegotiationUpdate(config.bidId, model.id, model)
          : () => mainAPI().hotelRfpNegotiationCreate(config.bidId, model)
      )
    },

    finalizeWithRequest () {
      return saveAndFinalize( formModel );
    },

    finalizeWithModel () {
      return saveAndFinalize( formModel );
    }
  };

function getLastOffer (negotiations) {
  return negotiations.find( n => !n.from.buyer );
}

function getLastRequest (negotiations) {
  return negotiations.find( n => n.from.buyer );
}

function calculateChanges(config, model){
  const areValuesPristine = calculateIsEqual(config.savedModel, model);

  return {
    areValuesPristine,
    isInputPristine: areValuesPristine && config.savedModel.message === model.message,
    isAcceptingReply: calculateIsEqual(config.lastReplyModel, model),
    isInitialRequest: !config.lastRequest,
    isRequestAccepted: config.isRequestAccepted,
  };
}

function calculateIsEqual(modelA, modelB) {
  return isEqual(modelA.rates, modelB.rates) && isEqual(modelA.amenities, modelB.amenities);
}

function save(saveFn){
  notificationUtils().onSave(saveFn)
    .then( handleHttpResponse )
}

function handleHttpResponse (response) {
  hotelRfpNegotiationsPanelMessagingService().closeNegotiationsPanel()
  hotelRfpBidManagerService().updateBids(response);
  return config.bidId;
}

function saveAndFinalize( model ) {
  return notificationUtils().onSave(
    config.mode === 'EDIT'
      ? () => mainAPI().hotelRfpNegotiationUpdateAndFinalize(config.bidId, model.id, model)
      : () => mainAPI().hotelRfpNegotiationCreateAndFinalize(config.bidId, model)
  )
    .then( handleHttpResponse );
}

function mergeModels(formModel, mergeModel) {
  return Object.assign(
    {},
    formModel,
    {
      rates: mergeModel.rates,
      amenities: mergeModel.amenities,
      analytics: mergeModel.analytics
    });
}

export default service;
