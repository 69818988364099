import Vue from 'vue'
import vDialog from './Dialog.vue'

const config = {
  closeOnOutsideClick: false,
  zIndex: 1000
}

class Dialog {

  constructor(containerElement, dialogComponent, dialogData, options) {
    this.promise = new Promise((resolve, reject) => {
      this.$$promiseResolve = resolve
      this.$$promiseReject = reject
    })

    const element = document.createElement('div')
    containerElement.appendChild(element);

    this.$$instance = new Vue({
      el: element,
      render (createElement) { return createElement(vDialog, {
        props: {
          dialog: {
            component: dialogComponent,
            data: dialogData
          },
          options: Object.assign({}, config, options),
          ord: containerElement.childElementCount,
        }
      })}
    })
  }

  cancel (reason) { return this.$destroy( () => { this.$$promiseReject(reason) } ) }

  hide (reason) { return this.$destroy( () => { this.$$promiseResolve(reason) } ) }

  $destroy( action ){
    this.$$instance.$destroy()
    action && action()
    return this.promise
  }
}

export default Dialog
