<template>
  <div class="RbvInputAddress">

    <rb-input forId="country" class="country" :error="$showError('country')">
      <span slot="label">Country</span>
      <rb-select name="country" v-model="viewValue.country"
                 :options="countries"
                 label-key="name"
                 v-validate="'required'"
                 placeholder="Select Country"
                 data-vv-as="Country"
                 @change="onChange"/>
    </rb-input>

    <div v-if="viewValue.country === 'US'" class="layout-2-columns">

      <rb-input forId="state" class="state" :error="$showError('state')">
        <span slot="label">State</span>
        <rb-select name="state" v-model="viewValue.state"
                   :options="usStates"
                   v-validate="'required'"
                   placeholder="Select State"
                   data-vv-as="State"
                   @change="onChange"/>
      </rb-input>

      <rb-input forId="county" :error="$showError('county')">
        <span slot="label">County</span>
        <input id="county" type="text" name="county" class="rbInput"
               v-model="viewValue.county"
               v-validate="'max:100'"
               placeholder="County"
               data-vv-as="County"
               @change="onChange"/>
      </rb-input>

    </div>

    <rb-input v-if="viewValue.country === 'CA'" forId="state" class="state" :error="$showError('state')">
      <span slot="label">State</span>
      <rb-select name="state" v-model="viewValue.state"
                 :options="canadaStates"
                 v-validate="'required'"
                 placeholder="Select State"
                 data-vv-as="State"
                 @change="onChange"/>
    </rb-input>


    <rb-input v-if="viewValue.country !== 'US' && viewValue.country !== 'CA'"
              forId="region" class="no-left-margin region" :error="$showError('region')">
      <span slot="label">Region/Province</span>
      <input id="region" type="text" name="region" class="rbInput"
             v-model="viewValue.region"
             v-validate="{max: 100}"
             placeholder="Region"
             data-vv-as="Region/Province"
             @change="onChange"/>
    </rb-input>

    <div class="layout-2-columns">
      <rb-input forId="city" :error="$showError('city')">
        <span slot="label">City</span>
        <input id="city" type="text" name="city" class="rbInput"
               v-model="viewValue.city"
               v-validate="{max: 100, required: true}"
               placeholder="City"
               data-vv-as="City"
               @change="onChange"/>
      </rb-input>

      <rb-input forId="zip" :error="$showError('zip')">
        <span slot="label">Zip / Postal Code</span>
        <input id="zip" type="text" name="zip" class="rbInput"
               v-validate="{max: 20}"
               v-model="viewValue.zip"
               placeholder="Zip"
               data-vv-as="Zip"
               @change="onChange"/>
      </rb-input>
    </div>

    <rb-input forId="address1" :error="$showError('address1')">
      <span slot="label">Address 1</span>
      <input id="address1" type="text" name="address1" class="rbInput"
             v-model="viewValue.address1"
             v-validate="{max: 100, required: true}"
             placeholder="Address 1"
             data-vv-as="Address 1"
             @change="onChange"/>
    </rb-input>

    <rb-input forId="address2" class="no-left-margin" :error="$showError('address2')">
      <div slot="label">Address 2</div>
      <span slot="hint">[optional]</span>
      <input id="address2" type="text" name="address2" class="rbInput"
             v-model="viewValue.address2"
             v-validate="{max: 100}"
             placeholder="Address 2"
             data-vv-as="Address 2"
             @change="onChange"/>
    </rb-input>
  </div>
</template>

<script>
  import { cloneDeep } from 'lodash'
  import { mainAPI } from "root/angular-injector-provider";

  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue';
  import RbInput from 'vRoot/_core/RbInput.vue'
  import RbSelect from 'vRoot/_core/rbv-input/RbSelectNew.vue'

  export default {
    name: 'RbvInputAddress',
    mixins: [ RbFormMixin ],
    components: { RbInput, RbSelect},
    $_veeValidate: {
      value() {
        return this.modelValue
      }
    },
    inject: {
      $validator: 'validator'
    },
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    },

    data(){
      return {
        modelValue: undefined,
        viewValue: undefined
      }
    },

    asyncComputed: {
      countries: {
        lazy: true,
        get () { return mainAPI().getCountries() }
      },
      usStates: {
        lazy: true,
        get () {
          return mainAPI().getStates()
            .then( results => results.filter( r => r.country === 'US') )
        }
      },
      canadaStates: {
        lazy: true,
        get () {
          return mainAPI().getStates()
            .then( results => results.filter( r => r.country === 'CA') )
        }
      },
    },

    watch: {
      value: {
        immediate: true,
        handler(v) {
          this.modelValue = v
          this.modelFormat()
          this.modelRender()
        },
      },
    },

    methods: {
      // model methods
      modelFormat() {},
      modelRender() {
        this.viewValue = this.modelValue && this.modelValue.country ? cloneDeep(this.modelValue) : { country: 'US' }
      },
      modelParse() {
        this.modelValue = this.viewValue
      },
      modelEmit() {
        this.$emit('input', this.modelValue);
        this.$emit('change', this.modelValue);
      },

      onChange(){
        this.modelParse()
        this.modelEmit()
      }
    }
  }
</script>

<style lang="stylus">
  .RbvInputAddress{
    .no-left-margin{
      margin-left 0 !important;
    }

    .country, .region{
      flex: 1;
    }

    .optional{
      float: right;
    }
  }
</style>

