/**
 * Created by DejanK on 12/28/2016.
 */
import angular from 'angular'
import './rb-tooltip.scss'
import rbAbsTooltip from './rb-abs-tooltip.cmp'

export default angular.module('rb.tooltip', [])
    .service('RbTooltipService', require('./rb-tooltip.service'))
    .component('rbTooltip', require('./rb-tooltip.cmp'))
    .directive('rbAbsTooltip', rbAbsTooltip )
