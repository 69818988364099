<template>
  <div class="DocumentView travel-destinations-list">
    <div class="DocumentViewContainer">
      <div class="DocumentViewHeading">
        <document-heading-title :props="{title:'Travel Destinations And Rate Caps'}" />

        <div class="DocumentViewHeadingActions">
          <button
            class="aBtn asLarge atDark"
            @click="bidManager"
          >
            Bid Manager
          </button>

          <button
            class="aBtn asLarge atDark"
            data-icon="print"
            @click="print"
          >
            Print
          </button>
        </div>
      </div>

      <div class="DocumentViewContentContainer">
        <div id="document-top" class="DocumentViewContent">
          <div class="tdViewListTable" ref="table">
            <div class="tdViewListTableHead">
              <div class="tdViewListTableRow">
                <div class="tdViewListTableHeadingCell msGridColumn9">
                  <div>Country</div>
                  <div class="rbTooltip simple" tooltip="placement:bottom-start">Travel Destination Country</div>
                </div>

                <div class="tdViewListTableHeadingCell msGridColumn11">
                  <div>State/Region</div>
                  <div class="rbTooltip simple" tooltip="placement:bottom-start">Travel Destination State/Region</div>
                </div>

                <div class="tdViewListTableHeadingCell msGridColumn13">
                  <div>City</div>
                  <div class="rbTooltip simple" tooltip="placement:bottom-start">Travel Destination City</div>
                </div>

                <div class="tdViewListTableHeadingCell msGridColumn3">
                  <div>Destination name</div>
                  <div class="rbTooltip simple" tooltip="placement:bottom-start">Travel Destination Name</div>
                </div>
              </div>
            </div>
            <div v-if="!destinations">
              <div class="tdViewListTableMessage">
                <rb-loading label="Please wait..."></rb-loading>
              </div>
            </div>

            <div v-else-if="destinations.length === 0">
              <div class="tdViewListTableMessage">
                <span>No Travel Destinations Found!</span><br />
              </div>
            </div>

            <div v-else class="tdViewListTableBody">
              <div class="tdViewListTableRowContainer" v-for="destination in destinations" :key="destination.id">
                <div class="tdViewListTableRow">
                  <div class="tdViewListTableCell msGridColumn9"><span>{{destination.country}}</span></div>
                  <div class="tdViewListTableCell msGridColumn11"><span>{{destination.region}}</span></div>
                  <div class="tdViewListTableCell msGridColumn13"><span>{{destination.city}}</span></div>
                  <div class="tdViewListTableCell msGridColumn3">
                    <span>{{destination.name}}</span>
                    <div class="rbTooltip simple">
                      <b>Full Address:</b> <span>{{destination.location.fullAddress}}</span><br>
                      <b>Estimated Room Nights:</b> <span>{{destination.estimatedRoomNights}}</span><br>
                      <b>Estimated Spend:</b> <span>{{ destination.estimatedSpend }}</span><br>
                    </div>
                  </div>
                </div>
                <div class="tdViewListRateCapsTable" v-if="destination.caps && destination.caps.length > 0">
                  <div class="tdViewListRateCapsTableRow head">
                    <div class="tdViewListRateCapsTableCol">
                      Season Start - End
                    </div>
                    <div class="tdViewListRateCapsTableCol" v-for="(cap, index) in destination.caps" :key="index">
                      {{cap.startDate | date}} - {{cap.endDate | date}}
                    </div>
                  </div>
                  <div class="tdViewListRateCapsTableRow">
                    <div class="tdViewListRateCapsTableCol">
                      Rate Caps
                    </div>
                    <div class="tdViewListRateCapsTableCol" v-for="(cap, index) in destination.caps" :key="index">
                      <span class="rate">{{ cap.rate }}</span> {{destination.currency}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getRfpId, bidManager, getTravelDestinations} from './services/travel-destinations.service';
  import rbLoading from 'vRoot/_core/RbvLoading.vue';
  import moment from 'moment';
  import print from 'root/shared/tools/printer.service';
  import './travel-destination-caps-list.scss';
  import DocumentHeadingTitle from 'root/rfp-hotel/main/rfp/rbs-document-heading-title/DocumentHeadingTitle.vue';

  const printStyles = `
        .rbTooltip{
            display: none;
        }

        .tdViewListTable {
            flex: 1 0 100%;
        }

        .tdViewListTableHead, .tdViewListPropertiesTableHead {
            position: sticky;
            top: 0;
            z-index: 5;
            font-size: 14px;
            color: #546E7A;
        }

        .tdViewListTableBody, .tdViewListPropertiesTableBody {
            font-size: 14px;
        }

        .tdViewListTableCell, .tdViewListPropertiesTableCell {
            vertical-align: middle;

            text-overflow: ellipsis;
            white-space: nowrap;
            text-shadow: inherit;
            overflow: hidden;
        }

        .tdViewListTableHead {
            border-top: 1px solid #D9E0E3;
            border-bottom: 1px solid #D9E0E3;
            background: #ECEFF1;
        }

        .tdViewListTableBody {
            background: white;
            color: #455a64;
        }

        .tdViewListTableRow {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 2fr;
            grid-column-gap: 5px;

            display: -ms-grid;
            -ms-grid-columns: 1fr 5px 1fr 5px 1fr 5px 2fr;

            padding: 0 20px;
        }

        .tdViewListTableHeadingCell {
            vertical-align: middle;
            line-height: 42px;
            height: 42px;
        }

        .tdViewListTableCell {
            line-height: 42px;
            height: 42px;
            font-size: 14px;
        }

        .tdViewListTableBody .tdViewListTableRowContainer {
            border-bottom: 1px solid #ECEFF1;
        }

        .tdViewListTableBody .tdViewListTableRowContainer:not(.opened) .tdViewListTableRow:hover {
            background: #FBFBFC;
        }

        .tdViewListRateCapsTable{
            border: 1px solid #cfd8dc;
            border-radius: 4px;
            background: #e7ebed;
            margin: 0 20px 5px;
            overflow: hidden;
            font-size: 12px;
            color: #78909c;
            min-height: 54px;
        }

        .tdViewListRateCapsTableRow{
            display: flex;
            line-height: 2.5;
            min-height: 28px;
        }

        .tdViewListRateCapsTableRow.head{
            border-bottom: 1px solid #e7ebed;
        }

        .tdViewListRateCapsTableCol{
            width: 153px;
            border-right: 1px solid #e7ebed;
            background: #fff;
            padding: 0 10px;
            box-sizing: border-box;
        }
        
        .tdViewListRateCapsTableCol .rate{
            font-weight: bold;
        }
    `;

  export default {
    name: 'travel-destinations-list',
    components: {DocumentHeadingTitle, rbLoading },
    asyncComputed: {
      destinations() {
        return getTravelDestinations(getRfpId());
      },
    },
    methods: {
      bidManager() {
        bidManager(getRfpId());
      },
      print() {
        print(this.$refs.table, printStyles)
      }
    },
    filters: {
      date(value) {
        return moment(value).format('MM.DD.YYYY');
      }
    }
  }
</script>
