<Container {width} overflow="{allowOverflow}">
  <div class="Heading">
    <div class="HeadingText"><slot name="heading" /></div>
    {#if close}
      <button class="aBtn asLarge asIcon Custom"
              on:click={() => close()}
              data-icon="clear" />
    {/if}
  </div>

  <slot />
</Container>

<script>
  import Container from './Container.modal.svelte';

  export let
    width = '500px',
    allowOverflow = false,
    close;
</script>


<style lang="stylus">.Heading {
  display: flex;
}
.HeadingText {
  flex: 1 1 100%;
}
.Custom {
  border-color: transparent !important;
}
</style>
