import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';

export default {
  template: '<div></div>',
  bindings: {
    bid: '<',
    onChanged: '&'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(this, SvelteNgComponent(import('./HotelRfpBidSpecifications.svelte'), $element[0], () => ({
    bid: vm.bid,
  }), {
    change: ev => vm.onChanged({bid: ev.detail})
  }));
}
