/**
 * Created by DejanK on 11/8/2016.
 */

'use strict';

module.exports = {
  template: templateFn,
  bindings: {
    model: '=',
    country: '='
  },
  controller: Ctrl
};

templateFn.$inject = ['$attrs', 'RbInputTemplate'];
function templateFn($attrs, RbInputTemplate){
  let options = angular.extend(RbInputTemplate.readDefaultAttributes($attrs), {
    maxlength: 100
  });

  return RbInputTemplate.ngRequiredTextTemplate(options);
}

Ctrl.$inject = ['$scope', '$element'];
function Ctrl($scope, $element){
  let vm = this;
  shouldWatchCountry();

  function shouldWatchCountry(){
    vm.required = $element.attr('required') === 'required';
    if($element.attr('country')){
      watchCountry();
    }
  }

  function watchCountry() {
    $scope.$watch(
      function countryWatcher() {
        return vm.country;
      },
      function handleChange(val) {
        if(val !== 'US') {vm.model = undefined}
      }
    );
  }
}
