import angular from 'angular'
import Servers from '../shared-ng/servers'
import Auth from '../auth'
import NgSharedUtils from '../shared-ng/utils'
import Notifications from '../ui-ng/notifications'
import NgForm from '../ui-form-ng'
import RateLoadingInformation from '../entity/rate-loading-information'

import Dialogs from './dialogs'

import AccountRepository from './account.repo'

export default angular.module('rb.user-account', [
  Servers, Auth, NgSharedUtils, Notifications, NgForm, RateLoadingInformation
])
  .config(Dialogs)
  .service('AccountRepository', AccountRepository)
