import Vue from 'vue';
import noop from 'lodash/noop';

export default {
  template: '<div></div>',
  bindings: {
    bid: '<',
    options: '<'
  },
  controller: Ctrl,
}

Ctrl.$inject = ['$element', 'CurrentUser'];
function Ctrl($element, currentUser){
  const
    vm = this;

  let
    vueComponent = null;

  Object.assign(this, {

    $onInit () {
      import( /* webpackChunkName: 'TheHotelRateQuickBidForm' */ 'vRoot/rfp-hotel/bid/rate-quick/TheRateQuickQuestionnaireForm.vue')
        .then( async (imported) => {
          const user = await currentUser.get();

          vueComponent = new Vue({
            el: $element[0],
            render ( h ) {
              return h( imported.default, {
                props: {
                  bid: vm.bid,
                  user,
                  options: vm.options
                },
              });
            },
          });
        })
        .catch( noop );
    },

    $onDestroy () {
      if(vueComponent) {
        vueComponent.$destroy();
      }
    },
  });
}
