import angular from 'angular'

import Dialog from '../dialog'
import Components from '../components'
import Servers from '../../shared-ng/servers'

import Dialogs from './dialogs'
import States from './states'
import MessagesService from './messages.service'

export default angular.module('rb.notifications', [ Components, Servers, Dialog ])
  .config(Dialogs)
  .config(States)
  .service('MessagesService', MessagesService)
