export const
  PAGE_HOME = 'Home',
  PAGE_HOME_CORPORATIONS = 'homeCorporations',
  PAGE_HOME_AGENCIES = 'homeAgencies',
  PAGE_HOME_HOTELS = 'homeHotels',
  PAGE_HOME_CHAINS = 'homeChains',
  PAGE_HOME_PRODUCTS_COMPETITIVE_BIDS = 'homeCompetitiveBids',
  PAGE_HOME_PRODUCTS_RATE_AUDITING = 'homeRateAuditing',
  PAGE_HOME_PRODUCTS_PROGRAM_MANAGEMENT = 'homeProgramManagement',
  PAGE_HOME_ABOUT_US = 'homeAboutUs',
  PAGE_TERMS = 'terms',
  // PAGE_PUBLIC_CONTACT_US = 'contactUs',
  // PAGE_PUBLIC_CUSTOM_RFP = 'customRfp',
  PUBLIC_INVITATIONS_VIEW_PAGE_NAME = 'publicInvitationsView',
  PAGE_COMPANY_PROFILE = 'company-profile',
  PAGE_RFP_MANAGER_COMPANY_PROFILE = 'rfp-manager-company-profile',
  PAGE_CURRENT_USER_PROFILE = 'user.profile',
  PAGE_HOTEL_DIRECTORY = 'hotelDirectory',
  PAGE_SELECT_RFP_TEMPLATE = 'createRfp.selectRfpTemplate',
  PAGE_DUPLICATE_RFP = 'duplicateRfp.select-template',
  PRICES = 'prices',
  SIGN_UP = 'signUp',
  EMAIL_ADDRESS_VERIFICATION_PAGE = 'emailAddressVerification',
  SIGN_IN = 'signIn',
  DASHBOARD = 'dashboard',
  BID_MANAGER = 'bidManager',
  PAGE_RFP_MANAGER = 'rfpManager',
  HOTEL_RFP_SPECIFICATIONS = 'hotelRfp.rfp.specifications',
  HOTEL_RFP_COMPETITIVE_BIDS = 'hotelRfp.rfp.competitiveBids',
  HOTEL_RFP_COMPETITIVE_BIDS_PREVIEW = 'publicCompetitiveBidLetterPreview',
  HOTEL_RFP_COMPETITIVE_BIDS_RESPONSE = 'hotelRfp.bid.competitiveBidsResponse',
  HOTEL_RFP_COMPETITIVE_BIDS_VIEW = 'hotelRfp.bid.competitiveBidView',
  HOTEL_RFP_MANUAL_ENTRY = 'hotelRfp.bid.manualEntry',
  SEND_FINAL_AGREEMENT_STATE = 'hotelRfp.bid.finalAgreement.send',
  HOTEL_RFP_BID_PREVIEW = 'hotelRfp.bid.preview',
  HOTEL_RFP_BID_RESPONSE = 'hotelRfp.bid.response',
  HOTEL_RFP_BID_UPDATE_RESPONSE = 'hotelRfp.bid.updateResponse',
  HOTEL_RFP_BID_SPECIFICATIONS = 'hotelRfp.bid.specifications',
  NEGOTIATED_SAVINGS_REPORT = 'hotelRfp.rfp.reports.negotiatedSavings',
  PAGE_USER_BANNED = 'userBanned',
  PAGE_TOKEN_USER_ACCOUNT_MANAGER = 'tokenUserAccountManager',
  PAGE_TOKEN_USER_ACCOUNT_MANAGER_ADD_ACCOUNT = 'tokenUserAccountManagerAddAccount',
  PAGE_USER_ACCOUNT_MANAGER = 'userAccountManager',
  PAGE_USER_ACCOUNT_MANAGER_ADD_ACCOUNT = 'userAccountManagerAddAccount',
  NO_BID_MANAGER_VIEW_PAGE_STATE = 'account.no-bm-view',
  CHAIN_PORTAL_STATE = 'hotelRfp.bids.chainPortal',
  CHAIN_PORTAL_HOTEL_PROFILE_STATE = 'hotelRfp.bids.hotelProfile',
  CHAIN_PORTAL_ALL_HOTELS_STATE = 'hotelRfp.bids.allHotels',
  CHAIN_PORTAL_OVERDUE_BIDS_STATE = 'hotelRfp.bids.overdue',
  CHAIN_PORTAL_HOTEL_REQUEST = 'chainPortalHotelRequests',
  CHAIN_PORTAL_EDIT_HOTEL_REQUEST = 'chainPortalHotelRequests.editRequest',
  CHAIN_PORTAL_CREATE_REQUEST_TO_ADD_HOTEL = 'chainPortalHotelRequests.createAddRequest',
  CHAIN_PORTAL_UPLOAD_HOTELS_STATE = 'hotelRfp.bids.uploads',
  HOTEL_RFP_DESTINATION_ADD_HOTELS_BY_SABRE_CODE = 'hotelRfp.rfp.destinations.addBySabre',
  HOTEL_RFP_DESTINATION_VIEW = 'hotelRfp.rfp.destinations.view',
  PAGE__HOTEL_BID = 'hotelBids',
  PAGE__HOTEL_BID__DECLINE = `${PAGE__HOTEL_BID}.decline`,
  PAGE__HOTEL_BID__RESPOND = `${PAGE__HOTEL_BID}.respond`
