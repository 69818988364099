/**
 * Created by DejanK on 1/11/2017.
 *
 * rbMinDyn
 *
 * Validates that number is not less of given value (with interpolation)
 */
export default rbMinDyn

function rbMinDyn() {
  return {
    require: 'ngModel',
    link: (scope, e, attrs, ngModelCtrl)=>{
      scope.$watch(attrs.rbMinDyn, newValue => {
        const min = newValue

        if(isNaN(min)){
          ngModelCtrl.$validators.rbMinDyn = () => true
        } else {
          ngModelCtrl.$validators.rbMinDyn = (modelValue, viewValue)=>{
            const v = parseFloat(modelValue || viewValue);
            return isNaN(v) || v >= min;
          }
        }
      })
    }
  }
}
