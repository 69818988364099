<script>
  /* eslint-disable no-unused-vars,no-unused-labels */
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import DropDownButton from 'root/rfp/reports/components/DropDownButton.svelte';
  import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
  import moment from 'moment';

  // eslint-disable-next-line prefer-const
  export let cancel;

  const
    yearsOptions = createYearsOptions(),
    bidTypesOptions = createBidsTypesOptions(),
    reportTypeOptions = createReportTypesOptions();

  let programYear = yearsOptions[0],
    bidType = bidTypesOptions[0].value,
    reportType = reportTypeOptions[0].value;

  function createYearsOptions(){
    const
      cy = moment.utc().add(7, 'M').year(), // After May, add next year as option for ' and later'
      options = [createYearOption(cy, 'GTE', `${cy} and later`)];

    for(let py = cy-1; py >= 2018; py-- ){
      options.push(createYearOption(py))
    }

    return options;

    function createYearOption(year, comparisonOp = 'EQ', label = ''){
      const o =  {
        value: `${comparisonOp}:${year}`,
        label: `${label || year}`
      };
      o.action = () => {
        programYear = o;
      };
      return o
    }
  }

  function createBidsTypesOptions(){
    return [
      createBidsTypesOption('CHAIN', 'Chain Bids'),
      createBidsTypesOption('DIRECT', 'Direct to Property'),
      createBidsTypesOption('COMPETITIVE', 'Competitive Bids'),
      createBidsTypesOption('ALL', 'All Bids'),
    ];

    function createBidsTypesOption(value, label){
      return {
        value,
        label,
        action: () => {
          bidType = value;
        }
      }
    }
  }

  function createReportTypesOptions(){
    return [
      createReportTypesOption('DASHBOARD:CHAIN_PORTAL_BIDS_STATES', 'Bids States Report'),
      createReportTypesOption('DASHBOARD:CHAIN_PORTAL_BIDS_STATES_DENORMALIZED', 'Bids States List'),
      createReportTypesOption('DASHBOARD:HOTEL_RFP:GBTA:EXCEL', 'GBTA'),
      createReportTypesOption('DASHBOARD:HOTEL_RFP:GBTA_WITH_STATUS:CSV', 'GBTA with Bid State')
    ];

    function createReportTypesOption(value, label){
      return {
        value,
        label,
        action: () => {
          reportType = value;
        }
      }
    }
  }

  function exportData(){
    const
      [yearComparison, year] = programYear.value.split(':'),
      config = { year, yearComparison, type: bidType };
    notificationUtils().onLoadWithNotification(() => mainAPI().chainPortalExport(reportType, config));
  }

</script>


<ClosableModal close="{cancel}">
  <div slot="heading">
    <div class="Header">
      <div class="HeaderTitle">
        Export Data
      </div>
    </div>
  </div>

  <div class="Container">

    <div class="Options">
      <div class="Label Inline">Program Year:</div>
      <DropDownButton
        actions="{yearsOptions}"
        execute={({ action }) => action()}
        label="{programYear.label}"
        asLight={true}
      />
    </div>

    <div class="Options">
      <div class="Label">Bid Type:</div>
      {#each bidTypesOptions as bidTypeOption}
        <div class="Option"
             data-icon-before="{bidType === bidTypeOption.value ? 'radio_button_checked' : 'radio_button_unchecked'}"
             on:click={bidTypeOption.action}
        >
          {bidTypeOption.label}
        </div>
      {/each}
    </div>

    <div class="Options">
      <div class="Label">Report Type:</div>
      {#each reportTypeOptions as reportTypeOption}
        <div class="Option"
             data-icon-before="{reportType === reportTypeOption.value ? 'radio_button_checked' : 'radio_button_unchecked'}"
             on:click={reportTypeOption.action}
        >
          {reportTypeOption.label}
        </div>
      {/each}
    </div>
  </div>

  <ModalButtons
    yesIcon="file_download"
    yesLabel="Export"
    yesAction="{exportData}"
    noAction="{cancel}"
    wide="{true}"
  />
</ClosableModal>

<style lang="stylus">.Header {
  padding-left: 14px;
  display: flex;
  height: 63px;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  line-height: 20px;
  color: #546e7a;
}
.HeaderTitle {
  font-weight: bold;
  font-size: 16px;
  color: #455a64;
}
.Label {
  font-size: 16px;
  color: #455a64;
}
.Inline {
  display: inline-block;
}
.Options {
  font-size: 14px;
  line-height: 20px;
  color: #546e7a;
  margin: 15px;
}
.Option {
  cursor: pointer;
  display: flex;
  margin-left: 15px;
  margin-top: 10px;
  opacity: 0.8;
}
.Option:before {
  margin-right: 10px;
}
.Option:hover {
  opacity: 1;
}
</style>
