import HTML from './rb-chain-nag.html';
import './../nag.scss';
import Ctrl from '../nag.ctrl';

export default {
  bindings: {
    rbClick: '&',
    supplier: '<',
    buyer: '<'
  },
  template: HTML,
  controller: Ctrl
}