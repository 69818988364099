<template>
  <questionnaire-view-section-group
    v-if="template.type === 'GROUP'"
    :model="model"
    :response="response"
    :template="template"
    @mounted="onSectionMounted"
  />

  <questionnaire-view-section-table
    v-else-if="template.type === 'TABLE'"
    :config = "config"
    :globals = "globals"
    :model="model"
    :response="response"
    :template="template"
    @mounted="onSectionMounted"
  />

  <questionnaire-hotel-rfp-rate-grid
    v-else-if="template.type === 'HOTEL_RFP_RATE_GRID'"
    :config = "config"
    :globals = "globals"
    :model="model"
    :response="response"
    :template="template"
    @mounted="onSectionMounted"
  />
</template>

<script>
  import QuestionnaireViewSectionGroup from './QuestionnaireViewSectionGroup.vue'
  import QuestionnaireViewSectionTable from './QuestionnaireViewSectionTable.vue'
  import QuestionnaireHotelRfpRateGrid from './HotelRfpQuestionnaireViewSectionRateGrid.vue'

  export default {
    name: 'rb-questionnaire-view-section',

    components: {
      QuestionnaireViewSectionGroup,
      QuestionnaireViewSectionTable,
      QuestionnaireHotelRfpRateGrid
    },

    props: [ 'config', 'globals', 'model', 'response', 'template' ],

    methods: {
      onSectionMounted () {
        this.$emit('mounted') }
    }
  }
</script>
