<template>
  <div :class="$style.filterBar" class="filter-bar">
    <div :class="$style.filters">
      <chains-filter :class="$style.filter" class="filter"
        :filter="filters.chains"
        :chains="chains"
        @filtersChanged="chainFiltersChanged"/>
      <distance-filter :class="$style.filter" class="filter"
        :distance="filters.maxDistance"
        @distanceChanged="distanceFilterChanged"/>
    </div>
  </div>
</template>

<script>
  import DistanceFilter from './distance-filter/DistanceFilter.vue';
  import ChainsFilter from './chains-filter/ChainsFilter.vue';
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue';

  export default {
    name: 'rb-travel-destinations-map-filter-bar',

    mixins: [ RbEventsMixin ],

    components: { DistanceFilter, ChainsFilter },

    props: {
      filters: {
        type: Object,
        required: true
      },
      chains: {
        type: Array,
        required: true
      }
    },

    methods: {
      chainFiltersChanged(event) {
        this.emitFiltersChanged({
          ...this.filters,
          chains: event.filters
        })
      },
      emitFiltersChanged( filters ) {
        this.$rbEmit({
          name: 'filtersChanged',
          filters
        });
      },
      distanceFilterChanged(event) {
        const filters = { ...this.filters }
        filters.maxDistance.value = event.value
        this.emitFiltersChanged(filters)
      }
    }
  }
</script>

<style lang="stylus" module >

  .filterBar {
    display flex
    align-items center
  }

  .filters {
    flex: 1 0 auto
    display flex
    width 100%
    flex-wrap wrap
  }

  .filter {
    margin 7px 0
    flex 1
    max-width 420px;
    min-width 230px;
    display flex;
    align-items: center;
    justify-content space-between
  }
</style>
