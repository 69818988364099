import CopyBidLinkModal from './CopyBidLinkModal.svelte';
import {SvelteDialog} from 'root/v-app/rbServices';
import noop from 'lodash/noop';


export default function CopyBidLink(){
  return function (selectedBidsReport){
    return {
      label: 'Copy Bid Link',
      icon: 'content_copy',
      isAvailable: () => selectedBidsReport.bids.length === 1,
      action: () => {
        SvelteDialog.show(CopyBidLinkModal, {bid: selectedBidsReport.bids[0]})
          .catch(noop);
      }
    };
  }
}
