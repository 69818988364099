import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent';
import Component from './QuestionnaireFormNavigation.svelte';

export default {
  template: '<div></div>',
  bindings: {
    saveDraft: '&',
    attachments: '<',
    stateName: '<',
    showCoverLetter: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(this, SvelteNgComponent(Component, $element[0], () => ({
    attachments: vm.attachments,
    stateName: vm.stateName,
    showCoverLetter: vm.showCoverLetter
  }), {
    'leaving': () => { if(vm.saveDraft) { vm.saveDraft(); }}
  }));
}