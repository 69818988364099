/**
 * Created by DejanK on 5/22/2017.
 */
import { noop } from 'lodash'
import HTML from './ask-to-add-account.html'
const NAME = 'askToAddAccount'

export { NAME, HTML, AskToAddAccountDialogController as Controller }

AskToAddAccountDialogController.$inject = ['NotificationUtils', 'Invitation', 'InvitationService', 'rbDialog', 'CurrentUser'];
function AskToAddAccountDialogController(notificationUtils, invitation, InvitationService, dialog, currentUser){
  const vm = this;

  vm.accountName = ''
  vm.username = ''
  vm.jobTitle = ''

  vm.cancel = dialog.cancel;
  vm.submit = submit;

  $onInit();

  function $onInit(){
    vm.accountName = invitation.accountName
    vm.jobTitle = invitation.jobTitle
    vm.username = invitation.username
  }

  function submit(){
    notificationUtils.onSave(() => { return InvitationService.addAccount({
      emailAddress: invitation.emailAddress,
      token: invitation.token,
      jobTitle: vm.jobTitle
    }) })
      .then(currentUser.refresh)
      .then(dialog.hide, noop)
  }
}
