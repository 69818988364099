export default Repo

Repo.$inject = ['MainServer', 'AuthServer', '$q'];
function Repo(mainServer, authServer, $q){
  const $repo = this;

  $repo.getInvitationDetails = token => token?
    mainServer.get(`account-users/invitation-details/${token}`): $q.reject({status: 404});

  $repo.markInvitationAsUsed = token => token?
    authServer.put(`invitation/${token}/used`): $q.reject({status: 404});

  $repo.handleAgentInvitation = data => data? mainServer.post(`account-users/apply-invitation/`, data): $q.reject({status: 500});
}
