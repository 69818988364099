{#if nullSafeAttachments.length}
  <div id="ViewAttachments" class="Container">
    <div class="Title">Attachments:</div>

    {#each nullSafeAttachments as a}
      <button class="aBtn asSmall atLight asLink" data-icon="attach_file" on:click={() => download(a.id)}>
        <span>{a.name}</span>
      </button>
    {/each}
  </div>
{/if}

<script>
  import downloadAttachment from 'root/rfp-hotel/attachments/download-attachment.service';
  import showProgressAndHandleErrors from './show-progress-and-handle-errors.js';

  export let
    token,
    attachments = [];

  let nullSafeAttachments;

  $: nullSafeAttachments = attachments || [];

  function download(attId){
    showProgressAndHandleErrors(() => downloadAttachment(attId, token), 'Download');
  }
</script>

<style lang="stylus">.Container {
  border: 1px dashed #cfd8dc;
  border-left: 0;
  border-right: 0;
  padding: 12px;
}
.Title {
  font-weight: bold;
  font-size: 14px;
  color: #455a64;
  height: 20px;
  line-height: 20px;
}
.aBtn {
  margin: 0 5px 0 0;
}
</style>