<template>
  <div>
    <div :class="$style.top">
      <img
        :src="logo"
        alt="directory logo"
        :class="$style.logo"
      >
      <div :class="{[$style.header]: true, [$style.hover]: !previewMode}">
        <div
          v-if="!editing.header"
          :class="$style.wrapper"
        >
          <span :class="$style.full">{{ header }}</span>
          <i
            v-if="!previewMode"
            class="material-icons"
            :class="$style.clickable"
            @click="toggleEdit('header')"
          >edit</i>
        </div>
        <text-editor
          v-else
          name="header"
          :value="header"
          :class="$style.headerEditorContainer"
          max="100"
          :classes="$style.headerEditor"
          placeholder="Your hotel directory header text goes here"
          @save="updateHeader"
          @clear="toggleEdit('header')"
        />
      </div>
    </div>
    <div
      v-if="canShowSubHeader"
      :class="{[$style.subheader]: true, [$style.hover]: !previewMode}"
    >
      <div
        v-if="!editing.subHeader"
        :class="$style.subContent"
      >
        <span
          v-if="subHeader"
          :class="$style.full"
        >{{ subHeader }}</span>
        <span
          v-else
          :class="$style.fullWithoutContent"
        >Your sub header text goes here</span>
        <i
          v-if="!previewMode"
          class="material-icons"
          :class="$style.clickable"
          @click="updateShowSubHeader"
        >delete</i>
        <i
          v-if="!previewMode"
          class="material-icons"
          :class="$style.clickable"
          @click="toggleEdit('subHeader')"
        >edit</i>
      </div>
      <text-editor
        v-else
        name="subHeader"
        :value="subHeader"
        max="250"
        placeholder="Your sub header text goes here"
        @save="updateSubHeader"
        @clear="toggleEdit('subHeader')"
      />
    </div>
    <div
      v-if="canShowWarning"
      :class="{[$style.warning]: true, [$style.hover]: !previewMode}"
    >
      <div
        v-if="!editing.warning"
        :class="$style.subContent"
      >
        <i class="material-icons">warning</i>
        <span
          v-if="warning"
          :class="$style.full"
        >{{ warning || 'Your warning message goes here' }}</span>
        <span
          v-else
          :class="$style.fullWithoutContent"
        >Your warning message goes here</span>
        <i
          v-if="!previewMode"
          class="material-icons"
          :class="$style.clickable"
          @click="updateShowWarning"
        >delete</i>
        <i
          v-if="!previewMode"
          class="material-icons"
          :class="$style.clickable"
          @click="toggleEdit('warning')"
        >edit</i>
      </div>
      <text-editor
        v-else
        name="warning"
        :value="warning"
        max="250"
        :classes="$style.warningEditor"
        placeholder="Your warning message goes here"
        @save="updateWarning"
        @clear="toggleEdit('warning')"
      >
        <i
          v-if="editing.warning"
          slot="prefix"
          class="material-icons"
          :class="$style.warningEditorSign"
        >warning</i>
      </text-editor>
    </div>
  </div>
</template>

<script>
import TextEditor from './TextEditor.vue';
export default {
    name: 'HotelDirectoryHeader',
    components: { TextEditor },
    props: ['header', 'edit', 'subHeader', 'warning', 'logo', 'showSubHeader', 'showWarning', 'previewMode'],
    data(){
        return {
            editing: {
                header: false,
                subHeader: false,
                warning: false
            }
        }
    },
    computed: {
        canShowSubHeader() {
            if(this.showSubHeader && this.previewMode) {
                return this.subHeader
            }
            return this.showSubHeader;
        },
        canShowWarning() {
            if(this.showWarning && this.previewMode) {
                return this.warning
            }
            return this.showWarning;
        }
    },
    watch:{
        edit(value){
            this.toggleEdit(value);
        }
    },
    methods: {
        toggleEdit(name){
            this.editing[name] = !this.editing[name];
        },
        updateHeader(value) {
            this.update('header', value);
            this.toggleEdit('header');
        },
        updateSubHeader(value){
            this.update('subHeader', value);
            this.toggleEdit('subHeader');
        },
        updateWarning(value){
            this.update('warning', value);
            this.toggleEdit('warning');
        },
        updateShowSubHeader(){
            this.update('showSubHeader', !this.showSubHeader);
        },
        updateShowWarning(){
            this.update('showWarning', !this.showWarning);
        },
        update(key, value){
            this.$emit('update', {
                [key]: value
            });
        }
    }
}
</script>

<style lang="stylus" module>
    .top{
        display flex;
        min-height 74px;
        color #fff;
        margin-bottom 2px;
        box-sizing border-box;
        overflow hidden;
    }

    .logo{
        width 113px;
        border-top-left-radius 5px;
        border-bottom-left-radius 5px;
        border 1px solid #ECEFF1;
        background-color #fff;
        height 72px;
        object-fit contain;
        margin-right 5px;
    }

    .header{
        flex 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size 22px;
        background rgba(0,0,0,0.5);
        border-top-right-radius 5px;
        border-bottom-right-radius 5px;
        overflow hidden;
    }

    .warning{
        min-height: 36px;
        background-color: rgba(242, 60, 24, 0.8);
        color: #fff;
        width: 100%;
        box-sizing: border-box;
        font-size 13px;
        margin-bottom 2px;
        display flex;
        position relative;
        border-radius 5px;

        & > *{
            vertical-align middle;
        }
    }

    .hover{
        &:hover{
            background-color #313c41;
        }
    }

    .subContent{
        padding 6px;
        display flex;
        width 100%;
        align-items center;
    }

    .subheader{
        composes warning;
        background rgba(0,0,0,0.5);
    }

    .editMode{
        background-color #313c41;
    }

    .wrapper{
        display flex;
        flex-direction row;
        padding 5px;
    }

    .full{
        flex 1;
        word-break break-word;
    }

    .fullWithoutContent{
        composes full;
        color: #d3dbde78;
    }

    .clickable{
        cursor pointer;
    }
    .headerEditorContainer{
        min-height 65px;
    }

    .headerEditor{
        font-size: 22px !important;
    }

    .warningEditorSign{
        color rgba(242, 60, 24, 0.8);
        padding 20px 6px;
    }
</style>
