<template>
  <div v-show="!preview || this.model">
    <div class="questionnaireEditModuleHeader" :id="template.id">
      <label class="rbCheckbox">
        <input type="checkbox"
               class="rbCheckboxInput"
               :id="template.id"
               :name="template.id"
               :value="isSelected"
               @change="onChange($event.target.value)"
        />
        <span class="rbCheckboxLabel questionnaireEditModuleHeaderText" :class="checkBoxClasses">
           {{ template.name }}
          </span>
      </label>
    </div>
    <transition-group tag="div" name="questionnaire-section">
      <!--v-for="section in delayedCells" :key="section.id"-->
      <!--@mounted="onMounted"-->

      <questionnaire-edit-section
        v-for="cell in modelCells" :key="cell.id"
        :config="config"
        :model="getModel(cell.id)"
        :preview="preview"
        :template="cell"
        @questionAdded="addModuleAndForwardEvent"
        @questionRemoved="addModuleAndForwardEvent"
        @sectionAdded="addModuleAndForwardEvent"
        @sectionRemoved="addModuleAndForwardEvent"
        @questionRequiredChanged="addModuleAndForwardEvent"
        @tableAdded="addModuleAndForwardEvent"
        @tableRemoved="addModuleAndForwardEvent"
        @tableConfigure="addModuleAndForwardEvent"
        @userDefinedChanged="addModuleAndForwardEvent"
        @userDefinedRemoved="addModuleAndForwardEvent"
        @specialsChanged="addModuleAndForwardEvent"
      />
    </transition-group>
  </div>
</template>

<script>
  import RbEventsMixin from '../../_mixins/RbEventsMixin.vue'
  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  //  import QuestionnaireDelayedDisplayMixin from '../_mixins/QuestionnaireDelayedDisplayMixin.vue'
  import QuestionnaireEditSection from './QuestionnaireEditSection.vue'

  export default {
    name: 'rb-questionnaire-edit-module',

    mixins: [ RbEventsMixin, QuestionnaireMixin ],
//    _mixins: [ QuestionnaireMixin, QuestionnaireDelayedDisplayMixin ],

    components: { QuestionnaireEditSection },

    props: [ 'config', 'model', 'preview', 'template'],

    computed: {

      checkBoxClasses () {
        const classes = {
          preview: this.preview
        }
        classes[this.isSelected] = true
        return classes
      },

      modelCells () {
        return this.template.cells
      },

      isSelected () {
        const modelCells = this.model && this.model.cells, templateCells = this.template.cells
        return !modelCells || modelCells.length === 0 ? 'unchecked'
          : areAllNonLockedGroupsSelected() ? 'checked' : 'indeterminate'

        function areAllNonLockedGroupsSelected(){
          for(let i=0, l=templateCells.length; i<l; i++){
            const tg = templateCells[i]
            if(!tg.locked){
              const mg = modelCells[i]
              if(!mg || !mg.cells || mg.id !== tg.id || mg.cells.length !== tg.cells.length) { return false }
            }
          }
          return true
        }
      }
    },

    methods: {
      onChange (value) {
        !this.preview && this.$rbEmit({
          name: value === 'checked' ? 'moduleRemoved' : 'moduleAdded',
          moduleId: this.template.id,
          moduleTemplate: this.template
        })
      },

      addModuleAndForwardEvent ( event ) {
        this.$rbEmit( event, {
          moduleId: this.template.id,
          moduleTemplate: this.template
        })
      }
    }
  }
</script>

<style lang="stylus">

  .questionnaireEditModuleHeader {
    height: 27px;
    background: #455a64;
    margin: 8px 0 1px;
    padding: 0 15px;
  }

  .questionnaireEditModuleHeaderText.rbCheckboxLabel {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 26px;
    color: #fff;
    vertical-align middle

    padding: 0 4px 0 16px;

    &:before {
      width: 14px;
      height: 14px;
      top: 5px;
      border-radius: 2px;
    }

    &:after {
      width: 9px;
      top: 11px;
      left: 3px;
      border-color #455a64
    }

    &.preview {
      cursor default
      &:before, &:after {
        display none
      }
    }
  }

</style>
