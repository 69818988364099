import Vue from 'vue'
import { noop, debounce } from 'lodash'

import QuestionnaireForm from './QuestionnaireForm.component.vue'
import EventBus from 'vRoot/_mixins/EventBus'
import reviseResponseStore from 'root/rfp-hotel/main/rfp/rbs-rfp-details-navigation/revert-revise-response.store';

export default {
  template: '<div></div>',
  bindings: {
    questionnaire: '<',
    source: '<',
    unlocked: '<',
    onChange: '&'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'DataServer', '$scope']
function Ctrl($element, dataServer, $scope){
  const vm = this,
    getTemplatePromise = dataServer.getQuestionnaire(),
    questionnaire = {
      template: {},
      config: [],
      globals: {},
      model: {},
      response: {}
    },
    debouncedNotifyOfChange = debounce(notifyOfChange, 100)

  let vueComponent

  vm.errors = {}

  this.$onInit = () => {
    $scope.$on('vue-event', (ev, data) => EventBus.$emit(data.event, data.data));
  }

  this.$onDestroy = () => { vueComponent && vueComponent.$destroy() }

  this.$onChanges = ( changes ) => {
    if(changes.questionnaire && changes.questionnaire.currentValue) {
      vm.questionnaire = changes.questionnaire.currentValue
      questionnaire.config = vm.questionnaire.config
      questionnaire.globals = vm.questionnaire.globals
      questionnaire.model = vm.questionnaire.model
      questionnaire.response = vm.questionnaire[vm.source]

      getTemplatePromise
        .then(initVueComponent, noop)
    }
  }

  function initVueComponent(template){
    !vueComponent && initVue()

    function initVue(){
      questionnaire.template = vm.unlocked ? unlockTemplate(template) : template;
      setUpReviseResponseRevertFeature(questionnaire);
      vueComponent = new Vue({
        el: $element[0],
        data: {
          questionnaire: questionnaire
        },
        render: function (createElement) {
          return createElement(QuestionnaireForm, {
            props: { questionnaire: questionnaire },
            on: { change: debouncedNotifyOfChange }
          })
        }
      })
    }
  }

  function setUpReviseResponseRevertFeature(q){
    if(vm.unlocked) reviseResponseStore.initialize(toJson(q),
      originalQuestionnaire => {
        if(vueComponent){
          const copyOfOriginal = toJson(originalQuestionnaire);
          Object.keys(copyOfOriginal).forEach(key => Vue.set(questionnaire, key, copyOfOriginal[key]));
        }
      });
  }

  function unlockTemplate(template) {
    return {
      ...template,
      cells: unlockCells(template.cells)
    };

    function unlockCells(cells){
      return cells.map(c => c.cells ? {...c, cells: unlockCells(c.cells)} : {...c, readonly: c.id === 'PROPCODE'});
    }
  }

  function notifyOfChange({response, errors}){
    $scope.$timeout(()=> {
      const changes = toJson({response, errors});
      reviseResponseStore.processResponseChanges(changes.response.answers);
      vm.onChange({ event: { response: changes }})
    })
  }

  function toJson(o){
    return JSON.parse(JSON.stringify(o))
  }
}
