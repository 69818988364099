/**
 * Created by DejanK on 4/5/2017.
 */
import angular from 'angular';
import Dialogs from './dialogs';
import RfpHotelBidRepository from './rfp-hotel.bid.repo';
import rbsBidDetailsNavigation from './rbs-bid-details-navigation/rbs-bid-details-navigation.cmp';
import rbsQuestionnaireFormNavigation from './rbs-bid-details-navigation/rbs-questionnare-form-navigation.cmp';
import rbNgSvelteRfpBidSpecifications from './pages/specifications/rb-ng-svelte-rfp-bid-specifications.cmp';
import rbHotelRfpBidResponse from './pages/response/component/bid-response.cmp';
import rbHotelRfpStandardBidResponse from './pages/response/standard-bid-response/standard-bid-response.cmp';
import rbHotelRfpRateQuickBidResponse from './pages/response/rate-quick-bid-response/rate-quick-bid-response.cmp';
import rbHotelRfpBidStandardPreview from './pages/preview/standard-preview.cmp';
import rbHotelRfpBidRateQuickPreview from './pages/preview/rate-quick-preview.cmp';

export default angular.module('rb.rfpHotel.main.bid', [])
  .config(Dialogs)
  .service('RfpHotelBidRepository', RfpHotelBidRepository)
  .component('rbsBidDetailsNavigation', rbsBidDetailsNavigation)
  .component('rbsQuestionnaireFormNavigation', rbsQuestionnaireFormNavigation)
  .component('rbNgSvelteRfpBidSpecifications', rbNgSvelteRfpBidSpecifications)
  .component('rbHotelRfpBidResponse', rbHotelRfpBidResponse)
  .component('rbHotelRfpStandardBidResponse', rbHotelRfpStandardBidResponse)
  .component('rbHotelRfpRateQuickBidResponse', rbHotelRfpRateQuickBidResponse)
  .component('rbHotelRfpBidStandardPreview', rbHotelRfpBidStandardPreview)
  .component('rbHotelRfpBidRateQuickPreview', rbHotelRfpBidRateQuickPreview)
