import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';

export default {
  template: '<div></div>',
  bindings: {
    rfpName: '<'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(this, SvelteNgComponent(import('root/rfp-hotel/competitive-bids/manager/TheCompetitiveBidsManagerComponent.svelte'), $element[0], () => ({
    rfpName: vm.rfpName,
  })));
}
