<RbAuthHeader>
  <div slot="additionalControls">
    <slot />
  </div>

  <div slot="mainControls">

    {#if showBidManager}
      <a href="/" on:click|preventDefault|stopPropagation="{() => openState('bidManager', {viewId: null})}"
              class="aBtn asLarge CustomButton" data-icon="list_alt">Bid Manager</a>
    {/if}


    {#if isBuyer}
      <a href="/" on:click|preventDefault|stopPropagation="{() => openState('rfpManager')}"
         class="aBtn asLarge CustomButton" data-icon="all_inbox">RFP Manager</a>
    {/if}

    <a href="http://readybidnet.freshdesk.com" target="_blank"
       class="aBtn asLarge CustomButton" data-icon="help">Need Help?</a>
  </div>
</RbAuthHeader>


<script>
  import { $state as stateProvider, currentUser as currentUserProvider } from 'root/angular-injector-provider';
  import { onMount, onDestroy } from 'svelte';
  import RbAuthHeader from "./RbAuthHeader.svelte";

  export let showBidManager = true;

  let
    user = null,
    isBuyer = false,
    unwatchUser;

  $: if (user) {
    isBuyer = user.isBuyer('HOTEL_RFP');
  }

  onMount(() => { unwatchUser = currentUserProvider().watch(u => user = u) });
  onDestroy(() => { if(unwatchUser) unwatchUser() });

  function openState(stateName, params) {
    stateProvider().go(stateName, params, {reload: true});
  }
</script>


<style lang="stylus">.aBtn.asLarge.CustomButton {
  background: #313c42;
  border-radius: 2px;
  color: #90a4ae;
  height: 36px;
  font-size: 13px;
  border: 1px solid #313c42;
  text-decoration: none;
  margin-top: 10px;
}
.aBtn.asLarge.CustomButton:hover {
  color: #cfd8dc;
}
.aBtn.asLarge.CustomButton:focus {
  border-color: #00b8ab;
}
</style>
