<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header">
      <div v-if="!user">Add New User</div>
      <div v-else>Edit User Details</div>
      <div :class="$style.subHeader">
        Please fill out the form below, we will send the link to your user.
      </div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
    >
      <div :class="$style.group">
        <rb-input
          :class="{
            [$style.groupItem]: true,
            rbInputEmpty: !data.user.firstName
          }"
          :for-id="'firstName'"
          :error="$showError('firstName')"
        >
          <span slot="label">First Name<i>*</i></span>
          <input
            id="firstName"
            v-model="data.user.firstName"
            v-validate="'required|max:100'"
            type="text"
            name="firstName"
            class="rbInput"
            autofocus
            maxlength="100"
            data-vv-as="First Name"
          >
        </rb-input>
        <rb-input
          :class="{
            [$style.groupItem]: true,
            rbInputEmpty: !data.user.lastName
          }"
          :for-id="'lastName'"
          :error="$showError('lastName')"
        >
          <span slot="label">Last Name<i>*</i></span>
          <input
            id="lastName"
            v-model="data.user.lastName"
            v-validate="'required|max:100'"
            type="text"
            name="lastName"
            class="rbInput"
            maxlength="100"
            data-vv-as="Last name"
          >
        </rb-input>
      </div>
      <div :class="$style.group">
        <rb-input
          :class="{ rbInputEmpty: !data.user.email, [$style.groupItem]: true }"
          :for-id="'email'"
          :error="$showError('email')"
        >
          <span slot="label">Email Address<i>*</i></span>
          <input
            id="email"
            v-model="data.user.email"
            v-validate="'required|email'"
            type="text"
            name="email"
            class="rbInput"
            data-vv-as="Email Adress"
          >
        </rb-input>
        <rb-input
          :class="{ [$style.groupItem]: true, rbInputEmpty: !data.user.phone }"
          :for-id="'phone'"
          :error="$showError('phone')"
        >
          <span slot="label">Phone Number</span>
          <input
            id="phone"
            v-model="data.user.phone"
            v-validate="'max:100'"
            type="text"
            name="phone"
            class="rbInput"
            maxlength="100"
            data-vv-as="Phone number"
          >
        </rb-input>
      </div>
      <div :class="$style.group">
        <rb-input
          :class="{
            [$style.groupItem]: true,
            rbInputEmpty: !data.user.directoryViewId,
            [$style.disabledSelect]: viewId
          }"
          :for-id="'directoryViewId'"
          :error="$showError('directoryViewId')"
        >
          <span slot="label">Directory View<i>*</i></span>
          <rb-select
            id="directoryViewId"
            v-model="data.user.directoryViewId"
            v-validate="'required'"
            class="simple"
            name="directoryViewId"
            data-vv-as="directory view"
            :disabled="!!viewId"
          >
            <rb-option
              v-for="view in views"
              :key="view.id"
              :value="view.id"
            >
              {{ view.name }}
            </rb-option>
          </rb-select>
        </rb-input>
        <rb-input
          :class="{
            [$style.groupItem]: true,
            rbInputEmpty: !data.user.type,
          }"
          :for-id="'userType'"
          :error="$showError('userType')"
        >
          <span slot="label">User Type<i>*</i></span>
          <span class="icon">
            <i class="material-icons">info</i>
            <div
              v-if="data.user.type === 'PASSIVE'"
              class="rbTooltip simple"
              :class="$style.typeTooltip"
              tooltip="placement:bottom-start"
            >
              A passive user can only view hotels on the directory
            </div>
            <div
              v-else
              class="rbTooltip simple"
              :class="$style.typeTooltip"
              tooltip="placement:bottom-start"
            >
              A power user can make hotel recommendations
            </div>
          </span>
          <rb-select
            id="userType"
            v-model="data.user.type"
            v-validate="'required'"
            class="simple"
            name="userType"
            data-vv-as="User type"
          >
            <rb-option value="PASSIVE">Passive User</rb-option>
            <rb-option value="ACTIVE">Power User</rb-option>
          </rb-select>
        </rb-input>
      </div>
      <div :class="$style.group">
        <rb-input
          :class="{
            [$style.groupItem]: true,
            rbInputEmpty: !data.user.jobTitle
          }"
          :for-id="'jobTitle'"
          :error="$showError('jobTitle')"
        >
          <span slot="label">Job Title</span>
          <input
            id="jobTitle"
            v-model="data.user.jobTitle"
            v-validate="'max:100'"
            type="text"
            name="jobTitle"
            class="rbInput"
            maxlength="100"
            data-vv-as="Job Title"
          >
        </rb-input>
        <rb-input
          :class="{
            [$style.groupItem]: true,
            rbInputEmpty: !data.user.department
          }"
          :for-id="'department'"
          :error="$showError('department')"
        >
          <span slot="label">Deparment</span>
          <input
            id="department"
            v-model="data.user.department"
            v-validate="'max:100'"
            type="text"
            name="department"
            class="rbInput"
            maxlength="100"
            data-vv-as="Department"
          >
        </rb-input>
      </div>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <template v-if="creating">
        <button
          class="rbButton"
          type="button"
          @click="cancel()"
        >
          Cancel
        </button>
        <button
          class="rbButton"
          type="button"
          @click="saveOnly"
        >
          <i class="material-icons">save</i>
          Save {{ onlySave? "" :"Only" }}
        </button>
        <button
          v-if="!onlySave"
          class="rbButton mainButton"
        >
          <i class="material-icons">send</i>
          Save And Send
        </button>
      </template>
      <template v-else>
        <button
          class="rbButton"
          type="button"
          @click="cancel()"
        >
          Cancel
        </button>
        <button
          v-if="!onlySave"
          class="rbButton mainButton"
        >
          <i class="material-icons">save</i>
          Save User
        </button>
      </template>
    </div>
  </modal-form>
</template>

<script>
import ModalForm from 'vRoot/_core/RbModalForm.vue';
import DialogMixin from 'vRoot/_mixins/DialogMixin.vue';
import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue';
import RbInput from 'root/v-components/_core/RbInput.vue';
import RbSelect from 'vRoot/_core/RbSelect.vue';

export default {
  name: 'SaveUserDialog',
  components: { RbInput, ModalForm, RbSelect },
  mixins: [RbFormMixin, DialogMixin],
  props: {
    user: {
      type: Object,
      default: null
    },
    views: {
      type: Array,
      required: true
    },
    viewId: {
      type: String,
      default: ''
    },
    onlySave: Boolean
  },
  data() {
    return {
      data: {
        user: {...this.user} || { directoryViewId: this.viewId, type: 'PASSIVE' },
        another: false,
        setMessageAsDefault: false,
        message: this.message,
        saveOnly: this.onlySave
      }
    };
  },
  computed: {
    creating() {
      return !this.user;
    }
  },
  methods: {
    onSubmit() {
      const data = { ...this.data };
      this.data.saveOnly = false;
      return data;
    },
    saveOnly() {
      this.data.saveOnly = true;
      this.$formSubmitHandler();
    }
  }
};
</script>

<style lang="stylus" module>

.dialog {
  min-width: 500px;
  overflow visible
}

.subHeader{
  font-size 12px;
  line-height 14pt;
  color #78909C;
  font-weight lighter;
}

.group{
  display: flex;
  justify-content space-between;
}

.groupItem{
  flex: 1;

  &+&{
      margin-left 5px;
  }
}

.sub{
  flex 1;
  display flex;
  align-items center;
  font-size 12px;
}

.sub i{
  color #90A4AE;
  padding-left 50px
}

.subEnd{
  composes sub;
  justify-content flex-end;
  color #90A4AE;
  font-weight bolder;
  i{
    padding 0;
  }
}

.message{
  position relative;
  font-size 12px;
}

.message label{
  position absolute;
  color: #91a4af;
  background #fff;
  top 0;
  left: 5px;
  padding: 0 5px;
  font-size: 10px;
}

.message textarea{
  height: 52px;
  width: calc(100% - 5px);
  border: 1px solid #91a4af;
  border-radius: 6px;
  margin: 10px 0;
  color: #91a4af;
  padding: 5px 10px;
  box-sizing: border-box;
}

.another{
  float left;
}

.disabledSelect {
  opacity .6;
}

.typeTooltip {
  width 200px;
}
</style>
