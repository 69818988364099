import agentInvitationTypeHandlerModule from './agent/agentInvitationsHandler';
import supplierContactChangehandlerModule from './supplier-contact-change/supplierContactChangeInvitationsHandler';
import bidResetInvitationsHandlerModule from './bid-reset/bidResetInvitationsHandlerModule';
import InvitationCheckComponent from './InvitationCheck.vue';

export default {
  loadModules: loadModules
}

function loadModules(){
  const availableModules = [];

  load(agentInvitationTypeHandlerModule);
  load(supplierContactChangehandlerModule);
  load(bidResetInvitationsHandlerModule);
  load({ id: 'INVITATION_CHECK', component: InvitationCheckComponent });

  return availableModules;

  function load(module){
    availableModules.push(module)
  }
}
