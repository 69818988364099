<template>
  <div
    :class="$style.grouperControl"
  >
    <div
      v-if="!anyLoading"
      @click="$emit('toggle', ids)"
    >
      <i
        v-if="allSelected"
        class="material-icons"
      >
        check_box
      </i>
      <i
        v-else-if="someSelected"
        class="material-icons"
      >
        indeterminate_check_box
      </i>
      <i
        v-else
        class="material-icons"
      >check_box_outline_blank</i>
    </div>
    <rbv-loading
      v-else
      rb-class="small"
    />
  </div>
</template>

<script>
import RbvLoading from 'vRoot/_core/RbvLoading.vue';
export default {
  name: 'HDBulkCheckBox',
  components: {RbvLoading},
  props: {
    ids: {
      type: Array,
      required: true
    },
    view: {
      type: Object,
      required: true
    },
    updatingList: {
      type: Array,
      required: true
    }
  },
  computed: {
    allSelected() {
      return this.ids.every(id => !this.view.excludedBids.includes(id));
    },
    someSelected() {
      return (
        this.ids.some(id => !this.view.excludedBids.includes(id)) &&
        !this.allSelected
      );
    },
    anyLoading() {
      return this.ids.some(id => this.updatingList.includes(id));
    }
  }
}
</script>

<style lang="stylus" module>
  .grouperControl{
    cursor pointer;
    width: 18px;
    margin-right: 10px;
  }
</style>
