<template>
  <div class="account-manage-companies">
    <div
      v-if="!loaded"
      class="layout-column flex-center loader"
    >
      <rbv-loading label="Loading, please wait..." />
    </div>
    <template v-else>
      <div class="header flex column">
        <div class="flex row">
          <div class="logo">
            <img
              v-if="userAccount && userAccount.account.logo"
              :src="logo"
              alt="Account Logo"
            >
            <div v-else>
              <i class="material-icons">business</i>
            </div>
          </div>
          <div class="flex column">
            <div class="title">
              {{ userAccount && userAccount.account.name }}
            </div>
            <div class="sub-title">
              {{ module.title }}
            </div>
          </div>
        </div>
        <div class="flex row controls">
          <div class="flex row">
            <dropdown-actions
              v-model="sortBy"
              class="light"
              :items="sorters"
              align="left"
            >
              <template slot="value">
                <i class="material-icons">sort</i>
                Sort By: <b>{{ sortBy.label }}</b>
              </template>
            </dropdown-actions>
          </div>

          <template v-if="isFromRfpManager">
            <button
              class="button"
              @click="openRfpManager"
            >
              <i class="material-icons">arrow_back</i>
              Back to RFP Manager
            </button>
            <button
              class="button"
              @click="openUsersAndPermissions"
            >
              <i class="material-icons">people</i>
              Users &amp; Permissions
            </button>
          </template>

          <button
            v-if="isAdmin"
            class="button create"
            @click="createCompany"
          >
            <i class="material-icons">playlist_add</i>
            Add New Company
          </button>
        </div>
      </div>
      <rows
        :accounts="sortedAndSearched"
        :user-account="userAccount || {}"
        :current-user-is-admin="isAdmin"
        @edit="edit"
        @editLogo="editLogo"
        @assignments="assignments"
        @drop="drop"
        @editPrimaryContact="editPrimaryContact"
        @hotelDirectory="hotelDirectory"
        @rateLoadingInformation="rateLoadingInformation"
      />
    </template>
  </div>
</template>

<script>
  import dropdownActions from 'vRoot/rfp-manager/core/rfp-manager-dropdown-actions.vue';
  import listUsersService from '../manage-users/services/account-manage-users-list.service';
  import manageCompaniesService from './services/manage-company.service';
  import RbvLoading from 'vRoot/_core/RbvLoading.vue';
  import rows from './components/rows.vue';
  import module from './AccountManageCompaniesModule';

  import { orderBy } from 'lodash';
  import { PAGE_RFP_MANAGER_COMPANY_PROFILE } from 'root/states';

  export default {
    name: 'AccountManageUsers',
    components: {dropdownActions, RbvLoading, rows},
    props: {
      state: {
        type: String,
        required: true
      },
      userAccount: {
        type: Object,
        default: () => ({}),
      },
      isAdmin: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      const sorters = [
        {
          id: 'nameDesc',
          label: 'Name A-Z',
          order: 'asc',
          key: 'name'
        },
        {
          id: 'nameAsc',
          label: 'Name Z-A',
          order: 'desc',
          key: 'name'
        }
      ];

      return {
        sorters,
        sortBy: sorters[0],
        module
      }
    },
    asyncComputed: {
      accounts() { return listUsersService.listAccounts().then(({data}) => data)}
    },
    computed: {
      logo() { return `/images/logos/${this.userAccount.account.logo}` },
      loaded() { return this.accounts && this.userAccount; },
      sorted() { return this.accountsToShow && orderBy(this.accountsToShow, [company => company.id === this.userAccount.accountId? -1: 1, this.sortBy.key], ['asc', this.sortBy.order])},
      sortedAndSearched() {
        return this.searchTerm? this.sorted.filter(account => {
          const searchTerm = this.searchTerm.toLowerCase();
          const matched = account.name.toLowerCase().indexOf(searchTerm) >= 0;
          return matched || account.primaryAgent.userDetails && account.primaryAgent.userDetails.fullName.toLowerCase().indexOf(searchTerm) > -1;
        }): this.sorted;
      },
      isFromRfpManager() { return this.state === PAGE_RFP_MANAGER_COMPANY_PROFILE },
      accountsToShow() { return this.accounts && this.userAccount && this.accounts.filter(({id}) => id !== this.userAccount.accountId)}
    },
    methods: {
      createCompany() {
        manageCompaniesService.createCompany(this.accounts, this.state).then(this.refreshCompanies)
      },
      refreshCompanies() {
        return listUsersService.listAccounts().then(({data}) => { this.accounts = data; });
      },
      edit(company) {
        manageCompaniesService.openCompanyProfile(company.id, this.state);
      },
      editLogo(account) {
        return manageCompaniesService.editLogo(account).then(this.refreshCompanies);
      },
      assignments(account) {
        return manageCompaniesService.manageAssignments(this.userAccount.accountId, account).then(this.refreshCompanies);
      },
      drop(account) {
        return manageCompaniesService.deleteTarget(this.userAccount.accountId, account).then(this.refreshCompanies);
      },
      editPrimaryContact(account) {
        return manageCompaniesService.editPrimaryContact(account).then(this.refreshCompanies);
      },
      openRfpManager() { manageCompaniesService.openRfpManager(); },
      openUsersAndPermissions() { manageCompaniesService.openUsersAndPermissions() },
      hotelDirectory({id}) {
        return manageCompaniesService.openHotelDirectory(id);
      },
      rateLoadingInformation({entityId}) {
        return manageCompaniesService.manageRateLoadingInformation(entityId);
      }
    }
  }
</script>

<style lang="stylus">
  .account-manage-companies{
    color #37474f;
    position relative;
    overflow auto;
    max-height: calc(100vh - 100px);

    .loader{
      height 100%;
    }

    .header{
      padding: 20px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      position: sticky;
      top: 0;
      background: #fff;
      z-index 9;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      & > div > i.material-icons{
        padding: 10px;
        background: rgba(0,0,0,0.1);
        border-radius: 100%;
        margin-right: 10px;
      }

      .title{
        font-size: 12px;
        color: #8c999f;
      }

      .sub-title{
        font-size 20px;
      }
    }

    .flex{
      display flex;

      &.row{
        align-items center;
        justify-content flex-start;
      }

      &.column{
        flex-direction column;
      }
    }

    .controls{
      margin-top 10px;
      justify-content space-between !important;

      &, & > div{
        align-items flex-end !important;
      }

      .rfp-manager-group-by{
        font-size 12px;
      }

      & > .row{
        flex: 1;
      }
    }

    .button{
      padding: 2px 5px;
      align-items: center;
      display: flex;
      font-size 12px;
      background white linear-gradient(0deg, #f5f6f7 10%, white 11.05%, white 100%);
      height 27px;

      &:not(.icon-only) {
        i.material-icons{
          margin 0 5px 0 0;
          font-size 18px;
        }
      }
    }

    .create{
      background #00b8ab linear-gradient(0deg, #00b0a3 10%, #00b8ab 11.05%, #00b8ab 100%)
      color: #fff;
      border: 1px solid #00b8a9;
    }

    .logo{
      position relative;
      margin-right 10px;

      & > img, & > div{
        height: 65px;
        width: 110px;
        object-fit: contain;
        border-radius 3px;
        border 1px solid #ECEFF1;
        background: #ECEFF1;
        display: flex;
        justify-content: center;
        align-items: center;

        i.material-icons{
          font-size 50px;
        }
      }

      & > img{
        background transparent;
      }
    }
  }
</style>
