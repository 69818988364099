import {
  $state,
  RfpHotelBidRepository,
  notificationUtils
} from 'root/angular-injector-provider';
import { HOTEL_RFP_BID_PREVIEW } from 'root/states';

export {
  handle,
}

function handle({token}) {
  return notificationUtils().onSave(() => RfpHotelBidRepository().updateContacts(token))
    .then(({data: {targetId}}) => Promise.resolve($state().go(HOTEL_RFP_BID_PREVIEW, {bidId: targetId})))
}
