<template>
  <div v-show="!preview || this.model">
    <questionnaire-edit-section-group
      v-if="template.type === 'GROUP'"
      :model="model"
      :preview="preview"
      :template="template"
      @mounted="onSectionMounted"
      @questionAdded="$rbEmit"
      @questionRemoved="$rbEmit"
      @sectionAdded="$rbEmit"
      @sectionRemoved="$rbEmit"
      @questionRequiredChanged="$rbEmit"
      @userDefinedChanged="$rbEmit"
      @userDefinedRemoved="$rbEmit"
    />

    <questionnaire-edit-section-table
      v-else-if="template.type === 'TABLE'"
      :config="config"
      :model="model"
      :preview="preview"
      :template="template"
      @mounted="onSectionMounted"
      @tableAdded="$rbEmit"
      @tableRemoved="$rbEmit"
      @tableConfigure="$rbEmit"
    />

    <questionnaire-edit-hotel-rfp-rate-grid
      v-else-if="template.type === 'HOTEL_RFP_RATE_GRID'"
      :config="config"
      :model="model"
      :preview="preview"
      :template="template"
      @specialsChanged="$rbEmit"
      @mounted="onSectionMounted"
    />
  </div>
</template>

<script>
  import RbEventsMixin from '../../_mixins/RbEventsMixin.vue'
  import QuestionnaireEditSectionGroup from './QuestionnaireEditSectionGroup.vue'
  import QuestionnaireEditSectionTable from './QuestionnaireEditSectionTable.vue'
  import QuestionnaireEditHotelRfpRateGrid from './HotelRfpQuestionnaireEditSectionRateGrid.vue'

  export default {
    name: 'rb-questionnaire-edit-section',

    mixins: [ RbEventsMixin ],

    components: {
      QuestionnaireEditSectionGroup,
      QuestionnaireEditSectionTable,
      QuestionnaireEditHotelRfpRateGrid
    },

    props: [ 'config', 'model', 'preview', 'template' ],

    methods: {
      onSectionMounted () { this.$emit('mounted') },
    }
  }
</script>
