import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';
import Component from './BidStandardNavigation.svelte';
import get from 'lodash/get';

export default {
  template: '<div></div>',
  bindings: {
    bid: '<'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(this, SvelteNgComponent(Component, $element[0], () => ({
    bidId: get(vm.bid, 'id'),
    attachments: {
      coverLetter: !!get(vm.bid, 'coverLetter.attachments.length', 0),
      finalAgreement: !!get(vm.bid, 'finalAgreement.attachments.length', 0),
    }
  }), {}));
}
