export const
  CREATED = 'CREATED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  RESPONDED = 'RESPONDED',
  NEGOTIATION_SENT = 'NEGOTIATION_SENT',
  NEGOTIATION_RESPONDED = 'NEGOTIATION_RESPONDED',
  NEGOTIATION_FINALIZED = 'NEGOTIATION_FINALIZED',
  NO_LONGER_INTERESTED = 'NO_LONGER_INTERESTED',
  NO_THANK_YOU = 'NO_THANK_YOU',
  NO_THANK_YOU_PENDING = 'NO_THANK_YOU_PENDING',
  FINAL_AGREEMENT = 'FINAL_AGREEMENT',
  FINAL_AGREEMENT_PENDING = 'FINAL_AGREEMENT_PENDING',
  BUYER_DRAFT = 'BUYER_DRAFT',
  DELETED = 'DELETED';

export function statusMatches(selectedBidsStatuses, requiredStatuses) {
  if(selectedBidsStatuses.length > requiredStatuses.length) {
    return false;
  }

  // all selected bids must have one of action required statuses
  for(let i=0, l = selectedBidsStatuses.length; i<l; i++){
    if( requiredStatuses.indexOf(selectedBidsStatuses[i]) === -1 ) {
      return false;
    }
  }
  return true;
}
