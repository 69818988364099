<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <div
      slot="header"
      :class="$style.header"
    >
      <i class="material-icons">info_outline</i>
      <div>
        <div :class="$style.title">
          Exit This Page
        </div>
        <div :class="$style.subTitle">
          You are about to exit the invitation page
        </div>
      </div>
    </div>
    <div
      slot="body"
      class="vModalDefaultBody"
      :class="$style.body"
    >
      Once you exit this invitation page, you will only be able to access it again by clicking on the invitation link sent to your email.
      Click on <b>OK</b> to exit or <b>Go Back</b> to stay on this page.
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Ok
      </button>
      <button
        class="rbButton mainButton"
      >
        Go Back
      </button>
    </div>
  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'

  export default {
    name: 'UserCreatedDialog',
    components: { ModalForm },
    mixins: [ RbFormMixin, DialogMixin ],
    methods: {
      onSubmit(){
        return {};
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 450px;
    overflow visible
  }

  .body{
    line-height: 2;
    font-size: 12px;
    color: #637b85;
  }

  .header{
    display flex;
    align-items center;
  }

  .header i{
    font-size 45px;
    color #00b4a6;
    margin-right: 10px;
  }

  .header > .title{
    font-size 15px;
  }

  .header .subTitle{
    font-size 17px;
    font-weight: normal;
  }
</style>
