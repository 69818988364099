<template>
  <div :class="addedClasses.row">
    <div class="msGridColumn1" >
      <supplier-checkbox :state="supplier.bidState"
                         @click="handleSupplierCheckboxClicked(supplier)"/>
    </div>
    <div class="msGridColumn3" :class="$style.property">
      <div :class="addedClasses.name">
        <i v-if="supplier.lastYear" class="material-icons" :class="addedClasses.icon">star</i>
        <span class="vAlignMiddle">{{supplier.name}}</span>
        <div v-if="supplier.lastYear" class="rbTooltip simple"
             tooltip="placement:top-start">Was in Last Years Programme</div>
      </div>
      <div :class="$style.address">{{supplier.location.address.address1}}</div>
    </div>
    <div class="msGridColumn5" :class="$style.cell">
      <span v-if="hasCity" :class="inBadgeCity">{{supplier.location.address.city}}</span>
    </div>
    <div class="msGridColumn7" :class="$style.cell">
      <span v-if="hasState" :class="inBadgeState">{{supplier.location.address.state || supplier.location.address.region}}</span>
    </div>
    <div class="msGridColumn9" :class="$style.cell">
      <span v-if="hasCountry" :class="inBadgeCountry">{{supplier.location.address.country}}</span>
    </div>
    <div class="msGridColumn11" :class="$style.cell">
      <span v-if="hasDistance" :class="inBadgeDistance">{{formattedDistance}}</span>
    </div>
    <div class="msGridColumn13" :class="$style.cell">
      <span v-if="hasChain" :class="inBadgeChain">{{supplier.chain.masterChain.name}}</span>
    </div>
    <div class="msGridColumn15" :class="$style.cell">
      <span v-if="hasBrand" :class="inBadgeBrand">{{supplier.chain.name}}</span>
    </div>
  </div>
</template>

<script>
  import { get } from 'lodash'
  import suppliersListMixin from './SupplierMixin.vue';
  import SupplierCheckbox from './SupplierSelectedCheckbox.vue';

  export default {
    name: "rbv-travel-destinations-map-suppliers-table-expanded-view-supplier",
    mixins: [ suppliersListMixin ],
    components: { SupplierCheckbox },
    computed: {
      addedClasses() {
        if(this.isSelected) {
          return {
            row:  this.$style.rowAdded,
            icon: this.$style.lastYearIconAdded,
            name: this.$style.nameAdded
          }
        } else {
          return {
            row:  this.$style.rowNotAdded,
            icon: this.$style.lastYearIconNotAdded,
            name: this.$style.nameNotAdded
          }
        }
      },
      hasBrand(){ return get(this.supplier, 'chain.name', '').length },
      hasChain(){ return get(this.supplier, 'chain.masterChain.name', '').length },
      hasCity(){ return get(this.supplier, 'location.address.city', '').length },
      hasCountry(){ return get(this.supplier, 'location.address.country', '').length },
      hasDistance(){ return (get(this.supplier, 'distanceMi', '')+'').length },
      hasState(){ return get(this.supplier, 'location.address.state', '').length + get(this.supplier, 'location.address.region', '').length },
      inBadgeCity(){ return this.shouldDisplayInBadge('location.address.city')},
      inBadgeState(){ return this.shouldDisplayInBadge('location.address.state')},
      inBadgeCountry(){ return this.shouldDisplayInBadge('location.address.country')},
      inBadgeDistance(){ return this.shouldDisplayInBadge('distanceMi')},
      inBadgeChain(){ return this.shouldDisplayInBadge('chain.masterChain.name')},
      inBadgeBrand(){ return this.shouldDisplayInBadge('chain.name')},
    },
    methods: {
      shouldDisplayInBadge( key ){ return this.sorter.isDisplayedInBadge && this.sorter.key === key && this.$style.badge }
    }
  }
</script>

<style lang="stylus" module>

  .row {
    font-size : 12px;
    color : #78909C;
    white-space nowrap
  }

  .rowAdded {
    composes row
    background #F5F7F8
  }

  .rowNotAdded {
    composes row
    background white
  }

  .cell {
    text-overflow ellipsis
    overflow hidden
    padding-right 10px
  }

  .property {
    height: 42px;
    line-height: 16px;
    padding: 10px 0;
    overflow hidden;
  }

  .lastYearIcon {
    font-size 16px
    vertical-align middle
  }

  .lastYearIconAdded {
    composes lastYearIcon
    color #00A99D
  }

  .lastYearIconNotAdded {
    composes lastYearIcon
    color #546E7A
  }

  .name {
    composes cell
    font-size : 13px;
    line-height 20px;
  }

  .nameAdded {
    composes name
    color #00A99D
  }

  .nameNotAdded {
    composes name
    color #90A4AE
  }

  .address {
    composes cell
    font-size : 11px;
    color : #90A4AE;
  }

  .badge {
    display inline-block
    padding: 1px 5px
    background : #B0BEC5;
    border-radius : 10px;
    color: white;
    line-height: 16px;
    height: 16px;
  }
</style>
