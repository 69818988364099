import {get, noop} from 'lodash'
import RbLog from "../../log/log";
import {Dialog as VueDialog} from "root/v-app/rbServices";
import NotificationDialog from "root/v-components/_core/notifications/NotificationDialog.vue";

export { HttpErrorHandler as default }

HttpErrorHandler.$inject = ['$q', '$state', 'AuthSession']
function HttpErrorHandler($q, $state, authSession){
  const defaultErrorHandlingMap = {
    '-1': displayCheckConnectionDialog,
    '0': displayCheckConnectionDialog,
    '400': reportAndDisplayGenericErrorPage,
    '500': reportAndDisplayGenericErrorPage,
    '503': displayServerUnavailableDialog,
    '504': displayServerUnavailableDialog,
    '401': saveVmStateAndRedirectToSignIn,
    '403': toForbidden,
    '404': toNotFound,
    '408': retryOnce,
    'default' : displayGenericErrorPage
  }

  this.handle = handleError

  function handleError(httpError, options){
    const errorStatus = httpError.status+'',
      handler = get(options, `${errorStatus}`)
        || get(defaultErrorHandlingMap, `${errorStatus}`)
        || defaultErrorHandlingMap.default
    RbLog.error(httpError)
    return handler(httpError) || $q.reject(httpError)
  }

  function reportAndDisplayGenericErrorPage(){
    // todo: save to send errors to server;
    goToError('500');
  }

  function saveVmStateAndRedirectToSignIn(){
    // todo: save VM state and provide data for redirection back after successful signIn
    authSession.reauthenticate()
  }

  function displayCheckConnectionDialog(httpError) {
    return VueDialog.show(NotificationDialog, {
      subTitle: 'Error',
      title: 'No Connection',
      message: 'Please check your Internet connection.',
      type: 'error'
    })
      .catch(noop)
      .then(() => Promise.reject(httpError))
  }

  function displayServerUnavailableDialog(httpError) {
    return VueDialog.show(NotificationDialog, {
      subTitle: 'Error',
      title: 'Service Unavailable',
      message: 'Service is not available at the moment. Please try again later.',
      type: 'error'
    })
      .catch(noop)
      .then(() => Promise.reject(httpError))
  }

  function toForbidden(){ goToError('403') }

  function toNotFound(){ goToError('404') }

  function displayGenericErrorPage(error){ goToError('500', error) }

  function retryOnce(){
    // todo: will see how to implement this. First server should support idempotent POSTs. Redirect to 408 for now
    goToError('408');
  }

  function goToError(errorId, error){
    $state.go('error', {id: errorId, error: error ? b64EncodeUnicode(JSON.stringify(error)) : ''}); // todo: check this
  }

  function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
  }
}
