<RbAuthHeader>
  <div slot="additionalControls">
    <TravelDestinationPicker  {destination} {rfpId} />
  </div>

  <div slot="mainControls">
    <div class="Inline">
      <a href="/" on:click|preventDefault|stopPropagation="{globalSearch}"
         class="aBtn asLarge CustomButton" data-icon="travel_explore">Global Search</a>
      <div class="rbTooltip">If you cannot find your hotel using our proximity search for this area, try searching our entire database</div>
    </div>

    <a href="/" on:click|preventDefault|stopPropagation="{() => toggleTable(true)}"
       class="aBtn asLarge CustomButton {tableShowing ? 'Active': ''}" data-icon="list">Properties List</a>

    <a href="/" on:click|preventDefault|stopPropagation="{() => toggleTable(false)}"
       class="aBtn asLarge CustomButton {!tableShowing ? 'Active': ''}" data-icon="map">Properties Map</a>
  </div>
</RbAuthHeader>


<script>
  import {createEventDispatcher} from 'svelte';
  import {SvelteDialog} from 'root/v-app/rbServices';

  import TravelDestinationPicker from './TravelDestinationPicker.svelte';
  import RbAuthHeader from './RbAuthHeader.svelte';
  import GlobalSearchDialog from './RbTravelDestinationGlobalSearchDialog.svelte';

  const dispatch = createEventDispatcher();

  export let tableShowing = false;
  export let destination;
  export let rfpId;

  function toggleTable(state) {
    dispatch('toggle', state);
  }

  function globalSearch(){
    SvelteDialog.show(GlobalSearchDialog, {rfpId, destination})
  }
</script>


<style lang="stylus">.Inline {
  display: inline-block;
}
.aBtn.asLarge.CustomButton {
  background: #313c42;
  border-radius: 2px;
  color: #90a4ae;
  height: 36px;
  font-size: 13px;
  border: 1px solid #313c42;
  text-decoration: none;
  margin-top: 10px;
}
.aBtn.asLarge.CustomButton:hover {
  color: #cfd8dc;
}
.aBtn.asLarge.CustomButton:focus {
  border-color: #00b8ab;
}
.aBtn.asLarge.CustomButton.Active {
  color: #fff;
  border-color: #00b8ab;
}
</style>