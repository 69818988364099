/**
 * Created by DejanK on 4/4/2017.
 */
import { get, merge, set } from 'lodash'
import {getSupplierByHotelId, createBid, removeBid, updateSupplier} from 'root/rfp-hotel/main/rfp/pages/destinations/suppliers.store.js';

export default TravelDestinationManagerService

TravelDestinationManagerService.$inject = ['RfpHotelRfpDestinationsRepository', 'MainAPI', '$timeout', '$rootScope'];
function TravelDestinationManagerService(RfpHotelRfpDestinationsRepository, mainAPI, $timeout, $scope){
  const $service = this
  let focusedSupplierIdTimeoutPromise

  $service.getRfpTravelDestination = getRfpTravelDestination;
  $service.getSupplierByHotelId = getSupplierByHotelId;
  $service.addToTheBidManager = addToTheBidManager;
  $service.removeFromBidManager = removeFromTheBidManager;
  $service.setFocusedSupplierId = setFocusedSupplierId;
  $service.scrollListTo = scrollListTo;
  $service.focusedSupplierId = null;
  $service.scrollListToId = null;

  function getRfpTravelDestination(rfpId, destinationId){
    return RfpHotelRfpDestinationsRepository.getRfpTravelDestination(rfpId, destinationId)
      .then(prepareFilter);

    function prepareFilter(httpResponse){
      let filter = getDefaultFilter(get(httpResponse, 'data.location.coordinates'));
      filter = merge(filter, get(httpResponse, 'data.filter' ));
      set(httpResponse, 'data.filter', filter);
      return httpResponse;
    }

    function getDefaultFilter(coordinates){
      return {
        coordinates: coordinates,
        maxDistance: {
          value: 3,
          unit: 'MI'
        },
        chains: []
      }
    }
  }

  function addToTheBidManager(supplierId){
    const supplier = {...getSupplierByHotelId(supplierId)};
    supplier.bidState = 'WORKING';
    updateSupplier(supplier);

    return createBid(supplierId)
      .then(r => {updateSupplier(r.data.data)});
  }

  function removeFromTheBidManager(supplierId){
    const supplier = {...getSupplierByHotelId(supplierId)};
    supplier.bidState = 'WORKING';
    updateSupplier(supplier)

    return removeBid(supplier.bidId)
      .then(() => {
        supplier.bidState = 'NA';
        updateSupplier(supplier);
      });
  }

  function setFocusedSupplierId(supplierId){
    $timeout.cancel(focusedSupplierIdTimeoutPromise);
    focusedSupplierIdTimeoutPromise = $scope.$timeout(()=>{
      $service.focusedSupplierId = supplierId;
    }, supplierId ? 200 : 0);
  }

  function scrollListTo(id){
    $service.scrollListToId = id;
  }
}
