import {checkAndExecute, checkAndExecuteToActionReport} from 'root/api/helpers';

export default function HotelRfpRateLoadingAPI(api) {
  Object.assign(api, {
    rateLoading: {
      sendRequests: bidsIds => checkAndExecuteToActionReport(
        () => bidsIds,
        () => api.server.put('rfps/hotel/bids/rate-loading/send-requests', {bids: bidsIds})
      ),

      getRequestDetails: (bidId, token) => checkAndExecute(
        () => bidId && token,
        () => api.server.get(`rate-loading/request/${bidId}`, {token})
      ),

      markBidsLoadedStatus: (bidsIds, loadedStatus, details) => checkAndExecuteToActionReport(
        () => bidsIds,
        () => api.server.put('rfps/hotel/bids/rate-loading/mark-loaded', {bids: bidsIds, loaded: loadedStatus, details})
      ),

      markBidLoadedStatus: (bidId, token, person, loadedStatus, details) => checkAndExecuteToActionReport(
        () => bidId && token && person,
        () => api.server.put(`rfps/hotel/bids/${bidId}/rate-loading/mark-loaded`, {token, person, loaded: loadedStatus, details})
      ),
    }
  });
}
