import get from 'lodash/get';

const RATE_ABBR = [
  { gbta: 'LRA', neg: 'lra'},
  { gbta: 'NLRA', neg: 'nlra'},
];

const OCC_ABBR = [
  { gbta: 'SGL', neg: 'S'},
  { gbta: 'DBL', neg: 'D'},
];
const SEASONS = ['1','2','3','4','5'];
const ROOM_TYPES = ['1','2','3'];

export default function (bid, rows, matchigCodes){
  const bidDataRow = findDataRowForBid(bid, rows, matchigCodes);
  return createNegotiationCommunication(bidDataRow);
}

function findDataRowForBid(bid, rows, matchingCodes){
  const codes = getBidCodes();
  return rows.find(row => isMatch(codes, row))

  function getBidCodes(){
    return matchingCodes.map(code => ({
      id: code,
      value: trimCode(get(bid, `questionnaire.response.answers.${code}`))
    }));
  }

  function trimCode(code){
    return code ? `${code}`.trim().replace(/^0+/, '') : null;
  }

  function isMatch(cs, row){
    if(!row.$propCodes) {
      row.$propCodes = createResponsePropCodesCache();
    }

    return cs.find(codeDetails => codeDetails.value && codeDetails.value === row.$propCodes[codeDetails.id]);

    function createResponsePropCodesCache(){
      return matchingCodes.reduce((acc, code) => {
        acc[code] = trimCode(row[code]);
        return acc;
      }, {});
    }
  }
}

function createNegotiationCommunication(bidDataRow){
  if(!bidDataRow) return null;
  return {rates: readRates(bidDataRow), amenities: readAmenities(bidDataRow), message: readMessage(bidDataRow)};
}

function readRates(response){
  const rates = {};
  RATE_ABBR.forEach(rate => {
    OCC_ABBR.forEach( occ => {
      SEASONS.forEach(season => {
        ROOM_TYPES.forEach(roomType => {
          const responseRate = response[`${rate.gbta}_S${season}_RT${roomType}_${occ.gbta}`];
          rates[`${rate.neg}${occ.neg}_s${season}_rt${roomType}`] = createFixed(responseRate);
        })
      });
    });
  });

  rates.dyn = readDynamicRate(response);

  return rates;
}

function readDynamicRate(response){
  const
    isAvailable = readAvailability(response.DYNAMIC_PRICING),
    value = response.DYNAMIC_PCT_Discount;

  return createPercentage(isAvailable, value);
}

function createFixed(value, included = false){
  if(!included && !value) return createUnavailable();
  return {
    value: value || '0',
    type: 'FIXED',
    valid: true,
    included,
  };
}

function createUnavailable(){
  return {
    value:'0',
    type: 'UNAVAILABLE',
    valid: true,
    included: false
  };
}

function createPercentage(isAvailable, value, included = false){
  if(!included && (!isAvailable || !value)) return createUnavailable();
  try {
    return {
      value: value ? (Number.parseFloat(value)/100).toFixed(4) : '0',
      type: 'PERCENTAGE',
      valid: true,
      included,
    };
  } catch (e){
    return createUnavailable();
  }
}

function readAvailability(val){
  return val
    && !equalsIgnoringCase(val, 'N')
    && !equalsIgnoringCase(val, 'NO')
    && !equalsIgnoringCase(val, 'NA')
    && !equalsIgnoringCase(val, 'NONE AVAILABLE');
}

function equalsIgnoringCase(text = '', other = '') {
  return text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0;
}

function readAmenities(response){
  return {
    'cp': createText(response.CANC_POL),
    'cm': createPercentage(response.COMM_PCT_CLIENT, response.COMM_PCT_CLIENT, false),
    'ec': createMixedFromResponse(response, 'EARLYCK_FEE', 'EARLYCK_UOM', 'EARLYCK_INCLUDE'),
    'prk': createFixedFromResponse(response, 'PARKATTEND', 'PARK_FEE', 'PARK_INCLUDE'),
    'bf': createFixedFromResponse(response, 'BREAK_INCLUDE', 'BREAK_FEE', 'BREAK_INCLUDE'),
    'ft': createFixedFromResponse(response, 'FITON_CENT', 'FITNESS_FEE_ON', 'FITNESS_INCLUDE_ON'),
    'ia': createFixedFromResponse(response, 'HIGHSPEED_INROOM', 'HSIA_FEE', 'HSIA_INCLUDE'),
    'wf': createFixedFromResponse(response, 'WIRELESS', 'WIRELESS_FEE', 'WIRELESS_INCLUDE'),
    'as': createFixedFromResponse(response, 'AIRTRANS_DESCRIBE', 'AIRTRANS_FEE', 'AIRTRANS_INCLUDE'),
  };
}

function createFixedFromResponse(response, avail, val, inc){
  const
    isAvail = readAvailability(response[avail]),
    value = response[val],
    included = readIncluded(response[inc]);

  if(included || isAvail){
    return createFixed(value, included);
  } else {
    return createUnavailable();
  }
}

function createText(v){
  return !v ? createUnavailable() : {
    value: v,
    type: 'TEXT',
    valid: true,
    included: true,
  }
}

function readIncluded(v) {
  return equalsIgnoringCase('Y', v) || equalsIgnoringCase('YES', v);
}

function createMixedFromResponse(response, val, typeKey, inc){
  const
    type = response[typeKey],
    value = response[val],
    included = readIncluded(response[inc]);

  if(equalsIgnoringCase('P', type) || equalsIgnoringCase('PERCENTAGE', type)){
    return createPercentage(true, value, included);
  }

  else if(equalsIgnoringCase('F', type) || equalsIgnoringCase('FIXED', type)){
    return createFixed(value, included);
  }

  else {
    return createUnavailable();
  }
}

function readMessage(response){
  return response.MESSAGE || undefined;
}
