<template>
  <div class="hotel-directory">
    <hotel-directory-editor
      :user="user"
      :account-id="accountId"
    />
  </div>
</template>

<script>
import HotelDirectoryEditor from './editor/HotelDirectoryEditor.vue';
export default {
    name: 'HotelDirectory',
    components: { HotelDirectoryEditor },
    props: {
        user: {
            type: Object,
            required: true,
        },
        accountId: {
            type: String,
            required: true,
        }
    }
}
</script>

<style lang="stylus">
    .hotel-directory{
        & + .vModalDefaultFooter .rbCheckboxOptionButton, .rbCheckboxOptionButton{
            border none !important;
            color #313C42;
            display flex;
            font-size 12px;

            &:hover{
                border none;
            }

            .material-icons.rbCheckboxOptionIcon{
                font-size 20px;
                color: #90A4AE;
            }

            .rbCheckboxOptionText {
                vertical-align: top;
                padding-left 10px;
                color: #90A4AE;
                text-transform none;
                font-size 14px;
            }

            &.selected{
                .material-icons.rbCheckboxOptionIcon{
                    color #00B8AB;
                }
                .rbCheckboxOptionText {
                    vertical-align: top;
                    padding-left 10px;
                    color: #37474F;
                }
            }

            &.dark{
                color #727D84;

                &.selected{
                    color: #00B8AB;

                    .rbCheckboxOptionText {
                        color #fff;
                    }
                }
            }

            &.dark-icon{
              &.selected{
                .material-icons.rbCheckboxOptionIcon{
                  color #37474F;
                }
              }
            }
        }

        .rbInputContainer{
            position relative;

            .icon{
                position: absolute;
                top: 9px;
                left: 15px;
                color: #90a4ae;
                z-index: 1;
            }

            .rbSelectWrapper.simple{
                background-color transparent;
            }

            .clear-icon{
                cursor pointer;
                position: absolute;
                right: 10px;
                top: 8px;
                color #90a4ae;
            }

            &.dark{
              .rbInputLabel{
                *{
                  background #313c42;
                  white-space nowrap;
                }
              }

              .rbInput, .rbSelect{
                background transparent !important;
                border-color #263238 !important;
              }
            }

            .rbInput, .rbSelect{
                border-radius 20px;
                padding 0 15px;
                outline none;
                color #90a4ae;

                &:focus{
                    border 1px solid #00A99D;
                    color #546E7A;
                }
            }

            .icon + .rbInput, .icon +  .rbSelectWrapper .rbSelect{
                padding-left 40px !important;
            }

            &.rbInputInvalid{
                .rbInputError{
                    margin: 0 11px;
                    color: #f15a25;
                    font-weight normal;
                    background: transparent;
                }

                .icon{
                    color: #f15a25;
                }

                .rbInput, .rbSelect{
                    border-color #f15125 !important;
                    background-color #fff !important;
                }

                .rbInputLabel{
                    color #b0bec5 !important;
                }
            }

            .rbInputLabel{
                position: absolute;
                top: -6px;
                width: calc(100% - 40px);
                margin: -2px 15px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                transition all .3s;

                *{
                    padding 0 5px;
                    background-color #fff;
                }
            }

            &:focus-within {
                .icon{
                    color #00A99D;
                }

                .rbInputLabel{
                    color #00A99D;
                    z-index 99;
                }
            }

            &.rbInputEmpty:not(:focus-within){
                .rbInput, .rbSelect{
                    background-color #ECEFF1;
                    border-color #ECEFF1;
                }

                .rbInputLabel{
                    top: 11px;
                    font-size: 12px;
                    width: auto;

                    *{
                        background transparent;
                        padding 0;
                    }

                    .rbInputLabelHint{
                        position relative;
                        padding-left 5px;
                    }
                }
            }

            &:not(.rbInputEmpty){
                .rbInputLabel {
                z-index 99;
            }
            }
        }

        & + .vModalDefaultFooter{
            .rbButton{
                border-radius 20px;
            }
        }

        .bar{
            min-height 88px;
            background #313C42;
            display flex;
            padding 25px 20px;
            box-sizing border-box;
            justify-content space-between;
            flex-wrap wrap;
            border-bottom 3px solid #263238;

            &.no-border{
              border-bottom none;
            }

            &.responsive {
                .button.responsive {
                    width 36px;
                    overflow hidden;
                    padding 0 12px;
                }

                .title{
                    display: none;
                }
            }
        }

        .manage-table{
            border-bottom: 5px solid #dedede;

            .grouper{
                color: #78909c;
                min-height: 30px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                position: sticky;
                top: 46px;
                z-index: 9;
                background: #e6e9eb;
                font-weight 600;
                padding 10px 7px;
                border-top: 5px solid #dedede;
                font-size 12px;
                border-bottom: 1px solid #cfd8dc !important;

                .hotel-type{
                  height 15px;
                  overflow hidden;
                }

                .check-box{
                  cursor pointer;
                  margin-right 10px;
                }
            }

            .row {
                display: flex;
                align-items center;
                overflow visible;
                height: 45px;
                position relative;
                color #37474F;
                border-bottom: 1px solid #cfd8dc !important;

                &.selected{
                  background #fff;
                }

                &:hover{
                  background #ECEFF1;
                }

                &.tableHead {
                    background: #313c41;
                    font-size: 12px;
                    color: #acbec5;
                    position sticky
                    top 0;
                    z-index 99;
                }

                .cell {
                    font-size 12px;
                    box-sizing: border-box;
                    flex 1;
                    width unset;
                    min-width unset;
                    border 0;
                    padding 0;
                    flex-direction row;
                    justify-content flex-start;
                    align-items center;
                    color inherit;
                    overflow visible;

                  &.hidden{
                      display none !important;
                    }

                    &.cell-xs{
                      min-width 120px;
                      max-width 120px;
                    }

                    &.cell-s{
                      min-width 165px;
                      max-width 165px;
                    }

                    &.cell-l{
                      min-width 220px;
                      max-width 220px;
                    }

                    &.cell-xl{
                      min-width 270px;
                      max-width 270px;
                    }

                    &.head {
                      height: 45px;
                      vertical-align: bottom;
                      cursor pointer
                      display: flex;
                      flex-direction row;
                      justify-content flex-start;
                      align-items center;

                        &.checkboxHead{
                            max-width 40px;
                            min-width 40px;
                            padding 0 8px;
                        }
                    }

                    &.checkboxCell{
                        max-width 40px;
                        min-width 40px;
                        padding 0 7px;
                        min-height 35px;
                        & > span{
                            font-size 25px !important;
                        }

                        &.plus{
                          i{
                            background: #77909d;
                            color: white;
                            border-radius: 3px;
                            cursor pointer;
                          }
                        }
                    }

                    & + & {
                        margin-left 5px;
                    }
                }

            }

            .wrapped-cells{
              background-color: #dae0e294;
              color: #627b85;
              font-size 12px;
              transform scaleY(0);
              padding 0 30px;
              justify-content space-between;
              overflow:hidden;
              transition:all 0.1s ease-out;
              height: 0;
              transform-origin: top;

              &.open{
                transform scaleY(1);
                border-bottom 5px solid #dedede;
                height:auto;
              }

              .wrapped-cell{
                min-height 25px;
                display flex;
                border-bottom: 1px solid #eceff1;
                padding: 2px 10px;
                align-items center;

                .value{
                  overflow hidden;
                  white-space normal;
                }

                .label{
                  width 250px;
                }
              }
            }
        }

        .button{
            height 36px;
            display: inline-flex;
            padding 0 15px;
            border-radius: 36px;
            white-space nowrap;
            background-image: linear-gradient(to top, #f5f6f7 10%, #fff 11.05%, #fff 100%);
            border 1px solid #DCDEE0;
            color #546E7A;
            align-items center;
            cursor pointer;
            text-decoration none;
            font-size 14px;

            &:hover{
                background-image: linear-gradient(to top, rgba(245, 246, 247, 1) 10%, rgba(255, 255, 255, 1) 11.05%, rgba(255, 255, 255, 1) 100%);
                border 1px solid #DCDEE0;
                color #455A64;
            }

            &.disabled, &:disabled{
                background-image: unset ;
                background-color: #ECEFF1;
                border 1px solid #CFD8DC;
                color #78909C;
            }

            &.cta{
                background-image: unset ;
                background-color: #00B8AB;
                border 1px solid #00A99D;
                color #fff;

                &:hover{
                    background-color #00A99D;
                }

                &:disabled, &.disabled{
                    background-color: #ECEFF1;
                    border 1px solid #CFD8DC;
                    color #B0BEC5;
                }
            }

            i.material-icons{
                margin-left: -5px;
                margin-right: 10px;

                &:last-child:not(:first-child)  {
                    margin 0;
                    margin-left 10px;
                }
            }

            &.icon-only{
                width 36px;
                padding: 5px 5px 5px 0;
                justify-content center;

                i.material-icons{
                    margin 0;
                    padding: 5px 0 0 5px;
                }
            }

            &.dark{
                background-image: unset ;
                background-color: #263238;
                border 1px solid #263238;
                color #B0BEC5;

                &:hover{
                    color #ffffff;
                }

                &.disabled, &:disabled{
                    background-color: #3C464C
                    border 1px solid #3C464C;
                    color: #637078
                }

                &.cta{
                    background-image: unset ;
                    background-color: #00A99D;
                    border 1px solid #263238;
                    color #fff;

                    &:hover{
                        background-color #00B8AB;
                        border 1px solid #00B8AB;
                    }

                    &:disabled, &.disabled{
                        background-color: #3C464C;
                        border 1px solid #3C464C;
                        color #637078;
                    }
                }
            }

            &+& {
                margin-left 5px;
            }
        }

        .dropdown{
            /*position absolute;*/
            min-width 160px;
            right 0;
            top: 3px;
            border-radius 3px;
            padding 5px 0;
            border 1px solid #B0BEC5;
            margin: 5px 0;
            list-style-type none;
            max-height: 300px;
            overflow: auto;
            background #fff;

            .item{
                min-height 33px;
                display flex;
                overflow hidden;
                text-overflow ellipsis;
                padding: 5px 40px 5px 15px;
                box-sizing: border-box;
                cursor pointer;
                white-space nowrap;
                align-items center;
                font-size 15px;
                color #546E7A;

                .icon{
                    color #90A4AE;
                    margin-left: -5px;
                    margin-right: 10px;
                }

                .icon2 {
                  position absolute;
                  right 10px;
                }

                &:hover{
                    color #546E7A;
                    background #ECEFF1;

                    .icon{
                        color #546E7A;
                    }
                }

                &.active{
                    color #37474F;
                    background #ECEFF1;
                }

                &.disabled{
                    color #546E7A;
                    opacity 0.3;
                }
            }

            &.dark{
                border 1px solid #1A2226;
                background-color: #263238;

                .item{
                    color #90A4AE;

                    .icon{
                        color #B0BEC5;
                    }

                    &:hover{
                        color #90A4AE;
                        background #313C42;

                        .icon{
                            color #fff;
                        }
                    }

                    &.active{
                        color #fff;
                        background #313C42;
                    }

                    &.disabled{
                        color #90A4AE;
                    }
                }
            }
        }

        i.material-icons{
          font-size: 20px;
          width fit-content;
          height fit-content;
        }
    }



    .vModal{
        .vModalDefaultFooter{
            padding 0 15px;
        }

        .vModalDefaultHeader{
            padding-left 15px;
        }

        .vModalDefaultBody{
            margin-top: 15px;
            margin-bottom 0;
            padding 0 15px;
        }
    }
</style>
