import {fileToJson} from 'root/shared/tools/xls.service';

export default function loadLocations(files){
  if(!files || !files.length) throw new Error('Unable to load file!');
  return fileToJson(files[0])
    .then(rows => {
      if(!rows || !rows.length) throw new Error('Unable to load file!');
      const {keys, warnings} = determineKeys(rows);
      return loadRows(rows, keys, warnings);
    });
}

function determineKeys(rows){
  const rowKeys = getAllKeys(rows);
  const name = determineKey(rowKeys, ['name'], 'name');
  const address = determineKey(rowKeys, ['address'], 'address', true);
  const lon = determineKey(rowKeys, ['lon', 'longitude', 'lng'], 'longitude');
  const lat = determineKey(rowKeys, ['lat', 'latitude'], 'latitude');

  return {
    keys: {
      name: name.key,
      address: address.key,
      lon: lon.key,
      lat: lat.key,
    },
    warnings: address.warning ? [address.warning] : [],
  };

  function getAllKeys(rs){
    const keys = new Set();
    rs.forEach(r => Object.keys(r).forEach(k => keys.add(k)));
    return [...keys];
  }

  function determineKey(keys, matches, label, ignoreFailure = false){
    const patterns = matches.map(m => new RegExp(`^${m}$`, 'i'));
    const prepKeys = keys.map(k => k.trim());
    let warning = '';
    const key = prepKeys.find(k => patterns.find(p => p.test(k)));

    if(!key){
      if(ignoreFailure) {
        warning = `Unable to determine location ${label} column!`;
      } else {
        throw new Error(`Unable to determine location ${label} column!`);
      }
    }

    return {warning, key};
  }
}

function loadRows(rows, keys, warnings){
  const locations = rows
    .map((r, ind) => loadRow(r, ind, keys, warnings))
    .filter(l => l);

  return {locations, warnings};
}

function loadRow(row, index, keys, warnings){
  const rowNo = index+2;
  return {
    name: loadName(row[keys.name], rowNo, warnings),
    address: keys.address ? loadAddress(row[keys.address], rowNo, warnings) : '',
    lon: loadCoordinate('Longitude', row[keys.lon], rowNo, warnings),
    lat: loadCoordinate('Latitude', row[keys.lat], rowNo, warnings),
  };
}

function loadName(value = '', rowNo, warnings){
  let v = value.trim();
  if(!v) throw new Error(`Location name is empty in row ${rowNo}!`);
  if(v.length > 100) {
    warnings.push(`Location name in row ${rowNo} truncated to 100 characters!`);
    v = v.substring(0, 100);
  }

  return v;
}

function loadAddress(value = '', rowNo, warnings){
  let v = value.trim();
  if(!v) warnings.push(`Location address empty in row ${rowNo}!`)
  if(v.length > 200) {
    warnings.push(`Location address in row ${rowNo} truncated to 100 characters!`);
    v = v.substring(0, 200);
  }

  return v;
}

function loadCoordinate(name, value, rowNo, warnings){
  let v = parseFloat(value);
  if(isNaN(v)) {
    warnings.push(`${name} in row ${rowNo} not found and set to 0!`);
    v = 0.0;
  }
  return v;
}
