import Vue from 'vue'
import HotelDirectory from './HotelDirectory.vue';
import {CURRENT_USER_CHANGED_EVENT} from 'root/auth/current-user.service';

export default {
  template: '<div></div>',
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'CurrentUser', '$scope', '$state'];
function Ctrl($element, currentUser, $scope, $state){
  const vm = this;
  let vueComponent;

  vm.$onInit = () => {
    vm.user = null;
    vm.accountId = $state.params.accountId;
    $scope.$on(CURRENT_USER_CHANGED_EVENT, (event, user) => {
      vm.user = user;
      initVueComponent();
    });
    currentUser.get().then(initVueComponent);
  };

  vm.$onDestroy = () => { return vueComponent && vueComponent.$destroy() };

  function initVueComponent(user = null){
    vm.user = user? user: vm.user;
    return !vueComponent && initVue();

    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { user: vm.user, accountId: vm.accountId },
        render: function (createElement) {
          return createElement(HotelDirectory, {
            props: {
              user: vm.user,
              accountId: vm.accountId,
            },
          })
        }
      })
    }
  }
}
