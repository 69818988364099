/**
 * Created by DejanK on 8/11/2017.
 */
import './rb-negotiations-panel-edit-actions.scss';
import HTML from './rb-negotiations-panel-edit-actions.html';

export default {
  template: HTML,
  bindings: {
    side: '<',
    currency: '<',
  },
  controller: Ctrl
};

Ctrl.$inject = ['$rootScope'];
function Ctrl($rootScope) {
  const vm = this;

  vm.$onChanges = $onChanges;
  vm.$onInit = $onInit;

  vm.requestOffAll = requestOffAll ;

  function requestOffAll(percent){
    $rootScope.$broadcast('RB_REQUESTED_OF_ALL', {percentage: 1 - parseFloat(percent)/100});
  }

  function $onChanges(changes){
    if(changes.side && changes.side.currentValue){
      setSide(changes.side.currentValue)
    }

    function setSide(side){
      vm.side = side;
    }
  }

  function $onInit(){
    vm.requestOffAllPercentage = 5;
  }
}
