<script>
  import DeclineToBidOptions from 'root/rfp-hotel/competitive-bids/decline-to-bid/HotelRfpCompetitiveBidDeclineToBidOptions'
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalYesNoButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import { mainAPI, notificationUtils } from 'root/angular-injector-provider';
  import noop from 'lodash/noop';

  export let bid = {}, cancel, hide;

  const
    OTHER_MESSAGE_MAX_LENGTH = 200,
    options = DeclineToBidOptions;

  let
    company,
    value = '',
    optionsValue = '',
    otherReasonValue = '';

  $: company = bid.buyer.company.name;

  $: onOptionsValueChange(optionsValue);
  $: onOtherReasonValueChange(otherReasonValue);

  function onOptionsValueChange(ov = ''){
    if(!ov) return;
    value = ov;
    otherReasonValue = null;
  }

  function onOtherReasonValueChange(ov = ''){
    if(otherReasonValue === null) {
      otherReasonValue = '';
    } else {
      if(ov.length > OTHER_MESSAGE_MAX_LENGTH){
        ov = ov.substring(0,OTHER_MESSAGE_MAX_LENGTH);
      }
      optionsValue = '';
      otherReasonValue = ov;
      value = ov;
    }
  }

  function save(){
    notificationUtils()
      .onSave(() => mainAPI().hotelRfpCompetitiveBidDecline(bid._id, value))
      .then(response => hide(response))
      .catch(noop)
  }
</script>


<ClosableModal close="{() => cancel()}">
  <div slot="heading">
    <div class="Header">
      <div>Decline Competitive Bid From</div>
      <div class="HeaderTitle">
        {company}
      </div>
    </div>
  </div>

  <div class="Content">
    <p class="Paragraph">
      We would like to give {company} a reason for the decline to bid. We have listed a few of the most common reasons below or you can write your own.
    </p>

    <p class="Paragraph">
      We're sure {company} will appreciate you taking the time.
    </p>

    <div>
      <p class="Paragraph">
        Please select a reason:
      </p>

      <div>
        {#each options as option}
          <label class="OptionContainer">
            <input type="radio" bind:group={optionsValue} value="{option}"
                   class="OptionInput" tabindex="-1">
            <span class="OptionLabel">{option}</span>
          </label>
        {/each}
      </div>
    </div>

    <div class="OtherInputContainer">
      <input type="text" bind:value={otherReasonValue}
             class="OtherInput {otherReasonValue.length && 'OtherInputNotEmpty'}"
             tabindex="-1"/>

      {#if !otherReasonValue}
        <div class="OtherInput OtherPlaceholder">
          <span class="material-icons PrefixIcon">edit</span> Other...
        </div>
      {/if}
    </div>
  </div>


  <ModalYesNoButtons noAction="{() => cancel()}"
                     yesLabel="Submit"
                     yesAction="{() => save()}"
  />
</ClosableModal>

<style lang="stylus">.Header {
  padding-left: 14px;
  display: flex;
  height: 63px;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  line-height: 20px;
  color: #546e7a;
}
.HeaderTitle {
  font-weight: bold;
  font-size: 16px;
  color: #455a64;
}
.Content {
  padding: 0 14px;
}
.Paragraph {
  margin: 0;
  margin-bottom: 14px;
  font-size: 12px;
  line-height: 14px;
  color: #546e7a;
}
.OptionContainer {
  display: flex;
  margin-top: 10px;
}
.OptionContainer span {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  color: #90a4ae;
  cursor: pointer;
}
.OptionContainer span:hover {
  color: #78909c;
}
.OptionContainer span:before {
  font-family: "Material Icons";
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 1;
  font-size: 14px;
  height: 14x;
  margin-right: 5px;
  content: "radio_button_unchecked";
}
.OptionContainer [type="radio"] {
  -moz-appearance: radio;
  -webkit-appearance: radio;
  -ms-appearance: radio;
  -o-appearance: radio;
  width: 0;
  height: 0;
  margin: 0;
}
.OptionContainer [type="radio"]:checked + span {
  color: #00a99d;
}
.OptionContainer [type="radio"]:checked + span:before {
  content: "radio_button_checked";
  color: #00b8ab;
}
.OtherInputContainer {
  display: flex;
  justify-content: space-between;
  margin: 14px 0;
  position: relative;
}
.OtherInput {
  font-family: 'Noto Sans', sans-serif;
  position: relative;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  height: 26px;
  padding: 0 10px;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
  font-size: 12px;
  color: #b0bec5;
  background: transparent;
  display: flex;
  align-items: center;
}
.OtherInput:focus {
  border: 1px solid #00a99d;
}
.OtherInput.OtherInputNotEmpty {
  color: #546e7a;
}
.OtherPlaceholder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  border: 0;
}
.PrefixIcon {
  font-size: 14px;
  margin-right: 3px;
}
</style>