<div class="Heading">
  <div class="HeadingText">
    <div class="Title">{title}</div>
    <div class="Text">{subtitle}</div>
  </div>

  <div>
    <button class="aBtn atDark asLarge asIcon PinButton" data-icon="play_for_work" data-icon-rotate="90"
            on:click={() => minimized = true}></button>
  </div>
</div>

{#if before}
  <svelte:component this={before} maximized="{true}"/>
{/if}

<div class="Menu">
  {#each menu as item}
    <Item {item} />
  {/each}
  <slot name="after" />
</div>

<script>
  import Item from './NavigationMaximizedLevel1MenuItem.svelte';

  export let title = '';
  export let subtitle = '';
  export let menu;
  export let minimized;
  export let before;
</script>


<style lang="stylus">.Heading {
  height: 96px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  flex: 0 0 auto;
}
.HeadingText {
  flex: 0 1 100%;
  padding: 0 0 10px 16px;
}
.Title {
  font-size: 20px;
  color: #fff;
}
.Text {
  font-size: 13px;
  color: #72828a;
}
.PinButton {
  margin: 10px;
}
.Menu {
  flex: 1 1 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
