import { checkAndExecute } from 'root/api/helpers';

export default function RfpBookmarkAPI(api) {

  Object.assign(api, {

    rfpBookmarks: {
      add: (userAccountId, data) => checkAndExecute(
        () => userAccountId && data,
        () => api.server.post(`rfp-bookmarks/${userAccountId}/add`, data)),

      remove: (userAccountId, data) => checkAndExecute(
        () => userAccountId && data,
        () => api.server.post(`rfp-bookmarks/${userAccountId}/remove`, data)),
    }

  });
}
