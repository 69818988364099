{#if shown}
  <div class="vDialogWrapper"
       style="--zIndex: {zIndex}"
       transition:opacity="{{duration: TRANSITION_DURATION}}"
       on:click="{(event) => handleOverlayClicked(event)}">

    <div transition:inOut="{{duration: TRANSITION_DURATION}}">
      <svelte:component
        this="{dialog.component}"
        {...dialog.props}
        cancel={reason => cancel(reason)}
        hide={reason => hide(reason)}
      />
    </div>
  </div>
{/if}

<script>
  import { onMount, tick, onDestroy } from 'svelte';
  import { SvelteDialog } from 'root/v-app/rbServices';
  import { $newScope as ngScopeProvider } from 'root/angular-injector-provider';

  export let
    dialog,
    ord = 1,
    options;

  const
    TRANSITION_DURATION = 200;

  let
    shown = false,
    instance = null,
    zIndex = 0,
    ngScope,
    timeoutId;

  $: zIndex = ord * (options && options.zIndex || 1000);

  function handleOverlayClicked (event) {
    options.closeOnOutsideClick && event.target.classList.contains("vDialogWrapper") && cancel();
  }

  function cancel (reason) {
    waitForAnimation( () => { SvelteDialog.cancel(reason) } )
  }

  function hide (reason) {
    waitForAnimation( () => { SvelteDialog.hide(reason) } )
  }

  function waitForAnimation (action){
    shown = false;
    if(timeoutId) window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(action, TRANSITION_DURATION);
  }

  onMount( async () => {
    if(document.activeElement) document.activeElement.blur();
    ngScope = ngScopeProvider();
    ngScope.$on('$stateChangeStart', () => { cancel() });
    await tick();
    shown = true;
  });

  onDestroy(() => {
    shown = false;
    if(ngScope) ngScope.$timeout(() => ngScope.$destroy());
    if(timeoutId) window.clearTimeout(timeoutId);
  });

  function inOut(node, {duration}) {
    return {
      duration,
      css: t => `transform: scale(${t});`
    };
  }

  function opacity(node, {duration}) {
    return {
      duration,
      css: t => `opacity: ${t};`
    };
  }
</script>


<style lang="stylus">.vDialogWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  z-index: var(--zIndex);
}
.vDialogWrapper + .vDialogWrapper {
  background: rgba(0,0,0,0.05);
}
</style>

