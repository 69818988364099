import HTML from './rfp-bid-specifications.html';
import get from 'lodash/get';

export { HotelRfpBidSpecificationsController as Controller, HTML }

HotelRfpBidSpecificationsController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$stateParams', '$scope'];
function HotelRfpBidSpecificationsController(utils, repository, $stateParams, $scope){
  const vm = this;

  $onInit();

  vm.onChanged = value => {
    vm.bid.data = value;
    $scope.$applyAsync();
  };

  function $onInit(){
    vm.bidId = $stateParams.bidId;
    vm.state = 'LOADING';
    
    utils.onLoad(() => repository.getBidPreview(vm.bidId), vm, 'bid', )
      .then(() => {
        const bidData = vm.bid.data;

        vm.rfpName = get(bidData, 'specifications.name', '');
        vm.subHeaderTitle = 'Bid Specifications';
        vm.supplierName = get(bidData, 'supplier.company.name', '');
        vm.state = 'LOADED';
      })
      .catch(() => {
        vm.state = 'ERROR';
      });
  }
}
