<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'RbvHotelRfpBidManagerSendNoLongerInterestedActionReport',
    mixins: [ ActionReportMixin ],
    data () {
      return {
        messages: {
          'OK': '"Decline rebid" Sent',
          'NOT_FOUND': `Bid was not found - "Decline rebid" not sent`,
          'STATUS_CHANGED': 'Bid status was changed - "Decline rebid" not sent',
          'ERROR': '"Decline rebid" not sent due to unknown error'
        }
      }
    },
    computed: {
      title () { return this.items.length === 1 ? '"Decline rebid" not sent!' : 'Failed to send some "Decline rebid" responses!' },
      message () {
        return this.items.length === 1
          ? 'We were not able to send "Decline rebid" because of the reason listed below:'
          : `${(this.items.length - this.failures)} of ${this.items.length} "Decline rebid" were successfully sent.<br /> We were not able to send other because of the reasons listed below:`
      }
    }
  }
</script>
