/**
 * Created by stefan on 10/5/2016.
 */
'use strict';

module.exports = rbMatch;
function rbMatch() {

  return {
    restrict: 'A',
    require: '?ngModel',
    scope:{
      rbMatch:'='
    },
    link: ($scope, $elem, $attr, ngModel)=> {
      function validator(value) {
        var v =  value == $scope.rbMatch;
        //console.log(value +' '+ $scope.rbMatch);
        ngModel.$setValidity('rbmatch',v);
        return value;
      }
      ngModel.$parsers.unshift(validator);
      ngModel.$formatters.push(validator);
      $scope.$watch('rbMatch', function () {
        validator(ngModel.$viewValue);
      });
    }
  }
}







