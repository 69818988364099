<script>
  import { noop } from 'lodash'
  import RbEventsMixin from './RbEventsMixin.vue'

  export default {
    mixins: [ RbEventsMixin],

    methods: {
      afterCancel () {},
      afterSubmit () {},
      beforeCancel () {},
      beforeSubmit () {},
      onSubmit () { return { data: this.data } },

      $formCancelHandler () {
        this.beforeCancel()
        this.$rbEmit({ name: 'cancel'} )
        this.afterCancel()
      },

      $formSubmitHandler () {
        this.$validateForm()
          .then( () => {
            this.beforeSubmit()
            this.$rbEmit(Object.assign({ name: 'hide'} , this.onSubmit() ))
            this.afterSubmit()
          }, noop )
      },

      $showError ( fieldName ) {
        return this.$shouldShowError(fieldName) && this.errors.first(fieldName)
      },

      $shouldShowError ( fieldName ) {
        const field = this.fields[fieldName]
        return field && field.touched && field.invalid
      },

      $validateForm () {
        return this.$validator.validateAll()
          .then(
            (result) => {
              if (result) {
                return Promise.resolve()
              } else {
                this.$touchAllFields()
                return Promise.reject()
              }
            }
          )
      },

      $touchAllFields () { Object.keys(this.fields).forEach( this.$touchField ) },

      $touchField ( fieldName ) {
        const field = this.fields[ fieldName ]
        field.touched = true;
        field.untouched = false
      },

    }
  }
</script>
