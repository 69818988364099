<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header">
      <div>Edit Columns</div>
      <div :class="$style.subHeader">Add or remove columns and arrange them by priority</div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
    >
      <columns-editor
        :columns="data.columns"
        :all-columns="otherColumns"
        :get="get"
        :can-remove="canRemove"
        :can-reposition="canReposition"
        :visible-columns="visibleColumns"
        @pushUp="pushUp"
        @removeColumn="removeColumn"
        @addColumn="addColumn"
      />
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button class="rbButton mainButton">
        <i class="material-icons">save</i>
        Save Changes
      </button>
    </div>
  </modal-form>
</template>

<script>

import ModalForm from 'vRoot/_core/RbModalForm.vue'
import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
import columnsEditor from '../_core/ColumnsEditor.vue';

export default {
    name: 'EditColumnsDialog',
    components: { ModalForm, columnsEditor },
    mixins: [ RbFormMixin, DialogMixin ],
    props: ['columns', 'otherColumns', 'get', 'canRemove', 'canReposition', 'visibleColumns'],
    data() {
       return {
            data : {
                columns: this.columns
            }
        }
    },
    methods: {
        onSubmit(){
            return this.data;
        },
        pushUp(index){
            const columns = Object.assign([], this.data.columns);
            const firstColumn = columns[index - 1];
            columns[index - 1] = columns[index];
            columns[index] = firstColumn;
            this.data.columns = columns;
        },
        removeColumn(column){
            this.data.columns = this.data.columns.filter(col => col !== column);
        },
        addColumn(column){
            this.data.columns = this.data.columns.concat(column);
        },
    }
}
</script>

<style lang="stylus" module>

    .dialog {
        min-width: 500px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }
</style>
