import PHOTO_NOT_AVAILABLE from 'images/ui/no-hotel-photo-available.gif';

export function getImage(image) {
  if(!image) return PHOTO_NOT_AVAILABLE;
  const url = image.url;
  return url ? (url.startsWith('http') ? url : `/images/entities/${url}`): PHOTO_NOT_AVAILABLE;
}


export function parseWebsite(ws){
  if(!ws) return undefined;
  return ws.match(/^http[s]?:\/\//i) ? ws : `http://${ws}`;
}