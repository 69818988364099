<script>
  import { noop, get } from 'lodash';
  import { $state as state, currentUser as currentUserProvider, authSession, ngDialog } from 'root/angular-injector-provider';
  import { onMount, onDestroy } from 'svelte';
  import { NAME as SwitchAccountDialog} from '../../dialogs/switch-account/switch-account.ctrl'
  import { NAME as EditRateLoadingInformationDialog } from '../../../entity/rate-loading-information/dialogs/rate-loading-information/rate-loading-information.ctrl'
  import RfpChecklistPdf from 'root/assets/files/ReadyBid Standard RFP Pre Launch Checklist.pdf';
  import defaultImage from 'images/user-image.png';
  import {PAGE_USER_ACCOUNT_MANAGER} from 'root/states';

  let
    user = null,
    unwatchUser,
    userName = '',
    userCompany = '',
    userPicture = defaultImage,
    menu = [],
    opened = false,
    dropdownIcon;

  onMount(() => { unwatchUser = currentUserProvider().watch(u => user = u) });
  onDestroy(() => { if(unwatchUser) unwatchUser() });

  $: dropdownIcon = opened ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
  $: if(user){
    userName = user.firstName;
    userCompany = get(user, 'currentUserAccount.account.name', '');
    userPicture = user.profilePicture || defaultImage;

    menu = [
      user.currentUserAccount ? { label: 'Company Profile', icon: 'person_pin', action: () => go('company-profile') } : null,
      user.currentUserAccount ? { label: 'Users & Permissions', icon: 'people', action: () => go('rfp-manager-company-profile', {module: 'manageUsers'}) } : null,
      user.isBuyer('HOTEL') ? {label: 'Support Documents', icon: 'library_books', submenu: [
          { label: 'Pre Launch Checklist', icon: 'fact_check', target: '_blank', href: RfpChecklistPdf },
          { label: 'Corporate User Guide', icon: 'info_outline', target: '_blank', href: 'https://www.canva.com/design/DAE87oNozwE/kBuzWfdcGCManM2-wWbiaA/view?utm_content=DAE87oNozwE&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton' },
          { label: 'TMC User Guide', icon: 'info_outline', target: '_blank', href: 'https://www.canva.com/design/DAE80F0jNdM/fdlgBtQcNTJhvZ38fogvng/view?utm_content=DAE80F0jNdM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton#32' },
        ].filter(i => i)} : null,
      user.isBuyer('HOTEL') ? { label: 'Rate Loading Information', icon: 'account_balance_wallet', action: () => showDialog(EditRateLoadingInformationDialog) } : null,
      { label: 'User Profile', icon: 'settings_applications', action: () => go('user.profile') },
      user.isBuyer('HOTEL') ? { label: 'Hotel Directory', icon: 'view_list', action: () => go('hotelDirectory', {accountId: user.getAccountId()}) } : null,
      { label: 'Contact / Support', icon: 'info', href: 'http://readybidnet.freshdesk.com' },
      'divider',
      user.userAccounts > 1 ? { label: 'Switch Account', icon: 'autorenew', action: () => showDialog(SwitchAccountDialog) } : null,
      { label: 'Manage Accounts', icon: 'manage_accounts', action: () => go(PAGE_USER_ACCOUNT_MANAGER)},
      'divider',
      { label: 'Sign Out', icon: 'power_settings_new', action: () => authSession().forget() }
    ].filter(i => i);
  }

  function go(stateName, params){
    state().go(stateName, params);
  }

  function showDialog(dialogName) {
    ngDialog().show(dialogName).catch( noop )
  }

  function fallbackToDefaultImage(){
    if(userPicture !== defaultImage){
      userPicture = defaultImage;
    }
  }
</script>

<div class="Component" data-icon-after="{dropdownIcon}" on:click={() => opened = true}>
  <div class="Text">
    <div class="Ellipsis text-right">Hi {userName}</div>
    <div class="Ellipsis text-right">{userCompany}</div>
  </div>

  <img class="Picture" src="{userPicture}" alt="User" on:error={() => fallbackToDefaultImage()}>
</div>

{#if opened}
  <div class="Backdrop" on:click={() => opened = false}>
    <div class="Menu">
      {#each menu as item, i (i)}
        {#if item === 'divider'}
          <div class="Divider"></div>
        {:else}
          {#if item.href}
            <a class="ActionLink" href="{item.href}" data-icon-before="{item.icon}">{item.label}</a>

          {:else if item.submenu}
            <div class="SubmenuTitle" data-icon-before="{item.icon}">{item.label}</div>
            <div class="Submenu">
              {#each item.submenu as subitem, j (j)}
                {#if subitem === 'divider'}
                  <div class="Divider"></div>
                {:else}
                  {#if subitem.href}
                    <a class="ActionLink" href="{subitem.href}" target="{subitem.target || null}" data-icon-before="{subitem.icon}">{subitem.label}</a>
                  {:else}
                    <div class="ActionButton" data-icon-before="{subitem.icon}" on:click={() => subitem.action()}>{subitem.label}</div>
                  {/if}
                {/if}
              {/each}
            </div>

          {:else}
            <div class="ActionButton" data-icon-before="{item.icon}" on:click={() => item.action()}>{item.label}</div>
          {/if}
        {/if}
      {/each}
    </div>
  </div>
{/if}


<style lang="stylus">.Component {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-size: 13px;
  line-height: 1.2;
  color: #fff;
  cursor: pointer;
}
.Text {
  overflow: hidden;
  max-width: 200px;
  margin-right: 10px;
}
.Picture {
  flex: 0 0 auto;
  height: 42px;
  border: 1px solid #fff;
  border-radius: 3px;
  background: #e7ebed;
}
.Menu {
  position: absolute;
  top: 45px;
  right: 5px;
  min-height: 30px;
  min-width: 100px;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
}
.Divider {
  border: 0;
  display: block;
  height: 1px;
  margin: 0;
  overflow: hidden;
  background-color: rgba(120,130,140,0.13);
  padding: 0;
}
.Submenu .ActionButton,
.Submenu .ActionLink,
.Submenu .ActionLink:visited {
  padding-left: 25px;
}
.ActionButton,
.ActionLink,
.ActionLink:visited,
.SubmenuTitle {
  text-align: left;
  text-decoration: none;
  padding: 0 10px;
  font-size: 14px;
  height: 36px;
  min-width: 190px;
  box-sizing: border-box;
  color: #263238;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  display: flex;
}
.ActionButton:before,
.ActionLink:before,
.ActionLink:visited:before,
.SubmenuTitle:before {
  color: #90a4ae;
  margin-right: 10px;
}
.ActionButton,
.ActionLink,
.ActionLink:visited {
  cursor: pointer;
}
.ActionButton:hover,
.ActionLink:hover,
.ActionLink:visited:hover,
.ActionButton:hover:visited,
.ActionLink:hover:visited,
.ActionLink:visited:hover:visited {
  background-color: #eceff1;
  border-color: #eceff1;
  color: #263238;
}
</style>

