import formatDate from 'utils/formatDate';
import getBidStateOptions from './bid-supplier-states-library';

export default function formatBid(bid){
  const ss = getBidStateOptions(bid);

  return {
    stateDate: formatDate(bid.state.at, 'dddd, MMMM Do'),
    stateTime: formatDate(bid.state.at, 'LTS'),
    sabreCode: formatSabreCode(bid),
    ...ss
  };
}

function formatSabreCode(bid){
  const sc = parseInt(bid && bid.questionnaire && bid.questionnaire.response && bid.questionnaire.response.answers && bid.questionnaire.response.answers.SABRE_PROPCODE, 10);
  return isNaN(sc) ? '' : `${sc}`;
}
