import {statusMatches, RESPONDED, NEGOTIATION_SENT, NEGOTIATION_RESPONDED, NEGOTIATION_FINALIZED, FINAL_AGREEMENT, NO_THANK_YOU_PENDING, NO_LONGER_INTERESTED} from 'rfp/bid-manager/main/core/actions/action/status';
import {SvelteDialog} from 'root/v-app/rbServices';
import SendNoThankYouDialog from './SendNoThankYouDialog.svelte';
import SendNoThankYouActionReport
  from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendNoThankYouActionReport.vue';

import noop from 'lodash/noop';

export default function SendNoThankYou(handleActionResultFn){
  return function (selectedBidsReport) {
    return {
      label: 'Send "No Thank You"',
      icon: 'thumb_down',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [RESPONDED, NEGOTIATION_SENT, NEGOTIATION_RESPONDED, NEGOTIATION_FINALIZED, FINAL_AGREEMENT, NO_THANK_YOU_PENDING, NO_LONGER_INTERESTED]),
      action: () => SvelteDialog.show( SendNoThankYouDialog, { bids: selectedBidsReport.bids })
        .then( actionReport => handleActionResultFn(actionReport, selectedBidsReport.bids, SendNoThankYouActionReport))
        .catch( noop )
    }
  }
}