<template>
  <div class="questionnaireQuestion list" :class="classes">
    <div class="questionnaireQuestionLabel list">
      <span class="questionnaireQuestionLabelOrd" :class="classes">{{template.ord}}.</span>
      <span>{{template.name}}</span>
    </div>
    <div class="questionnaireQuestionOptions">
      <template v-for="option in template.options">
        <label class="questionnaireQuestionOption">
          <span class="questionnaireQuestionOptionText"
                :class="{'on' : value === option.value }">
           {{option.label}} <span v-if="isUserDefined">({{option.value}})</span>
          </span>
        </label>
      </template>
    </div>

    <tooltip
      :ord="template.ord"
      :title="template.name"
      :description="template.description"
      :required="!!isRequired"
    />

  </div>
</template>

<script>
  import QuestionnaireQuestionMixin from '../mixins/QuestionnaireQuestionMixin.vue'

  export default {
    name: 'rb-questionnaire-question-list',
    mixins: [ QuestionnaireQuestionMixin ]
  }
</script>

<style lang="stylus">

  .questionnaireQuestion.list {
      flex: 1 1 auto
  }

  .questionnaireQuestionLabel.list {
    max-width: 200px;
  }

  .questionnaireQuestionOptions {
    display: block;
    line-height: 14px;
    white-space: nowrap;
  }

  .questionnaireQuestionOption {
    display inline-block
    position: relative
    line-height 14px
    margin-top 2px
  }

  .questionnaireQuestionOptionText {
    display inline-block
    cursor: pointer;
    margin: 0 8px !important;
    padding: 0 4px 0 8px !important;
    text-transform: uppercase;
    font-size: 10px;
    vertical-align: middle;

    &:before {
      content: '';
      position absolute
      width: 11px;
      height: 11px;
      top: 2px;
      left: 0;
      background: #eee;
      border: 1px solid #ddd;
    }

    &:after {
      opacity: 0.1;
      content: '';
      position: absolute;
      width: 7px;
      height: 3px;
      background: transparent;
      top: 5px;
      left: 2px;
      border: 2px solid #546E7A;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
    }

    &.on:after {
      opacity: 1
    }
  }
</style>
