<template>
  <div :class="classes.overlay">
    <div :class="classes.container">

      <div :class="classes.header" v-if="tableOpen">
        <view-toggler class="flex-hold" :is-table-open="tableOpen" @toggled="toggleTable" />
        <filter-bar
          :filters="filters"
          :chains="chains"
          @filtersChanged="$rbEmit"
          key="filters"
        />
        <local-search :class="$style.tableSearch" :searchTerm="query" @onSearch="setQuery" />
        <sorter :sorter="sort" size="large" @onDestinationSortChanged="setSortKey" />
        <action-buttons :class="$style.actions"
          @save="save"
          @setDefaultFilters="setDefaultFilters"
          @done="done" />
      </div>

      <template v-else>
        <div :class="classes.header">
          <view-toggler :is-table-open="tableOpen" @toggled="toggleTable"/>
          <properties-counter :class="$style.counter" :count="this.suppliersCount" />
        </div>

        <div :class="$style.searchFilterContainer">
          <div :class="$style.searchFilterHeader">
            <filter-bar
              :filters="filters"
              :chains="chains"
              @filtersChanged="$rbEmit"
              key="filters"
            />
            <local-search class="flex-100" @onSearch="setQuery" :searchTerm="query" />
            <sorter class="flex-hold" size="small" :sorter="sort" @onDestinationSortChanged="setSortKey"/>
          </div>
        </div>
      </template>

      <v-list :class="classes.items" :items="sortedSuppliers" :size="62" :scroll-to="scroll">
        <template slot="before" v-if="tableOpen">
          <div :class="$style.tableHead">
            <div :class="$style.tableRowHead">
              <sortable class="msGridColumn1" :class="$style.centered" :id="'bidState'" :by="sort"
                        @onDestinationSortChanged="setSortKey">ADD</sortable>
              <sortable class="msGridColumn3" :id="'name'" :by="sort"
                        @onDestinationSortChanged="setSortKey"><properties-counter :count="suppliersCount" /></sortable>
              <sortable class="msGridColumn5" :id="'city'" :by="sort"
                        @onDestinationSortChanged="setSortKey">City</sortable>
              <sortable class="msGridColumn7" :id="'state'" :by="sort"
                        @onDestinationSortChanged="setSortKey">State/Region</sortable>
              <sortable class="msGridColumn9" :id="'country'" :by="sort"
                        @onDestinationSortChanged="setSortKey">Country</sortable>
              <sortable class="msGridColumn11" :id="'distanceMi'" :by="sort"
                        @onDestinationSortChanged="setSortKey">Distance</sortable>
              <sortable class="msGridColumn13" :id="'chain'" :by="sort"
                        @onDestinationSortChanged="setSortKey">Chain</sortable>
              <sortable class="msGridColumn15" :id="'brand'" :by="sort"
                        @onDestinationSortChanged="setSortKey">Brand</sortable>
            </div>
          </div>
        </template>

        <template slot-scope="item">
          <div v-if="tableOpen" :class="$style.tableBody" >
            <expanded-supplier :class="$style.tableRowBody"
                               :supplier="item.item"
                               :filters="filters"
                               :sorter="sort"
                               @onRemoveFromBidManager="$rbEmit"
                               @onAddToBidManager="$rbEmit" />
          </div>
          <shrinked-supplier v-else
                             :supplier="item.item"
                             :sorter="sort"
                             @onRemoveFromBidManager="$rbEmit"
                             @onAddToBidManager="$rbEmit"

                             @onSupplierMouseEnter="$rbEmit"
                             @onSupplierMouseLeave="$rbEmit"
                             @onPanMapTo="$rbEmit"/>
        </template>
      </v-list>
      <div :class="$style.tableFooter" v-if="!tableOpen">
        <action-buttons :class="$style.actions"
          @save="save"
          @setDefaultFilters="setDefaultFilters"
          @done="done" />
      </div>
    </div>
  </div>
</template>

<script>
  import rbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue';
  import ViewToggler from './ViewToggler.vue'
  import LocalSearch from './LocalSearch.vue';
  import sorter from './TableSorter.vue';
  import PropertiesCounter from './PropertiesCounter.vue'
  import Sortable from './Sortable.vue';
  import vList from 'vRoot/_core/RbVirtualList.vue';
  import shrinkedSupplier from './ListSupplier.vue';
  import expandedSupplier from './TableSupplier.vue';
  import filterBar from './travel-destinations-map-filter-bar/FilterBar.vue';
  import ActionButtons from './travel-destinations-map-filter-bar/filter-action-buttons/ActionButtons.vue'
  import {default as suppliersStore, updateQuery, updateSort} from 'root/rfp-hotel/main/rfp/pages/destinations/suppliers.store.js'

  export default {
    name: 'travel-destinations-map-suppliers-table',
    mixins: [rbEventsMixin],
    components: {
      ViewToggler, LocalSearch, sorter, PropertiesCounter, ActionButtons,
      Sortable, vList, shrinkedSupplier, expandedSupplier, filterBar
    },
    data() {
      return {
        query: '',
        sort: {},
        scroll: -1,
        $$storeSubscription: null,
        suppliersCount: [],
        sortedSuppliers: []
      }
    },
    props: {
      'table-open': {
        type: Boolean,
        required: true,
        default: false
      },
      filters: {
        type: Object,
        required: true,
        default: () => {}
      },
      chains: {
        type: Array,
        required: true
      },
      scrollTo: {}
    },
    computed: {
      classes () {
        return this.tableOpen
          ? {
            overlay: this.$style.tableOverlay,
            container: this.$style.tableContainer,
            header: this.$style.tableHeader,
            items: this.$style.table,
          }
          : {
            overlay: this.$style.listOverlay,
            container: this.$style.listContainer,
            header: this.$style.listHeader,
            items: this.$style.list,
          }
      }
    },
    watch: {
      scrollTo (val) {
        const index = this.sortedSuppliers.findIndex(s => s.id === val);
        this.scroll = index === -1 ? this.scroll : index;
      }
    },
    mounted () {
      const vm = this;
      this.$$storeSubscription = suppliersStore.subscribe($sv => {
        vm.suppliersCount = $sv.suppliersCount;
        vm.sortedSuppliers = [...$sv.sortedSuppliers];
        vm.sort = {...$sv.sort};
      });
    },
    methods: {
      setQuery(event){
        this.query = event.value;
        updateQuery(this.query);
      },
      setSortKey(event){
        updateSort(event.id);
      },
      toggleTable(){
        this.$rbEmit({
          name: 'toggleTable'
        });
      },
      save () {
        this.$rbEmit({ name: 'saveFilters', filters: this.filters })
      },
      done () {
        this.$rbEmit({ name: 'done' })
      },
      setDefaultFilters(){
        this.$rbEmit({ name: 'setDefaultFilters', filters: this.filters })
      }
    },
    destroyed() {
      if(this.$$storeSubscription) {
        this.$$storeSubscription();
      }
    }
  }
</script>

<style lang="stylus" module >

  .overlay {
    position absolute
    top 0
    bottom 0
    right 0
    transition width .3s linear, background .4s linear
    box-shadow 0 8px 16px 0 rgba(0,0,0,0.2)
  }

  .listOverlay {
    composes overlay
    width 421px
  }

  .tableOverlay {
    composes overlay
    width 100%
    background rgba(0,0,0, .3)
  }

  .container {
    height 100%
    display flex
    flex-direction column
    background #ECEFF1
  }

  .listContainer {
    composes container
  }

  .tableContainer {
    composes container
    margin-left 1vw
  }

  .header {
    display flex
    align-items center
  }

  .listHeader {
    composes header
    flex 0 0 34px
    padding-right: 10px
    margin-top: -1px;

    border-top: 1px solid #eceff1;
  }

  .tableHeader {
    composes header
    flex 0 0 auto

    background #ECEFF1
    padding 11px
  }

  .tableHeader :global(.filter-bar){
    flex 1
  }

  .tableHeader :global(.filter-bar .filter), .tableHeader :global(.travelDestinationsMapSuppliersFilterInputWrapper) {
    margin-left: 5px;
    border-left: 1px solid #ced8dc;
    padding-left: 5px;
  }

  .tableHeader :global(.label) {
    display block
  }

  .tableHeader .actions{
    padding-left: 5px;
    border-left: 1px solid #ced8dc;
  }

  .tableHeader :global(.rbvDropDown) {
    top 100%
  }

  .items {
    overflow-y auto
    border-bottom 1px solid #ced8dc
  }

  .table {
    composes items
    background #ECEFF1
    position relative
  }

  .list {
    composes items
    flex 1 1 auto
  }

  .counter {
    margin-left auto
    font-size 14px
  }

  .searchFilterContainer {
    flex: 0 0 70px
    background #ECEFF1
    border 1px solid #D9E0E3
    border-top 0
    padding 6px 10px 9px
  }

  .searchFilterHeader {
    line-height 26px
    font-size 12px
    color #546E7A
    display flex
    align-items center
    margin-bottom 7px
    flex-wrap wrap
  }

  .searchFilterHeader :global(.rbvDropDown) {
    right: calc(100% + 10px);
    top: 0;
    width: 280px;
    min-width: unset !important;
  }

  .tableSearch {
    max-width 300px
  }

  .tableHead {
    background: #ECEFF1;
    font-size : 12px;
    color : #546E7A;
    position sticky
    top -1px
    z-index 1
    border-bottom: 1px solid #D9E0E3;
  }

  .tableRow {
    display grid
    grid-template-columns: 60px 1fr .5fr 100px 100px 100px .5fr 1fr;
    grid-column-gap: 5px;

    display: -ms-grid;
    -ms-grid-columns: 60px 5px 1fr 5px .5fr 5px 100px 5px 100px 5px 100px 5px .5fr 5px 1fr;
  }

  .tableRowHead {
    composes tableRow
    height 38px
  }

  .tableRowBody {
    composes tableRow
    height 61px
    line-height 61px
    margin-bottom 1px
  }

  .centered {
    justify-content center
  }

  .tableFooter .actions {
    width 100% !important
    padding 10px
    box-sizing border-box
  }

  .overlay :global(.rbButton) {
    border-radius 20px;
  }

  .tableFooter .actions :global(.rbvDropDown) {
    bottom 36px
  }
</style>
