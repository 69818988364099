/**
 * Created by DejanK on 4/26/2017.
 *
 * Bid Manager Filter By
 */
import HTML from './rb-bid-manager-filter-by.html'
import {default as viewStateStore, setFilterBy} from '../../stores/view-state'
import {scrollToTop} from '../../stores/bid-manager'

export default {
  template: HTML,
  controller: RbBidManagerFilterBy
}

RbBidManagerFilterBy.$inject = ['$scope'];
function RbBidManagerFilterBy($scope) {
  const vm = this

  const vssUnsubscribe = viewStateStore.subscribe($vss => {
    $scope.$timeout(() => {
      vm.filteredBy = $vss.tableFilteredBy;
      vm.filterItems = $vss.filterByOptions;
    });
  });

  vm.$onDestroy = () => {
    vssUnsubscribe();
  }

  vm.onFilterByChanged = onFilterByChanged;

  function onFilterByChanged(){
    scrollToTop();
    setFilterBy(vm.filteredBy)
  }
}
