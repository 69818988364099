import {
  notificationUtils,
  ngDialog,
  mainAPI,
  $state
} from 'root/angular-injector-provider';
import { NAME as askToConfirmRfpContractDialog } from 'root/rfp-hotel/main/bid/dialogs/ask-to-confirm-rfp-contract/ask-to-confirm-rfp-contract';
import { Dialog as VueDialog } from 'root/v-app/rbServices';
import ForceSendingInvalidResponsesQuestion from 'vRoot/rfp-hotel/bid-manager/actions/questions/ForceSendingInvalidResponsesQuestion.vue';
import ForceSendingResponseWithoutRatesQuestion from 'vRoot/rfp-hotel/bid-manager/actions/questions/ForceSendingResponseWithoutRatesQuestion.vue';

import get from 'lodash/get';
import noop from 'lodash/noop';

import NotificationDialog from 'vRoot/_core/notifications/NotificationDialog.vue';
import NotificationDialogButtonConfigurations from 'vRoot/_core/notifications/button-configuration.js';

const ERROR_MESSAGES = {
  'NOT_FOUND': 'Bid was deleted. You will be redirected to your Bid Manager.',
  'STATUS_CHANGED': 'Bid has changed. Response might have been sent by someone else. You will be redirected to your Bid Manager.',
  'ERROR': 'Response was already submitted!',
  'DEFAULT': 'Something went wrong! Please try again.',
};

export default {
  /**
   * TODO: merge questionnaire on server!!!
   * -- IF http response returns RESPONSE_NOT_VALID and form doesn't report the error we have a problem. This should be rare, but with lack of discipline and organization it is possible that questionnaire templates become desynchronized
   * ---- the only way to avoid this is to force client to load questionnaire template from server
   * ------ as a quick fix, template is loaded from server every time questionnaire needs to be displayed (either with 2 requests or with one)
   * ------- it is relatively safe to cache template on client, since it is also cached on backend
   * ------ probably best solution would be to always merge questionnaire on server. This however requires removal of merge code on client.
   */

  saveResponse,

  saveDraftResponse(saveFn, onSuccess, onError){
    return saveFn()
      .then(validateReport)
      .then( onSuccess || noop, onError || showResponseSaveError)
  },

  reviseResponse(answers, bidId) {
    return mainAPI().reviseBidResponse(bidId, answers);
  },

  askToSendToContact,

  toBidManager
};

function validateReport(report){
  return report.validate ? report.validate() : report;
}

function saveResponse(response, saveFn, options = {}){
  return isResponseValid(response)
    .then(areRatesSet)
    .then(confirmThatRfpContractIsSet)
    .then(saveFn)
    .then(validateReport)
    .then( options.onSuccess || showResponseSaveSuccess, options.onError || showResponseSaveError)


  function isResponseValid(r) {
    return (Object.keys(r.errors).length === 0
        ? Promise.resolve(r.response)
        : Promise.reject(r.response)
    ).catch(shouldErrorsBeIgnoredDialog);
  }

  function areRatesSet(r) {
    const rates = [
        'DYNAMIC_PCT_Discount',
        'LRA_S1_RT1_SGL',
        'LRA_S1_RT1_DBL',
        'NLRA_S1_RT1_SGL',
        'NLRA_S1_RT1_DBL',
        'GOVT_S1_RT1_SGL',
        'GOVT_S1_RT1_DBL'
      ],
      answers = r.answers;

    return (rates.some(rate => answers[rate])
        ? Promise.resolve(r)
        : Promise.reject(r)
    ).catch(shouldRatesBeIgnored);
  }

  function confirmThatRfpContractIsSet(r) {
    return (r.answers.RFP_CONTRACT === 'Y' || options.ignoreContract
        ? Promise.resolve(r)
        : Promise.reject(r)
    ).catch(confirmRfpContractDialog);
  }

  function shouldErrorsBeIgnoredDialog(r) {
    return VueDialog.show(ForceSendingInvalidResponsesQuestion, {isBulk: false})
      .then(() => r);
  }

  function shouldRatesBeIgnored(r) {
    return VueDialog.show(ForceSendingResponseWithoutRatesQuestion)
      .then(() => r);
  }

  function confirmRfpContractDialog(r) {
    return ngDialog()
      .show(askToConfirmRfpContractDialog, {locals: {
          SupplierName: r.answers.PROPNAME,
          asSupplier: options.asSupplier || false
        }})
      .then(() => {
        r.answers.RFP_CONTRACT = 'Y';
        return r;
      });
  }
}

function askToSendToContact(supplierContact) {
  return VueDialog.show(NotificationDialog, {
    title: 'Please Confirm',
    subTitle: 'Send to Supplier?',
    message: `This bid contains a supplier contact - ${
      supplierContact.firstName
    } ${supplierContact.lastName} (${
      supplierContact.emailAddress
    }). Would you like us to send a final agreement notification email to this contact?`,
    buttonsConfiguration: NotificationDialogButtonConfigurations.YES_NO
  });
}

function showResponseSaveSuccess(actionReport){
  const buyerName = get(actionReport.getData(), 'buyer.company.name', 'the buyer');
  const rfpId = get(actionReport.getData(), 'rfp._id');
  return notificationUtils()
    .showSuccessDialog({
      dialogData: {
        heading: 'Questionnaire Response',
        title: 'Submitted Successfully',
        message: `RFP was received successfully at ${buyerName}`
      }
    })
    .finally(() => toBidManager(rfpId))
}

function showResponseSaveError(error) {
  if(!error) return Promise.reject();

  const status = error && error.getStatus && error.getStatus() || 'DEFAULT';

  return notificationUtils().showErrorDialog({dialogData: {
      heading: 'Response',
      title: 'Not Submitted!',
      message: ERROR_MESSAGES[status] || ERROR_MESSAGES.DEFAULT
    }})
    .finally(() => { if (['NOT_FOUND', 'STATUS_CHANGED' ].indexOf(status) !== -1) toBidManager(); });
}

function toBidManager(rfp) {
  return $state().go('bidManager', {viewId: null, rfp})
    .catch(noop);
}
