/**
 * Created by DejanK on 10/10/2016.
 * rbSorted should be asc or desc
 */
import angular from 'angular'
import './rb-sorted.scss'

export default function(){
  return {
    template: templateFn,
    scope: {
      rbSorted: '<'
    }
  }
}

function templateFn(element){
  element.addClass('rbSortedContainer clickable')
  angular.element(element.children()[0]).addClass('rbSortedLabel')
  element.append(`<div class="rbSorted" ng-class="'rbSortedOn-' + rbSorted" ><rb-icon class="rbSortedAsc" rb-src="arrow_drop_up" /><rb-icon class="rbSortedDesc" rb-src="arrow_drop_down" /></div>`)
  return element[0].innerHTML
}
