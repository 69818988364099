<template>
  <div
    class="vModal vModalDefault"
    :class="$style.dialog"
  >
    <div :class="$style.header">
      <img
        src="~images/ui/icon-warning.svg"
        :class="$style.headerIcon"
      >

      <div>
        <h2 :class="$style.headerSub">
          <span :class="$style.headerSubText">Please Note</span>
        </h2>

        <h1 :class="$style.headerMain">
          <span :class="$style.headerMainText">{{ title }}</span>
        </h1>
      </div>

      <button
        class="vModalDefaultCloseButton"
        @click="hide()"
      >
        close
      </button>
    </div>

    <div :class="$style.modalBody">
      <div
        :class="$style.bodyText"
        v-html="message"
      />

      <div :class="$style.list">
        <div :class="$style.listHeading">
          <div :class="$style.listHeadingName">
            Bid - Supplier Name
          </div>

          <div :class="$style.listHeadingStatus">
            Bid Status
          </div>
        </div>

        <report-item
          v-for="item in items"
          :key="item.tObject._id"
          :report="item"
          :messages="messages"
        />
      </div>
    </div>

    <div class="vModalDefaultFooter">
      <slot name="footer">
        <button
          class="rbButton mainButton"
          :class="$style.okButton"
          @click="hide()"
        >
          OK
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import ReportItem from './ReportItem.vue'

  export default {
    name: 'RbvHotelRfpBidManagerActionReportMixin',
    components: { ReportItem },
    mixins: [ DialogMixin, RbEventsMixin ],
    props: {
      report: {
        type: Object,
        required: true
      }
    },
    computed: {
      items () { return this.report.children || this.report.reports },
      failures () { return this.report.failuresCount }
    }
  }
</script>

<style lang="stylus" module="">

  .dialog {
    width 750px
  }

  .header {
    flex 0 0 auto
    display flex
    flex-direction row
    padding 20px
    position relative
  }

  .headerIcon {
    flex 0 0 48px
    height 48px
    margin-right 20px
  }

  .headerText {
    color : #546E7A;
    text-shadow : 1px 0 0 rgba(0, 0, 0, 0.15);
    font-weight: normal;
    line-height 1
  }

  .headerSub {
    composes headerText
    height 18px
    font-size : 17px;
    text-transform : uppercase;
  }

  .headerSubText{
    vertical-align text-top
  }

  .headerMain{
    composes headerText
    height 30px
    font-size : 28px;
  }

  .headerMainText{
    vertical-align text-bottom
  }

  .modalBody {
    flex: 0 1 auto;
    padding: 0;
    margin: 10px 0 5px;
    display flex
    flex-direction column
  }

  .bodyText{
    padding: 0 20px;
    font-size: 14px;
    line-height: 28px;
    color: #546E7A;
    flex: 0 0 auto;
    margin-bottom 25px
  }

  .list{
    flex: 1 0 300px
    overflow-y auto;
    position relative
  }

  .listHeading {
    height: 41px
    display flex
    border-bottom 1px solid #ECEFF1
    align-items center
    position sticky
    font-size : 12px;
    color: #263238;
  }

  .listHeadingName {
    flex 1 3 auto
    min-width 200px
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    margin-left: 20px;
  }

  .listHeadingStatus {
    flex 3 1 auto
    min-width 200px
    text-align right
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    margin-right: 20px;
  }

  .okButton{
    width: 126px;
    margin 0 10px
  }

</style>
