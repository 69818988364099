export default function autoSave(file, fileType, fileName){
  const blob = new Blob([file], {type: fileType});

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const downloadLink = createLink();

    document.body.appendChild(downloadLink);
    downloadLink.click();
    window.URL.revokeObjectURL(downloadLink.href);
    document.body.removeChild(downloadLink);
  }

  function createLink() {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName;
    downloadLink.style.display = 'none';
    return downloadLink;
  }
}
