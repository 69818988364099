<RbHeader>
  {#if views && selectedView}
    <BidManagerViewPicker {views} {selectedView} />
  {/if}
</RbHeader>


<script>
  import RbHeader from "./RbHeader.svelte";
  import BidManagerViewPicker from "./BidManagerViewPicker.svelte";

  export let
    views,
    selectedView;
</script>

