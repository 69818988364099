import {logError} from 'root/log/log';

let injector

export {
  injectorGet as AngularServices,
  $newScope,
  $state,
  PublishAngularInjectorForVue,
  accountRepository,
  currentUser,
  httpErrorHandler,
  mainAPI,
  authAPI,
  notificationUtils,
  ngDialog,
  hotelRfpBidManagerService,
  hotelRfpNegotiationsPanelMessagingService,
  hotelDirectoryRepository,
  rfpManagerRepository,
  AccountRepository,
  InvitationService,
  InvitationHandlersRepository,
  RfpDestinationsViewService,
  RfpHotelBidRepository,
  authSession,
  PublicAuth,
  RfpHotelRfpRepository,
  BidManagerBidActionsService,
  BidManagerActionsFactory,
  BidManagerRedirectService,
  BidManagerService,
  BidManagerRepository,
  RfpHotelRfpDestinationsRepository,
  $compile,
}

PublishAngularInjectorForVue.$inject = ['$injector']
function PublishAngularInjectorForVue($injector) {
  injector = $injector;
}

function injectorGet(name){
  if(!injector) {
    logError('Angular Injector not Loaded');
  }
  return injector.get(name)
}

function accountRepository(){
  return injectorGet('AccountRepository')
}

function currentUser(){
  return injectorGet('CurrentUser')
}

function mainAPI(){
  return injectorGet('MainAPI')
}

function authAPI(){
  return injectorGet('AuthAPI')
}

function httpErrorHandler(){
  return injectorGet('HttpErrorHandler')
}

function $newScope(){
  const $rootScope = injectorGet('$rootScope')
  return $rootScope.$new(true, $rootScope)
}

function $state(){
  return injectorGet('$state')
}

function notificationUtils(){
  return injectorGet('NotificationUtils')
}

function hotelDirectoryRepository(){
  return injectorGet('rbvHotelDirectoryRepository');
}
function ngDialog(){
  return injectorGet('rbDialog')
}

function hotelRfpBidManagerService(){
  return injectorGet('BidManagerService')
}

function hotelRfpNegotiationsPanelMessagingService(){
  return injectorGet('NegotiationsPanelMessagingService')
}

function rfpManagerRepository() {
  return injectorGet('rfpManagerRepository')
}

function AccountRepository() {
  return injectorGet('AccountRepository')
}

function InvitationService() {
  return injectorGet('InvitationService')
}

function InvitationHandlersRepository() {
  return injectorGet('InvitationHandlersRepository')
}

function RfpDestinationsViewService() {
  return injectorGet('RfpDestinationsViewService');
}

function RfpHotelBidRepository() {
  return injectorGet('RfpHotelBidRepository');
}

function RfpHotelRfpRepository() {
  return injectorGet('RfpHotelRfpRepository');
}

function authSession() {
  return injectorGet('AuthSession');
}

function PublicAuth() {
  return injectorGet('PublicAuth');
}

function BidManagerBidActionsService(){
  return injectorGet('BidManagerBidActionsService')
}

function BidManagerActionsFactory(){
  return injectorGet('BidManagerActionsFactory')
}

function BidManagerRedirectService(){
  return injectorGet('BidManagerRedirectService')
}

function BidManagerService(){
  return injectorGet('BidManagerService')
}

function BidManagerRepository(){
  return injectorGet('BidManagerRepository')
}

function RfpHotelRfpDestinationsRepository(){
  return injectorGet('RfpHotelRfpDestinationsRepository')
}

function $compile(){
  return injectorGet('$compile')
}
