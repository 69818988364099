import RbLog from '../log/log';

export default AuthSessionService

AuthSessionService.$inject = ['$state', 'CurrentUser'];
function AuthSessionService($state, currentUser) {
  const $service = this;

  $service.continue = continueSession;
  $service.reauthenticate = reAuthenticateUser;
  $service.forget = forget;

  function reAuthenticateUser(target) {
    currentUser.forget();
    const signInState = 'signIn';

    if ($state.current.name !== signInState) {
      $state.go(signInState, {
        redirect: encodeURIComponent(btoa(JSON.stringify(target || {
          'toState': $state.current.name,
          'toParams': $state.params
        })))
      });
    }
  }

  function continueSession() {
    const redirectString = $state.params.redirect;
    if (redirectString) {
      try {
        const r = JSON.parse(atob(decodeURIComponent(redirectString)));
        if (r && r.toState) {
          if (['signIn', 'signUp', 'error', 'success'].indexOf(r.toState) === -1) {
            $state.go(r.toState, r.toParams || {}, {reload: true});
            return;
          }
        }
      } catch (ignore) {
        RbLog.info(ignore)
      }
    }
    
    $state.go('bidManager', {viewId: null}, {reload: true});
  }

  function forget() {
    currentUser.forget();
    $state.go('Home');
  }
}
