<script>
  import infiniteScroll from './infiniteScroll';
  import ListSeparator from './ListSeparator.svelte';
  import LoadingMore from './LoadingMore.svelte';

  export let bids, pagination;

  let p;

  $: p = {...pagination, threshold: 1000};
</script>


<div class="ListContainer" use:infiniteScroll="{p}">
  <div class="ListHeader">
    <div class="ItemTitle noWrap">Status</div>

    <div class="StatusDate noWrap">Time</div>

    <div class="ItemSubtitle noWrap">RFP Name</div>

    <div class="ItemSubtitle noWrap">Hotel Name</div>

    <div class="ItemSubtitle s noWrap">Sabre</div>

    <div class="ActionsArea noWrap">Actions</div>
  </div>

  <div class="List">
    {#each bids as group}
      <ListSeparator label="{group.date}" />

      {#each group.bids as bid}
        <div class="ListItem">
          <div class="{bid.stateColor}" class:ListItemIcon="{1}"
               data-icon-before="{bid.stateIcon}">
          </div>

          <div class="ItemTitle noWrap">{bid.stateLabel}</div>

          <div class="StatusDate noWrap">{bid.stateTime}</div>

          <div class="ItemSubtitle noWrap">{bid.rfp.specifications.name}</div>

          <div class="ItemSubtitle noWrap">{bid.supplier.company.name}</div>

          <div class="ItemSubtitle s noWrap">{bid.sabreCode}</div>

          <div class="ActionsArea noWrap">
            {#each [...bid.actions].reverse() as action}
              <div>
                <button class="aBtn asSmall asIcon"
                        data-icon="{action.icon}"
                        on:click={action.action} />
                <div class="rbTooltip" data-tooltip="placement:left-start">
                  {action.label}
                </div>
              </div>
            {/each}
          </div>
        </div>
      {/each}
    {/each}
  </div>
  <LoadingMore isLoading="{p.loadingMore}" />
</div>


<style lang="stylus">.ListContainer {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.ListContainer :global(.ListSeparator) {
  top: 60px !important;
}
:global(.List > div:first-of-type) {
  margin-top: 0 !important;
}
.ListItem {
  margin: 30px 10px;
  display: flex;
  height: 60px;
  line-height: 60px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 1px 1px 3px 2px rgba(0,0,0,0.2);
  font-size: 14px;
  color: #37474f;
}
.ListItemIcon {
  flex: 0 0 auto;
}
.ListItemIcon:before {
  height: 60px;
  font-size: 48px;
  width: 60px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}
.ItemTitle {
  padding: 0 10px;
  flex: 0 0 140px;
}
.StatusDate {
  padding: 0 10px;
  flex: 0 0 80px;
}
.ItemSubtitle {
  padding: 0 10px;
  flex: 1 1 100%;
}
.ItemSubtitle.s {
  flex: 0 0 50px;
}
.ActionsArea {
  flex: 0 0 90px;
  display: flex;
  padding: 0 10px;
  justify-content: flex-end;
}
.aBtn {
  border: 0;
  background: transparent;
  margin-right: 2px;
}
.aBtn:hover {
  color: #00a99d;
}
.ListHeader {
  display: flex;
  height: 60px;
  line-height: 60px;
  background: #f5f6f7;
  font-size: 16px;
  color: #16272b;
  padding-left: 60px;
  margin: 0 10px;
  border-radius: 6px 6px 0 0;
  position: sticky;
  top: 0;
  box-shadow: 0 -6px 0px 6px #fff;
}
</style>
