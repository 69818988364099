{#if maximized}
  <div class="Container">
    <div class="LinkContainer" class:Active="{active}" on:click={() => toState()}>
      <div class="LinkText"
           class:Active="{active}"
           data-icon-after="settings"
      >
        RFP Specifications
      </div>
    </div>
  </div>

{:else if minimized}
  <Item item="{menuItem}" />
{/if}


<script>
  import Item from './NavigationMinimizedLevel1MenuItem.svelte';
  import noop from 'lodash/noop';
  import { $state as state } from 'root/angular-injector-provider';
  import { createMenuItem}
    from 'root/components/organisms/SideNavigation/MenuItemFactory.js';


  export let maximized, minimized, stateName = '';
  let active, menuItem, currentStateName;

  $: currentStateName = state().current.name;
  $: active = currentStateName === stateName;
  $: menuItem = createMenuItem('RFP Specifications', 'settings', stateName, currentStateName);

  function toState(){
    state().go(stateName).catch(noop);
  }
</script>


<style lang="stylus">.Container {
  flex: 0 0 48px;
  background: #263238;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.LinkContainer {
  border-radius: 18px 0 0 18px;
  background: #313c42;
  cursor: pointer;
}
.LinkContainer.Active {
  background: #00a99d;
}
.LinkText {
  height: 36px;
  width: 239px;
  color: #b0bec5;
  opacity: 0.5;
  font-size: 13px;
  justify-content: space-between;
  padding: 0 14px;
}
.LinkText:hover {
  opacity: 0.7;
}
.LinkText.Active {
  color: #fff;
  opacity: 1;
}
</style>
