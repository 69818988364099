/**
 * Created by DejanK on 4/26/2017.
 *
 * Bid Manager Group By
 */
import HTML from './rb-bid-manager-group-by.html';
import {default as viewStateStore, setGroupBy} from '../../stores/view-state'
import {scrollToTop} from '../../stores/bid-manager'

export default {
  template: HTML,
  controller: RbBidManagerGroupBy
}

RbBidManagerGroupBy.$inject = ['$scope'];
function RbBidManagerGroupBy($scope) {
  const vm = this;
  vm.onGroupByChanged = onGroupByChanged;

  const vssUnsubscribe = viewStateStore.subscribe($vss => {
    vm.groupedBy = $vss.tableGroupedBy ? $vss.tableGroupedBy.id : null;
    vm.groupableBy = $vss.groupByOptions;
    $scope.$applyAsync();
  });

  vm.$onDestroy = () => {
    vssUnsubscribe();
  }

  function onGroupByChanged(){
    scrollToTop();
    setGroupBy(vm.groupableBy.find(g => g.id === vm.groupedBy));
  }
}
