<div class="Component">
  <div class="Controls">
    <a href="/" on:click|preventDefault|stopPropagation="{() => openState(homeState, homeStateParams)}"
       class="LogoLink">
      <img src="{logo}" alt="Home" width="150" />
    </a>
    <slot name="additionalControls" />
  </div>

  <slot name="mainControls" />
</div>


<script>
  import {getContext} from 'svelte';
  import logoUrl from 'images/rb-icon-header.svg';
  import competitiveLogoUrl from 'images/rb-icon-header-competitive.svg';
  import { $state as state } from 'root/angular-injector-provider';

  export let
    homeState = 'Home',
    homeStateParams = {};

  let logo = getContext('competitive') ? competitiveLogoUrl : logoUrl;

  function openState(stateName, params){
    state().go(stateName, params, {reload: true});
  }
</script>


<style lang="stylus">.Component {
  background: #263238;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.LogoLink {
  line-height: 0;
  margin: 0 13px;
}
.Controls {
  display: flex;
  align-items: center;
  position: relative;
}
</style>