<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'RbvHotelRfpBidManagerSendFinalAgreementsActionReport',
    mixins: [ ActionReportMixin ],
    data () {
      return {
        messages: {
          'OK': 'Final Agreement Sent',
          'NOT_FOUND': `Bid was not found`,
          'STATUS_CHANGED': 'Bid status was changed',
          'ERROR': 'Final Agreement not sent due to unknown error'
        }
      }
    },
    computed: {
      title () { return this.items.length === 1 ? 'Final Agreement not sent!' : 'There were errors...' },
      message () {
        return this.items.length === 1
          ? 'We were not able to send Final Agreements because of the reason listed below:'
          : `Final Agreements sent to ${this.items.length - this.failures} of ${this.items.length} bids.<br /> We were not able to send others because of the reasons listed below:`
      }
    }
  }
</script>
