import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';

export default {
  template: '<div></div>',
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  Object.assign(this, SvelteNgComponent(import('./RbBidManagerQuickFilter.svelte'), $element[0]));
}
