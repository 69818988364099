import ChangeTravelDestinationModal from './ChangeTravelDestinationModal.svelte';
import ChangeTravelDestinationActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/ChangeTravelDestinationActionReport.vue';
import {SvelteDialog} from 'root/v-app/rbServices';
import noop from 'lodash/noop';


export default function ChangeTravelDestination(handleActionResultFn){
  return function (selectedBidsReport){
    return {
      label: `Change Travel Destination`,
      icon: 'corporate_fare',
      isAvailable: () => selectedBidsReport.sharedRfp,
      action: () => {
        SvelteDialog.show(ChangeTravelDestinationModal, {bids: selectedBidsReport.bids, rfpId: selectedBidsReport.sharedRfp})
          .then(result => handleActionResultFn(result, selectedBidsReport.bids, ChangeTravelDestinationActionReport))
          .catch(noop);
      }
    };
  }
}
