<template>
  <div class="questionnaireTableContainer layout-column" :class="template.classes">
    <div class="flex-hold layout-row">
      <div class="questionnaireTableWrapper">
        <table class="questionnaireTable">
          <questionnaire-table-row
            v-for="cell in template.cells" :key="cell.id"
            :template="cell"
            :model="tableConfig"
            :response="response"
          />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { cloneDeep, assign } from 'lodash'
  import QuestionnaireTableRow from './QuestionnaireViewSectionTableRow.vue'

  export default {
    name: 'rb-questionnaire-view-section-table',

    components: { QuestionnaireTableRow },

    props: [ 'config', 'globals', 'model', 'response', 'template' ],

    computed: {
      tableConfig () {
        const config = cloneDeep(this.config.find( c => c.id === this.template.id )),
          tableState = this.response.state.find( state => state.id === this.template.id )
        if(config) {
          config.filters = assign({}, config.data)
          if(tableState && tableState.data){
            const states = tableState.data
            Object.keys(states).forEach( sk => {
              config.filters[sk] = config.filters[sk].filter( c => states[sk].indexOf(c) !== -1 )
            })
          }
        }
        return config
      }
    },

    mounted () { this.$emit('mounted')}
  }
</script>

<style lang="stylus">

  .questionnaireTableContainer {
    padding: 0 15px;
  }

  .questionnaireTableWrapper {
    border: 1px solid #CFD8DC;
    padding: 3px;
    margin: 12px auto;
  }

  .questionnaireTable {
    table-layout fixed
    border-spacing 0
    border-collapse collapse

    font-size: 11px;
    font-weight: 400;
    background white
    color: #78909C;
    border: 1px solid #CFD8DC
  }

</style>
