<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header">
      <div>Edit User Details</div>
      <div :class="$style.subHeader">Please fill out the form below, we will send the link to your user.</div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
    >
      <div :class="$style.group">
        <rb-input
          :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.firstName}"
          :for-id="'firstName'"
          :error="$showError('firstName')"
        >
          <span slot="label">First Name<i>*</i></span>
          <input
            id="firstName"
            v-model="data.user.firstName"
            v-validate="'required|max:100'"
            type="text"
            name="firstName"
            class="rbInput"
            autofocus
            maxlength="100"
            data-vv-as="First Name"
          >
        </rb-input>
        <rb-input
          :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.lastName}"
          :for-id="'lastName'"
          :error="$showError('lastName')"
        >
          <span slot="label">Last Name<i>*</i></span>
          <input
            id="lastName"
            v-model="data.user.lastName"
            v-validate="'required|max:100'"
            type="text"
            name="lastName"
            class="rbInput"
            maxlength="100"
            data-vv-as="Last name"
          >
        </rb-input>
      </div>
      <rb-input
        :class="{rbInputEmpty: !data.user.email}"
        :for-id="'email'"
        :error="$showError('email')"
      >
        <span slot="label">Email Address<i>*</i></span>
        <input
          id="email"
          v-model="data.user.email"
          v-validate="'required|email'"
          type="text"
          name="email"
          class="rbInput"
          data-vv-as="Email Adress"
        >
      </rb-input>
      <div :class="$style.group">
        <rb-input
          :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.phone}"
          :for-id="'phone'"
          :error="$showError('phone')"
        >
          <span slot="label">Phone Number<i>*</i></span>
          <input
            id="phone"
            v-model="data.user.phone"
            v-validate="'max:100'"
            type="text"
            name="phone"
            class="rbInput"
            autofocus
            maxlength="100"
            data-vv-as="Phone number"
          >
        </rb-input>
      </div>
      <div :class="$style.group">
        <rb-input
          :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.jobTitle}"
          :for-id="'jobTitle'"
          :error="$showError('jobTitle')"
        >
          <span slot="label">Job Title</span>
          <input
            id="jobTitle"
            v-model="data.user.jobTitle"
            v-validate="'max:100'"
            type="text"
            name="jobTitle"
            class="rbInput"
            autofocus
            maxlength="100"
            data-vv-as="Job Title"
          >
        </rb-input>
        <rb-input
          :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.department}"
          :for-id="'department'"
          :error="$showError('department')"
        >
          <span slot="label">Deparment</span>
          <input
            id="department"
            v-model="data.user.department"
            v-validate="'max:100'"
            type="text"
            name="department"
            class="rbInput"
            maxlength="100"
            data-vv-as="Department"
          >
        </rb-input>
      </div>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button class="rbButton mainButton">
        <i class="material-icons">save</i>
        Apply Changes
      </button>
    </div>
  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'
  import { cloneDeep } from 'lodash';

  export default {
    name: 'EditUploadUserDialog',
    components: { RbInput, ModalForm },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data() {
       return {
            data : {
                user: cloneDeep(this.user),
            }
        }
    },
    methods: {
        onSubmit(){
            return this.data;
        }
    }
  }
</script>

<style lang="stylus" module>

    .dialog {
        min-width: 500px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }

    .group{
        display: flex;
        justify-content space-between;
    }

    .groupItem{
        flex: 1;

        &+&{
            margin-left 5px;
        }
    }

    .another{
        float left;
    }
</style>
