import HTML from './competitive-bids.html';
import { init as loadDestinations } from 'root/rfp-hotel/competitive-bids/manager/store/destinations';
import { init as loadBrands } from 'root/rfp-hotel/competitive-bids/manager/store/brands';

export { HotelRfpCompetitiveBidsController as Controller, HTML }

HotelRfpCompetitiveBidsController.$inject = ['NotificationUtils', 'RfpHotelRfpRepository', '$stateParams', '$scope'];
function HotelRfpCompetitiveBidsController(utils, rfpHotelRfpRepository, $stateParams, $scope){
  const vm = this;

  $onInit();

  vm.onChanged = value => {
    vm.rfp = value;
    $scope.$applyAsync();
  };

  function $onInit(){
    vm.state = 'LOADING';

    loadDestinations($stateParams.rfpId);
    loadBrands();

    utils.onLoad( () => rfpHotelRfpRepository.getRfpPreview($stateParams.rfpId) )
      .then( httpResponse  => {
        vm.rfp = httpResponse.data;
        vm.state = 'LOADED';
      })
      .catch(() => {
        vm.state = 'ERROR';
      });
  }
}
