/**
 * Created by DejanK on 4/5/2017.
 */
import {checkAndExecuteToActionReport} from 'root/api/helpers';
import { isNil } from 'lodash'
export default BidRepository

BidRepository.$inject = ['MainServer', 'DataServer', '$q'];
function BidRepository(MainServer, DataServer, $q){
  const $repo = this;

  $repo.getBidPreview = (bidId, placeholders) => { return bidId
    ? MainServer.get(`rfps/bids/${bidId}/preview`, { placeholders: isNil(placeholders) ? 1 : placeholders })
    : $q.reject({status: 404})}

  $repo.getPublicBidPreview = (token) => { return token
    ? MainServer.get(`rfps/bids/preview`, {token: token})
    : $q.reject({status: 404})};

  $repo.updateBidProgramPeriod =
    (bidId, programStartDate, programEndDate) => {return bidId
      ? MainServer.put(`rfps/bids/${bidId}/specifications/program-period`, {programStartDate: programStartDate, programEndDate: programEndDate})
      : $q.reject({status: 404})};

  $repo.getBidCoverLetterTemplate =
      bidId => {return bidId
    ? MainServer.get(`rfps/bids/${bidId}/cover-letter/template`)
    : $q.reject({status: 404})};

  $repo.updateBidCoverLetterTemplate =
    (bidId, template) => {return bidId
      ? MainServer.put(`rfps/bids/${bidId}/cover-letter/template`, {template: template})
      : $q.reject({status: 404})};

  $repo.getHotelRfpCoverLetterPlaceholders = DataServer.getHotelRfpCoverLetterPlaceholders;

  $repo.getQuestionnaireModel = bidId => { return bidId
    ? MainServer.get(`rfps/bids/${bidId}/questionnaire/model`)
    : $q.reject({status: 404})};

  $repo.updateQuestionnaireModel =
    (bidId, questionnaireModel) => {return bidId
      ? MainServer.put(`rfps/bids/${bidId}/questionnaire/model`, questionnaireModel)
      : $q.reject({status: 404})};

  $repo.getBidResponse = (bidId) => { return bidId
    ? MainServer.get(`rfps/bids/${bidId}/response`)
    : $q.reject({status: 404})};

  $repo.getSupplier = (bidId) => { return bidId
    ? MainServer.get(`rfps/bids/${bidId}/supplier`) : $q.reject({status: 404})};

  $repo.getBidResponseDraft = (bidId) => { return bidId
    ? MainServer.get(`rfps/bids/${bidId}/response-draft`)
    : $q.reject({status: 404})};

  $repo.getBidResponseForManualEntry = (bidId) => { return bidId
    ? MainServer.get(`rfps/bids/${bidId}/response-draft-manual-entry`)
    : $q.reject({status: 404})};

  $repo.getBidHistory = (bidId) => { return bidId
    ? MainServer.get(`bids/${bidId}/rate-history`)
    : $q.reject({status: 404})};

  $repo.updateRateHistory = (bidId, rates) => checkAndExecuteToActionReport(
    () => bidId && rates,
    () => MainServer.put(`bids/${bidId}/rate-history`, {rates})
  );

  $repo.updateContacts = token => token? MainServer.put(`bid-invitations/update-contacts/${token}`): $q.reject({status: 404});
}
