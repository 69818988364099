import { checkAndExecute, checkAndExecuteToActionReport } from 'root/api/helpers';

export default function RfpBidNotesApi(api) {
  Object.assign(api, {
    listNotes: (bidId) => checkAndExecute(
      () => bidId,
      () => api.server.get(`rfps/bids/notes/${bidId}`)
    ),
    createNote: (bidId, note) => checkAndExecuteToActionReport(
      () => bidId && note,
      () => api.server.post(`rfps/bids/notes/${bidId}/create`, note)
    ),
    remove: (bidId, note) => checkAndExecuteToActionReport(
      () => bidId && note,
      () => api.server.delete(`rfps/bids/notes/${bidId}/delete/${note}`)
    ),
    togglePrivacy: (bidId, note) => checkAndExecuteToActionReport(
      () => bidId && note,
      () => api.server.put(`rfps/bids/notes/${bidId}/toggle/${note}`)
    ),
    markNotesAsRead: (bidId) => checkAndExecuteToActionReport(
      () => bidId,
      () => api.server.put(`rfps/bids/notes/${bidId}/read/`)
    ),
    markNotesAsUnRead: (bidId) => checkAndExecuteToActionReport(
      () => bidId,
      () => api.server.put(`rfps/bids/notes/${bidId}/unread/`)
    )
  })
}
