import Vue from 'vue'
import UserProfile from './UserProfile.vue';
import {CURRENT_USER_CHANGED_EVENT} from 'root/auth/current-user.service';

export default {
  template: '<div></div>',
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'CurrentUser', '$scope'];
function Ctrl($element, currentUser, $scope){
  const vm = this
  let vueComponent

  this.$onInit = () => {
    vm.user = {data : {}}
    $scope.$on(CURRENT_USER_CHANGED_EVENT, (event, user) => { vm.user.data = user })
    currentUser.refresh().then(initVueComponent)
  }

  this.$onDestroy = () => { vueComponent && vueComponent.$destroy() }

  function initVueComponent(){
    !vueComponent && initVue();

    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { user: vm.user },
        render: function (createElement) {
          return createElement(UserProfile, {
            props: { user: vm.user }
          });
        }
      });
    }
  }
}
