import {writable} from 'svelte/store';
import {currentUser, mainAPI} from 'root/angular-injector-provider';

const LOCAL_STORAGE_ID = 'bidsDashboard';

export const store = writable({});

export async function initialize(){
  const
    user = await currentUser().get(),
    userAccountType = user.getAccountType(),
    availableSources = listSourcesForType(userAccountType),
    savedSettings = loadFromStorage(user.currentUserAccount.id, availableSources),
    settings = savedSettings
      ? savedSettings
      : getDefaultSettingsForAccountType(userAccountType, availableSources)

  store.set({
    source: settings.source,
    sources: availableSources,
    viewType: settings.viewType,
  });
}

export async function updateSettings(settings = {}) {
  const user = await currentUser().get();
  store.update(vals => {
    const newSettings = {...vals, ...settings};

    saveSettings(user.currentUserAccount.id, {
      sourceId: newSettings.source.id,
      viewType: newSettings.viewType
    });

    return newSettings;
  })
}


function listSourcesForType(accountType) {
  // needs to be inside the function because of angular-injector.
  const sources = [
    {
      id: 'personal',
      title: 'Your Bids',
      description: 'Bids for which you have been set as a supplier contact.',
      source: mainAPI().dashboard.listPersonalRecentBids,
      notAvailableForAccountTypes: ['HMC'],
      defaultForType: ['CHAIN'],
    },
    {
      id: 'bookmarked',
      title: 'Bookmarked RFPs Bids',
      description: 'Bids from Bookmarked RFPs.',
      source: mainAPI().dashboard.listRecentBidsFromFavoriteRfps,
    },
    {
      id: 'all',
      title: 'All Bids',
      description: 'All Bids.',
      source: mainAPI().dashboard.listCompanyRecentBids,
      defaultForType: ['HMC'],
    },
  ];

  return sources.filter(s => !s.notAvailableForAccountTypes || s.notAvailableForAccountTypes.indexOf(accountType) === -1);
}

function getDefaultSettingsForAccountType(accountType, availableSources){
  const defaultSource = availableSources.find(s => s.defaultForType && s.defaultForType.indexOf(accountType) !== -1);
  if(defaultSource){
    return {
      source: defaultSource,
      sources: availableSources,
      viewType: 'TABLE'
    };
  } else {
    throw new Error(`Unable to load default settings for type <${accountType}>`);
  }
}

function loadFromStorage(userAccountId, availableSources){
  try {
    const savedSettings = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_ID)),
      userSavedSettings = savedSettings[userAccountId],
      source = availableSources.find(s => s.id === userSavedSettings.sourceId);

    if(source){
      return {
        source: source,
        sources: availableSources,
        viewType: userSavedSettings.viewType
      };
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

function saveSettings(userAccountId, settings){
  try {
    const savedSettings = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_ID)) || {};
    savedSettings[userAccountId] = settings;
    window.localStorage.setItem(LOCAL_STORAGE_ID, JSON.stringify(savedSettings));
  } catch {
    //ignore
  }
}