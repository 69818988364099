/**
 * Created by DejanK on 11/7/2016.
 */
import HTML from './rb-input-address.html'

export default {
  template: HTML,
  bindings: {
    model: '=',
    type: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$scope', 'DataServer'];
function Ctrl($scope, DataServer){
  const vm = this
  let states

  DataServer.getStates()
    .then( result => { states = result } )
    .then( () => { $scope.$watch( '$ctrl.model.country', onCountryChanged ) } )

  vm.$onChanges = $onChanges

  function $onChanges(changes) {
    changes.type && updateType(changes.type.currentValue)
  }

  function onCountryChanged(){
    const model = vm.model || {};

    switch(model.country) {
      case 'US':
        handleUS()
        return

      case 'CA':
        handleCA()
        return

      default:
        model.county = undefined;
        model.state = undefined;
    }

    function handleUS () {
      if (model.state && !isStateFrom(model.state, 'US')) {
        model.state = undefined
      }
      model.region = undefined
    }

    function handleCA(){
      if(model.state && !isStateFrom(model.state, 'CA')) {
        model.state = undefined
      }
      model.region = undefined
      model.county = undefined
    }
  }

  function isStateFrom(stateId, country){
    const st = states.find( ( state ) => { return state.id === stateId })
    return st && st.country === country
  }

  function updateType(currentType){
    vm.type = currentType
  }
}
