<template>
  <modal-form :class="$style.dialog"
              @cancel="$formCancelHandler">
    <span slot="header">
        <div>{{hotel.company.name}} Rates</div>
        <div :class="$style.subHeader">See full rate details that can be displayed on the hotel directory for {{hotel.company.name}} below.</div>
    </span>
    <div slot="body" class="vModalDefaultBody hotel-directory" :class="$style.body">
        <rate-grid :hotel="hotel"/>
    </div>

    <div slot="footer" class="vModalDefaultFooter">
        <button class="rbButton" type="button" @click="cancel()">Close</button>
    </div>
  </modal-form>
</template>

<script>
  
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RateGrid from 'vRoot/hotel-directory/_core/RateGrid.vue';

  export default {
    name: 'HotelRatesDialog',
    mixins: [ RbFormMixin, DialogMixin ],
    props: ['hotel'],
    components: { ModalForm, RateGrid },
  }
</script>

<style lang="stylus" module>

  .dialog {
    min-width: 500px;
    overflow visible;
  }

  .subHeader{
    font-size 12px;
    line-height 14pt;
    color #78909C;
    font-weight lighter;
  }

  .body{
    border: 1px solid #6d838c;
    margin: 15px 10px;
    padding: 2px !important;
  }
</style>
