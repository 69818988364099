import {checkAndExecute, checkAndExecuteToActionReport} from 'root/api/helpers';

export default function HotelRfpBidFinalAgreementAPI(api) {
  Object.assign(api, {

    setFinalAgreementPending: bidsIds => checkAndExecuteToActionReport (
      () => bidsIds,
      () => api.server.post(`rfps/hotel/bids/final-agreement-pending`, { bids: bidsIds })),

    sendFinalAgreement: bidId => checkAndExecute (
      () => bidId,
      () => api.server.post(`rfps/hotel/bids/${bidId}/final-agreement`)),

    sendResponseAndFinalAgreement: (response, bidId, ignoreErrors, sendToSupplier) => checkAndExecute(
      () => response && bidId,
      () => api.server.post(`rfps/hotel/bids/${bidId}/respond-to-final`, {response, ignoreErrors, sendToSupplier})
    ),

    viewHotelRfpBidFinalAgreement: bidId => checkAndExecute(
      () => bidId,
      () => api.server.get(`rfps/hotel/bids/${bidId}/final-agreement`)
    ),

    exportFinalAgreementAsPdf: bidId => checkAndExecute (
      () => bidId,
      () => api.server.getFile(`rfps/hotel/bids/${bidId}/final-agreement/pdf`)),

    exportFinalAgreementAsExcel: bidId => checkAndExecute (
      () => bidId,
      () => api.server.getAndAutoSaveFile(`rfps/hotel/bids/${bidId}/final-agreement/xlsx`)),

    getBidFinalAgreementTemplate: bidId => checkAndExecute (
      () => bidId,
      () => api.server.get(`rfps/hotel/bids/${bidId}/final-agreement/template`)),

    updateBidFinalAgreementTemplate: (bidId, template) => checkAndExecute (
      () => bidId,
      () => api.server.put(`rfps/hotel/bids/${bidId}/final-agreement/template`, {template: template})),

    sendFinalAgreements: (bidsIds, skipEmails) => checkAndExecuteToActionReport (
      () => bidsIds,
      () => api.server.post('rfps/hotel/bids/final-agreement/send', {bidsIds, skipEmails})),

    resendFinalAgreements: bidsIds => checkAndExecuteToActionReport (
      () => bidsIds,
      () => api.server.post('rfps/hotel/bids/final-agreement/resend', {bidsIds})),

    checkRateLoadingInformation: bidsIds => checkAndExecute (
      () => bidsIds,
      () => api.server.post(`rfps/bids/rate-loading-information/check`, { bidsIds })),

    getBidRateAccessCode: bidId => checkAndExecute (
      () => bidId,
      () => api.server.get(`rfps/hotel/bids/${bidId}/rate-access-code`)),

    setBidRateAccessCode: (bidId, data) => checkAndExecute (
      () => bidId && data,
      () => api.server.put(`rfps/hotel/bids/${bidId}/rate-access-code`, data)),
  })
}
