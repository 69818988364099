/**
 * Created by DejanK on 12/27/2016.
 */
import angular from 'angular';

import headers from './headers';
import tooltips from './rb-tooltip/rb-tooltip.module';

export default angular.module('rb.core.ui', [
  headers,
  tooltips.name,

  require('./rb-float/rb-float.module').name,
  require('./rb-fixed-on-scroll/rb-fixed-on-scroll.module').name,
  require('./rb-virtual-repeat/rb-virtual-repeat.module').name
])

  .component('rbRating', require('./rb-rating/rb-rating.cmp'))

  .filter('rbDate', require('./rb-date-filter/rb-date.filter'))
