/**
 * Created by DejanK on 8/17/2016.
 *
 * use {locals: {type: type}}) for predefined labels
 *
 * types:
 *  - saving
 *  - loading
 *  - working
 *  - checking
 *  - use your own
 */
import '../dialogs.scss'
import '../notifications.scss'
import HTML from './working.html'

const NAME = 'working'
const SAVING = 'saving'
const LOADING = 'loading'
const WORKING = 'working'
const CHECKING = 'checking'

export { NAME, HTML, WorkingController as Controller, SAVING, LOADING, WORKING, CHECKING }

WorkingController.$inject = ['rbDialog', '$scope'];
function WorkingController(rbDialog, $scope) {
  const vm = this;
  vm.cancel = rbDialog.cancel;

  init();

  function init(){
    vm.subtitle = 'Please wait';
    vm.title = 'Saving...';

    $scope.params && onType($scope.params.type);
    vm.type && onType(vm.type);

    function onType(type){
      switch (type){
        case SAVING:
          vm.title = 'Saving...';
          break;
        case LOADING:
          vm.title = 'Loading...';
          break;
        case WORKING:
          vm.title = 'Working...';
          break;
        case CHECKING:
          vm.title = 'Checking...';
          break;
        default:
          vm.title = type;
      }
    }
  }
}
