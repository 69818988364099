/**
 * Created by DejanK on 1/16/2017
 *
 * Creates GoogleMap for TravelDestinationManager
 */
import { get, debounce } from 'lodash'
import Store from 'root/rfp-hotel/main/rfp/pages/destinations/suppliers.store.js';

export default {
  bindings: {
    center: '<',
    destination: '<',
    distance: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['RbGoogle', '$element', 'TravelDestinationManagerMapService', 'TravelDestinationManagerService'];
function Ctrl(RbGoogle, $element, mapService, travelDestinationManagerService){
  const vm = this,
    debouncedOnSuppliersChanged = debounce(onSuppliersChanged, 100);

  let gMapsInit, profileToShow, suppliersStoreSubscription, suppliersHash = -1;

  this.$onInit = ()=>{
    gMapsInit = RbGoogle.getMap();
    suppliersStoreSubscription = Store.subscribe(($store) => {
      vm.suppliers = $store.queryFilteredSuppliers;
      gMapsInit.then(debouncedOnSuppliersChanged);
    })
  };

  this.$onChanges = (changes)=>{
    if(gMapsInit) {
      changes.center && gMapsInit.then(onCenterChanged);
      changes.destination && gMapsInit.then(onDestinationChanged);
      changes.distance && gMapsInit.then(onDistanceChanged);
    }
  };

  this.$doCheck = ()=>{
    if(travelDestinationManagerService.focusedSupplierId !== profileToShow){
      profileToShow = travelDestinationManagerService.focusedSupplierId;
      onProfileToShowChanged();
    }
    mapService.checkAndUpdateSuppliersIcons(vm.suppliers);
  };

  this.$onDestroy = () => {
    if(suppliersStoreSubscription) suppliersStoreSubscription();
  }

  function onCenterChanged(){
    mapService.panMapTo(vm.center);
  }

  function onDestinationChanged(){
    const coordinates = get(vm, 'destination.location.coordinates')
    if(coordinates) {
      mapService.createMap($element, coordinates);
      mapService.updateDestination(vm.destination);
      onDistanceChanged();
    }
  }

  function onDistanceChanged() {
    mapService.updateDistanceCircle(vm.distance);
  }

  function onSuppliersChanged() {
    const newHash = stringHashCode(JSON.stringify(vm.suppliers));
    if(newHash !== suppliersHash){
      suppliersHash = newHash;
      mapService.updateSuppliersMarkers(vm.suppliers)
    }
  }

  function onProfileToShowChanged(){
    mapService.updateShownProfile(profileToShow);
  }


  function stringHashCode (str) {
    let hash = 0
    for (let i = 0; i < str.length; ++i)
      hash = Math.imul(31, hash) + str.charCodeAt(i)

    return hash | 0;
  }
}


