import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import HTML from './edit-cover-letter.html';

export { HTML, RfpCoverLetterEditController as Controller }

RfpCoverLetterEditController.$inject = ['NotificationUtils', 'MainAPI', '$state', '$q', 'rbDialog'];
function RfpCoverLetterEditController(utils, MainAPI, $state, $q, dialog){
  const vm = this;
  let originalCoverLetter;

  vm.save = saveCoverLetter;
  vm.onCoverLetterChanged = onCoverLetterChanged;

  $onInit();

  function $onInit(){
    vm.rfpId = $state.params.rfpId;

    utils.onLoad(() => {
      return $q.all({
        template: MainAPI.getHotelRfpCoverLetterTemplate(vm.rfpId)
          .then(httpResponse => {
            originalCoverLetter = get(httpResponse, 'data.letter');
            return cloneDeep(originalCoverLetter)
          }).catch(noop),
        placeholders: MainAPI.getHotelRfpCoverLetterPlaceholders(),
      });
    }, vm, 'coverLetterModel' );
  }

  function saveCoverLetter(){
    if(isEqual(originalCoverLetter, vm.coverLetterModel.template)){
      goToView();

    } else if (vm.coverLetterModel.template.length > 1000000) {
      displayError();

    } else {
      utils.onSave(() => MainAPI.setHotelRfpCoverLetterTemplate(vm.rfpId, vm.coverLetterModel.template))
        .then(goToView)
        .catch(noop);
    }
  }

  function goToView(){
    $state.go('hotelRfp.rfp.coverLetter.view');
  }

  function displayError(){
    dialog
      .show('error', { locals: { Error: {
            heading: 'Saving Template',
            title: 'Failed!',
            message: 'Letter template is too big.'
          }}}
      )
      .catch(noop)
  }

  function onCoverLetterChanged($event){
    vm.coverLetterModel.template = $event.letter;
  }
}
