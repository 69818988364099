<script>
    export default {
        name: 'ResponsiveBarMixin',
        directives: {
            responsive: {
                componentUpdated(el) {
                    let prevItem = {};
                    let currItem = {};
                    const items = el.children;
                    let wrapped = false;
                    el.classList.remove('responsive');
                    for (let i = 0; i < items.length; i++) {
                        currItem = items[i].getBoundingClientRect();
                        if (prevItem && prevItem.top < currItem.top) {
                            wrapped = true;
                        }
                        prevItem = currItem;
                    }
                    return wrapped && el.classList.add('responsive');
                }
            }
        }
    }
</script>