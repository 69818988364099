import { checkAndExecute } from 'root/api/helpers';

export default function AttachmentsApi(api) {

  Object.assign(api, {
    createAttachment: data => checkAndExecute(
      () => data,
      () => api.server.post(`attachments`, data)
    ),

    getAttachment: (id, token) => checkAndExecute(
      () => id,
      () => api.server.get(`attachments/${id}`, { token: token ? token : undefined  })
    ),
  });
}
