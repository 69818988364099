<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import YesNoButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
  import noop from 'lodash/noop';
  import {toISO} from 'utils/formatDate';

  export let hide, cancel, bidsIds, statusLoaded, save = true;

  const minDate = toISO(new Date());
  let expectedDate = '', title = '';

  $: title = `Mark Rates as ${statusLoaded ? '': 'Not '}Loaded for ${bidsIds.length === 1 ? 'the hotel' : `${bidsIds.length} selected hotels`}?`;

  function mark(){
    const details = {};
    if(expectedDate) details.expectedDate = expectedDate;

    if (save) {
      notificationUtils().onSave(() => mainAPI().rateLoading.markBidsLoadedStatus(bidsIds, statusLoaded, details))
        .then(hide)
        .catch(noop)
    } else {
      hide({status: statusLoaded, details});
    }
  }
</script>

<ClosableModal close="{cancel}" width="{'500px'}">
  <div slot="heading">
    <ModalTitle {title} />
  </div>

  {#if !statusLoaded}
    <div class="body">
      <label for="expectedDate">Please provide the date when you expect rates to be loaded:</label>
      <input type="date"
             class="input"
             id="expectedDate"
             min="{minDate}"
             bind:value={expectedDate}
             onfocus="if(this.showPicker) this.showPicker()"
      />
    </div>
  {/if}

  <YesNoButtons
    noAction="{cancel}"
    noLabel="Cancel"
    yesAction="{mark}"
    yesIcon="done"
    yesLabel="Mark"
  />
</ClosableModal>

<style lang="stylus">.body {
  margin-top: -35px;
  padding: 15px;
  color: #37474f;
  font-size: 14px;
  line-height: 16px;
}
input {
  background-color: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 6px;
  font-family: "Noto Sans", Sans-serif;
  font-weight: 500;
  color: #37474f;
  font-size: 16px;
  height: 47px;
  padding: 6px 16px;
  width: 100%;
  max-width: 100%;
  line-height: 1.4;
  box-sizing: border-box;
  margin: 0;
  outline: none;
}
input::placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}
input:placeholder-shown ~ label {
  opacity: 0;
  top: 0;
}
input ~ label {
  color: #37474f;
}
input:focus {
  border-color: #00a99d;
}
input:focus ~ label {
  color: #00a99d;
}
label {
  padding: 0 5px;
  font-size: 14px;
  line-height: 1.6;
}
</style>
