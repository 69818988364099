<script>
  import debounce from "lodash/debounce";
  import get from "lodash/get";
  import { $state as stateProvider } from "root/angular-injector-provider.js";
  import ImageWithPlaceholder from "./Table/ImageWithPlaceholder.svelte";

  import {
    CHAIN_PORTAL_STATE,
    PAGE_RFP_MANAGER_COMPANY_PROFILE,
    CHAIN_PORTAL_ALL_HOTELS_STATE,
    CHAIN_PORTAL_OVERDUE_BIDS_STATE,
    DASHBOARD
  } from "root/states";
  import Navigation from "./NavBar.svelte";
  import {
    stepGroups,
    updateFilters,
    bidTypes,
    currentUser,
    onlyAssignments,
  } from "./stores/group-sort-filter.store";
  import { fetchOverDueBidsCount } from "./stores/data.store";
  import { currentList } from "./hotels/stores/data.store";

  export let showBidManager, rows;

  $: currentStateGroup = $stepGroups[0] && $stepGroups[0].id;

  let overDueBidsCount = 0;

  const loadOverDueBidsCount = debounce(async () => {
    overDueBidsCount = await fetchOverDueBidsCount()
  }, 1000);

  loadOverDueBidsCount();

  function makeChainPortalSubMenuItem({ label, id, icon, blocked, hidden }) {
    return {
      label,
      id,
      icon,
      active: isCurrent(CHAIN_PORTAL_STATE, id),
      action: () => go(id),
      openWhenActive: true,
      blocked,
      hidden
    };
  }

  function go(id) {
    if (!isCurrent(CHAIN_PORTAL_STATE)) {
      navigate(CHAIN_PORTAL_STATE, { steps: id });
    } else {
      updateFilters(id);
      $bidTypes = null;
      $onlyAssignments = id === "COMPANY" || null;
    }
  }

  function navigate(id, props) {
    stateProvider().go(id, {...props, negotiations: undefined});
  }

  function isCurrent(state, section = null) {
    return (
      state === stateProvider().current.name &&
      (!section || section === currentStateGroup)
    );
  }

  function hasPermission(feature) {
    return $currentUser && $currentUser.hasPermission(feature);
  }

  $: items = [
    {
      id: DASHBOARD,
      label: 'My Dashboard',
      icon: 'list_alt',
      action: () => navigate(DASHBOARD),
      active: isCurrent(DASHBOARD)
    },
    makeChainPortalSubMenuItem({
      label: "All Customers",
      icon: "people",
      id: "COMPANY",
      currentStateGroup,
      user: $currentUser
    }),
    {
      label: "All Hotels",
      icon: "location_city",
      action: () => navigate(CHAIN_PORTAL_ALL_HOTELS_STATE, {requests: null}),
      blocked: !hasPermission("MANAGE_HOTELS"),
      active:
        isCurrent(CHAIN_PORTAL_ALL_HOTELS_STATE) ||
        isCurrent(CHAIN_PORTAL_STATE, "PROPERTY"),
      submenu: [
        {
          label: "All Hotels",
          action: () => navigate(CHAIN_PORTAL_ALL_HOTELS_STATE, {requests: null}),
          active: isCurrent(CHAIN_PORTAL_ALL_HOTELS_STATE) && $currentList === "all",
          icon: "list_alt",
          blocked: !hasPermission("MANAGE_HOTELS"),
        },
        makeChainPortalSubMenuItem({
          id: "PROPERTY",
          label: "Hotels With Bids",
          icon: "domain"
        }),
        makeChainPortalSubMenuItem({
          id: "BRAND",
          label: "Brands",
          icon: "style",
          blocked: !hasPermission("MANAGE_BRANDS"),
          hidden: !$currentUser || $currentUser.isOfType("HMC")
        })
      ]
    },
    {
      label: 'Urgent Bids',
      icon: "help",
      blocked: !hasPermission("VIEW_URGENT_BIDS"),
      action: () =>
        navigate(CHAIN_PORTAL_OVERDUE_BIDS_STATE),
      active: isCurrent(CHAIN_PORTAL_OVERDUE_BIDS_STATE),
      hidden: !isCurrent(CHAIN_PORTAL_OVERDUE_BIDS_STATE) && overDueBidsCount <= 0,
      tooltip: 'Overdue bids represent bids that are overdue or 24hrs away from being due, but are not yet responded to.',
      badge: overDueBidsCount > 99? '99+': overDueBidsCount
    },
    {
      label: "Account Managers",
      icon: "people",
      action: () =>
        navigate(PAGE_RFP_MANAGER_COMPANY_PROFILE, { module: "manageUsers" }),
      active: isCurrent(PAGE_RFP_MANAGER_COMPANY_PROFILE)
    }
  ].filter(({hidden}) => !hidden);
  $: logo = $currentUser && $currentUser.getAccount().logo;
  $: firstRow = rows && rows[0];
  $: image = (rows && rows.length === 1 && firstRow && getImageFromRow(firstRow)) || '';
  $: name = (rows && rows.length === 1 && firstRow && getValue(firstRow, 'rfp.specifications.name', 'buyer.company.name', 'supplier.company.name', 'brand.name')) || '';
  $: address = firstRow && get(firstRow, 'supplier.company.location.fullAddress')

  function getValue(row, ...keys) {
    return keys.reduce((acc, key) => acc || get(row, key), "")
  }

  function getImageFromRow(row) {
    return logo(row.buyer || row.brand || {}) || hotelImage(row.supplier || row || {});

    function hotelImage({company, image}) {
      image = (company && company.image && company.image.url) || image && image.url || "";
      return image
        && image.startsWith("http")
          ? image
          : `/images/entities/${image}`
    }

    function logo({company, logo}) {
      logo = logo || company && company.logo;
      return logo && `/images/logos/${logo}`;
    }
  }
</script>

<Navigation {items}>
  <div class="Item">
    {#if !showBidManager}
      {#if logo}
        <span class="logoImage logo">
          <ImageWithPlaceholder alt="company logo" url={logo} placeholder="/images/hotel_placeholder.png" />
        </span>
      {:else}
        <div class="noLogo">
          <i class="fa fa-image noLogoIcon" aria-hidden="true" />
        </div>
      {/if}
    {:else}
        <span class="logoImage">
          <ImageWithPlaceholder url={image} alt={name} placeholder="/images/hotel_placeholder.png" />
        </span>
        <div>
          <div class="Name">{name}</div>
          {#if address}
            <div>{address}</div>
          {/if}
        </div>
    {/if}
  </div>
</Navigation>

<style lang="stylus">.logoImage :global(img) {
  height: 40px;
  border-radius: 5px;
  width: 120px;
  object-fit: contain;
}
.noLogo {
  text-align: center;
  width: 120px;
  background: #eceff1;
  box-sizing: border-box;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noLogoIcon {
  font-size: 35px;
  text-transform: uppercase;
  color: #b0bec5;
}
.Item {
  height: 40px;
  min-width: 160px;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: none;
}
.Item .logoImage:not(.logo) :global(img) {
  width: 40px;
  margin-right: 10px;
  overflow: hidden;
}
.Item div {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Item .Name {
  font-weight: bold;
}
</style>
