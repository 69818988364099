/**
 * Created by DejanK on 7/17/2017.
 */

import HTML from './rb-negotiations-input-amenity-tooltip.html';
import { cloneDeep } from 'lodash';

export default {
  template: HTML,
  bindings: {
    negotiations: '<',
    amenityId: '@',
    side: '<',
    primaryRate: '<',
    model: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$scope', '$rootScope', 'NegotiationsEditorTooltipsFactory', 'NegotiationsHtmlFactory'];
function Ctrl($element, $scope, $rootScope, NegotiationsEditorTooltipsFactory, NegotiationsHtmlFactory) {
  const
    vm = this;

  let
    tooltipster;

  vm.$onChanges = updateVM;

  vm.onIncludedClicked = onIncludedClicked;
  vm.onTypeClicked = onTypeClicked;

  function updateVM(){
    if ((vm.negotiations && vm.amenityId && vm.model && (vm.primaryRate || vm.primaryRate === 0)) && !tooltipster) {

      vm.initial = cloneDeep(vm.model);
      vm.amenity = vm.negotiations.getAmenity(vm.amenityId);
      const history = NegotiationsHtmlFactory.printAmenityHistory(vm.negotiations.communication, vm.amenityId, null, vm.amenity.cat === 'MIXED');
      tooltipster = NegotiationsEditorTooltipsFactory.createAmenityTooltip(this, $element, history, vm.amenity.cat === 'MIXED');

      $scope.$on('RB_AMENITIES_FOCUSED', (event, data) => { data.isFocused ? tooltipster.enable() : tooltipster.disable(); });
      $scope.$on('RB_NEGOTIATION_CHANGED', () => { render(); });

      render();
    }
  }

  function onIncludedClicked() {
    if (vm.model.included) {
      exclude();
    } else {
      include();
    }

    render();
    notifyOfChange();

    function exclude() {
      vm.model.included = false;
      if (vm.amenity.id === 'bf' || !vm.model.value) {
        vm.model.type = 'UNAVAILABLE';
      }
    }

    function include() {
      vm.model.included = true;
      if (vm.amenity.id === 'bf') {
        vm.model.type = 'FIXED';
        vm.model.value = vm.negotiations.communication.reduceRight((acc, comm) => {
          const bfValue = comm.values.amenities.bf.value;
          return (!acc && bfValue) ? bfValue : acc;
        }, 0);
      } else if(vm.model.type === 'UNAVAILABLE') {
        vm.model.type = vm.model.defaultType;
        vm.model.value = 0;
      }
    }
  }

  function onTypeClicked(){
    const value = vm.model.value || 0;
    if(vm.isPercentage){
      vm.model.type = 'FIXED';
      vm.model.value = (value * 100).toFixed(2)
    } else {
      vm.model.type = 'PERCENTAGE';
      vm.model.value = (value / 100).toFixed(5)
    }

    render();
    notifyOfChange();
  }

  function render(){
    vm.isIncluded = vm.model.included;
    vm.isPercentage = vm.model.type === 'PERCENTAGE';
  }

  function notifyOfChange(){
    $rootScope.$broadcast('RB_NEGOTIATION_CHANGED');
  }
}
