import './assets/images/browserNotSupported.png';
import './assets/images/bad-robot.png';
import './styles/app.scss';
import './styles/custom-tags.scss';
import 'rfp/bid-manager/main/ui/rb-bid-manager-table/rb-bid-manager-table.scss'

import 'tooltipster';
import VueApp from './v-app/vue-app.js';

// import to fix removal of public-profile module, and provide styling for hotel profile modal
import './public-profile.scss';
import AngularApp from './angular-app';
import TooltipListener from 'root/core/ui/tooltip/tooltip-listener';
import './styles/styl/index.styl';

VueApp();
TooltipListener();

export default AngularApp;
