<template>
  <div :class="{ 'selected': isSelected, 'marked': isMarked }"
       @mousedown="$emit('click', option)"
       @mousemove="$emit('hover', option)"
       v-html="highlightedLabel">
  </div>
</template>

<script>
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'

  export default {
    name: 'rb-select-option',
    mixins: [RbEventsMixin],
    props: ['option', 'selected', 'marked', 'highlight', 'scroll', "labelKey"],
    computed: {
      highlightedLabel () {
        return this.highlight && this.highlight.length
          ? this.option[this.labelKey].replace(new RegExp('('+this.highlight+')', 'gi'), '<strong>$1</strong>')
          : this.option[this.labelKey]
      },
      isMarked(){
        const marked = this.marked && this.marked.id === this.option.id
        marked && this.scrollIntoView()
        return marked;
      },
      isSelected(){
        return this.selected && this.selected.id === this.option.id
      }
    },
    methods: {
      scrollIntoView (scrollSelectedIntoView) {
        if (this.$el && this.scroll) {
          const thisElement = this.$el,
            scrollElementRect = this.$el.parentNode.getBoundingClientRect(),
            thisElementRect = thisElement.getBoundingClientRect()

          if(scrollSelectedIntoView) {
            const previous = thisElement.previousSibling
            previous && previous .nodeType === Node.ELEMENT_NODE
              ? previous.scrollIntoView(true)
              : thisElement.scrollIntoView(true)
          } else if (scrollSelectedIntoView || thisElementRect.top < scrollElementRect.top) {
            thisElement.scrollIntoView(true)
          } else if (thisElementRect.bottom > scrollElementRect.bottom) {
            thisElement.scrollIntoView(false)
          }
        }
      }
    },
    mounted () { this.isSelected && this.scrollIntoView(true) }
  }
</script>

<style lang="stylus"></style>
