import { checkAndExecute } from 'root/api/helpers';

export default function (api) {

  api.userAccountReportsSettings = {

    getActivitySummaryReportSettings: () => api.server.get('current-user/reports/settings/activity-summary-report'),

    setActivitySummaryReportSettings: data => checkAndExecute(
      () => data,
      () => api.server.put('current-user/reports/settings/activity-summary-report', data),
    ),
  };
}
