/**
 * Created by DejanK on 1/22/2017.
 */
import { get, isEqual, noop, debounce } from 'lodash'

export default TravelDestinationManagerMapService

TravelDestinationManagerMapService.$inject = ['RbGoogle', 'rbDialog', 'TravelDestinationManagerService'];
function TravelDestinationManagerMapService(RbGoogle, rbDialog, TravelDestinationManagerService){
  const $service = this
  let gMaps,
    map,
    destinationMarker,
    suppliersWithMarkers,
    focusedMarker,
    distanceCircle;

  $service.createMap = createMap;
  $service.panMapTo= panMapTo;
  $service.updateDestination = updateDestination;
  $service.updateDistanceCircle = updateDistanceCircle;
  $service.updateSuppliersMarkers = updateSuppliersMarkers;
  $service.checkAndUpdateSuppliersIcons = debounce(checkAndUpdateSuppliersIcons, 50);
  $service.updateShownProfile = updateFocusedMarker;
  $service.getSupplierWithMarker = getSupplierWithMarker;
  $service.getMap = getMap;

  onInit();

  function onInit(){
    RbGoogle.getMap().then(googleMaps => { gMaps = googleMaps }).catch( noop )
  }

  function createMap($element, center){
    if(gMaps){
      map = newMap($element, center);
      destinationMarker = null;
      suppliersWithMarkers = null;
      focusedMarker = null;
      distanceCircle = null;
    }
  }


  function getSupplierWithMarker(supplierId){ return suppliersWithMarkers.find(s => s.id === supplierId) }

  function getMap(){ return map }

  function panMapTo(coordinates){ map && map.panTo(coordinates) }

  function updateDestination(newDestination){
    if(gMaps) {
      removeDestinationMarker()
      destinationMarker = newDestinationMarker(newDestination)
    }
  }

  function updateDistanceCircle(distance){
    if(gMaps && distance && map){
      distanceCircle = distanceCircle ? updateDistanceCircleRadius(distanceCircle, distance) : newDistanceCircle(map, distance)
      const circleBounds = distanceCircle.getBounds()
      map.fitBounds(circleBounds);
      map.panToBounds(circleBounds);
    }
  }

  function updateSuppliersMarkers(newSuppliers){
    const newSuppliersWithMarkers = createNewSuppliersWithMarkers(suppliersWithMarkers, newSuppliers);
    removeOldMarkersFromMap(suppliersWithMarkers);
    suppliersWithMarkers = newSuppliersWithMarkers;

    /**
     * creares new markers or recycles old ones
     * @param oldSuppliersWithMarkers
     * @param newSuppliers
     */
    function createNewSuppliersWithMarkers(oldSuppliersWithMarkers, newSuppliers){
      const newSuppliersWithMarkers = [];
      if(gMaps) {
        newSuppliers && newSuppliers.forEach(newSupplier => {
          const oldSupplierWithMarkerIndex = oldSuppliersWithMarkers && oldSuppliersWithMarkers.findIndex( s => s.id === newSupplier.id )
          if (!oldSupplierWithMarkerIndex || oldSupplierWithMarkerIndex === -1) {
            newSuppliersWithMarkers.push(createSupplierWithMarker(newSupplier));
          } else {
            const oldSupplierWithMarker = oldSuppliersWithMarkers.splice(oldSupplierWithMarkerIndex, 1)[0];
            newSuppliersWithMarkers.push(updateSupplierWithMarker(oldSupplierWithMarker, newSupplier));
          }
        });
      }
      return newSuppliersWithMarkers;
    }

    function removeOldMarkersFromMap(oldMarkers){
      oldMarkers && oldMarkers.forEach(oldSupplierWithMarker => oldSupplierWithMarker.marker.setMap(null))
    }
  }

  function checkAndUpdateSuppliersIcons(suppliers){
    checkAndUpdateLists(suppliers);
    checkAndUpdateIcons();

    function checkAndUpdateLists(suppliers){
      if(suppliers && suppliersWithMarkers && suppliers.length !== suppliersWithMarkers.length){
        updateSuppliersMarkers(suppliers);
      }
    }

    function checkAndUpdateIcons() {
      suppliersWithMarkers && suppliersWithMarkers.forEach(supplierWithMarker => {
        if (supplierWithMarker.previousBidState !== supplierWithMarker.data.bidState) {
          updateIcon(supplierWithMarker.marker, supplierWithMarker.data);
          supplierWithMarker.previousBidState = supplierWithMarker.data.bidState;
        }
      })
    }
  }

  function getSupplierIcon(supplier) {
    let icon = ''

    if(supplier.bidState === 'NA') {
      icon = supplier.lastYear ? 'hotel-last-year-default' : 'hotel-default'
    } else {
      icon = supplier.lastYear ? 'hotel-last-year-selected' : 'hotel-selected'
    }

    return '/images/map/' + icon + '.png'
  }

  function updateFocusedMarker(profileToShow){
    focusedMarker && removeFocus();
    profileToShow && setFocus(profileToShow);

    function removeFocus(){
      focusedMarker.marker.setIcon({
        url: focusedMarker.marker.getIcon().url,
        scaledSize: focusedMarker.saved.iconSize
      });
      focusedMarker.marker.setZIndex(focusedMarker.saved.zIndex);
      focusedMarker = null;
    }

    function setFocus(profileToShow){
      const supplier = getSupplierWithMarker(profileToShow);
      if(supplier) {
        const marker = supplier.marker,
          icon = marker.getIcon();
        focusedMarker = {
          marker: marker,
          saved: {
            iconSize: icon.scaledSize,
            zIndex: marker.getZIndex()
          }
        };

        marker.setIcon({
          url: icon.url,
          scaledSize: {width: 32, height: 34}
        });
        marker.setZIndex(6001);
      }
    }
  }


  function newMap($element, center){
    return new gMaps.Map($element[0], {
      center: center,
      zoom: 14,
      mapTypeId: 'roadmap',
      styles: [
        {
          featureType: 'poi.business',
          elementType: 'labels',
          stylers: [ { visibility: 'off' } ]
        }
      ]
    });
  }

  function newDestinationMarker(destination){
    return new gMaps.Marker({
      position: get(destination, 'location.coordinates'),
      map: map,
      icon: {
        url: '/images/map/office.png',
        scaledSize: {width: 30, height: 30}
      }
    })
  }

  function removeDestinationMarker(){
    destinationMarker && destinationMarker.setMap(null);
  }

  function newDistanceCircle(map, distance) {
    return new gMaps.Circle({
      center: map.getCenter(),
      radius: getMaxDistanceInMeters(distance),
      map: map,
      clickable: false,
      fillColor: '#00B8AB',
      fillOpacity: 0.1,
      strokeColor: '#ffffff',
      strokeOpacity: 0.9,
      strokeWeight: 1.5
    });
  }

  function updateDistanceCircleRadius(distanceCircle, distance){
    distanceCircle.setRadius(getMaxDistanceInMeters(distance));
    return distanceCircle;
  }

  function createSupplierWithMarker(supplier){
    const marker = new gMaps.Marker({
      position: supplier.location.coordinates,
      map: map,
      icon: {
        url : getSupplierIcon(supplier),
        scaledSize: {width: 24, height: 25}
      }
    });
    const markerWithData = {id: supplier.id, previousBidState: supplier.bidState, data: supplier, marker: marker};

    marker.addListener('click', function() {
      rbDialog.show('destinationManagerHotelProfile', { locals: {SupplierHotelId: supplier.id}} ).catch(noop)
    });

    marker.addListener('mouseover', function() {
      TravelDestinationManagerService.setFocusedSupplierId(supplier.id);
      TravelDestinationManagerService.scrollListTo(supplier.id);
    });

    marker.addListener('mouseout', function() {
      TravelDestinationManagerService.setFocusedSupplierId(null);
    });

    return markerWithData;
  }

  function updateSupplierWithMarker(supplierWithMarker, supplier){
    if(!isEqual(supplierWithMarker.data, supplier)){
      updateIcon(supplierWithMarker.marker, supplier);
      supplierWithMarker.data = supplier;
      supplierWithMarker.previousBidState = supplier.bidState;
    }
    return supplierWithMarker;
  }

  function updateIcon(supplierMarker, supplier){
    const currentIcon = supplierMarker.getIcon(),
      newIcon = getSupplierIcon(supplier);
    if(currentIcon.url !== newIcon){
      supplierMarker.setIcon({
        url : newIcon,
        scaledSize: currentIcon.scaledSize
      })
    }
  }

  function getMaxDistanceInMeters(distance){
    const distanceValue = distance.value;
    if(!distanceValue || distanceValue < 0) { return 0 }
    if(distanceValue > 50) { return 50*1609.344 }
    switch (distance.unit) {
      case 'KM':
        return distanceValue * 1000;
      case 'MI':
        return distanceValue * 1609.344;
      default :
        return 0;
    }
  }
}
