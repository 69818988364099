import {checkAndExecute, checkAndExecuteToActionReport} from 'root/api/helpers';

export default function HotelRfpDestinationsAPI(api) {
  Object.assign(api, {

    listRfpTravelDestinations: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/${rfpId}/travel-destinations`)),

    updateBidsTravelDestination: (bids, destinationId) => checkAndExecuteToActionReport(
      () => bids && bids.length && destinationId,
      () => api.server.post(`rfps/hotel/bids/travel-destinations/${destinationId}/add`, { bids: bids })
    ),

    geoSearchHotels: (rfpId, destinationId, query) => checkAndExecute(
      () => rfpId && destinationId && query,
      () => api.server.post(`rfps/${rfpId}/travel-destinations/${destinationId}/geo-search`, query)
    ),

    searchBySabreCode: (rfpId, destinationId, sabreCodes = []) => checkAndExecute(
      () => rfpId && destinationId && sabreCodes.length,
      () => api.server.post(`rfps/${rfpId}/travel-destinations/${destinationId}/by-sabre`, {sabreCodes})
    ),

    searchByHotelId: (rfpId, destinationId, hotelsIds = []) => checkAndExecute(
      () => rfpId && destinationId && hotelsIds.length,
      () => api.server.post(`rfps/${rfpId}/travel-destinations/${destinationId}/by-hotel-id`, {hotelsIds})
    )
  });
}
