import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';
import Component from './ViewAttachments.svelte';

export default {
  template: '<div></div>',
  bindings: {
    attachments: '<',
    token: '<'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(vm, SvelteNgComponent(Component, $element[0], () => ({
    attachments:  vm.attachments,
    token:  vm.token,
  })));
}
