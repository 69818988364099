<template>
  <modal-form @cancel="$formCancelHandler" @submit="$formSubmitHandler" :class="$style.dialog">
    <span slot="header">Change Your Password</span>

    <div slot="body" class="vModalDefaultBody">
      <rb-input forId="newPassword"
                :error="$showError('newPassword')">
        <span slot="label">Enter New Password</span>
        <input id="newPassword" type="password" name="newPassword" class="rbInput"
               v-model="data.password"
               v-validate="{required: true, max: 200, min: 8, has_lowercase: true, has_uppercase: true, has_number: true, no_whitespace: true}"
               data-vv-as="Password"/>
      </rb-input>
      <rb-input forId="confirmPassword"
                :error="$showError('confirmPassword')">
        <span slot="label">Confirm New Password</span>
        <input id="confirmPassword" type="password" name="confirmPassword" class="rbInput"
               v-model="data.confirmPassword"
               v-validate="{ required: true, value: data.password }"
               data-vv-as="Confirm Password"/>
      </rb-input>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'
  import RbInput from 'vRoot/_core/RbInput.vue'

  export default {
    name: 'rbv-user-profile-edit-personal-info-dialog',
    mixins: [ RbFormMixin, DialogMixin ],
    components: { RbInput, ModalForm },
    data() {
      return {
        data: {
          password: '',
          confirmPassword: ''
        }
      }
    },
    methods: {
      onSubmit () { return { form: { password: this.data.password} } }
    }
  }
</script>

<style lang="stylus" module="">
  .dialog {
    min-width 400px
  }
</style>

