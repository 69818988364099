<template>
  <notification-dialog-template
    :class="$style.dialog"
    icon="question"
    @cancel="cancel()"
  >
    <template slot="subTitle">
      Did you want to
    </template>

    <template slot="title">
      Send Final Agreement now?
    </template>

    <template slot="message">
      If not, you can access "Send Final Agreement" option from the Bid context menu.
    </template>

    <template slot="footer">
      <v-button @click="cancel()">Later</v-button>
      <v-button @click="toSendFinalAgreement()" type="main">Send Now</v-button>
    </template>

  </notification-dialog-template>
</template>

<script>
  import NotificationDialogTemplate from 'vRoot/_core/templates/NotificationDialogTemplate.vue';
  import VButton from 'vRoot/_core/buttons/Button.vue';
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import { $state } from 'root/angular-injector-provider';

  export default {
    name: 'ProceedToFinalAgreementDialog',

    components: { NotificationDialogTemplate, VButton },

    mixins: [ DialogMixin ],

    props: {
      bidId: {
        type: String,
        required: true
      },
    },

    methods: {
      toSendFinalAgreement() {
        this.hide();
        $state().go('hotelRfp.bid.finalAgreement.send', { bidId: this.bidId });
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 500px;
  }

</style>
