<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header">
      <div>{{ view.name }} category display content</div>
      <div :class="$style.subHeader">
        Add or remove content to be displayed on your hotel directory
      </div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
      :class="$style.Body"
    >
      <rb-input
        :for-id="'name'"
        :error="$showError('name')"
      >
        <span slot="label">View Name</span>
        <input
          id="name"
          v-model="data.view.name"
          v-validate="'required|max:100'"
          type="text"
          name="name"
          class="rbInput"
          autofocus
          maxlength="100"
          placeholder="view name"
          data-vv-as="view name"
        >
      </rb-input>

      <div :class="$style.recommendations">
        Allow user(s) to recommend up to
        <hd-select
          :key="maximumRecommendations"
          v-model="maximumRecommendations"
          :items="recommendations"
        />
        hotels
      </div>

      <template v-if="rfps && rfps.length > 0">
        <div :class="$style.rfpsTitle">
          <h3 :class="$style.cardTitle">Include hotels from these RFPs</h3>
          <h3 :class="$style.cardTitle">Program Year</h3>
        </div>
        <h5 :class="$style.rfpSubTitle">
          When no RFP is selected, hotels from all your RFPs will be displayed
          on the hotel directory
        </h5>
        <div :class="$style.rfps">
          <div
            v-if="rfps.length > 1"
            :class="$style.rfp"
          >
            <check-box
              :value="includeAllRfps || data.view.rfpIds.length === rfps.length"
              :check-box-value="true"
              check-box-label="All Rfps"
              :class="$style.columnCheckbox"
              @input="toggleAllRfps()"
            />
          </div>
          <div
            v-for="rfp in rfps"
            :key="rfp.id"
            :class="$style.rfp"
          >
            <check-box
              :id="data.view.rfpIds && data.view.rfpIds.length + '' + rfp.id"
              :value="data.view.rfpIds && data.view.rfpIds.includes(rfp.id)"
              :check-box-value="true"
              :check-box-label="rfp.name"
              :class="$style.columnCheckbox"
              @input="toggleRfpId(rfp.id)"
            />
            <span>{{ rfp.programStartDate }} - {{ rfp.programEndDate }}</span>
          </div>
        </div>
      </template>

      <template>
        <div :class="$style.rfpsTitle">
          <h3 :class="$style.cardTitle">Hotel List View Type</h3>
        </div>

        <h5 :class="$style.rfpSubTitle">
          Users can view all hotels (default) or search them by location. If no Locations are uploaded in Locations Manager, users can still search for hotels by city.
        </h5>

        <div :class="$style.groupCentered">
          <div>
            <check-box
              :value="data.view.useLocations"
              :check-box-value="true"
              :check-box-label="'Use Locations View'"
              class="light"
              :class="$style.columnCheckbox"
              @input="toggleLocationsView()"
            />
          </div>

          <div>
            <button
              class="rbButton rounded"
              type="button"
              @click="showLocationsCustomMessageEditor()"
            >
              Customize Notification
            </button>
          </div>
        </div>
      </template>


      <div :class="$style.group">
        <div :class="$style.cardBody">
          <h3 :class="$style.cardTitle">Display these COLUMNS/FIELDS</h3>
          <div
            v-for="column in columns"
            :key="column.id"
          >
            <check-box
              v-if="column.id !== 'code'"
              :value="data.view.columns.indexOf(column.id) > -1"
              :check-box-value="true"
              :check-box-label="column.text"
              class="light"
              :class="$style.columnCheckbox"
              @input="toggleColumn(column.id)"
            />
            <div
              v-else
              :class="$style.codeSelectorGroup"
            >
              <check-box
                :value="!!selectedCode"
                :check-box-value="true"
                class="light"
                :class="$style.columnCheckbox"
                @input="toggleCode"
              />
              <hd-select
                :value="selectedCode"
                :items="propertyCodes"
                :class="$style.codeDropdown"
                :disabled="!selectedCode"
                @input="selectCode"
              >
                <i
                  slot="icon"
                  class="material-icons"
                > info </i>
                <label slot="label">{{ column.text }}</label>
              </hd-select>
            </div>
          </div>
        </div>
        <div :class="$style.cardBody">
          <h3 :class="$style.cardTitle">Display these RATES</h3>
          <div
            v-for="type in rates"
            :key="type.id"
          >
            <check-box
              :value="data.view.rates.indexOf(type.id) > -1"
              :check-box-value="true"
              :check-box-label="type.name"
              class="light"
              @input="toggleRateType(type.id)"
            />
          </div>
          <div>
            <check-box
              v-model="data.view.showBlackoutDates"
              :check-box-value="true"
              check-box-label="Show Blackout Dates"
              class="light"
            />
          </div>
          <div>
            <check-box
              v-model="data.view.showRateTypes"
              :check-box-value="true"
              check-box-label="Show Rate Type"
              class="light"
            />
          </div>
        </div>
        <div :class="$style.cardBody">
          <h3 :class="$style.cardTitle">Display these HOTEL TYPES</h3>
          <div
            v-for="type in hotelTypes"
            :key="type.id"
          >
            <check-box
              :value="data.view.hotelTypes.indexOf(type.id) > -1"
              :check-box-value="true"
              :check-box-label="type.name"
              class="light"
              @input="toggleHotelType(type.id)"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        v-if="canDelete"
        class="rbButton"
        :class="$style.left"
        type="button"
        @click="drop"
      >
        <i class="material-icons">delete</i>
        Delete View
      </button>
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button class="rbButton mainButton">
        <i class="material-icons">save</i>
        Save Changes
      </button>
    </div>
  </modal-form>
</template>

<script>
import ModalForm from 'vRoot/_core/RbModalForm.vue';
import DialogMixin from 'vRoot/_mixins/DialogMixin.vue';
import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue';
import RbInput from 'root/v-components/_core/RbInput.vue';
import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
import hotelDirectoryService from '../hotel-directory-service';
import HdSelect from 'vRoot/hotel-directory/_core/hd-select.vue';
import VueLocationsCustomMessageEditor from './LocationsCustomMessageEditor.vue'
import { cloneDeep } from 'lodash';
import { Dialog } from 'root/v-app/rbServices';

export default {
  name: 'ManageContentDialog',
  components: { RbInput, ModalForm, CheckBox, HdSelect },
  mixins: [RbFormMixin, DialogMixin],
  props: {
    view: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    rfps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: {
        action: 'update',
        view: cloneDeep(this.view)
      },
      hotelTypes: [
        { id: 'L', name: 'Luxury' },
        { id: 'UU', name: 'Upper Upscale' },
        { id: 'U', name: 'Upscale' },
        { id: 'UM', name: 'Upper Midscale' },
        { id: 'M', name: 'Midscale' },
        { id: 'E', name: 'Economy' }
      ],
      rates: [
        { id: 'SS', name: 'Standard Single' },
        { id: 'SD', name: 'Standard Double' },
        { id: 'US', name: 'Upgraded Single' },
        { id: 'UD', name: 'Upgraded Double' },
        { id: 'DS', name: 'Deluxe Single' },
        { id: 'DD', name: 'Deluxe Double' },
        { id: 'DYN', name: 'Dynamic' }
      ],
      propertyCodes: [
        { id: 'AMADEUS_PROPCODE', text: 'Amadeus Code', value: 'amadeuscode' },
        { id: 'APOLLO_PROPCODE', text: 'Apollo Code', value: 'apollocode' },
        { id: 'SABRE_PROPCODE', text: 'Sabre Code', value: 'sabrecode' },
        {
          id: 'WRLDSPAN_PROPCODE',
          text: 'WorldSpan Code',
          value: 'worldspancode'
        }
      ]
    };
  },
  computed: {
    columns() {
      return hotelDirectoryService.getColumns();
    },
    selectedCode() {
      const c = this.propertyCodes.find(
        code => this.data.view.columns.indexOf(code.value) >= 0
      );
      return c && c.id;
    },
    includeAllRfps() {
      return !this.data.view.rfpIds || this.data.view.rfpIds.length === 0;
    },
    recommendations() {
      return new Array(10)
        .fill(0)
        .map((_, id) => `${id + 1}`)
        .map(id => ({ id, value: id, text: id }));
    },
    maximumRecommendations: {
      get() {
        return `${this.data.view.maximumRecommendations || 1}`;
      },
      set(value) {
        this.data = {
          ...this.data,
          view: { ...this.data.view, maximumRecommendations: +value }
        };
      }
    }
  },
  methods: {
    toggleCode(value) {
      if (value) {
        return this.selectCode(this.propertyCodes[0].id);
      } else {
        return this.selectCode(value);
      }
    },
    onSubmit() {
      return this.data;
    },
    selectCode(e) {
      let columns;
      if (e) {
        if (this.selectedCode) {
          columns = this.data.view.columns.map(column => {
            return column === this.getCodeValue(this.selectedCode)
              ? this.getCodeValue(e)
              : column;
          });
        } else {
          columns = [...this.data.view.columns, this.getCodeValue(e)];
        }
      } else {
        columns = this.data.view.columns.filter(
          column => column !== this.getCodeValue(this.selectedCode)
        );
      }
      this.data.view.columns = columns;
    },

    showLocationsCustomMessageEditor(){
      Dialog.show(VueLocationsCustomMessageEditor, {
        viewId: this.data.view.id,
        message: this.data.view.locationsNotification
      })
    },

    getCodeValue(id) {
      return this.propertyCodes.find(code => code.id === id).value;
    },
    toggleHotelType(id) {
      if (this.data.view.hotelTypes.indexOf(id) === -1) {
        this.data.view.hotelTypes = this.data.view.hotelTypes.concat(id);
      } else {
        this.data.view.hotelTypes = this.data.view.hotelTypes.filter(
          type => type !== id
        );
      }
    },
    toggleRateType(id) {
      if (this.data.view.rates.indexOf(id) === -1) {
        this.data.view.rates = this.data.view.rates.concat(id);
      } else {
        this.data.view.rates = this.data.view.rates.filter(type => type !== id);
      }
    },
    toggleColumn(id) {
      if (this.data.view.columns.indexOf(id) === -1) {
        this.data.view.columns = this.data.view.columns.concat(id);
      } else {
        this.data.view.columns = this.data.view.columns.filter(
          column => column !== id
        );
      }
    },
    drop() {
      this.data.action = 'delete';
      this.$formSubmitHandler();
    },
    toggleRfpId(id) {
      const rfpIds = this.data.view.rfpIds || [];

      if (!rfpIds.includes(id)) {
        this.data.view.rfpIds = [...rfpIds, id];
      } else {
        this.data.view.rfpIds = rfpIds.filter(rfp => id !== rfp);
      }
    },
    toggleAllRfps() {
      this.data.view.rfpIds = !this.includeAllRfps
        ? []
        : this.rfps.map(({ id }) => id);
    },
    toggleLocationsView() {
      this.data.view.useLocations = !this.data.view.useLocations;
    },
  }
};
</script>

<style lang="stylus" module>

.dialog {
  min-width: 750px;
  overflow auto;
}

.Body {
  overflow: auto;
}

.subHeader{
  font-size 12px;
  line-height 14pt;
  color #78909C;
  font-weight lighter;
}

.cardTitle{
  padding 5px 0;
  font-size 13px;
  color: #46555c;
  margin-bottom 10px;
}

.rfpSubTitle{
  font-size: 11px;
  font-weight normal;
}

.cardBody > div{
  height 25px;
}

.group {
  display grid;
  grid-template-columns repeat(3, minmax(0, 1fr));
  gap 10px;
}

.groupCentered {
  display grid;
  grid-template-columns repeat(3, minmax(0, 1fr));
  gap 10px;
  align-items: center;
  margin: 10px 0;
}

.columnCheckbox{
  margin 0 !important;
}

.left{
  float left;
}

.codeSelectorGroup{
  margin-top: 1px;
  display flex;
  align-items: center;
}

.codeDropdown :global .button{
  height: 25px !important;
  min-width: fit-content;
  font-size 12px !important;
  cursor: pointer;
  padding: 2px 6px;
}

.codeDropdown{
  cursor pointer;
  height unset;
}

.codeDropdown :global .button i.material-icons{
  z-index 9;
}

.codeDropdown :global .button i.material-icons:first-child{
  font-size: 18px;
}

.rfpsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rfps{
  border 1px solid #dddee0;
  max-height: 272px;
  overflow: auto;
  margin 15px 0;
}

.rfp{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 5px;
  border-bottom: 1px solid #dddee0;
  color: #a1a9ac;
}

.rfp > span{
  color: #7e96a2;
  font-size 12px;
}

.recommendations {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #46555c;
  margin-bottom: 20px;

  & :global(.rb-action-select) {
    margin: 0 10px;
  }
}
</style>
