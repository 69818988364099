<template>
  <rb-modal @cancel="cancel()" @hide="submit()" :options="modalOptions" :class="$style.dialog">
    <span slot="header">Decline to rebid</span>
    <div slot="body">
      You have chosen to decline to rebid. Are you sure you want to continue?
    </div>
  </rb-modal>
</template>

<script>
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbModal from 'vRoot/_core/RbModal.vue'
  import {notificationUtils, mainAPI} from 'root/angular-injector-provider';

  export default {
    name: 'RbvHotelRfpBidManagerActionSendNoLongerInterested',
    components: { RbModal },
    mixins: [ DialogMixin, RbEventsMixin ],
    props: { 'bidsIds': {
        required: true,
        type: Array
      } },
    data () {
      return {
        modalOptions: {labels: {buttons: {apply: 'Yes', cancel: 'No'}}}
      }
    },
    methods: {
      submit () {
        notificationUtils().onSave( () => mainAPI().sendNoLongerInterested(this.bidsIds[0]) )
          .then( report => this.hide(report), err => this.cancel(err) )
      }
    }
  }
</script>
<style lang="stylus" module="">
  .dialog {
    max-width: 400px;
    font-size: 12px;
    color: #263238;
  }
</style>
