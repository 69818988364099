import InvitationTypesHandlerModules from './modules/InvitationsTypeHandlerModulesLibrary';
import {
  $state,
  InvitationService
} from 'root/angular-injector-provider';
import noop from 'lodash/noop';


import { Dialog } from 'root/v-app/rbServices';
import { PAGE_HOME } from 'root/states';
import ExitInvitationDialog from './dialogs/ExitInvitationInformationDialog.vue';

let invitationToken,
  availableModules,
  activeModule;

export default {
  initialize,
  handleInvitation,
  getToken,
  exit,
}

function initialize(token) {
  invitationToken = token;
  availableModules = InvitationTypesHandlerModules.loadModules();
  activeModule = findActiveModule(availableModules)
    .catch(handleActiveModuleErrors);

  return activeModule
}

function findActiveModule(modules) {
  const currentModule = $state().params.type,
    active = currentModule && modules.find( m => m.id === currentModule );

  return active ? Promise.resolve(active) : Promise.reject('no active modules')
}

function handleActiveModuleErrors(){
  $state().go('error', {id: '500'}).catch(noop)
}

function handleInvitation() {
  return InvitationService().handle(invitationToken)
}

function getToken() {
  return invitationToken;
}

function exit(then, err) {
  return err? Dialog.show(ExitInvitationDialog).then(then).catch(goHome): Promise.reject();
}

function goHome() {
  return $state().go(PAGE_HOME);
}

