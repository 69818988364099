import { checkAndExecute } from 'root/api/helpers';

export default function (api) {

  api.userAccountsManager = {
    listUserAccounts: () => api.server.post('current-user/user-accounts/list'),

    addAccount: data => checkAndExecute(
      () => data,
      () => api.server.post('current-user/user-accounts/add-account', data)
    ),

    createAccount: data => checkAndExecute(
      () => data,
      () => {
        switch(data.entityType){
          case 'HOTEL':
            return api.server.post('current-user/user-accounts/create-account/hotel', data);
          case 'COMPANY':
            return api.server.post('current-user/user-accounts/create-account/company', data);
          default:
            return api.server.post('current-user/user-accounts/create-account/entity', data);
        }
      }
    ),

    setCurrentUserAccount: userAccountId => checkAndExecute(
      () => userAccountId,
      () => api.server.post('current-user/user-accounts/set-current-account', {userAccountId})
    ),

    token: (token) => ({
      listUserAccounts: () => checkAndExecute(
        () => token,
        () => api.server.post('t-user-accounts/list', {token})
      ),

      addAccount: data => checkAndExecute(
        () => token && data,
        () => api.server.post('t-user-accounts/add-account', {token, ...data})
      ),

      createAccount: data => checkAndExecute(
        () => token && data,
        () => {
          switch(data.entityType){
            case 'HOTEL':
              return api.server.post('t-user-accounts/create-account/hotel', {...data, token});
            case 'COMPANY':
              return api.server.post('t-user-accounts/create-account/company', {...data, token});
            default:
              return api.server.post('t-user-accounts/create-account/entity', {...data, token});
          }
        }
      ),

      setCurrentUserAccount: userAccountId => checkAndExecute(
        () => token && userAccountId,
        () => api.server.post('t-user-accounts/set-current-account', {token, userAccountId})
      )
    })
  }
}
