import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';
import Component from './RfpEditQuestionnaireNavigation.svelte';

export default {
  template: '<div></div>',
  controller: Ctrl,
  bindings: {
    id: '<',
    title: '<',
    subtitle: '<',
  }
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(this, SvelteNgComponent(Component, $element[0], () => ({
    id: vm.id,
    title: vm.title,
    subtitle: vm.subtitle,
  })));
}
