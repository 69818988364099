<template>
  <a href="#" class="tdMapChainFilterListBrand" :class="{selected: brand.selected}" @click="toggleSelection($event)">
    <i class="material-icons">{{ icon }}</i>
    <span>{{ brand.name }}</span>
  </a>
</template>

<script>
  import {stopEvent} from "root/shared/tools/view-utils";

  export default {
    name: 'rbv-travel-destinations-map-chains-filter-chain',
    props: [ 'brand' ],
    computed: {
      icon () { return this.brand.selected ? 'check_box' : ' check_box_outline_blank' }
    },
    methods: {
      toggleSelection($event) {
        stopEvent($event)
        this.brand.selected = !this.brand.selected
        this.brand.selected ? this.brand.masterChain.selected++ : this.brand.masterChain.selected--
      }
    }
  }
</script>

<style lang="stylus">
  a.tdMapChainFilterListBrand {
    display block
    height 33px;
    line-height 33px;
    box-sizing border-box
    border-bottom 1px solid #F2F4F5;
    padding-left 35px
    font-size 12px
    color #90A4AE
    text-decoration none
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .material-icons {
      font-size 20px
      margin-right 5px
      vertical-align middle
    }

    &.selected {
      color #455A64;
    }
  }
</style>
