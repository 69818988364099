<script>
  import {onMount} from 'svelte';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import ModalButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import Spinner from 'root/components/atoms/Spinner.svelte';

  import {mainAPI, httpErrorHandler} from 'root/angular-injector-provider';
  import defaultImage from 'images/user-image.png';

  export let rfpId, cancel, hide, selected;

  let selectedCache;

  onMount(() => {
    selectedCache = selected;
  });

  function loadBuyers() {
    return mainAPI().listBuyerContactsForRfp(rfpId)
      .catch(e => httpErrorHandler(e));
  }
</script>


<ClosableModal close="{() => cancel()}">
  <div slot="heading">
    <ModalTitle title="Select Travel Manager">
      This will change the Buyer name on the Cover letter. <br />
      To edit your Profile go to User Profile or Users and Permissions.
    </ModalTitle>
  </div>

  <div class="Options">
    {#await loadBuyers()}
      <div class="FillAndCenter hide-overflow">
        <Spinner />
      </div>
    {:then bla }
      {#each bla.data as contact}
        <div class="Container"
             class:Selected={contact.id === selected}
             data-icon-before="{contact.id === selected ? 'radio_button_checked' : 'radio_button_unchecked'}"
             on:click={() => selected = contact.id}
        >

          <div class="PictureContainer">
            <img class="Picture"
                 src="{contact.profilePicture ? `/images/users/${contact.profilePicture}` : defaultImage }"
                 alt="User">
          </div>

          <div class="TextContainer">
            <div class="Title">{contact.fullName}</div>
            <div class="Text">{contact.jobTitle}</div>
          </div>
        </div>
      {/each}
    {/await}
  </div>

  <ModalButtons disabled="{selected === selectedCache}"
                noAction="{() => cancel()}"
                yesAction="{() => hide(selected)}"
  />
</ClosableModal>



<style lang="stylus">.Container {
  display: flex;
  padding: 5px 15px;
  color: #455a64;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
}
.Container:before {
  margin-right: 15px;
}
.Container.Selected:before {
  color: #00a89d;
}
.PictureContainer {
  flex: 0 0 auto;
  display: flex;
}
.Picture {
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 6px;
}
.TextContainer {
  flex: 1 1 auto;
  height: 40px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.Title {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Text {
  font-size: 13px;
  color: #78909c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Options {
  max-height: 200px;
  overflow-y: auto;
  margin: -5px 0 25px;
}
</style>
