<script>
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import noop from 'lodash/noop';

  export let checking = false, code = '', error = '';

  const TIMEOUT = 900000; // 15 Minutes

  let value, sessionTime = Date.now();

  $: updateCode(value);

  function updateCode(v){
    checkSessionTimeOut()
      .then(() => checkCodeLength(v))
      .then(c => {code = c})
      .catch(noop)
  }

  function checkSessionTimeOut(){
    if((Date.now() - sessionTime) > TIMEOUT){
      error = 'Session has expired. Please refresh page and try again.';
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  }

  function checkCodeLength(v){
    error = '';
    const cNumbers = v.replace(/\D/g,'');
    return cNumbers === v && v.length === 6 ? v : '';
  }

</script>

<div class="Component">
  <!-- svelte-ignore a11y-autofocus -->
  <input type="text" class:error bind:value autofocus maxlength="6"/>
  {#if checking}<div class="Spinner"><Spinner /></div>{/if}
</div>
{#if error}<div class="Error">{error}</div>{/if}

<style lang="stylus">.Component {
  display: inline-flex;
  align-items: center;
}
input {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 30px;
  line-height: 40px;
  width: 120px;
  text-align: center;
  border: 1px solid #dcdee0;
  color: #263238;
  outline: 0;
  transition: all 0.2s linear;
}
input:focus {
  border-color: #00a99d;
}
input.error {
  border-color: #f15a24 !important;
}
.Spinner {
  margin-left: 5px;
  transform: scale(0.8, 0.8);
}
.Error {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  color: #f15a24;
}
</style>
