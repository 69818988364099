/**
 * Created by DejanK on 5/18/2017.
 */
import { find } from 'lodash'
import { NAME as AskToSignOutDialog } from '../dialogs/ask-to-sign-out/ask-to-sign-out.ctrl';
import { NAME as AskToSignUpDialog } from '../dialogs/ask-to-sign-up/ask-to-sign-up.ctrl';
import { NAME as AskToSignInDialog } from '../dialogs/ask-to-sign-in/ask-to-sign-in.ctrl';
import { NAME as AskToSwitchAccountDialog } from '../dialogs/ask-to-switch-account/ask-to-switch-account.ctrl';
import { NAME as AskToAddAccountDialog } from '../dialogs/ask-to-add-account/ask-to-add-account.ctrl';
import { NAME as AskForNewEmailDialog } from '../dialogs/ask-for-new-email/ask-for-new-email.ctrl';
import InvitationRepository from './invitation.repo'

export default InvitationService

InvitationService.$inject = ['CurrentUser', 'AuthServer', '$q', 'NotificationUtils', 'rbDialog'];
function InvitationService(CurrentUser, AuthServer, $q, notificationUtils, dialog) {
  const $service = this, repository = InvitationRepository(AuthServer);

  $service.handle = handleInvitation;
  $service.signUp = (registration) => {
    return repository.signUp(registration)
  };
  $service.addAccount = (registration) => {
    return repository.addAccount(registration)
  };
  $service.changeEmailAddress = changeEmailAddress;

  function handleInvitation(token) {
    return repository.getInvitationDetails(token)
      .then(onValidInvitation, onInvalidInvitation)
  }

  function onValidInvitation(httpResponse) {
    const invitationData = httpResponse.data;

    if (CurrentUser.isSignedIn()) {
      return CurrentUser.get()
        .then((currentUser) => {
          if (currentUser.hasEmailAddress(invitationData.emailAddress)) {
            return handleSignedIn(invitationData)
          } else {
            return askToSignOut(currentUser, invitationData)
              .then(() => { return handleSignedOut(invitationData) })
          }
        })
    } else {
      return handleSignedOut(invitationData)
    }
  }

  function handleSignedOut(invitationData) {
    return invitationData.isUser ? askToSignIn(invitationData) : askToSignUp(invitationData)
  }

  function handleSignedIn(invitationData) {
    return CurrentUser.listAllUserAccounts()
      .then(userAccounts => {
          const userAccount = find(userAccounts.data, ua => ua.accountId === invitationData.accountId);
          if(!userAccount){
            return askToAddUserAccount(invitationData);
          } else if (userAccount.status === 'ACTIVE') {
            return handleActiveUserAccount();
          } else if(userAccount.status === 'UNVERIFIED') {
            return CurrentUser.activateUserAccount(userAccount.id)
              .then(handleActiveUserAccount);
          } else {
            return notificationUtils.showErrorDialog({
              dialogData: {
                heading: 'Access',
                title: 'Denied!',
                message: 'Access to this account has been denied to you by account or ReadyBid administrator.'
              }
            });
          }
        }
      )

    function handleActiveUserAccount(){
      return CurrentUser.get()
        .then(cu => cu.isCurrentAccountId(invitationData.accountId)
          ? $q.resolve(invitationData)
          : askToSwitchUserAccount(invitationData)
        );
    }
  }

  function askToSignOut(currentUser, invitationData) {
    return dialog.show(AskToSignOutDialog, {locals: {User: currentUser, Invitation: invitationData}})
  }

  function askToSignIn(invitationData) {
    return dialog.show(AskToSignInDialog, {locals: {Invitation: invitationData}})
      .then(({notYou}) => {
        if(notYou){
          return askForNewEmail(invitationData)
            .then(details => details.goBack ? askToSignIn(invitationData): details);
        } else {
          return handleSignedIn(invitationData);
        }
      });
  }

  function askToSignUp(invitationData) {
    return dialog.show(AskToSignUpDialog, {locals: {Invitation: invitationData}})
      .then(results => results.notYou? askForNewEmail(invitationData).then(details => details.goBack? askToSignUp(invitationData): details): results);
  }

  function askForNewEmail(invitationData) {
    return dialog.show(AskForNewEmailDialog, {locals: {Invitation: invitationData}})
      .then(({token, goBack}) => goBack? ({goBack}): handleInvitation(token));

  }

  function askToSwitchUserAccount(invitationData) {
    return dialog.show(AskToSwitchAccountDialog, {
      locals: {
        Account: {
          id: invitationData.accountId,
          name: invitationData.accountName
        }
      }
    }).then(() => invitationData);
  }

  function askToAddUserAccount(invitationData) {
    return dialog.show(AskToAddAccountDialog, {locals: {Invitation: invitationData}}).then(() => invitationData)
  }

  function onInvalidInvitation() {
    notificationUtils.showError('invalid-invitation');
    return $q.reject()
  }


  function changeEmailAddress(data) {
    return notificationUtils.onSave(() => repository.changeEmailAddress(data));
  }
}
