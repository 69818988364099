import { currentUser as currentUserProvider } from 'root/angular-injector-provider.js';
import { derived, readable, writable } from 'svelte/store';
import {get} from 'lodash';
import { dataStore, defaultState, formattedData } from './data.store';

export const currentUser = readable(null, set => {
  currentUserProvider().watch(user => {
    set(user);
    if(!user) {
      reset();
    }
  })
})

export const bidTypes = writable(null);

export const onlyAssignments = writable(null);

export const rfpPackageBidId = writable(null);

export const groups = derived(
  currentUser,
  user => {
    return user? [
      {
        id: 'COMPANY',
        label: 'Customers',
        labelKey: 'buyer.company.name',
        icon: 'people',
        root: true
      },
      {
        id: 'PROPERTY',
        label: 'Properties',
        labelKey: 'supplier.company.name',
        icon: 'hotel',
        root: true
      },
      {
        id: 'SUPPLIER_CONTACT',
        label: 'Primary Contacts',
        labelKey: 'supplier.contact.fullName',
        icon: 'person'
      },
      {
        id: 'BUYER_CONTACT',
        label: 'Customer Primary Contacts',
        labelKey: 'buyer.contact.fullName',
        icon: 'person'
      },
      {
        id: 'CHAIN',
        label: 'Chains',
        labelKey: 'supplier.company.chain.master.name',
        icon: 'link',
        hidden: !user.isOfType('HMC'),
        root: true
      },
      {
        id: 'BRAND',
        label: 'Brands',
        labelKey: 'supplier.company.chain.name',
        icon: 'groups',
        root: true
      },
      {
        id: 'HMC',
        label: 'Hotel Management Companies',
        labelKey: 'supplier.company.hotelManagementCompany.name',
        icon: 'business',
        hidden: !user.isOfType('CHAIN'),
        root: true
      },
      {
        id: 'COUNTRY',
        label: 'Countries',
        labelKey: 'analytics.address.country',
        icon: 'location_on',
        root: true
      },
      {
        id: 'CITY',
        label: 'Cities',
        labelKey: 'analytics.address.city',
        icon: 'where_to_vote'
      },
      {
        id: 'RFP',
        label: 'RFP',
        labelKey: 'rfp.specifications.name',
        icon: 'program'
      },
      {
        id: 'BID_PROGRAM_YEAR',
        label: 'Program Year',
        labelKey: 'rfp.specifications.programYear',
        icon: 'av_timer'
      },
      {
        id: 'STATUS',
        label: 'Bid Statuses',
        labelKey: 'state.statusLabel',
        icon: 'timeline'
      }
    ].filter(({ hidden }) => !hidden): []
  }
);

export const urlSteps = writable(['COMPANY']);

export const stepsArray = derived(urlSteps, steps => Array.isArray(steps)? steps: [steps]);

export const stepGroups = derived([stepsArray, groups, formattedData], ([$steps, $groups, $data]) => $steps.reduce((acc, step) => {
  const [id, value] = step? step.split('-'): [];

  if(id) {
    const group = $groups.find(g => g.id === id)
    acc.push({
      ...group,
      value,
      valueLabel: group && value? get($data[0], group.labelKey, 'Undefined') : null
    });
  }

  return acc;
}, []));

export const lastGroup = derived(stepGroups, steps => steps[steps.length - 1])

export const filters = derived(stepGroups, $steps => $steps.map(({id, value}) => id? ({group: id, value}): null).filter(Boolean))

export const sorter = writable('')

export const sortBy = derived(sorter, $sorter => ({
  key: $sorter[0] || 'label',
  direction: $sorter[1]
}))

export const currentPage = writable(1);

export function updateFilters(steps) {
  urlSteps.set(steps);
  currentPage.set(1);
}

export const hasCustomView = derived(currentUser, user => {
  const ids = user && user.currentUserAccount.defaultChainPortalViewAccountIds || [];
  return ids.length > 0;
});

function reset() {
  bidTypes.set(null);
  onlyAssignments.set(null)
  rfpPackageBidId.set(null);
  urlSteps.set(['COMPANY']);
  sorter.set(['label', 'up']);
  currentPage.set(1);
  dataStore.set(defaultState)
}
