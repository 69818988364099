import isEmpty from 'lodash/isEmpty';

const ifOperations = {
  'eq' ( rule, answer ) {
    // noinspection EqualityComparisonWithCoercionJS
    return answer == rule
  },
  'ne' ( rule, answer ) {
    // noinspection EqualityComparisonWithCoercionJS
    return answer != rule
  },
  'included' ( rule, answer ) { return rule.indexOf(answer) !== -1 },
  'isBlank' ( rule, answer ) { return isEmpty(answer) === rule },
  'gt' ( rule, answer ) { return answer > rule },
};

ifOperations.and = ( rule, answer ) => {
  return true;
}

export default Object.freeze(ifOperations);
