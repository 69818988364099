<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'rbv-hotel-rfp-bid-manager-send-responses-action-report',
    mixins: [ ActionReportMixin ],
    data () {
      return {
        messages: {
          'OK': 'Response Sent',
          'NOT_FOUND': 'Response not Sent - Bid Deleted',
          'STATUS_CHANGED': 'Response not Sent - Bid Changed',
          'ERROR': 'Response was already Sent'
        }
      }
    },
    computed: {
      title () { return this.items.length === 1 ? "Response not sent!" : "Failed to send some responses!" },
      message () {
        return this.items.length === 1
          ? "We were not able to send response because of the reason listed below:"
          : `${this.items.length - this.failures} of ${this.items.length} responses were successfully sent.<br /> We were not able to send other responses because of the reasons listed below:`
      }
    }
  }
</script>
