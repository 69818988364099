import angular from 'angular'
import Servers from '../../shared-ng/servers'
import Notifications from '../../ui-ng/notifications'
import NgForm from '../../ui-form-ng'

import RateLoadingInformationRepository from './rate-loading-information.repo'
import rbRateLoadingInformationEditor from './ui/rb-rate-loading-information-editor/rb-rate-loading-information-editor.cmp'
import Dialogs from './dialogs'

export default angular.module('rb.entity.rateLoadingInformation', [
  Servers, Notifications, NgForm
])
  .config(Dialogs)
  .service('RateLoadingInformationRepository', RateLoadingInformationRepository)
  .component('rbRateLoadingInformationEditor', rbRateLoadingInformationEditor)

