<script>
	import { onMount } from 'svelte'
  import ModalYesNoButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import { notificationUtils, PublicAuth } from 'root/angular-injector-provider';
  import {
    isEmailAddressVerificationPendingError,
    toEmailAddressVerification
  } from 'root/public-auth/shared/signInEmailAddressVerificationPendingService';

  export let hide, cancel, credentials;

  const admin = { pin: '' };

  let
    loading = false,
    trials = 0,
    pinInput = null,
    error = '';

  function signIn() {
    loading = true;
    notificationUtils()
      .onSave(() => PublicAuth().signIn({...credentials, adminPin: admin.pin}), {
        customErrorHandlers: {
          '401': httpError => {
            if(isEmailAddressVerificationPendingError(httpError)){
              toEmailAddressVerification(credentials, httpError);
              cancel();
            } else if(++trials >= 5) {
              cancel();
            } else {
              error = 'Invalid PIN number. Please try again.';
              loading = false;
            }
            return Promise.reject();
          },
          '404': () => {
            error = `User <${credentials.email}> does not exist!`
            loading = false;
          }
        }
      })
      .then(hide)
  }

  onMount(() => pinInput.focus());

</script>

<style lang="stylus">.SectionInput {
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 0px 15px 15px;
  color: #758a94;
  font-size: 14px;
}
.ContentContainer {
  min-height: 100px;
  max-height: calc(95vh - 300px);
  overflow: auto;
  color: #fff;
}
.SectionInput label {
  margin-left: 20px;
  line-height: 1;
  z-index: 1;
  background: #1e2a2c;
  width: fit-content;
  padding: 5px;
  font-size: 12px;
  margin-bottom: -12px;
  border-radius: 5px;
}
.Modal :global(.Modal),
.Modal :global(.Modal .Heading button) {
  background-color: #1e292c;
}
.Modal :global(.anInput.atDark:not(:focus)) {
  border-color: #425257;
  background: #1e2a2c;
}
.Error {
  color: #f15a24;
  font-size: 12px;
  margin: 5px 0;
}
.guestBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.logo {
  padding: 0 20px;
  font-size: 20px;
}
.logo span {
  color: #00b8ab;
}
.sub-title {
  font-size: 12px;
  color: #758a94;
  display: block;
}
</style>

<div class="Modal">
  <div class="guestBackground"></div>
  <ClosableModal close={cancel} width="350px">
    <div class="ContentContainer">
      <div class="logo">
        ADMIN <span> PIN </span>

        <small class="sub-title">We detected your password to be an admin password so we just need your PIN number to make sure it's you.</small>
      </div>
      <div class="SectionInput">
        <span class="Error">{error}</span>
      </div>
      <div class="SectionInput">
        <label for="pin">PIN Number</label>
        <input
          id="pin"
          type="password"
          class="anInput asLarge atDark"
          bind:this={pinInput}
          bind:value={admin.pin} />
      </div>
    </div>
    <ModalYesNoButtons
      noAction={cancel}
      yesLabel="Sign In"
      disabled={!admin.pin || loading}
      atLight={false}
      yesIcon={null}
      yesAction={signIn} />
  </ClosableModal>
</div>
