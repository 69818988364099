<Navigation id="{rfpId}" {title} {subtitle} {menu} before="{RfpSpecificationMenuItem}" />


<script>
  import RfpSpecificationMenuItem from './HotelRfpRfpSpecificationMenuItem.svelte';
  import Navigation from 'root/components/organisms/SideNavigation/SideNavigation.svelte';
  import { $state as stateProvider } from 'root/angular-injector-provider.js';
  import { onDestroy } from 'svelte';
  import { createMenuItem, createCoverLetter, createQuestionnaireNavigation, createFinalAgreement  }
    from 'root/components/organisms/SideNavigation/MenuItemFactory.js';

  export let
    rfpId,
    chainSupport = false,
    attachments = {};

  const
    title = 'RFP Details',
    subtitle = 'Manage your RFP';
  let menu;

  $: menu = createMenu(chainSupport, attachments);

  function createMenu(chainSupport, attachments){
    const currentState = stateProvider().current.name;

    return [
      createCoverLetter({
        currentState,
        directLetter: { state: 'hotelRfp.rfp.coverLetter.view', attachments: attachments.direct },
        chainLetter: chainSupport ? { state: 'hotelRfp.rfp.namCoverLetter.view', attachments: attachments.chain } : null
      }),
      createQuestionnaireNavigation(currentState, 'hotelRfp.rfp.questionnaire.view', reloadMenu, onDestroy),
      createMenuItem('Travel Destinations & Hotels', 'near_me', 'hotelRfp.rfp.destinations.view', currentState),
      createFinalAgreement(currentState, 'hotelRfp.rfp.finalAgreement.view', attachments.finalAgreement),
    ];
  }

  function reloadMenu(){
    menu = [...menu];
  }
</script>
