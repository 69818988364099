/**
 * Created by DejanK on 1/23/2018.
 *
 * Uses Event Propagation to handle tooltips, replacing 1 listener per tooltip with 1 listener for all tooltips
 *
 * Usage:
 * - Import to application and run
 * - Add class CSS_CLASS_TOOLTIP (rbTooltip currently) to any element that should be a tooltip
 * - Tooltip element must be the last child (for performance reasons)
 * - Customize tooltip placement and behavior (Popper.js options) with tooltip or data-tooltip attributes
 * -- use key:value notation ( example: placement:top; modifiers.flip.behavior:clockwise; )
 * - Customize tooltip transitions with CSS classes
 * -- simple css class adds basic fade in transition
 * -- when tooltip is shown class CSS_CLASS_TOOLTIP_SHOWN (rbTooltipShown) is added to the tooltip element
 *
 * - do NOT mix position and overflow styles in parent elements to allow tooltips to escape parent boundaries
 * -- tooltips will still flip when they reach view boundaries
 */
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import set from 'lodash/set';
import Popper from 'popper.js';
import './tooltip-listener.scss';

export default CreateTooltipListener;

function CreateTooltipListener(){
  const
    element = document.body,
    throttledUpdateTooltips = throttle(updateTooltips, 100),
    CSS_CLASS_TOOLTIP = 'rbTooltip',
    CSS_CLASS_TOOLTIP_SHOWN = 'rbTooltipShown',
    debouncedShowTooltip = debounce(showPopper, 500);

  let
    popperInstance = null,
    refElement = null,
    tooltipElement = null,
    lastMouseMoveTarget;

  element.addEventListener('mousemove', throttle(onMouseMove, 33));
  element.addEventListener('scroll', debounce(hidePopper, 33, {leading: true}), true);
  // if tooltips persist when location changes try adding window.addEventListener("hashchange", funcRef, false);

  function onMouseMove(event){
    if(event.target !== lastMouseMoveTarget){
      lastMouseMoveTarget = event.target;
      throttledUpdateTooltips(event);
    }
  }

  function updateTooltips(event) {
    const tooltipElements = findTooltipElements(event.target);
    if(tooltipElements){
      showTooltip(tooltipElements);
    } else {
      hidePopper();
    }

    function showTooltip(elements){
      if(elements.parent !== refElement){
        if(popperInstance){
          hidePopper();
        }

        debouncedShowTooltip(elements.tooltip, elements.parent);
      }
    }
  }

  function hidePopper(){
    debouncedShowTooltip.cancel();
    if(popperInstance){
      tooltipElement.classList.remove(CSS_CLASS_TOOLTIP_SHOWN);
      const
        ttElement = tooltipElement,
        pElement = popperInstance,
        tOut = setTimeout(() => {
          ttElement.style.display = '';
          ttElement.style.visibility = '';
          pElement.destroy();
          clearTimeout(tOut);
        }, 100);
      popperInstance = null;
    }
    tooltipElement = null;
    refElement = null;
  }

  function showPopper(tooltip, parent){
    tooltipElement = tooltip;
    refElement = parent;
    const options = readOptions(tooltip);
    tooltip.style.display = 'block';
    popperInstance = displayPopper(parent, tooltip, options);
    tooltip.style.visibility = 'visible';
    tooltip.classList.add(CSS_CLASS_TOOLTIP_SHOWN);
    popperInstance.scheduleUpdate();
  }

  function findTooltipElements(el){
    // const tooltip = findTooltipInChildren(el);
    const tooltip = findTooltipInLastChild(el);
    if(tooltip){
      return {
        tooltip: tooltip,
        parent: el
      }
    } else {
      return el.parentElement && findTooltipElements(el.parentElement);
    }
  }


  // function findTooltipInChildren(el){
  //   const childrenLength = el.childElementCount;
  //   if(childrenLength) {
  //     const children = el.children;
  //     for(let i=0; i<childrenLength; i++){
  //       const c = children.item(i);
  //       if(c.classList && c.classList.contains(CSS_CLASS_TOOLTIP)) {
  //         return c;
  //       }
  //     }
  //   }
  // }

  function findTooltipInLastChild(el){
    const c = el.lastElementChild;
    return c && c.classList && c.classList.contains(CSS_CLASS_TOOLTIP) && c;
  }

  function displayPopper(reference, popper, options){
    const rect = popper.getBoundingClientRect(),
      o = Object.assign( { placement: 'top' }, options);
    const p = o.placement.split('-')[0].toLowerCase();
    o.modifiers = Object.assign({
      preventOverflow: { padding: -1 * ( p === 'top' || p === 'bottom' ? rect.height : rect.width), priority: [ p ] }
    }, o.modifiers );
    return new Popper(reference, popper, o);
  }

  function readOptions(tooltipEl){
    let tData;

    if(tooltipEl.hasAttribute('tooltip')){
      tData = tooltipEl.getAttribute('tooltip');
    } else if(tooltipEl.hasAttribute('data-tooltip')){
      tData = tooltipEl.getAttribute('data-tooltip');
    }

    if(tData && tData.length){
      const values = tData.split(';'), options = {};

      values.forEach( function(v){
        if(v && v.length){
          const keyValue = v.split(':'),
            key = keyValue && keyValue.length && keyValue[0] && keyValue[0].trim(),
            value = keyValue && keyValue[1];

          if(key){
            set(options, key, value.trim());
          }
        }
      });

      return options;
    }

    return undefined;
  }
}
