<template>
  <td v-if="(template.cell)" class="questionnaireTableCellQuestion"
      :class="classes"
      :rowspan="rowSpan"
      :colspan="colSpan">

    <div class="questionnaireTableCellInputContainer" :class="classesForInputContainer">

      <input type="text" readonly="readonly" class="questionnaireTableCellInput"
             :id="template.cell.id"
             :name="template.cell.id"
             :placeholder="template.cell.placeholder"
             :value="answer"
      />

      <tooltip
        :ord="template.cell.ord"
        :title="template.cell.name"
        :description="template.cell.description"
        :required="isRequired"
      />
    </div>
  </td>
  <td v-else="" class="questionnaireTableCellLabel"
      :class="classes"
      :rowspan="rowSpan"
      :colspan="colSpan">
    {{ template.name }}
  </td>
</template>

<script>
  import Tooltip from '../tooltips/QuestionnaireTooltip.vue'
  import { formatDate } from 'root/shared/tools/date-tools'
  import { isEmpty } from 'lodash'

  export default {
    name: 'rb-questionnaire-view-section-table-row-cell',

    components: { Tooltip },

    props: [ 'model', 'template', 'response' ],

    computed: {
      answer () {
        const answer = this.response && this.response.answers[this.template.cell.id]
        return isEmpty(answer) ? undefined : this.formatAnswer(answer)
      },

      classes () {
        return this.template.classes
      },

      classesForInputContainer () {
        return { 'required' : this.isRequired }
      },

      colSpan () {
        return this.span('colspan', 'colspanId', 'colspanIds', )
      },

      isRequired () {
        return !!( this.template.cell.req || (this.model && this.model.req) || (this.validations && this.validations.required) )
      },

      rowSpan () {
        return this.span('rowspan', 'rowspanId', 'rowspanIds', )
      }
    },
    methods: {
      span ( spanKey, spanValueKey, spanArrayKey ) {
        if(this.template[spanValueKey]) {
          return this.model.filters[this.template[spanValueKey]].length
        } else if (this.template[spanArrayKey]) {
          let span = 1
          this.template[spanArrayKey].forEach( (id) => { span *= this.model.filters[id].length })
          return span
        } else {
          return this.template[spanKey] || 1
        }
      },

      formatAnswer (answer) {
        return this.template.cell.type === 'DATE' ? formatDate(answer) : answer
      }
    }
  }
</script>

<style lang="stylus">

  .questionnaireTableCellQuestion, .questionnaireTableCellLabel {
    background-color white
    border 1px solid #CFD8DC
    padding 0
  }

  .questionnaireTableCellLabel {
    padding 0 5px

    &.strong {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .questionnaireTableRow.row-header .questionnaireTableCellLabel {
    background-color: #ECEFF1;
    text-align: center;
    font-weight: bold;
    border: 0;
  }

  .questionnaireTableRow.row-sub-header .questionnaireTableCellLabel {
    background-color: #FDFDFE;
  }

  .questionnaireTableCellInput {
    width: 75px;
    min-width: 100%;
    height: 26px;
    background: white;
    border: 1px solid #DCDEE0;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 26px;
    padding: 0 3px;
    color: #546E7A;

    &::-webkit-input-placeholder {
      color: #B0BEC5;
    }

    &:-moz-placeholder {
      color: #B0BEC5;
    }

    &:-ms-input-placeholder {
      color: #B0BEC5;
    }
  }

  .questionnaireTableCellInputContainer {
    padding 3px

    &.required {
      background-color: #fefcd3
    }
  }
</style>
