<template>
  <notification-dialog-template
    :class="$style.dialog"
    icon="question"
    theme="error"
    @cancel="cancel()"
  >
    <template slot="subTitle">
      Are you sure you wish to
    </template>

    <template slot="title">
      Finalize the Negotiations?
    </template>

    <template slot="message">
      Please know that inputting a rate or amenity change then clicking "Finalize Negotiations" you are confirming
      that the supplier (hotel) is in agreement with this change.
    </template>

    <template slot="footer">
      <v-button @click="cancel()">Cancel</v-button>
      <v-button @click="hide()" type="error">I UNDERSTAND</v-button>
    </template>

  </notification-dialog-template>
</template>

<script>
  import NotificationDialogTemplate from 'vRoot/_core/templates/NotificationDialogTemplate.vue';
  import VButton from 'vRoot/_core/buttons/Button.vue';
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'

  export default {
    name: 'ConfirmNegotiationFinalizationDialog',

    components: { NotificationDialogTemplate, VButton },

    mixins: [ DialogMixin ],
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 500px;
  }

</style>
