/**
 * Created by DejanK on 7/8/2017.
 *
 */
export default TcosFactory;

TcosFactory.$inject = [];
function TcosFactory(){

  const tcosConfig = {
      name: 'Total Cost of Stay',
      abbr: 'TCOS'
  };

  function Tcos(tcosData, negotiation, negotiations){
    Object.assign(this, tcosData, tcosConfig);
    this.negotiation = negotiation;
    this.negotiations = negotiations;
  }

  Tcos.prototype.getFormattedValue = function(season, roomType){
    const tcos = this[`tcos_s${season}_rt${roomType}.value`] || {};
    return this.formatValue(tcos.value);
  };

  Tcos.prototype.formatValue = function(value){
    return this.negotiations.currencyFormat.format(value);
  };

  return {
    create: (tcosData, negotiation, negotiations)=>{
      return new Tcos(tcosData, negotiation, negotiations);
    }
  };
}
