import noop from 'lodash/noop';
import {writable} from 'svelte/store';
import SearchStore from './RbTravelDestinationGlobalSearchDialogStore';
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
import {createBid, addSuppliersToTop} from 'root/rfp-hotel/main/rfp/pages/destinations/suppliers.store.js';

export default function TravelDestinationHotelSearchStoreAdapter(rfpId, destinationId){

  const searchStore = new SearchStore(),
    store = writable({initializing: true}, () => {
      const ssUnsubscribe = searchStore.subscribe($ss => {
        store.update($s => ({
          ...$s,
          ...$ss,
          results: markAllAdded($ss.results, $s.hotelIdBidIdMap)
        }));
      });

      return () => {
        ssUnsubscribe();
      }
    })

  initialize();

  store.search = searchStore.search;
  store.more = searchStore.more;
  store.clearSelected = searchStore.clearSelected;
  store.toggleSelected = searchStore.toggleSelected;
  store.removeSelected = searchStore.removeSelected;

  store.createBid = async function (hotelId){
    try {
      const created = await createBid(hotelId);
      markAdded(hotelId, created.data.data.bidId)
      addSuppliersToTop([created.data.data]);
      return created.data;
    } catch (err){
      markRemoved(hotelId);
      throw(err);
    }
  }

  function markAdded(hotelId, bidId){
    store.update($s => {
      const hotelIdBidIdMap = {
        ...$s.hotelIdBidIdMap,
        [hotelId]: bidId,
      };

      return {
        ...$s,
        hotelIdBidIdMap,
        results: markAllAdded($s.results, hotelIdBidIdMap)
      };
    });
  }

  function markRemoved(hotelId){
    store.update($s => {
      const {[hotelId]: omit, ...hotelIdBidIdMap} = $s.hotelIdBidIdMap;
      return {
        ...$s,
        hotelIdBidIdMap,
        results: markAllAdded($s.results, hotelIdBidIdMap)
      };
    });
  }

  function markAllAdded(results, hotelIdBidIdMap){
    if(!results.suggestions) return results;

    return {
      ...results,
      suggestions: results.suggestions.reduce(hotelIdBidIdMap ? whenBidsAreLoaded: whenBidsAreNotLoaded, []),
    };

    function whenBidsAreLoaded (acc, hotel) {
      acc.push({...hotel, locked: !!hotelIdBidIdMap[hotel.id]});
      return acc;
    }

    function whenBidsAreNotLoaded (acc, hotel) {
      acc.push({...hotel, locked: false});
      return acc;
    }
  }

  function initialize(){
    return notificationUtils().onLoad(() => mainAPI().listDestinationProperties(rfpId, destinationId))
      .then(r => {
        const hotelIdBidIdMap = r.data.reduce((acc, {hotelId, id}) => {
          acc[hotelId] = id;
          return acc;
        }, {});

        store.update($s => ({
          ...$s,
          initializing: false,
          hotelIdBidIdMap,
          results: markAllAdded($s.results, hotelIdBidIdMap)
        }));
      })
      .catch(noop)
  }

  return store;
}
