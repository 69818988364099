import {SvelteDialog} from 'root/v-app/rbServices';
import HotelRfpSpecificationsReportDialog from 'root/rfp-hotel/main/bid/dialogs/HotelRfpSpecificationsReportDialog.svelte';
import noop from 'lodash/noop';
import {mainAPI} from 'root/angular-injector-provider';

export function viewBidPackage(selectedBidsReport){
  return {
    label: 'View Bid Package',
    icon: 'pageview',
    action: () => openBidPackageDialog(selectedBidsReport.bids[0]._id)
  }
}

export function openBidPackageDialog(bidId, showViewBidButton = true) {
  SvelteDialog.show(HotelRfpSpecificationsReportDialog, {bidId, showViewBidButton})
        .catch(noop)
}

export function exportBidPackage(selectedBidsReport){
  return {
    label: 'Export Bid Package',
    icon: 'download',
    action: () => {
      mainAPI().exportBidPackage(selectedBidsReport.bids[0]._id)
    }
  }
}
