<template>
  <div class="account-manage-users">
    <div
      v-if="!loaded"
      class="layout-column flex-center loader"
    >
      <rbv-loading label="Loading, please wait..." />
    </div>
    <template v-else>
      <div class="header flex column">
        <div class="flex row">
          <div class="logo">
            <img
              v-if="userAccount && userAccount.account.logo"
              :src="logo"
              alt="Account Logo"
            >
            <div v-else>
              <i class="material-icons">business</i>
            </div>
          </div>
          <div class="flex column">
            <div class="title">
              {{ userAccount && userAccount.account.name }}
            </div>
            <div class="sub-title">
              {{ module.title }}
            </div>
          </div>
        </div>
        <div class="flex row controls">
          <div class="flex row">
            <dropdown-actions
              v-model="sortBy"
              class="light"
              :items="includedSorters"
              align="left"
            >
              <template slot="value">
                <i class="material-icons">sort</i>
                Sort By: <b>{{ sortBy.label }}</b>
              </template>
            </dropdown-actions>
          </div>

          <template v-if="isFromRfpManager">
            <button
              v-if="canAccessRfpManager"
              class="button"
              @click="openRfpManager"
            >
              <i class="material-icons">arrow_back</i>
              Back to RFP Manager
            </button>
            <button
              v-if="!isCompany"
              class="button"
              @click="openManageCustomers"
            >
              <i class="material-icons">business</i>
              Customers
            </button>
          </template>

          <button
            v-if="isAdmin"
            class="button create"
            @click="createNewUser"
          >
            <i class="material-icons">person_add</i>
            Add New User
          </button>
        </div>
      </div>

      <users-list
        v-if="users && userAccount"
        :users="searchAndSortedUsers"
        :search-term="searchTerm"
        :sort-by="sortBy"
        :user-account="userAccount"
        :current-user-is-admin="isAdmin"
        :current-account-is-company="isCompany"
        :current-account-is-tmc="isTmc"
        @editUser="editUser"
        @confirmUser="confirmUser"
        @deactivateUser="deactivateUser"
        @showAssignments="showAssignments"
        @resendInvitation="resendInvitation"
        @removeInvitation="removeInvitation"
        @changeAccountType="changeAccountType"
      />

      <div class="footer">
        <button
          class="button"
          @click="toggleDeactivatedUsers"
        >
          <i class="material-icons">person_off</i>
          {{ deactivatedUsersShown ? 'Hide' : 'Show' }} Deactivated Users
        </button>

        <div
          v-if="isNotAHotel"
          class="autoVerification"
        >
          <div>User Auto Verification is <span :class="account.userAutoVerification ? 'On' : 'Off'">{{ account.userAutoVerification ? 'ON' : 'OFF' }}</span></div>
          <div>
            <button
              class="button"
              @click="manageAutoVerification"
            >
              Manage Auto Verification
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import dropdownActions from 'vRoot/rfp-manager/core/rfp-manager-dropdown-actions.vue';
  import usersList from './components/UsersList.vue';
  import listUsersService from './services/account-manage-users-list.service';
  import RbvLoading from 'vRoot/_core/RbvLoading.vue';
  import { orderBy } from 'lodash';
  import module from './AccountManageUsersModule';
  import { PAGE_RFP_MANAGER_COMPANY_PROFILE } from 'root/states';
  import { TRAVEL_AGENCY_TYPE } from 'vRoot/account-profile/modules/manage-companies/services/manage-company.service';
  import { SvelteDialog } from 'root/v-app/rbServices';
  import AutoVerificationManager from './components/AutoVerificationManagerDialog.svelte';

  export default {
    name: 'AccountManageUsers',
    components: {dropdownActions, usersList, RbvLoading},
    props: {
      state: {
        type: String,
        required: true
      },
      userAccount: {
        type: Object,
        default: () => ({}),
      },
      isAdmin: {
        type: Boolean,
        default: false,
      },
      account: {
        type: Object,
        default: () => ({}),
      }
    },
    data() {
      const sorters = [
        {
          id: 'nameAsc',
          label: 'User\'s Name A-Z',
          order: 'asc',
          key: 'fullName',
          included: () => true,
        },
        {
          id: 'nameDesc',
          label: 'User\'s Name Z-A',
          order: 'desc',
          key: 'fullName',
          included: () => true,
        },
        {
          id: 'accountTypeAsc',
          label: 'Account Type A-Z',
          order: 'asc',
          key: 'role',
          included: () => true,
        },
        {
          id: 'accountTypeDesc',
          label: 'Account Type Z-A',
          order: 'desc',
          key: 'role',
          included: () => true,
        },
        {
          id: '#companiesAsc',
          label: 'Companies Managed - Asc',
          order: 'asc',
          key: 'accountsManaged.length',
          included: () => !this.isCompany,
        },
        {
          id: '#companiesDesc',
          label: 'Companies Managed - Desc',
          order: 'desc',
          key: 'accountsManaged.length',
          included: () => !this.isCompany,
        },
        {
          id: 'status',
          label: 'User Status',
          order: 'asc',
          key: 'type',
          included: () => true,
        }
      ];

      return {
        sorters,
        sortBy: sorters[2],
        searchTerm: '',
        module,
        deactivatedUsersShown: false,
      }
    },
    asyncComputed: {
      users() { return listUsersService.loadUsers().then(data => data)},
    },
    computed: {
      accountType() {return this.account.type || ''},
      logo() { return `/images/logos/${this.userAccount.account.logo}` },
      loaded() { return this.users && this.userAccount; },
      filteredUsers() {
        return this.deactivatedUsersShown ? this.users : this.users.filter(u => u.type !== 'UNVERIFIED');
      },
      sortedUsers() { return orderBy(this.filteredUsers, [user => this.isCurrentUser(user)? 1: -1, this.sortBy.key], ['desc', this.sortBy.order])},
      searchAndSortedUsers() {
        return this.searchTerm? this.sortedUsers.filter(user => {
          const searchTerm = this.searchTerm.toLowerCase();
          const matched = user.fullName.toLowerCase().indexOf(searchTerm) >= 0;
          return matched || !!user.accountsManaged.find(({accountDetails}) => accountDetails.name.toLowerCase().indexOf(searchTerm) > -1);
        }): this.sortedUsers;
      },
      isFromRfpManager() { return this.state === PAGE_RFP_MANAGER_COMPANY_PROFILE },
      isCompany() { return this.accountType !== TRAVEL_AGENCY_TYPE; },
      includedSorters() { return this.sorters.filter(({included}) => included())},
      canAccessRfpManager() {
        return this.userAccount && ['COMPANY', 'TRAVEL_AGENCY', 'TRAVEL_CONSULTANCY'].indexOf(this.accountType) > -1
      },
      isTmc() { return this.accountType === TRAVEL_AGENCY_TYPE; },
      isNotAHotel() { return this.accountType !== 'HOTEL' }
    },
    methods: {
      createNewUser() {
        listUsersService.createAgent(this.userAccount.accountId, this.isCompany).then(this.refreshUsers);
      },
      refreshUsers(){
        listUsersService.loadUsers().then((data) => {this.users = data})
      },
      editUser(user) {
        listUsersService.editUser(this.userAccount.accountId, user).then(this.refreshUsers);
      },
      confirmUser(user){
        listUsersService.confirmUser(this.userAccount.accountId, user.id).then(this.refreshUsers);
      },
      deactivateUser(user) {
        listUsersService.deactivateUser(this.userAccount.accountId, user.id).then(this.refreshUsers);
      },
      showAssignments(user) {
        listUsersService.manageAssignments(
          this.userAccount.accountId,
          user,
          this.isAdmin,
          this.isCurrentUser(user),
          !this.canAccessRfpManager
        ).then(this.refreshUsers);
      },
      resendInvitation(user) {
        listUsersService.resendUserInvitation(this.userAccount.accountId, user.id).then(this.refreshUsers);
      },
      removeInvitation(user) {
        listUsersService.removeInvitation(this.userAccount.accountId, user.id).then(this.refreshUsers);
      },
      isCurrentUser(user) { return this.userAccount.id === user.id },
      openRfpManager() { listUsersService.openRfpManager(); },
      openManageCustomers() { listUsersService.openManageCustomers() },
      changeAccountType(user) { listUsersService.changeAccountType(this.userAccount.accountId, user).then(this.refreshUsers) },
      manageAutoVerification() {
        SvelteDialog.show(AutoVerificationManager, {account: JSON.parse(JSON.stringify(this.account))})
          .then(r => {
            this.account.userAutoVerification = r;
          })
      },
      toggleDeactivatedUsers() {
        this.deactivatedUsersShown = !this.deactivatedUsersShown;
      },
    }
  }
</script>

<style lang="stylus">
  .account-manage-users {
    color #37474f;
    position relative;
    overflow hidden;
    max-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;

    .loader {
      height 100%;
    }

    .header {
      flex: 0 0 auto;
      padding: 20px;
      border-bottom: 1px solid #ECEFF1;
      background: #fff;

      & > div > i.material-icons{
        padding: 10px;
        background: #ECEFF1;
        border-radius: 100%;
        margin-right: 10px;
      }

      .title{
        font-size: 12px;
        color: #8c999f;
      }

      .sub-title{
        font-size 20px;
      }
    }

    .footer {
      flex: 0 0 auto;
      padding: 20px;
      border-top: 1px solid #ECEFF1;
      background: #fff;
      display: flex;
      justify-content: space-between;

      .On {
        color: #00A99D;
      }

      .Off {
        color: #f25b26;
      }
    }

    .flex {
      display flex;

      &.row{
        align-items center;
        justify-content flex-start;
      }

      &.column{
        flex-direction column;
      }
    }

    .controls{
      margin-top 10px;
      justify-content space-between !important;

      &, & > div{
        align-items flex-end !important;
      }

      .rfp-manager-group-by{
        font-size 12px;
      }

      & > .row{
        flex: 1;
      }
    }

    .button{
      padding: 2px 5px;
      align-items: center;
      display: flex;
      font-size 12px;
      background white linear-gradient(0deg, #f5f6f7 10%, white 11.05%, white 100%);
      height 27px;

      &:not(.icon-only) {
        i.material-icons{
          margin 0 5px 0 0;
          font-size 18px;
        }
      }
    }

    .create{
      background #00b8ab linear-gradient(0deg, #00b0a3 10%, #00b8ab 11.05%, #00b8ab 100%)
      color: #fff;
      border: 1px solid #00b8a9;
    }

    .logo{
      position relative;
      margin-right 10px;

      & > img, & > div{
        height: 65px;
        width: 110px;
        object-fit: contain;
        border-radius 3px;
        border 1px solid #ECEFF1;
        background: #ECEFF1;
        display: flex;
        justify-content: center;
        align-items: center;

        i.material-icons{
          font-size 50px;
        }
      }

      & > img{
        background transparent;
      }
    }

    .autoVerification {
      display: flex;
      column-gap: 20px;
      align-items: center;
    }
  }
</style>
