import setFinalAgreementPendingActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SetFinalAgreementPendingActionReport.vue';
import noop from 'lodash/noop';
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
import {
  RESPONDED,
  NEGOTIATION_FINALIZED,
  NO_THANK_YOU_PENDING,
  statusMatches,
  NO_LONGER_INTERESTED
} from 'rfp/bid-manager/main/core/actions/action/status';

export default function setFinalAgreementPending(actionResultHandler){
  return function (selectedBidsReport) {
    return {
      label: 'Set Final Agreement Pending',
      icon: 'thumb_up',
      isAvailable: () => {
        return statusMatches(selectedBidsReport.statuses, [RESPONDED, NEGOTIATION_FINALIZED, NO_THANK_YOU_PENDING, NO_LONGER_INTERESTED])
      },
      action: () => {
        notificationUtils().onSave(() => mainAPI().setFinalAgreementPending(selectedBidsReport.bidsIds))
          .then(actionReport => actionResultHandler(actionReport, selectedBidsReport.bids, setFinalAgreementPendingActionReport))
          .catch(noop);
      }
    }
  };
}
