<script>
  import { onMount } from 'svelte';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import ModalButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import {mainAPI, notificationUtils} from 'root/angular-injector-provider';

  export let bidId, cancel, hide;

  const data = {
    rateAccessCode: ''
  };

  let state = '';

  onMount(() => {
    notificationUtils().onLoad(() => mainAPI().getBidRateAccessCode(bidId))
      .then(r => {
        data.rateAccessCode = r.data || '';
        state = 'READY';
      })
  });


  function save(){
    notificationUtils().onSave(() => mainAPI().setBidRateAccessCode(bidId, data))
      .then(hide)
  }

</script>


<ClosableModal width="600px" close="{() => cancel()}">
  <div slot="heading">
    <ModalTitle title="Bid Rate Access Code">
    </ModalTitle>
  </div>

  <div class="rb-input">
    {#if state === 'READY'}
      <label for="rac">Rate Access Code</label>
      <input id="rac" type="text" bind:value={data.rateAccessCode} maxlength="20" />
    {:else}
      <div class="layout-column flex-center">
        <Spinner />
      </div>
    {/if}
  </div>

  <ModalButtons disabled="{state !== 'READY'}"
                noAction="{() => cancel()}"
                yesLabel="Save"
                yesIcon="save"
                yesAction="{() => save()}"
  />
</ClosableModal>

<style lang="stylus">.rb-input {
  padding: 0 15px 15px;
}
</style>
