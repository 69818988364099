import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';

export default {
  template: '<div></div>',
  bindings: {
    base: '<',
    conversionOn: '<',
    onChanged: '&'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;
  Object.assign(this, SvelteNgComponent(import('./NegotiationsCurrencyConverter.svelte'), $element[0], () => ({
    base: vm.base,
    conversionOn: vm.conversionOn,
    setConversion: ev => vm.onChanged({settings: ev})
  })));
}
