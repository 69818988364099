/**
 * Created by DejanK on 8/17/2016.
 */
import '../dialogs.scss'
import '../notifications.scss'
import HTML from './success.html'
const NAME = 'success'

export { NAME, HTML, SuccessController as Controller }

SuccessController.$inject = ['rbDialog', 'Success'];
function SuccessController(rbDialog, Success) {
  var vm = this;

  vm.success = Success;
  vm.cancel = rbDialog.cancel;
}
