<script>
  import defaultImage from "images/user-image.png";
  import adminLogo from "images/rb-user-icon.svg";

  import moment from "moment";
  import {createEventDispatcher} from 'svelte';

  export let note;
  export let isUser;
  const dispatch = createEventDispatcher();

  let userPicture = note.created.by.rbAdmin? adminLogo: note.created.by.profilePicture? `images/users/${note.created.by.profilePicture}`: defaultImage;

  $: message = parseNote(note.note);
  $: time = moment(note.created.at).format("MM/DD/YYYY hh:mm A");
  $: companyName = note.created.by.rbAdmin? 'ReadyBid Support' :note.created.by.companyName;

  function fallbackToDefaultImage() {
    if(userPicture !== defaultImage) {
      userPicture = defaultImage;
    }
  }

  function remove() {
    dispatch("remove");
  }

  function toggle() {
    dispatch("toggle");
  }

  function parseNote(m) {
    return m
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
      .replace(/(?:\r\n|\r|\n)/g, '<br>')
  }
</script>

<style lang="stylus">.note {
  margin: 10px 15px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}
.note p {
  font-size: 13px;
}
.note .Picture.NotePicture {
  height: 42px;
  width: 42px;
}
.details {
  display: flex;
}
.message {
  flex: 1;
  padding: 0 10px;
  color: #546e7a;
}
.message p:last-child {
  color: #455a64;
}
.footer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.7;
}
p.action {
  display: inline-flex;
  align-items: center;
  color: #455a64;
  font-size: 12px;
}
.action:not(.time) {
  border-left: 1px solid #455a64;
  margin-left: 5px;
  padding-left: 10px;
}
.action i {
  font-size: 15px;
  margin-right: 5px;
  text-decoration: none;
}
.action span {
  cursor: pointer;
}
.action span:hover {
  text-decoration: underline;
}
</style>

<div class="note">
  <div class="details">
    <img
      class="Picture NotePicture"
      class:admin={note.created.by.rbAdmin}
      src={userPicture}
      alt="Sender"
      on:error={() => fallbackToDefaultImage()} />
    <div class="message">
      <p><b>{note.created.by.fullName}</b> ({companyName})</p>
      <p>
        {@html message}
      </p>
    </div>
  </div>
  <div class="footer">
    <p class="action time">
      <i class="material-icons">history</i>
      {time}
    </p>
    {#if isUser}
      <div class="actions">
        <p class="action" on:click|preventDefault={remove}>
          <i class="material-icons">delete</i>
          <span>Delete</span>
        </p>
        <p class="action" on:click|preventDefault={toggle}>
          <i class="material-icons">visibility{!note.public? '': '_off'}</i>
          <span>Move to {note.public? 'Private': 'Public'}</span>
        </p>
      </div>
    {/if}
  </div>
</div>
