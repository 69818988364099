import {mainAPI} from 'root/angular-injector-provider';

const cache = {};

export default async function isSabreAvailable(sabreCode = '') {
  if(sabreCode === ''){
    return Promise.resolve(true)
  }

  else if(sabreCode in cache) {
    return Promise.resolve(cache[sabreCode])
  }

  else {
    try {
      const isAvailable = await mainAPI().isSabreAvailable(sabreCode);
      cache[sabreCode] = isAvailable;
      return Promise.resolve(isAvailable);
    } catch(err){
      return Promise.resolve(true);
    }
  }
}