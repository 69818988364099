import angular from 'angular'
import RbMap from './rb-map/rb-map.module'
import rbInputCoordinates from './coordinates/rb-input-coordinates.cmp'

import './rb-map.scss'

export default angular.module('rb.google', [ RbMap.name ])
  .service('RbGoogle', require('./rb-google.service'))
  .component('rbCoordinates', require('./coordinates/rb-coordinates.cmp'))
  .component('rbInputCoordinates', rbInputCoordinates)
