/**
 * Created by DejanK on 10/14/2016.
 */
export { isValidToken, getTokenPayload }

function isValidToken(token) {
  try {
    const payload = JSON.parse(window.atob(token.split('.')[1]));
    return new Date(payload.exp * 1000) > new Date();
  } catch (e) {
    return false;
  }
}

/* should be unicode safe */
function getTokenPayload(token){
  try {
    const
      base64Url = token.split('.')[1],
      base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
      jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

    return JSON.parse(jsonPayload);
  } catch (e){
    console.error(e)
    throw e;
  }
}
