import AccountBasicInformationModule from './AccountBasicInformationModule.vue'
export const ACCOUNT_BASIC_INFORMATION = 'basic';

export default {
  id: ACCOUNT_BASIC_INFORMATION,
  priority: 10,
  title: 'Basic Information',
  icon: 'business_center',
  component: AccountBasicInformationModule,
  isAvailable () { return true }
}
