/**
 * Created by DejanK on 11/6/2017.
 */
import HTML from './due-date-expired.html'
import { DateFormatter } from 'root/internationalization/internationalization.service'
const NAME = 'dueDateExpiredNotification'

export { NAME, HTML, DueDateExpiredNotificationCtrl as Controller }

DueDateExpiredNotificationCtrl.$inject = ['rbDialog', 'expiredDueDate']
function DueDateExpiredNotificationCtrl( dialog, expiredDueDate ){
  const vm = this

  vm.expiredDueDate = DateFormatter( null, null, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric' }
  ).format(expiredDueDate)

  vm.save = dialog.hide
  vm.cancel = dialog.cancel
}
