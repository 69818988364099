import { mainAPI } from 'root/angular-injector-provider';

export default {
  labelOf,
  getName
}

function labelOf(type) {
  return mainAPI().getEntityTypes()
    .then( types => {
      const typeObject = types.find( t => t.id === type )
      return typeObject === null ? type : typeObject.label
    })
    .catch( () => type )
}

function getName(t){
  switch (t){
    case 'COMPANY': return 'Company';
    case 'TRAVEL_AGENCY': return 'Travel Agency';
    case 'TRAVEL_CONSULTANCY': return 'Travel Consultancy';
    case 'HOTEL': return 'Hotel';
    case 'CHAIN': return 'Hotel Chain';
    case 'HMC': return 'Hotel Management Company';
    default: return t;
  }
}
