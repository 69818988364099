/**
 * Created by DejanK on 4/4/2017.
 */
import HTML from './delete-destination.html';

const NAME = 'deleteTravelDestination';

export { NAME, HTML, DeleteTravelDestinationDialogCtrl as Controller}

DeleteTravelDestinationDialogCtrl.$inject = ['NotificationUtils', 'rbDialog', 'RfpHotelRfpDestinationsRepository'];
function DeleteTravelDestinationDialogCtrl(notificationUtils, dialog, rfpHotelRfpDestinationsRepository){
  const vm = this;
  vm.confirm = confirm;
  vm.close = dialog.hide;
  vm.cancel = dialog.cancel;

  function confirm(){
    vm.dialogState = 'working';

    notificationUtils.onLoad(() => rfpHotelRfpDestinationsRepository.deleteRfpTravelDestination(vm.destination.id, vm.rfpId), vm, 'results')
      .then(() => { vm.dialogState = 'finished'; });
  }
}
