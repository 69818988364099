/**
 * Mounts svelte component to angular component
 *
 * @param component Object |function Svelte Component or import() promise
 * @param targetElement element to attach Svelte component to
 * @param propsProvider function providing values for Svelte component properties.
 * @param listeners Object containing event names as keys and event handlers as values
 * @return {{$onChanges(): void, $onInit(): void, $onDestroy(): void}}
 */
export default function (component, targetElement, propsProvider = () => ({}), listeners = {}) {
  let svelteComponent;

  return {
    async $onInit() {
      try {
        const c = await component;
        const Component = c.default ? c.default : c;
        svelteComponent = new Component({
          target: targetElement,
          props: propsProvider()
        });

        Object.keys(listeners).forEach(event => svelteComponent.$on(event, listeners[event]));
      } catch(e){
        // eslint-disable-next-line no-console
        console.error('Component failed to load', e)
      }
    },

    $onChanges(){
      if(svelteComponent)
        svelteComponent.$set(propsProvider())
    },

    $onDestroy() {
      if(svelteComponent) {
        try {
          svelteComponent.$destroy();
          svelteComponent = null;
        } catch(ignore){
          // suppresses https://github.com/sveltejs/svelte/issues/4765
        }
      }
    },
  }
}
