<template>
  <div class="vModal vModalDefault LocationsCustomMessageEditor">
    <div class="vModalDefaultHeader">
      Custom Notification Editor
      <button type="button" class="vModalDefaultCloseButton" @click="cancel()">close</button>
    </div>

    <div class="Body">
      <div
        v-if="loading"
        class="Loader"
      >
        <RbvLoading />
      </div>
      <RichTextarea
        v-else
        :init="tinyMceConfig"
        :model="content"
        @onChange="updateModel"
        @onUndo="updateModel"
        @onRedo="updateModel"
      />
    </div>

    <div class="vModalDefaultFooter">
      <button type="button" class="rbButton" @click="cancel()">Cancel</button>
      <button type="button" class="rbButton mainButton" @click="save()">Save</button>
    </div>
  </div>
</template>

<script>
import DialogMixin from 'vRoot/_mixins/DialogMixin.vue';
import RichTextarea from 'vRoot/_core/inputs/RichTextarea.vue';
import {editorBodyStyleClass, editorStyles} from 'vRoot/_core/inputs/RichTextareaLetterEditorStyles';
import RbvLoading from 'vRoot/_core/RbvLoading.vue';
import {hotelDirectoryRepository, notificationUtils} from 'root/angular-injector-provider';

export default {
  name: 'HotelDirectoryLocationsViewCustomNotificationEditor',
  components: { RbvLoading, RichTextarea },
  mixins: [DialogMixin],
  props: {
    viewId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    content: '',
    loading: true,
    tinyMceConfig: {
      height: '100%',
      menubar: false,
      elementpath: false,
      resize: false,
      statusbar: false,
      body_class: editorBodyStyleClass,
      content_style: editorStyles,
      plugins: [
        'advlist autolink code charmap hr image imagetools ',
        'insertdatetime link lists media noneditable paste table ',
        'print preview searchreplace visualblocks '
      ],
      toolbar: 'undo redo | insert | styleselect | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | table',
      contextmenu: 'link image inserttable | cell row column deletetable',
    },
  }),
  mounted() {
    notificationUtils().onLoad(() => hotelDirectoryRepository().getLocationViewNotification(this.viewId))
      .then(response => {
        this.content = response.data || '';
        this.loading = false;
      });
  },
  methods: {
    save () {
      notificationUtils().onSave(() => hotelDirectoryRepository().setLocationViewNotification(this.viewId, this.content))
        .then(() => this.hide())
    },
    updateModel(event, editor){
      this.content = editor.getContent();
    },
  }
};
</script>

<style lang="stylus">
.LocationsCustomMessageEditor {
  min-width: 920px;

  .Body {
    margin-top: 20px;
    min-height: 300px;
    height: 620px;
    width: 920px;
  }

  .Loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
