/**
 * Created by DejanK on 1/11/2017.
 *
 * rbMin
 *
 * Validates that number is not less of given value
 */

'use strict';

module.exports = rbMin;

function rbMin() {
  return {
    require: '?ngModel',
    link: (s, e, attrs, ngModelCtrl)=>{
      let min = parseFloat(attrs.rbMin);
      if(!isNaN(min)){
        if(ngModelCtrl){
          ngModelCtrl.$validators.rbMin = (modelValue, viewValue)=>{
            let v = parseFloat(modelValue || viewValue);
            return isNaN(v) || v >= min;
          }
        }
      } else {
        throw `RbMin requires a number but got ${attrs.rbMin}`;
      }
    }
  }
}
