import get from 'lodash/get';
import noop from 'lodash/noop';
import HTML from './view-cover-letter.html';
import scrollToAttachments from 'root/rfp-hotel/attachments/scroll-to-attachments.service';

export { HTML, BidCoverLetterViewController as Controller }

BidCoverLetterViewController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state'];
function BidCoverLetterViewController(utils, RfpHotelBidRepository, $state){
  const vm = this;

  $onInit();

  function $onInit(){
    vm.bidId = $state.params.bidId;

    utils.onLoad(() => RfpHotelBidRepository.getBidPreview(vm.bidId), vm, 'bid', )
      .then(() => {
        const bidData = vm.bid.data;
        vm.rfpName = get(bidData, 'specifications.name', '');
        vm.supplierName = get(bidData, 'supplier.company.name', '');
        if($state.params.attachments) scrollToAttachments();
      })
      .catch(noop)
  }
}