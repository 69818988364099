import HTML from './bid-response.cmp.html';
import get from 'lodash/get';

const
  STANDARD = 'STANDARD',
  RATE_QUICK = 'RATE_QUICK',
  PER_DIEM = 'PER_DIEM';

export default {
  template: HTML,
  bindings: {
    bid: '<',
    options: '<'
  },
  controller: Ctrl,
}

Ctrl.$inject = [];
function Ctrl(){
  const vm = this;
  vm.isStandard = false;
  vm.isRateQuick = false;
  vm.isPerDiem = false;

  vm.$onChanges = () => {
    if(vm.bid){
      vm.isStandard = isSubtype(vm.bid, STANDARD);
      vm.isRateQuick = isSubtype(vm.bid, RATE_QUICK);
      vm.isPerDiem = isSubtype(vm.bid, PER_DIEM);
    }
  }
}

function isSubtype( bid, subType ){
  return (get(bid, 'specifications.subType', STANDARD) === subType);
}
