<template>
    <div class="rbTooltip simple questionnaireTooltip">

      <div class="questionnaireTooltipBody">
        <div><strong>{{ ord }}. {{ title }}</strong></div>
        <div v-if="description" class="margin-top">{{ description }}</div>
      </div>

      <div v-if="canEdit" class="questionnaireTooltipHint">
        <div v-if="locked" class="questionnaireTooltipHintTextLocked">
          This question is Locked
        </div>
        <div v-if="required" class="questionnaireTooltipHintTextRequired">
          This question is Mandatory
        </div>

        <div v-if="!locked">
          <div class="questionnaireTooltipHintText">
            <strong class="questionnaireEditTooltipHintAction">Left click</strong>
            - <span v-if="added">Remove</span><span v-else="">Add</span> this Question
          </div>
          <div v-if="added" class="questionnaireTooltipHintText">
            <strong class="questionnaireEditTooltipHintAction">Right click</strong>
            - Set as <span v-if="required">Optional</span><span v-else="">Mandatory</span>
          </div>
        </div>
      </div>

      <div v-else-if="isEditUserDefined" class="questionnaireTooltipHint">
        <div class="questionnaireTooltipHintText">
          <strong class="questionnaireEditTooltipHintAction">Left click</strong> - <span>Configure</span> this Question
        </div>
        <div v-if="added" class="questionnaireTooltipHintText">
          <strong class="questionnaireEditTooltipHintAction">Right click</strong>
          - Set as <span v-if="required">Optional</span><span v-else="">Mandatory</span>
        </div>
      </div>
      <div v-else-if="error" class="questionnaireTooltipError">
        <div class="questionnaireTooltipErrorText">
          {{ error }}
        </div>
      </div>
      <div v-else="" class="questionnaireTooltipHint">
        <div v-if="required" class="questionnaireTooltipHintTextRequired">
          This question is Mandatory
        </div>
      </div>
    </div>
</template>

<script>

  export default {
    name: 'rb-questionnaire-tooltip',
    props: {
      ord: {
        type: [Number],
        required: true
      },
      title: {
        type: String,
        required: true
      },
      description: [String],
      canEdit: {
        type: Boolean,
        "default": false
      },
      locked: {
        type: Boolean,
        "default": false
      },
      required: {
        type: Boolean,
        "default": false
      },
      added: {
        type: Boolean,
        "default": true
      },
      error: {
        type: [Boolean,String],
        "default": false
      },
      isEditUserDefined: {
        type: Boolean,
        "default": false
      }
    }
  }
</script>

<style lang="stylus">

  .rbTooltip.simple.questionnaireTooltip {
    padding: 0
  }

  .questionnaireTooltipBody {
    padding: 5px 10px 3px;
  }

  .questionnaireTooltipHint, .questionnaireTooltipError {
    margin-top: 5px;
    font-size: 10px;
    text-align: left;
    background: #37474f;
    border-radius: 0 0 5px 5px;
    line-height 20px
  }

  .questionnaireTooltipError {
    background: #F15A24;
    line-height 15px
    padding: 5px 0
  }

  .questionnaireTooltipHintText,
  .questionnaireTooltipErrorText,
  .questionnaireTooltipHintTextLocked,
  .questionnaireTooltipHintTextRequired {
    color: #b0bec5;
    padding: 0 10px;
  }

  .questionnaireTooltipErrorText {
    color: white;
  }

  .questionnaireTooltipHintTextLocked {
    color: #00b3a6;
  }

  .questionnaireTooltipHintTextRequired {
    color: #fafe68;
  }

  .questionnaireEditTooltipHintAction {
    color: #eceff1;
    text-transform: uppercase;
    font-weight: 700;
  }

</style>
