import angular from 'angular'
import Dialog from '../../ui-ng/dialog'
import Components from '../../ui-ng/components'
import Google from '../../shared-ng/google'

import NotificationUtils from './notification-utils.service'
import HttpErrorHandler from './http-error-handler'

export default angular.module('rb.utils', [ Dialog, Google, Components ])
  .service('NotificationUtils', NotificationUtils)
  .service('HttpErrorHandler', HttpErrorHandler)
