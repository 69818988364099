export default [
  {'id': 'AF', 'flag': 2311, 'name': 'Afghanistan (‫افغانستان‬‎)', 'code': 93},
  {'id': 'AL', 'flag': 1034, 'name': 'Albania (Shqipëri)', 'code': 355},
  {'id': 'DZ', 'flag': 528, 'name': 'Algeria', 'code': 213},
  {'id': 'AS', 'flag': 1562, 'name': 'American Samoa', 'code': 1684},
  {'id': 'AD', 'flag': 594, 'name': 'Andorra', 'code': 376},
  {'id': 'AO', 'flag': 1947, 'name': 'Angola', 'code': 244},
  {'id': 'AI', 'flag': 1980, 'name': 'Anguilla', 'code': 1264},
  {'id': 'AG', 'flag': 869, 'name': 'Antigua And Barbuda', 'code': 1268},
  {'id': 'AR', 'flag': 2377, 'name': 'Argentina', 'code': 54},
  {'id': 'AM', 'flag': 176, 'name': 'Armenia (Հայաստան)', 'code': 374},
  {'id': 'AW', 'flag': 792, 'name': 'Aruba', 'code': 297},
  {'id': 'AC', 'flag': 55, 'name': 'Ascension Island', 'code': 247},
  {'id': 'AU', 'flag': 1716, 'name': 'Australia', 'code': 61},
  {'id': 'AT', 'flag': 1331, 'name': 'Austria (Österreich)', 'code': 43},
  {'id': 'AZ', 'flag': 1243, 'name': 'Azerbaijan (Azərbaycan)', 'code': 994},
  {'id': 'BS', 'flag': 363, 'name': 'Bahamas', 'code': 1242},
  {'id': 'BH', 'flag': 1496, 'name': 'Bahrain (‫البحرين‬‎)', 'code': 973},
  {'id': 'BD', 'flag': 1771, 'name': 'Bangladesh (বাংলাদেশ)', 'code': 880},
  {'id': 'BB', 'flag': 1573, 'name': 'Barbados', 'code': 1246},
  {'id': 'BY', 'flag': 1100, 'name': 'Belarus (Беларусь)', 'code': 375},
  {'id': 'BE', 'flag': 0, 'name': 'Belgium', 'code': 32},
  {'id': 'BZ', 'flag': 484, 'name': 'Belize', 'code': 501},
  {'id': 'BJ', 'flag': 1298, 'name': 'Benin (Bénin)', 'code': 229},
  {'id': 'BM', 'flag': 1914, 'name': 'Bermuda', 'code': 1441},
  {'id': 'BT', 'flag': 1848, 'name': 'Bhutan (འབྲུག)', 'code': 975},
  {'id': 'BO', 'flag': 1650, 'name': 'Bolivia', 'code': 591},
  {'id': 'BA', 'flag': 1584, 'name': 'Bosnia And Herzegovina (Босна и Херцеговина)', 'code': 387},
  {'id': 'BW', 'flag': 2707, 'name': 'Botswana', 'code': 267},
  {'id': 'BR', 'flag': 770, 'name': 'Brazil (Brasil)', 'code': 55},
  {'id': 'IO', 'flag': 55, 'name': 'British Indian Ocean Territory', 'code': 246},
  {'id': 'VG', 'flag': 1408, 'name': 'British Virgin Islands', 'code': 1284},
  {'id': 'BN', 'flag': 1683, 'name': 'Brunei', 'code': 673},
  {'id': 'BG', 'flag': 2586, 'name': 'Bulgaria (България)', 'code': 359},
  {'id': 'BF', 'flag': 726, 'name': 'Burkina Faso', 'code': 226},
  {'id': 'BI', 'flag': 1892, 'name': 'Burundi (Uburundi)', 'code': 257},
  {'id': 'KH', 'flag': 242, 'name': 'Cambodia (កម្ពុជា)', 'code': 855},
  {'id': 'CM', 'flag': 2057, 'name': 'Cameroon (Cameroun)', 'code': 237},
  {'id': 'CA', 'flag': 1375, 'name': 'Canada', 'code': 1},
  {'id': 'CV', 'flag': 2652, 'name': 'Cape Verde (Kabu Verdi)', 'code': 238},
  {'id': 'AN', 'flag': 2719, 'name': 'Caribbean Netherlands', 'code': 599},
  {'id': 'KY', 'flag': 308, 'name': 'Cayman Islands', 'code': 1345},
  {'id': 'CF', 'flag': 1837, 'name': 'Central African Republic (République centrafricaine)', 'code': 236},
  {'id': 'TD', 'flag': 814, 'name': 'Chad (Tchad)', 'code': 235},
  {'id': 'CL', 'flag': 1342, 'name': 'Chile', 'code': 56},
  {'id': 'CN', 'flag': 825, 'name': 'China (中国)', 'code': 86},
  {'id': 'CO', 'flag': 330, 'name': 'Colombia', 'code': 57},
  {'id': 'KM', 'flag': 1430, 'name': 'Comoros (‫جزر القمر‬‎)', 'code': 269},
  {'id': 'CD', 'flag': 1518, 'name': 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'code': 243},
  {'id': 'CG', 'flag': 1793, 'name': 'Congo (Republic) (Congo-Brazzaville)', 'code': 242},
  {'id': 'CK', 'flag': 2267, 'name': 'Cook Islands', 'code': 682},
  {'id': 'CR', 'flag': 2090, 'name': 'Costa Rica', 'code': 506},
  {'id': 'CI', 'flag': 1661, 'name': 'Côte d’Ivoire', 'code': 225},
  {'id': 'HR', 'flag': 902, 'name': 'Croatia (Hrvatska)', 'code': 385},
  {'id': 'CU', 'flag': 748, 'name': 'Cuba', 'code': 53},
  {'id': 'CW', 'flag': 2729, 'name': 'Curaçao', 'code': 599},
  {'id': 'CY', 'flag': 561, 'name': 'Cyprus (Κύπρος)', 'code': 357},
  {'id': 'CZ', 'flag': 2256, 'name': 'Czech Republic (Česká republika)', 'code': 420},
  {'id': 'DK', 'flag': 1386, 'name': 'Denmark (Danmark)', 'code': 45},
  {'id': 'DJ', 'flag': 2101, 'name': 'Djibouti', 'code': 253},
  {'id': 'DM', 'flag': 2432, 'name': 'Dominica', 'code': 1767},
  {'id': 'DO', 'flag': 1529, 'name': 'Dominican Republic (República Dominicana)', 'code': 1809},
  {'id': 'EC', 'flag': 1188, 'name': 'Ecuador', 'code': 593},
  {'id': 'EG', 'flag': 2201, 'name': 'Egypt (‫مصر‬‎)', 'code': 20},
  {'id': 'SV', 'flag': 1639, 'name': 'El Salvador', 'code': 503},
  {'id': 'GQ', 'flag': 1507, 'name': 'Equatorial Guinea (Guinea Ecuatorial)', 'code': 240},
  {'id': 'ER', 'flag': 715, 'name': 'Eritrea', 'code': 291},
  {'id': 'EE', 'flag': 2410, 'name': 'Estonia (Eesti)', 'code': 372},
  {'id': 'ET', 'flag': 2443, 'name': 'Ethiopia', 'code': 251},
  {'id': 'FK', 'flag': 2762, 'name': 'Falkland Islands (Islas Malvinas)', 'code': 500},
  {'id': 'FO', 'flag': 1111, 'name': 'Faroe Islands (Føroyar)', 'code': 298},
  {'id': 'FJ', 'flag': 1859, 'name': 'Fiji', 'code': 679},
  {'id': 'FI', 'flag': 1903, 'name': 'Finland (Suomi)', 'code': 358},
  {'id': 'FR', 'flag': 1012, 'name': 'France', 'code': 33},
  {'id': 'GF', 'flag': 2234, 'name': 'French Guiana (Guyane française)', 'code': 594},
  {'id': 'PF', 'flag': 1705, 'name': 'French Polynesia (Polynésie française)', 'code': 689},
  {'id': 'GA', 'flag': 880, 'name': 'Gabon', 'code': 241},
  {'id': 'GM', 'flag': 627, 'name': 'Gambia', 'code': 220},
  {'id': 'GE', 'flag': 858, 'name': 'Georgia (საქართველო)', 'code': 995},
  {'id': 'DE', 'flag': 2509, 'name': 'Germany (Deutschland)', 'code': 49},
  {'id': 'GH', 'flag': 2112, 'name': 'Ghana (Gaana)', 'code': 233},
  {'id': 'GI', 'flag': 275, 'name': 'Gibraltar', 'code': 350},
  {'id': 'GR', 'flag': 165, 'name': 'Greece (Ελλάδα)', 'code': 30},
  {'id': 'GL', 'flag': 1760, 'name': 'Greenland (Kalaallit Nunaat)', 'code': 299},
  {'id': 'GD', 'flag': 2399, 'name': 'Grenada', 'code': 1473},
  {'id': 'GP', 'flag': 407, 'name': 'Guadeloupe', 'code': 590},
  {'id': 'GU', 'flag': 2366, 'name': 'Guam', 'code': 1671},
  {'id': 'GT', 'flag': 935, 'name': 'Guatemala', 'code': 502},
  {'id': 'GN', 'flag': 2575, 'name': 'Guinea (Guinée)', 'code': 224},
  {'id': 'GW', 'flag': 1925, 'name': 'Guinea-Bissau (Guiné-Bissau)', 'code': 245},
  {'id': 'GY', 'flag': 803, 'name': 'Guyana', 'code': 592},
  {'id': 'HT', 'flag': 319, 'name': 'Haiti', 'code': 509},
  {'id': 'HN', 'flag': 2156, 'name': 'Honduras', 'code': 504},
  {'id': 'HK', 'flag': 2696, 'name': 'Hong Kong (香港)', 'code': 852},
  {'id': 'HU', 'flag': 682, 'name': 'Hungary (Magyarország)', 'code': 36},
  {'id': 'IS', 'flag': 1991, 'name': 'Iceland (Ísland)', 'code': 354},
  {'id': 'IN', 'flag': 1694, 'name': 'India (भारत)', 'code': 91},
  {'id': 'ID', 'flag': 1958, 'name': 'Indonesia', 'code': 62},
  {'id': 'IR', 'flag': 2013, 'name': 'Iran (‫ایران‬‎)', 'code': 98},
  {'id': 'IQ', 'flag': 649, 'name': 'Iraq (‫العراق‬‎)', 'code': 964},
  {'id': 'IE', 'flag': 1969, 'name': 'Ireland', 'code': 353},
  {'id': 'IL', 'flag': 341, 'name': 'Israel (‫ישראל‬‎)', 'code': 972},
  {'id': 'IT', 'flag': 143, 'name': 'Italy (Italia)', 'code': 39},
  {'id': 'JM', 'flag': 1727, 'name': 'Jamaica', 'code': 1876},
  {'id': 'JP', 'flag': 429, 'name': 'Japan (日本)', 'code': 81},
  {'id': 'JO', 'flag': 1463, 'name': 'Jordan (‫الأردن‬‎)', 'code': 962},
  {'id': 'KZ', 'flag': 1210, 'name': 'Kazakhstan (Казахстан)', 'code': 7},
  {'id': 'KE', 'flag': 2630, 'name': 'Kenya', 'code': 254},
  {'id': 'KI', 'flag': 374, 'name': 'Kiribati', 'code': 686},
  {'id': 'KW', 'flag': 2487, 'name': 'Kuwait (‫الكويت‬‎)', 'code': 965},
  {'id': 'KG', 'flag': 1617, 'name': 'Kyrgyzstan (Кыргызстан)', 'code': 996},
  {'id': 'LA', 'flag': 451, 'name': 'Laos (ລາວ)', 'code': 856},
  {'id': 'LV', 'flag': 1936, 'name': 'Latvia (Latvija)', 'code': 371},
  {'id': 'LB', 'flag': 1254, 'name': 'Lebanon (‫لبنان‬‎)', 'code': 961},
  {'id': 'LS', 'flag': 2190, 'name': 'Lesotho', 'code': 266},
  {'id': 'LR', 'flag': 2068, 'name': 'Liberia', 'code': 231},
  {'id': 'LY', 'flag': 132, 'name': 'Libya (‫ليبيا‬‎)', 'code': 218},
  {'id': 'LI', 'flag': 979, 'name': 'Liechtenstein', 'code': 423},
  {'id': 'LT', 'flag': 1122, 'name': 'Lithuania (Lietuva)', 'code': 370},
  {'id': 'LU', 'flag': 1474, 'name': 'Luxembourg', 'code': 352},
  {'id': 'MO', 'flag': 2597, 'name': 'Macau (澳門)', 'code': 853},
  {'id': 'MK', 'flag': 1353, 'name': 'Macedonia (FYROM) (Македонија)', 'code': 389},
  {'id': 'MG', 'flag': 1287, 'name': 'Madagascar (Madagasikara)', 'code': 261},
  {'id': 'MW', 'flag': 2145, 'name': 'Malawi', 'code': 265},
  {'id': 'MY', 'flag': 1870, 'name': 'Malaysia', 'code': 60},
  {'id': 'MV', 'flag': 616, 'name': 'Maldives', 'code': 960},
  {'id': 'ML', 'flag': 2520, 'name': 'Mali', 'code': 223},
  {'id': 'MT', 'flag': 1551, 'name': 'Malta', 'code': 356},
  {'id': 'MH', 'flag': 1144, 'name': 'Marshall Islands', 'code': 692},
  {'id': 'MQ', 'flag': 198, 'name': 'Martinique', 'code': 596},
  {'id': 'MR', 'flag': 253, 'name': 'Mauritania (‫موريتانيا‬‎)', 'code': 222},
  {'id': 'MU', 'flag': 2179, 'name': 'Mauritius (Moris)', 'code': 230},
  {'id': 'MX', 'flag': 2024, 'name': 'Mexico (México)', 'code': 52},
  {'id': 'FM', 'flag': 1738, 'name': 'Micronesia', 'code': 691},
  {'id': 'MD', 'flag': 2685, 'name': 'Moldova (Republica Moldova)', 'code': 373},
  {'id': 'MC', 'flag': 913, 'name': 'Monaco', 'code': 377},
  {'id': 'MN', 'flag': 2553, 'name': 'Mongolia (Монгол)', 'code': 976},
  {'id': 'ME', 'flag': 2167, 'name': 'Montenegro (Crna Gora)', 'code': 382},
  {'id': 'MS', 'flag': 583, 'name': 'Montserrat', 'code': 1664},
  {'id': 'MA', 'flag': 2333, 'name': 'Morocco', 'code': 212},
  {'id': 'MZ', 'flag': 638, 'name': 'Mozambique (Moçambique)', 'code': 258},
  {'id': 'MM', 'flag': 11, 'name': 'Myanmar (Burma)', 'code': 95},
  {'id': 'NA', 'flag': 1881, 'name': 'Namibia (Namibië)', 'code': 264},
  {'id': 'NR', 'flag': 1749, 'name': 'Nauru', 'code': 674},
  {'id': 'NP', 'flag': 110, 'name': 'Nepal (नेपाल)', 'code': 977},
  {'id': 'NL', 'flag': 1441, 'name': 'Netherlands (Nederland)', 'code': 31},
  {'id': 'NC', 'flag': 1276, 'name': 'New Caledonia (Nouvelle-Calédonie)', 'code': 687},
  {'id': 'NZ', 'flag': 1540, 'name': 'New Zealand', 'code': 64},
  {'id': 'NI', 'flag': 154, 'name': 'Nicaragua', 'code': 505},
  {'id': 'NE', 'flag': 550, 'name': 'Niger (Nijar)', 'code': 227},
  {'id': 'NG', 'flag': 2476, 'name': 'Nigeria', 'code': 234},
  {'id': 'NU', 'flag': 2079, 'name': 'Niue', 'code': 683},
  {'id': 'NF', 'flag': 209, 'name': 'Norfolk Island', 'code': 6723},
  {'id': 'MP', 'flag': 704, 'name': 'Northern Mariana Islands', 'code': 1},
  {'id': 'KP', 'flag': 1804, 'name': 'North Korea (조선민주주의인민공화국)', 'code': 850},
  {'id': 'NO', 'flag': 836, 'name': 'Norway (Norge)', 'code': 47},
  {'id': 'OM', 'flag': 2454, 'name': 'Oman (‫عُمان‬‎)', 'code': 968},
  {'id': 'PK', 'flag': 2035, 'name': 'Pakistan (‫پاکستان‬‎)', 'code': 92},
  {'id': 'PW', 'flag': 231, 'name': 'Palau', 'code': 680},
  {'id': 'PS', 'flag': 1199, 'name': 'Palestine (‫فلسطين‬‎)', 'code': 970},
  {'id': 'PA', 'flag': 847, 'name': 'Panama (Panamá)', 'code': 507},
  {'id': 'PG', 'flag': 1485, 'name': 'Papua New Guinea', 'code': 675},
  {'id': 'PY', 'flag': 2344, 'name': 'Paraguay', 'code': 595},
  {'id': 'PE', 'flag': 946, 'name': 'Peru (Perú)', 'code': 51},
  {'id': 'PH', 'flag': 1815, 'name': 'Philippines', 'code': 63},
  {'id': 'PL', 'flag': 1177, 'name': 'Poland (Polska)', 'code': 48},
  {'id': 'PT', 'flag': 517, 'name': 'Portugal', 'code': 351},
  {'id': 'PR', 'flag': 473, 'name': 'Puerto Rico', 'code': 1787},
  {'id': 'QA', 'flag': 462, 'name': 'Qatar (‫قطر‬‎)', 'code': 974},
  {'id': 'RE', 'flag': 264, 'name': 'Réunion (La Réunion)', 'code': 262},
  {'id': 'RO', 'flag': 671, 'name': 'Romania (România)', 'code': 40},
  {'id': 'RU', 'flag': 660, 'name': 'Russia (Россия)', 'code': 7},
  {'id': 'RW', 'flag': 2674, 'name': 'Rwanda', 'code': 250},
  {'id': 'WS', 'flag': 2300, 'name': 'Samoa', 'code': 685},
  {'id': 'SM', 'flag': 2123, 'name': 'San Marino', 'code': 378},
  {'id': 'ST', 'flag': 2388, 'name': 'São Tomé And Príncipe (São Tomé e Príncipe)', 'code': 239},
  {'id': 'SA', 'flag': 33, 'name': 'Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'code': 966},
  {'id': 'SN', 'flag': 2134, 'name': 'Senegal', 'code': 221},
  {'id': 'RS', 'flag': 2465, 'name': 'Serbia (Србија)', 'code': 381},
  {'id': 'SC', 'flag': 1045, 'name': 'Seychelles', 'code': 248},
  {'id': 'SL', 'flag': 737, 'name': 'Sierra Leone', 'code': 232},
  {'id': 'SG', 'flag': 22, 'name': 'Singapore', 'code': 65},
  {'id': 'SX', 'flag': 2773, 'name': 'Sint Maarten', 'code': 1721},
  {'id': 'SK', 'flag': 2212, 'name': 'Slovakia (Slovensko)', 'code': 421},
  {'id': 'SI', 'flag': 1221, 'name': 'Slovenia (Slovenija)', 'code': 386},
  {'id': 'SB', 'flag': 1067, 'name': 'Solomon Islands', 'code': 677},
  {'id': 'SO', 'flag': 1364, 'name': 'Somalia (Soomaaliya)', 'code': 252},
  {'id': 'ZA', 'flag': 2355, 'name': 'South Africa', 'code': 27},
  {'id': 'KR', 'flag': 2245, 'name': 'South Korea (대한민국)', 'code': 82},
  {'id': 'SS', 'flag': 2741, 'name': 'South Sudan (‫جنوب السودان‬‎)', 'code': 211},
  {'id': 'ES', 'flag': 1155, 'name': 'Spain (España)', 'code': 34},
  {'id': 'LK', 'flag': 2641, 'name': 'Sri Lanka (ශ්‍රී ලංකාව)', 'code': 94},
  {'id': 'BL', 'flag': 1012, 'name': 'St. Barthélemy (Saint-Barthélemy)', 'code': 590},
  {'id': 'SH', 'flag': 495, 'name': 'St. Helena', 'code': 290},
  {'id': 'KN', 'flag': 99, 'name': 'St. Kitts And Nevis', 'code': 1869},
  {'id': 'LC', 'flag': 1397, 'name': 'St. Lucia', 'code': 1758},
  {'id': 'MF', 'flag': 55, 'name': 'St. Martin (Saint-Martin)', 'code': 590},
  {'id': 'PM', 'flag': 1078, 'name': 'St. Pierre And Miquelon (Saint-Pierre-et-Miquelon)', 'code': 508},
  {'id': 'VC', 'flag': 2619, 'name': 'St. Vincent And Grenadines', 'code': 1784},
  {'id': 'SD', 'flag': 352, 'name': 'Sudan (‫السودان‬‎)', 'code': 249},
  {'id': 'SR', 'flag': 2663, 'name': 'Suriname', 'code': 597},
  {'id': 'SZ', 'flag': 2278, 'name': 'Swaziland', 'code': 268},
  {'id': 'SE', 'flag': 385, 'name': 'Sweden (Sverige)', 'code': 46},
  {'id': 'CH', 'flag': 1320, 'name': 'Switzerland (Schweiz)', 'code': 41},
  {'id': 'SY', 'flag': 1826, 'name': 'Syria (‫سوريا‬‎)', 'code': 963},
  {'id': 'TW', 'flag': 506, 'name': 'Taiwan (台灣)', 'code': 886},
  {'id': 'TJ', 'flag': 187, 'name': 'Tajikistan', 'code': 992},
  {'id': 'TZ', 'flag': 2289, 'name': 'Tanzania', 'code': 255},
  {'id': 'TH', 'flag': 957, 'name': 'Thailand (ไทย)', 'code': 66},
  {'id': 'TL', 'flag': 2784, 'name': 'Timor-Leste', 'code': 670},
  {'id': 'TG', 'flag': 605, 'name': 'Togo', 'code': 228},
  {'id': 'TK', 'flag': 2751, 'name': 'Tokelau', 'code': 690},
  {'id': 'TO', 'flag': 1089, 'name': 'Tonga', 'code': 676},
  {'id': 'TT', 'flag': 440, 'name': 'Trinidad And Tobago', 'code': 1868},
  {'id': 'TN', 'flag': 539, 'name': 'Tunisia', 'code': 216},
  {'id': 'TR', 'flag': 1606, 'name': 'Turkey (Türkiye)', 'code': 90},
  {'id': 'TM', 'flag': 2542, 'name': 'Turkmenistan', 'code': 993},
  {'id': 'TC', 'flag': 1309, 'name': 'Turks And Caicos Islands', 'code': 1649},
  {'id': 'TV', 'flag': 286, 'name': 'Tuvalu', 'code': 688},
  {'id': 'VI', 'flag': 1782, 'name': 'U.S. Virgin Islands', 'code': 1340},
  {'id': 'UG', 'flag': 1166, 'name': 'Uganda', 'code': 256},
  {'id': 'UA', 'flag': 2002, 'name': 'Ukraine (Україна)', 'code': 380},
  {'id': 'AE', 'flag': 2223, 'name': 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'code': 971},
  {'id': 'GB', 'flag': 55, 'name': 'United Kingdom', 'code': 44},
  {'id': 'US', 'flag': 44, 'name': 'United States', 'code': 1, 'ord': 1},
  {'id': 'UY', 'flag': 2608, 'name': 'Uruguay', 'code': 598},
  {'id': 'UZ', 'flag': 1001, 'name': 'Uzbekistan (Oʻzbekiston)', 'code': 998},
  {'id': 'VU', 'flag': 1265, 'name': 'Vanuatu', 'code': 678},
  {'id': 'VA', 'flag': 2322, 'name': 'Vatican City (Città del Vaticano)', 'code': 379},
  {'id': 'VE', 'flag': 1056, 'name': 'Venezuela', 'code': 58},
  {'id': 'VN', 'flag': 968, 'name': 'Vietnam (Việt Nam)', 'code': 84},
  {'id': 'WF', 'flag': 1012, 'name': 'Wallis And Futuna', 'code': 681},
  {'id': 'YE', 'flag': 1672, 'name': 'Yemen (‫اليمن‬‎)', 'code': 967},
  {'id': 'ZM', 'flag': 1595, 'name': 'Zambia', 'code': 260},
  {'id': 'ZW', 'flag': 2046, 'name': 'Zimbabwe', 'code': 263}
]

// const countries = {
//   "AF": "Afghanistan",
//   "AL": "Albania",
//   "DZ": "Algeria",
//   "AS": "American Samoa",
//   "AD": "Andorra",
//   "AO": "Angola",
//   "AI": "Anguilla",
//   "AG": "Antigua And Barbuda",
//   "AR": "Argentina",
//   "AM": "Armenia",
//   "AW": "Aruba",
//   "AC": "Ascension Island",
//   "AU": "Australia",
//   "AT": "Austria",
//   "AZ": "Azerbaijan",
//   "BS": "Bahamas",
//   "BH": "Bahrain",
//   "BD": "Bangladesh",
//   "BB": "Barbados",
//   "BY": "Belarus",
//   "BE": "Belgium",
//   "BZ": "Belize",
//   "BJ": "Benin",
//   "BM": "Bermuda",
//   "BT": "Bhutan",
//   "BO": "Bolivia",
//   "BA": "Bosnia And Herzegovina",
//   "BW": "Botswana",
//   "BR": "Brazil",
//   "IO": "British Indian Ocean Territory",
//   "VG": "British Virgin Islands",
//   "BN": "Brunei",
//   "BG": "Bulgaria",
//   "BF": "Burkina Faso",
//   "BI": "Burundi",
//   "KH": "Cambodia",
//   "CM": "Cameroon",
//   "CA": "Canada",
//   "CV": "Cape Verde",
//   "AN": "Caribbean Netherlands",
//   "KY": "Cayman Islands",
//   "CF": "Central African Republic",
//   "TD": "Chad",
//   "CL": "Chile",
//   "CN": "China",
//   "CO": "Colombia",
//   "KM": "Comoros",
//   "CD": "Congo",
//   "CG": "Congo",
//   "CK": "Cook Islands",
//   "CR": "Costa Rica",
//   "CI": "Côte d’Ivoire",
//   "HR": "Croatia",
//   "CU": "Cuba",
//   "CW": "Curaçao",
//   "CY": "Cyprus",
//   "CZ": "Czech Republic",
//   "DK": "Denmark",
//   "DJ": "Djibouti",
//   "DM": "Dominica",
//   "DO": "Dominican Republic",
//   "EC": "Ecuador",
//   "EG": "Egypt",
//   "SV": "El Salvador",
//   "GQ": "Equatorial Guinea",
//   "ER": "Eritrea",
//   "EE": "Estonia",
//   "ET": "Ethiopia",
//   "FK": "Falkland Islands",
//   "FO": "Faroe Islands",
//   "FJ": "Fiji",
//   "FI": "Finland",
//   "FR": "France",
//   "GF": "French Guiana",
//   "PF": "French Polynesia",
//   "GA": "Gabon",
//   "GM": "Gambia",
//   "GE": "Georgia",
//   "DE": "Germany",
//   "GH": "Ghana",
//   "GI": "Gibraltar",
//   "GR": "Greece",
//   "GL": "Greenland",
//   "GD": "Grenada",
//   "GP": "Guadeloupe",
//   "GU": "Guam",
//   "GT": "Guatemala",
//   "GN": "Guinea",
//   "GW": "Guinea-Bissau",
//   "GY": "Guyana",
//   "HT": "Haiti",
//   "HN": "Honduras",
//   "HK": "Hong Kong",
//   "HU": "Hungary",
//   "IS": "Iceland",
//   "IN": "India",
//   "ID": "Indonesia",
//   "IR": "Iran",
//   "IQ": "Iraq",
//   "IE": "Ireland",
//   "IL": "Israel",
//   "IT": "Italy",
//   "JM": "Jamaica",
//   "JP": "Japan",
//   "JO": "Jordan",
//   "KZ": "Kazakhstan",
//   "KE": "Kenya",
//   "KI": "Kiribati",
//   "KW": "Kuwait",
//   "KG": "Kyrgyzstan",
//   "LA": "Laos",
//   "LV": "Latvia",
//   "LB": "Lebanon",
//   "LS": "Lesotho",
//   "LR": "Liberia",
//   "LY": "Libya",
//   "LI": "Liechtenstein",
//   "LT": "Lithuania",
//   "LU": "Luxembourg",
//   "MO": "Macau",
//   "MK": "Macedonia",
//   "MG": "Madagascar",
//   "MW": "Malawi",
//   "MY": "Malaysia",
//   "MV": "Maldives",
//   "ML": "Mali",
//   "MT": "Malta",
//   "MH": "Marshall Islands",
//   "MQ": "Martinique",
//   "MR": "Mauritania",
//   "MU": "Mauritius",
//   "MX": "Mexico",
//   "FM": "Micronesia",
//   "MD": "Moldova",
//   "MC": "Monaco",
//   "MN": "Mongolia",
//   "ME": "Montenegro",
//   "MS": "Montserrat",
//   "MA": "Morocco",
//   "MZ": "Mozambique",
//   "MM": "Myanmar",
//   "NA": "Namibia",
//   "NR": "Nauru",
//   "NP": "Nepal",
//   "NL": "Netherlands",
//   "NC": "New Caledonia",
//   "NZ": "New Zealand",
//   "NI": "Nicaragua",
//   "NE": "Niger",
//   "NG": "Nigeria",
//   "NU": "Niue",
//   "NF": "Norfolk Island",
//   "MP": "Northern Mariana Islands",
//   "KP": "North Korea",
//   "NO": "Norway",
//   "OM": "Oman",
//   "PK": "Pakistan",
//   "PW": "Palau",
//   "PS": "Palestine",
//   "PA": "Panama",
//   "PG": "Papua New Guinea",
//   "PY": "Paraguay",
//   "PE": "Peru",
//   "PH": "Philippines",
//   "PL": "Poland",
//   "PT": "Portugal",
//   "PR": "Puerto Rico",
//   "QA": "Qatar",
//   "RE": "Réunion",
//   "RO": "Romania",
//   "RU": "Russia",
//   "RW": "Rwanda",
//   "WS": "Samoa",
//   "SM": "San Marino",
//   "ST": "São Tomé And Príncipe",
//   "SA": "Saudi Arabia",
//   "SN": "Senegal",
//   "RS": "Serbia",
//   "SC": "Seychelles",
//   "SL": "Sierra Leone",
//   "SG": "Singapore",
//   "SX": "Sint Maarten",
//   "SK": "Slovakia",
//   "SI": "Slovenia",
//   "SB": "Solomon Islands",
//   "SO": "Somalia",
//   "ZA": "South Africa",
//   "KR": "South Korea",
//   "SS": "South Sudan",
//   "ES": "Spain",
//   "LK": "Sri Lanka",
//   "BL": "St. Barthélemy",
//   "SH": "St. Helena",
//   "KN": "St. Kitts And Nevis",
//   "LC": "St. Lucia",
//   "MF": "St. Martin",
//   "PM": "St. Pierre And Miquelon",
//   "VC": "St. Vincent And Grenadines",
//   "SD": "Sudan",
//   "SR": "Suriname",
//   "SZ": "Swaziland",
//   "SE": "Sweden",
//   "CH": "Switzerland",
//   "SY": "Syria",
//   "TW": "Taiwan",
//   "TJ": "Tajikistan",
//   "TZ": "Tanzania",
//   "TH": "Thailand",
//   "TL": "Timor-Leste",
//   "TG": "Togo",
//   "TK": "Tokelau",
//   "TO": "Tonga",
//   "TT": "Trinidad And Tobago",
//   "TN": "Tunisia",
//   "TR": "Turkey",
//   "TM": "Turkmenistan",
//   "TC": "Turks And Caicos Islands",
//   "TV": "Tuvalu",
//   "VI": "U.S. Virgin Islands",
//   "UG": "Uganda",
//   "UA": "Ukraine",
//   "AE": "United Arab Emirates",
//   "GB": "United Kingdom",
//   "US": "United States",
//   "UY": "Uruguay",
//   "UZ": "Uzbekistan",
//   "VU": "Vanuatu",
//   "VA": "Vatican City",
//   "VE": "Venezuela",
//   "VN": "Vietnam",
//   "WF": "Wallis And Futuna",
//   "YE": "Yemen",
//   "ZM": "Zambia",
//   "ZW": "Zimbabwe"
// }
