/**
 * Created by DejanK on 4/26/2017
 *
 * General drop down with fully customizable toggler and content
 *
 * config:
 *  toggler: toggler configuration
 *    element: selector (default first child)
 *    events: custom events
 *      open: custom event to open dropdown (default 'click')
 *      close: custom event to close dropdown (default 'click')
 *  * all Popper.js config options
 */
import angular from 'angular'
import './rb-dropdown.scss'
import {stopEvent} from '../../../shared/tools/view-utils'
import { get } from 'lodash'
import Popper from 'popper.js'

export default {
  bindings: {
    onOpen: '&',
    onClose: '&',
    config: '<',
    leaveOpenOnBodyClick: '<',
  },
  transclude: {
    toggler: 'dropdownToggler',
    content: 'dropdownContent'
  },
  template: `<div class="dropdown-toggler" ng-transclude="toggler"></div><div class="dropdown-content" ng-transclude="content"></div>`,
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$scope', '$document'];
function Ctrl($element, $scope, $body){
  const vm = this
  let elements

  vm.opened = false;

  this.$onInit = $onInit
  this.$onDestroy = $onDestroy

  function $onInit(){
    $scope.$timeout(() => {
      elements = loadElements();
      setupActions()
      elements.popper = new Popper(elements.toggler[0], elements.contentContainer[0], vm.config)
      closeDropDown();
    });
  }

  function $onDestroy() {
    $body.off('click')
    elements && elements.popper && elements.popper.destroy()
  }

  function loadElements(){
    const containers = $element.children(),
      contentContainer = angular.element(containers[1]);

    return {
      root: $element,
      toggler: getTogglerElement(containers[0]),
      contentContainer: contentContainer,
      content: getContentElement(contentContainer)
    };

    function getTogglerElement(togglerContainer){
      const selector = get(vm, 'config.toggler.element'),
        togglerContainerElement = angular.element(togglerContainer),
        togglerElement = selector ? togglerContainerElement.find(selector) : togglerContainerElement.children().children()[0]

      if(!togglerElement) { throw new Error('rbDropdown: toggler element not found') }
      return angular.element(togglerElement)
    }

    function getContentElement(contentContainer){
      return angular.element(contentContainer.children()[0]);
    }
  }

  function setupActions(){
    const customEvents = get(vm, 'config.toggler.events')

    customEvents && customEvents.open ? setupCustomOpen(customEvents.open) : setupDefaultOpen()
    customEvents && customEvents.close ? setupCustomClose(customEvents.close) : setupDefaultClose()
    $body.on('click', () => { vm.opened && closeDropDown() })

    function setupCustomOpen(openEvent){
      if(Array.isArray(openEvent)){
        openEvent.forEach(oe => elements.toggler.on(oe, () => { openDropDown() }));
      } else {
        elements.toggler.on(openEvent, () => { openDropDown() })
      }
    }

    function setupDefaultOpen(){
      elements.toggler.on('click', ()=> {
        vm.opened ? closeDropDown(elements, vm) : openDropDown()
      });
    }

    function setupDefaultClose(){
      elements.contentContainer.on('click', ()=>{ return !vm.leaveOpenOnBodyClick && closeDropDown() })
    }

    function setupCustomClose(closeEvent){
      // timeout is added to give time for other events (like click on some list item) to fire
      if(Array.isArray(closeEvent)){
        closeEvent.forEach(ce => elements.toggler.on(ce, () => { $scope.$timeout( () => {
          closeDropDown()
        }, 200) }));

      } else {
        elements.toggler.on(closeEvent, () => { $scope.$timeout( () => {
          closeDropDown()
        }, 200) })
      }
    }
  }

  function closeDropDown(){
    vm.onClose();
    $scope.$timeout(()=>{
      elements.toggler.removeClass('dropdown-open');
      elements.contentContainer.css('display', 'none');
      vm.opened = false;
    });
  }

  function openDropDown(){
    vm.onOpen();
    $scope.$timeout(()=>{
      displayDropDown(elements)
      vm.opened = true;
    });

    function displayDropDown(elements){
      elements.contentContainer.css('display', 'block')
      elements.toggler.addClass('dropdown-open')
      return elements.popper && elements.popper.scheduleUpdate()
    }
  }
}
