/**
 * Created by DejanK on 5/9/2016
 *
 * Prints a date from string
 *
 * usage:
 * rbDate: "empty label"
 */

'use strict';

let lodash = require('lodash');

module.exports = rbDateFilter;

function rbDateFilter()
{
  return function printRbDate(dateString, emptyLabel){
    if(!dateString || !lodash.isString(dateString) || !dateString.length) {
      return emptyLabel || 'none';
    }
    var ymd = dateString.slice(0, 10).split('-');
    return ymd[1] + '/' + ymd[2] + '/' + ymd[0];
  };
}
