<template>
  <div class="snackbar vModal" @submit.prevent.stop="submit">
    <slot name="icon">
      <div class="snackbar-icon">
        <i class="material-icons">info_outline</i>
      </div>
    </slot>
    <slot name="body">
      <div class="snackbar-body"></div>
    </slot>
    <slot name="actions">
      <div class="snackbar-buttons">
        <button class="snackbar-button" type="button" @click="cancel">CLOSE</button>
      </div>
    </slot>
  </div>
</template>

<script>
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  export default {
    name: "RbSnackBar",
    mixins: [DialogMixin]
  }
</script>

<style lang="scss">
  .snackbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    font-size: 12px;
    background: #01b8aa;
    color: #fff;
    padding: 0 15px 0 10px;
    border-radius: 3px;
    border: 1px solid #fff;

    .snackbar-icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;

      i.material-icons{
        font-size: 20px;
      }
    }

    .snackbar-buttons {
      margin-left: 30px;

      .snackbar-button{
        background: transparent;
        border: 0;
        color: #fff;
        font-weight: bold;
        padding: 5px 0;

        &+&{
          margin-left: 5px;
        }
      }
    }
  }
</style>
