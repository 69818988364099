/**
 * Created by DejanK on 5/22/2017.
 */
import { noop } from 'lodash'
import HTML from './ask-for-new-email.html'
const NAME = 'askForNewEmail';

export { NAME, HTML, AskForNewEmailAddress as Controller }

AskForNewEmailAddress.$inject = ['NotificationUtils', 'Invitation', 'InvitationService', 'rbDialog'];
function AskForNewEmailAddress(notificationUtils, invitation, InvitationService, dialog){
  const vm = this;

  vm.emailAddress = '';

  vm.cancel = dialog.cancel;
  vm.submit = submit;
  vm.goBack = goBack;

  $onInit()

  function $onInit(){
    vm.invitation = invitation;
  }

  function submit(){
    notificationUtils.onSave(() => { return InvitationService.changeEmailAddress({
      emailAddress: vm.emailAddress,
      token: invitation.token,
    }) })
      .then(redirect, noop)
  }

  function redirect({data}) {
    dialog.hide(data);
  }

  function goBack() {
    dialog.hide({goBack: true});
  }
}
