<template>
  <table :class="$style.ratesTable" v-if="seasons.length > 0">
    <thead>
    <tr>
      <th colspan="2">{{seasons.length}} Season(s) / {{seasons[0].roomTypes.length}} Room Type(s)</th>
      <th :class="$style.ratesH" :colspan="anyDouble && anyDouble? 2: 1">
        Rates / {{hotel.currency}}
      </th>
    </tr>
    </thead>
    <tbody>
    <template v-for="(season, i) in seasons" >
      <tr>
        <td>Season</td>
        <td>RT</td>
        <td v-if="anySingle">Single</td>
        <td v-if="anyDouble">Double</td>
      </tr>
      <tr :key="i">
        <td :rowspan="season.roomTypes.length">
          <div :class="$style.seasonLabel">
            <i class="material-icons">calendar_today</i>
            <span>{{season.start}} to {{season.end}}</span>
          </div>
        </td>
        <td>1</td>
        <td v-if="anySingle">{{season.roomTypes[0].single}}</td>
        <td v-if="anyDouble">{{season.roomTypes[0].double}}</td>
      </tr>
      <tr v-for="(rt, i) in season.roomTypes.slice(1)">
        <td>{{i+2}}</td>
        <td v-if="anySingle">{{rt.single}} </td>
        <td v-if="anyDouble">{{rt.double}}</td>
      </tr>
    </template>
    </tbody>
    <tfoot v-if="hotel.dynamicRate">
      <tr>
        <td colspan="2">Dynamic Rate</td>
        <td :colspan="anyDouble && anyDouble? 2: 1">{{parseFloat(hotel.dynamicRate).toFixed(2)}}%</td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
    export default {
      name: "RateGrid",
      props: ['hotel'],
      computed: {
        seasons() {
          return this.hotel.seasons.map(season => {
            return {
              start: season.start,
              end: season.end,
              roomTypes: this.getRoomTypes(season.rates)
            }
          })
        },
        anySingle() {
          return this.seasons.filter(season => season.roomTypes.filter(rt => rt.single).length > 0).length > 0;
        },
        anyDouble() {
          return this.seasons.filter(season => season.roomTypes.filter(rt => rt.double).length > 0).length > 0;
        }
      },
      methods: {
        getRoomTypes(rates) {
          const RTGroups = [
            {
              id: 'standard',
              text: 'Standard',
              keys: ['SS', 'SD']
            },
            {
              id: 'upgraded',
              text: 'Upgraded',
              keys: ['US', 'UD']
            },
            {
              id: 'deluxe',
              text: 'Deluxe',
              keys: ['DS', 'DD']
            },
          ];
          return RTGroups.map(group => this.formRT(rates, group)).filter(rt => rt);

        },
        formRT(rates, group) {
          if(rates.hasOwnProperty(group.keys[0]) || rates.hasOwnProperty(group.keys[1])){
            const single = rates[group.keys[0]];
            const double = rates[group.keys[1]];
            return {
              id: group.id,
              name: group.text,
              single: single && parseFloat(single).toFixed(2),
              double: double && parseFloat(double).toFixed(2),
            }
          }
        }
      }
    }
</script>

<style lang="stylus" module>
  .ratesTable{
    width 100%;
    border: 1px solid #d1d3d4;
    text-align left;
    font-size 12px;
    color #627b85;
    border-collapse collapse;
  }

  .ratesTable td, .ratesTable th{
    padding 5px;
    border 1px solid #d1d3d4;
  }

  .ratesTable thead{
    background: #ebeeef;
  }

  .ratesH{
    text-align center;
  }

  .rateCol{
    display flex;
    justify-content flex-start;
    font-weight lighter;
    align-items center;
  }

  .rateCol > span{
    flex 1;
    text-align center;
  }

  .seasonLabel{
    display flex;
    justify-content space-evenly;
  }

</style>
