/**
 * Created by DejanK on 3/24/2017.
 */
import { merge } from 'lodash'

export default (accountData) => { return new Account(accountData) }

function Account(data){
  merge(this, data);
}

Account.prototype.isBuyer = function(rfpType){
  switch (rfpType){
    case 'HOTEL':
      // list all account types that user is a buyer for HOTEL RFP
      return ['COMPANY', 'TRAVEL_AGENCY', 'TRAVEL_CONSULTANCY'].indexOf(this.type) !== -1;
    default :
      // list all account types that user is a buyer (ALL RFPs)
      return ['COMPANY', 'TRAVEL_AGENCY', 'TRAVEL_CONSULTANCY'].indexOf(this.type) !== -1;
  }
};

Account.prototype.getEntityId = function(){
  return this.entityId
}

Account.prototype.getId = function(){
  return this.id
}

Account.prototype.getType = function(){
  return this.type
}

Account.prototype.getHotelDirectoryId = function(){
  return this.hotelDirectoryId
}

Account.prototype.isOfType = function(...types) {
  return types.includes(this.type);
}
