import SendFinalAgreementsActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendFinalAgreementsActionReport.vue';
import { statusMatches, FINAL_AGREEMENT } from 'rfp/bid-manager/main/core/actions/action/status';
import noop from 'lodash/noop';
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';

export default function ResendFinalAgreements(handleActionResultFn){
  return function (selectedBidsReport){
    return {
      label: `Resend Final Agreement${selectedBidsReport.bidsIds.length === 1 ? '' : 's'}`,
      icon: 'thumb_up',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [ FINAL_AGREEMENT ]),
      action: () => action(selectedBidsReport, handleActionResultFn)
    };
  }
}

function action(report, handleActionResultFn){
  notificationUtils().onSave(() => mainAPI().resendFinalAgreements(report.bidsIds))
    .then(actionReport => handleActionResultFn( actionReport, report.bids, SendFinalAgreementsActionReport))
    .catch(noop);
}
