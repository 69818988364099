import { checkAndExecute } from 'root/api/helpers';

export default function ManageCompaniesApi(api) {

  Object.assign(api, {
    assignAgentToCompany: (accountId, request) => checkAndExecute(
      () => accountId && request,
      () => api.server.post(`manage-accounts/update-primary-agent/${accountId}`, request )),

    sendEmailNotificationForAccountPrimaryChange: (request) => checkAndExecute(
      () => request,
      () => api.server.put(`manage-accounts/send-account-manager-changed-notification/`, request )),

    deleteCompany: (accountId, targetId) => checkAndExecute(
      () => accountId && targetId,
      () => api.server.delete(`manage-accounts/delete/${accountId}/${targetId}`)),

    updatePrimaryContact: (accountId, contact) => checkAndExecute(
      () => accountId && contact,
      () => api.server.put(`manage-accounts/${accountId}/update-primary-contact`, contact)),

    findAccountManagedByAgency: (agencyId, accountId) => checkAndExecute(
      () => accountId && agencyId,
      () => api.server.get(`manage-accounts/${agencyId}/get/${accountId}`)
    )
  });
}
