import { writable } from 'svelte/store';
import {httpErrorHandler, mainAPI} from 'root/angular-injector-provider';

const
  initial = { loading: false, ready: false, raw: [], grouped: [] },
  independentMasterChainTemplate = { id: 0, name: 'Independent', code: '--' },
  store = writable(initial);

export default store;

export function init(list = []){
  store.update(values => {
    if(values.loading) return values;
    if(values.ready) return values;

    (list.length ? Promise.resolve(list) : loadAllChains())
      .then(chains => store.set({
        loading: false,
        ready: true,
        raw: chains,
        grouped: groupAndSortChains(chains)
      }))
      .catch(() => { store.set(initial) });

    return {...values, loading: true};
  });
}


function loadAllChains(){
  return mainAPI().listChains()
    .then( response => response.data )
    .catch(error => httpErrorHandler().handle(error))
}

function groupAndSortChains(chains){
  const preparedChains = chains.reduce(groupChains, []);
  preparedChains.sort(sortByName);
  moveIndependentBrandsToTheEnd(preparedChains);
  preparedChains.forEach( c => c.brands.sort(sortByName) );

  return preparedChains;
}

function groupChains(acc, chain) {
  if(chain.subtype === 'BRAND') {
    addBrand(acc, chain);
  }

  return acc;
}

function addBrand(chains, brand){
  let brandMasterChain = brand.masterChain;
  if(!brandMasterChain) brandMasterChain = independentMasterChainTemplate;

  let master = chains.find(m => m.id === brandMasterChain.id);
  if(!master){
    master = { ...brandMasterChain, brands: [] };
    chains.push(master);
  }
  master.brands.push({ ...brand });
}

function sortByName(a, b){
  return a.name.localeCompare(b.name)
}

function moveIndependentBrandsToTheEnd(chains){
  const independentsGroupIndex = chains.findIndex( c => c.id === independentMasterChainTemplate.id );
  if(independentsGroupIndex > -1){
    const independents = chains.splice(independentsGroupIndex, 1);
    chains.push(independents[0]);
  }
}