/**
 * Created by DejanK on 8/02/2017.
 */
'use strict';

let lodash = require('lodash');

module.exports = {
  template: require('./rb-negotiation-view-taxes-cell.html'),
  bindings: {
    negotiations: '<',
    negotiation: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$scope', 'NegotiationsHtmlFactory', '$rootScope'];
function Ctrl($element, $scope, NegotiationsHtmlFactory, $rootScope) {
  let vm = this, taxId = 'taxId';

  vm.$onInit = $onInit;
  vm.$onChanges = $onChanges;

  vm.onMouseEnter = onMouseEnter;
  vm.onMouseLeave = onMouseLeave;


  function $onInit(){
    $scope.$on('RB_NEGOTIATIONS_MOUSE_ENTER', onMouseEnterScopeEvent);
    $scope.$on('RB_NEGOTIATIONS_MOUSE_LEAVE', onMouseLeaveScopeEvent);
  }

  function $onChanges(changes){
    changes.negotiation && changes.negotiation.currentValue && setupVm(changes.negotiation.currentValue);

    function setupVm(negotiation){
      vm.negotiation = negotiation;
      vm.taxes = negotiation.getTaxesSummary();
      vm.taxesTotal = vm.taxes.getFormattedTotal();
      vm.taxesChangeClass = NegotiationsHtmlFactory.buildChangeNotificationClass(vm.taxes.total.change);
    }
  }

  function onMouseEnter(){
    $rootScope.$broadcast('RB_NEGOTIATIONS_MOUSE_ENTER', {cellId: taxId})
  }

  function onMouseLeave(){
    $rootScope.$broadcast('RB_NEGOTIATIONS_MOUSE_LEAVE', {cellId: taxId})
  }

  function onMouseEnterScopeEvent(event, data){
    if(data.cellId === taxId) $element.addClass('highlight');
  }

  function onMouseLeaveScopeEvent(event, data){
    if(data.cellId === taxId) $element.removeClass('highlight');
  }
}
