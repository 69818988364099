<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'rbv-hotel-rfp-bid-manager-delete-bids-action-report',
    mixins: [ ActionReportMixin ],
    data () {
      return {
        messages: {
          'OK': `Reset Successfull`,
          'NOT_FOUND': 'Reset Failed - Bid not found',
          'STATUS_CHANGED': 'Reset Failed - Bid Changed',
          'ERROR': 'Reset Failed'
        }
      }
    },
    computed: {
      title () { return this.items.length === 1 ? "Reset failed!" : "Some bids could not be resetted!" },
      message () {
        return this.items.length === 1
          ? "We were not able to reset a bid because of the reason listed below:"
          : `${this.items.length - this.failures} of ${this.items.length} bids were successfully resetted.<br /> We were not able to reset all bids because of the reasons listed below:`
      }
    }
  }
</script>
