import { writable, get as getStore } from 'svelte/store';
import get from 'lodash/get';
import {isValid, validate, getErrorMessage} from './validations/validations';
import debounce from 'lodash/debounce';
import './RbForm.styl';

const validateForm = debounce(function (element, form){
  const inputs = element.getElementsByTagName('input');
  form.resetErrors();
  for(const i of inputs){
    onInput(i, form);
  }
}, 200);

function Form(values = {}){
  this.valuesStore = writable({
    ...values
  });
  this.errorsStore = writable({ $$isValid: true });
}

Form.prototype.setElement = function (element){
  this.element = element;

  element.addEventListener('change', ({target}) => onInput(target, this));
  element.addEventListener('input', ({target}) => onInput(target, this));
  element.addEventListener('focusout', ({target}) => onFocusOut(target, this));

  this.observer = new MutationObserver(() => { this.validate() });
  this.observer.observe(element, {subtree: true, childList: true})

  this.validate();
}

Form.prototype.setValue = function (id, value = ''){
  this.valuesStore.update($s => {
    const currentValue = get($s, id);
    if(currentValue === value){
      return $s;
    } else {
      const values = set({...$s}, id, value);
      return {...$s, ...values};
    }
  })
}

Form.prototype.resetErrors = function (){
  this.errorsStore.update(() => ({$$isValid: true}));
}

Form.prototype.setError = function (id, error){
  this.errorsStore.update($s => {
    let errors;

    if(error){
      errors = {...$s, [id]: error};
    } else {
      const {[id]: remove, ...err} = $s;
      errors = err;
    }

    return {...errors, $$isValid: Object.keys(errors).length === 1}
  })
}

Form.prototype.validate = function(){validateForm(this.element, this) }

Form.prototype.destroy = function() {
  this.observer.disconnect();
}

Form.prototype.touchAll = function touchAll(){
  const inputs = this.element.getElementsByTagName('input');
  for(const i of inputs){
    i.classList.add('touched')
  }
}

Form.prototype.getModel = function (){
  return removeEmpty({...getStore(this.valuesStore)});
}

export default Form;

function onInput(target, form) {
  const value = getValue(target);
  validate(target, value)
    .finally(() => checkAndUpdateValue(target, value, form));
}

function onFocusOut(target, form) {
  onInput(target, form);
  target.classList.add('touched');
}

function getValue(element){
  const dataValue = 'data-value';
  return element.hasAttribute(dataValue) || element.hasAttribute('required-data-value')
    ? element.getAttribute(dataValue)
    : element.value;
}

function checkAndUpdateValue(target, value = '', form) {
  const id = target.id;
  if (isValid(target)) {
    form.setValue(id, value);
    form.setError(id);
  } else {
    form.setValue(id);
    form.setError(id, getErrorMessage(target))
  }
}

function set(obj, path, value = '') {
  const a = path.split('.')
  const copy = {...obj};
  let o = copy;
  while (a.length - 1) {
    const n = a.shift()
    o[n] = (n in o) ? {...o[n]} : {}
    o = o[n]
  }
  o[a[0]] = value;

  return copy;
}

function removeEmpty(data){
  const type = getType(data);
  if(type === 'ARRAY') {
    return data;
  }

  else if(type === 'OBJECT') {
    const cleaned = Object.keys(data).reduce((acc, k) => {
      const v = removeEmpty(data[k]);
      if(v !== null){ acc[k] = v }
      return acc;
    }, {});

    return Object.keys(cleaned).length ? cleaned : null;
  }

  else {
    return data === '' || data === undefined ? null : data;
  }

  function getType(v){
    if(Array.isArray(v)){
      return 'ARRAY';
    } else if(typeof v === 'object' && v !== null){
      return 'OBJECT';
    } else {
      return 'SIMPLE';
    }
  }
}
