<template>
  <button type="button"
          class="rbCheckboxOptionButton"
          :class="{ 'readonly' : readonly, selected: isSelected }"
          @click.prevent.stop="onClick">
    <span class="material-icons rbCheckboxOptionIcon" aria-hidden="true">{{ stateIcon }}</span>
    <span v-if="checkBoxLabel" class="rbCheckboxOptionText">{{ checkBoxLabel }}</span>
  </button>
</template>

<script>
  export default {
    name: 'rb-checkbox-button',

    props: {
      checkBoxLabel: null,
      checkBoxValue: {
        default: true
      },
      value: null,
      readonly: {
        "default": false,
        "type": Boolean
      }
    },

    computed: {
      isSelected () { return this.value === this.checkBoxValue },
      stateIcon () { return this.isSelected ? 'check_box' : 'check_box_outline_blank' }
    },

    methods: {
      onClick () { !this.readonly && this.$emit('input', this.isSelected ? undefined : this.checkBoxValue) }
    }
  }
</script>

<style lang="stylus">
  .rbCheckboxOptionButton {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 2px;
    line-height: 14px;
    font-size: 10px;
    margin-right: 1px;
    padding: 1px 5px 1px 1px;
    text-transform: uppercase;
    font-weight: 400;
    color: #78909c;
    
    &:focus {
      border-color: #546e7a;
      color #546e7a;
    }

    &.readonly {
      cursor: default;

      &:hover {
        border-color: transparent;
        color #78909c;
      }
    }
  }

  .rbCheckboxOptionIcon.material-icons {
    font-size: 14px;
    height: 14px;
    line-height: inherit;
    vertical-align: middle;
  }

  .rbCheckboxOptionText {
    vertical-align: middle;
  }
</style>
