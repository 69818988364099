import { checkAndExecute } from 'root/api/helpers';

const urlsPerType = {
  HOTEL: {
    searchEntities: 'hotels/search',
    searchEntityById: id => `hotels/search/${id}`,
    signUpAsNew: 'sign-up/hotel'
  },

  COMPANY: {
    searchEntities: 'companies/search',
    signUpAsNew: 'sign-up/company'
  },

  CHAIN: {
    searchEntities: 'hotel-chains/master/search',
    signUpAsNew: 'sign-up/entity'
  },

  CHAIN_BRAND: {
    searchEntities: 'hotel-chains/brand/search',
  },

  HMC: {
    searchEntities: 'hotel-management-companies/search',
    signUpAsNew: 'sign-up/entity'
  },

  TRAVEL_AGENCY: {
    searchEntities: 'travel-agencies/search',
    signUpAsNew: 'sign-up/entity'
  },

  TRAVEL_CONSULTANCY: {
    searchEntities: 'travel-consultancies/search',
    signUpAsNew: 'sign-up/entity'
  },
};


export default function (o) {
  Object.assign(o, {

    searchEntities (type, query, page) {
      return this.server.get(urlsPerType[type].searchEntities, {query: query, page: page || 1});
    },

    searchEntityById (type, entityId) {
      return this.server.get(urlsPerType[type].searchEntityById(entityId));
    },

    isSabreAvailable(sabreCode){
      if(!sabreCode) return Promise.resolve(false);

      return this.server.post('available/sabre-code', {value: sabreCode})
    },

    signUp(data) {
      const urls = urlsPerType[data.entityType],
        url = data.createNewEntity ? urls.signUpAsNew : `sign-up/entities/${data.entityType}/${data.values.entityId}`;
      return this.server.post(url, data.values);
    },

    signInWithToken(token) {
      return this.server.post('token-sign-in', {token})
    },

    setPaymentMethod(paymentMethodId){
      return checkAndExecute(
        () => paymentMethodId,
        () => this.server.put('payment-method/set-default', {paymentMethodId})
      )
    }
  })
}
