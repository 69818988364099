<script type="text/jsx">
    import uniqueId from 'lodash/uniqueId';
    import Editor from '@tinymce/tinymce-vue';
    import { TINY_MCE_KEY } from 'config/app.config';

    export default {

        functional: true,

        render (h, { props, listeners }) {
            const id = uniqueId('toolbar_');

            return <div style="height: 100%">
                <div id={ id } style="width:917px"></div>

                <Editor
                    api-key={ TINY_MCE_KEY }
                    initial-value={ props.model || '' }
                    init={ { ...props.init, fixed_toolbar_container:`#${id}`} }
                    cloud-channel="5"
                    inline={ props.inline || false }
                    { ...{ on: listeners } }
                />
            </div>
        }
    }
</script>

<style lang="stylus">
.tox-tinymce-aux {
  z-index: 30000 !important;
}
</style>
