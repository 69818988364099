<template>
  <div class="travelDestinationsMapSuppliersFilterInputWrapper">
    <input type="text"
           class="travelDestinationsMapSuppliersFilterInput"
           placeholder="Enter Property Name, Chain, Brand..."
           :value="searchTerm"
           @input="onSearch" />
  </div>
</template>

<script>
  import rbEventsMixin from '../../_mixins/RbEventsMixin.vue'

  export default {
    name: 'rbv-travel-destinations-map-suppliers-table-local-search',
    props: {
      'search-term': {
        type: String,
        required: false,
        default: ''
      },
    },
    
    mixins: [rbEventsMixin],
    methods:{
      onSearch(e){
        this.$rbEmit({
          name: 'onSearch',
          value: e.target.value
        });
      }
    }
  }
</script>

<style lang="stylus">
  @import "~vRoot/_styles/mixins"

  .travelDestinationsMapSuppliersFilterInputWrapper
    position relative
    display: inline-block
    flex: 1

    &:before
      position absolute
      top 7px
      left 10px
      font-family "Material Icons"
      color #546E7A
      font-size 24px
      content '\E8B6'


  .travelDestinationsMapSuppliersFilterInput
    box-sizing border-box
    height 36px
    line-height 36px
    width 100%
    border 1px solid #DCDEE0
    padding 0 10px 0 40px
    display: block

    background #FFFFFF
    border-radius 2px
    font-family "Noto Sans", sans-serif
    font-size 13px
    color #263238
    border-radius 20px

    &:focus
      border-color: #00b8ab

    +placeholder()
      font-family "Noto Sans", sans-serif
      font-size 13px
      line-height 36px
      color #B0BEC5

</style>
