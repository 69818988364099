<template>
  <div :class="$style.buttons">
    <save-button @save="$rbEmit"
                 @setDefaultFilters="$rbEmit"/>

    <button class="rbButton mainButton"
            @click="$rbEmit({ name: 'done'})">
      <i class="material-icons">check_circle</i>
      <span>Done</span>
    </button>
  </div>
</template>

<script>
  import rbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import SaveButton from './SaveButton.vue';

  export default {
    name: 'rbv-travel-destinations-map-filter-bar-action-buttons',

    components: {SaveButton },

    mixins: [ rbEventsMixin ]
  }
</script>

<style lang="stylus" module>
  .buttons{
    display flex
    align-items center;
    justify-content space-between
  }
</style>
