import angular from 'angular'
import angularUiRouter from '@uirouter/angularjs'
import angularLocker from 'angular-locker'

import Servers from '../shared-ng/servers'

import HttpAuthInterceptor from './http-authorization.interceptor'
import AuthInterceptor from './auth.interceptor'
import AuthSessionService from './auth-session.service'
import CurrentUserService from './current-user.service'
import AuthStorageService from './authorization-storage.service'
import UserRepository from './user.repo'
import AuthService from './auth.service'
import TutorialsService from './tutorials.service'

export default angular.module('rb.auth', [
  angularUiRouter, angularLocker,

  Servers
])
  .config(HttpAuthInterceptorConfig)
  .run(AuthInterceptor)
  .factory('HttpAuthorizationInterceptor', HttpAuthInterceptor)
  .service('Auth', AuthService)
  .service('AuthSession', AuthSessionService)
  .service('CurrentUser', CurrentUserService)
  .service('AuthorizationStorageService', AuthStorageService)
  .service('UserRepository', UserRepository)
  .service('TutorialsService', TutorialsService)
;

HttpAuthInterceptorConfig.$inject = ['$httpProvider'];
function HttpAuthInterceptorConfig($httpProvider){
  $httpProvider.interceptors.push('HttpAuthorizationInterceptor');
}
