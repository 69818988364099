/**
 * Created by DejanK on 10/8/2016.
 *
 */

'use strict';

require("./rb-textarea.scss");

let lodash = require("lodash");

module.exports = {
  template: '<textarea></textarea>',
  require: {
    ngModel: "?ngModel"
  },
  bindings: {
    maxRows: '<',
    placeholder: '@'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$timeout'];
function Ctrl($element, $timeout){
  let vm = this, maxRows = 5,
    textareaElement = angular.element($element.find('textarea')[0]);

  this.$onInit = ()=>{
    textareaElement[0].rows = 1;

    textareaElement.on('input change', ()=>{
      autoGrow();
      vm.ngModel.$setViewValue(textareaElement[0].value);
    });

    vm.ngModel.$render = ()=>{
      textareaElement[0].value = angular.isUndefined(vm.ngModel.$viewValue) ? '' : vm.ngModel.$viewValue;
    };

    autoGrow();
  };

  this.$onChanges = (changes)=>{
    changes.maxRows && vm.maxRows && updateMaxRows();
    changes.placeholder && vm.placeholder && updatePlaceholder();

    function updateMaxRows(){
      maxRows = vm.maxRows;
      autoGrow();
    }

    function updatePlaceholder(){
      textareaElement.attr('placeholder', vm.placeholder);
    }
  };

  function autoGrow(){
    $timeout(()=>{
      let textareaHtmlElement = textareaElement[0];
      while(textareaHtmlElement.scrollTop > 0){
        if(textareaHtmlElement.rows < maxRows) {
          textareaHtmlElement.rows++;
        } else {
          textareaHtmlElement.rows = maxRows;
          textareaElement.css('overflow', 'auto');
          break;
        }
      }
    })
  }
}

