import {forEach, get, isArray, merge, set} from 'lodash'
import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';

export { simpleState, abstractState, svelteState }

function simpleState(url, template, controller, authorizedRoles, options) {
  const stateObject = merge({
    url: url,
    template: template,
    controller: controller,
    controllerAs: 'VM'
  }, options);
  addRoles(stateObject, authorizedRoles);
  return stateObject;
}

function abstractState(url, authorizedRoles, template) {
  const stateObject = {
    abstract: true,
    url: url,
    template: template || '<div ui-view></div>'
    // template: '<ui-view class="flex-0 layout-column"></ui-view>'
  };
  addRoles(stateObject, authorizedRoles);
  return stateObject
}

function svelteState(url, SvelteComponentProvider){
  return {
    url: url,
    template: '<div>',
    controller: SveltePageNgController,
    resolve: { SvelteComponent:SvelteComponentProvider}
  };
}

function addRoles(stateObject, roles){
  if(roles){
    const stateRoles = get(stateObject, 'data.authorizedRoles', []);
    if(isArray(roles)){
      forEach(roles, (role)=>{addToStateRolesSet(stateRoles, role)});
    } else {
      addToStateRolesSet(stateRoles, roles);
    }

    set(stateObject, 'data.authorizedRoles', stateRoles);
  }

  function addToStateRolesSet(rolesSet, role){
    if(rolesSet.indexOf(role) === -1){
      rolesSet.push(role);
    }
  }
}


SveltePageNgController.$inject = [ '$element',  '$stateParams', 'SvelteComponent', '$scope'];
function SveltePageNgController($element, $stateParams, SvelteComponent, $scope){
  const ctrl = Object.assign(
    this,
    SvelteNgComponent(
      SvelteComponent.default ? SvelteComponent.default : SvelteComponent, // check for default is necessary because of dynamic import
      $element[0],
      () => $stateParams)
  );

  $scope.$on('$destroy', () => {
    if(ctrl.$onDestroy) {
      ctrl.$onDestroy();
    }
  });

  return ctrl;
}
