/**
 * Created by DejanK on 9/25/2017.
 */
import HTML from './send-final-agreement.html';
import './send-final-agreement.scss';
import 'root/rfp-hotel/main/rfp/pages/final-agreement/view-final-agreement.scss';
import autoSave from 'root/shared/tools/file-autosave';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { NAME as AskForRateLoadingInformationDialog }
  from 'root/rfp-hotel/main/bid/dialogs/ask-for-rate-loading-information/ask-for-rate-loading-information.ctrl';
import { NAME as askToConfirmRfpContractDialog } from 'root/rfp-hotel/main/bid/dialogs/ask-to-confirm-rfp-contract/ask-to-confirm-rfp-contract';
import SelectFinalAgreementRatesDialog from 'vRoot/rfp-hotel/final-agreement/SelectFinalAgreementRatesDialog.vue';
import {Dialog as VueDialog, SvelteDialog} from 'root/v-app/rbServices';
import saveResponseService from 'root/rfp-hotel/main/bid/pages/response/save-response.service'
import EditFinalAgreementRatesDialog from 'vRoot/rfp-hotel/final-agreement/EditFinalAgreementRatesDialog.vue'
import EditRateAccessCodeDialog from './EditRateAccessCodeDialog.svelte';

export { HTML, BidFinalAgreementSendController as Controller };

BidFinalAgreementSendController.$inject = ['RfpHotelBidRepository', '$state', 'NotificationUtils', 'rbDialog', '$q', 'MainAPI'];
function BidFinalAgreementSendController(rfpHotelBidRepository, $state, notificationUtils, dialog, $q, mainAPI) {
  const vm = this;

  vm.exportAsPdf = exportAsPdf;
  vm.exportAsExcel = exportAsExcel;
  vm.canSend = canSend;
  vm.canResend = canResend;
  vm.canDownload = canDownload;
  vm.canSelectRates = canSelectRates;
  vm.selectRates = selectRates;
  vm.canUpdateRates = canUpdateRates;
  vm.updateRates = updateRates;
  vm.canEditLetter = canEdit;
  vm.send = checkAndSend;
  vm.resend = resend;
  vm.addAttachment = addAttachment;
  vm.removeAttachment = removeAttachment;
  vm.editRateAccessCode = editRateAccessCode;

  $onInit();

  function $onInit() {
    vm.bidId = $state.params.bidId;
    notificationUtils.onLoad(() => rfpHotelBidRepository.getBidPreview(vm.bidId), vm, 'bid')
      .then(() => {
        vm.showSideMenu = get(vm.bid, 'data.specifications.subType') !== 'RATE_QUICK';
        vm.rfpName = get(vm.bid.data, 'specifications.name', '');
        vm.rfpId = vm.bid.data.rfpId;
        vm.supplierName = get(vm.bid.data, 'supplier.company.name', '');
      })
      .catch(noop);
  }

  function exportAsPdf() {
    notificationUtils
      .onLoadWithNotification(() => mainAPI.exportFinalAgreementAsPdf(vm.bidId))
      .then(response => {
        autoSave(response, 'application/pdf', `${vm.bid.data.specifications.name} Final Agreement.pdf`);
      })
      .catch(noop);
  }

  function exportAsExcel() {
    notificationUtils
      .onLoadWithNotification(
        () => mainAPI.hotelRfpExportFinalAgreementsToExcel([vm.bidId], true)
      )
      .catch(noop);
  }

  function canSend() {
    return isBidStateIn(['RESPONDED', 'NEGOTIATION_FINALIZED', 'BUYER_DRAFT', 'FINAL_AGREEMENT_PENDING', 'NO_LONGER_INTERESTED']);
  }

  function canResend() {
    return isBidStateIn(['FINAL_AGREEMENT']);
  }

  function canSelectRates() {
    return isBidStateIn(['RESPONDED', 'NEGOTIATION_FINALIZED', 'BUYER_DRAFT', 'FINAL_AGREEMENT_PENDING']);
  }

  function canUpdateRates() {
    return isBidStateIn(['FINAL_AGREEMENT']);
  }

  function selectRates() {
    return VueDialog.show(SelectFinalAgreementRatesDialog, {bidId: vm.bidId})
      .then(() => $state.reload())
  }

  function updateRates() {
    VueDialog.show(EditFinalAgreementRatesDialog, {bidId: vm.bidId})
      .then(r => {
        if (r) resend().then(() => {
          $state.reload()
        });
      });
  }

  function canEdit() {
    return isBidStateIn(['RESPONDED', 'NEGOTIATION_FINALIZED', 'BUYER_DRAFT', 'FINAL_AGREEMENT_PENDING']);
  }

  function canDownload() {
    return isBidStateIn(['FINAL_AGREEMENT', 'RESPONDED', 'NEGOTIATION_FINALIZED', 'BUYER_DRAFT', 'FINAL_AGREEMENT_PENDING']);
  }

  function isBidStateIn(states) {
    const bidState = get(vm.bid, 'data.state.id');
    return states.indexOf(bidState) !== -1;
  }

  async function checkAndSend() {
    let sendToContact = true;
    if (vm.bid.data.selfFilled) {
      if (vm.bid.data.questionnaire.response.answers.RFP_CONTRACT != 'Y') {
        await dialog.show(askToConfirmRfpContractDialog, {locals: {SupplierName: vm.supplierName}});
      }

      if (vm.bid.data.supplier.contact) {
        try {
          await saveResponseService.askToSendToContact(vm.bid.data.supplier.contact)
        } catch (e) {
          sendToContact = false;
        }
      }
    }

    send(sendToContact);
  }

  function send(sendToContact) {
    notificationUtils.onSave(() => mainAPI.sendFinalAgreement(vm.bidId), {
      customErrorHandlers: {
        '409'(errorResponse) {
          const message = errorResponse.data.message;
          switch (message) {
            case 'NO_ACCEPTED_RATES':
              return VueDialog.show(SelectFinalAgreementRatesDialog, {bidId: vm.bidId})
                .then(() => send(sendToContact), noop);
            case 'NO_RATE_LOADING_INFORMATION':
              return dialog
                .show(AskForRateLoadingInformationDialog, {locals: {EntityId: get(vm.bid, 'data.buyer.company.entityId')}})
                .then(() => send(sendToContact), noop);
            default:
              return $q.reject(errorResponse);
          }
        }
      }
    })
      .then(response => {
        if (response) {
          vm.bid = response;

          notificationUtils.showSuccessDialog({
            dialogData: {
              heading: 'Finally Agreement Sent',
              title: 'Sent Successfully',
              message: 'Your final agreement was sent successfully',
            }
          })
        }
      })
      .catch(noop);
  }

  function resend() {
    return notificationUtils.onSave(() => mainAPI.resendFinalAgreements([vm.bidId]))
      .then(() => {
        notificationUtils.showSuccessDialog({
          dialogData: {
            heading: 'Finally Agreement Resent',
            title: 'Resent Successfully',
            message: 'Your final agreement was resent successfully',
          }
        })
      })
  }

  function addAttachment(id) {
    return mainAPI
      .addHotelRfpBidFinalAgreementAttachment(vm.rfpId, vm.bidId, {id})
      .then(res => updateAttachments(res.data));
  }

  function removeAttachment(id) {
    notificationUtils.onSave(() => mainAPI.removeHotelRfpBidFinalAgreementAttachment(vm.rfpId, vm.bidId, id))
      .then(res => updateAttachments(res.data))
      .catch(noop);
  }

  function updateAttachments(attachments) {
    vm.bid = {data: {...vm.bid.data, finalAgreement: {...vm.bid.data.finalAgreement, attachments}}};
  }

  function editRateAccessCode() {
    SvelteDialog.show(EditRateAccessCodeDialog, {bidId: vm.bidId})
      .then(() => $state.reload())
      .catch(noop);
  }
}
