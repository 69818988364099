<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import {fileToJson} from 'root/shared/tools/xls.service';
  import createNegotiation from './UploadNegotiationsService';
  import {mainAPI} from 'root/angular-injector-provider';

  export let cancel, hide, bids = [], side, reUpload, actionResultHandler;

  const
    CONFIGURATIONS = {
      'SUPPLIER_UPLOAD': {
        title: 'Upload Negotiation Responses',
        content: `
      <div>Upload GBTA-compatible Excel or CSV file to send Hotel RFP Negotiation responses. The file must have GBTA questions IDs as headers. A message to the buyer can be added by adding a custom column marked "MESSAGE" as a header.</div>
      <div>GBTA data in the upload file are matched with bids by "SABRE_PROPCODE", "INTERNALHOTELCODE" or "PROPCODE" (a ReadyBid property code, available from GBTA or Bid Manager export) headers.</div>
      <div>Negotiations are available only on questions available for negotiation on the standard ReadyBid negotiation panel. The upload will not change seasons, room types, or occupancies that were not available in the hotel response.</div>`,
        matches: ['SABRE_PROPCODE', 'INTERNALHOTELCODE', 'PROPCODE'],
        action: (bidId, negotiation) =>  mainAPI().hotelRfpNegotiationCreate(bidId, negotiation)
      },
      'SUPPLIER_RE_UPLOAD': {
        title: 'Re-upload Negotiation Responses',
        content: `
      <div>Upload GBTA-compatible Excel or CSV file to resend Hotel RFP Negotiation responses. The file must have GBTA questions IDs as headers. A message to the buyer can be added by adding a custom column marked "MESSAGE" as a header.</div>
      <div>GBTA data in the upload file are matched with bids by "SABRE_PROPCODE", "INTERNALHOTELCODE" or "PROPCODE" (a ReadyBid property code, available from GBTA or Bid Manager export) headers.</div>
      <div>Negotiations are available only on questions available for negotiation on the standard ReadyBid negotiation panel. The upload will not change seasons, room types, or occupancies that were not available in the hotel response.</div>`,
        matches: ['SABRE_PROPCODE', 'INTERNALHOTELCODE', 'PROPCODE'],
        action: (bidId, negotiation) =>  mainAPI().hotelRfpNegotiationUpdateLast(bidId, negotiation)
      },
      'BUYER_UPLOAD': {
        title: 'Upload Negotiation Requests',
        content: `
      <div>Upload GBTA-compatible Excel or CSV file to send Hotel RFP Negotiation requests. The file must have GBTA questions IDs as headers. A message to a hotel can be added by adding a custom column marked "MESSAGE" as a header.</div>
      <div>GBTA data in the upload file are matched with bids by "PROPCODE" (a ReadyBid property code, available from GBTA or Bid Manager export), "SABRE_PROPCODE", or "INTERNALHOTELCODE" headers.</div>
      <div>Negotiations are available only on questions available for negotiation on the standard ReadyBid negotiation panel. The upload will not change seasons, room types, or occupancies that were not available in the hotel response.</div>`,
        matches: ['PROPCODE', 'SABRE_PROPCODE', 'INTERNALHOTELCODE'],
        action: (bidId, negotiation) =>  mainAPI().hotelRfpNegotiationCreate(bidId, negotiation)
      },
      'BUYER_RE_UPLOAD': {
        title: 'Re-upload Negotiation Requests',
        content: `
      <div>Upload GBTA-compatible Excel or CSV file to resend Hotel RFP Negotiation requests. The file must have GBTA questions IDs as headers. A message to a hotel can be added by adding a custom column marked "MESSAGE" as a header.</div>
      <div>GBTA data in the upload file are matched with bids by "PROPCODE" (a ReadyBid property code, available from GBTA or Bid Manager export), "SABRE_PROPCODE", or "INTERNALHOTELCODE" headers.</div>
      <div>Negotiations are available only on questions available for negotiation on the standard ReadyBid negotiation panel. The upload will not change seasons, room types, or occupancies that were not available in the hotel response.</div>`,
        matches: ['PROPCODE', 'SABRE_PROPCODE', 'INTERNALHOTELCODE'],
        action: (bidId, negotiation) =>  mainAPI().hotelRfpNegotiationUpdateLast(bidId, negotiation)
      },
    }

  let files, negotiatedBids, finished, uploadError, config;

  $: onFileSelected(files);
  $: negotiatedBids = bids.map(bid => ({bid, response: undefined, state: 'WORKING', status: 'Matching...'}));
  $: config = CONFIGURATIONS[`${side}_${reUpload ? 'RE_' : ''}UPLOAD`] || {}

  function onFileSelected(){
    if(files && files.length) {
      finished = false;
      fileToJson(files[0])
        .then(rows => {
          for (let i = 0, l = negotiatedBids.length; i < l; i++) {
            const
              nb = negotiatedBids[i],
              negotiation = createNegotiation(nb.bid, rows, config.matches);

            negotiatedBids[i] = {
              ...nb,
              negotiation,
              status: negotiation ? 'Waiting to upload...' : 'ERROR - Not matched',
              state: negotiation ? nb.state : 'ERROR',
            };
          }
        })

        .then(async () => {
          for (let i = 0, l = negotiatedBids.length; i < l; i++) {
            const nb = negotiatedBids[i];
            if (nb.negotiation) {
              try {
                negotiatedBids[i] = {...nb, status: 'Uploading'};
                // eslint-disable-next-line no-await-in-loop
                const actionReport = await config.action(nb.bid._id, nb.negotiation);
                actionResultHandler.handleActionResult(actionReport, [nb.bid]);
                negotiatedBids[i] = {
                  ...nb,
                  status: 'Uploaded!',
                  state: 'DONE'
                };
              } catch (err) {
                const msg = getErrorMessage(err);
                negotiatedBids[i] = {
                  ...nb,
                  status: `ERROR - ${msg}`,
                  state: 'ERROR'
                };
              }
            }
          }
        })
        .then(() => {
          finished = true;
        })
        .catch(err => {
          uploadError = err;
          finished = true;
        })
    }

    function getErrorMessage(err) {
      try {
        return err.data.message;
      } catch (e) {
        return 'Upload failed!';
      }
    }
  }

</script>

<ClosableModal close="{files ? false : cancel}" width="700px">
  <div slot="heading">
    <div class="Header">{config.title}</div>
  </div>

  {#if !files}
    <div class="ContentHtml">
      {@html config.content}
    </div>

    <div class="Actions">
      <div>
        <label for="fileUploader" class="aBtn atLight asLarge asMain">
          <i class="material-icons">file_upload</i><span>Upload File</span>
        </label>

        <input type="file"
               id="fileUploader"
               accept=".csv, .txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               bind:files
               class="hidden"
        />
      </div>
      <button type="button" class="aBtn atLight asLarge" on:click={cancel}>Cancel</button>
    </div>

  {:else if !uploadError}
    <div class="Content">
      {#each negotiatedBids as negotiatedBid}
        <div class="Row">
          <div class="ellipsis">
            <b>{negotiatedBid.bid.supplier.company.name}</b>, <small>{negotiatedBid.bid.supplier.company.location.fullAddress}</small>
          </div>
          <div class="Result"
            class:Error={negotiatedBid.state === 'ERROR'}
            class:Done={negotiatedBid.state === 'DONE'}
          >{negotiatedBid.status}</div>
        </div>
      {/each}
    </div>

    <div class="Actions">
        <button type="button" class="aBtn atLight asLarge asMain" on:click={hide} disabled="{!finished}">Close</button>
    </div>

  {:else}
    <div class="Content">
      There was an unexpected error - Upload Failed;
      <br/>
      {uploadError}
    </div>

    <div class="Actions">
      <button type="button" class="aBtn atLight asLarge" on:click={cancel}>Close</button>
    </div>
  {/if}
</ClosableModal>

<style lang="stylus">.Header {
  padding: 9px 13px 0;
  color: #37474f;
  font-size: 16px;
}
.Content,
.ContentHtml {
  padding: 0 13px;
  color: #546e7a;
  font-size: 13px;
  margin: 10px 0 20px;
  max-height: 400px;
  overflow: auto;
}
.ContentHtml :global(div) {
  margin: 20px 0;
}
.Actions {
  padding: 0 18px 21px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.Row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 5px 0;
}
.Result {
  flex: 0 0 220px;
  text-align: right;
}
.Error {
  color: #d75539;
}
.Done {
  color: #00a99d;
}
</style>
