import { checkAndExecute } from 'root/api/helpers';

export default function UserSecurityAPI(api) {

  Object.assign(api, {
    userSecurity: {
      sendEmailMfaEmail: credentials => checkAndExecute(
        () => credentials,
        () => api.server.post('sign-in/mfa/email', credentials),
      ),
    },
  });
}
