/**
 * Created by DejanK on 10/08/2019.
 *
 */
export default DynamicRate;

function DynamicRate(rateData, negotiation, negotiations){
  this.id = 'dyn';
  this.negotiation = negotiation;
  this.negotiations = negotiations;

  this.prefix = 'dyn';
  this.season = '1';
  this.roomType = '1';

  Object.assign(this, rateData, {
    name: 'Dynamic',
    abbr: 'DYN'
  });
}

DynamicRate.prototype.getValue = function(){
  return { value: this.value, valid: this.valid, included: false, type: this.type };
};

DynamicRate.prototype.getFormattedValue = function(){
  return this.negotiations.percentageFormatter.format(this.type === 'UNAVAILABLE' || !this.valid ? null : this.value);
};
