import Vue from 'vue';
import FilterBar from './BidManagerExport.vue';
import {default as bidManagerStore} from "rfp/bid-manager/main/stores/bid-manager";

export default {
  template: '<div></div>',
  controller: Ctrl
}

Ctrl.$inject = ['$element', '$scope']
function Ctrl($element, $scope){
  const
    vm = this,
    reactiveData = { view: {} },
    bmsUnsubscribe = bidManagerStore.subscribe($bms => {
      vm.bidManager = $bms;
      $scope.$applyAsync();
    });

  let vueComponent;

  vm.$onInit = () => { initVueComponent() }
  vm.$onDestroy = () => {
    if(vueComponent) vueComponent.$destroy();
    bmsUnsubscribe();
  }

  function updateView(){
    const bidManager = vm.bidManager,
    selectedBids = bidManager.selectedBids;

    Vue.set(reactiveData, 'view', {
      bids: selectedBids && selectedBids.length ? selectedBids.slice() : bidManager.bids.filter(b => !b.header),
      columns: getExportColumns(bidManager.view)
    })

    function getExportColumns({columns, side}){
      return [
        bidId(),
        ...propCodes(),
        ...columns.reduce((acc, c) => {
          if(!c.export) return acc;
          acc.push(...c.export);
          return acc;
        }, []),
        ...(side === 'SUPPLIER' ? rateCapsColumns() : []),
        ...propResponses(),
      ];
    }

    function bidId(){
      return {
        label: 'Bid ID',
        type: 'String',
        field: `_id`
      };
    }

    function propCodes() {
      const key = 'questionnaire.response.answers';
      return [
        {
          label: 'Prop Code',
          type: 'String',
          field: `${key}.PROPCODE`
        },
        {
          label: 'Internal Hotel Code',
          type: 'String',
          field: `${key}.INTERNALHOTELCODE`
        },
      ]
    }

    function propResponses(){
      const key = 'questionnaire.response.answers';
      return [
        {
          label: 'Address',
          type: 'String',
          field: `${key}.PROPADD1`
        },
        {
          label: 'Zip/Postal Code',
          type: 'String',
          field: `${key}.PROPPOSTCODE`
        },
        {
          label: 'City',
          type: 'String',
          field: `${key}.PROPCITY`
        },
        {
          label: 'State',
          type: 'String',
          field: `${key}.PROPSTATEPROV`
        },
        {
          label: 'Region',
          type: 'String',
          field: `${key}.PROPREGION`
        },
        {
          label: 'Country',
          type: 'String',
          field: `${key}.PROPCOUNTRY`
        },
        {
          label: 'Sabre Chain Code',
          type: 'String',
          field: `${key}.SABRE_CHAINCODE`
        },
        {
          label: 'Sabre Property Code',
          type: 'String',
          field: `${key}.SABRE_PROPCODE`
        },
        {
          label: 'Worldspan Chain Code',
          type: 'String',
          field: `${key}.WORLDSPAN_CHAINCODE`
        },
        {
          label: 'Worldspan Property Code',
          type: 'String',
          field: `${key}.WRLDSPAN_PROPCODE`
        },
        {
          label: 'Apollo Chain Code',
          type: 'String',
          field: `${key}.APOLLO_CHAINCODE`
        },
        {
          label: 'Apollo Property Code',
          type: 'String',
          field: `${key}.APOLLO_PROPCODE`
        },
        {
          label: 'Amadeus Chain Code',
          type: 'String',
          field: `${key}.AMADEUS_CHAINCODE`
        },
        {
          label: 'Amadeus Property Code',
          type: 'String',
          field: `${key}.AMADEUS_PROPCODE`
        },
      ];
    }

    function rateCapsColumns() {
      let columns = [];
      for(let i = 0; i < 5; i++) {
        columns = [
          ...columns,
          {
            label: `City Caps Season ${i+1} Start Date`,
            type: 'Date',
            field: `subject.caps.startDate`,
            key: `subject.caps.${i}.startDate`,
          },
          {
            label: `City Caps Season ${i+1} End Date`,
            type: 'Date',
            key: `subject.caps.${i}.endDate`,
            field: `subject.caps.endDate`,
          },
          {
            label: `City Caps Season ${i+1} Rate`,
            type: 'Double',
            field: `subject.caps.rate`,
            key: `subject.caps.${i}.rate`,
          }
        ];
      }
      return columns;
    }
  }

  function initVueComponent(){
    if(!vueComponent) initVue();

    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { reactiveData: reactiveData },
        render: function (createElement) {
          return createElement(FilterBar, {
            props: {
              view: reactiveData.view,
              rfpId: vm.bidManager.view && vm.bidManager.view.rfpId,
              side: vm.bidManager.view && vm.bidManager.view.side,
            },
            on: {
              refreshView () { updateView() },
            },
          })
        }
      })
    }
  }
}
