<template>
  <select-rates :bidId="bidId" @cancel="cancel" @hide="hide" @submit="hide" />
</template>

<script>
  import selectRates from './SelectFinalAgreementRates.vue'

  export default {
    name: 'rbv-hotel-rfp-select-rates-dialog',
    components: { selectRates },
    props: { 'bidId': {
        required: true,
        type: String
      } },
    methods: {
      cancel ( reason ) { this.$emit('cancel', reason) },
      hide ( reason ) { this.$emit('hide', reason) }
    }
  }
</script>
