export default (server) => { return new AuthRepository(server) }

function AuthRepository(server){ this.server = server }

AuthRepository.prototype = {
  constructor: AuthRepository,

  signIn (credentials) { return this.server.post('sign-in', credentials) },
  resetPassword (emailAddress) { return this.server.post('reset-password', {emailAddress: emailAddress}) },
  verifyResetPasswordToken (token) { return this.server.post('reset-password', {token: token})},
  setPassword (token, password) { return this.server.post('reset-password', {token: token, password: password})}
}
