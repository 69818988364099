import get from 'lodash/get';
import noop from 'lodash/noop';
import HTML from './view-cover-letter.html'
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';

export { HTML, BidCoverLetterViewController as Controller }

const CHAIN = 'CHAIN', DIRECT = 'DIRECT';

BidCoverLetterViewController.$inject = ['RfpHotelBidRepository', '$state'];
function BidCoverLetterViewController(RfpHotelBidRepository, $state){
  const vm = this;
  vm.addAttachment = addAttachment;
  vm.removeAttachment = removeAttachment;

  $onInit();

  function $onInit(){
    vm.bidId = $state.params.bidId;

    notificationUtils().onLoad(() => RfpHotelBidRepository.getBidPreview(vm.bidId), vm, 'bid', )
      .then(() => {
        const bidData = vm.bid.data;
        vm.bidType = get(bidData, 'type', DIRECT);

        vm.isChainBid = vm.bidType === CHAIN;
        vm.rfpName = get(bidData, 'specifications.name', '');
        vm.rfpId = bidData.rfpId;
        vm.subHeaderTitle = `${vm.isChainBid ? 'Chain' : 'Direct to Property'} Cover Letter`;
        vm.supplierName = get(bidData, 'supplier.company.name', '');
      })
      .catch(noop)
  }

  function addAttachment ( id ) {
    return mainAPI()
      .addHotelRfpBidCoverLetterAttachment(vm.rfpId, vm.bidId, { id })
      .then(res => updateAttachments(res.data));
  }

  function removeAttachment ( id ) {
    notificationUtils().onSave(() => mainAPI().removeHotelRfpBidCoverLetterAttachment(vm.rfpId, vm.bidId, id))
      .then(res => updateAttachments(res.data))
      .catch(noop);
  }

  function updateAttachments(attachments) {
    vm.bid = { data: { ...vm.bid.data, coverLetter: { ...vm.bid.data.coverLetter, attachments }}};
  }
}
