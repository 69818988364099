<template>
  <modal-form :class="$style.dialog" @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <span slot="header">Configure {{ template.name }} Table</span>
    <div slot="body" class="vModalDefaultBody">

      <rb-input>
        <span slot="label">How many Room Types will you allow?</span>
        <rb-select class="simple" v-model="data.roomType">
          <rb-option :value="[ 1 ]">One Room Type</rb-option>
          <rb-option :value="[ 1,2 ]">Two Room Types</rb-option>
          <rb-option :value="[ 1,2,3 ]">Three Room Types</rb-option>
        </rb-select>
      </rb-input>

      <rb-input :error="$showError('rates')">
        <span slot="label">Rates</span>
        <rb-checkboxes :field="fields['rates']"
                       name="rates"
                       v-model="data.rate"
                       v-validate="'required'"
                       data-vv-as="Rates">
          <rb-checkbox :value="'LRA'">LRA</rb-checkbox>
          <rb-checkbox :value="'NLRA'">NLRA</rb-checkbox>
          <rb-checkbox :value="'GOVT'">GOVT</rb-checkbox>
        </rb-checkboxes>
      </rb-input>

      <rb-input>
        <span slot="label">Occupancy</span>
        <rb-select class="simple" v-model="data.occupancy">
          <rb-option :value="[ 'SGL' ]">Single Only</rb-option>
          <rb-option :value="[ 'DBL' ]">Double Only</rb-option>
          <rb-option :value="[ 'SGL','DBL' ]">Both</rb-option>
        </rb-select>
      </rb-input>

      <div :class="$style.heading">Rate Rules</div>

      <p :class="$style.p">Do not allow supplier to provide rate that exceeds percentage over last year’s rate</p>

      <rb-input :forId="'rateRules'"
                :error="$showError('rateRules')">
        <span slot="label">Percent</span>
        <span slot="hint">[0 - disabled]</span>
        <input id="rateRules" type="text" name="rateRules" class="rbInput"
               v-model="data.rateRules.amount"
               v-validate="'required|decimal:2|max_value:1000|min_value:0'"
               data-vv-as="Rate Rule"
        />
      </rb-input>

    </div>
  </modal-form>
</template>

<script>
  import { cloneDeep } from 'lodash'
  import ModalForm from '../../_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbSelect from '../../_core/RbSelect.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'
  import RbCheckboxes from 'root/v-components/_core/RbCheckboxes.vue'

  export default {
    name: 'rb-questionnaire-edit-configure-rate-grid-dialog',

    mixins: [ RbFormMixin, DialogMixin ],

    components: { RbCheckboxes, RbInput, ModalForm, RbSelect },

    props: [ 'template', 'config' ],

    data () { return { data: { rateRules: { amount: 0 } } } },

    methods: {
      onSubmit () { return { data: this.data, id: 'RT' } },
      initData ( rateGridConfigData ) { this.data = cloneDeep( rateGridConfigData.data ) },
    },

    watchers: { config ( val ) { this.initData(val) } },

    created () {
      this.initData( this.config )
    }
  }
</script>

<style lang="stylus" module >
  .dialog { min-width: 400px }

  .heading {
    color: #546e7a;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
  }

  .p {
    color: #546e7a;
    font-size: 11px;
    line-height: 13px;
  }

</style>

