/**
 * Created by DejanK on 7/10/2017
 *
 * Behaves like a fixed table header
 *
 * - follows x-axis scrolling
 * - adds and masks y scroll bar on header when visible on target
 *    - uses FIXED positioning because it needs overflow:hidden to follow x-axis scrolling *
 */

'use strict';

let lodash = require("lodash");

module.exports = rbFixedOnScrollContainer;

function rbFixedOnScrollContainer(){
  return {
    restrict: 'A',
    //scope: {
    //  maskBackground: '@',
    //  rbFixedTableHeader: '@'
    //},
    controller: Ctrl,
    //controllerAs: '$ctrl',
    //bindToController: true
  }
}

Ctrl.$inject = ['$element', '$document', '$interval', '$scope', '$timeout'];
function Ctrl($element, $document, $interval, $scope, $timeout){
  init();

  function init(){
    $element.on('scroll', onScroll);
    $timeout(onScroll);
  }

  function onScroll (){
    let t = Date.now();
    $scope.$broadcast('RB_SCROLL_EVENT', {
      scrollTop: $element[0].scrollTop,
      scrollHeight:  $element[0].scrollHeight,
      scrollLeft: $element[0].scrollLeft,
      scrollWidth:  $element[0].scrollWidth,
      element: $element[0]
    });
    console.log(Date.now() - t);
  }
}
