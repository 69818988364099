/**
 * Created by DejanK on 9/3/2016.
 *
 * rbLoadingComponent
 */

'use strict';

require('./rb-loading.scss');

module.exports = {
  template: require('./rb-loading.html'),
  bindings: {
    label: '@',
    classes: '@'
  }
};
