<Navigation id="QUESTIONNAIRE_RESPONSE" {title} {subtitle} {menu} />

<script>
  import Navigation from 'root/components/organisms/SideNavigation/SideNavigation.svelte';
  import { $state as stateProvider } from 'root/angular-injector-provider.js';
  import { onDestroy, createEventDispatcher } from 'svelte';
  import { createQuestionnaireNavigation } from 'root/components/organisms/SideNavigation/MenuItemFactory.js';

  export let
    attachments = false,
    stateName = 'hotelRfp.bid.response',
    showCoverLetter = true;

  const
    COVER_LETTER_LABEL = 'Cover Letter',
    COVER_LETTER_ICON = 'description',
    COVER_LETTER_STATE = 'hotelRfp.bid.coverLetter.preview',
    title = 'RFP Response',
    subtitle = '',
    dispatch = createEventDispatcher();

  let menu;

  $: menu = createMenu(attachments);

  function createMenu(att){
    const currentState = stateProvider().current.name,
      m = [];
    if(showCoverLetter) m.push(att ? createCoverLetterMenuItemWithSubmenu(currentState) : createCoverLetterMenuItem(currentState));
    m.push(createQuestionnaireNavigation(currentState, stateName, reloadMenu, onDestroy));

    return m;
  }

  function reloadMenu(){
    menu = [...menu];
  }

  function createCoverLetterMenuItem(currentState){
    return createMenuItem(currentState, COVER_LETTER_LABEL, COVER_LETTER_ICON, COVER_LETTER_STATE);
  }

  function createCoverLetterMenuItemWithSubmenu(currentState){
    const menuItem = createCoverLetterMenuItem(currentState);
    menuItem.alwaysOpened = true;
    menuItem.submenu = [
      createMenuItem(currentState, 'Letter', COVER_LETTER_ICON, COVER_LETTER_STATE),
      createMenuItem(currentState, 'Attachments', 'attach_file', COVER_LETTER_STATE, {attachments : true}),
    ];
    return menuItem;
  }

  function createMenuItem(currentState, label, icon, stateName, stateParams = null) {
    const state = stateProvider();
    return {
      label,
      icon,
      action: () => {
        dispatch('leaving');
        state.go(stateName, stateParams);
      },
      active: stateName === currentState
    };
  }
</script>
