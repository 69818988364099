/**
 * Created by DejanK on 4/5/2017.
 */
import HTML from './view-questionnaire.html';
import get from 'lodash/get';
import noop from 'lodash/noop';

export {HTML, BidQuestionnaireViewController as Controller};

BidQuestionnaireViewController.$inject = ['ControllerUtils', 'RfpHotelBidRepository'];
function BidQuestionnaireViewController(utils, RfpHotelBidRepository){
  const vm = this;

  $onInit();

  function $onInit(){
    vm.bidId = utils.getParam('bidId');
    utils.loadTo(vm, 'bid', ()=> RfpHotelBidRepository.getBidPreview(vm.bidId))
      .then(() => {
        const bidData = vm.bid.data;
        vm.rfpName = get(bidData, 'specifications.name', '');
        vm.subHeaderTitle = 'Questionnaire';
        vm.supplierName = get(bidData, 'supplier.company.name', '');
      })
      .catch(noop)
  }
}
