/**
 * Created by DejanK on 9/18/2016.
 */
import './forgotten-password.scss'
import { noop } from 'lodash'
import { CHECKING } from "../../../ui-ng/notifications/dialogs/working/working.ctrl";
import HTML from './enter-email-address.html'

export { HTML, ForgottenPasswordVerifyEmailAddressCtrl as Controller}

ForgottenPasswordVerifyEmailAddressCtrl.$inject = ['NotificationUtils', 'PublicAuth', '$state'];
function ForgottenPasswordVerifyEmailAddressCtrl(notificationUtils, publicAuth, $state){

  onInit();

  function onInit(){
    notificationUtils.onSave(() => { return publicAuth.verifyResetPasswordToken($state.params.token) }, {
      workingDialogLabel: CHECKING,
      customErrorHandlers: {
        '404' () { notificationUtils.showError('forgotten-password-email-failed') },
        '409' () { notificationUtils.showError('forgotten-password-sending-sms-failed') }
      }
    })
      .then(toSetPassword, noop);

    function toSetPassword(passwordToken){
      $state.go('forgottenPassword.setPassword', passwordToken.data)
    }
  }
}
