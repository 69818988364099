import { checkAndExecute } from 'root/api/helpers';

export default function(api) {
  Object.assign(api, {
    chainPortal: {
      updateBasicInfo,
      updateAmenities,
      getContacts,
      updateAnswers,
      rebrandHotel,
      getAnswers,
      updateGdsCodes,
      fetchRfpActivity,
      listBrands,
      getAccount,
      addRequestedHotel,
      updateRequestedHotel,
      addNotificationsToRequestedHotel,
      deleteRequestedHotel,
      removeFromPortfolio,
      createAddHotelRequest,
    }
  });

  function getAccount(entityId) {
    return checkAndExecute(
      () => entityId,
      () => api.server.get(`chain-portal/hotel/${entityId}/get-account`)
    )
  }

  function getContacts(id) {
    return checkAndExecute(
      () => !!id,
      () => api.server.get(`chain-portal/hotel/${id}/contacts`)
    );
  }

  function updateBasicInfo(id, request) {
    return checkAndExecute(
      () => id && request,
      () => api.server.put(`chain-portal/hotel/${id}/basic-info`, request)
    );
  }

  function updateAmenities(id, request) {
    return checkAndExecute(
      () => id && request,
      () => api.server.put(`chain-portal/hotel/${id}/amenities`, request)
    );
  }

  function updateAnswers(id, request) {
    return checkAndExecute(
      () => id && request,
      () => api.server.put(`chain-portal/hotel/${id}/answers`, request)
    );
  }

  function rebrandHotel(id, request) {
    return checkAndExecute(
      () => id && request,
      () => api.server.put(`chain-portal/hotel/${id}/update-brand`, request)
    );
  }

  function getAnswers(id) {
    return checkAndExecute(
      () => id,
      () => api.server.get(`chain-portal/hotel/${id}/saved-responses`)
    );
  }

  function updateGdsCodes(id, codes) {
    return checkAndExecute(
      () => id && codes,
      () => api.server.put(`chain-portal/hotel/${id}/gds-codes`, codes)
    );
  }

  function fetchRfpActivity(entityId) {
    return checkAndExecute(
      () => entityId,
      () => api.server.get(`chain-portal/hotel/${entityId}/rfp-activity`)
    );
  }

  function listBrands() {
    return api.server.get(`chain-portal/current-chain-brands`);
  }

  function addRequestedHotel(data) {
    return checkAndExecute(
      () => !!data,
      () => api.server.sendFile('chain-portal/add-hotel/', data)
    )
  }

  function createAddHotelRequest(data) {
    return checkAndExecute(
      () => data,
      () => api.server.put('chain-portal/hotel-requests/add', data)
    )
  }

  function updateRequestedHotel(data) {
    return checkAndExecute(
      () => !!data,
      () => api.server.sendFile('chain-portal/add-hotel/update', data)
    )
  }

  function addNotificationsToRequestedHotel(hotelId, data) {
    return checkAndExecute(
      () => hotelId && data,
      () => api.server.put(`chain-portal/add-hotel/${hotelId}/notifications`, data)
    )
  }

  function deleteRequestedHotel(ids) {
    return checkAndExecute(
      () => ids && ids.length > 0,
      () => api.server.post(`chain-portal/add-hotel/delete`, ids)
    )
  }

  function removeFromPortfolio(ids) {
    return checkAndExecute(
      () => ids && ids.length > 0,
      () => api.server.post(`chain-portal/hotels/remove`, {ids})
    )
  }
}
