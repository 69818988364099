<template>
  <div class="rbInputFileContainer box" :class="{'has-advanced-upload': hasAdvancedUpload, 'is-dragging': dragging}">
    <input type="file" @change="choseFiles" :name="name" :accept="accept">
    <div class="box__input"
         v-if="!uploaded"
         @drag.stop.prevent="noop"
         @dragstart.stop.prevent="noop"
         @dragover.stop.prevent="dragBegin"
         @dragenter.stop.prevent="dragBegin"
         @dragleave.stop.prevent="dragStop"
         @dragend.stop.prevent="dragStop"
         @drop.stop.prevent="drop"
    >
      <label v-if="!(dragging || uploading || uploaded)">
        <div class="box__dragndrop">
          <i class="material-icons">cloud_upload</i>
          <div>Drag and Drop Image File Here to Upload</div>
          <div class="box__error">{{loadError}}<br>{{vvError?vvError:''}}<span></span></div>
        </div>
        <button @click="chooseFiles" class="manual-upload" type="button">{{hasAdvancedUpload?"Or":''}} Select File Manually</button>
      </label>
      <div v-if="dragging || uploading" :class="{box__dragging: true, dragging, uploading}">
        <i class="material-icons">add_a_photo</i>
        <div v-if="dragging">Drop File Here</div>
        <div v-if="uploading">
          <rb-loading rb-class="medium"/>
          Uploading File {{loadPercentage}}%
        </div>
      </div>
    </div>
    <rb-progress :max="100" v-if="uploading" :value="loadPercentage"/>
    <rb-croppie :url="fileUrl" @change="croppieChange" :options="croppieOptions" v-if="uploaded && accept=='image/*'" />
  </div>
</template>

<script>
  import rbProgress from 'vRoot/_core/RbProgressBar.vue';
  import rbCroppie from 'vRoot/_core/RbCroppie.vue';
  import rbLoading from 'vRoot/_core/RbvLoading.vue';

  import rbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue';

  export default {
    name: 'rbv-input-file',
    props: {
      name: {
        type: String,
        required: false,
        default: 'file'
      },
      accept: {
        type: String,
        required: false,
        default: 'image/*'
      },
      value: {
        type: File,
      },
      'vv-error': {
        required: false,
        default: ''
      },
      reset: {
        required: false,
        type: Boolean,
        default: false
      },
      croppieOptions: {
        type: Object,
        default: () =>({})
      }
    },
    components: {rbProgress, rbCroppie, rbLoading},
    mixins: [rbEventsMixin],
    data() {
      return {
        hasAdvancedUpload: this.isAdvancedUpload(),
        dragging: false,
        uploaded: false,
        uploading: false,
        file: false,
        loadPercentage: 0,
        loadError: '',
        fileUrl: ''
      }
    },
    watch:{
      reset(){
        this.resetState();
      }
    },
    methods: {
      isAdvancedUpload(){
        var div = document.createElement('div');
        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
      },
      resetState(){
        this.dragging = this.uploading = this.uploaded = this.file = false;
        this.loadPercentage = 0;
        this.loadError = this.fileUrl = '';
        this.$emit( 'input', null);
      },
      dragBegin(e){
        this.dragging = true;
        e.dataTransfer.dropEffect = 'copy'
      },
      dragStop(){
        this.dragging = false;
      },
      noop(){},
      drop(e){
        this.dragging = false;
        this.loadFile(e.dataTransfer.files[0]);
        this.$el.querySelector('input').files = e.dataTransfer.files;
      },
      chooseFiles(){
        this.$el.querySelector('input').click();
      },
      choseFiles(e){
        this.loadFile(e.target.files[0]);
      },
      loadFile(file){
        if (!file.type.match(this.accept)) {
          this.loadError = "Selected File Format is Unsupported";
          return;
        }
        this.$emit( 'input', file);

        var reader = new FileReader();
        this.file = file;
        // Closure to capture the file information.
        reader.onload = (e) => {
          this.uploaded = true;
          this.uploading = false;
          this.fileUrl = e.target.result;
        }

        reader.onloadstart = () => {
          this.uploading = true;
        }

        reader.onerror = function (evt) {
          switch(evt.target.error.code) {
            case evt.target.error.NOT_FOUND_ERR:
              this.loadError = 'File Not Found!';
              break;
            case evt.target.error.NOT_READABLE_ERR:
              this.loadError = 'File is not readable';
              break;
            case evt.target.error.ABORT_ERR:
              this.loadError = 'File upload cancelled';
              break;
            default:
              this.loadError = 'An error occurred reading this file.';
          }
        }

        reader.onprogress = (evt) => {
          // evt is an ProgressEvent.
          if (evt.lengthComputable) {
            this.loadPercentage = Math.round((evt.loaded / evt.total) * 100);
          }
        }

        // Read in the image file as a data URL.
        reader.readAsDataURL(this.file);
      },
      croppieChange(e){
        this.$emit( 'input', new File([e.blob], this.file.name) );
      }
    }
  }
</script>

<style lang="stylus">
  .rbInputFileContainer.box{
    text-align center;

    input[type=file]{
      display none;
    }

    .box__dragndrop {
      display: none;
    }

    .box__error{
      font-size 12px;
      color: red;
    }

    .box__input{
      padding: 65px 0 65px 0;
      color: #546e7a;
      font-size: 12px;
      
      .manual-upload{
        height 27px;
        font-size 12px;
        line-height 0;
      }
    }

    &.has-advanced-upload {
      background-color: white;
      &.is-dragging{
        outline: 2px dashed grey;
        outline-offset 0px;
      }

      .box__dragndrop {
        display: block;
        padding-bottom: 15px;
        i.material-icons{
          color: #b0bec5;
          font-size: 100pt;
        }
      }

      .box__dragging {
        display: block;
        padding-bottom: 15px;
        i.material-icons{
          color: grey;
          font-size: 80pt;
        }

        &.dragging{
          padding: 40px 0 40px 0;
        }
      }
    }
  }
</style>
