import {mainAPI} from 'root/angular-injector-provider';

const cache = {};

export default async function isEmailAddressAvailable(emailAddress = '') {
  const ea = emailAddress.trim().toLowerCase();

  if(ea in cache) {
    return Promise.resolve(cache[ea])
  } else {
    try {
      const isAvailable = await mainAPI().emailAddress.isAvailable(ea);
      cache[ea] = isAvailable;
      return Promise.resolve(isAvailable);
    } catch(err){
      return Promise.resolve(true); // server registration must catch if email is already taken
    }
  }
}
