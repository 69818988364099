<template>
  <div>
    <questionnaire-view-module
      v-for="module in modelCells" :key="module.id"
      :config = "questionnaire.config"
      :globals = "questionnaire.globals"
      :model="module"
      :response="questionnaire.response"
      :template="getTemplate(module.id)"
    />
  </div>
</template>

<script>
  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  import QuestionnaireViewModule from './QuestionnaireViewModule.vue'

  export default {
    name: 'rb-questionnaire-view',

    mixins: [ QuestionnaireMixin ],

    components: { QuestionnaireViewModule },

    props: ['questionnaire'],

    computed: {
      modelCells () {
        return this.questionnaire.model.cells
      }
    },

    mounted() {
      const event = new Event('QuestionnaireReady');
      document.dispatchEvent(event);
    },
  }
</script>
