/**
 * Created by DejanK on 03/20/2017.
 */
import {abstractState, simpleState, svelteState} from '../shared/tools/router-helper';
import { GUEST } from 'auth/auth-roles';
import { HTML as SignInHtml, Controller as SignInController} from '../public-auth/states/sign-in/sign-in.ctrl'
import { HTML as ForgottenPasswordEnterEmailHtml, Controller as ForgottenPasswordEnterEmailController} from '../public-auth/states/forgotten-password/enter-email-address.ctrl'
import { HTML as ForgottenPasswordVerifyEmailHtml, Controller as ForgottenPasswordVerifyEmailController} from '../public-auth/states/forgotten-password/verify-email-address.ctrl'
import { HTML as ForgottenPasswordSetPasswordHtml, Controller as ForgottenPasswordSetPasswordController} from '../public-auth/states/forgotten-password/set-password.ctrl'

import {
  PRICES,
  SIGN_IN,
  SIGN_UP,
  EMAIL_ADDRESS_VERIFICATION_PAGE,
  PAGE_USER_BANNED,
  PAGE_TOKEN_USER_ACCOUNT_MANAGER,
  PAGE_TOKEN_USER_ACCOUNT_MANAGER_ADD_ACCOUNT
} from './index'

export default States

function States($stateProvider) {
  $stateProvider
    .state(PRICES, svelteState(
      '/prices',
      () => import( /* webpackChunkName: "PricesPage" */'root/public/states/home/home-pricing/HomePricingPage.svelte')))
    .state(SIGN_UP, svelteState(
      '/sign-up?subscription&entityId',
      () => import( /* webpackChunkName: "SignUpPage" */'root/public-auth/states/registration/sign-up/TheSignUpPage.svelte')))
    .state(EMAIL_ADDRESS_VERIFICATION_PAGE, svelteState(
      '/verify-email-address?token',
      () => import( /* webpackChunkName: "EmailAddressVerification" */'root/public-auth/states/registration/email-address-verification/TheEmailAddressVerificationPage.svelte')))

    .state(SIGN_IN, simpleState('/sign-in?redirect', SignInHtml, SignInController, GUEST))

    .state(PAGE_USER_BANNED, svelteState(
      '/banned',
      () => import( /* webpackChunkName: "TheAccountUnderReviewPage" */'root/public-auth/states/user-disabled/TheUserDisabledPage.svelte')))

    .state(PAGE_TOKEN_USER_ACCOUNT_MANAGER, svelteState(
      '/t-accounts-manager?token',
      () => import( /* webpackChunkName: "TheTokenUserAccountsManagerPage" */'root/public-auth/states/user-accounts-manager/TheUserAccountsManagerPage.svelte')))

    .state(PAGE_TOKEN_USER_ACCOUNT_MANAGER_ADD_ACCOUNT, svelteState(
      '/t-accounts-manager/add-account?token&type',
      () => import( /* webpackChunkName: "TheTokenUserAccountsManagerAddAccountPage" */'root/public-auth/states/user-accounts-manager/TheTokenUserAccountsManagerAddAccountPage.svelte')))


    .state('forgottenPassword', abstractState('/forgotten-password', GUEST))
    .state('forgottenPassword.enterEmailAddress', simpleState('/enter-email-address', ForgottenPasswordEnterEmailHtml, ForgottenPasswordEnterEmailController))
    .state('forgottenPassword.verifyEmailAddress', simpleState('/verify-email-address?token', ForgottenPasswordVerifyEmailHtml, ForgottenPasswordVerifyEmailController))
    .state('forgottenPassword.setPassword', simpleState('/set-password?token', ForgottenPasswordSetPasswordHtml, ForgottenPasswordSetPasswordController))
}
