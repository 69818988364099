<template>
  <modal-form
    :class="addressFormVisible ? $style.dialogWhenAddressFormVisible : $style.dialog"
    @cancel="$formCancelHandler"
    @submit="submitHandler"
  >
    <span slot="header">Change Basic Information</span>
    <div
      slot="body"
      class="vModalDefaultBody"
    >
      <rb-input
        :for-id="'name'"
        :error="$showError('name')"
      >
        <span slot="label">Name</span>
        <input
          id="name"
          v-model="data.name"
          v-validate="'required|max:100'"
          type="text"
          name="name"
          class="rbInput"
          autofocus
          placeholder="Name"
          data-vv-as="Name"
        >
      </rb-input>
      <div
        v-if="canEditIndustry"
        :class="$style.columnsContainer"
      >
        <rb-input
          :for-id="'industry'"
          :error="$showError('industry')"
          :class="$style.column"
        >
          <span slot="label">Industry</span>
          <rb-select-new
            v-model="data.industry"
            v-validate="'required'"
            name="industry"
            :options="industries"
            placeholder="Select Industry"
            data-vv-as="Industry"
          />
        </rb-input>

        <rb-input
          for-id="website1"
          :error="$showError('website1')"
          :class="$style.column"
        >
          <span slot="label">Company Website</span>
          <rbv-input-url
            id="website1"
            v-model="data.website"
            v-validate="{url:true}"
            name="website1"
            class="rbInput"
            placeholder="http://yoursiteishere.com"
            data-vv-as="Company Website"
          />
        </rb-input>
      </div>

      <div v-else>
        <rb-input
          for-id="website2"
          :error="$showError('website2')"
        >
          <span slot="label">Company Website</span>
          <rbv-input-url
            id="website2"
            v-model="data.website"
            v-validate="'url:true'"
            type="url"
            name="website2"
            class="rbInput"
            placeholder="http://yoursiteishere.com"
            data-vv-as="Company Website"
          />
        </rb-input>
      </div>

      <rbv-input-address-with-coordinates
        v-model="data.location"
        v-validate="{required: true}"
        name="location"
        :form-opened="addressFormVisible"
        @formOpened="onFormOpened"
      />
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button class="rbButton mainButton">
        Confirm &amp; Apply
      </button>
    </div>
  </modal-form>
</template>

<script>
  import { cloneDeep, noop } from 'lodash'
  import {currentUser, mainAPI, notificationUtils} from 'root/angular-injector-provider';

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'
  import RbSelectNew from 'vRoot/_core/rbv-input/RbSelectNew.vue'

  import RbvInputAddressWithCoordinates from './RbvInputAddressWithCoordinates.vue'
  import RbvInputUrl from 'vRoot/_core/rbv-input/RbvInputUrl.vue';

  export default {
    name: 'RbvCompanyProfileEditBasicInfoDialog',
    components: { RbSelectNew, RbInput, ModalForm, RbvInputAddressWithCoordinates, RbvInputUrl },
    mixins: [ RbFormMixin, DialogMixin ],
    provide () {
      return {
        validator: this.$validator
      }
    },
    props: {
      entity: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },

    data() {
      return {
        data: cloneDeep(this.entity),
        addressFormVisible: 0
      }
    },

    asyncComputed: {
      industries: {
        lazy: true,
        get () { return mainAPI().getEntityIndustries() }
      }
    },

    computed: {
      canEditIndustry () { return !['CHAIN', 'HOTEL', 'HMC'].find( t => t === this.entity.type) }
    },

    methods: {
      onFormOpened(){
        this.addressFormVisible++
      },

      submitHandler () {
        this.addressFormVisible++;

        this.$nextTick()
          .then(() => this.$validator.validateAll())
          .then(result => {
            if (result) {
              return Promise.resolve()
            } else {
              this.$touchAllFields();
              return Promise.reject()
            }
          })
          .then(() =>
            notificationUtils().onSave(
              () => mainAPI().updateEntityDetails(this.entity.type, this.entity.id, this.data)
            )
          )
          .then( () => {
            this.$rbEmit({ name: 'hide', data: this.data})
          })
          .then( () => { currentUser().refresh() })
          .catch(noop)
      }
    }
  }
</script>

<style lang="stylus" module="">

  .dialog {
    min-width: 730px;
    overflow visible
  }

  .dialogWhenAddressFormVisible {
    composes dialog
    overflow auto
  }

  .columnsContainer {
    display flex
    justify-content space-between
  }

  .column {
    width: 48.73%
  }
</style>
