<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import {onMount} from 'svelte';
  import stripeProvider from 'root/stripe.provider';
  import {notificationUtils, $state as ngState} from 'root/angular-injector-provider'
  import {PAGE_TERMS} from 'root/states'
  import getSelectionIcon from 'root/services/selection-icons.service';
  import noop from 'lodash/noop';

  export let amount, recipient, hide, cancel, secret, askForRecipient;

  let
    formModel = {
      emailAddress: recipient || '',
    },
    errors = {},
    tosConfirmed = !askForRecipient,
    tosLink = ngState().href(PAGE_TERMS),
    card,
    email;

  onMount(setupForm);

  async function setupForm() {
    card = await mountCard();
    startValidators();

    function startValidators(){
      if(askForRecipient) email.addEventListener('change', () => { errors.email = email.validationMessage; });
      card.on('change', (event) => { errors.card = event.error ? event.error.message : undefined; });
    }

    async function mountCard(){
      const stripe = await stripeProvider();
      const elements = stripe.elements(),
        card = elements.create('card', {
          style: {
            base: {
              color: '#455A64',
              fontWeight: 500,
              fontFamily: 'Noto Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
              fontSize: '14px',
              fontSmoothing: 'antialiased',
              backgroundColor: 'transparent',

              '::placeholder': {
                color: '#CFD7DF',
              },
              ':-webkit-autofill': {
                color: '#e39f48',
              },
            },
            invalid: {
              color: '#f15a24',

              '::placeholder': {
                color: '#f15a24',
              },
            },
          },
        });
      card.mount('#card');

      return card;
    }
  }

  function pay(event) {
    event.preventDefault();
    notificationUtils().onSave(() => new Promise((resolve, reject) => {
      if(!isFormValid()) return reject({status: 'INVALID_FORM'});

      stripeProvider()
        .then( stripe => stripe.confirmCardPayment(secret, {
            payment_method: {
              card: card,
              billing_details: {
                email: formModel.emailAddress,
              },
            },
            receipt_email: formModel.emailAddress,
          })
        )
        .then(result => {
          const paymentIntent = result.paymentIntent || result.error.payment_intent; // if invoice was already payed before attempt, an error will be returned from stripe

          if (paymentIntent && paymentIntent.status === 'succeeded') {
            resolve();
          } else {
            reject({
              status: 'STRIPE_ERROR',
              message: (result.error && result.error.message) || 'Unexpected error',
            });
          }
        })
        .catch(e => reject(e));

    }), { customErrorHandlers: {
        INVALID_FORM: () => {},
        STRIPE_ERROR: e => {errors.card = e.message}
      }})
      .then(() => hide())
      .catch(noop);

    function isFormValid(){
      errors.email = askForRecipient ? email.validationMessage : null;
      errors.tos = tosConfirmed ? undefined : 'Terms of service must be accepted';
      return !Object.keys(errors).find(key => errors[key]);
    }
  }

  function handleTosClicked(){
    tosConfirmed = !tosConfirmed;
    errors.tos = undefined;
  }
</script>


<ClosableModal close="{cancel}">
  <div slot="heading">
    <div class="Header">Charge <b>{amount}</b></div>
  </div>

  <div class="Content">
    <span>Once we charge your card, you will have access to the bid.</span>
  </div>
  <form novalidate on:submit={e => pay(e)}>
    {#if askForRecipient}
      <div class="InputLabel" class:invalid={errors.email}>Receipt Email Address</div>
      <div class="InputContainer" class:invalid={errors.email}>
        <input type="email"
               name="emailAddress"
               bind:value="{formModel.emailAddress}"
               bind:this={email}
               placeholder="Receipt Email Address"
               required
               autocomplete="emailAddress" />
      </div>
      <div class="ErrorContainer">{errors.email || ''}</div>
    {/if}

    <div class="InputLabel" class:invalid={errors.card}>Card Information</div>
    <div class="InputContainer StripeCard" class:invalid={errors.card}>
      <div id="card"></div>
    </div>
    <div class="ErrorContainer">{errors.card || ''}</div>

    {#if askForRecipient}
      <div class="Tos"
           data-icon-before="{getSelectionIcon(tosConfirmed)}"
           on:click={handleTosClicked}
           class:invalid={errors.tos}
      >
        <span>I have read and I accept <a href="{tosLink}" target="_blank">Terms of Service</a>.</span>
      </div>
    {/if}

    <div class="Actions">
      <button type="button" class="aBtn atLight asLarge" on:click={cancel}>Cancel</button>

      <button type="submit" class="aBtn atLight asLarge asMain"
              data-icon="verified_user"
      >
        Pay {amount}
      </button>
    </div>
  </form>
</ClosableModal>

<style lang="stylus">.Header {
  padding: 21px 13px 0;
  color: #37474f;
  font-size: 14px;
}
.Content {
  padding: 0 13px;
  color: #546e7a;
  font-size: 13px;
  margin-bottom: 20px;
}
.Actions {
  padding: 0 18px 21px;
  text-align: right;
}
.Tos {
  padding: 0 13px 31px;
  color: #546e7a;
  font-size: 13px;
  cursor: pointer;
}
.Tos:before {
  padding-right: 7px;
}
.Tos.invalid {
  color: #f15a24;
}
.InputLabel {
  margin: 0 13px 2px;
  padding: 0 15px;
  background: #fff;
  font-size: 12px;
  color: #90a4ae;
}
.InputLabel.invalid {
  color: #f15a24;
}
.InputContainer {
  border: 1px solid #cfd8dc;
  margin: 0 13px;
  height: 34px;
  border-radius: 18px;
  flex: 1;
  color: #455a64;
}
.InputContainer :global(input) {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 32px;
  width: 100%;
  font-size: 14px;
  color: inherit;
  border: 0;
  background: transparent;
  padding: 0 14px;
  box-sizing: border-box;
}
.InputContainer :global(input)::placeholder {
  color: #cfd7df;
}
.InputContainer :global(input):-webkit-autofill {
  transition-delay: 3600s;
}
.InputContainer.invalid {
  border-color: #f15a24;
  color: #f15a24;
}
.InputContainer.invalid :global(input)::placeholder {
  color: #f15a24,;
}
.StripeCard {
  min-height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 14px;
}
.ErrorContainer {
  color: #f15a24;
  font-size: 12px;
  margin: 4px 26px 5px;
  line-height: 16px;
  min-height: 20px;
}
</style>