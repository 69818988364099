<script>
  export default {
    props: {
      props: {
        type: Object,
        required: false,
        default: () => ({})
      },

      listeners: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },

    watch: {
      props() {
        if(this.svelteComponent)
          this.svelteComponent.$set(this.props);
      }
    },

    async mounted() {
      try {
        const c = await this.component;
        const Component = c.default ? c.default : c;
        this.svelteComponent = new Component({
          target: this.$el,
          props: this.props
        });

        Object.keys(this.listeners).forEach(event => this.svelteComponent.$on(event, this.listeners[event]));
      } catch (e){
        // eslint-disable-next-line no-console
        console.error('Component failed to load');
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },

    destroyed() {
      if(this.svelteComponent){
        this.svelteComponent.$destroy();
      }
    }
  }
</script>