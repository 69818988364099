import { checkAndExecute } from 'root/api/helpers';
import HotelRfpBidAPI from './rfp-hotel/bid/HotelRfpBidAPI.js';
import HotelRfpBidManagerAPI from './rfp-hotel/bid/HotelRfpBidManagerAPI.js';
import HotelRfpBidNegotiationsAPI from './rfp-hotel/bid/HotelRfpBidNegotiationsAPI.js';
import HotelRfpBidExportAPI from './rfp-hotel/bid/HotelRfpBidExportAPI.js';
import HotelRfpAPI from './rfp-hotel/rfp/HotelRfpAPI.js';
import HotelRfpDestinationsAPI from './rfp-hotel/rfp/HotelRfpDestinationsAPI.js';
import HotelRfpExportAPI from './rfp-hotel/rfp/HotelRfpExportAPI.js';
import HotelRfpCompetitiveBidsAPI from './rfp-hotel/bid/HotelRfpCompetitiveBidsAPI.js';
import RfpAPI from './rfp/RfpAPI.js';
import ManageCompaniesApi from 'root/api/account/companies/ManageCompaniesApi';
import HotelRfpCompetitiveBidsProfilesAPI from 'root/api/rfp-hotel/rfp/HotelRfpCompetitiveBidsProfilesAPI';
import AttachmentsApi from 'root/api/other/AttachmentsApi';
import rfpReportsRepo from '../rfp/reports/rfp-reports.repo.js';
import ChainPortalHotelProfileRepo from '../rfp/reports/chain-portal/profile/services/repo';
import RfpAutomatedReminderApi from './rfp/RfpAutomatedReminderApi.js';
import RfpBidNotesApi from './rfp/bid/RfpBidNotesApi.js';
import RegistrationApi from './registration';
import ExchangeRatesApi from './other/ExchangeRatesApi.js';
import HotelRfpBidBenchmarkRateApi from './rfp-hotel/bid/HotelRfpBidBenchmarkRateAPI.js';
import DashboardAPI from 'root/api/rfp/DashboardAPI';
import RfpBookmarkAPI from 'root/api/rfp/RfpBookmarkAPI';
import EmailApi from 'root/api/email/EmailApi';
import EmailAddressApi from 'root/api/email/EmailAddressApi';
import HotelBidAPI from 'root/api/rfp-hotel/bid/HotelBidAPI';
import HotelAPI from 'root/api/entity/hotel';
import UserAccountsManager from './account/user-accounts-manager'
import UserAccountReportsSettings from './account/user-account-reports-settings'
import AccountsAPI from './account/account-api'
import UserSecurityAPI from './user/user-security-api'
import HotelRfpRateLoadingAPI from './rfp-hotel/bid/HotelRfpRateLoadingAPI'

export default MainAPI

MainAPI.$inject = ['MainServer', 'DataServer'];
function MainAPI( mainServer, dataServer ) {
  const $api = this;
  this.server = mainServer;
  this.data = dataServer;
  RfpAPI(this);
  HotelRfpAPI(this);
  HotelRfpDestinationsAPI(this);
  HotelRfpExportAPI(this);
  HotelRfpBidAPI(this);
  HotelRfpBidNegotiationsAPI(this);
  HotelRfpBidExportAPI(this);
  HotelRfpCompetitiveBidsAPI(this);
  ManageCompaniesApi(this);
  HotelRfpCompetitiveBidsProfilesAPI(this);
  AttachmentsApi(this);
  rfpReportsRepo(this);
  RfpAutomatedReminderApi(this);
  RfpBidNotesApi(this);
  ChainPortalHotelProfileRepo(this);
  RegistrationApi(this);
  HotelRfpBidManagerAPI(this);
  ExchangeRatesApi(this);
  HotelRfpBidBenchmarkRateApi(this);
  DashboardAPI(this);
  RfpBookmarkAPI(this);
  EmailApi(this);
  EmailAddressApi(this);
  HotelBidAPI(this);
  HotelAPI(this);
  UserAccountsManager(this);
  UserAccountReportsSettings(this);
  AccountsAPI(this);
  UserSecurityAPI(this);
  HotelRfpRateLoadingAPI(this);

  $api.getCountries = dataServer.getCountries;
  $api.getStates = dataServer.getStates;
  $api.getEntityTypes = dataServer.getAccountTypes;
  $api.getEntityIndustries = dataServer.getIndustries;

  $api.getEntity = ( entityType, entityId ) => checkAndExecute(
    () => entityType && entityId,
    () => mainServer.get(`entities/${entityType}/${entityId}`));

  $api.updateEntityDetails = ( entityType, entityId, data ) => checkAndExecute(
    () => entityType && entityId,
    () => mainServer.put(`entities/${entityType}/${entityId}/details`, data));

  $api.updateEntityLogo = ( entityType, entityId, file ) => {
    const fd = new FormData();
    fd.append('file', file);
    return checkAndExecute(
      () => entityType && entityId,
      () => mainServer.sendFile(`entities/${entityType}/${entityId}/logo`, fd),
      404 )
  };

  $api.updateEntityImage = ( entityType, entityId, file ) => {
    const fd = new FormData();
    fd.append('file', file);
    return checkAndExecute(
      () => entityType && entityId,
      () => mainServer.sendFile(`entities/${entityType}/${entityId}/image`, fd),
      404)
  };

  $api.listChains = () => mainServer.get('hotel-chains/all');
}
