import {checkAndExecute} from 'root/api/helpers';

export default function HotelRfpBidResponseAPI(api) {
  Object.assign(api, {

    previewRfp: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/${rfpId}/preview`)),

    updateRateQuickRfp: ( rfpId, payload ) => checkAndExecute(
      () => rfpId && payload,
      () => api.server.put( `rfps/hotel/rate-quick/${rfpId}`, payload )),

    previewEditedRateQuickRfp: ( rfpId, payload ) => checkAndExecute(
      () => rfpId && payload,
      () => api.server.put( `rfps/hotel/rate-quick/${rfpId}/preview`, payload )),

    getHotelRfpCoverLetterTemplate: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/${rfpId}/cover-letter`)),

    getHotelRfpNamCoverLetterTemplate: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/hotel/${rfpId}/nam-cover-letter/template`)),

    getLetterTemplateForHotelRateQuickRFPChainRepresentative: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`templates/letters/hotel-rfp-rate-quick-nam-cover-letter-template`)),

    setHotelRfpCoverLetterTemplate: (rfpId, template) => checkAndExecute(
      () => rfpId && template,
      () => api.server.put(`rfps/${rfpId}/cover-letter`, { template })),

    addHotelRfpDirectCoverLetterAttachment: (rfpId, data) => checkAndExecute(
        () => rfpId && data,
        () => api.server.post(`rfps/hotel/${rfpId}/cover-letter/direct/attachments`, data)),

    removeHotelRfpDirectCoverLetterAttachment: (rfpId, attachmentId) => checkAndExecute(
        () => rfpId && attachmentId,
        () => api.server.delete(`rfps/hotel/${rfpId}/cover-letter/direct/attachments/${attachmentId}`)),

    addHotelRfpNamCoverLetterAttachment: (rfpId, data) => checkAndExecute(
        () => rfpId && data,
        () => api.server.post(`rfps/hotel/${rfpId}/cover-letter/chain/attachments`, data)),

    removeHotelRfpNamCoverLetterAttachment: (rfpId, attachmentId) => checkAndExecute(
        () => rfpId && attachmentId,
        () => api.server.delete(`rfps/hotel/${rfpId}/cover-letter/chain/attachments/${attachmentId}`)),

    addHotelRfpFinalAgreementAttachment: (rfpId, data) => checkAndExecute(
        () => rfpId && data,
        () => api.server.post(`rfps/hotel/${rfpId}/final-agreement/attachments`, data)),

    removeHotelRfpFinalAgreementAttachment: (rfpId, attachmentId) => checkAndExecute(
        () => rfpId && attachmentId,
        () => api.server.delete(`rfps/hotel/${rfpId}/final-agreement/attachments/${attachmentId}`)),

    setHotelRfpNamCoverLetterTemplate: (rfpId, template) => checkAndExecute(
      () => rfpId && template,
      () => api.server.put(`rfps/hotel/${rfpId}/nam-cover-letter/template`, { template })),

    getHotelRfpCoverLetterPlaceholders: () => api.data.getHotelRfpCoverLetterPlaceholders(),

    getHotelRfpNamCoverLetterPlaceholders: () => api.data.getHotelRfpNamCoverLetterPlaceholders(),

    enableChainSupportOnRfp: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.post(`rfps/hotel/${rfpId}/chain-support`, {enableChainSupport: true})),

    updateRfpName: (rfpId, name) => checkAndExecute(
      () => rfpId,
      () => api.server.put(`rfps/hotel/${rfpId}/specifications/name`, {name})
    ),

    updateRfpSpecifications: (rfpId, values) => checkAndExecute(
      () => rfpId,
      () => api.server.put(`rfps/hotel/${rfpId}/specifications`, values)
    ),

    listBuyerContactsForRfp: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/hotel/${rfpId}/buyer/list`)
    ),

    setBuyerContactForRfp: (rfpId, userAccountId) => checkAndExecute(
      () => rfpId && userAccountId,
      () => api.server.put(`rfps/hotel/${rfpId}/buyer`, { userAccountId })
    ),

    listDestinationProperties: (rfpId, destinationId) => checkAndExecute(
      () => rfpId && destinationId,
      () => api.server.get(`rfps/${rfpId}/travel-destinations/${destinationId}/properties`)
    ),

    createOrMoveBids: (rfpId, destinationId, toCreate = [], toMove = []) => checkAndExecute(
      () => rfpId && destinationId && (toMove.length || toCreate.length),
      () => api.server.post(`rfps/${rfpId}/travel-destinations/${destinationId}/bids/create-or-move`, {toMove, toCreate})
    )
  })
}
