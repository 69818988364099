import { noop } from 'lodash'

const FillResponseOnlyOnce = 'FillResponseOnlyOnce';

export { FillResponseOnlyOnce }

export default TutorialsService

TutorialsService.$inject = ["rbDialog", "CurrentUser"]
function TutorialsService(dialog, currentUser){

  this.show = showTutorial;
  this.disable = disableTutorial

  function showTutorial(tutorialName){
    currentUser.get()
      .then((user) => { return user.tutorials.indexOf(tutorialName) !== -1 && dialog.show(tutorialName) })
      .catch( noop )
  }

  function disableTutorial(tutorialName){
    return currentUser.disableTutorial(tutorialName).catch( noop )
  }
}
