import Vue from 'vue';
import noop from 'lodash/noop';

export default {
  template: '<div></div>',
  controller: Ctrl,
}

Ctrl.$inject = ['$element', '$stateParams', 'CurrentUser'];
function Ctrl($element, $stateParams, currentUser){
  let
    vueComponent = null;

  Object.assign(this, {

    $onInit () {
      currentUser.get()
        .then( user => {
          import( /* webpackChunkName: 'TheSelectRfpTemplate' */ './TheSelectRfpTemplate.vue')
            .then( imported => {
              vueComponent = new Vue({
                el: $element[0],
                render ( h ) {
                  return h( imported.default, {
                    props: {
                      accountId: $stateParams.accountId || user.getAccountId(),
                    },
                  });
                },
              });
            })
        })
        .catch( noop );
    },

    $onDestroy () {
      if(vueComponent) {
        vueComponent.$destroy();
      }
    },
  });
}
