/**
 * Created by DejanK on 2/1/2017
 *
 * Virtual Repeat Container
 * Based on Angular Material Virtual Repeat Container:
 *
 * does not support:
 *    - horizontal scroll
 *    - auto-shrink
 */

'use strict';

let lodash = require("lodash");

module.exports = rbVirtualRepeatContainerDirective;

function rbVirtualRepeatContainerDirective(){
  return {
    controller: VirtualRepeatContainerController,
    template: virtualRepeatContainerTemplate,
    compile: function virtualRepeatContainerCompile($element) {
      $element
        .addClass('rb-virtual-repeat-container')
        .addClass('rb-orient-vertical')
    }
  };
}

function virtualRepeatContainerTemplate($element, $attrs) {
  return `<div id="${$attrs.rbScrollerId}" class="rb-virtual-repeat-scroller">
    <div class="rb-virtual-repeat-sizer"></div>
    <div class="rb-virtual-repeat-offsetter">
    ${$element[0].innerHTML}
    </div></div>`;
}

/**
 * Number of additional elements to render above and below the visible area inside
 * of the virtual repeat container. A higher number results in less flicker when scrolling
 * very quickly in Safari, but comes with a higher rendering and dirty-checking cost.
 * @const {number}
 */
var NUM_EXTRA = 3;

/** @type {!number} Maximum amount of pixels allowed for a single DOM element */
var ELEMENT_MAX_PIXELS = 1533917;

/** @ngInject */
VirtualRepeatContainerController.$inject = ['$$rAF', '$parse', '$rootScope', '$window', '$scope', '$element', '$attrs'];
function VirtualRepeatContainerController($$rAF, $parse, $rootScope, $window, $scope, $element, $attrs) {
  this.$rootScope = $rootScope;
  this.$scope = $scope;
  this.$element = $element;
  this.$attrs = $attrs;

  /** @type {number} The width or height of the container */
  this.size = 0;
  /** @type {number} The scroll width or height of the scroller */
  this.scrollSize = 0;
  /** @type {number} The scrollLeft or scrollTop of the scroller */
  this.scrollOffset = 0;

  /** @type {!VirtualRepeatController} The repeater inside of this container */
  this.repeater = null;

  this.originalSize = null;

  ///** @type {number} Amount to offset the total scroll size by. */
  this.offsetSize = parseInt(this.$attrs.mdOffsetSize, 10) || 0;
  ///** @type {?string} height or width element style on the container prior to auto-shrinking. */
  this.oldElementSize = null;
  /** @type {!number} Maximum amount of pixels allowed for a single DOM element */
  this.maxElementPixels = ELEMENT_MAX_PIXELS;

  if (this.$attrs.mdTopIndex) {
    /** @type {function(angular.Scope): number} Binds to topIndex on Angular scope */
    this.bindTopIndex = $parse(this.$attrs.mdTopIndex);
    /** @type {number} The index of the item that is at the top of the scroll container */
    this.topIndex = this.bindTopIndex(this.$scope);

    if (!angular.isDefined(this.topIndex)) {
      this.topIndex = 0;
      this.bindTopIndex.assign(this.$scope, 0);
    }

    this.$scope.$watch(this.bindTopIndex, angular.bind(this, function(newIndex) {
      if (newIndex !== this.topIndex) {
        this.scrollToIndex(newIndex);
      }
    }));
  } else {
    this.topIndex = 0;
  }

  this.scroller = $element[0].querySelector('.rb-virtual-repeat-scroller');
  this.sizer = this.scroller.querySelector('.rb-virtual-repeat-sizer');
  this.offsetter = this.scroller.querySelector('.rb-virtual-repeat-offsetter');

  // After the dom stablizes, measure the initial size of the container and
  // make a best effort at re-measuring as it changes.
  var boundUpdateSize = angular.bind(this, this.updateSize);

  $$rAF(angular.bind(this, function() {
    boundUpdateSize();

    var debouncedUpdateSize = lodash.debounce(boundUpdateSize, 100);
    var jWindow = angular.element($window);

    // Make one more attempt to get the size if it is 0.
    // This is not by any means a perfect approach, but there's really no
    // silver bullet here.
    if (!this.size) {
      debouncedUpdateSize();
    }

    jWindow.on('resize', debouncedUpdateSize);
    $scope.$on('$destroy', function() {
      jWindow.off('resize', debouncedUpdateSize);
    });

    $scope.$emit('$md-resize-enable');
    $scope.$on('$md-resize', boundUpdateSize);
  }));
}

/** Called by the md-virtual-repeat inside of the container at startup. */
VirtualRepeatContainerController.prototype.register = function(repeaterCtrl) {
  this.repeater = repeaterCtrl;

  angular.element(this.scroller)
    .on('scroll wheel touchmove touchend', angular.bind(this, this.handleScroll_));
};


/** @return {number} The size (width or height) of the container. */
VirtualRepeatContainerController.prototype.getSize = function() {
  return this.size;
};


/**
 * Resizes the container.
 * @private
 * @param {number} size The new size to set.
 */
VirtualRepeatContainerController.prototype.setSize_ = function(size) {
  var dimension = this.getDimensionName_();

  this.size = size;
  this.$element[0].style[dimension] = size + 'px';
};


VirtualRepeatContainerController.prototype.unsetSize_ = function() {
  this.$element[0].style[this.getDimensionName_()] = this.oldElementSize;
  this.oldElementSize = null;
};


/** Instructs the container to re-measure its size. */
VirtualRepeatContainerController.prototype.updateSize = function() {
  // If the original size is already determined, we can skip the update.
  if (this.originalSize) return;

  this.size = this.$element[0].clientHeight;

  // Recheck the scroll position after updating the size. This resolves
  // problems that can result if the scroll position was measured while the
  // element was display: none or detached from the document.
  this.handleScroll_();

  this.repeater && this.repeater.containerUpdated();
};


/** @return {number} The container's scrollHeight or scrollWidth. */
VirtualRepeatContainerController.prototype.getScrollSize = function() {
  return this.scrollSize;
};


VirtualRepeatContainerController.prototype.getDimensionName_ = function() {
  return 'height';
};


/**
 * Sets the scroller element to the specified size.
 * @private
 * @param {number} size The new size.
 */
VirtualRepeatContainerController.prototype.sizeScroller_ = function(size) {
  var dimension =  this.getDimensionName_();
  var crossDimension = 'height';

  // Clear any existing dimensions.
  this.sizer.innerHTML = '';

  // If the size falls within the browser's maximum explicit size for a single element, we can
  // set the size and be done. Otherwise, we have to create children that add up the the desired
  // size.
  if (size < this.maxElementPixels) {
    this.sizer.style[dimension] = size + 'px';
  } else {
    this.sizer.style[dimension] = 'auto';
    this.sizer.style[crossDimension] = 'auto';

    // Divide the total size we have to render into N max-size pieces.
    var numChildren = Math.floor(size / this.maxElementPixels);

    // Element template to clone for each max-size piece.
    var sizerChild = document.createElement('div');
    sizerChild.style[dimension] = this.maxElementPixels + 'px';
    sizerChild.style[crossDimension] = '1px';

    for (var i = 0; i < numChildren; i++) {
      this.sizer.appendChild(sizerChild.cloneNode(false));
    }

    // Re-use the element template for the remainder.
    sizerChild.style[dimension] = (size - (numChildren * this.maxElementPixels)) + 'px';
    this.sizer.appendChild(sizerChild);
  }
};


///**
// * If auto-shrinking is enabled, shrinks or unshrinks as appropriate.
// * @private
// * @param {number} size The new size.
// */
//VirtualRepeatContainerController.prototype.autoShrink_ = function(size) {
//  var shrinkSize = Math.max(size, this.autoShrinkMin * this.repeater.getItemSize());
//
//  if (this.autoShrink && shrinkSize !== this.size) {
//    if (this.oldElementSize === null) {
//      this.oldElementSize = this.$element[0].style[this.getDimensionName_()];
//    }
//
//    var currentSize = this.originalSize || this.size;
//
//    if (!currentSize || shrinkSize < currentSize) {
//      if (!this.originalSize) {
//        this.originalSize = this.size;
//      }
//
//      // Now we update the containers size, because shrinking is enabled.
//      this.setSize_(shrinkSize);
//    } else if (this.originalSize !== null) {
//      // Set the size back to our initial size.
//      this.unsetSize_();
//
//      var _originalSize = this.originalSize;
//      this.originalSize = null;
//
//      // We determine the repeaters size again, if the original size was zero.
//      // The originalSize needs to be null, to be able to determine the size.
//      if (!_originalSize) this.updateSize();
//
//      // Apply the original size or the determined size back to the container, because
//      // it has been overwritten before, in the shrink block.
//      this.setSize_(_originalSize || this.size);
//    }
//
//    this.repeater.containerUpdated();
//  }
//};


/**
 * Sets the scrollHeight or scrollWidth. Called by the repeater based on
 * its item count and item size.
 * @param {number} itemsSize The total size of the items.
 */
VirtualRepeatContainerController.prototype.setScrollSize = function(itemsSize) {
  var size = itemsSize + this.offsetSize;
  if (this.scrollSize === size) return;

  this.sizeScroller_(size);
  //this.autoShrink_(size);
  this.scrollSize = size;
};


/** @return {number} The container's current scroll offset. */
VirtualRepeatContainerController.prototype.getScrollOffset = function() {
  return this.scrollOffset;
};

/**
 * Scrolls to a given scrollTop position.
 * @param {number} position
 */
VirtualRepeatContainerController.prototype.scrollTo = function(position) {
  this.scroller['scrollTop'] = position;
  this.handleScroll_();
};

/**
 * Scrolls the item with the given index to the top of the scroll container.
 * @param {number} index
 */
VirtualRepeatContainerController.prototype.scrollToIndex = function(index) {
  var itemSize = this.repeater.getItemSize();
  var itemsLength = this.repeater.itemsLength;
  if(index > itemsLength) {
    index = itemsLength - 1;
  }
  this.scrollTo(itemSize * index);
};

VirtualRepeatContainerController.prototype.resetScroll = function() {
  this.scrollTo(0);
};


VirtualRepeatContainerController.prototype.handleScroll_ = function() {
  var ltr = document.dir != 'rtl' && document.body.dir != 'rtl';
  if(!ltr && !this.maxSize) {
    this.scroller.scrollLeft = this.scrollSize;
    this.maxSize = this.scroller.scrollLeft;
  }
  var offset = this.scroller.scrollTop;
  if (offset === this.scrollOffset || offset > this.scrollSize - this.size) return;

  var itemSize = this.repeater.getItemSize();
  if (!itemSize) return;

  var numItems = Math.max(0, Math.floor(offset / itemSize) - NUM_EXTRA);

  var transform = 'translateY(' + numItems * itemSize  + 'px)';

  this.scrollOffset = offset;
  this.offsetter.style.webkitTransform = transform;
  this.offsetter.style.transform = transform;

  if (this.bindTopIndex) {
    var topIndex = Math.floor(offset / itemSize);
    if (topIndex !== this.topIndex && topIndex < this.repeater.getItemCount()) {
      this.topIndex = topIndex;
      this.bindTopIndex.assign(this.$scope, topIndex);
      if (!this.$rootScope.$$phase) this.$scope.$digest();
    }
  }

  this.repeater.containerUpdated();
};
