import noop from 'lodash/noop';
import UploadNegotiationDialog from './UploadNegotiationDialog.svelte'

import {
  NEGOTIATION_RESPONDED,
  NEGOTIATION_SENT,
  RESPONDED,
  statusMatches
} from 'rfp/bid-manager/main/core/actions/action/status';
import {SvelteDialog} from 'root/v-app/rbServices';

function uploadNegotiations(actionResultHandler){
  return function (selectedBidsReport) {
    const
      label = `Upload Negotiation ${(selectedBidsReport.view.side === 'BUYER' ? 'Request' : 'Response') + (selectedBidsReport.bids.length === 1 ? '' : 's')}`,
      allowedStates = selectedBidsReport.view.side === 'BUYER' ? [NEGOTIATION_RESPONDED, RESPONDED] : [NEGOTIATION_SENT],
      reUpload = false;

    return createActionObject(actionResultHandler, selectedBidsReport, {label, allowedStates, reUpload});
  };
}

function reUploadNegotiations(actionResultHandler){
  return function (selectedBidsReport) {
    const
      label = `Re-upload Negotiation ${(selectedBidsReport.view.side === 'BUYER' ? 'Request' : 'Response') + (selectedBidsReport.bids.length === 1 ? '' : 's')}`,
      allowedStates = selectedBidsReport.view.side === 'BUYER' ? [NEGOTIATION_SENT] : [NEGOTIATION_RESPONDED],
      reUpload = true;

    return createActionObject(actionResultHandler, selectedBidsReport, {label, allowedStates, reUpload});
  };
}

function createActionObject(actionResultHandler, selectedBidsReport, config){
  return {
    label: config.label,
    icon: 'drive_folder_upload',
    isAvailable: () => {
      return statusMatches(selectedBidsReport.statuses, config.allowedStates)
    },
    action: () => {
      SvelteDialog
        .show(UploadNegotiationDialog, {
          reUpload: config.reUpload,
          bids: selectedBidsReport.bids,
          side: selectedBidsReport.view.side,
          actionResultHandler
        })
        .catch(noop);
    }
  };
}

export default {
  upload: uploadNegotiations,
  reUpload: reUploadNegotiations
}
