<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'rbv-hotel-rfp-bid-manager-send-no-thank-you-action-report',
    mixins: [ ActionReportMixin ],
    data () {
      return {
        messages: {
          'OK': '"No Thank You" Sent',
          'NOT_FOUND': `Bid was not found - "No Thank You" not sent`,
          'STATUS_CHANGED': 'Bid status was changed - "No Thank You" not sent',
          'ERROR': '"No Thank You" not sent due to unknown error'
        }
      }
    },
    computed: {
      title () { return this.items.length === 1 ? '"No Thank You" not sent!' : 'Failed to send some "No Thank You" messages!' },
      message () {
        return this.items.length === 1
          ? 'We were not able to send "No Thank You" messages because of the reason listed below:'
          : `${this.items.length - this.failures} of ${this.items.length} "No Thank You" messages were successfully sent.<br /> We were not able to send other because of the reasons listed below:`
      }
    }
  }
</script>
