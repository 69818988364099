import angular from 'angular'
import Public from '../public'
import FormUI from '../ui-form-ng'
import Auth from '../auth'

import Dialogs from './dialogs'

import PublicAuthService from './shared/public-auth.service'
import SignInService from './shared/sign-in.service'
import InvitationService from './shared/invitation.service'

export default angular.module('rb.public-auth', [ Public, FormUI, Auth ])
  .config(Dialogs)
  .service('PublicAuth', PublicAuthService)
  .service('InvitationService', InvitationService)
  .service('SignInService', SignInService)
;

