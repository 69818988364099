/**
 * Created by DejanK on 7/3/2017.
 */

import './rb-negotiations-panel-header.scss';
import HTML from './rb-negotiations-panel-header.html';

export default {
  template: HTML,
  transclude: true,
  bindings: {
    side: '<',
    negotiations: '<',
    bid: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = [];
function Ctrl() {
  const vm = this;

  this.$onChanges = (changes)=>{
    if (changes.side || changes.negotiations) init();
  };

  function init(){
    if(!(vm.side && vm.negotiations)) return;
    const otherSideLastResponse = vm.negotiations.getOtherSideLastResponse(vm.side);
    vm.companyName = otherSideLastResponse.getCompanyName();
    vm.negotiatorName = otherSideLastResponse.getContactName();
    vm.dateTime = otherSideLastResponse.getFormattedDateTime();
  }
}
