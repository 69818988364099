import noop from 'lodash/noop';
import isEmailAddressAvailable from './isEmailAddressAvailable.js';
import isSabreAvailable from './isSabreAvailable.js';

const
  ASYNC_VALIDATION_CSS_CLASS = 'AsyncValidation',
  ASYNC_VALID_CSS_CLASS = 'AsyncValid',
  asyncChecks = {};

export function isValid(element){
  // Don't validate submits, buttons, file and reset inputs, and disabled fields
  return element.disabled
    || element.type === 'file'
    || element.type === 'reset'
    || element.type === 'submit'
    || element.type === 'button'
    || !element.validity
    || element.validity.valid;
}

export function validate(element, value){
  resetCustomValidity(element);
  let valid = isValid(element);

  valid = valid && requiredDataValue(element, value);
  valid = passwordComplexity(element, value) && valid;
  return !valid
    ? Promise.resolve()
    : uniqueEmail(element, value)
      .then(() => uniqueSabre(element, value))
      .catch(noop);
}

function resetCustomValidity(element) {
  element.classList.remove(ASYNC_VALIDATION_CSS_CLASS);
  element.classList.remove(ASYNC_VALID_CSS_CLASS);
  setCustomValidity(element,'');

  if(element.id) asyncChecks[element.id] = {};
}

function uniqueEmail(element, value){
  const validityAttribute = 'unique-email';
  return asyncCheck(element, value, validityAttribute, isEmailAddressAvailable, isAvailable => {
    if(isAvailable){
      setCustomValidity(element, '');
      element.classList.remove(ASYNC_VALIDATION_CSS_CLASS);
      element.classList.add(ASYNC_VALID_CSS_CLASS);
      return Promise.resolve();
    } else {
      setCustomValidity(element, 'This email address is already in use. Please sign in or provide different email address.');
      element.classList.remove(ASYNC_VALIDATION_CSS_CLASS);
      return Promise.reject(validityAttribute);
    }
  });
}

function uniqueSabre(element, value){
  const validityAttribute = 'unique-sabre';
  return asyncCheck(element, value, validityAttribute, isSabreAvailable, isAvailable => {
    if(isAvailable){
      setCustomValidity(element, '');
      element.classList.remove(ASYNC_VALIDATION_CSS_CLASS);
      element.classList.add(ASYNC_VALID_CSS_CLASS);
      return Promise.resolve();
    } else {
      setCustomValidity(element, 'Sabre code is already in use.');
      element.classList.remove(ASYNC_VALIDATION_CSS_CLASS);
      return Promise.reject(validityAttribute);
    }
  });
}

async function asyncCheck(element, value, elementAttribute, check, resolver){
  if(element.hasAttribute(elementAttribute)){
    const checkId = new Date().getMilliseconds();
    asyncChecks[element.id][elementAttribute] = checkId;
    element.classList.add(ASYNC_VALIDATION_CSS_CLASS)
    setCustomValidity(element, 'checking....');

    const checkResult = await check(value);

    // to avoid async race - if new checkId was added, this is an old one and should be aborted and async check chain stopped
    if(asyncChecks[element.id][elementAttribute] !== checkId) {
      return Promise.reject();
    }

    return resolver(checkResult);
  } else {
    return Promise.resolve();
  }
}


function requiredDataValue(element, value){
  const validityAttribute = 'required-data-value';
  if(element.hasAttribute(validityAttribute) && (value === null || value === undefined || value === '')) {
    setCustomValidity(element, element.getAttribute(`${validityAttribute}-message`) || 'Please fill out this field');
    return false;
  } else {
    return true;
  }
}

function passwordComplexity(element, value = ''){
  const validityAttribute = 'password-complexity';
  if(element.hasAttribute(validityAttribute)) {
    let valid = lengthCheck(value.length, element);
    valid = uppercaseCheck(value, element) && valid;
    valid = lowercaseCheck(value, element) && valid;
    valid = digitCheck(value, element) && valid;

    if(whitespaceCheck(value, element)){
      if(valid){
        return true;
      } else {
        setCustomValidity(element, 'Password too simple.')
        return false;
      }
    } else {
      setCustomValidity(element, 'Password must not contain white space.')
      return false;
    }
  } else {
    return true;
  }

  function lengthCheck(l = 0, el){
    if(l >= 8 && l <= 200){
      el.classList.add('ValidLength');
      return true;
    } else {
      el.classList.remove('ValidLength');
      return false;
    }
  }

  function uppercaseCheck(v = '', el){
    if(/(?=\S*?[A-Z])/.test(v)){
      el.classList.add('ValidUppercase');
      return true;
    } else {
      el.classList.remove('ValidUppercase');
      return false;
    }
  }

  function lowercaseCheck(v = '', el){
    if(/(?=\S*?[a-z])/.test(v)){
      el.classList.add('ValidLowercase');
      return true;
    } else {
      el.classList.remove('ValidLowercase');
      return false;
    }
  }

  function digitCheck(v = '', el){
    if(/(?=\S*?[0-9])/.test(v)){
      el.classList.add('ValidDigit');
      return true;
    } else {
      el.classList.remove('ValidDigit');
      return false;
    }
  }

  function whitespaceCheck(v = ''){
    return /^(\S*)$/.test(v);
  }
}

export function getErrorMessage(field) {
  // Get validity
  const validity = field.validity;

  // if(!validity) return 'Unknown error';

  // If field is required and empty
  if (validity.valueMissing) return 'Please fill out this field.';

  // If not the right type
  if (validity.typeMismatch) {

    // Email
    if (field.type === 'email') return 'Please enter an email address.';

    // URL
    if (field.type === 'url') return 'Please enter a URL.';
  }

  // If too short
  if (validity.tooShort) return 'Please lengthen this text to ' + field.getAttribute('minLength') + ' characters or more. You are currently using ' + field.value.length + ' characters.';

  // If too long
  if (validity.tooLong) return 'Please shorten this text to no more than ' + field.getAttribute('maxLength') + ' characters. You are currently using ' + field.value.length + ' characters.';

  // If number input isn't a number
  if (validity.badInput) return 'Please enter a number.';

  // If a number value doesn't match the step interval
  if (validity.stepMismatch) return 'Please select a valid value.';

  // If a number field is over the max
  if (validity.rangeOverflow) return 'Please select a value that is no more than ' + field.getAttribute('max') + '.';

  // If a number field is below the min
  if (validity.rangeUnderflow) return 'Please select a value that is no less than ' + field.getAttribute('min') + '.';

  // If pattern doesn't match
  if (validity.patternMismatch) {

    // If pattern info is included, return custom error
    if (field.hasAttribute('title')) return field.getAttribute('title');

    // Otherwise, generic error
    return 'Please match the requested format.';
  }

  if (validity.customError) {
    return field.validationMessage;
  }

  // If all else fails, return a generic catchall error
  return 'The value you entered for this field is invalid.';
}

function setCustomValidity(element, message){
  if(element.setCustomValidity){
    element.setCustomValidity(message);
  } else {
    // console.trace(element, message);
  }
}
