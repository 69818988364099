<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'RbvHotelRfpBidManagerSetFinalAgreementPendingActionReport',
    mixins: [ ActionReportMixin ],
    data () {
      return {
        messages: {
          'OK': 'Pending Final Agreement Set',
          'NOT_FOUND': `Bid was not found`,
          'STATUS_CHANGED': 'Bid status was changed',
          'ERROR': 'Final Agreement Pending not set due to unknown error'
        }
      }
    },
    computed: {
      title () { return this.items.length === 1 ? 'Pending Final Agreement not set!' : 'There were errors...' },
      message () {
        return this.items.length === 1
          ? 'We were not able to set Final Agreement Pending states because of the reason listed below:'
          : `${this.items.length - this.failures} of ${this.items.length} successfully set to Final Agreement Pending state.<br /> We were not able to set others because of the reasons listed below:`
      }
    }
  }
</script>
