<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler($event)"
  >
    <span slot="header">Configure User Defined Question</span>

    <div
      slot="body"
      class="vModalDefaultBody"
    >
      <div :class="$style.heading">
        Question number: {{ template.ord }}
      </div>

      <rb-input
        for-id="Name"
        :error="$showError('Name')"
      >
        <span slot="label">Label</span>

        <input
          id="Name"
          v-model="formModel.name"
          v-validate="'required|max:100'"
          type="text"
          name="Name"
          class="rbInput"
        >
      </rb-input>

      <rb-input
        for-id="Description"
        :error="$showError('Description')"
      >
        <span slot="label">Description/Tooltip</span>

        <input
          id="Description"
          v-model="formModel.description"
          v-validate="'max:250'"
          type="text"
          name="Description"
          class="rbInput"
        >
      </rb-input>

      <rb-input>
        <span slot="label">Type</span>

        <rb-select
          class="simple"
          :value="formModel.type"
          @change="onTypeChanged"
        >
          <rb-option value="TEXT">
            Text
          </rb-option>

          <rb-option value="NUMBER">
            Number
          </rb-option>

          <rb-option value="LIST">
            List
          </rb-option>

          <rb-option value="DATE">
            Date
          </rb-option>
        </rb-select>
      </rb-input>

      <template v-if="formModel.type === 'TEXT'">
        <rb-input
          for-id="textSize"
          :error="$showError('textSize')"
        >
          <span slot="label">Max Size</span>

          <input
            id="textSize"
            key="inputTextSize"
            v-model="formModel.validations.max"
            v-validate.initial="'required|decimal:0|between:1,500'"
            type="text"
            name="textSize"
            class="rbInput"
            data-vv-as="Max Size"
          >
        </rb-input>
      </template>

      <template v-else-if="formModel.type === 'NUMBER'">
        <rb-input
          for-id="numberDecimals"
          :error="$showError('numberDecimals')"
        >
          <span slot="label">Decimals</span>

          <input
            id="numberDecimals"
            key="numberDecimals"
            v-model="formModel.validations.decimal"
            v-validate.initial="'required|decimal:0|between:0,6'"
            type="text"
            name="numberDecimals"
            class="rbInput"
            data-vv-as="Decimals"
          >
        </rb-input>


        <rb-input
          for-id="numberMin"
          :error="$showError('numberMin')"
        >
          <span slot="label">Min</span>

          <span slot="hint">(to -2.000.000.000)</span>

          <input
            id="numberMin"
            v-model="formModel.validations.min_value"
            v-validate.initial="'required|decimal:0|between:-2000000000,2000000000'"
            type="text"
            name="numberMin"
            class="rbInput"
            data-vv-as="Min"
          >
        </rb-input>


        <rb-input
          for-id="numberMax"
          :error="$showError('numberMax')"
        >
          <span slot="label">Max</span>

          <span slot="hint">(to 2.000.000.000)</span>

          <input
            id="numberMax"
            key="numberMax"
            v-model="formModel.validations.max_value"
            v-validate.initial="'required|decimal:0|between:-2000000000,2000000000'"
            type="text"
            name="numberMax"
            class="rbInput"
            data-vv-as="Max"
          >
        </rb-input>
      </template>

      <template v-else-if="formModel.type === 'LIST'">
        <div
          class="layout-row"
          :class="$style.heading"
        >
          <div class="flex-100">
            Options
          </div>

          <div>
            <button
              type="button"
              class="rbButton smallIcon"
              @click="addOption()"
            >
              <i class="material-icons">add</i>
            </button>

            <div class="rbTooltip simple">
              Add List Option
            </div>
          </div>
        </div>

        <div :class="$style.optionsList">
          <div :class="$style.headerRow">
            <div class="msGridColumn1">
              Label
            </div>

            <div class="msGridColumn3">
              Value
            </div>

            <div class="msGridColumn5" />
            <div class="msGridColumn7" />
            <div class="msGridColumn9" />
          </div>

          <div
            v-for="(option, index) in formModel.options"
            :key="'inputListKey'+index"
            :class="$style.bodyRow"
          >
            <div class="msGridColumn1">
              <input
                :key="'inputListLabel'+index"
                v-model="option.label"
                v-validate="'required|max:50'"
                :class="errors.has('label_'+index) ? $style.optionsInputError : $style.optionsInput"
                :name="'label_'+index"
                data-vv-as="Label"
              >

              <div
                v-if="errors.has('label_'+index)"
                class="rbTooltip simple error"
              >
                {{ errors.first('label_'+index) }}
              </div>
            </div>

            <div class="msGridColumn3">
              <input
                :key="'inputListValue'+index"
                v-model="option.value"
                v-validate="{ required:true, max:20, unique_included: formModel.validations.included }"
                :class="errors.has('value_'+index) ? $style.optionsInputError : $style.optionsInput"
                :name="'value_'+index"
                data-vv-as="Value"
                @change="onOptionValueChanged(index, $event.target.value)"
              >

              <div
                v-if="errors.has('value_'+index)"
                class="rbTooltip simple error"
              >
                {{ errors.first('value_'+index) }}
              </div>
            </div>

            <div class="msGridColumn5">
              <button
                v-if="index !== formModel.options.length-1"
                type="button"
                class="rbButton smallIcon"
                style="transform: rotate(90deg)"
                @click="moveOption(index, index+1)"
              >
                <i class="material-icons">forward</i>
              </button>
            </div>

            <div class="msGridColumn7">
              <button
                v-if="index !== 0"
                type="button"
                class="rbButton smallIcon"
                style="transform: rotate(-90deg)"
                @click="moveOption(index, index-1)"
              >
                <i class="material-icons">forward</i>
              </button>
            </div>

            <div class="msGridColumn9">
              <button
                v-if="formModel.options.length > 1"
                type="button"
                class="rbButton smallIcon"
                @click="removeOption(index)"
              >
                <i class="material-icons">delete</i>
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="formModel.type === 'DATE'">
        <rb-input
          for-id="dateMin"
          :error="$showError('dateMin')"
        >
          <span slot="label">Min Date</span>

          <rb-input-date
            id="dateMin"
            key="inputDateMin"
            v-model="formModel.validations.after"
            v-validate="'rb_date_format:yyyy-MM-dd,MM/dd/yyyy'"
            type="text"
            name="dateMin"
            class="rbInput"
            data-vv-as="Date Min"
          />
        </rb-input>

        <rb-input
          for-id="dateMax"
          :error="$showError('dateMax')"
        >
          <span slot="label">Max Date</span>

          <rb-input-date
            id="dateMax"
            key="inputDateMax"
            v-model="formModel.validations.before"
            v-validate="'rb_date_format:yyyy-MM-dd,MM/dd/yyyy'"
            type="text"
            name="dateMax"
            class="rbInput"
            data-vv-as="Date Max"
          />
        </rb-input>
      </template>
    </div>

    <div slot="footer">
      <div class="vModalDefaultFooter layout-row">
        <button
          type="button"
          class="rbButton"
          @click="removeQuestion()"
        >
          Remove Question
        </button>

        <button
          type="button"
          class="rbButton"
          :class="$style.leftAuto"
          @click="cancel()"
        >
          Cancel
        </button>

        <button
          type="submit"
          class="rbButton mainButton"
        >
          Apply
        </button>
      </div>
    </div>
  </modal-form>
</template>

<script>
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import RbSelect from 'vRoot/_core/RbSelect.vue'
  import RbInput from 'vRoot/_core/RbInput.vue'
  import RbInputDate from 'vRoot/_core/RbInputDate.vue'


  export default {

    name: 'RbTestDialog',


    components: { ModalForm, RbInput, RbSelect, RbInputDate},


    mixins: [ DialogMixin, RbFormMixin ],


    props: {
      'template': {
        type: Object,
        default: () => ({}),
      },

      'model': {
        type: Object,
        default: () => ({
          _default: true,
        }),
      },
    },


    data () {
      return {
        isUserDefined: true,
        formModel: { validations: {} },
        test: undefined
      }
    },


    created () {
      if(!this.model._default){
        this.formModel = JSON.parse(JSON.stringify(this.model));
      } else {
        this.initializeTextQuestionModel(JSON.parse(JSON.stringify(this.template)));
      }
    },

    methods: {
      onSubmit () { return { model: this.formModel } },

      onTypeChanged (value) {
        switch (value){
          case 'NUMBER':
            return this.initializeNumberQuestionModel(this.formModel);
          case 'LIST':
            return this.initializeListQuestionModel(this.formModel);
          case 'DATE':
            return this.initializeDateQuestionModel(this.formModel);
          case 'TEXT':
          default:
            return this.initializeTextQuestionModel(this.formModel);
        }
      },

      initializeNumberQuestionModel(formModel){
        if(this.formModel.type !== 'NUMBER'){
          this.formModel = Object.assign( {}, formModel, {
            type: 'NUMBER',
            options: undefined,
            validations: { decimal: 0, min_value: 0, max_value: 2000000000 }
          })
        }
      },

      initializeListQuestionModel(formModel){
        if(this.formModel.type !== 'LIST'){
          this.formModel = Object.assign( {}, formModel, {
            type: 'LIST',
            options: [{'value': 'Y', 'label': 'Yes'},{'value': 'N', 'label': 'No'}],
            validations: { 'included': ['Y', 'N'] }
          })
        }
      },

      initializeDateQuestionModel(formModel){
        if(this.formModel.type !== 'DATE'){
          this.formModel = Object.assign( {}, formModel, {
            type: 'DATE',
            options: undefined,
            validations: {}
          })
        }
      },

      initializeTextQuestionModel(formModel){
        if(this.formModel.type !== 'TEXT'){
          this.formModel = Object.assign( {}, formModel, {
            type: 'TEXT',
            options: undefined,
            validations: { max: 100 }
          })
        }
      },

      addOption(){
        this.formModel.options.push({ value:'', label: '' });
        this.formModel.validations.included.push('');
      },

      removeOption(index){
        this.formModel.options.splice(index, 1);
        this.formModel.validations.included.splice(index, 1);
      },

      moveOption(fromIndex, toIndex){
        const
          options = this.formModel.options,
          includedValidation = this.formModel.validations.included;

        if(toIndex >= 0 && toIndex < options.length){
          options.splice(toIndex, 0, options.splice(fromIndex, 1)[0]);
          includedValidation.splice(toIndex, 0, includedValidation.splice(fromIndex, 1)[0]);
        }
      },

      onOptionValueChanged (index, value) {
        this.formModel.validations.included[index] = value;
      },

      removeQuestion () {
        this.hide('REMOVED');
      }
    }
  }
</script>

<style lang="stylus" module >
  @import '~vRoot/_styles/brackets.styl'

  .leftAuto {
    margin-left: auto !important
  }

  .dialog {
    width: 450px;
  }

  .heading {
    color: #546e7a;
    font-size: 12px;
    line-height: 32px;
    font-weight: bold;
  }

  .optionsList {
    overflow auto
    max-height: 220px;
    min-height: 150px;
  }

  .row {
    display: grid;
    grid-template-columns b(label) 1fr b(value) .6fr b(up) 30px b(down) 30px b(delete) 30px
    grid-column-gap: 5px;

    display: -ms-grid;
    -ms-grid-columns: 1fr 5px .6fr 5px 30px 5px 30px 5px 30px;
  }

  .headerRow {
    composes row
    height: 25px;
    line-height: 25px;
    position: sticky;
    top: 0;
    z-index: 1;

    color #546E7A
    font-size 12px
    background #ECEFF1
    border-bottom 1px solid #D9E0E3
  }

  .bodyRow {
    composes row
    height: 27px;
    margin: 5px 0;
  }

  .optionsInput {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 27px;
    margin: 0;
    border: 0;
    border-bottom: 1px solid #d9e0e3;
    padding: 0 5px;

    color: #78909C;
    font-size: 12px;
    font-family "Noto Sans", sans-serif

    &:focus {
      border-color: rgba(0, 184, 171, .8);
      color: #00B8AB;
    }
  }

  .optionsInputError {
    composes optionsInput
    border-color: rgba(241, 90, 36, .8);
    color: #F15A24;

    &:focus {
      border-color: rgba(241, 90, 36, .8);
      color: #F15A24;
    }
  }
</style>
