import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
import noop from 'lodash/noop';
import SendResponsesActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendResponsesActionReport.vue';
import {Dialog as VueDialog} from 'root/v-app/rbServices';
import ForceSendingInvalidResponsesQuestion
  from 'vRoot/rfp-hotel/bid-manager/actions/questions/ForceSendingInvalidResponsesQuestion.vue';

export default function CreateSendCompetitiveBidResponse(bidManagerService){
  return function SendCompetitiveBidResponses(report){
    return {
      label: `Send Response${ report.bids.length === 1 ? '' : 's' }`,
      icon: 'textsms',
      isAvailable: () => !report.statuses.find(s => s !== 'COMPETITIVE_RECEIVED_ACCEPTED'),
      action: () => {
        sendResponsesToAPI(report.bids, report.bids.map( b => b._id ))
          .then(httpResponse => bidManagerService.handleActionResult(httpResponse, report.bids, SendResponsesActionReport))
          .catch(noop);

        function sendResponsesToAPI(bidsToSend, bidsIds, forceSending) {
          return notificationUtils().onSave(() => mainAPI().setCompetitiveBidResponses(bidsIds, forceSending), {
            customErrorHandlers: {
              400(error) {
                return (error.data && error.data.id === 'RESPONSE_NOT_VALID')

                  ? VueDialog.show(ForceSendingInvalidResponsesQuestion, { isBulk: bidsToSend.length > 1 })
                    .then(() => sendResponsesToAPI(bidsToSend, bidsIds, true))

                  : notificationUtils.handleErrors(error);
              }
            }
          });
        }
      }
    }
  }
}