<template>
  <transition name="vNotification">
    <div class="vNotificationWrapper"
         v-show="shown"
         :style="zIndex"
         @click="handleOverlayClicked($event)">
      <div :class="type"></div>
    </div>
  </transition>
</template>

<script>
  import Vue from "vue";
  import { Notification } from "root/v-app/rbServices";
  import { $newScope } from "root/angular-injector-provider";

  export default {
    name: 'rb-notification-wrapper',
    props: [ 'dialog', 'ord', 'options', 'type' ],
    data () { return { shown: false, vInstance: null } },
    computed: { zIndex () { return { zIndex: this.ord * 1000}} },
    methods: {
      handleOverlayClicked ($event) {
        this.options.closeOnOutsideClick && $event.target.classList.contains("vNotificationWrapper") && this.cancel()
      },
      cancel (reason) { this.waitForAnimation( () => { Notification.cancel(reason) } ) },
      hide (reason) { this.waitForAnimation( () => { Notification.hide(reason) } ) },
      waitForAnimation (action){
        this.shown = false;
        window.setTimeout(action, 200)
      }
    },
    mounted () {
      document.activeElement && document.activeElement.blur();
      const
        dElement = this.$el.querySelector(`.${this.type}`),
        self = this;

      this.vInstance = new Vue({
        el: dElement,
        render: createElement => createElement(self.dialog.component, {
          props: self.dialog.data,
          on: {
            cancel ( reason ) { self.cancel(reason) },
            hide ( reason ) { self.hide(reason) }
          }
        })
      });
      this.ngScope = $newScope();
      this.ngScope.$on('$stateChangeStart', () => { Notification.cancel() });
      this.$nextTick(() => { this.shown = true })
    },
    destroyed () {
      this.vInstance && this.vInstance.$destroy();
      this.ngScope && this.ngScope.$timeout(() => this.ngScope.$destroy());
      this.$el.remove()
    }
  }
</script>

<style lang="stylus">

  .vNotificationWrapper {
    position fixed
    bottom 15px
    right 15px
    min-height fit-content
    min-width fit-content

    display flex
    align-items center
    justify-content center
    overflow hidden

    transition: all .2s ease-in-out;

    & + .vNotificationWrapper {
      background rgba(0, 0, 0, 0.05)
    }
  }

  .vModal { transition: all .2s ease-in-out; }

  .vNotification-enter, .vNotification-leave-to { opacity: 0; }
  .vNotification-enter-to, .vNotification-leave { opacity: 1; }

  .vNotification-enter .vModal,
  .vNotification-leave-to .vModal {
    transform: scale(1.1);
  }

  .snackbar{
    background #00A99D;
  }

</style>

