import Vue from 'vue'
import { noop } from 'lodash'

import QuestionnaireView from './QuestionnaireView.vue'

export default {
  template: '<div></div>',
  bindings: {
    questionnaire: '<'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'DataServer']
function Ctrl($element, dataServer){
  const vm = this,
    getTemplatePromise = dataServer.getQuestionnaire(),
    questionnaire = {
      template: {},
      config: [],
      globals: {},
      model: {},
      response: {}
    }

  let vueComponent

  this.$onDestroy = () => { vueComponent && vueComponent.$destroy() }

  this.$onChanges = ( changes ) => {
    if(changes.questionnaire && changes.questionnaire.currentValue) {
      vm.questionnaire = changes.questionnaire.currentValue

      questionnaire.config = vm.questionnaire.config
      questionnaire.globals = vm.questionnaire.globals
      questionnaire.model = vm.questionnaire.model
      questionnaire.response = vm.questionnaire.response

      getTemplatePromise
        .then(initVueComponent, noop)
    }
  }

  function initVueComponent(template){
    !vueComponent && initVue()

    function initVue(){
      questionnaire.template = template
      vueComponent = new Vue({
        el: $element[0],
        data: {
          questionnaire: questionnaire
        },
        render: function (createElement) {
          return createElement(QuestionnaireView, {
            props: { questionnaire: questionnaire }
          })
        }
      })
    }
  }
}
