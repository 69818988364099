<script>
  /* eslint-disable no-unused-vars,no-unused-labels */
  import Store from './RbTravelDestinationGlobalSearchDialogStoreAdapter';
  import {notificationUtils} from 'root/angular-injector-provider';
  import GlobalSearch
    from './RbTravelDestinationGlobalSearchComponent.svelte';

  // eslint-disable-next-line prefer-const
  export let destination = {}, rfpId, cancel;

  const searchData = new Store(rfpId, destination.id);

  function addToBidManager(hotelsIds){
    notificationUtils().onSave( async function() {
      for (const hotelId of hotelsIds) {
        // eslint-disable-next-line no-await-in-loop
        await searchData.createBid(hotelId);
        searchData.removeSelected(hotelId);
      }
    })
  }
</script>


<GlobalSearch {cancel} {searchData} onSave="{addToBidManager}" onSaveLabel="Add to Bid Manager" />
