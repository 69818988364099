import HTML from './upload-responses.html'
import './upload-responses.scss'
import {logError} from 'root/log/log.js';
import {fileToJson} from 'root/shared/tools/xls.service';
import noop from 'lodash/noop';

const NAME = 'uploadHotelRfpResponses';

export { NAME, HTML, uploadHotelRfpResponsesController as Controller }

uploadHotelRfpResponsesController.$inject = [ 'rbDialog', '$scope', 'NotificationUtils', 'Bids', 'MainAPI' ];
function uploadHotelRfpResponsesController( dialog, $scope, notificationUtils, bids, mainAPI ) {

  const vm = this;
  vm.cancel = dialog.cancel;
  vm.state = null;

  vm.onFileSelected = () => {
    if (vm.files && vm.files.length) {
      vm.progress = 0;
      vm.state = 'WORKING';

      fileToJson(vm.files[0])
        .then(saveDraftResponses, showError)
        .then(dialog.hide)
        .catch(noop)

    } else {
      showError();
    }

    function showError ( error ) {
      vm.state = 'ERROR';
      logError(error);
      return Promise.reject('ERROR')
    }

    function saveDraftResponses(responses){
      return notificationUtils.onSave( () => mainAPI.saveDraftResponses(bids.map( b => b._id ), responses) , {
        customErrorHandlers: { 400 (error) { return showError(error) } }
      })
    }
  }
}
