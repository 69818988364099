import throttle from 'lodash/throttle';

export default function ScrollService(containerClass = 'DocumentViewContentContainer', submenu = [], callback){
  const
    scrollContainer = getContainer(containerClass),
    richSubmenu = getTargets(scrollContainer, submenu),
    scrollUpdateFn = throttle(scrollUpdate(scrollContainer, richSubmenu, callback), 200);

  scrollUpdateFn();
  scrollContainer.addEventListener('scroll', scrollUpdateFn);
  return () => scrollContainer.removeEventListener('scroll', scrollUpdateFn);
}

function scrollUpdate(scrollContainer, submenuTemplate, callback){
  return () => {
    const activeId = determineActiveId(scrollContainer, submenuTemplate);
    callback(submenuTemplate.map(i => ({ ...i, active: activeId === i.id })));
  }
}

function determineActiveId(scrollContainer, targets){
  const scrollTop = scrollContainer.getBoundingClientRect().top;
  let activeId = null;
  targets.forEach(t => {
    const elementTop = t.element.getBoundingClientRect().top;
    activeId = !activeId || elementTop - scrollTop <= 10 ? t.id : activeId;
  });

  return activeId;
}

function getContainer(containerClass) {
  const containers = document.getElementsByClassName(containerClass);
  if(!containers.length) throw new Error('Scroll Container not found!');
  return containers[0];
}

function getTargets(container, submenu){
  return submenu
    .map(item => {
      const element = container.querySelector(`#${item.id}`);
      return {...item, element, action: element ? () => element.scrollIntoView(true) : () => {}}
    })
    .filter(i => i.element);
}