<script>
  import { get, isEqual, noop } from "lodash";
  import "./set-supplier-contact.scss";
  import { NAME as dueDateExpiredNotification } from "root/rfp/bid-manager/main/dialogs/due-date-expired/due-date-expired.ctrl";
  import {
    mainAPI,
    notificationUtils,
    ngDialog, currentUser
  } from "root/angular-injector-provider";
  import Spinner from "root/components/atoms/Spinner.svelte";
  import SupplierContactComponent from "./SupplierContactComponent.svelte";
  import {
    ID_OF_NEW,
    TYPE_HOTEL,
    TYPE_CHAIN
  } from "./constants";
  import {SvelteDialog} from "root/v-app/rbServices";
  import SignUpForFixMyEmailServiceModal
    from "rfp/bid-manager/main/ui/rb-bid-manager-table/cells/email/SignUpForFixMyEmailServiceModal.svelte";

  export let hide, cancel, bids;

  let allRepresentatives = [];

  let configuration = {};
  let active = {};
  let loading = true;
  let
    isUserInEmailAddressFixService = true,
    setSupplier,
    canSave;

  const isNew = user => user.id === ID_OF_NEW;

  onInit();

  $: setSupplier = configuration.selected[active.type];
  $: supplierHasChanged = !same(configuration.supplier[active.type], setSupplier);
  $: canSave = setSupplier && ['BOUNCED', 'COMPLAINT'].indexOf(setSupplier.emailAddressStatus) === -1
  $: representatives = active ? filterRepresentatives(allRepresentatives, active.type, configuration): [];

  function onInit() {
    currentUser().hasFeature('EMAIL_ADDRESS_FIX_SERVICE').then(status => {
      isUserInEmailAddressFixService = status;
    });
    const report = loadBids(bids);
    configuration = loadConfiguration(report);
    loadRepresentatives();
  }

  function loadRepresentatives() {
    const hotelId = configuration.hotelId,
      chainId = configuration.chainId,
      loadRepresentativesFn = hotelId
        ? () => mainAPI().listHotelContacts(hotelId)
        : () => mainAPI().listChainContacts(chainId);

    notificationUtils()
      .onLoad(loadRepresentativesFn)
      .then(response =>
        parseLoadRepresentativesResponse(response, configuration.selected)
      )
      .then(reps => {
        allRepresentatives = reps;
        loading = false;
        return configuration.tabs.init === TYPE_CHAIN ? toChain() : toHotel();
      });
  }

  function select(user) {
    configuration = {
      ...configuration,
      selected: {
        ...configuration.selected,
        [active.type]: user
      }
    }
  }

  function isSelected({id}) {
    return setSupplier && setSupplier.id === id;
  }

  function toHotel() {
    active = {
      type: TYPE_HOTEL,
      hotelClass: "tab active",
      chainClass: "tab",
      header: "Hotel",
      hotelRadioIcon: "radio_button_checked",
      chainRadioIcon: "radio_button_unchecked"
    };
  }

  function toChain() {
    active = {
      type: TYPE_CHAIN,
      hotelClass: "tab",
      chainClass: "tab active",
      header: "Chain",
      message: true,
      hotelRadioIcon: "radio_button_unchecked",
      chainRadioIcon: "radio_button_checked"
    };
  }

  function save() {
    if (setSupplier) {
      notificationUtils()
        .onSave(() => saveSelected(setSupplier))
        .then(hide);
    }
  }

  function same(original, selected) {
    return original && selected && original.id === selected.id;
  }

  function saveSelected(selected) {
    return bids.length === 1 ? saveToBid() : saveToBids();

    function saveToBid() {
      const bidId = bids[0]._id;
      return selected.isUser
        ? mainAPI().setBidSupplierContact(bidId, selected.id)
        : mainAPI().createBidSupplierContact(bidId, selected);
    }

    function saveToBids() {
      const bidsIds = bids.map(b => b._id);
      return selected.isUser
        ? mainAPI().setSupplierContactToBids(bidsIds, selected.id)
        : mainAPI().createSupplierContactInBids(bidsIds, selected);
    }
  }

  function saveAndSend() {
    if (setSupplier) {
      sendBidsToSelected(bids.map(b => b._id), setSupplier)
        .then(hide)
        .catch(noop);
    }
  }

  function sendBidsToSelected(bidsIds, contact, ignoreDueDate) {
    const actionFn =
      ["CREATED", "SENT", "RECEIVED", "NOT_INTERESTED"].indexOf(
        configuration.status
      ) > -1
        ? sendBidsToSupplier()
        : changeSupplier();

    function changeSupplier() {
      return contact.isUser
        ? () =>
            mainAPI().sendBidsChangeToSelectedContact(
              bidsIds,
              contact.id,
              !!ignoreDueDate
            )
        : () =>
            mainAPI().sendBidsChangeToNewContact(
              bidsIds,
              contact,
              !!ignoreDueDate
            );
    }

    function sendBidsToSupplier() {
      return contact.isUser
        ? () =>
            mainAPI().sendBidsToSelectedContact(
              bidsIds,
              contact.id,
              !!ignoreDueDate
            )
        : () =>
            mainAPI().sendBidsToNewContact(bidsIds, contact, !!ignoreDueDate);
    }

    return notificationUtils().onSave(actionFn, {
      customErrorHandlers: {
        400(error) {
          if (error.data && error.data.id === "DUE_DATE_EXPIRED") {
            return ngDialog()
              .show(dueDateExpiredNotification, {
                locals: { expiredDueDate: error.data.value }
              })
              .then(() => sendBidsToSelected(bidsIds, contact, true));
          } else {
            return notificationUtils().handleErrors(error);
          }
        }
      }
    });
  }

  function getSaveAndSendLabel() {
    const action =
        configuration.status === "CREATED"
          ? "Send"
          : configuration.status === "SENT"
          ? "Resend"
          : "Send/Resend",
      bidsText = configuration.multipleBids ? "Bids" : "Bid",
      s = supplierHasChanged ? "Save &" : "";
    return `${s} ${action} ${bidsText}`;
  }

  function createNewContact() {
    return hide({
      create: true,
      user: { accountType: active.type, isUser: false, id: ID_OF_NEW }
    });
  }

  function edit(user) {
    return hide({ edit: true, user });
  }

  function loadBids(bids) {
    const config = {
      multipleBids: bids.length > 1,
      status: null,
      hotelId: null,
      chainId: null,
      chainSupport: null,
      selected: { HOTEL: null, CHAIN: null },
      supplier: { HOTEL: null, CHAIN: null },
      bids: []
    };

    bids.forEach(b => loadBid(config, b));

    return config;

    function loadBid(config, bid) {
      config.status =
        (config.status === null || config.status === bid.state.status) &&
        bid.state.status;
      config.hotelId =
        (config.hotelId === null ||
          config.hotelId === bid.supplier.company.entityId) &&
        bid.supplier.company.entityId;
      config.chainId =
        bid.supplier.company.chain.master &&
        (config.chainId === null ||
          config.chainId === bid.supplier.company.chain.master._id) &&
        bid.supplier.company.chain.master._id;
      config.chainSupport =
        config.chainSupport === null
          ? bid.rfp.specifications.chainSupport
          : config.chainSupport && bid.rfp.specifications.chainSupport;
      config.bids.push({ rfpId: bid.rfp._id, bidId: bid._id });
      updateSelectedSupplier();

      function updateSelectedSupplier() {
        const bidSupplierContact = bid.supplier.contact;

        if (bidSupplierContact) {
          updateSelected(config, TYPE_HOTEL, bidSupplierContact);
          updateSelected(config, TYPE_CHAIN, bidSupplierContact);
        }
      }

      function updateSelected(config, type, contact) {
        if (contact.company && contact.company.type === type) {
          const selected = config.selected[type];
          config.selected[type] =
            (selected === null || isEqual(selected, contact)) && contact;
          config.supplier[type] =
            (selected === null || isEqual(selected, contact)) && contact;
        }
      }
    }
  }

  function loadConfiguration(report) {
    const config = Object.assign({}, report, {
      tabs: {
        shown: report.hotelId && report.chainId && report.chainSupport,
        init:
          !report.hotelId || (report.selected && report.selected[TYPE_CHAIN])
            ? TYPE_CHAIN
            : TYPE_HOTEL
      }
    });
    config.selected[TYPE_HOTEL] = normalizeSupplierContact(
      config.selected[TYPE_HOTEL]
    );
    config.selected[TYPE_CHAIN] = normalizeSupplierContact(
      config.selected[TYPE_CHAIN]
    );

    return config;
  }

  function normalizeSupplierContact(supplierContact) {
    if (!supplierContact) return undefined;
    return {
      ...supplierContact,
      lastActive: undefined,
      accountId: supplierContact.company.accountId,
      entityId: supplierContact.company.entityId,
      accountType: supplierContact.company.type,
      accountName: supplierContact.company.name
    };
  }

  function parseLoadRepresentativesResponse(
    httpResponse,
    selectedRepresentatives
  ) {
    const reps = httpResponse.data;

    updateRepsWithSelected(selectedRepresentatives[TYPE_CHAIN]);
    updateRepsWithSelected(selectedRepresentatives[TYPE_HOTEL]);

    return reps;

    function updateRepsWithSelected(selected) {
      if(!selected) return;
      const rIndex = reps.findIndex(r => r.emailAddress === selected.emailAddress && r.accountType === selected.accountType);
      if(rIndex > -1){
        const rep = reps.splice(rIndex, 1)[0];
        selected.emailAddressStatus = rep.emailAddressStatus;
        selected.lastActive = rep.lastActive;
      }
      reps.unshift(selected);
    }
  }

  function filterRepresentatives(reps, type) {
    return reps
      .filter(r => r.accountType === type)
      .map(user => ({
        ...user,
        selected: isSelected(user),
        sortString: `${['BOUNCED', 'COMPLAINT'].indexOf(user.emailAddressStatus) === -1 ? 1 : 9} ${user.isUser ? 1 : 9} ${user.firstName} ${user.lastName}`.toLowerCase()
      }))
      .sort((a, b) => a.sortString > b.sortString ? 1 : (a.sortString < b.sortString ? -1 : 0));
  }

  function signUpForFixMyEmailService(){
    SvelteDialog.show(SignUpForFixMyEmailServiceModal)
      .then(() => {
        isUserInEmailAddressFixService = true;
      })
  }

</script>

<div id="set-supplier-contact" class="BG">
  <div class="Banner">
    {#if !loading && configuration.tabs.shown}
      <span>{active.header} Representatives</span>
      <div class="setSupplierContactTabContainer">
        <div
          class={active.hotelClass}
          on:click={toHotel}
          class:clickable={true}
          role="button">
          <div class="material-icons">{active.hotelRadioIcon}</div>
          <div>
            <span class="type">Hotel</span>
            Reps.
          </div>
        </div>
        <div
          class={active.chainClass}
          on:click={toChain}
          class:clickable={true}
          role="button">
          <div class="material-icons">{active.chainRadioIcon}</div>
          <div>
            <span class="type">Chain</span>
            Reps.
          </div>
          <div class="setSupplierContactChainsHeaderIcon">
            <i class="material-icons">error</i>
          </div>
          <span
            class="setSupplierContactChainsHeaderTooltip rbTooltip simple"
            tooltip="placement:top-end">
            ReadyBid assumes you have already contacted your chain
            representative and you have both agreed to a chain level RFP
            process. If this has not yet happened, please be sure you have taken
            such steps prior to sending your RFP. Thank You!
          </span>
        </div>
      </div>
    {/if}
  </div>

  {#if !loading }
    <div class="setSupplierContactSuppliersContainer">
      <div class="setSupplierContactSuppliers">
        {#if representatives.length > 0}
          <div class="setSupplierContactSuppliersHeader">
            <div>
              <div class="title">Select your <b>{active.header} Representative</b></div>
              <p class="sub">or Create a new one</p>
            </div>
            <button
              type="button"
              class="aBtn asLarge atLight"
              on:click={createNewContact}
              data-icon="person_add">
              Create {active.header} Rep.
            </button>
          </div>
        {/if}
        {#each representatives as contact}
          <SupplierContactComponent
            {contact}
            selected={contact.selected}
            on:select={() => select(contact)}
            on:edit={() => edit(contact)} />
        {/each}
        {#if representatives.length === 0}
          <div class="no-contacts">
            <h3>No Contacts</h3>
            <p>
              No {active.header}s have been added yet. Create a new one to send
              this bid.
            </p>
            <button
              type="button"
              class="aBtn asLarge atLight"
              on:click={createNewContact}
              data-icon="person_add">
              Create {active.header} Rep.
            </button>
          </div>
        {/if}
      </div>
    </div>
  {/if}

  {#if loading}
    <div class="Loading">
      <Spinner />
    </div>
  {:else}
    <div class="flex Footer layout-padding">
      <div>
        {#if !isUserInEmailAddressFixService}
          <button class="aBtn atLight asLarge asError"
                  data-icon="alternate_email"
                  on:click={signUpForFixMyEmailService}
          >
            Fix My Emails
          </button>
        {/if}
      </div>

      <div>
        <button type="button" class="aBtn asLarge atLight" on:click={cancel}>
          Cancel
        </button>
        {#if supplierHasChanged}
          <button
            type="button"
            class="aBtn asLarge atLight"
            disabled={!canSave}
            on:click={save}>
            Save
          </button>
        {/if}

        <button
          type="button"
          class="aBtn asLarge atLight asMain"
          disabled={!canSave}
          on:click={saveAndSend}>
          {getSaveAndSendLabel()}
        </button>
      </div>
    </div>
  {/if}
</div>


<style>
  .Footer {
    padding: 10px;
    text-align: right;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .BG {
    background: #ebeff2;
  }

  .Loading {
    margin: auto;
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
