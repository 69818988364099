import {writable, get} from 'svelte/store';
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
import sortService from 'root/v-components/travel-destinations-map/travel-destinations-map-table/suppliers-sort-service';
import noop from 'lodash/noop';

const store = writable({});

export default store;

export function initialize(rfpId, destinationId){
  store.set({
    rfpId,
    destinationId,
    suppliers: [],
    suppliersCount: 0,
    query: '',
    queryFilteredSuppliers: [],
    sort: sortService.getDefaultSort(),
    sortedSuppliers: []
  });
}

export function reload(filter) {
  const $s = get(store);

  notificationUtils().onLoad(() => mainAPI().geoSearchHotels($s.rfpId, $s.destinationId, filter))
    .then(r => {
      store.update($$s => {
        const queryFilteredSuppliers = filterSuppliersByQuery(r.data, $$s.query);
        return {
          ...$$s,
          suppliers: r,
          suppliersCount: r.data.length,
          queryFilteredSuppliers,
          sortedSuppliers: sortService.sort(queryFilteredSuppliers, $$s.sort.key, $$s.sort.order)
        }
      });
    })
    .catch(noop)
}

export function updateQuery(query){
  store.update($s => {
    const queryFilteredSuppliers = filterSuppliersByQuery($s.suppliers.data, query);
    return {
      ...$s,
      query,
      queryFilteredSuppliers,
      sortedSuppliers: sortService.sort(queryFilteredSuppliers, $s.sort.key, $s.sort.order)
    }
  });
}

export function updateSort(sortId){
  store.update($s => {
    const sort = {...$s.sort};
    sortService.updateSortObject(sort, sortId);
    return {
      ...$s,
      sort,
      sortedSuppliers: sortService.sort($s.queryFilteredSuppliers, sort.key, sort.order)
    }
  });
}

export function addSuppliersToTop(suppliers){
  store.update($s => {
    const mergedSuppliers = [...suppliers, ...($s.suppliers.data.filter(s => suppliers.findIndex(ns => ns.id === s.id) === -1))];
    return {
      ...$s,
      suppliers: {data: mergedSuppliers},
      suppliersCount: mergedSuppliers.length,
      queryFilteredSuppliers: [...suppliers, ...($s.queryFilteredSuppliers.filter(s => suppliers.findIndex(ns => ns.id === s.id) === -1))],
      sortedSuppliers: [...suppliers, ...($s.sortedSuppliers.filter(s => suppliers.findIndex(ns => ns.id === s.id) === -1))]
    }
  });
}

export function updateSupplier(supplier){
  store.update($s => {
    const mergedSuppliers = $s.suppliers.data.map(s => s.id === supplier.id ? supplier : s);
    const queryFilteredSuppliers = filterSuppliersByQuery(mergedSuppliers, $s.query);

    return {
      ...$s,
      suppliers: {data: mergedSuppliers},
      suppliersCount: mergedSuppliers.length,
      queryFilteredSuppliers,
      sortedSuppliers: sortService.sort(queryFilteredSuppliers, $s.sort.key, $s.sort.order)
    }
  });
}

export function getSupplierByHotelId(hotelId){
  const $s = get(store);
  return {...$s.suppliers.data.find(s => s.id === hotelId)};
}

export function createBid(hotelId){
  const $s = get(store);
  return mainAPI().createBid($s.rfpId, {travelDestinationId: $s.destinationId, hotelId});
}

export function removeBid(bidId){
  const $s = get(store);
  return mainAPI().removeBid($s.rfpId, bidId);
}


function filterSuppliersByQuery(suppliers, query){
    if(query) {
      return suppliers.filter(s => {
        let searchString = s.$$searchString;
        if (!searchString) {
          searchString = `${s.name} ${s.chain ? s.chain.name || '' : ''} ${s.location ? s.location.fullAddress || '' : ''}`.toLowerCase();
          s.$$searchString = searchString.toLowerCase();
        }

        return searchString.indexOf(query.toLowerCase()) > -1
      })
    } else {
      return suppliers;
    }
}