/**
 * Created by DejanK on 10/02/2017.
 */
import noop from 'lodash/noop';
import HTML from './ask-for-rate-loading-information.html';
import './ask-for-rate-loading-information.scss';

const NAME = 'askForRateLoadingInformation';

export { NAME, HTML, AskForRateLoadingInformationController as Controller };

AskForRateLoadingInformationController.$inject = ['NotificationUtils', 'EntityId', 'rbDialog', 'RateLoadingInformationRepository'];
function AskForRateLoadingInformationController(notificationUtils, entityId, dialog, rateLoadingInformationRepository){
  const vm = this;

  vm.submit = submit;
  vm.cancel = dialog.cancel;

  $onInit();

  function $onInit(){
    notificationUtils.onLoad( () => rateLoadingInformationRepository.get(entityId), vm, 'rateLoadingInformation')
      .catch( noop );
  }

  function submit($event){
    notificationUtils
      .onSave( () => rateLoadingInformationRepository.put(entityId, $event.model) )
      .then(dialog.hide)
      .catch( noop );
  }
}
