import { checkAndExecute } from 'root/api/helpers';

export default function HotelRfpBidAPI(api) {
  Object.assign(api, {

    getBidManagerViewWithViewId: viewId => checkAndExecute(
      () => viewId,
      () => api.server.get(`bid-managers/${viewId}`)
    ),

    getBidManagerViewWithRfpId: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`bid-managers/rfps/${rfpId}`)
    ),

    getBidManagerBids: query => checkAndExecute(
      () => query,
      () => api.server.post('rfps/bids/query', query)
    ),
    loadChainPortalBids: configs =>  checkAndExecute(
      () => configs,
      () => api.server.post('rfps/bids/custom-query', configs)
    )
  });
}
