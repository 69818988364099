<script>
  import {store, updateSettings} from './bids-sources';

  let
    focusComponent,
    menuDisplayed;

  function displayMenu(){
    focusComponent.focus();
    menuDisplayed = true;
  }

  function hideMenu(){
    focusComponent.focus();
    menuDisplayed = false;
  }

  function setSource(newSource){
    if(newSource.id !== $store.source.id) {
      updateSettings({source: newSource});
    }
  }
</script>

<div class="Component">
  <div class="Title" data-icon-before="menu" on:click={displayMenu}>
    {#if $store.source}
      {$store.source.title}
      <div class="rbTooltip">{$store.source.description}</div>
    {/if}
  </div>
  <input class="Hidden" on:blur={hideMenu} bind:this={focusComponent} />

  <div class="MenuContainer">
    {#if menuDisplayed}
      <div class="SourcesMenu">
        <div class="SourcesMenuTitle">
          Select Bids to display:
        </div>

        {#each $store.sources as source}
          <div class="SourcesMenuItem">
            <div class="SourcesMenuItemTitle"
                 class:Selected={$store.source && $store.source.id === source.id}
                 on:mousedown={() => setSource(source)}>
              {source.title}
            </div>
            <div>{source.description}</div>
          </div>
        {/each}
      </div>
    {/if}
  </div>
</div>

<style lang="stylus">.Component {
  flex: 1 1 100%;
}
.Title {
  font-size: 18px;
  font-weight: bold;
  color: inherit;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  cursor: pointer;
}
.Title:before {
  padding-right: 5px;
}
.Title:hover {
  text-shadow: none;
  color: #00a99d;
}
.Hidden {
  display: block;
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.MenuContainer {
  position: relative;
}
.SourcesMenu {
  position: absolute;
  top: 40px;
  left: 10px;
  z-index: 2;
  background: #fff;
  padding: 10px;
  border: 1px solid #808080;
  border-radius: 6px;
  box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
  font-size: 13px;
  color: #546e7a;
}
.SourcesMenuTitle {
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
}
.SourcesMenuItem {
  margin-top: 10px;
  opacity: 0.8;
}
.SourcesMenuItemTitle {
  font-size: 14px;
  font-weight: bold;
  opacity: 1;
  text-decoration: underline;
  cursor: pointer;
}
.SourcesMenuItemTitle:hover {
  color: #00a99d;
  text-decoration: none;
}
.SourcesMenuItemTitle.Selected {
  color: #00a99d;
  text-decoration: none;
  cursor: initial;
}
</style>