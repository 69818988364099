<template>
  <div class="pageWithHeaderBody userBackground layout-column">
    <div :class="$style.accountProfileContainer">
      <div :class="$style.accountProfileNavigation">
        <image-editor :is-admin="isAdmin" />
        <navigation />
      </div>
      <component
        :is="activeModuleComponent"
        v-if="activeModuleComponent"
        :state="state"
        :class="$style.accountProfileContent"
        :user-account="userAccount"
        :is-admin="isAdmin"
      />
    </div>
  </div>
</template>

<script>
  import accountProfileService from './accountProfileService'
  import ImageEditor from './image-editors/ImageEditors.vue'
  import Navigation from './navigation/AccountProfileNavigation.vue'
  import {PAGE_COMPANY_PROFILE} from 'root/states';
  import listUsersService, { ACCOUNT_ADMIN } from 'root/v-components/account-profile/modules/manage-users/services/account-manage-users-list.service';

  export default {
    name: 'RbvUserProfileComp',
    components: { ImageEditor, Navigation },
    props: {
      accountId: {
        required: true,
        type: String
      },
      accountType: {
        required: true,
        type: String
      }
    },
    asyncComputed:{
      activeModuleComponent () {
        return accountProfileService.initialize(this.accountType, this.accountId)
            .then( module => module && module.component )
      },
      userAccount() { return listUsersService.loadAuthorization().then(({data}) => data)},
    },
    data() {
      return {
        state: PAGE_COMPANY_PROFILE
      }
    },
    computed: {
      role() { return this.userAccount && this.userAccount.role && this.userAccount.role.permission; },
      isAdmin() { return this.role === ACCOUNT_ADMIN },
    }
  }
</script>

<style lang="stylus" module>

  .accountProfileContainer {
    box-sizing: border-box;
    flex: 1 1 100%;
    margin: 20px auto;
    display: flex;
    max-width: 95%;
  }

  .accountProfileNavigation {
    width: 304px;
    background: white;
    margin-right: 1px;
    border-radius: 6px 0 0 6px;
    box-shadow : 0 0 3.14px rgba(0, 0, 0, 0.18);
  }

  .accountProfileContent {
    background: white;
    min-width : 624px;
    border-radius : 0 6px 6px 0;
    box-shadow : 0 0 7.53px rgba(0, 0, 0, 0.18);
  }

</style>
