<script>
  import CodeInput from 'root/public-auth/dialogs/multi-factor-authentication/codeInput.svelte';

  export let code = '', error = '', checking = false;
</script>

<div class="Title">Enter the code from your Authentication App</div>

<section>
  <CodeInput bind:code {checking} {error}/>
</section>
