<template>
  <input type="url" :value="viewValue" @input="onInput" @focus="onFocus" @blur="onBlur"/>
</template>

<script>
  export default {
    name: 'RbvInputUrl',
    $_veeValidate: {
      value () { return this.modelValue }
    },
    props: ['value'],
    data(){
      return {
        focused: false,
        modelValue: undefined,
        viewValue: undefined
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(v) {
          if(!this.focused){
            this.modelValue = v
            this.modelFormat()
            this.modelRender()
          }
        }
      }
    },
    methods: {
      modelFormat() {},
      modelRender() { this.viewValue = this.modelValue },
      modelParse(){
        this.modelValue = !this.viewValue || !this.viewValue.length || this.viewValue.match(/^[a-zA-Z]+?:\/\//) ? this.viewValue : `http://${this.viewValue}`
      },
      modelEmit(){
        this.$emit('input', this.modelValue);
        this.$emit('change', this.modelValue);
      },
      onBlur () {
        this.focused = false
        this.$emit('blur', this.modelValue);
      },
      onFocus(){
        this.focused = true
      },
      onInput(ev){
        this.viewValue = ev.target.value
        this.modelParse()
        this.modelEmit()
      }
    }
  }
</script>
