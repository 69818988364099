import get from 'lodash/get';
import HTML from './view-standard-cover-letter.html';
import { mainAPI, notificationUtils } from 'root/angular-injector-provider';
import noop from 'lodash/noop';

export default {
  template: HTML,
  bindings: {
    rfp: '<'
  },
  controller: HotelRfpViewStandardCoverLetterController,
}

function HotelRfpViewStandardCoverLetterController(){
  const
    vm = this;

  Object.assign(this, {
    $onChanges ( changes ) {
      vm.rfp = get(changes, 'rfp.currentValue');
      vm.rfpId = vm.rfp.id;
    },

    addAttachment ( id ) {
      return mainAPI()
        .addHotelRfpDirectCoverLetterAttachment(vm.rfp.id, { id })
        .then(res => updateAttachments(res.data))
    },

    removeAttachment ( id ) {
      notificationUtils().onSave(() => mainAPI().removeHotelRfpDirectCoverLetterAttachment(vm.rfp.id, id))
        .then(res => updateAttachments(res.data))
        .catch(noop);
    }
  });

  function updateAttachments(attachments){
    vm.rfp = { ...vm.rfp, coverLetter: { ...vm.rfp.coverLetter, attachments}};
  }
}
