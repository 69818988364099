/**
 * Created by DejanK on 9/25/2017.
 */
import HTML from './view-final-agreement.html'
import './view-final-agreement.scss'
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
import noop from 'lodash/noop';

export { HTML, HotelRfpFinalAgreementViewController as Controller }

HotelRfpFinalAgreementViewController.$inject = ['RfpHotelRfpRepository', '$stateParams'];
function HotelRfpFinalAgreementViewController(rfpHotelRfpRepository, $stateParams){
  const vm = this;
  vm.addAttachment = addAttachment;
  vm.removeAttachment = removeAttachment;

  $onInit();

  function $onInit(){
    vm.rfpId = $stateParams.rfpId;
    notificationUtils().onLoad( () => rfpHotelRfpRepository.getRfpPreview(vm.rfpId), vm, 'rfp');
  }

  function addAttachment ( id ) {
    return mainAPI()
      .addHotelRfpFinalAgreementAttachment(vm.rfpId, { id })
      .then(res => updateAttachments(res.data));
  }

  function removeAttachment ( id ) {
    notificationUtils().onSave(() => mainAPI().removeHotelRfpFinalAgreementAttachment(vm.rfpId, id))
      .then(res => updateAttachments(res.data))
      .catch(noop);
  }

  function updateAttachments(attachments){
    vm.rfp = { data: { ...vm.rfp.data, finalAgreement: { ...vm.rfp.data.finalAgreement, attachments }}};
  }
}
