/**
 * Created by stefan on 10/5/2016.
 */
import {RECAPTCHA_KEY} from 'config/app.config';

export default {
  template: templateFn,
  controller: ctrl,
  bindings: {
    model: '='
  }
};

templateFn.$inject = ['$element', '$attrs'];
function templateFn ($element, $attrs){
  const name = $attrs.name || 'captcha';
  return `
<div class="rb-input layout-column flex-center" ng-form="${name}" ng-class="{'focused':focused}">
 <div vc-recaptcha key="'${RECAPTCHA_KEY}'"
     on-create="$ctrl.setWidgetId(widgetId)"
     on-success="$ctrl.setResponse(response)"
     on-expire="$ctrl.cbExpiration()"></div>
<input type="hidden" name="${name}" ng-model="$ctrl.model" required/>
</div>`
}

function ctrl() {
  const vm = this;
  vm.widgetId = null;

  vm.setResponse = function setResponse(response) {
    vm.model = response;
  }

  vm.setWidgetId = function setId(widgetId) {
    vm.widgetId = widgetId;
  }

  vm.cbExpiration = function cbExpiration() {
    vm.model = null;
  }
}
