<template>
  <modal-form :class="$style.dialog" @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <div slot="header">
      <div>{{title}}</div>
      <div :class="$style.titleDescription">Drag image here or upload manually, and use our cropper to adjust the position and size.</div>
    </div>
    <div slot="body" :class="$style.vModalBody">
      <rb-input-file v-model="picture" name="profile_picture"
                     v-validate="{required: true}"
                     data-vv-as="Profile Picture"
                     :vv-error="$showError('profile_picture')"
                     :reset="reset"
                     :croppieOptions="croppieOptions" />
    </div>
    <div slot="footer" :class="$style.vModalFooter">
      <button v-if="picture" type="reset"
              class="rbButton" :class="$style.resetButton"
              @click="reset=!reset"> <i class="material-icons">cloud_upload</i> Upload Different Image</button>
      <button type="button" class="rbButton" @click="cancel()">Cancel</button>
      <button type="submit" v-if="picture" class="rbButton mainButton"> Save</button>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue';
  import RbInputFile from 'vRoot/_core/RbInputFile.vue';

  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue';
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue';
  export default {
    name: 'update-profile-picture',
    components: {ModalForm, RbInputFile},
    mixins: [RbFormMixin, DialogMixin],
    props: ['options', 'title'],
    data(){
      return {
        picture: null,
        reset: false
      };
    },
    methods: {
      onSubmit(){ return {file: this.picture}}
    }, computed: {
      croppieOptions(){
        return this.options?this.options.croppieOptions:null;
      }
    }
  }
</script>

<style lang="stylus" module="">

  .dialog {
    min-width: 490px;
    width: auto;
  }

  .titleDescription {
    color: #78909c;
    font-size: 12px;
    max-width: 100%;
    font-weight: 400;
  }

  .vModalBody {
    flex: 0 1 auto;
    margin: 10px 0 5px;
    border-bottom 1px solid #eceff1
    border-top 1px solid #eceff1
  }

  .vModalFooter {
    flex: 0;
    margin: 30px 0 20px;
    padding: 0 10px;
    display: flex;
    justify-content: flex-end;
  }

  .resetButton {
    flex: 0 0 auto
    margin-right: auto
  }
</style>
