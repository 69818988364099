<script>
  import Spinner from "root/components/atoms/Spinner.svelte";
  import {
    mainAPI,
    notificationUtils,
    hotelRfpBidManagerService
  } from "root/angular-injector-provider";
  import ModalYesNoButtons from "root/services/modals/ModalButtons.YesNo.svelte";
  import Note from "./BidNoteComponent.svelte";

  import defaultImage from "images/user-image.png";
  import adminLogo from "images/rb-user-icon.svg";

  export let bid, cancel;
  export let user;

  let notes = [];
  let currentFilter = "all";
  let newNote = "";
  let userPicture;
  let promise;
  let loading = true;

  function loadNotesAndUser() {
    loading = true;
    userPicture = user.isPowerUser()
      ? adminLogo
      : user.profilePicture || defaultImage;
    promise = notificationUtils().onLoad(() =>
      mainAPI()
        .listNotes(bid._id)
        .then(data => {
          notes = data;
          loading = false;
          return [data, data];
        })
    );

    return promise;
  }

  function isUser({ created: { by } }) {
    const u = user.isPowerUser() ? user.powerUser : user;
    return by.userId === u.id;
  }

  loadNotesAndUser()
    .then(() => mainAPI().markNotesAsRead(bid._id))
    .then(handleActionReport);

  const filterOptions = [
    {
      label: "All Notes",
      value: "all"
    },
    // {
    //   label: "My Notes",
    //   value: "mine",
    //   filter: isUser
    // },
    {
      label: "Private Notes",
      value: "private",
      filter({ public: isPublic }) {
        return !isPublic;
      }
    }
  ];

  function fallbackToDefaultImage() {
    if (userPicture !== defaultImage) {
      userPicture = defaultImage;
    }
  }

  function createNote() {
    if (newNote.trim()) {
      notificationUtils()
        .onSave(() =>
          mainAPI().createNote(bid._id, { note: newNote, isPublic: !isPrivate, at: new Date() })
        )
        .then(handleActionReport)
        .then(loadNotesAndUser)
        .then(() => {
          newNote = "";
        });
    }
  }

  function remove(note) {
    notificationUtils()
      .onSave(() => mainAPI().remove(bid._id, note.id))
      .then(handleActionReport)
      .then(() => {
        notes = notes.filter(n => note.id !== n.id);
      });
  }

  function toggle(note) {
    notificationUtils()
      .onSave(() => mainAPI().togglePrivacy(bid._id, note.id))
      .then(handleActionReport)
      .then(() => {
        notes = notes.map(n =>
          note.id === n.id ? { ...note, public: !note.public } : n
        );
      });
  }

  function handleActionReport(reports) {
    hotelRfpBidManagerService().handleActionResult(reports, [bid]);
    return Promise.resolve();
  }

  function markAsUnread() {
    mainAPI().markNotesAsUnRead(bid._id).then(handleActionReport);
  }

  $: supplierName = bid.supplier.company.name;
  $: rfpTitle = bid.rfp.specifications.name;
  $: filter = filterOptions.find(({ value }) => value === currentFilter).filter;
  $: filteredNotes = filter ? notes.filter(filter) : notes;
  $: isPrivate = currentFilter === "private";
  $: canMarkAsUnread = notes.length > 0 && !notes.every(({public: isPublic}) => !isPublic);


  function catchContentEditable(ev){
    newNote = ev.target.innerText;
  }
</script>

<style lang="stylus">.Header {
  padding: 14px;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Filters {
  display: flex;
  align-items: center;
}
.Filter {
  display: flex;
  align-items: center;
  margin: 5px 20px 0 0;
  color: #455a64;
  font-size: 12px;
  cursor: pointer;
}
.Filter i.material-icons {
  margin-right: 5px;
}
.Filter i.material-icons.active {
  color: #04a89d;
}
.NotesContainer {
  background: #eceff1;
  min-height: 0px;
  max-height: calc(95vh - 300px);
  overflow: auto;
}
.ModalFooter .TextAreaBox {
  background: #d9e0e3;
  padding: 10px 14px 10px 16px;
  display: flex;
  align-items: center;
}
.ModalFooterButtons {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ModalFooterButtons > :global(*) {
  padding: 0;
  margin: 0;
}
.Dialog :global(.Picture) {
  flex: 0 0 auto;
  height: 30px;
  width: 30px;
  border: 1px solid #fff;
  border-radius: 100%;
  background: #e7ebed;
  object-fit: cover;
  overflow: hidden;
  margin-left: 10px;
}
.Dialog :global(.Picture.admin) {
  background: #1e292c;
}
.AutoGrowTextArea {
  flex: 1;
  margin-left: 10px;
  border-radius: 5px;
  border: none;
  padding: 10px;
  box-sizing: border-box;
  font-family: inherit;
  color: #455a64;
  font-size: 12px;
  min-height: 40px;
  max-height: 160px;
  background: #fff;
  overflow: auto;
}
.AutoGrowTextArea:empty:before {
  content: attr(data-placeholder);
  color: #b0bec5;
}
</style>

{#await promise}
  <Spinner />
{:then}
  <div class="Dialog">
    <div class="Header Banner">
      <span>Bid Notes</span>
      <div class="Filters">
        {#each filterOptions as option}
          <div class="Filter" on:click={() => (currentFilter = option.value)}>
            <i
              class="material-icons"
              class:active={option.value === currentFilter}>
              {option.value === currentFilter ? 'radio_button_checked' : 'radio_button_unchecked'}
            </i>
            {option.label}
          </div>
        {/each}
      </div>
    </div>
    <div class="NotesContainer">
      {#each filteredNotes as note, index (note.id)}
        <Note
          {note}
          isUser={isUser(note)}
          on:remove={() => remove(note)}
          on:toggle={() => toggle(note)}
        />
      {/each}
    </div>
    <div class="ModalFooter">
      <div class="TextAreaBox">
        <img
          class="Picture"
          class:admin={user.isPowerUser()}
          src={userPicture}
          alt="User"
          on:error={() => fallbackToDefaultImage()} />
        <div class="AutoGrowTextArea"
             contenteditable="true"
             data-placeholder="Your note here..."
             on:input={catchContentEditable}
        ></div>
      </div>
      {#if notes.length > 0 || newNote}
        <div class="ModalFooterButtons">
          <div class="Filter">
          {#if canMarkAsUnread}
            <button
              type="button"
              class="aBtn asLarge atLight"
              on:click={markAsUnread}
              data-icon="person_add"
            >
              Mark As Unread
            </button>
          {/if}
          </div>
          <ModalYesNoButtons
            noAction={() => cancel()}
            yesIcon="save"
            yesLabel={`Save ${isPrivate? "Private": ""} Note`}
            disabled={loading || !newNote}
            yesAction={createNote} />
        </div>
      {/if}
    </div>
  </div>
{/await}
