{#if item.submenu}
  <button
    class="MenuItem {item.active ? 'Active': ''}"
    data-icon-before="{item.icon}"
    data-icon-after="{'keyboard_arrow_' + (opened ? 'up' : 'down') }"
    on:click={() => opened = !opened}
  >
    {item.label}
  </button>

  <div class="Menu {opened ? '' : 'Closed'}">
    {#each item.submenu as subitem}
      <Item item="{subitem}" />
    {/each}
  </div>

{:else}
  <button class="MenuItem {item.active ? 'Active': ''}"
          data-icon-before="{item.icon}"
          data-icon-after="{item.iconAfter}"
          on:click={() => item.action()}>{item.label}</button>

  {#if item.subitem}
    <svelte:component this={item.subitem}/>
  {/if}
{/if}


<script>
  import Item from './NavigationMaximizedLevel2MenuItem.svelte';

  export let item = {};
  let opened = item.active || item.alwaysOpened;
</script>


<style lang="stylus">.MenuItem {
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: 0;
  font-size: 13px;
  color: #b0bec5;
  opacity: 0.5;
  background: transparent;
  border-color: transparent;
  box-sizing: border-box;
  padding: 0 14px;
  margin: 0;
  border-left: 0;
  border-right: 0;
  white-space: nowrap;
}
.MenuItem:before {
  margin-right: 15px;
}
.MenuItem:after {
  margin-left: auto;
}
.MenuItem:hover {
  opacity: 0.7;
}
.MenuItem:focus {
  border-color: #b0bec5;
}
.MenuItem.Active {
  background: #00b8ab;
  color: #fff;
  opacity: 1;
}
.MenuItem.Active:hover {
  opacity: 1;
}
.Menu {
  background: #263238;
}
.Menu.Closed {
  max-height: 0;
  overflow: hidden;
}
</style>
