<script>
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import RfpSpecificationsTravelManager from '../../rfp/pages/specifications/RfpSpecificationsTravelManager.svelte';
  import ModalYesNoButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import ModalCloseButton from 'root/services/modals/ModalButtons.Close.svelte';
  import logoPlaceholder from 'images/logos/logo-placeholder.png';
  import { mainAPI, notificationUtils, $state as stateProvider } from 'root/angular-injector-provider';
  import moment from 'moment';
  import noop from 'lodash/noop';
  import { HOTEL_RFP_BID_PREVIEW } from 'root/states'
  import {DecimalFormatter} from 'root/internationalization/internationalization.service';

  export let
    bidId,
    showViewBidButton = false,
    hideTravelDestinationInfo = false,
    cancel;

  const dataPromise = loadData(),
    dateFormat = 'MM/DD/YY',
    specifications = [
      { label: 'General Area (City)', value: d => getTravelDestinationAddress('CITY', d) },
      { label: 'General Area (Office)', value: d => getTravelDestinationAddress('OFFICE', d) },
      { label: 'Projected Room Night volume for this area', value: d => {
          const v = hideTravelDestinationInfo ? null : d.travelDestination.estimatedRoomNights;
          return v == null || v === 0 ? null : v;
        }},
      { label: 'Distance to Hotel', value: d => {
          const v = hideTravelDestinationInfo ? null : d.distanceMI;
          return v == null || v === 0 ? null : DecimalFormatter().format(v) + ' Miles';
        }},
      { label: 'RFP Program Period', value: d => {
          const start = formatDate(d.specifications.programStartDate), end = formatDate(d.specifications.programEndDate);
          return start === null || end === null ? null : `${start} - ${end}`;
        }},
      { label: 'RFP Due Date', value: d => formatDate(d.specifications.dueDate)},
      { label: 'Primary Decision Criteria', value: d => formatArray(d.specifications.decisionCriteria) },
      { label: 'Mandate Usage', value: d => formatBoolean(d.specifications.mandateUsage) },
      { label: 'Number of employees', value: d => d.specifications.employeesCount },
      { label: 'Expected Rates Loaded by', value: d => formatDate(d.specifications.ratesLoadedByDate) },
      { label: 'Busiest travel days', value: d => d.specifications.busiestDays },
    ];

  async function loadData(){
    try {
      const response = await notificationUtils().onLoad(() => mainAPI().viewBidPackage(bidId));
      return {
        name: response.data.specifications.name,
        specifications: loadSpecifications(response.data),
        manager: response.data.buyer.contact,
        logo: response.data.buyer.company.logo,
        note: response.data.specifications.note
      };

    } catch (e) {
      cancel();
    }
  }

  function loadSpecifications(data){
    return specifications.reduce((acc, s) => {
      const value = s.value(data, data.travelDestination);
      if(value || value === 0){
        acc.push({ label: s.label, value })
      }
      return acc;
    }, [])
  }

  function formatDate(dateString){
    const m = dateString ? moment(dateString) : null;
    return m === null || !m.isValid() ? null : m.format(dateFormat);
  }

  function formatArray(arr){
    return arr && arr.length ? arr.join(', ') : null;
  }

  function formatBoolean(b){
    return b ? 'Yes' : ( b === false ? 'No' : null );
  }

  function getTravelDestinationAddress(type, { travelDestination }){
    return !hideTravelDestinationInfo && travelDestination.type === type ? travelDestination.location.fullAddress : null;
  }

  function viewInitialBid(){
    stateProvider().go(HOTEL_RFP_BID_PREVIEW, { bidId }).catch(noop);
  }
</script>

{#await dataPromise}
  <Spinner />
{:then data}
  <ClosableModal close="{() => cancel()}">
    <div slot="heading">
      <div class="Header">
        <div class="LogoContainer">
          <img class="Logo" src="{data.logo ? `/images/logos/${data.logo}` : logoPlaceholder}" alt="Company Logo">
        </div>

        <div class="HeaderTitleContainer">
          <div>Bid Package For</div>
          <div class="HeaderTitle">
            {data.name}
          </div>
        </div>
      </div>
    </div>

    <div class="ManagerContainer">
      <RfpSpecificationsTravelManager manager="{data.manager}" readonly="{true}"/>
    </div>

    <div class="Specifications">
      {#each data.specifications as spec}
        <div class="Specification">
          <div class="SpecificationLabel">{spec.label}</div>
          <div>{spec.value}</div>
        </div>
      {/each}

      {#if data.note}
        <div class="NoteContainer">
          <div class="NoteLabel">Additional Notes</div>
          <div class="Note">{data.note}</div>
        </div>
      {/if}
    </div>

    {#if showViewBidButton}
      <ModalYesNoButtons noAction="{() => cancel()}"
                    yesIcon="remove_red_eye"
                    yesLabel="View Initial Bid"
                    yesAction="{() => viewInitialBid()}"
      />
    {:else}
      <ModalCloseButton action="{() => cancel()}" />
    {/if}

  </ClosableModal>
{/await}

<style lang="stylus">.Header {
  padding: 14px;
  padding-right: 0;
  display: flex;
}
.LogoContainer {
  flex: 0 0 auto;
  height: 64px;
  border: 1px solid #eceff1;
  border-radius: 6px;
  overflow: hidden;
}
.HeaderTitleContainer {
  flex: 1 1 100%;
  height: 63px;
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  line-height: 20px;
  color: #546e7a;
}
.HeaderTitle {
  font-weight: bold;
  font-size: 16px;
  color: #455a64;
}
.Logo {
  height: 64px;
}
.ManagerContainer {
  padding: 0 14px 0 16px;
}
.Specifications {
  max-height: 450px;
  padding: 0 14px;
  margin: 5px 0 20px;
  overflow-y: auto;
  border-top: 1px dashed #cfd8dc;
}
.Specification {
  min-height: 40px;
  font-size: 14px;
  line-height: 18px;
  color: #455a64;
  border-bottom: 1px dashed #cfd8dc;
  padding: 17px 0 5px;
}
.SpecificationLabel {
  font-size: 13px;
  color: #78909c;
  padding-bottom: 2px;
}
.NoteContainer {
  margin-top: 10px;
  position: relative;
  padding-top: 15px;
}
.NoteLabel {
  position: absolute;
  top: 2px;
  z-index: 1;
  background: #fff;
  font-size: 12px;
  line-height: 12px;
  color: #b0bec5;
  padding: 5px;
  left: 10px;
}
.Note {
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
  font-weight: normal;
  font-family: "Noto Sans", sans-serif;
  color: #546e7a;
  border: 1px solid #cfd8dc;
  border-radius: 6px;
  padding: 10px;
  white-space: pre-wrap;
}
</style>