<script>
  import store from 'root/rfp-hotel/main/rfp/rbs-rfp-details-navigation/revert-revise-response.store';

  let changesCount = 0;

  $: changesCount = $store && $store.changes.length || 0;

</script>

{#if changesCount}
  <button on:click={store.revertChanges}>
    Revert {changesCount} Change{changesCount > 1 ? 's' : ''}
  </button>
{/if}

<style lang="stylus">button {
  background: transparent;
  border: 0;
  outline: 0;
  text-decoration: underline;
  width: 100%;
  color: #00b8ab;
}
button:hover {
  color: #fff;
}
</style>
