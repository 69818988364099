<template>
  <div :class="$style.container">
    <img v-if="image" :src="image" :class="$style.pictureImage"/>
    <div v-else :class="$style.noPicture">
      <div class="fa fa-building" :class="$style.noPictureIcon" aria-hidden="true"></div>
      <div :class="$style.noPictureLabel">Picture of your Property</div>
    </div>

    <div :class="$style.logoContainer">
      <img v-if="logo" :src="'/images/logos/'+logo" :class="$style.logoImage"/>
      <div v-else :class="$style.noLogo">
        <div class="fa fa-image" :class="$style.noLogoIcon" aria-hidden="true"></div>
        <div :class="$style.noLogoLabel">Your Logo</div>
      </div>
    </div>

    <button class="rbButton expandButtonToShowText accountProfileUploadImageButton"
            :class="$style.uploadLogoButton"
            @click="editLogo">
      <i class="material-icons">add_a_photo</i> <span :class="$style.uploadButtonLabel">Upload</span>
    </button>

    <button class="rbButton expandButtonToShowText accountProfileUploadImageButton"
            v-if="isAdmin"
            :class="$style.uploadPictureButton"
            @click="editImage">
      <i class="material-icons">add_a_photo</i> <span :class="$style.uploadButtonLabel">Upload</span>
    </button>
  </div>
</template>

<script>
  import { noop } from 'lodash';
  import accountProfileService from "root/v-components/account-profile/accountProfileService";

  export default {
    name: "rbv-logo-with-image-editor",
    props: {
      isAdmin: {
        type: Boolean,
        default: false,
      },
    },
    data () { return { updated: 0 } },
    asyncComputed: {
      logo: {
        get() { return accountProfileService.getLogo() },
        watch() { return this.updated }
      },
      image: {
        get() { return accountProfileService.getEntity().then( e => this.formatImageUrl(e.image) ) },
        watch() { return this.updated }
      },
    },
    methods: {
      editLogo () {
        accountProfileService.editLogo()
          .then( () => { this.updated++ }, noop )
      },
      editImage () {
        accountProfileService.editImage()
          .then( () => { this.updated++ }, noop )
      },
      formatImageUrl(image){
        return image ? (image.startsWith('http') ? image : `/images/entities/${image}`): ''
      }
    }
  }
</script>

<style lang="stylus" module>

  .container {
    background: white;
    border: 1px solid #ECEFF1;
    width : 258px;
    min-height: 258px;
    border-radius : 3px;
    margin: 23px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pictureImage {
    min-width: 120px;
    max-width: 258px;
    max-height: 300px;
  }

  .noPicture {
    text-align center
    padding: 74px 0;
    width: 100%;
    background: #ECEFF1;
  }

  .noPictureIcon {
    font-size : 80px;
    text-transform : uppercase;
    color : #B0BEC5;
  }

  .noPictureLabel {
    margin-top: 15px;
    font-size : 11px;
    text-transform : uppercase;
    color : #90A4AE;
  }

  .logoContainer {
    position: absolute;
    top: -2px;
    left: -2px;
    background: white;
    border: 1px solid #ECEFF1;
    width : 108px;
    min-height : 62px;
    overflow hidden
    border-radius : 3px 0 16px 0;
    box-shadow : 3px 3px 3px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logoImage {
    min-width: 55px;
    max-width: 108px;
    max-height: 90px;
  }

  .noLogo {
    text-align center
    padding: 8px 0;
    width: 100%;
    background: #ECEFF1;
  }

  .noLogoIcon {
    font-size : 33px;
    text-transform : uppercase;
    color : #B0BEC5;
  }

  .noLogoLabel {
    font-size : 11px;
    text-transform : uppercase;
    color : #90A4AE;
  }

  .uploadLogoButton {
    position: absolute;
    top: -2px;
    left: -2px;
    height: 29px;
    line-height: 27px;
    border-radius: 3px 0
  }

  .uploadPictureButton {
    position: absolute;
    bottom: -1px;
    right: -1px;
    height: 29px;
    line-height: 27px;
    border-radius: 3px 0
  }

  .uploadButtonLabel {
    font-size 12px
  }

</style>

<style lang="stylus">

  .rbButton.expandButtonToShowText.accountProfileUploadImageButton {
    max-width: 29px;
    padding: 0 13px;

    &:hover {
      max-width: 1000px
    }
  }

</style>
