import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';
import Component from './DocumentHeadingTitle.svelte';

export default {
  template: '<div></div>',
  bindings: {
    title: '<',
    subtitle: '<',
    pretitle: '<',
    iconStyle: '<',
    icon: '<',
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(this, SvelteNgComponent(Component, $element[0], () => ({
    title: vm.title,
    subtitle: vm.subtitle,
    pretitle: vm.pretitle,
    iconStyle: vm.iconStyle,
    icon: vm.icon,
  }), {}));
}
