<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header">
      <div>{{ title || "Edit Outgoing Message" }}</div>
      <div :class="$style.subHeader">{{ subTitle }}</div>
    </span>

    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
    >
      <div
        v-if="shouldSelectUser"
        class="rbInputContainer"
        :class="$style.categorySelect"
      >
        <i class="material-icons icon">person</i>
        <rb-select
          v-model="data.userId"
          class="simple"
        >
          <rb-option :value="null">Select User</rb-option>
          <rb-option
            v-for="u in sortedUsers"
            :key="u.id"
            :value="u.id"
          >
            {{ u.firstName }} {{ u.lastName }} - {{ u.email }}
            <template v-if="u.type === 'ACTIVE'">(Power User)</template>
          </rb-option>
        </rb-select>
        <button
          class="button"
          type="button"
          @click="createUser"
        >
          <i class="material-icons">add</i>
          Create User
        </button>
      </div>

      <edit-user-message
        v-model="data.message"
        :user="user"
        :user-account="userAccount"
      />

      <div
        v-if="message !== data.message"
        :class="$style.setDefault"
      >
        <check-box
          v-model="data.setMessageAsDefault"
          :check-box-value="true"
          check-box-label="Set as default"
        />
        <span>
          <i class="material-icons">info</i>
          <div
            class="rbTooltip simple"
            tooltip="placement:top-start"
          >
            Checking this box will set this message as the default message for
            future hotel directory emails.
          </div>
        </span>
      </div>
      <div
        :class="$style.setDefault"
      >
        <check-box
          v-model="data.copyMe"
          :check-box-value="true"
          check-box-label="Send a copy to my email"
        />
      </div>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
      :class="$style.vModalDefaultFooter"
    >
      <span>
        <button
          v-if="shouldSelectUser && data.userId"
          class="rbButton"
          type="button"
          @click="preview"
        >
          Show Preview
        </button>
      </span>

      <span>
        <button
          class="rbButton"
          type="button"
          @click="cancel()"
        >
          Cancel
        </button>
        <button
          class="rbButton mainButton"
          :disabled="shouldSelectUser && !data.userId"
        >
          <i class="material-icons">send</i>
          Send
        </button>
      </span>
    </div>
  </modal-form>
</template>

<script>
import ModalForm from 'vRoot/_core/RbModalForm.vue';
import DialogMixin from 'vRoot/_mixins/DialogMixin.vue';
import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue';
import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
import EditUserMessage from '../EditUserMessage.vue';
import RbSelect from 'vRoot/_core/RbSelect.vue';
import { Dialog } from 'root/v-app/rbServices';
import PreviewDialog from 'vRoot/rfp/reminder-letters/previewDialog.vue'
import hotelDirectoryService from '../../../hotel-directory-service';

export default {
  name: 'OutgoingMessageDialog',
  components: { ModalForm, CheckBox, EditUserMessage, RbSelect },
  mixins: [RbFormMixin, DialogMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    shouldSelectUser: Boolean,
    users: {
      type: Array,
      default: () => []
    },
    userAccount: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      data: {
        message: this.message,
        setMessageAsDefault: false,
        userId: '',
        copyMe: false
      }
    };
  },
  computed: {
    sortedUsers() {
      return [...this.users].sort((a, b) => `${a.firstName}${a.lastName}` > `${b.firstName}${a.lastName}`? 1: -1)
    },
    user() {
      return this.sortedUsers.find(({id}) => id === this.data.userId);
    }
  },
  methods: {
    onSubmit() {
      return this.data;
    },
    createUser() {
      this.hide({createUser: true});
    },
    preview() {
      hotelDirectoryService
        .getMessagePreview(this.data.userId, this.data.message)
        .then(email => Dialog.show(PreviewDialog, {emails: [email]}, { closeOnOutsideClick: true }));
    }
  }
};
</script>

<style lang="stylus" module>
.dialog {
  width: 500px;
  overflow visible
}

.subHeader{
  font-size 12px;
  line-height 14pt;
  color #78909C;
  font-weight lighter;
}

.setDefault{
  display: flex;
  color: #91a4af;
  align-items: baseline;
}

.categorySelect{
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  margin: 10px 0 30px;
}

.categorySelect > div:first-of-type {
  flex: 1;
  margin-right 10px;

  ul {
    border-radius 5px;
    overflow: auto;
    margin-top: 5px;
  }
}

.vModalDefaultFooter {
  display: flex;
  justify-content: space-between;
}
</style>
