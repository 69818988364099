import bidResetInvitationsHandler from './bidResetInvitationsHandler.vue';
const BID_STATUS_RESET = 'BID_STATUS_RESET';

export default {
  id: BID_STATUS_RESET,
  component: bidResetInvitationsHandler,
}

export {
    BID_STATUS_RESET
};
