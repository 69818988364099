<template>
  <div>
    <div :id="template.id" class="questionnaireModuleHeader">
      <span class="questionnaireModuleHeaderText">{{ template.name }}</span>
    </div>
    <div>
      <questionnaire-form-section
        v-for="section in modelCells" :key="section.id"
        :config = "config"
        :globals = "globals"
        :model="section"
        :response="response"
        :template="getTemplate(section.id)"
        @change="$rbEmit"
        @stateChange="$rbEmit"
      />
    </div>
  </div>
</template>

<script>
  import RbEventMixin from '../../_mixins/RbEventsMixin.vue'
  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  import QuestionnaireFormSection from '../form/QuestionnaireFormSection.vue'

  export default {
    name: 'rb-questionnaire-form-module',

    mixins: [ RbEventMixin, QuestionnaireMixin ],

    components: { QuestionnaireFormSection },

    props: [ 'config', 'globals', 'model', 'response', 'template' ],

    computed: {
      modelCells () {
        return this.model.cells
      }
    }
  }
</script>

<style lang="stylus">

  .questionnaireModuleHeader {
    height: 27px;
    border: 0;
    background: #D9E0E3;
    margin: 1px 0;
  }

  .questionnaireModuleHeaderText {
    display: inline-block;
    height: 21px;
    margin: 3px 0;
    padding: 0 15px 0 20px;
    background-color: #78909C;
    border-radius: 0 3px 3px 0;

    font-size: 12px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 21px;
  }

</style>
