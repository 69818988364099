<template>
  <modal-form @cancel="$formCancelHandler" @submit="$formSubmitHandler" :class="$style.dialog">
    <span slot="header">Change Email Address</span>
    <div slot="body" class="vModalDefaultBody">
      <rb-input forId="email" :error="$showError('email')">
        <span slot="label">New Email Address</span>
        <input id="email" type="email" name="email" class="rbInput" autofocus
               v-model="data.emailAddress"
               v-validate="{required: true, email: true, max: 255}"
               data-vv-as="Email Address"/>
      </rb-input>

      <rb-input forId="confirmEmail" :error="$showError('confirmEmail')">
        <span slot="label">Confirm New Email Address</span>
        <input id="confirmEmail" type="email" name="confirmEmail" class="rbInput"
               v-model="data.confirmEmailAddress"
               v-validate="{ required: true, value: data.emailAddress }"
               data-vv-as="Confirm Email Address"/>
      </rb-input>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'
  import RbInput from 'vRoot/_core/RbInput.vue'

  export default {
    name: 'rbv-user-profile-edit-personal-info-dialog',
    mixins: [ RbFormMixin, DialogMixin ],
    components: { RbInput, ModalForm },
    data() {
      return {
        data: {
          emailAddress: '',
          confirmEmailAddress: ''
        }
      }
    },
    methods: {
      onSubmit () { return { form: { emailAddress: this.data.emailAddress } } }
    }
  }
</script>

<style lang="stylus" module="">

  .dialog {
    min-width 400px
  }

</style>

