<template>
  <div :class="$style.component">
    <div
      v-responsive
      class="bar no-border"
      :class="$style.bar"
    >
      <div
        data-icon-before="store"
        :class="$style.title"
      >
        Manage Locations
      </div>

      <div :class="$style.controls">
        <button
          v-if="locations && locations.length"
          class="aBtn atDark asLarge"
          data-icon="download"
          @click="downloadLocations"
        >
          Download Locations
        </button>

        <button
          class="aBtn atDark asLarge"
          data-icon="upload"
          @click="uploadLocations"
        >
          Upload Locations
        </button>
      </div>
    </div>


    <div :class="$style.list">
      <div
        v-if="loading"
        :class="$style.NoLocationsNotification"
      >
        <RbvLoading />
      </div>

      <rbVirtualListFS
        v-else-if="locations && locations.length"
        :items="locations"
        :item-height="45"
      >
        <template v-slot:heading>
          <div :class="$style.headingRow">
            <template v-for="column in columns">
              <div
                :key="column.id"
                :style="column.style"
                :class="$style.headingCell"
              >
                {{ column.text }}
              </div>
            </template>
          </div>
        </template>

        <template v-slot="{ row }">
          <div :class="$style.row">
            <template v-for="column in columns">
              <div
                :key="column.id"
                :style="column.style"
                :class="$style.cell"
              >
                {{ column.print(row) }}
              </div>
            </template>
          </div>
        </template>
      </rbVirtualListFS>
      <div
        v-else
        :class="$style.NoLocationsNotification"
      >
        <div>No locations loaded yet.</div>
        <button
          class="aBtn asMain atDark asLarge"
          data-icon="upload"
          @click="uploadLocations"
        >
          Upload Locations
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import hotelDirectoryService from '../../hotel-directory-service';
import {SvelteDialog} from 'root/v-app/rbServices';

import ResponsiveBarMixin from 'vRoot/hotel-directory/_mixins/responsive-bar.vue';
import RbvLoading from 'vRoot/_core/RbvLoading.vue';
import rbVirtualListFS from 'root/components/molecules/RbVirtualListFixedSize.vue';
import UploadLocationsDialog from './UploadLocationsDialog.svelte';


const LOCATIONS_COLUMNS = [
  {id: 'name', text: 'Location Name', print: row => row.name, style: {minWidth: '30%'} },
  {id: 'address', text: 'Address', print: row => row.address, style: {minWidth: '50%'} },
  {id: 'coordinates', text: 'Coordinates', print: row => `[${row.lat}, ${row.lon}]`, style: {minWidth: '20%'} },
];


export default {
  name: 'HotelDirectoryManageLocations',

  components: { rbVirtualListFS,RbvLoading },

  mixins: [ ResponsiveBarMixin ],

  data() {
    return {
      columns: LOCATIONS_COLUMNS,
      locations: [],
      loading: true,
    }
  },

  mounted() {
    this.loadLocations();
  },

  asyncComputed: {
  },

  methods: {
    downloadLocations() {
      hotelDirectoryService.downloadLocations('locations', this.locations)
    },

    loadLocations() {
      this.loading = true;
      hotelDirectoryService.loadLocations()
        .then(res => {
          this.locations = res.map(Object.freeze);
          this.loading = false;
        });
    },

    uploadLocations(){
      SvelteDialog.show(UploadLocationsDialog)
        .then(() => this.loadLocations());
    },
  }
}
</script>

<style lang="stylus" module>

.component {
  height: calc(100vh - 61px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.bar {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items:center;
}

.title {
  color: #eceff1;
  font-size: 16px;

  &:before {
    background: #00a89d;
    border-radius: 20px;
    margin-right: 10px;
    height: 40px;
    width: 40px;
    line-height: 40px !important;
  }
}

.list {
  flex: 1 1 100%;
  background-color: #eceff1;
  overflow: hidden;
}

.row {
  display: flex;
  padding: 0 5px;
  height: 45px;
  line-height: 45px;

  border-bottom: 1px solid #dedede;
  box-sizing: border-box;
}

.cell {
  box-sizing: border-box;

  width: 5%;
  max-width: 100%;
  min-width: 60px
  padding: 0 5px;

  font-size: 13px;
  color: #546E7A;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headingRow {
  @extend .row;
  background: #313c41;
}

.headingCell {
  @extend .cell;
  color: #acbec5;
}

  .NoLocationsNotification {
    display: block;
    width: 300px;
    margin: 100px auto;
    background: rgba(49,60,66,0.8);
    color: #fff;
    padding: 20px;
    border: 1px solid #313c42;
    border-radius: 10px;
    text-align: center;


    button {
      margin-top: 10px;
    }
  }

</style>

