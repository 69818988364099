<template>
  <transition name="vDialog">
    <div class="vDialogWrapper"
         v-show="shown"
         :style="zIndex"
         @click="handleOverlayClicked($event)">
      <div class="dialog"></div>
    </div>
  </transition>
</template>

<script>
  import Vue from "vue";
  import { Dialog } from "root/v-app/rbServices";
  import { $newScope } from "root/angular-injector-provider";

  export default {
    name: 'rb-dialog-wrapper',
    props: [ 'dialog', 'ord', 'options' ],
    data () { return { shown: false, vInstance: null } },
    computed: { zIndex () { return { zIndex: this.ord * this.options.zIndex }} },
    methods: {
      handleOverlayClicked ($event) {
        this.options.closeOnOutsideClick && $event.target.classList.contains("vDialogWrapper") && this.cancel()
      },
      cancel (reason) { this.waitForAnimation( () => { Dialog.cancel(reason) } ) },
      hide (reason) { this.waitForAnimation( () => { Dialog.hide(reason) } ) },
      waitForAnimation (action){
        this.shown = false
        window.setTimeout(action, 200)
      }
    },
    mounted () {
      document.activeElement && document.activeElement.blur()
      const
        dElement = this.$el.querySelector(".dialog"),
        self = this

      this.vInstance = new Vue({
        el: dElement,
        render: createElement => createElement(self.dialog.component, {
          props: self.dialog.data,
          on: {
            cancel ( reason ) { self.cancel(reason) },
            hide ( reason ) { self.hide(reason) }
          }
        })
      })
      this.ngScope = $newScope()
      this.ngScope.$on('$stateChangeStart', () => { Dialog.cancel() })
      this.$nextTick(() => { this.shown = true })
    },
    destroyed () {
      this.vInstance && this.vInstance.$destroy()
      this.ngScope && this.ngScope.$timeout(() => this.ngScope.$destroy())
      this.$el.remove()
    }
  }
</script>

<style lang="stylus">

  .vDialogWrapper {
    position fixed
    top 0
    left 0
    height 100vh
    width 100vw
    background rgba(0, 0, 0, .2)

    display flex
    align-items center
    justify-content center
    overflow hidden

    transition: all .2s ease-in-out;

    & + .vDialogWrapper {
      background rgba(0, 0, 0, 0.05)
    }
  }

  .vModal { transition: all .2s ease-in-out; }

  .vDialog-enter, .vDialog-leave-to { opacity: 0; }
  .vDialog-enter-to, .vDialog-leave { opacity: 1; }

  .vDialog-enter .vModal,
  .vDialog-leave-to .vModal {
    transform: scale(1.1);
  }

</style>

