import { writable } from 'svelte/store';
import { mainAPI, httpErrorHandler } from 'root/angular-injector-provider';

const
  initial = {
    rfpId: undefined,
    loading: false,
    ready: false,
    raw: [],
    sortedBy: undefined,
    groupedBy: undefined,
    filteredBy: undefined,
    filterOptions: undefined,
    textFilter: '',
    grouped: [],
    disabled: [],
  },
  store = writable(initial);

let lastFetchPromise;

export default store;

export function init(rfpId, list = []){
  store.update(values => {
    if(values.rfpId === rfpId && values.loading) return values;

    const fetchPromise = (list.length ? Promise.resolve(list) : loadDestinations(rfpId));
    lastFetchPromise = fetchPromise;

    fetchPromise
      .then(destinations => {
        if(lastFetchPromise === fetchPromise){
          store.set({
            rfpId,
            loading: false,
            ready: true,
            raw: destinations,
            sortedBy: undefined,
            groupedBy: undefined,
            filteredBy: undefined,
            filterOptions: undefined,
            textFilter: '',
            grouped: groupAndSort(destinations, values),
            disabled: extractNonCompetitive(destinations)
          })
        }
      })
      .catch(() => {
        if(lastFetchPromise === fetchPromise){
          store.set(initial);
        }
      });

    return {...initial, rfpId, loading: true, ready: false};
  });
}

export function update(change){
  store.update(values => {
    const
      profileToSave = {...change, timestamp: Date.now() },
      index = values.raw.findIndex(d => d.id === profileToSave.id),
      raw = Object.assign([], values.raw, { [index]: profileToSave});

    saveProfile(values.rfpId, profileToSave);

    return {...values, raw, grouped: groupAndSort(raw, values), disabled: extractNonCompetitive(raw)};
  })
}

export function setDisabled(destination, value){
  update({...destination, settings: { ...destination.settings, disabled: value }});
}

export function setSort(sortFn) {
  store.update(values => {
    const newValues = { ...values, sortedBy: sortFn };
    return {...newValues, grouped: groupAndSort(newValues.raw, newValues) };
  });
}

export function setGroup(groupFn) {
  store.update(values => {
    const newValues = { ...values, groupedBy: groupFn === null ? undefined : groupFn },
      grouped = groupAndSort(newValues.raw, newValues),
    filterOptions = grouped.filter(g => !g.id).map( g => g.label );
    filterOptions.sort();
    return {...newValues, grouped, filteredBy: undefined, filterOptions: [ 'None', ...filterOptions ] };
  });
}

export function setFilter(filterValue) {
  store.update(values => {
    const newValues = { ...values, filteredBy: filterValue === 'None' ? undefined : filterValue };
    return {...newValues, grouped: groupAndSort(newValues.raw, newValues) };
  });
}

export function setTextFilter(filterValue) {
  store.update(values => {
    const newValues = { ...values, textFilter: filterValue };
    return {...newValues, grouped: groupAndSort(newValues.raw, newValues) };
  });
}

function loadDestinations(rfpId){
  return mainAPI().loadCompetitiveBidsProfiles(rfpId)
    .then(response => response.data)
    .catch(error => httpErrorHandler().handle(error));
}

function groupAndSort(destinations, rules){
  const textFilter = rules.textFilter ?  new RegExp(rules.textFilter, 'i') : false;
  let result = destinations.filter(d => !d.settings.disabled && (textFilter ? textFilter.test(d.name) : true));

  if(rules.sortedBy) result.sort(rules.sortedBy);
  if(rules.groupedBy) {
    result = result.reduce(rules.groupedBy, []);
    if (rules.filteredBy) result = result.filter(r => r.label === rules.filteredBy);
    result = result.reduce((acc, g) => {
      acc.push(g)
      return acc.concat(g.items);
    }, [])
  }

  return result;
}

function extractNonCompetitive(destinations) {
  return destinations.filter(d => d.settings.disabled);
}

function saveProfile(rfpId, profileToSave){
  mainAPI().saveCompetitiveBidsProfile(rfpId, profileToSave.id, {
    ...profileToSave.settings,
    brands: profileToSave.settings.brands.map(brand => brand.id),
    timestamp: profileToSave.timestamp
  })
    .catch(error => {
      if (error.status !== 409) // conflict is thrown when there is a newer version of the profile in the database (requests race through network)
        httpErrorHandler().handle(error)
    })
}