<template>
  <modal-form @cancel="$formCancelHandler" @submit="$formSubmitHandler" :class="$style.dialog">
    <div slot="header" :class="$style.title">
      <i class="material-icons">info_outline</i>
      <div :class="$style.notificationHead">
        <div :class="$style.top">Contact Set</div>
        <div :class="$style.main">Successfully</div>
      </div>
    </div>
    <div slot="body" class="vModalDefaultBody">
      <p>
        You have successfully assigned {{bids.length}} hotels(listed below) to {{contactName}} at {{contactCompanyName}}.
      </p>
      <ul :class="$style.bids">
        <li v-for="(name, index) in names" :key="index"> {{name}} </li>
      </ul>
    </div>
    <div slot="footer" class="vModalDefaultFooter">
      <button class="rbButton mainButton" :class="$style.button">                
        Okay
      </button>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'

  export default {
    name: 'bid-contacts-set-successfully',
    mixins: [ RbFormMixin, DialogMixin ],
    components: { ModalForm },
    props: {
      names: {
        type: Array,
        required: true,
      },
      bids: {
        type: Array,
        required: true,
      }
    },
    computed: {
      contactName() {
        return this.bids[0].supplier.contact.fullName
      },
      contactCompanyName() { return this.bids[0].supplier.contact.company.name },
    }
  }
</script>

<style lang="stylus" module="">
  .dialog {
    width 400px;
    color: #556f7b;
    font-size 12px;
  }

  .title{
    display: flex;
    align-items: center;
    font-weight: lighter;
  }

  .title i{
    height: 52px;
    width: 52px;
    font-size: 52px;
    color: #01a99d;
  }

  .top{
    font-size 15px;
  }

  .main{
    font-size 20px;
    font-weight: bold;
  }

  .bids{
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 15px;
  }

  .bids li{
    padding 5px 0;
    line-height 1;
  }

  .button{
    border-radius: 30px;
  }
</style>

