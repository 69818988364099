<template>
  <div>
    <label :class="$style.label">Search for Chain or Brand</label>
    <div :class="$style.inputContainer">
      <input type="text" :class="$style.input"
             placeholder="Keywords here..."
             :value="query"
             @input="search" />
    </div>
  </div>
</template>

<script>
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'

  export default {
    name: 'rbv-travel-destinations-map-chains-filter-search',
    mixins: [RbEventsMixin],
    props: [ 'query' ],
    methods: {
      search(e){ this.$rbEmit({ name: 'changed', value: e.target.value }) }
    }
  }
</script>

<style lang="stylus" module >
  @import "~vRoot/_styles/mixins"

  .label {
    font-size : 11px;
    line-height : 12px;
    color : #78909C;
  }

  .inputContainer {
    position: relative;

    &::before {
      position: absolute;
      top: 7px;
      right: 10px;
      font-family: "Material Icons", monospace;
      color: #B0BEC5;
      font-size: 24px;
      content: '\E8B6';
      z-index 1
    }
  }

  .input {
    line-height: 36px;
    height: 36px;
    width: 100%;
    border: 1px solid #DCDEE0;
    box-sizing: border-box;
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    background #fff;
    border-radius 3px;
    padding: 0 35px 0 5px

    +placeholder() {
      font-family "Noto Sans", sans-serif
      font-size 13px
      line-height 36px
      color #B0BEC5
    }
  }

</style>
