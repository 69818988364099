/**
 * Created by DejanK on 4/4/2017.
 */
import { cloneDeep, isEqual, noop } from 'lodash'
import HTML from './edit-questionnaire.html'

export { HTML, RfpQuestionnaireEditController as Controller }

RfpQuestionnaireEditController.$inject = [ 'NotificationUtils', 'RfpHotelRfpRepository', '$state'];
function RfpQuestionnaireEditController(notificationUtils, rfpHotelRfpRepository, $state){
  const vm = this
  let pristineQuestionnaire

  vm.isPreview = false
  vm.preview = preview
  vm.closePreview = closePreview
  vm.save = saveQuestionnaire

  $onInit();

  function $onInit(){
    vm.rfpId = $state.params.rfpId

    notificationUtils.onLoad( () => rfpHotelRfpRepository.getQuestionnaireModel(vm.rfpId), vm, 'questionnaire' )
      .then( response => { pristineQuestionnaire = cloneDeep(response.data) }, noop )
  }

  function preview(){ vm.isPreview = true }

  function closePreview(){ vm.isPreview = false }

  function saveQuestionnaire(){
    const model = vm.questionnaire.data

    if(isEqual(pristineQuestionnaire, model)){
      goToView()
    } else {
      notificationUtils.onSave( () => rfpHotelRfpRepository.updateQuestionnaireModel(vm.rfpId, model) )
        .then(goToView, noop)
    }

    function goToView(){ $state.go('hotelRfp.rfp.questionnaire.view') }
  }
}
