import { module as AngularModule } from 'angular';
import angularUiRouter from '@uirouter/angularjs';

import Servers from '../shared-ng/servers';
import ui from './ui';
import { name as LetterModule } from './letter/core.letter.module.js';

export default AngularModule('rb.core', [
  angularUiRouter,
  Servers,
  ui,
  LetterModule,
])
  .constant('AppConfig', {
    server: '/api/',
    appUrl: 'https://dev.readybid.net'
  })
  .value('GoogleConfig', {
    mapKey: 'AIzaSyDssFYpdJCKAXIFXyyigsPvw1d4CQWie4M'
  })
  .service('RbAnchorScroll', require('core/services/rb-anchor-scroll.service'))
  .service('ControllerUtils', require('./services/controller-utils.service'))
