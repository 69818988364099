<script>
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import RbHeader from 'root/core/ui/headers/rbs-headers/RbChainPortalHeader.svelte';
  import Navigation from 'root/rfp/reports/chain-portal/NavigationSidebar.svelte';
  import UserModule from './UserAccount.svelte';
  import BidsModule from './Bids.svelte';
  import RfpsModule from './Rfps.svelte';

  import {currentUser, httpErrorHandler} from 'root/angular-injector-provider';

  const userPromise = currentUser().get()
    .catch(err => {
      if(err === null){
        return httpErrorHandler().handle({status: 401});
      } else {
        return Promise.reject();
      }
    })

</script>


<RbHeader />
<div class="DocumentView Vertical">
  {#await userPromise}
    <div class="FillAndCenter">
      <Spinner />
    </div>

  {:then user}
    <div class="HeaderContainer">
      <Navigation />
      <UserModule {user} />
    </div>

    <div class="LayoutContainer">
      <div class="Panel LeftPanel">
        <BidsModule />
      </div>

      <div class="Panel RightPanel">
        <RfpsModule />
      </div>
    </div>

  {:catch err}
    <div class="FillAndCenter">
      <div>There was an error</div>
    </div>
  {/await}
</div>

<style lang="stylus">.DocumentView {
  width: 1360px;
  margin: 0 auto;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: row;
  background: transparent;
}
.HeaderContainer {
  background: #fff;
  flex: 0 0 auto;
  box-shadow: 1px 1px 3px 3px rgba(0,0,0,0.1);
  overflow: hidden;
  border-radius: 0 0 6px 6px;
  margin-bottom: 15px;
}
.LayoutContainer {
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 25px;
  flex: 1 1 100%;
  overflow: hidden;
  padding: 10px 10px 0;
  margin: -10px -10px 0;
}
.Panel {
  background: #fff;
  box-shadow: 0 0 3px 3px rgba(0,0,0,0.1);
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
}
.RightPanel {
  box-shadow: 0 0 3px 3px rgba(0,169,157,0.25);
}
</style>

