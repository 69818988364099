import {$state} from 'root/angular-injector-provider';
import {HOTEL_RFP_COMPETITIVE_BIDS_VIEW} from 'root/states';

export default function CreateViewCompetitiveBid(){
  return function ViewCompetitiveBid(report){
    return {
      label: 'View Bid',
      icon: 'remove_red_eye',
      action: () => {
        $state().inNew(HOTEL_RFP_COMPETITIVE_BIDS_VIEW, {bidId: report.bids[0]._id})
      }
    }
  }
}