/**
 * Created by DejanK on 3/23/2017.
 */
import angular from 'angular'

import BidManagerUI from './ui/rfp.bid-manager.main.ui.module'


import Dialogs from './dialogs'
import BidManagerListViewService from './core/bid-manager-list.view.service'
import BidManagerService from './core/bid-manager.service'
import BidManagerRepository from './core/bid-manager.repo'
import BidManagerBulkBidActionsService from './core/actions/bulk-bid-actions.service'
import BidManagerSingleBidActionsService from './core/actions/single-bid-actions.service'
import BidManagerActionsFactory from './core/actions/bid-manager-actions.factory'
import BidManagerBidActionsService from './core/actions/bid-manager-bid-actions.service';

import NegotiationsModelFactory from './core/negotiations/negotiations-model.factory'
import NegotiationsFactory from './core/negotiations/negotiations.factory'
import NegotiationFactory from './core/negotiations/negotiation.factory';
import NegotiationsHtmlFactory from './core/negotiations/negotiations-html.factory';
import RateFactory from './core/negotiations/rate.factory';
import TaxesFactory from './core/negotiations/taxes.factory';
import TcosFactory from './core/negotiations/tcos.factory';

import NegotiationsService from './core/negotiations/negotiations.service'
import NegotiationsPanelMessagingService from './core/negotiations/negotiations-panel-messaging.service';

export default angular.module('rb.rfp.bidManager.main', [ BidManagerUI.name ])
  .config(Dialogs)

  .service('BidManagerRedirectService', require('./bid-manager-redirect.service').default)

  .service('BidManagerService', BidManagerService)
  .service('BidManagerRepository', BidManagerRepository)
  .service('BidManagerListViewService', BidManagerListViewService)

  .service('BidManagerBidActionsService', BidManagerBidActionsService)
  .factory('BidManagerActionsFactory', BidManagerActionsFactory)
  .service('BidManagerBulkBidActionsService', BidManagerBulkBidActionsService)
  .service('BidManagerSingleBidActionsService', BidManagerSingleBidActionsService)

  .service('NegotiationsPanelMessagingService', NegotiationsPanelMessagingService)

  .service('NegotiationsService', NegotiationsService)

  .service('NegotiationsFactory', NegotiationsFactory)
  .service('NegotiationFactory', NegotiationFactory)
  .service('RateFactory', RateFactory)
  .service('TaxesFactory', TaxesFactory)
  .service('TcosFactory', TcosFactory)
  .service('SeasonsFactory', require('./core/negotiations/seasons.factory'))
  .service('RoomTypesFactory', require('./core/negotiations/room-types.factory'))
  .service('NegotiationsHtmlFactory', NegotiationsHtmlFactory)


  .factory('NegotiationsModelFactory', NegotiationsModelFactory)
  .factory('NegotiationsEditorTooltipsFactory', require('./core/negotiations/negotiations-editor-tooltips.factory'))
