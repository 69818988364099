<template>
  <div :class="$style.root">
    <div
      v-if="value"
      :class="$style.categoryDetails"
    >
      <i class="material-icons">{{ icon }}</i>
      <span>{{ text || "___" }}</span>
    </div>
    <div
      v-else
      :class="$style.categoryDetails"
    >
      <i
        class="material-icons"
        :class="$style.warning"
      >warning</i>
      <span>{{ label }}</span>
    </div>
    <div
      class="rbInputContainer"
      :class="$style.categorySelect"
    >
      <i class="material-icons icon">face</i>
      <rb-select
        class="simple"
        :value="value"
        @input="$emit('input', $event)"
      >
        <rb-option :value="null">{{ label }}</rb-option>
        <rb-option
          v-for="option in options"
          :key="option.id"
          :value="option.id"
        >
          {{ option.name }}
        </rb-option>
      </rb-select>
    </div>
  </div>
</template>

<script>
import RbSelect from 'vRoot/_core/RbSelect.vue';

export default {
  name: 'TableRowSelectColumn',
  components: {RbSelect},
  props: {
    value: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    text: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  }
}
</script>

<style module lang="stylus">
.root {
  overflow visible !important;
}

.categoryDetails{
  display: flex;
  justify-content flex-start;
  align-items: center;
  min-width 0;
  overflow hidden;

  *{
    padding 0 5px;
  }
}

.categorySelect{
  display none;
  margin 0 !important;
  max-width 170px;
  overflow visible !important;
}

.categorySelect i{
  top: 5px;
}

.categoryDetails span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.warning{
  color #f15a25;
}
</style>
