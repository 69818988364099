import { checkAndExecute } from 'root/api/helpers';

export default function (api) {

  api.accounts = {
    getApprovedEmailAddressDomains: accountId => checkAndExecute(
      () => accountId,
      () => api.server.get(`accounts/${accountId}/approved-email-address-domains`)
    ),

    setApprovedEmailAddressDomains: (accountId, domains = []) => checkAndExecute(
      () => accountId,
      () => api.server.put(`accounts/${accountId}/approved-email-address-domains`, {domains})
    ),
  };
}
