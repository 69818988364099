<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header">
      <template v-if="!user.token">
        <div :class="$style.noLink">
          <i class="material-icons icon">info_outline</i>
          <span>User has no valid link</span>
        </div>
        <div
          v-if="!action"
          :class="$style.noLinkText"
        >
          This user's link has been broken. To generate a new link for this user, click generate new link below.
        </div>
      </template>
      <div>{{ user.firstName }} {{ user.lastName }}</div>
      <div :class="$style.subHeader">
        <div>{{ user.department }}<template v-if="user.department && user.jobTitle">,</template>{{ user.jobTitle }}</div>
        <div>{{ user.email }}</div>
        <div>{{ user.phone }}</div>
      </div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
    >
      <div
        v-if="user.token"
        :class="$style.group"
      >
        <rb-input
          class="rbInputEmpty"
          :class="$style.link"
        >
          <i class="material-icons icon">link</i>
          <input
            type="text"
            class="rbInput"
            :value="link"
            disabled
          >
        </rb-input>
        <span>
          <i
            class="material-icons"
            :class="$style.copy"
            @click="copy"
          >filter_none</i>
          <div
            class="rbTooltip simple"
            tooltip="placement:top-start"
          >copy link</div>
        </span>
        <span>
          <i
            class="material-icons"
            :class="$style.copy"
            @click="refresh"
          >autorenew</i>
          <div
            class="rbTooltip simple"
            tooltip="placement:top-start"
          >generate new link</div>
        </span>
        <span>
          <i
            class="material-icons"
            :class="$style.copy"
            @click="deactivate"
          >link_off</i>
          <div
            class="rbTooltip simple"
            tooltip="placement:top-start"
          >break link</div>
        </span>
        <div
          v-if="copying"
          :class="$style.copied"
        >
          copied
        </div>
      </div>
      <rb-input
        v-else
        class="rbInputEmpty"
        :class="$style.link"
      >
        <i class="material-icons icon">link_off</i>
        <input
          type="text"
          class="rbInput"
          value="No Valid Link"
          disabled
        >
      </rb-input>
      <template v-if="action && action !== 'deactivate'">
        <div :class="$style.group">
          <div :class="$style.sub">
            Edit out going message
            <span>
              <i class="material-icons">info</i>
              <div
                class="rbTooltip simple"
                tooltip="placement:top-start"
              >
                This message wil be included in the email to be sent to the user
              </div>
            </span>
          </div>
          <div
            v-if="message !== msg"
            :class="$style.subEnd"
          >
            <check-box
              v-model="setMessageAsDefault"
              :check-box-value="true"
              check-box-label="Set as default"
            />
            <span>
              <i class="material-icons">info</i>
              <div
                class="rbTooltip simple"
                tooltip="placement:top-start"
              >
                this box will set this message as the default message for future hotel directory emails.
              </div>
            </span>
          </div>
        </div>
        <edit-user-message
          v-model="msg"
          :user-account="userAccount"
          :user="user"
        />
      </template>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <template v-if="!action">
        <button
          v-if="user.token && sendable"
          type="button"
          class="rbButton"
          @click.stop.prevent="send"
        >
          <i class="material-icons">email</i>
          Send Link
        </button>
        <button
          v-else-if="!user.token"
          type="button"
          class="rbButton"
          @click.stop.prevent="refresh()"
        >
          <i class="material-icons">autorenew</i>
          Generate New Link
        </button>
      </template>
      <button
        v-else
        class="rbButton mainButton"
      >
        <i class="material-icons">email</i>
        {{ buttonText }}
      </button>
    </div>
  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'
  import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
  import EditUserMessage from 'vRoot/hotel-directory/modules/manageUsers/EditUserMessage.vue';

  export default {
    name: 'ManageLinkDialog',
    components: { RbInput, ModalForm, CheckBox , EditUserMessage},
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      user: {
        type: Object,
        required: true,
      },
      message: {
        type: String,
        required: true,
      },
      userAccount: {
        type: Object,
        required: true
      },
      sendable: Boolean,
    },
    data() {
       return {
            action: null,
            copying: false,
            setMessageAsDefault: false,
            msg: this.message
        }
    },
    computed: {
        link(){
            return this.user.link;
        },
        buttonText() {
            if(this.action === 'refresh'){
                return 'Generate New Link';
            } else {
                return 'Resend Link';
            }
        }
    },
    methods: {
        onSubmit(){
            return {
                action: this.action,
                setMessageAsDefault: this.setMessageAsDefault,
                message: this.msg
            };
        },
        refresh(){
            this.action = 'refresh';
        },
        send() {
            this.action = 'resend';
        },
        copy(){
            const el = document.createElement('textarea');  // Create a <textarea> element
            el.value = this.link;                                 // Set its value to the string that you want copied
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.copying = true;
            window.setTimeout(() => {
                this.copying = false
            }, 1000);
        },
        deactivate(){
            this.action = 'deactivate';
            this.$formSubmitHandler();
        }
    }
  }
</script>

<style lang="stylus" module>

    .dialog {
        width: 500px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }

    .group{
        display flex;
        align-items center;
        position relative;
    }

    .copied{
        position: absolute;
        right: 0px;
        padding: 3px;
        top: -20px;
        background: rgba(86, 110, 124, 0.5);
        border-radius: 3px;
        color: #6a7a83;
    }

    .link{
        flex 1;
    }

    .link input{
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .copy{
        padding: 0 10px 10px;
        font-weight: 600;
        color: #556e7b;
        cursor pointer;
    }

    .newLinkButton{
        border-radius 50px;
        display flex;
    }

    .groupItem{
        flex: 1;

        &+&{
            margin-left 5px;
        }
    }

    .sub{
        flex 1;
        display flex;
        align-items center;
        font-size 12px;
    }

    .sub > span{
        display flex;
    }

    .sub i{
        color #90A4AE;
        padding-left 10px
        font-size 20px;
    }

    .subEnd{
        composes sub;
        justify-content flex-end;
        color #90A4AE;
        font-weight bolder;

        i{
            padding 0;
        }
    }

    .noLinkText{
        color: #556e7b;
        font-size: 13px;
        margin-bottom: 10px
    }

    .noLink{
        display flex;
        align-items center;
        margin-bottom 10px;
    }

    .noLink i{
        font-size 35px;
        color #00b8ab;
    }

    .noLink span{
        font-size 20px;
        font-weight lighter;
        color #556e7b;
    }
</style>
