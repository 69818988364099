import SendFinalAgreementsActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendFinalAgreementsActionReport.vue';
import {RESPONDED, NEGOTIATION_SENT, NEGOTIATION_RESPONDED, NEGOTIATION_FINALIZED, statusMatches, BUYER_DRAFT, FINAL_AGREEMENT_PENDING, NO_LONGER_INTERESTED} from 'rfp/bid-manager/main/core/actions/action/status';
import {Dialog as VueDialog} from 'root/v-app/rbServices';
import noop from 'lodash/noop';
import {mainAPI, ngDialog, notificationUtils} from 'root/angular-injector-provider';
import SendFinalAgreementsAction from 'vRoot/rfp-hotel/bid-manager/actions/SendFinalAgreementsAction.vue';
import {NAME as EditRateLoadingInformationDialog} from 'entity/rate-loading-information/dialogs/rate-loading-information/rate-loading-information.ctrl';

export default function SendFinalAgreements(handleActionResultFn){
  return function (selectedBidsReport){
    return {
      label: 'Send Final Agreements',
      icon: 'thumb_up',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [ BUYER_DRAFT, RESPONDED, NEGOTIATION_SENT, NEGOTIATION_RESPONDED, NEGOTIATION_FINALIZED, FINAL_AGREEMENT_PENDING, NO_LONGER_INTERESTED ]),
      action: () => action(selectedBidsReport, handleActionResultFn)
    };
  }
}

/**
 * Sends Final Agreement to all selected bids IF their buyer companies have set Rate Loading Instructions and
 * rates exist. For all bids that don't have pre-set accepted rates, all rates are considered as accepted.
 *
 * 1. Display Please wait => Checking Rate Loading Instructions
 * 2. Check Rate Loading Instructions
 *  => on fail display edit rate loading instructions modal for all failed companies one by one and check again after end
 * 3. on success notify user that if No rates have been accepted, all rates will be considered as accepted and ask to continue
 *  => on No close modal and end
 * 4. on Yes, accept all rates where no accepted exist, and send final agreements.
 *  => can Fail if:
 *    a. User does not have access to a bid
 *    b. Bid status is changed and is no longer compatible
 *    c. NO RATES EXISTS
 */
function action(report, handleActionResultFn){
  notificationUtils().onLoadWithNotification(() => mainAPI().checkRateLoadingInformation(report.bidsIds), {
    workingDialogLabel: 'Checking Rate Loading Instructions'
  })
    .then(response => checkRateLoadingInformationCheckResponse(response))
    .then(() => VueDialog.show(SendFinalAgreementsAction, { report }))
    .then(actionReport => handleActionResultFn( actionReport, report.bids, SendFinalAgreementsActionReport))
    .catch(noop);
}

function checkRateLoadingInformationCheckResponse(checkResponse) {
  return checkResponse.data
    .filter(i => !i.valid)
    .map(i => i.entityId)
    .reduce((promise, i) => {
      return promise.then(() => ngDialog().show(EditRateLoadingInformationDialog, { locals: {entityId: i.entityId} }))
    }, Promise.resolve());
}
