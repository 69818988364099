<template>
  <questionnaire-form
    :questionnaire="questionnaire"
    @change="onChange"
    @stateChange="onStateChange"
  />
</template>

<script>
import QuestionnaireForm from './QuestionnaireForm.vue'
import RbEventMixin from '../../_mixins/RbEventsMixin.vue'
import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
import {isNil} from 'lodash';
import Vue from 'vue';

export default {
  name: 'QuestionnaireFormComponentVue',

  components: { QuestionnaireForm },

  mixins: [ RbEventMixin, QuestionnaireMixin ],

  props: [ 'questionnaire' ],

  data() {
    return {
      formErrors: {}
    }
  },

  methods: {
    onChange (data) {

      if(isNil(data.value) || data.value.trim() === ''){
        Vue.delete(this.questionnaire.response.answers, data.id)
      } else {
        Vue.set(this.questionnaire.response.answers, data.id, data.value.trim())
      }

      if(data.error.length){
        this.formErrors[data.id] = data.error
      } else {
        delete this.formErrors[data.id]
      }

      Vue.set(this.questionnaire.response, 'isValid', !Object.keys(this.formErrors).length)

      this.notifyOfChange();
    },

    onStateChange( data ){
      const stateFilters = this.questionnaire.response.state.find( s => s.id === data.stateId).data
      Vue.set(stateFilters, data.filterId, data.templateFilters[data.filterId].slice(0, stateFilters[data.filterId].length + data.value))
      if(data.value < 0) { this.removeObsoleteValues( data, stateFilters[data.filterId] ) }
      this.notifyOfChange()
    },

    removeObsoleteValues ( data, filter ) {
      data.template.cells.forEach( row => {
        if( row.for && row.for[data.filterId] && filter.indexOf(row.for[data.filterId]) === -1 ) {
          row.cells.forEach( cellToRemove => {
            if(cellToRemove.cell) {
              const id = cellToRemove.cell.id
              Vue.delete(this.questionnaire.response.answers, id)
              delete this.formErrors[id]
            }
          })
        }
      })
      this.notifyOfChange();
    },

    notifyOfChange(){
      this.$rbEmit('change', {response: this.questionnaire.response, errors: this.formErrors})
    }
  }
}
</script>
