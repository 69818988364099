<script>
    export let selected;

    function select(option){
      selected = option;
    }
</script>

<div class="Title">Select Authentication Method</div>
<div class="Methods">
    <button on:click={() => select('EMAIL')}>Email</button>
    <button on:click={() => select('TOTP')}>Authentication App</button>
</div>
