import AuthRepository from './public-auth'

export default PublicAuthService

PublicAuthService.$inject = [ 'AuthServer' ]
function PublicAuthService(AuthServer){
  const repo = AuthRepository(AuthServer)

  this.signIn = credentials => { return repo.signIn(credentials) }
  this.signInWithToken = token => repo.signInWithToken(token);
  this.signInWithAdmin = credentials => { return repo.signInWithAdmin(credentials) }
  this.resetPassword = emailAddress => { return repo.resetPassword(emailAddress) }
  this.verifyResetPasswordToken = token => { return repo.verifyResetPasswordToken(token) }
  this.setPassword = (token, password) => { return repo.setPassword(token, password) }
}
