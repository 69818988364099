<template>
  <ul v-if="views">
    <li
      v-for="view of views"
      :key="view.id"
      :class="$style.flexParent"
    >
      <div :class="$style.flex">
        <div
          :class="
            active(view.id) ? $style.activeSubMenuItem : $style.subMenuItem
          "
          @click.prevent.stop="open(view)"
        >
          <span :class="$style.subMenuItemIcon">
            <i class="material-icons">face</i>
          </span>
          <span :class="$style.subMenuItemText">
            {{ view.name }}
            <div
              class="rbTooltip simple"
              tooltip="placement:top-start"
              :class="$style.tooltip"
            >
              {{ view.name }}
            </div>
          </span>
        </div>
        <i
          class="material-icons"
          :class="!active(view.id) ? $style.manage : $style.activeManage"
          @click="manage(view)"
        >settings</i>
      </div>
      <template v-if="active(view.id)">
        <div
          :class="$style.flexChild"
          @click="instantPreview"
        >
          <div>Instant Preview</div>
          <i class="material-icons">remove_red_eye</i>
        </div>
        <div
          :class="$style.flexChild"
          @click.stop="trigger(events.SEND_PREVIEW_EVENT)"
        >
          <div>Send Preview</div>
          <i
            class="material-icons"
            :class="$style.flipHorizontal"
          >reply</i>
        </div>
        <div
          :class="$style.flexChild"
          @click="trigger(events.MANAGE_VIEW_LINK)"
        >
          <div>Directory Link</div>
          <i class="material-icons icon">link</i>
        </div>
        <div
          :class="$style.flexChild"
          @click="trigger(events.EDIT_VIEW_EVENT)"
        >
          <div>Display Settings</div>
          <i class="material-icons">tune</i>
        </div>
        <div
          :class="$style.flexChild"
          @click="trigger(events.EDIT_HEADER_EVENT)"
        >
          <div>Edit Header</div>
          <i class="material-icons icon">edit</i>
        </div>
        <div :class="$style.flexChild">
          <check-box
            :value="view.showSubHeader"
            :check-box-value="true"
            check-box-label="Sub Header"
            @input="trigger(events.SHOW_SUBHEADER_EVENT)"
          />
          <i
            v-if="view.showSubHeader"
            class="material-icons icon"
            @click="trigger(events.EDIT_SUBHEADER_EVENT)"
          >edit</i>
        </div>
        <div :class="$style.flexChild">
          <check-box
            :value="view.showWarning"
            :check-box-value="true"
            check-box-label="Warning"
            @input="trigger(events.SHOW_WARNING_EVENT)"
          />
          <i
            v-if="view.showWarning"
            class="material-icons icon"
            @click="trigger(events.EDIT_WARNING_EVENT)"
          >edit</i>
        </div>
        <div
          :class="$style.flexChild"
          @click="trigger(events.EDIT_BACKGROUND_EVENT)"
        >
          <div>Edit Background</div>
          <i class="material-icons icon">image</i>
        </div>
        <div
          :class="$style.flexChild"
          @click="exportToExcel"
        >
          <div>Export to Excel</div>
          <i
            class="material-icons icon"
            :class="$style.flipVertical"
          >publish</i>
        </div>
        <div
          :class="$style.flexChild"
          @click="trigger(events.SELECT_HOTELS)"
        >
          <div>Select Hotels</div>
          <i class="material-icons icon">check_box</i>
        </div>
      </template>
    </li>

    <li
      :class="$style.subMenuItem"
      @click.prevent.stop="createNew"
    >
      <span :class="$style.subMenuItemIcon">
        <i class="material-icons">add_circle_outline</i>
      </span>
      <span :class="$style.subMenuItemText">Create New View</span>
    </li>
  </ul>
</template>

<script>
import { Dialog } from 'root/v-app/rbServices';
import CreateNewViewDialog from 'vRoot/hotel-directory/dialogs/CreateNewViewDialog.vue';
import ManageContentDialog from 'vRoot/hotel-directory/dialogs/ManageContentDialog.vue';
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';
import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
import * as events from 'vRoot/hotel-directory/_core/EventBus';
import hotelDirectoryService from 'vRoot/hotel-directory/hotel-directory-service';

export default {
  name: 'HotelDirectoryNavigationViewItems',
  components: { CheckBox },
  props: {
    views: {
      type: Array,
      required: true
    },
    activeView: {
      type: Object,
      default: null
    }
  },
  data() {
    return { events };
  },
  methods: {
    active(id) {
      return this.activeView && id === this.activeView.id;
    },
    open(data) {
      this.$emit('open', data);
    },
    createNew() {
      Dialog.show(CreateNewViewDialog).then(data => {
        this.$emit('createView', data);
      });
    },
    async manage(view) {
      const rfps = await hotelDirectoryService.loadRfps();
      Dialog.show(ManageContentDialog, {
        view,
        canDelete: this.views.length > 1,
        rfps
      }).then(data => {
        if (data.action === 'update') {
          this.$emit('updateView', {
            view: data.view,
            active: this.active(view.id)
          });
        } else {
          Dialog.show(WarningDialog, {
            title: `Delete ${this.activeView.name} View?`,
            subTitle:
              'Please be aware that deleting this view will eliminate all viewership for this directory and all related functionality.'
          }).then(() => {
            this.$emit('deleteView', data.view);
          });
        }
      });
    },
    trigger(event, data) {
      this.events.EventBus.$emit(event, data);
    },
    instantPreview() {
      hotelDirectoryService.openActiveViewAsTraveler();
    },
    exportToExcel() {
      hotelDirectoryService.exportActiveViewToExcel();
    }
  }
};
</script>

<style lang="stylus" module>
.subMenuItem{
    min-height: 36px;
    box-sizing: border-box;
    color: #90a4ae;
    padding-left: 20px;
    display flex;
    align-items center;
    border-radius: 4px;
    overflow hidden;
    flex 1;
    cursor pointer;

    &:hover{
        color: #fff;
    }
}

.activeSubMenuItem{
    composes subMenuItem;
    color: #fff;
    flex 1;
}

.subMenuItemIcon{
  height: 24px;
  font-size: 20px;
}

.activeSubMenuItem .subMenuItemIcon{
  color #ccc;
}

.subMenuItemText{
  font-size 13px;
  padding-left 15px;
  align-items: center;
  white-space nowrap;
  min-width 0;
  overflow hidden;
  text-overflow: ellipsis;

  .tooltip {
    max-width 150px;
  }
}

.manage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 5px;
    cursor: pointer;
    color #90a4ae;
}

.activeManage{
    composes manage;
    color #fff;
}

.flex{
    display flex;
    justify-content space-between;
    align-items center;
    width 100%;
}

.flexParent{
  composes flex;
  flex-direction column;
  align-items flex-start;
}

.flexChild{
  composes flex;
  padding: 5px !important;
  box-sizing: border-box;
  font-size: 13px;
  cursor: pointer;
  width: calc(100% - 50px);
  border-radius 3px;
  margin: 5px 0 5px 50px;
  color: #677980;
}

.flexChild:hover{
  background #313c42;
}

.flexChild :global .rbCheckboxOptionText{
  color: #677980 !important;
}

.flipVertical{
  transform scaleY(-1);
}

.flipHorizontal{
  transform scaleX(-1);
}
</style>
