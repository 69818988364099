export const editorBodyStyleClass = 'rbLetter';
export const editorStyles = `.mce-content-body { background: #ECEFF1; min-height: 100%; }
.mce-content-body { min-height: 600px; width: 870px; margin: 0 auto; padding: 24px 24px; box-sizing: border-box; font-size: 14px; color: #546E7A; line-height: 20px; font-family: 'Noto Sans', sans-serif; box-shadow: 0 0 9px rgba(35, 31, 32, 0.3); background: white;}
.mce-content-body .inserted.mceNonEditable { color: #00a99d; }
.mce-content-body div, .mce-content-body p, .mce-content-body span, .mce-content-body a, .mce-content-body a:visited, .mce-content-body a:hover { font-size: 14px; line-height: 20px; font-weight: normal; }
.mce-content-body p { margin: 20px 0; }
.mce-content-body td { font-size: 14px; color: #78909C; line-height: 20px; font-family: 'Noto Sans', sans-serif;  }
.mce-content-body h1 { display: block; font-size: 2em; margin: 0.67em 0; font-weight: bold; }
.mce-content-body h2 { display: block; font-size: 1.7em; margin: 0.83em 0; font-weight: bold; }
.mce-content-body h3 { display: block; font-size: 1.5em; margin: 1em 0; font-weight: bold; }
.mce-content-body h4 { display: block; font-size: 1.3em; margin: 1.33em 0; font-weight: bold; }
.mce-content-body h5 { display: block; font-size: 1.17em; margin: 1.67em 0; font-weight: bold; }
.mce-content-body h6 { display: block; font-size: 1em; margin: 2.33em 0; font-weight: bold; }
.mce-content-body hr { display: block; margin: 6px 0; border: 0; border-top: 1px dashed #E1E4E6; }
`;
