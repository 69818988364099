<template>
  <div />
</template>

<script>
  import SvelteVueComponent from 'root/svelte-vue-components/SvelteVueComponentMixin.vue';
  import SComponent from './DocumentHeadingTitle.svelte';

  export default {
    
    name: 'DocumentHeadingTitle',

    mixins: [ SvelteVueComponent ],

    created() {
      this.component = SComponent;
    }
  }
</script>