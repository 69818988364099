<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import YesNoButtons from 'root/services/modals/ModalButtons.YesNo.svelte';

  export let cancel, bid;

  let link, title = '';

  $: link = `${window.location.origin}/#!/public/bids/${bid._id}`
  $: title = `Bid link for ${bid.supplier.company.name}`

  function copy(){
    navigator.clipboard.writeText(link)
      .catch(e => {
        console.error(e)
        alert('There was an error copying to clipboard')
      });
  }

</script>

<ClosableModal close="{cancel}" width="{'500px'}">
  <div slot="heading">
    <ModalTitle {title} />
  </div>
  <div>
    <div class="InputContainer">
      <input id="filter"
             class="Input"
             value={link}
             readonly="readonly"
      />
    </div>
  </div>

  <YesNoButtons
    noAction="{cancel}"
    noLabel="Close"
    yesAction="{copy}"
    yesIcon="content_copy"
    yesLabel="Copy to Clipboard"
  />
</ClosableModal>

<style lang="stylus">.InputContainer {
  margin: 0 20px 20px;
}
.Input {
  font-family: 'Noto Sans', sans-serif;
  position: relative;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  padding: 0 10px;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
  font-size: 14px;
  color: #546e7a !important;
  background: transparent;
  display: flex;
  align-items: center;
}
.Input:focus {
  border: 1px solid #00a99d;
}
</style>
