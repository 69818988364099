import calculateRemainingDays from 'root/services/calculate-remaining-days';

Ctrl.$inject = ['$scope', '$element'];
export default function Ctrl($scope, $element) {
  const vm = this;
  let nagWindow, mainButtonElement;

  Object.assign(vm, {
    $onInit() {
      $element.focus();
      const e = $element[0];
      nagWindow = e.querySelector('div.rbPublicBidNag');
      mainButtonElement = e.querySelector('#CONTINUE');
    },

    $onChanges(changes){
      if(changes.dueDate) {
        vm.days = calculateRemainingDays(vm.dueDate);
      }
    },

    hide (action) {
      if(action) {
        action();
        vm.nagClass = 'nagClosed';
      } else {
        vm.nagClass = 'nagClosing';
        closeNagElementToButton(mainButtonElement, nagWindow);
        $scope.$applyAsync();
        $scope.$timeout( () => { vm.nagClass = 'nagClosed' }, 500);
      }
    }
  });

  function closeNagElementToButton(button, window) {
    const
      buttonRect = button.getBoundingClientRect(),
      windowRect = window.getBoundingClientRect(),
      xDelta = (buttonRect.left + buttonRect.right - windowRect.left - windowRect.right)/2,
      yDelta = (buttonRect.top + buttonRect.bottom - windowRect.top - windowRect.bottom)/2,
      xScale = buttonRect.width/windowRect.width,
      yScale = buttonRect.height/windowRect.height;

    nagWindow.setAttribute('style', `transform: translate(${xDelta}px, ${yDelta}px) scale(${xScale}, ${yScale}); opacity: .1`);
  }
}
