<script>
  /* eslint-disable no-unused-vars,no-unused-labels */
  import noop from 'lodash/noop';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import RequestToAddHotel from './RbTravelDestinationRequestToAddHotelDialog.svelte'
  import {SvelteDialog} from 'root/v-app/rbServices';

  // eslint-disable-next-line prefer-const
  export let searchData, cancel, onSave = () => {}, onSaveLabel = 'Save';

  // eslint-disable-next-line prefer-const
  let searchString = '';

  // eslint-disable-next-line no-labels
  $: onSearchStringChange(searchString);

  function onSearchStringChange(ss){
    searchData.search(ss);
    searchData.clearSelected();
  }

  function toggleAdded(hotelId){
    searchData.toggleSelected(hotelId);
  }

  function getSelectedIcon(option, selectedHotels){
    return option.locked ? 'lock' : (selectedHotels.indexOf(option.id) !== -1 ? 'check_box' : 'check_box_outline_blank')
  }

  function requestToAdd(){
    SvelteDialog.show(RequestToAddHotel).catch(noop)
  }
</script>


<ClosableModal close="{cancel}">
  <div slot="heading">
    <div class="Header">
      <div class="HeaderTitle">
        Search Entire Database
      </div>
      <div>If you find your hotel, you may still add it to this destination</div>
    </div>
  </div>

  <div>
    <div class="Input Search">
      <input type="text"
             class="WithIcon"
             id="entityId"
             placeholder="Search for Hotels by Sabre Code, Name or Address"
             name="entity"
             autocomplete="off"
             bind:value={searchString}
      />
      <label>Search for Hotels by Sabre Code, Name or Address</label>
      <div class="Icon" data-icon-after="search"></div>
    </div>

    <div class="Menu" >
      <div class="Options">
        {#if $searchData.results && $searchData.results.suggestions }

          {#each $searchData.results.suggestions as r}
            <div class="Option"
                 class:Locked="{r.locked}"
                 class:Selected="{$searchData.selected.indexOf(r.id) !== -1}"
                 data-icon-before="{getSelectedIcon(r, $searchData.selected)}"
                 on:mousedown={() => !r.locked && toggleAdded(r.id)}>
              <div>
                <div class="Title" >{r.name}</div>
                {#if r.sabreCode}
                  <div>Sabre Code: {r.sabreCode || 'NA'} &middot; {r.fullAddress}</div>
                {:else }
                  <div>{r.fullAddress}</div>
                {/if}
              </div>
            </div>
          {/each}

          {#if $searchData.results.hasMore && !$searchData.results.loadingMore}
            <div class="Option Notification">
              <button class="aBtn asLarge asMain atLight"
                      on:mousedown|stopPropagation|preventDefault={() => searchData.more()}>
                More...
              </button>
            </div>
          {/if}
        {/if}

        {#if $searchData.results.loading || $searchData.results.loadingMore}
          <div class="Option Notification">
            <div class="Title">Loading...</div>
          </div>
        {/if}
      </div>

      {#if searchString}
        <div class="NotFound">
          <button class="aBtn atLight asLarge" on:mousedown|preventDefault={requestToAdd}>
            Still cannot find your hotel?
          </button>

          <button class="aBtn atLight asLarge asMain" disabled="{!$searchData.selected.length}" on:mousedown|preventDefault={() => onSave($searchData.selected)}>
            {onSaveLabel}
          </button>
        </div>
      {/if}
    </div>
  </div>
</ClosableModal>

<style lang="stylus">.Header {
  padding-left: 14px;
  display: flex;
  height: 63px;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  line-height: 20px;
  color: #546e7a;
}
.HeaderTitle {
  font-weight: bold;
  font-size: 16px;
  color: #455a64;
}
.Input {
  position: relative;
  margin-top: 10px;
  padding: 0 14px;
}
.Input input {
  background-color: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 6px 6px 6px 6px;
  font-family: "Noto Sans", Sans-serif;
  font-weight: 500;
  color: #37474f;
  font-size: 16px;
  min-height: 47px;
  padding: 6px 16px;
  width: 100%;
  max-width: 100%;
  line-height: 1.4;
  box-sizing: border-box;
  margin: 0;
  outline: none;
}
.Input input::placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}
.Input input:placeholder-shown ~ label {
  opacity: 0;
  top: 0;
}
.Input input ~ label,
.Input input ~ .Icon:after {
  color: #78909c;
}
.Input input:focus {
  border-color: #00a99d;
}
.Input input:focus ~ label,
.Input input:focus ~ .Icon:after {
  color: #00a99d;
}
.Input .WithIcon {
  padding-right: 50px;
}
.Input label {
  position: absolute;
  box-sizing: border-box;
  background: rgba(255,255,255,0.8);
  transition: all 0.2s ease;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1;
  padding-bottom: 4px;
}
.Input .Icon {
  position: absolute;
  top: 0;
  right: 14px;
  line-height: 47px;
  width: 40px;
}
.Input .Icon:after {
  line-height: inherit;
}
.Menu {
  width: 100%;
  color: #90a4ae;
  font-size: 14px;
  line-height: 36px;
  padding: 2px 0;
  margin-top: 15px;
  height: 50vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.Menu .Options {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 0 1 100%;
}
.Menu .Option {
  height: 60px;
  color: #b0bec5;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  background: transparent;
  cursor: pointer;
}
.Menu .Option:before {
  flex: 0 0 50px;
}
.Menu .Option div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgba(144,164,174,0.5);
}
.Menu .Option .Title {
  color: #90a4ae;
}
.Menu .Option:not(.Locked):hover {
  background: rgba(22,39,43,0.1);
  color: #00a99d;
}
.Menu .Option:not(.Locked):hover .Title {
  color: #00b8ab;
}
.Menu .Option.Locked {
  cursor: initial;
}
.Menu .Option.Locked:before {
  color: #546e7a;
}
.Menu .Option.Selected:before {
  color: #00b8ab;
}
.Menu .Option.Selected .Title {
  color: #00b8ab;
}
.Menu .Option.Notification {
  justify-content: center;
  align-items: center;
}
.Menu .Option.Notification:hover {
  background: inherit;
}
.Menu .Option.Notification .Title {
  color: #00b8ab;
}
.Menu .NotFound {
  display: flex;
  color: rgba(144,164,174,0.7);
  padding: 10px;
  font-size: 13px;
  line-height: 15px;
  align-items: flex-end;
  justify-content: space-between;
  flex: 0 0 auto;
}
.Menu .Title,
.Menu .Selected .Title {
  color: #00b8ab;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}
.Menu .Title:before,
.Menu .Selected .Title:before {
  font-size: 20px;
  margin-right: 5px;
}
</style>
