import Vue from 'vue';
import AsyncComputed from 'vue-async-computed';
import VeeValidate from 'vee-validate';
import AddCustomValidators from './rbVeeValidators.js';
import AddCustomMessages from './rbVeeValidatorCustomMessages.js';

export default function VueApp() {
  Vue.use(AsyncComputed)
  Vue.use(VeeValidate)
  AddCustomValidators()
  AddCustomMessages()
}
