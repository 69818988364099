<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';

  export let cancel, letter, mode = 'VIEW';

  let title = mode === 'PREVIEW' ? 'Send "No Thank You" Letter Preview' : 'View "No Thank You" Letter'
</script>


<ClosableModal close="{cancel}" width="600px">
  <div slot="heading">
    <div class="ComponentTitle">{title}</div>
  </div>

  <div class="Letter">
    {@html letter}
  </div>

  <div class="ModalFooter">
    <div>
      <button class="rbButton" on:click="{cancel}">Close</button>
    </div>
  </div>
</ClosableModal>

<style lang="stylus">.ComponentTitle {
  padding: 5px 15px;
  color: #455a64;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
}
.Letter {
  max-height: 60vh;
  margin: 15px;
  padding: 30px 15px;
  overflow: auto;
  font-size: 14px !important;
  color: #455a64 !important;
  text-align: justify;
  border: 1px solid #b0bec5;
}
.Letter :global(p) {
  font-size: 14px !important;
  color: #455a64 !important;
}
.Letter :global(.insertedLogo) {
  max-width: 250px;
  max-height: 150px;
}
.Letter :global(.NoReasons) {
  color: #00a99d;
  font-weight: bold;
}
.Letter :global(.Reasons) {
  padding: 0 15px;
}
.Letter :global(.Message) {
  white-space: pre-wrap;
}
.Letter :global(.Reason) {
  font-weight: bold;
  display: flex;
  margin-bottom: 10px;
}
.Letter :global(.Reason):before {
  content: '-';
  margin-right: 10px;
}
.ModalFooter {
  height: 50px;
  margin-top: 10px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>