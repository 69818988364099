<template>
  <modal-form :class="$style.dialog" @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <span slot="header">Configure {{ template.name }} Table</span>

    <div slot="body" class="vModalDefaultBody">

      <template v-for="action in template.actions">

        <rb-input v-if="action.type === 'filter'">
          <span slot="label">{{ action.label }}</span>
          <rb-select v-model="filters[action.id]" class="simple">
            <rb-option v-for="option in action.options" :value="option.filter" :key="option.label">
              {{ option.label }}
            </rb-option>
          </rb-select>
        </rb-input>

        <rb-input v-else-if="action.type === 'toggle'"
                  :error="$showError(action.id)">
          <span slot="label">{{ action.label }}</span>
          <rb-checkboxes :field="fields[action.id]"
                         :name="action.id"
                         v-model="filters[action.id]"
                         v-validate="'required'"
                         :data-vv-as="action.label">
            <rb-checkbox v-for="option in action.options" :value="option.id" :key="option.id">{{ option.label }}</rb-checkbox>
          </rb-checkboxes>
        </rb-input>

      </template>

    </div>
  </modal-form>
</template>

<script>
  import { cloneDeep } from 'lodash'
  import ModalForm from '../../_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbSelect from '../../_core/RbSelect.vue'
  import RbCheckboxes from '../../_core/RbCheckboxes.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'

  export default {
    name: 'rb-questionnaire-edit-configure-table-dialog',

    mixins: [ RbFormMixin, DialogMixin ],

    components: {
      RbInput,
      ModalForm,
      RbSelect,
      RbCheckboxes
    },

    props: [ 'template', 'config' ],

    data () {
      return {
        filters: {}
      }
    },

    methods: {
      onSubmit () { return { id: this.template.id, data: this.filters } },

      initLocalConfig ( config ) { this.filters = cloneDeep( config.data ) }
    },

    watchers: { config ( val ) { this.initLocalConfig(val) } },

    created () { this.initLocalConfig( this.config ) }
  }
</script>

<style lang="stylus" module >
  .dialog {
    min-width: 400px
  }
</style>
