import Dialog from './Dialog'
import TargetedTooltip from './TargetedTooltip.svelte';
class DialogService {
  constructor() {
    this.$$shownDialogs = [];
    this.containerElementId = 's-dialog-container';
    this.containerElement = document.getElementById(this.containerElementId);
    document.addEventListener('STATE_TRANSITION', () => this.cancelAll());
  }

  show(dialogComponent, props, options){
    const dialog = new Dialog( this.containerElement, dialogComponent, props, options );
    this.$$shownDialogs.push(dialog);
    return dialog.promise
  }

  showNotification(dialogComponent, props, options){
    const dialog = new Dialog( this.containerElement, dialogComponent, props, options );
    this.$$shownDialogs.push(dialog);
    return () => {
      dialog.hide();
      const index = this.$$shownDialogs.indexOf(dialog);
      if(index > -1)
        this.$$shownDialogs.splice(index, 1);
    }
  }

  showTooltip(target, component, props, options = {}){
    const dialog = new Dialog( this.containerElement, TargetedTooltip, {props, target, component}, { closeOnOutsideClick: true, ...options} );
    this.$$shownDialogs.push(dialog);
    return dialog.promise
  }

  cancel(reason){
    const dialog = this.$$shownDialogs.pop();
    return dialog.cancel(reason)
  }

  cancelAll(){
    while(this.$$shownDialogs.length){
      this.cancel();
    }
  }

  hide(reason){
    const dialog = this.$$shownDialogs.pop();
    return dialog.hide(reason)
  }
}

export default DialogService
