<template>
  <div class="manage-accounts-row flex row">
    <div class="logo">
      <img
        v-if="account.logo"
        :src="logo"
        alt="Account Logo"
      >
      <div v-else>
        <i class="material-icons">business</i>
      </div>
    </div>
    <div class="details flex column">
      <div class="name">
        {{ account.name }}
      </div>
      <div
        class="top flex"
        :class="{unassigned: !primary}"
      >
        <template v-if="primary">
          <div class="companies">
            Assigned To <b> {{ primary.fullName }} </b>
          </div>
        </template>
      </div>
      <div
        class="contact flex"
        v-if="contact"
      >
        <div>{{ contact.emailAddress }}</div>
        <span
          v-if="contact.emailAddress && contact.phone"
          class="dot"
        >
          &middot;
        </span>
        <div>{{ contact.phone }}</div>
      </div>
    </div>
    <div class="actions flex row">
      <template v-if="currentUserIsAdmin || isAssignedToCurrentUser">
        <dropdown-actions
          :items="actions"
          :value="{}"
          class="light"
          @input="executeAction"
        >
          <template slot="value">
            <i class="material-icons">settings</i>
            Manage
          </template>
        </dropdown-actions>
      </template>
    </div>
  </div>
</template>

<script>
  import dropdownActions from 'vRoot/rfp-manager/core/rfp-manager-dropdown-actions.vue';
  import manageAccountService from '../services/manage-company.service';

  export default {
    name: 'Account',
    components: {dropdownActions},
    props: {
      account: {
        type: Object,
        required: true
      },
      currentUserIsAdmin: {
        type: Boolean,
        required: true
      },
      isCurrentAccount: {
        type: Boolean,
        required: true
      },
      isAssignedToCurrentUser: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        accountActions: [
          {
            id: 'primary',
            icon: 'perm_contact_calendar',
            label: 'Assign To',
            action: this.assignments,
            isApplicable: () => this.currentUserIsAdmin
          },
          {
            id: 'edit',
            icon: 'edit',
            label: 'Company Information',
            action: !this.isCurrentAccount? this.edit: this.editCompanyProfile,
            isApplicable() { return true }
          },
          {
            id: 'contact',
            icon: 'person',
            label: 'Edit Primary Contact',
            action: this.editPrimaryContact,
            isApplicable() { return true },
          },
          {
            id: 'logo',
            icon: 'image',
            label: 'Change Logo',
            action: this.editLogo,
            isApplicable() { return true }
          },
          {
            id: 'rateLoading',
            icon: 'rate_review',
            label: 'Rate Loading Information',
            action: this.rateLoadingInformation,
            isApplicable() { return true }
          },
          {
            id: 'directory',
            icon: 'list',
            label: 'View Hotel Directory',
            action: this.hotelDirectory,
            isApplicable() { return true }
          },
          {
            id: 'delete',
            icon: 'delete',
            label: 'Delete Company',
            action: this.drop,
            isApplicable: () => !this.isCurrentAccount && this.currentUserIsAdmin,
          }
        ]
      }
    },
    computed: {
      logo() { return `/images/logos/${this.account.logo}` },
      primary() {
        return this.account.primaryAgent.userDetails;
      },
      contact() { return this.account.primaryContact; },
      actions() {
        return this.accountActions.filter(action => action.isApplicable());
      },
    },
    methods: {
      executeAction(item) {
        return item && item.action && item.action(this.account);
      },
      edit(){
        this.$emit('edit');
      },
      assignments(){
        return this.$emit('assignments');
      },
      drop(){
        return this.$emit('drop');
      },
      editCompanyProfile() {
        manageAccountService.editMyCompanyProfile();
      },
      editLogo() {
        return this.$emit('editLogo');
      },
      editPrimaryContact() {
        return this.$emit('editPrimaryContact');
      },
      hotelDirectory() {
        return this.$emit('hotelDirectory');
      },
      rateLoadingInformation() {
        return this.$emit('rateLoadingInformation');
      }
    }
  }
</script>

<style lang="stylus">
  .manage-accounts-row{
    padding 10px 20px;
    border-bottom: 1px solid #ECEFF1;
    height 100px;
    box-sizing border-box;

    .details{
      flex 1;

      .top{
        font-size: 12px;
        align-items: center;
        color: #8a999f;

        & > *{
          margin-right 5px;
        }

        i.material-icons{
          background: #00b8aa;
          color: #fff;
          border-radius: 100%;
          padding: 3px;
          font-size: 14px;

          &.admin{
            background #f25b26;
          }
        }

        .position{
          text-transform uppercase;
        }

        &.unassigned{
          i.material-icons {
            background: unset;
            color: #f25b26;
            padding: 3px;
            font-size 14px;
          }

          font-size 13px;
        }
      }

      &.unverified, &.pending{
        color: #8a999f !important;

        i.material-icons {
          background: unset;
          color: #8a999f;
          padding: 3px;
          font-size 17px;
        }
      }

      span.dot {
        font-size 15px;
        font-weight bolder;
      }

      .name{
        font-size 16px;
      }

      .contact{
        font-size: 12px;
        color: #8d999f;

        & > *{
          margin-right 5px;
        }
      }
    }

    .actions{
      justify-content flex-end;

      .button{
        i.material-icons{
          &.active{
            color #00b8aa;
          }
        }
      }

      .rfp-manager-select{
        margin-left 5px;
      }
    }
  }
</style>
