<template>
  <div :class="$style.head" @click="onClick">
    <div><slot /></div>
    <div v-if="isSorted" class="material-icons" :class="sortClass">sort</div>
  </div>
</template>

<script>
  import rbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue';
  import sortService from './suppliers-sort-service'

  export default {
    name: "rbv-travel-destinations-map-suppliers-table-expanded-view-suppliers-list-sortable",
    mixins: [rbEventsMixin],
    props: [ 'id', 'by' ],
    computed: {
      isSorted() { return this.by.id === this.id },
      sortClass() { return this.$style['sort-'+this.by.order]}
    },
    methods: {
      onClick() { this.$rbEmit(sortService.createSortEvent(this.id)) }
    },
  }
</script>

<style lang="stylus" module>

  .head {
    display flex
    align-items center
    cursor pointer
    height: 38px
  }

  .sort {
    color #546E7A
    font-size 18px
    height 18px
    margin-left 5px
  }

  .sort-asc {
    composes sort
    transform rotateX(180deg)
  }

  .sort-desc {
    composes sort
  }
</style>
