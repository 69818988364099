import merge from 'lodash/merge';

const
  SANITIZE = 'sanitize',
  ALLOW = 'allow';

export default HtmlWhitelistedSanitizer;
export { ALLOW, SANITIZE };

const
  defaultConfiguration = {
    escapeUndesirableContent: true,
    allowedAttributes: {
      'dir': ALLOW,
      'lang': ALLOW,
      'title': ALLOW
    },
    allowedTags: {
      'a': {
        'download': ALLOW,
        'href': ALLOW,
        'hreflang': ALLOW,
        'ping': SANITIZE,
        'rel': ALLOW,
        'target': ALLOW,
        'type': ALLOW,
      },
      'img': {
        'alt': ALLOW,
        'height': ALLOW,
        'src': ALLOW,
        'width': ALLOW,
      },
      'p': {},
      'div': {},
      'span': {},
      'br': {},
      'b': {},
      'i': {},
      'u': {},
    },
    allowedCss: [
      'border',
      'margin',
      'padding',
    ],
    allowedUrls: [],
    debug: false,
  };

/**
 * @name HtmlSanitizer
 *
 * @description
 * Sanitizer which filters a set of whitelisted tags, attributes and css.
 *
 * @usage:
 * new HtmlSanitizer(configuration).sanitizeString(htmlString);
 *
 * @param {Object} configuration Sanitizer configuration with whitelist of tags, attributes, styles and urls
 * @param {boolean} configuration.escapeUndesirableContent Should unlisted tags be removed or escaped
 * @param {boolean} configuration.debug Show debug messages
 * @param {Object} configuration.allowedTags Allowed HTML Tags
 * @param {Object} configuration.allowedAttributes Attributes Allowed globally - on all allowed tags
 * @param {Object} configuration.allowedCss Allowed styles
 * @param {Array} configuration.allowedUrls list of strings that links can start with.
 * @constructor
 */
function HtmlWhitelistedSanitizer(configuration = {}) {

  Object.assign(this, configure( merge( {},  defaultConfiguration, configuration )) );

  // Use the browser to parse the input but create a new HTMLDocument.
  // This won't evaluate any potentially dangerous scripts since the element
  // isn't attached to the window's document. It also won't cause img.src to
  // preload images.
  //
  // To be extra cautious, you can dynamically create an iframe, pass the
  // input to the iframe and get back the sanitized string.
  this.doc = document.implementation.createHTMLDocument();
}

function configure( rawConfiguration ){
  if(rawConfiguration.debug){
    // eslint-disable-next-line
    console.info(rawConfiguration);
  }
  const
    allowedTags = {},
    config = {
      escape: rawConfiguration.escapeUndesirableContent,
      allowedCss: [ ...rawConfiguration.allowedCss ],
      allowedTags: allowedTags
    },
    allowFn =  ( x ) => x,
    sanitizeFn = ( str ) =>
      (str && rawConfiguration.allowedUrls.some( ( url ) => str.startsWith( url ) )) ? str : '',
    globalAttributes = mapAttributesToFunctions(rawConfiguration.allowedAttributes, allowFn, sanitizeFn);

  Object.keys(rawConfiguration.allowedTags).forEach( tagKey => {
    allowedTags[tagKey] = {
      ...globalAttributes,
      ...mapAttributesToFunctions(rawConfiguration.allowedTags[tagKey], allowFn, sanitizeFn)
    };
  });

  return config;
}

function mapAttributesToFunctions(attributes, allowFn, sanitizeFn){
  return Object.keys(attributes).reduce( ( acc, key ) => {
    const keyPermission = attributes[key];
    if(keyPermission === ALLOW){
      acc[key] = allowFn;
    } else if(keyPermission === SANITIZE){
      acc[key] = sanitizeFn;
    }
    return acc;
  }, {});
}

HtmlWhitelistedSanitizer.prototype.sanitizeString = function(input) {
  const div = this.doc.createElement('div');
  div.innerHTML = input;
  return this.sanitizeNode(div).innerHTML;
}

HtmlWhitelistedSanitizer.prototype.sanitizeNode = function(node) {
  const node_name = node.nodeName.toLowerCase();

  if (node_name === '#text') {
    return node;

  } else if (node_name === '#comment') {
    return this.doc.createTextNode('');

  } else if (this.allowedTags.hasOwnProperty(node_name)) {
    const copiedElement = this.doc.createElement(node_name);
    copyAllowedAttributes(copiedElement, node, this.allowedTags[node_name], this.debug);
    copyAllowedCss(copiedElement, node, this.allowedCss);

    while (node.childNodes.length > 0) {
      const child = node.removeChild(node.childNodes[0]);
      copiedElement.appendChild(this.sanitizeNode(child));
    }
    return copiedElement;

  } else {
    if(this.debug){
      // eslint-disable-next-line
      console.info(`HTML Sanitize forbidden tag: <${node_name}>`);
    }
    return this.doc.createTextNode(this.escape ? node.outerHTML : '');
  }
}

function copyAllowedAttributes(targetElement, sourceNode, allowedAttributes, debug){
  const
    nodeAttributes = sourceNode.attributes;

  for (let n_attr=0; n_attr < nodeAttributes.length; n_attr++) {
    const attr = nodeAttributes.item(n_attr).name;

    if (allowedAttributes.hasOwnProperty(attr)) {
      const sanitizer = allowedAttributes[attr];
      targetElement.setAttribute(attr, sanitizer(sourceNode.getAttribute(attr)));
    } else if (debug && attr !== 'style'){
      // eslint-disable-next-line
      console.info(`HTML Sanitize forbidden attribute: <${sourceNode.nodeName.toLowerCase()} ${attr}="" >`);
    }
  }
}

function copyAllowedCss(targetElement, sourceNode, allowedCss){
  allowedCss.forEach( ( css ) => {
    targetElement.style[css] = sourceNode.style[css];
  });
}
