<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import moment from 'moment';

  export let contact, selected;

  const
    dispatch = createEventDispatcher();

  let
    element = null,
    image = contact.isUser && contact.profilePicture ? `/images/users/${ contact.profilePicture }`: null,
    isEmailAddressInvalid = ['BOUNCED', 'COMPLAINT'].indexOf(contact.emailAddressStatus) !== -1,
    lastActive;

  $: lastActive = contact.isUser && contact.lastActive ? moment(contact.lastActive).format('MMMM Do YYYY') : null;

  function edit() {
    dispatch('edit');
  }

  function select() {
    if(!selected) dispatch('select');
  }

  onMount(() => {
    if(selected) element.scrollIntoView();
  });
</script>

<div
  bind:this={element}
  class="ContactItem"
  class:Selected={selected}
  class:BadEmail={!selected && isEmailAddressInvalid}
  on:click={select}
>
  <i class="Radio material-icons">
    {selected ? 'radio_button_checked' : (isEmailAddressInvalid ? 'dangerous' : 'radio_button_unchecked')}
  </i>

  <div class="WhiteBG">
    <div class="ContactDetails">
      <div class="setSupplierContactItemPictureImgContainer">
        {#if image}
          <img
            class="setSupplierContactItemPictureImg"
            src={image}
            alt="{contact.firstName}"
            on:error={() => image = null}
          />
        {:else}
          <div data-icon-before="person"></div>
        {/if}
      </div>

      <div class="DataDisplay">
        <div class="setSupplierContactSupplierName">
          {contact.firstName} {contact.lastName}
          {#if contact.jobTitle}<span>({contact.jobTitle})</span>{/if}
        </div>

        <div class="setSupplierContactItemStatus">
          {#if isEmailAddressInvalid}
            <div class="BadEmailNotification" data-icon-before="mail" data-icon-after="help">
              <span style="text-decoration: line-through">{contact.emailAddress}</span>
              <div class="rbTooltip BadEmailNotificationTooltip">
                ReadyBid has found this to be an invalid or bad email address
              </div>
            </div>
          {:else if contact.isUser}
            <i
              class="material-icons setSupplierContactItemStatusIcon
              setSupplierContactItemStatusIconVerified">
              check_circle
            </i>
            {#if lastActive}
              <div class="LastActiveContainer">
                Last Active &nbsp;
                <span class="LastActive">{lastActive}</span>
              </div>
            {:else}
              <span class="setSupplierContactItemStatusLabel">Verified User</span>
            {/if}
          {:else}
            <i class="material-icons setSupplierContactItemStatusIcon">done</i>
            <span class="setSupplierContactItemStatusLabel">Unverified User</span>
          {/if}
        </div>
      </div>
    </div>
    <div class="Info">
      <div class="InfoDetails">
        {#if isEmailAddressInvalid}
          {#if contact.isUser}
            <i
              class="material-icons setSupplierContactItemStatusIcon
              setSupplierContactItemStatusIconVerified">
              check_circle
            </i>
            {#if lastActive}
              <div class="LastActiveContainer">
                Last Active &nbsp;
                <span class="LastActive">{lastActive}</span>
              </div>
            {:else}
              <span class="setSupplierContactItemStatusLabel">Verified User</span>
            {/if}
          {:else}
            <i class="material-icons setSupplierContactItemStatusIcon">done</i>
            <span class="setSupplierContactItemStatusLabel">Unverified User</span>
          {/if}
        {:else}
          <div class="InfoDetail" data-icon-before="email">
            <span>{contact.emailAddress}</span>
          </div>
        {/if}
        {#if contact.phone}
          <div class="InfoDetail" data-icon-before="phone">
            <span>{contact.phone}</span>
          </div>
        {/if}
      </div>
      {#if !contact.isUser}
        <div class="InfoDetail Edit" on:click|stopPropagation={edit}>
          <i class="material-icons">edit</i>
          Edit
        </div>
      {/if}
    </div>
  </div>
</div>


<style lang="stylus">.ContactItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.ContactItem.BadEmail {
  cursor: not-allowed;
}
.Radio {
  color: #b0bbc5;
  margin-right: 10px;
  max-width: 20px;
}
.Selected .Radio {
  color: #01a99d;
}
.BadEmail .Radio {
  color: #f15a24;
}
.WhiteBG {
  padding: 10px;
  background: #f6f7f9;
  border-radius: 5px;
  flex: 1;
  margin: 5px;
  width: 490px;
  box-sizing: border-box;
  overflow: hidden;
}
.ContactDetails {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.ContactItem.Selected .WhiteBG,
.ContactItem:hover .WhiteBG {
  background: #fff;
}
.setSupplierContactItemStatusIcon {
  color: #b0bbc5;
  font-size: 18px;
}
.setSupplierContactItemStatusIconVerified {
  color: #01a99d;
}
.setSupplierContactItemStatusLabel {
  font-size: 12px;
  color: #78909c;
}
.LastActive {
  font-size: 12px;
  color: #01a99d;
}
.setSupplierContactSupplierName {
  font-size: 15px;
  line-height: 18px;
  color: #455a64;
  font-weight: bold;
}
.setSupplierContactSupplierName span {
  font-weight: normal;
  font-size: 12px;
}
.LastActiveContainer {
  font-size: 12px;
  color: #78909c;
  display: flex;
  align-items: center;
}
.setSupplierContactItemStatus {
  display: flex;
  align-items: center;
}
.setSupplierContactItemPictureImgContainer {
  height: 48px;
  width: 48px;
  border: 1px solid #fff;
  border-radius: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  background: #e7ebed;
  color: #b0bec5;
}
.setSupplierContactItemPictureImg {
  height: 48px;
  min-width: 48px;
}
.Info {
  margin-top: 10px;
  display: flex;
  font-size: 12px;
  color: #8a9ea9;
  position: relative;
}
.InfoDetails {
  flex: 1;
  display: flex;
  min-width: 0;
  overflow: hidden;
}
.InfoDetail {
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
}
.InfoDetail:before {
  font-size: 18px;
  margin-right: 3px;
}
.InfoDetail span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.InfoDetail i {
  font-size: 15px;
  margin-right: 5px;
  opacity: 0.8;
}
.Edit {
  justify-content: flex-end;
  min-width: 0;
  cursor: pointer;
  font-size: 13px;
}
.Edit:hover {
  text-decoration: underline;
}
.BadEmailNotification {
  font-size: 13px;
  color: #f15a24;
}
.BadEmailNotification:before {
  font-size: 18px;
  margin-right: 5px;
}
.BadEmailNotification:after {
  font-size: 18px;
  margin-left: 5px;
}
.BadEmailNotificationTooltip {
  max-width: 400px;
  background: #f15a24;
  color: #fff;
}
</style>
