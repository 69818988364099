<script>
  import { onMount, onDestroy } from 'svelte';
  import { slide } from 'svelte/transition';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import ModalButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import TextArea from 'root/components/molecules/inputs/TextArea.labeled.svelte';

  export let cancel, hide, error, explanation = '';


  onMount(() => {
  });

  onDestroy(() => {
  });

  function handleChange(v){
    explanation = v;
    error = v ? undefined : 'Explanation is required!';
  }

  function submit(){
    handleChange(explanation);
    if(!error) hide(explanation);
  }

</script>


<ClosableModal width="600px" close="{() => cancel()}">
  <div slot="heading">
    <ModalTitle title="Reason for the response update">
      Please provide explanation for the modification of the response.
    </ModalTitle>
  </div>

  <div class="body" >
    <div class:InputError="{error}"></div>
    <TextArea value="{explanation}"
              placeholder="Explanation"
              maxLength="1000"
              error="{error}"
              on:input="{ev => handleChange(ev.detail)}">
      Explanation
    </TextArea>

    {#if error}
      <div class="ErrorContainer" transition:slide={{}}>
        <div class="Error">
          { error }
        </div>
      </div>
    {/if}
  </div>


  <ModalButtons disabled="{error}"
                noAction="{() => cancel()}"
                yesLabel="Confirm"
                yesIcon="thumb_up_alt"
                yesAction="{ () => submit() }"
  />
</ClosableModal>

<style lang="stylus">.body {
  padding: 0 15px 30px 15px;
}
.InputError {
  color: #f15a24;
  border-color: #f15a24;
}
.ErrorContainer {
  padding-top: 5px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  height: 50px;
  box-sizing: border-box;
}
.Error {
  background: #f15a24;
  border-radius: 5px;
  padding: 5px;
}
</style>