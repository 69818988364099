/**
 * Created by DejanK on 4/5/2017.
 */
import './bid-manager.scss'
import HTML from './bid-manager.html'
import {initialize, default as bmStore} from '../../stores/bid-manager';
import noop from 'lodash/noop';

export { HTML, BidManagerController as Controller }

BidManagerController.$inject = ['NegotiationsPanelMessagingService', 'NotificationUtils', '$state', '$scope', 'BidManagerRepository'];
function BidManagerController(negotiationsPanelMessagingService, notificationUtils, $state, $scope, bidManagerRepository){
  const vm = this;
  vm.bm = {};
  let bmStoreUnsubscribe;

  this.$onInit = function(){
    const config = {
      viewId : $state.params.viewId,
      rfpId: $state.params.rfp,
      negotiations: $state.params.negotiations,
      mostRecent: !!$state.params['most-recent']
    };

    initialize(config)
      .then(loadBidManagerViews)
      .then(() => showNegotiations(config.negotiations))
      .catch(noop);

    bmStoreUnsubscribe = bmStore.subscribe($bms => {
      vm.bm = {
        ...vm.bm,
        selectedBids: [],
        view: $bms.view,
        bids: $bms.bids,
        bidsLoaded: $bms.bidsLoaded,
      }
      $scope.$applyAsync();
    });

    $scope.$on('$destroy', () => { if(bmStoreUnsubscribe) bmStoreUnsubscribe() });
  }

  function loadBidManagerViews(){
    return bidManagerRepository.getUserViews()
      .then(r => {
        vm.views = r;
      })
      .catch(noop)

  }

  function showNegotiations(bidId){
    return bidId && negotiationsPanelMessagingService.openNegotiationsPanel(bidId);
  }
}
