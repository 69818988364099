<script>
  export let rfps, queryString, toggleBookmark;
</script>

<div class="Component">
  <div class="Header">
    <div class="Search" data-icon-after="search">
      <input class="SearchInput"
             placeholder="Search RFPs"
             bind:value="{queryString}"
      />
    </div>
  </div>

  <div class="ListContainer">
    {#each rfps as rfp}
      <div class="ListItem">
        <div class="Bookmark"
             data-icon-before="{rfp.bookmark ? 'bookmark' : 'bookmark_border'}"
             on:click="{toggleBookmark(rfp._id)}"
        >
          <div class="rbTooltip Tooltip">
            <div>{rfp.bookmark ? 'Remove Bookmark' : 'Bookmark RFP'}</div>
          </div>
        </div>

        <div class="MainArea">
          <a class="ItemTitle" href="{rfp.href}" target="_blank">{rfp.name}</a>

          <div class="rbTooltip Tooltip">
            <div class="TooltipTitle">{rfp.name}</div>
            <div>{rfp.programYear}: {rfp.buyerCompanyName}</div>
            <div>{rfp.contactFullName} @ {rfp.contactCompanyName}</div>
            <br />
            <div>Total Bids: {rfp.totalBids}</div>
            <div>Responded Bids: {rfp.completed}%</div>
            <div>Due Date: {rfp.dueDateF}</div>
          </div>
        </div>

        <div class="CompletedArea">
          {rfp.completed}%
        </div>
      </div>
    {:else}
      <div class="NoResults">No Results</div>
    {/each}
  </div>
</div>

<style lang="stylus">.Component {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.Header {
  background: #00a99d;
  flex: 0 0 auto;
  display: flex;
  padding: 15px 10px;
}
.Search {
  line-height: 30px;
  background: #fff;
  color: #546e7a;
  width: 100%;
  border-radius: 6px;
  padding: 3px 10px;
}
.SearchInput {
  border: 0;
  outline: 0;
  font-size: 14px;
  font-family: 'Noto Sans';
  color: inherit;
  width: 100%;
}
.ListContainer {
  overflow: auto;
}
.ListItem {
  padding: 5px;
  margin-top: 5px;
  color: #546e7a;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  text-shadow: 0 0 1px rgba(0,0,0,0.25);
}
.Bookmark {
  flex: 0 0 30px;
  justify-content: flex-start;
  align-items: flex-start;
  color: #00a99d;
  cursor: pointer;
  filter: drop-shadow(3px 3px 1px rgba(0,169,157,0.3));
}
.Bookmark:hover {
  filter: none;
}
.MainArea {
  flex: 1 1 100%;
  padding-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ItemTitle {
  color: inherit;
  font-size: inherit;
}
.CompletedArea {
  flex: 0 0 40px;
  font-size: 16px;
  color: #546e7a;
  font-weight: bold;
  justify-content: flex-start;
  align-items: flex-start;
}
.Tooltip {
  background: #fff;
  color: #546e7a;
  font-size: 13px;
  line-height: 1.3;
  box-shadow: 1px 1px 5px 3px rgba(0,0,0,0.2);
}
.TooltipTitle {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.6;
}
.NoResults {
  text-align: center;
  padding: 20px 0;
  color: #78909c;
}
</style>