<template>
    <div class="rbv-ng-inactive-accounts">
        <div class="page">
            <div class="content">
                <div class="layout-column flex-center" >
                    <div class="flex">
                        <i class="material-icons">info_outline</i>
                        <div class="flex column">
                            <div class="title">Hmmm,</div>
                            <div class="sub-title">No Bid Manager</div>
                        </div>
                    </div>
                    <div>
                        <p>
                            {{user.firstName}}
                        </p>
                        <p>
                            Currently your administrator has not assigned any accounts to you, so you are not allowed to view RFP or Bid data. We am sure that will soon change.
                        </p>
                        <p>
                            {{user.firstName}}, in an effort to helo you understand ReadyBid and all it can do we have prepared the ReadyBid <a href="https://invis.io/QVV3NJ78SRD#/396121604_Corporate_User_Guide-01">TMC Tutorial</a> for your review
                        </p>
                        <p>ReadyBid Support</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InactiveAccountPage',
    props: {
        user: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style lang="stylus">
    .rbv-ng-inactive-accounts{
        background: #F5F6F7 url('~images/ui/auth-background.png');
        background-size: cover;
        height calc(100vh - 61px) !important;
        overflow auto;

        .page{
            height: 100%;
            width: 100%;
            position: relative;

            .content{
                background: #fff;
                box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
                padding: 10px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 3px;
                color: #546E7A;
                display: flex;
                width 400px;

                .flex-center{
                    flex 1;
                }

                .flex{
                    width: 100%;
                    display: flex;
                    align-items: center;

                    &.column{
                        flex-direction column;
                        align-items flex-start;
                    }

                    i.material-icons{
                        font-size: 40px;
                        margin-right 10px;
                        color #049e92;
                    }

                    .title{
                        font-size 15px;
                    }

                    .sub-title{
                        font-size: 20px;
                    }
                }

                p{
                    margin 10px 0;
                }
            }
        }
    }
</style>

