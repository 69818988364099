/**
 * Created by DejanK on 3/17/2017.
 */
import angular from 'angular'
import rbFileReader from './rb-file-reader.dir'
import rbMinDyn from './rb-min/rb-min-dyn.dir'
import rbMaxDyn from './rb-max/rb-max-dyn.dir'
import rbFormValidation from './rb-form-validation/rb-form-validation.dir'
import rbUrlInputHelper from './rb-url-input-helper/rb-url-input-helper.dir'
import rbUrlValidator from './rb-url-validator/rb-url-validator.dir'

export default angular.module('rb.ui-form.directives', [])
  .directive('rbFormValidation', rbFormValidation)
  .directive('rbShowFormErrorsOnHover', require('./rb-show-form-errors-on-hover/rb-show-form-errors-on-hover.dir')) // @DEPRECATED use rbFormValidation
  .directive('rbMin', require('./rb-min/rb-min.dir'))
  .directive('rbMinDyn', rbMinDyn)
  .directive('rbMax', require('./rb-max/rb-max.dir'))
  .directive('rbMaxDyn', rbMaxDyn)
  .directive('rbMatch', require('./rb-match/rb-match.dir'))
  .directive('rbHasUppercase', require('./rb-has-uppercase/rb-has-uppercase.dir'))
  .directive('rbHasLowercase', require('./rb-has-lowercase/rb-has-lowercase.dir'))
  .directive('rbHasNumber', require('./rb-has-number/rb-has-number.dir'))
  .directive('rbFileReader', rbFileReader)
  .directive('rbUrlInputHelper', rbUrlInputHelper)
  .directive('rbUrlValidator', rbUrlValidator)
