{#if destination && destinations}
  <div class="Component">
    <div class="View" data-icon-after="{dropDownIcon}"
         on:click={() => menuOpened = true}>
      <div class="Icon">
        <img src="/images/map/office.png" alt="Office" />
      </div>

      <div class="Text">
        <div class="Label Ellipsis">{destination.name}</div>
        <div class="Address Ellipsis">{destination.location.fullAddress}</div>
      </div>
    </div>

    {#if menuOpened}
      <div class="Backdrop" on:click={() => menuOpened = false}></div>

      <div class="Menu">
        {#each destinations.data as td}
          <div class="MenuItem {td.id === destination.id ? 'Active' : ''}" data-icon-before="location_on"
               on:click={() => onSelection(td.id)}>

            <div class="MenuItemText">
              <div class="MenuItemTextTitle Ellipsis">{td.name}</div>
              <div class="Ellipsis">{td.location.fullAddress}</div>
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </div>
{/if}

<script>
  import { onMount } from 'svelte';
  import { $state as stateProvider, RfpDestinationsViewService } from 'root/angular-injector-provider';

  export let rfpId;
  export let destination;
  export let target = 'hotelRfp.rfp.destinations.map';

  let
    destinations,
    dropDownIcon = 'keyboard_arrow_down',
    menuOpened = false;

  $: dropDownIcon = menuOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down';

  onMount( async () => {
    try {
      destinations = await RfpDestinationsViewService().getRfpTravelDestinations(rfpId);
      destinations.data.sort((a,b) => a.name > b.name ? 1 : -1);
    } catch (e) {}
  });

  function onSelection (destinationId) {
    if(destinationId === destination.id) return;
    stateProvider().go(target, { destinationId });
  }
</script>

<style lang="stylus">.Component {
  position: relative;
}
.View {
  display: flex;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  height: 36px;
  min-width: 340px;
  max-width: 340px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  text-align: left;
  overflow: hidden;
  cursor: pointer;
}
.Icon {
  flex: 0;
}
.Text {
  flex: 1 1 100%;
  margin-left: 10px;
  overflow: hidden;
  padding-right: 30px;
}
.Label {
  font-size: 11px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(255,255,255,0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Address {
  font-size: 14px;
  line-height: 23px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.Menu {
  position: absolute;
  left: -10px;
  top: 38px;
  min-width: 340px;
  max-width: 400px;
  max-height: 350px;
  z-index: 101;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.5);
  overflow-y: auto;
  overflow-x: hidden;
  background: #37474f;
  border: 1px solid #16272b;
  border-radius: 5px;
  color: #90a4ae;
}
.MenuItem {
  height: 50px;
  font-size: 12px;
  line-height: 16px;
  transition: all 0ms linear, background 0.2s ease-in-out, color 0.2s ease-in-out;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}
.MenuItem.Active,
.MenuItem:hover {
  background: #263238;
  color: #fff !important;
}
.MenuItem:not(.Active):hover .MenuItemTextTitle {
  color: #00a99d;
}
.MenuItemText {
  margin-left: 12px;
  overflow: hidden;
}
.MenuItemTextTitle {
  color: #cfd8dc;
  text-transform: uppercase;
}
</style>
