/**
 * Created by DejanK on 2/9/2017.
 */

import './input-date.scss';

import Pikaday from 'pikaday';
import Moment from 'moment';

export default{
  template: '<input type="text"/>',
  bindings: {
    minDate: '<',
    maxDate: '<',
    years: '<',
    position: '<',
    format: '@'
  },
  require: {
    ModelCtrl: 'ngModel'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this,
    input = angular.element($element.find('input'));
  let pikaday;


  this.$onInit = () => {
    vm.format = vm.format || 'MM/DD/YYYY';
    pikaday = new Pikaday({
      field: input[0],
      format: vm.format,
      position: vm.position || 'bottom left',
      minDate: parseDate(vm.minDate),
      maxDate: parseDate(vm.maxDate),
      yearRange: vm.years
      //showMonthAfterYear: true,
    });

    input.on('blur focusout onblur', ()=>{
      vm.ModelCtrl.$setViewValue(input.val());
      vm.ModelCtrl.$setTouched();
    });

    vm.ModelCtrl.$validators.valid = (modelValue, viewValue) => {
      const v = modelValue || viewValue;
      return Moment(v).isValid(); // v must be in ISO (YYYY-MM-DD) format
    };

    if(vm.minDate) {
      vm.ModelCtrl.$validators.minDate = (modelValue, viewValue) => {
        const v = Moment(modelValue || viewValue),
          minDate = Moment(vm.minDate);
        return !minDate.isValid() || v.isSameOrAfter(minDate);
      };
    }

    if(vm.maxDate) {
      vm.ModelCtrl.$validators.maxDate = (modelValue, viewValue) => {
        const v = Moment(modelValue || viewValue),
          maxDate = Moment(vm.maxDate);
  
        return !maxDate.isValid() || v.isSameOrBefore(maxDate);
      };
    }

    vm.ModelCtrl.$parsers.unshift((viewValue)=>{
      const m = Moment(viewValue, vm.format);

      if(m.isValid()){
        vm.ModelCtrl.$setValidity('valid', true);
        return m.format('YYYY-MM-DD')
      } else {
        vm.ModelCtrl.$setValidity('valid', false);
        return '';
      }
    });

    vm.ModelCtrl.$render = ()=>{
      const m = Moment(vm.ModelCtrl.$viewValue, 'YYYY-MM-DD');
      if(m.isValid()) {
        // if date is before min date (or possibly after max date) pikaday sets min (max) date
        // to prevent this, input is set after pikaday
        pikaday.setMoment(m);
        input.val(m.format(vm.format));
      } else {
        input.val(vm.ModelCtrl.$viewValue);
      }
    };
  };

  this.$onChanges = (changes) =>{
    changes.minDate && pikaday && onMinDateChanged();
    changes.maxDate && pikaday && onMaxDateChanged();
  };

  function parseDate(date){
    const d = date === 'now' ? Moment() : Moment(date);
    return date && d.isValid() ? d.toDate() : undefined;
  }

  function onMinDateChanged(){
    pikaday.setMinDate(parseDate(vm.minDate));
  }

  function onMaxDateChanged(){
    pikaday.setMaxDate(parseDate(vm.minDate));
  }
}