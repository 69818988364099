<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import Buttons from 'root/services/modals/ModalButtons.Close.svelte';
  import VirtualList from 'root/components/molecules/VirtualList.svelte';
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import QuickSearch from 'root/components/atoms/QuickSearch.svelte';

  import {$state as state, notificationUtils, BidManagerRepository} from 'root/angular-injector-provider';
  import {encodeState} from 'root/shared/tools/view-utils';
  import noop from 'lodash/noop';

  export let rfpId, viewType, hide;

  let destinations, destinationsInView = [], query = '', scrollToTop;

  $: destinationsInView = applyFilter(query, destinations);

  initialize();

  function initialize() {
    destinations = undefined;
    const repository = BidManagerRepository();
    notificationUtils().onLoad(() => {
      return viewType === 'RFP' ? repository.getRfpTravelDestinations(rfpId) : repository.getTravelDestinations()
    })
      .then(response => {
        destinations = response.data;
      })
      .catch(noop)
  }

  function openDestinationMap(td){
    const s = state();
    s.inNew('hotelRfp.rfp.destinations.map', {rfpId: td.rfpId, destinationId: td.id, from: encodeState(s)});
  }

  function applyFilter(filter = '', data = []){
    if(scrollToTop) scrollToTop();
    const f = filter.toLowerCase();
    return f.length ? data.filter(d => d.name.toLowerCase().startsWith(f)) : data;
  }
</script>

<ClosableModal width="500px" close="{hide}">
  <div slot="heading">
    <ModalTitle title="Choose Travel Destination">
      Choose a Travel Destination to add hotels:
    </ModalTitle>
  </div>

  <div class="body">
    {#if !destinations}
      <div class="rbCenter"><Spinner /></div>
    {:else if destinations.length}
      <VirtualList items="{destinationsInView}"
                   let:item="{destination}"
                   itemHeight="{32}"
                   bind:scrollToTop={scrollToTop}
      >
        <div slot="heading" class="ListHeading">
          <QuickSearch placeholder="Filter Destinations by name"
                       on:input={ev => query = ev.detail}
          />
          {#if !destinationsInView.length}
            <div class="rbCenter">
              No Destinations match the filter!
            </div>
          {/if}
        </div>

        <div slot="row" class="DestinationItem" on:click={() => openDestinationMap(destination)}>
          <div class="Name">{destination.name}</div>
          <div class="Rfp">{destination.rfpName}</div>
        </div>
      </VirtualList>
    {:else}
      <div class="rbCenter">
        No Travel Destinations found!
      </div>
    {/if}
  </div>

  <Buttons action="{hide}" />
</ClosableModal>

<style lang="stylus">.body {
  height: 400px;
  border-bottom: 1px solid #dcdee0;
  margin-bottom: 20px;
}
.ListHeading {
  height: 45px;
  padding: 0 15px;
  background: #fff;
  border-bottom: 1px solid #dcdee0;
}
.DestinationItem {
  box-sizing: border-box;
  height: 48px;
  border-bottom: 1px solid #dcdee0;
  cursor: pointer;
  padding: 9px 15px;
}
.DestinationItem:hover {
  background: #eceff1;
}
.Name {
  font-size: 13px;
  color: #37474f;
  font-weight: bold;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Rfp {
  color: #78909c;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.rbCenter {
  font-size: 15px;
  font-weight: bold;
  color: #78909c;
  margin: 20px;
}
</style>
