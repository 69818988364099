/**
 * Created by DejanK on 11/21/2016.
 */
import { get } from 'lodash'
import { GUEST } from "./auth-roles";

export default AuthInterceptor

AuthInterceptor.$inject = ['$rootScope', '$state', 'Auth', 'AuthSession'];
function AuthInterceptor($rootScope, $state, auth, authSession) {

  $rootScope.$on('$stateChangeStart', onStateChange);

  function onStateChange(event, toState, toParams) {
    const authorizedRoles = get(toState, "data.authorizedRoles", []);

    authorizedRoles.length && auth.isAuthorized(authorizedRoles)
      .catch(onAuthorizationFailure);

    function onAuthorizationFailure(){
      event.preventDefault();

      auth.isAuthenticated()
        .then(
          () => { onNotAuthorized(authorizedRoles) },
          () => { onNotAuthenticated(toState.name, toParams) }
        )
    }
  }

  function onNotAuthorized(roles){
    if(roles && roles.indexOf(GUEST) !== -1) {
      $state.go('bidManager', {viewId: null});
    } else {
      $state.go('error', {id: 403});
    }
  }

  function onNotAuthenticated(state, params){
    state !== 'signIn' && authSession.reauthenticate({'toState': state, 'toParams': params})
  }
}
