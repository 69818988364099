<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import YesNoButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import {notificationUtils, mainAPI} from 'root/angular-injector-provider';

  export let cancel, hide, type = '';

  function remove(){
    notificationUtils().onSave(() => mainAPI().userSecurity.removeMfa(type))
      .then(response => hide(response.data));
  }

</script>

<ClosableModal close="{cancel}">
  <div slot="heading">
    <ModalTitle title="Confirm Deletion of MFA Method">
      Please confirm this is the action you'd like to take.
    </ModalTitle>
  </div>

  <YesNoButtons noLabel="Confirm and Remove"
                noAction="{remove}"
                yesLabel="Cancel"
                yesIcon="{null}"
                yesAction="{cancel}"
  />
</ClosableModal>
