<div>
  <div class="Title">{title}</div>
  <div class="Subtitle"><slot /></div>
</div>


<script>
  export let title;
</script>


<style lang="stylus">.Title {
  font-weight: bold;
  font-size: 15px;
  color: #37474f;
  line-height: 24px;
  padding: 15px 15px 0;
}
.Subtitle {
  font-size: 13px;
  line-height: 16px;
  color: #78909c;
  padding: 4px 15px 30px;
}
</style>