<script>
  import BidManagerActionQuestionMixin from './BidManagerActionQuestionMixin.vue'

  export default {
    name: 'rbv-hotel-rfp-bid-manager-force-sending-response-without-rates-question',
    mixins: [ BidManagerActionQuestionMixin ],
    data() {
      return {
        headerText: 'No Rates Provided!',
        messageText: 'There are no rates provided in Client Specific Module.',
        confirmationText: 'I take full responsibility of sending this Response without Rates.'
      }
    },
  }
</script>
