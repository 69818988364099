<RbEmptyHeader>
  <div class="Controls" slot="mainControls">
    <a href="/" on:click|preventDefault|stopPropagation="{() => openState('signIn')}"
       class="aBtn asLarge CustomButton" data-icon="vpn_key">Sign In</a>

    <a href="/" on:click|preventDefault|stopPropagation="{() => openState('signUp')}"
       class="aBtn asLarge CustomButton" data-icon="account_box">Sign Up</a>

    <a href="http://readybidnet.freshdesk.com" target="_blank"
       class="aBtn asLarge CustomButton" data-icon="help">Need Help?</a>
  </div>
</RbEmptyHeader>

<script>
  import RbEmptyHeader from './RbEmptyHeader.svelte';
  import {$state as state} from 'root/angular-injector-provider';

  function openState(stateName, params) {
    state().go(stateName, params, {reload: true});
  }
</script>


<style lang="stylus">.Controls {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 5px;
}
.aBtn.asLarge.CustomButton {
  background: #313c42;
  border-radius: 2px;
  color: #90a4ae;
  height: 36px;
  font-size: 13px;
  border: 1px solid #313c42;
  text-decoration: none;
  margin-top: 10px;
  margin-left: 5px;
}
.aBtn.asLarge.CustomButton:hover {
  color: #cfd8dc;
}
.aBtn.asLarge.CustomButton:focus {
  border-color: #00b8ab;
}
</style>