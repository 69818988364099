<template>
  <rbv-dropdown
    :align="align"
    class="rb-action-select"
    :is-open="open"
    backdrop-style="backdrop"
    @close="toggle"
  >
    <div
      slot="toggler"
      @click="toggle"
    >
      <div
        :class="{inactive: !(match && theValue), dark}"
        class="button control filter"
      >
        <template v-if="!$slots.default">
          <slot name="icon" />
          <label class="label">
            <slot name="label" />
          </label>
          <template v-if="!open">
            {{ match && theValue? match.text: '' }}
          </template>
          <input
            v-else-if="search"
            ref="input"
            v-model="searchTerm"
            class="input"
            @click.stop.prevent
          >
        </template>
        <slot v-else />
        <i class="material-icons">keyboard_arrow_down</i>
      </div>
    </div>
    <ul
      slot="dropdown"
      class="dropdown"
      :class="{dark}"
    >
      <li
        v-for="item in searchedItems"
        :key="item.id"
        :class="{item: true, active: selected(item.id), [sortOrder]: selected(item.id) }"
        @click="select(item.id)"
      >
        {{ item.text }}
        <template v-if="sorter && item.id">
          <i class="material-icons icon2">sort</i>
        </template>
      </li>
    </ul>
  </rbv-dropdown>
</template>

<script>
  import RbvDropdown from 'vRoot/_core/RbDropdown.vue';

  export default {
    name: 'RbHdSelect',
    components: { RbvDropdown },
    props: {
      items: {
        type: Array,
        required: true,
      },
      value: {
        type: [String, Object],
        default: null,
      },
      dark: {
        type: Boolean,
      },
      sorter: {
        type: Boolean,
      },
      align: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean
      },
      search: Boolean
    },
    data() {
      return {
        open: false,
        searchTerm: ''
      };
    },
    computed: {
      match() {
        return this.items.find(item => item.id === this.theValue);
      },
      theValue() {
        return this.sorter? this.value.id : this.value;
      },
      sortOrder() {
        return this.value && this.value.order || 'asc';
      },
      searchedItems() {
        const exp = new RegExp(this.searchTerm, 'i');
        return this.search && this.searchTerm? this.items.filter(({text}) => exp.test(text)): this.items;
      }
    },
    methods: {
      toggle() {
        this.open = !this.disabled && !this.open;
        this.$nextTick().then(() => this.open && this.search && this.$refs.input.focus());
      },
      select(id) {
        if(this.sorter) {
          if(this.theValue === id) {
            const order = this.sortOrder === 'asc'? 'desc': 'asc';
            this.$emit('input', {id, order})
          }else{
            this.$emit('input', {id, order: 'asc'});
          }
        }else{
          this.$emit('input', id);
        }
        this.toggle();
      },
      selected(id) {
        return id === this.theValue;
      },
    },
  };
</script>


<style lang="stylus">
  .rb-action-select{
    border-radius: 50px;
    height 35px;

    &+& {
      margin-left 10px;
    }

    .filter{
      position relative;
      background transparent !important;

      &.inactive{
        color #B0BEC5 !important;
      }

      &:not(.inactive){
        i.material-icons:first-child, .label{
          color #329188;
        }

        .label{
          position: absolute;
          top: -10px;
          font-size: 12px;
          left: 15px;
          background #fff;
        }

        &.dark {
          .label{
            background: #313c42;
          }
        }
      }

      .label{
        transition all .3s;
      }

      *{
        vertical-align middle;
      }
    }

    .backdrop{
      background-color transparent !important;
    }

    &.dark-background {
      .filter{
        background: #263238 !important;
      }
    }

    .item.desc .icon2{
      transform rotateX(180deg);
    }

    .input {
      border: none;
      margin-left: 5px;
      padding: 5px;
      border-radius: 2px;
    }
  }
</style>

