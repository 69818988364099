/**
 * Created by DejanK on 7/3/2017.
 */
import { isNumber, isDate } from 'lodash'
import moment from 'moment'

export { DecimalFormatter, CurrencyFormatter, PercentageFormatter, DateTimeFormatter, DateFormatter, UnitFormatter }

function CurrencyFormatter(currency, nanValue, locale) {
  const localeUsed = locale || 'en-US',
    nanValueUsed = nanValue || '---',
    currencyFormat = new Intl.NumberFormat(localeUsed, {
      style: 'currency',
      currency: currency || 'USD'
    }),
    currencyFormatWhole = new Intl.NumberFormat(localeUsed, {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

  return {
    format (amount) { return isNaN(parseFloat(amount)) ? nanValueUsed : currencyFormat.format(amount) },
    formatWhole(amount) { return isNaN(parseFloat(amount)) ? nanValueUsed : currencyFormatWhole.format(amount) }
  }
}

function DecimalFormatter(nanValue = '---', locale = 'en-US', decimals = 2){
  const formatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: decimals
  });

  return {
    format (amount) { return isNaN(parseFloat(amount)) ? nanValue : formatter.format(amount) }
  }
}

function PercentageFormatter(minimumFractionDigits = 2, maximumFractionDigits = 2, nanValue, locale){
  const localeUsed = locale || 'en-US',
    nanValueUsed = nanValue || '---',
    percentFormat = new Intl.NumberFormat(localeUsed,
      {
        style: 'percent',
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
      });

  return {
    format (amount) { return isNumber(amount) ? percentFormat.format(amount) : nanValueUsed }
  };
}

function DateTimeFormatter(nanValue, locale){
  const localeUsed = locale || 'en-US',
    nanValueUsed = nanValue || '---',
    dateFormat = new Intl.DateTimeFormat(localeUsed,
      { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: '2-digit', minute: '2-digit'
      });
  return {
    format (dateTime) {
      const m = moment(dateTime);
      return m.isValid() ? dateFormat.format(m) : nanValueUsed
    }
  };
}

function DateFormatter(nanValue, locale, config){
  const localeUsed = locale || 'en-US',
    nanValueUsed = nanValue || '---',
    dateFormat = new Intl.DateTimeFormat(localeUsed,
      config || { year: 'numeric', month: 'numeric', day: 'numeric' });
  return {
    format: (date)=>{
      const m = moment(date);
      return m.isValid() ? dateFormat.format(m) : nanValueUsed
    }
  };
}

// https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
function UnitFormatter(unit = '', options = {}){
  const
    localeUsed = options.localeUsed || 'en-US',
    nanValue = options.nanValue || '---',
    Format = new Intl.NumberFormat(localeUsed, {
      style: 'unit',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      unit,
      ...options
    });

  return {
    format: (number) => isNumber(number) ? Format.format(number) : nanValue
  }
}
