/**
 * Created by DejanK on 10/03/2017.
 *
 */
import HTML from './rb-rate-loading-information-editor.html';
import './rb-rate-loading-information-editor.scss';
import '../../../../rfp-hotel/main/rfp/pages/final-agreement/view-final-agreement.scss';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {stopEvent} from 'root/shared/tools/view-utils';

export default {
  template: HTML,
  controller: Controller,
  bindings: {
    model: '<',
    onSubmit: '&',
    onCancel: '&'
  }
};

Controller.$inject = [];
function Controller(){
  const vm = this;
  let initialModel;

  newFormModel();

  vm.$onChanges = $onChanges;
  vm.addInfo = addInfo;
  vm.removeInfo = removeInfo;
  vm.cancel = cancel;
  vm.submit = submit;
  vm.onChange = onChange;

  function $onChanges(changes) {
    changes.model && changes.model.currentValue && setModel(changes.model.currentValue);
  }

  function addInfo(){
    vm.formModel.information.push(newInformation());
    updateCanRemoveInfo();
    updateCanAddInfo();
  }

  function removeInfo(index){
    vm.formModel.information.splice(index, 1);
    updateCanRemoveInfo();
    updateCanAddInfo();
  }

  function cancel(){
    vm.onCancel();
  }

  function isEmpty(trimmedInformation) {
    return !trimmedInformation.length && !vm.formModel.ignored;
  }

  function submit($event){
    stopEvent($event);
    const trimmedInformation = trimInformation();
    if(isEmpty(trimmedInformation)) {
      vm.rateLoadingEmptyError = true;
    } else {
      const modelToSave = {
        information: trimmedInformation,
        ignored: vm.formModel.ignored,
      };

      isEqual(initialModel, modelToSave) ? cancel() : vm.onSubmit({$event: { model: modelToSave }});
    }
  }

  function onChange(){
    if(vm.rateLoadingEmptyError){
      vm.rateLoadingEmptyError = isEmpty(trimInformation());
    }
  }

  function newFormModel() {
    vm.formModel = {
      information: [ newInformation() ],
      ignored: false,
    };
    updateCanRemoveInfo();
    updateCanAddInfo();
  }

  function setModel(modelChanges){
    initialModel = Object.assign({}, modelChanges);
    vm.formModel = cloneDeep(modelChanges);
    updateCanRemoveInfo();
    updateCanAddInfo();
  }

  function newInformation(){
    return {
      'arcIatas': '',
      'pseudoCityCode': '',
      'gdsName': '',
      'rateAccessCode': ''
    };
  }

  function updateCanRemoveInfo(){
    vm.canRemoveInfo = vm.formModel.information.length > 1;
  }

  function updateCanAddInfo(){
    vm.canAddInfo = vm.formModel.information.length < 10;
  }

  function trimInformation() {
    const trimmed = [];

    vm.formModel.information.forEach(info => {
      let isInfoEmpty = true;

      Object.entries(info).forEach(entry => {
        let value = entry[1];

        if (!isNil(value)) {
          value = trim(value);
          isInfoEmpty = isInfoEmpty && value.length === 0;
          entry[1] = value;
        }
      });

      !isInfoEmpty && trimmed.push(info);
    });

    return trimmed;
  }
}
