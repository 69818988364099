<template>
  <rbv-dropdown
    :class="$style.filter"
    :is-open="dropDownOpen"
    @close="closeDropDown"
    class="tdMapChainFilterDropDownContainer"
  >

    <template slot="toggler">
      <button class="rbButton"
              :class="headerText.length ? $style.togglerDefault : $style.togglerEmpty"
              @click="toggleDropDown($event)">
        <i class="material-icons" :class="dropDownOpen && $style.openedIcon">location_city</i>
        <span :class="$style.togglerText"> {{ headerText || 'Filter by Chain/Brand...' }}</span>
        <i class="material-icons" :class="dropDownOpen ? $style.dropdownOpenArrow : $style.dropdownArrow">arrow_drop_down</i>
      </button>
      <button v-if="headerText" class="rbButton flex-hold" :class="$style.clearFilter" @click="clearFilters">
        <i class="material-icons">close</i>
      </button>
    </template>

    <div slot="dropdown" :class="$style.dropdownContainer">
      <chains-search :class="$style.search" :query="query" @changed="applyQuery"/>

      <div :class="$style.header">
        <rb-checkbox :checked="allSelected" :indeterminate="someSelected" @change="onAllSelectedChanged"
                     :class="$style.headerCheckbox"/>
        <span :class="$style.headerLabel1">Select chain</span>
        <span :class="$style.headerLabel2">Brand</span>
      </div>

      <div :class="$style.list">
        <master-chain v-for="chain in queryFilteredMasterChains" :key="chain.id" :chain="chain" :listExpanded="listExpanded"/>
      </div>

      <div :class="listExpanded ? $style.listExpandedFooter : $style.listClosedFooter">
        <button class="rbButton" @click="cancel">Cancel</button>
        <button class="rbButton mainButton" @click="apply">
          <i class="material-icons">done</i>
          <span>Apply</span>
        </button>
      </div>
    </div>

  </rbv-dropdown>
</template>

<script>
  import RbvDropdown from 'vRoot/_core/RbDropdown.vue';
  import ChainsSearch from './ChainsSearch.vue';
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import { orderBy } from 'lodash';
  import {stopEvent} from "root/shared/tools/view-utils";
  import MasterChain from './MasterChain.vue';
  import RbCheckbox from 'vRoot/_core/rbv-input/RbCheckbox.vue';

  export default {
    name: 'rbv-destinations-map-chains-filter',
    components: { RbvDropdown, ChainsSearch, MasterChain, RbCheckbox },
    mixins: [ RbEventsMixin ],
    props: ['filter', 'chains'],
    data: function() {
      return {
        masterChains: {},
        masterChainsList: [],
        brands: {},
        brandsList: [],
        chainFilter: [],
        dropDownOpen: false,
        query: ""
      }
    },
    watch: {
      chains: { 
        handler(newChains) {
          if(!newChains) return;
          
          this.masterChains = {}
          this.masterChainsList = []
          this.brands = {}
          this.brandsList = []
          const nullMasterChain = { id: 'NULL', name: 'Other', subtype: 'MASTER', expanded: false, selected: 0, brands: [], $brands: [] }

          orderBy(newChains, ['subtype', 'name'], [ 'desc', 'asc' ]).forEach( c => {
            if(c.subtype === 'MASTER'){
              const m = Object.assign({}, c, { expanded: false, selected: 0, $brands: [], brands: [] })
              this.$set(this.masterChains, c.id, m)
              this.masterChainsList.push(m)
            } else {
              const master = c.masterChain ? this.masterChains[c.masterChain.id] : nullMasterChain,
                brand = Object.assign({}, c, { selected: false, masterChain: master })
              master.$brands.push(brand)
              this.$set(this.brands, brand.id, brand)
              this.brandsList.push(brand)
            }
          })

          this.$set(this.masterChains, nullMasterChain.id, nullMasterChain)
          this.masterChainsList.push(nullMasterChain)
          this.filter && this.applyFilterToChains(this.filter)
        },
        immediate: true
      },
      filter (newFilter) { this.applyFilterToChains(newFilter) }
    },
    computed: {
      allSelected () {
        return this.masterChainsList.every( c => c.$brands.length === c.selected )
      },
      someSelected () {
        return !this.allSelected && this.masterChainsList.some( c => c.selected )
      },
      headerText(){
        return this.masterChainsList.reduce( ( acc, m ) => {
          const s = toString(m)
          return acc + (s.length ? s + ", " : "")
        }, "" ).slice(0, -2)

        function toString(m){
          let s = ""
          if(m.selected) {
            const brands = m.selected !== m.$brands.length
              && m.$brands.reduce((acc, item) => acc + (item.selected ? item.name + ", " : ""), "").slice(0, -2)
            s = m.name + ( brands ? ` (${brands})` : '' )
          }
          return s
        }
      },
      listExpanded () { return this.masterChainsList.some( m => m.expanded) },
      queryFilteredMasterChains () {
        const queryFiltered = [], query = this.query.trim().toLowerCase()

        this.masterChainsList.forEach( m => {
          m.expanded = !!query.length
          m.brands = query.length && m.name.toLowerCase().indexOf(query) === -1
            ? m.$brands.filter( b => b.name.toLowerCase().indexOf(query) !== -1 )
            : m.$brands
          m.brands.length && queryFiltered.push(m)
        })

        return queryFiltered
      }
    },
    methods: {
      apply(){
        this.closeDropDown()
        this.emitFilterChanged(this.getFilterFromChains())
      },
      applyFilterToChains (newFilter) {
        this.masterChainsList.forEach( m => { m.selected = 0 })
        this.brandsList.forEach( b => { b.selected = false })
        newFilter.forEach( id => {
          const b = this.brands[id]
          if(b){
            b.selected = true
            b.masterChain.selected++
          }
        })
      },
      applyQuery(event) { this.query = event.value },
      cancel(){
        this.closeDropDown()
        this.applyFilterToChains(this.filter)
      },

      clearFilters(){
        this.applyFilterToChains([])
        !this.dropDownOpen && this.emitFilterChanged([])
      },

      closeDropDown(){
        this.dropDownOpen = false
        this.masterChainsList.forEach( m => { m.expanded = false } )
        this.query = ''
      },

      emitFilterChanged (f) { this.$rbEmit({ name: 'filtersChanged', filters: f }) },

      getFilterFromChains(){
        return this.brandsList.reduce( (acc, b) => {
          b.selected && acc.push(b.id)
          return acc
        }, [])
      },
      toggleDropDown($event){
        stopEvent($event)
        this.dropDownOpen ? this.apply() : this.openDropDown()
      },
      openDropDown(){ this.dropDownOpen = true },

      onAllSelectedChanged(allSelected){
        allSelected ? this.selectAll() : this.clearFilters()
      },

      selectAll(){
        this.applyFilterToChains(this.brandsList.reduce( (acc, b) => {
          acc.push(b.id)
          return acc
        }, [] ))
      }
    }
  }
</script>

<style lang="stylus" module >

  .toggler {
    flex 1 1 100%
    font-size : 13px;
    line-height : 35px;
    padding: 0 0 0 20px;
    width 100%;

    &:hover {
      position relative
      z-index 1
    }
  }

  .filter :global(.rbvDropDownToggler) {
    width 100%;
  }

  .togglerDefault {
    composes toggler
    color #546E7A
    border-radius 20px 0 0 20px !important;
    width calc(100% - 34px)  
  }

  .togglerEmpty {
    composes toggler
    color #B0BEC5
  }

  .openedIcon {
    color #00A99D !important
  }

  .togglerText {
    display inline-block
    width calc(100% - 50px)
    text-align left
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .dropdownArrow {
    color #546E7A
  }

  .dropdownOpenArrow {
    composes dropdownArrow
    transform rotateX(180deg)
  }

  .clearFilter {
    border-radius 0 20px 20px 0 !important;
    margin-left: -1px !important;
    padding: 0 0 0 10px;
    width: 35px;
    text-align: center;
  }

  .dropdownContainer {
    border: 1px solid #DEE1E3;
    top: 36px;
    width: 100%;
    box-sizing: border-box;
    min-width: 250px;
    padding 0
    border-radius: 5px;
    overflow: hidden;
  }

  .search {
    padding 5px 10px
    background #ECEFF1
  }

  .list {
    background white
    min-height 160px
    max-height 360px;
    overflow-y auto;
    position relative
  }

  .header {
    border-bottom: 1px solid #ECEFF1;
    background: #eceff1
    color: #455a64;
    font-size 12px;
    padding 0 10px
    height: 30px
    line-height: 27px;
  }

  .headerCheckbox {
    vertical-align middle
  }

  .headerLabel1 {
    width: calc(100% - 80px)
    display: inline-block;
    vertical-align middle
  }

  .headerLabel2 {
    vertical-align middle
  }

  .footer {
    display flex
    align-items center
    justify-content flex-end
    padding: 10px 10px
    border-top 1px solid #E1E4E6
    transition all .2s linear
  }

  .listClosedFooter {
    composes footer
    background #ECEFF1
  }

  .listExpandedFooter {
    composes footer
    background white
  }

</style>
