/**
 * Created by DejanK on 1/18/2017.
 */
import angular from 'angular'
import rbMap from './rb-map.cmp'
import rbInputMarker from './rb-input-marker.cmp'

export default angular.module('rb.map', [])
  .component('rbMap', rbMap)
  .component('rbMarker', require('./rb-marker.cmp'))
  .component('rbInputMarker', rbInputMarker)
