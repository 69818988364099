import { get } from 'lodash'
import HTML from './rfp-preview.html'
import scrollToAttachments from 'root/rfp-hotel/attachments/scroll-to-attachments.service';

export { HTML, RfpPreviewController as Controller}

RfpPreviewController.$inject = ['NotificationUtils', 'RfpHotelRfpRepository', '$window', '$state', 'isNam'];
function RfpPreviewController(utils, RfpHotelRfpRepository, $window, $state, isNam){
  const vm = this;

  vm.hasBack = () => $window.history.length > 1;
  vm.goBack = () => $window.history.back();
  vm.toAttachments = () => scrollToAttachments();

  $onInit();

  function $onInit() {
    vm.rfpId = $state.params.rfpId;
    utils.onLoad( () => RfpHotelRfpRepository.getRfpPreview(vm.rfpId), vm, 'rfp' )
      .then( () => { vm.letter = get(vm.rfp, isNam ? 'data.namCoverLetter' : 'data.coverLetter') })
  }
}
