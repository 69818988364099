<script>
  import get from 'lodash/get';
  import {onMount} from 'svelte';

  export let
    id = 'select',
    label = 'Select',
    values = {},
    errors = {},
    options = [],
    required=null;

  let inputElement, value, error;

  $: value = get(values, id, undefined)
  $: error = errors ? errors[id] : '';
  $: updateSelect(value)

  onMount(() => {
    inputElement.onpaste = e => e.preventDefault();
    inputElement.onkeydown = e => e.keyCode !== 9 ? e.preventDefault() : undefined;
    updateSelect(value);
  });

  function onMousedown(ev){
    updateValueAndView(ev.toElement.getAttribute('data-value'), ev.toElement.textContent);
  }

  function updateSelect(storeValue){
    if(inputElement){
      const selectedOption = options.find(o => o.id === storeValue);
      if(selectedOption){
        updateValueAndView(selectedOption.id, selectedOption.label);
      } else {
        updateValueAndView('', '');
      }
    }
  }

  function updateValueAndView(v, text){
    inputElement.setAttribute('data-value', v);
    inputElement.value = text;
    inputElement.dispatchEvent(new CustomEvent('change', {bubbles: true}))
  }

</script>


<div class="Input Select" >
  <input type="text"
         bind:this={inputElement}
         id="{id}"
         name="{id}"
         placeholder="{label}"
         autocomplete="off"
         {required}
  />
  <label>{label}</label>
  <div class="Options" on:mousedown={onMousedown}>
    {#each options as o}
      <div class="Option"
           class:Selected={o.id === value}
           data-value="{o.id}"
      >
        {o.label}
      </div>
    {/each}
  </div>
  <div class="Error">{error || ''}</div>
</div>
