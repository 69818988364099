<script>
  import 'webcomponent-qr-code'

  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import CodeInput from 'root/public-auth/dialogs/multi-factor-authentication/codeInput.svelte';

  import {httpErrorHandler, mainAPI} from 'root/angular-injector-provider';
  import noop from 'lodash/noop';

  export let cancel, hide, user = {};

  let totpUrl = '', code = '', error = '', checking = false, barcodeHelp = false;

  $: checkCode(code)

  initialize();

  async function initialize(){
    const p = await mainAPI().userSecurity.getTotpUrl();
    totpUrl = p.data;
  }

  function checkCode(c){
    if(c){
      check(c)
        .catch(noop);
    } else {
      checking = false;
      error = '';
    }
  }

  function check(c) {
    checking = true;
    return mainAPI().userSecurity.setTotp(code)
      .then(result => {
        hide(result.data)
      })
      .catch(httpError => {
        if (checking && c === code) {
          checking = false;
          if (`${httpError.status}` === '401') {
            error = 'Invalid Code. Please try again.'
          } else {
            httpErrorHandler().handle(httpError);
          }
        }

        return Promise.reject(httpError);
      });
  }

  function showBarcodeHelp(){
    const url = new URL(totpUrl);
    barcodeHelp = url.searchParams.get('secret');
  }
</script>


<ClosableModal width="600px" close="{cancel}">
  <div slot="heading">
    <ModalTitle title="Set Up Authenticator App"></ModalTitle>
  </div>

  <div class="body">
    <section>
      <section-title>STEP ONE</section-title>
      Download/Use any authenticator application (Google Authenticator, Microsoft Authenticator,etc.) on your mobile device.
    </section>

    <section>
      <section-title>STEP TWO</section-title>
      On your authenticator application, tap the "+" icon and then select "Scan QR code" to scan the QR code below
      {#if totpUrl}
        <qr-code data="{totpUrl}"></qr-code>
      {:else}
        <Spinner />
      {/if}

      {#if barcodeHelp}
        <div>
          Enter the following account and key information into the app and continue:<br /><br />
          <b>Account:</b><br />
          {user.emailAddress}<br />
          <b>Key:</b><br />
          {barcodeHelp}<br />
        </div>
      {:else}
        <div class="BarcodeHelpButton" on:click={showBarcodeHelp}>Can't scan the barcode?</div>
      {/if}
    </section>

    <section>
      <section-title>STEP THREE</section-title>
      Once this code has been scanned, enter the 6-digit code generated by the app.
      <div class="CodeInput">
        <CodeInput bind:code {error} {checking}/>
      </div>
    </section>
  </div>
</ClosableModal>

<style lang="stylus">.body {
  padding: 0 15px;
  font-size: 13px;
  line-height: 16px;
  color: #78909c;
  margin-top: -10px;
  margin-bottom: 20px;
}
section {
  margin-bottom: 32px;
}
section-title {
  color: #00a99d;
  font-weight: bold;
  display: block;
  margin-bottom: 12px;
}
qr-code {
  display: block;
  margin: 12px 0;
  text-align: center;
}
.CodeInput {
  margin-top: 10px;
}
.BarcodeHelpButton {
  color: #00a99d;
  text-decoration: underline;
  cursor: pointer;
}
.BarcodeHelpButton:hover {
  text-decoration: none;
}
</style>
