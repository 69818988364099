import {BidManagerActionsFactory, $state as stateProvider} from 'root/angular-injector-provider';
import {CHAIN_PORTAL_STATE} from 'root/states';

const
  GREEN = 'Green',
  YELLOW = 'Yellow',
  RED = 'Red',
  GRAY = 'Gray',
  PURPLE = 'Purple',
  BLUE = 'Blue',
  openBidManager = bid => ({
    label: 'Open Bid Manager',
    icon: 'launch',
    action: () => stateProvider().inNew(CHAIN_PORTAL_STATE, {steps: [`COMPANY-${bid.buyer.company.accountId}`, `RFP-${bid.rfp._id}`], chain:'CHAIN'})
  }),
  viewNegotiations = bid => ({
    label: 'View Negotiations',
    icon: 'currency_exchange',
    action: () => stateProvider().inNew(CHAIN_PORTAL_STATE, {
      steps: [`COMPANY-${bid.buyer.company.accountId}`, `RFP-${bid.rfp._id}`], chain:'CHAIN', negotiations:bid._id})
  }),
  STATES_LIBRARY = Object.freeze({
    SENT: {
      stateLabel: 'New Bid',
      stateIcon: 'note_add',
      stateColor: GREEN,
      actions(bid) {
        const
          factory = BidManagerActionsFactory(),
          report = { bids: [bid] };
        return [
          openBidManager(bid),
          factory.previewBid({label: 'View Bid'})(report),
          factory.createEditResponse()(report),
        ]
      }
    },
    RESPONSE_INCOMPLETE: {
      stateLabel: 'Bid Incomplete',
      stateIcon: 'pending_actions',
      stateColor: YELLOW,
      actions(bid) {
        const
          factory = BidManagerActionsFactory(),
          report = { bids: [bid] };
        return [
          openBidManager(bid),
          factory.previewBid({label: 'View Bid'})(report),
          factory.createEditResponse()(report),
        ]
      }
    },
    RESPONSE_READY: {
      stateLabel: 'Response Ready',
      stateIcon: 'assignment_turned_in',
      stateColor: YELLOW,
      actions(bid) {
        const
          factory = BidManagerActionsFactory(),
          report = { bids: [bid] };
        return [
          openBidManager(bid),
          factory.previewBid({label: 'View Bid'})(report),
          factory.createEditResponse()(report),
        ]
      }
    },
    RESPONDED: {
      stateLabel: 'Response Sent',
      stateIcon: 'drive_file_move',
      stateColor: GRAY,
      actions(bid) {
        const
          factory = BidManagerActionsFactory(),
          report = { bids: [bid] };
        return [
          openBidManager(bid),
          factory.previewBid({label: 'View Bid'})(report),
        ]
      }
    },
    NEGOTIATION_SENT: {
      stateLabel: 'Neg. Received',
      stateIcon: 'currency_exchange',
      stateColor: YELLOW,
      actions(bid) {
        return [
          openBidManager(bid),
          viewNegotiations(bid)
        ]
      }
    },
    NEGOTIATION_RESPONDED: {
      stateLabel: 'Neg. Responded',
      stateIcon: 'currency_exchange',
      stateColor: GRAY,
      actions(bid) {
        return [
          openBidManager(bid),
          viewNegotiations(bid)
        ]
      }
    },
    NEGOTIATION_FINALIZED: {
      stateLabel: 'Neg. Finalized',
      stateIcon: 'currency_exchange',
      stateColor: GRAY,
      actions(bid) {
        return [
          openBidManager(bid),
          viewNegotiations(bid)
        ]
      }
    },
    WITHDRAWN: {
      stateLabel: 'Withdrawn',
      stateIcon: 'subtitles_off',
      stateColor: PURPLE,
      actions(bid) {
        return [
          openBidManager(bid),
        ]
      }
    },
    BUYER_DELETED: {
      stateLabel: 'Bid Discontinued',
      stateIcon: 'subtitles_off',
      stateColor: PURPLE,
      actions(bid) {
        return [
          openBidManager(bid),
        ]
      }
    },
    FINAL_AGREEMENT: {
      stateLabel: 'Final Agreement Received',
      stateIcon: 'thumb_up',
      stateColor: BLUE,
      actions(bid) {
        const
          factory = BidManagerActionsFactory(),
          report = { bids: [bid] };

        return [
          openBidManager(bid),
          factory.viewFinalAgreement(report),
        ]
      }
    },
    NO_THANK_YOU: {
      stateLabel: 'No Thank You Received',
      stateIcon: 'thumb_down',
      stateColor: RED,
      actions(bid) {
        const
          factory = BidManagerActionsFactory(),
          report = { bidsIds: [bid._id] };

        return [
          openBidManager(bid),
          factory.viewNoThankYouLetter(report),
        ]
      }
    },
    NOT_INTERESTED: {
      stateLabel: 'Bid Declined',
      stateIcon: 'subtitles_off',
      stateColor: PURPLE,
      actions(bid) {
        return [
          openBidManager(bid),
        ]
      }
    },
    NO_LONGER_INTERESTED: {
      stateLabel: 'Negotiation Declined',
      stateIcon: 'subtitles_off',
      stateColor: PURPLE,
      actions(bid) {
        return [
          openBidManager(bid),
        ]
      }
    }
  });

export default function getBidStateOptions(bid){
  const options = {...formatStateStatus(bid.state)};
  // noinspection JSValidateTypes
  options.actions = options.actions ? options.actions(bid) : [];
  return options;
}

function formatStateStatus(state){
  const status = state.status;

  switch (status){
    case 'RECEIVED':
      if(!state.touched) return STATES_LIBRARY.SENT;
      return state.errors ? STATES_LIBRARY.RESPONSE_INCOMPLETE : STATES_LIBRARY.RESPONSE_READY;

    case 'FINAL_AGREEMENT_PENDING':
    case 'NO_THANK_YOU_PENDING':
      return STATES_LIBRARY.NEGOTIATION_FINALIZED;

    default:
      return STATES_LIBRARY[status] || {
        stateLabel: status,
        stateIcon: 'report_problem',
        stateColor: PURPLE,
        actions () { return []; }
      };
  }
}