<template>
  <dynamic-rate v-if="isProvided">
    <rate
      :is-available="isAvailable"
      :value="formattedValue"
      :is-selected="isSelected"
      :toggle="toggleRate" />
  </dynamic-rate>
</template>

<script>
  import Rate from './Rate.vue';
  import DynamicRate from './DynamicRate.vue';

  const DYNAMIC_RATE_PROVIDED_KEY = 'DYNAMIC_PRICING';
  const DYNAMIC_RATE_VALUE_KEY = 'DYNAMIC_PCT_Discount';

  export default {
    components: { DynamicRate, Rate },
    props: {
      toggle: { default: null },
      answers: { default: {} },
      selected: { default: [] }
    },
    computed: {
      isSelected () {
        return this.selected.indexOf(DYNAMIC_RATE_VALUE_KEY) !== -1;
      },
      value () {
        return this.answers[DYNAMIC_RATE_VALUE_KEY];
      },
      formattedValue () {
        return `${this.value}%`;
      },
      isProvided () {
        return this.answers[DYNAMIC_RATE_PROVIDED_KEY] === 'Y'
      },
      isAvailable(){
        const v = parseInt(this.value, 10)
        return this.isProvided && !( isNaN(v) || v === 0);
      }
    },
    methods: {
      toggleRate () {
        this.toggle(DYNAMIC_RATE_VALUE_KEY);
      },
      unselectIfUnavailable(){
        if(!this.isAvailable && this.isSelected){
          this.toggleRate();
        }
      }
    },
    mounted(){
      this.unselectIfUnavailable();
    }
  }
</script>
