<template>
  <div class="supplier-contact-changed-invitation-handler">
    <div class="content">
      <div
        class="layout-column flex-center"
      >
        <rbv-loading label="Loading, please wait..." />
      </div>
    </div>
  </div>
</template>

<script>
  import RbvLoading from 'vRoot/_core/RbvLoading.vue';
  import {handle} from './supplier-contact-change-invitations-handler.service';
  import invitationsService from '../../invitations-handler.service';

  export default {
    name: 'AgentInvitationsHandler',
    components: { RbvLoading },
    mounted(){
      this.handle();
    },
    methods: {
      handle() {
        return invitationsService.handleInvitation()
          .then(handle)
          .catch((err) => invitationsService.exit(this.handle, err));
      },
    },
  }
</script>

<style lang="stylus">
  .supplier-contact-changed-invitation-handler{
    height: 100%;
    width: 100%;
    position: relative;

    .content{
      background: #fff;
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
      padding: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      color: #546E7A;
      display: flex;

      .flex-center{
        flex 1;
      }
    }
  }
</style>
