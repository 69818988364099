/**
 * Created by DejanK on 10/09/2019.
 */
export default {
  template: '<input />',
  bindings: {
    negotiations: '<',
    model: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', 'NegotiationsModelFactory', '$scope'];
function Ctrl($element, NegotiationsModelFactory, $scope) {
  const vm = this;
  let model;

  this.$onChanges = initialize;

  function initialize() {
    if (!vm.negotiations || !vm.model || model) return;

    model = NegotiationsModelFactory.createDynamicRateModel(vm.model, $scope, $element, vm.negotiations.percentageFormatter.format);
    // $scope.$on('RB_REQUESTED_OF_ALL', function(event, data){
    //   //model.resetAndReduceByPercent(data.percentage);
    // });
  }
}
