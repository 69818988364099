<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header">
      <div>Create New View</div>
      <div :class="$style.subHeader">Create new view for your hotel directory.</div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
    >
      <rb-input
        :for-id="'name'"
        :error="$showError('name')"
      >
        <span slot="label">View Name</span>
        <input
          id="name"
          v-model="data.view"
          v-validate="'required|max:100'"
          type="text"
          name="name"
          class="rbInput"
          autofocus
          maxlength="100"
          placeholder="view name"
          data-vv-as="view name"
        >
      </rb-input>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button class="rbButton mainButton">                
        <i class="material-icons">save</i> 
        Create View
      </button>
    </div>
  </modal-form>
</template>

<script>
  
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'

  export default {
    name: 'CreateNewViewDialog',
    components: { RbInput, ModalForm },
    mixins: [ RbFormMixin, DialogMixin ],
    data() {
       return {
            data : {
                view: ''
            }
        }
    },
    methods: {
        onSubmit(){
            return this.data;
        }
    }
  }
</script>

<style lang="stylus" module>
  .dialog {
      min-width: 500px;
      overflow visible
  }

  .subHeader{
      font-size 12px;
      line-height 14pt;
      color #78909C;
      font-weight lighter;
  }
</style>
