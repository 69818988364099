<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header">
      <div>Create New RFP</div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody"
    >
      <rb-input
        :for-id="'accountId'"
        :error="$showError('accountId')"
      >
        <span slot="label">Select Company</span>
        <rb-select-new
          id="accountId"
          v-model="data.accountId"
          class="simple"
          name="accountId"
          :options="accountOptions"
        ></rb-select-new>
      </rb-input>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        :class="$style.left"
        @click="createCompany"
      >
        <i class="material-icons">business</i>
        New Company
      </button>
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button
        class="rbButton mainButton"
        :disabled="!data.accountId"
      >
        <i class="material-icons">save</i>
        Create RFP
      </button>
    </div>
  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'
  import RbSelectNew from 'vRoot/_core/rbv-input/RbSelectNew.vue'

  export default {
    name: 'CreateRfpDialog',
    components: { RbSelectNew, ModalForm, RbInput },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      accounts: {
        type: Array,
        required: true
      },
      accountId: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        data : {
          accountId: this.accountId,
        },
        action: 'createRfp'
      }
    },
    computed: {
      accountOptions(){
        const options = [{
          id: null,
          label: 'Select Company...'
        }];

        return options.concat(this.accounts.map(({id, name}) => ({id, label: name})));
      }
    },
    methods: {
      onSubmit(){
        return {data: this.data, action: this.action};
      },
      createCompany() {
        this.action = 'createCompany';
        this.$nextTick(() => this.$formSubmitHandler());
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 500px;
    overflow visible
  }

  .rfps{
    color: #7f909c;
    border: 1px solid;
    max-height 250px;
    overflow auto;
  }

  :global .vModalDefaultBody{
    margin-bottom 15px;
  }

  .left{
    float left;
  }
</style>
