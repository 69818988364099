<script>
  import get from 'lodash/get'
  import TheAddressSearch from './address-search/TheAddressSearch.svelte';
  import TheCreateLocationForm from './TheCreateLocationForm.svelte';

  export let
    values = {},
    errors = {},
    ids,
    setValues = () => {};

  let
    showLocationForm = false,
    locationId,
    locationIdError;

  $: locationId = ids ? ids.locationId : 'locationId'
  $: locationIdError = errors ? errors[locationId] : ''
  $: showLocationForm = showLocationForm || get(values, locationId)
</script>

<TheAddressSearch
  id="{locationId}"
  {setValues}
  onAddManually="{() => {showLocationForm = true}}"
  bind:showLocationForm
  requiredErrorMessage="Please select Hotel address."
  error="{locationIdError}"
/>

{#if showLocationForm}
  <TheCreateLocationForm
    {ids}
    {values}
    {errors}
  />
{/if}
