/**
 * Created by DejanK on 8/17/2016.
 */
import '../dialogs.scss'
import '../notifications.scss'
import '../../states/notices.scss'
import HTML from './success-large.html'
const NAME = 'successLarge'

export { NAME, HTML, SuccessController as Controller }

SuccessController.$inject = ['rbDialog', 'Success', '$state', '$scope']
function SuccessController(rbDialog, Success, $state, $scope) {
  const vm = this, autoRedirect = Success.autoRedirect

  vm.success = Success
  vm.cancel = rbDialog.cancel

  autoRedirect && $scope.$timeout( () => {
    $state.go( autoRedirect.state, autoRedirect.stateParams || {})
  }, autoRedirect.timeOut || 5000)
}
