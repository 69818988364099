import { progress } from './progress.store';
import { mainAPI } from 'root/angular-injector-provider';

export default async function downloadAttachment(attachmentId, token){
  updateProgress(0);
  const attachment = await getAttachmentDetails(attachmentId, token);
  updateProgress(5);
  const fileData = await downloadFile(attachment, p => updateProgress(Math.round(p / 100 * 95) + 5));
  updateProgress(100);
  saveFile(attachment, fileData);
}

function updateProgress(val){
  progress.set(val);
}

async function getAttachmentDetails(attachmentId, token){
  const res = await mainAPI().getAttachment(attachmentId, token);
  return res.data;
}

function downloadFile(attachment, progressCallback) {
  return new Promise(((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => resolve(new Blob([xhr.response], {type: attachment.contentType}));
    xhr.onerror = e => reject(e);
    xhr.onabort = e => reject(e);
    xhr.onprogress = e => progressCallback( e.lengthComputable ? Math.round((e.loaded / e.total) * 100 ) : 0);

    xhr.open('GET', attachment.signedUrl, true);
    xhr.setRequestHeader('Content-Type', attachment.contentType);
    xhr.responseType = 'blob';
    xhr.send();
  }))
}

function saveFile(attachment, fileData) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(fileData);
  a.download = attachment.name;
  a.dispatchEvent(new MouseEvent('click'));
  a.remove();
}