<script>
  export let subscriptions = "PRO and ELITE";
  export let hide, cancel;
</script>

<div class="Root">
  <i class="material-icons" on:click={cancel}>close</i>
  <div>This feature is restricted for</div>
  <div class="Main">{subscriptions} Versions</div>
  <div>ONLY</div>
  <button class="aBtn atLight asLarge asMain" data-icon="info" on:click={hide}>Request Access</button>
</div>


<style lang="stylus">.Root {
  background: #fff;
  padding: 15px 0;
  font-size: 12px;
  width: 230px;
  text-align: center;
  border-radius: 5px;
  color: #738092;
}
div {
  padding: 7px;
}
.Main {
  background: #f45f0b;
  color: #fff;
}
button {
  margin-top: 5px;
}
i.material-icons {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 18px;
  cursor: pointer;
}
</style>
