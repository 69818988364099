<script>
  import 'webcomponent-qr-code'

  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import CodeInput from 'root/public-auth/dialogs/multi-factor-authentication/codeInput.svelte';

  import {onDestroy} from 'svelte';
  import noop from 'lodash/noop';
  import {httpErrorHandler, mainAPI} from 'root/angular-injector-provider';
  import {maskEmailAddress} from 'utils/email-address';

  export let cancel, hide, user;

  const RESEND_TIMER = 120; // 2 minutes

  let maskedEmailAddress = '', code = '', error = '', checking = false, timer = -1, intervalId;

  $: checkCode(code)

  initialize();

  onDestroy(() => {
    if(intervalId) clearInterval(intervalId);
  });

  function initialize(){
    maskedEmailAddress = maskEmailAddress(user.emailAddress);
    sendEmail();
    intervalId = setInterval(() => {
      if(timer > 0) timer--;
    }, 1000);
  }

  function sendEmail(){
    mainAPI().userSecurity.sendEmailMfaSetupEmail()
      .then(onEmailSent)
      .catch(httpErrorHandler().handle)
  }

  function checkCode(c){
    if(c){
      check(c)
        .catch(noop);
    } else {
      checking = false;
      error = '';
    }
  }

  function check(c) {
    checking = true;
    return mainAPI().userSecurity.activateEmailMfa(code)
      .then(result => {
        hide(result.data)
      })
      .catch(httpError => {
        if (checking && c === code) {
          checking = false;
          if (`${httpError.status}` === '401') {
            error = 'Invalid Code. Please try again.'
          } else {
            httpErrorHandler().handle(httpError);
          }
        }

        return Promise.reject(httpError);
      });
  }

  function onEmailSent(){
    timer = RESEND_TIMER;
  }

</script>


<ClosableModal width="600px" close="{cancel}">
  <div slot="heading">
    <ModalTitle title="Set Up Email Authentication"></ModalTitle>
  </div>

  <div class="body">
    <section>
      <section-title>STEP ONE</section-title>
      A verification code was sent to <b>{maskedEmailAddress}</b>.
      {#if timer === 0}
        <button class="aBtn atLight asMain ResendEmail" on:click={sendEmail}>Send Again</button>
      {/if}
    </section>

    <section>
      <section-title>STEP TWO</section-title>
      Check your email and enter the code below.
      <div class="CodeInput">
        <CodeInput bind:code {error} {checking}/>
      </div>
    </section>
  </div>
</ClosableModal>

<style lang="stylus">.body {
  padding: 0 15px;
  font-size: 13px;
  line-height: 16px;
  color: #78909c;
  margin-top: -10px;
  margin-bottom: 20px;
}
section {
  margin-bottom: 32px;
}
section-title {
  color: #00a99d;
  font-weight: bold;
  display: block;
  margin-bottom: 12px;
}
.CodeInput {
  margin-top: 10px;
}
.ResendEmail {
  display: block;
  margin-top: 5px;
}
</style>
