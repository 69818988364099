/**
 * Created by DejanK on 1/24/2017
 *
 * Displays Google Map
 */
import {Google} from "root/shared/tools/google/googleProvider"

export default {
  template: '<div></div><div class="transluded" ng-transclude=""></div>',
  transclude: true,
  bindings: {
    center: '<',
    zoom: '<',
    mapTypeId: '@'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', '$scope'];
function Ctrl($element, $scope){
  const vm = this
  let mapPromise, map

  vm.$onInit = $onInit
  vm.$onChanges = $onChanges
  vm.getMap = getMap

  function $onInit(){
    mapPromise = Google().then(newMap)
  }

  function $onChanges( changes ){
    if(mapPromise) {
      changes.center && mapPromise.then(onCenterChanged);
      changes.zoom && mapPromise.then(onZoomChanged);
    }
  }

  function getMap(){ return mapPromise }

  function newMap(google){
    if (map) {
      return Promise.resolve(map)
    } else {
      const mapDiv = $element.find('div')[0],
        config = {
          center: vm.center || {lat: 0, lng: 0},
          zoom: vm.zoom || 14,
          mapTypeId: vm.mapTypeId || 'terrain'
        },
        m = new google.maps.Map(mapDiv, config)

      return $scope.$timeout(() => {
        google.maps.event.trigger(m, "resize")
        map = m
        return m
      }, 1000)
    }
  }

  function onCenterChanged(){
    map && map.panTo(vm.center);
  }

  function onZoomChanged(){
    map && map.setZoom(vm.zoom);
  }
}
