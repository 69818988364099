import Vue from 'vue';

import DestinationsTable from './SuppliersTable.vue';
import { cloneDeep, noop } from 'lodash';
export default {
  template: '<div></div>',
  bindings: {
    tableOpen: '<',
    onToggleTable:'&',
    onUpdate:'&',
    onPanMapTo: '&',
    filters: '<',
    onSave: '&',
    onDone: '&',
    onSetDefaultFilters: '&'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'TravelDestinationManagerService', 'RfpHotelRfpDestinationsRepository', '$scope', 'NotificationUtils'];
function Ctrl($element, ManagerService, RfpHotelRfpDestinationsRepository, $scope, utils){
  const vm = this, vueData = { tableOpen: false, filters: {}, scrollTo: undefined, chains: []};
  let vueComponent;

  vm.panMapTo = panMapTo;
  vm.onHotelItemMouseEnter = ManagerService.setFocusedSupplierId;
  vm.onHotelItemMouseLeave = ManagerService.setFocusedSupplierId;

  vm.$onDestroy = () => { if(vueComponent) vueComponent.$destroy() };

  vm.$onInit = () => {
    utils.onLoad(RfpHotelRfpDestinationsRepository.listChains)
      .then( chains => { vueData.chains = chains.data })
      .catch(noop)
  }

  vm.$onChanges = (changes) => {
    initVueComponent();

    if(vueComponent && changes.tableOpen){
      vueData.tableOpen = vm.tableOpen
    }

    if(vueComponent && changes.filters && vm.filters){
      vueData.filters = cloneDeep(vm.filters);
    }
  };

  function handleTableToggle() {
    vm.onToggleTable({
      $event: {
        state: !vm.tableOpen
      }
    });
  }

  function handleFiltersChanged({filters}) {
    vm.onUpdate({
      $event: {
        filters: filters,
        apply: true
      }
    });
  }

  function handleSaveFilters({filters}){
    vm.onSave({
      $event: {
        filters: filters,
        apply: true
      }
    });
  }

  function handleSetDefaultFilters({filters}){
    vm.onSetDefaultFilters({
      $event: {
        filters: filters,
        apply: true
      }
    });
  }

  this.$doCheck = () => {
    if(ManagerService.scrollListToId){
      scrollIntoView(ManagerService.scrollListToId)
    }
  };

  function panMapTo(coordinates){
    $scope.$applyAsync(()=>{
      vm.onPanMapTo({$event: {coordinates: cloneDeep(coordinates)}})
    })
  }

  function addToBidManager(supplier){
    ManagerService.addToTheBidManager(supplier.id);
  }

  function removeFromBidManager(supplier){
    ManagerService.setFocusedSupplierId();
    ManagerService.removeFromBidManager(supplier.id)
  }

  function scrollIntoView(idToScrollTo){
    vueData.scrollTo = idToScrollTo
  }

  function initVueComponent(){
    if(!vueComponent) initVue();

    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { vueData },
        render: function (createElement) {
          return createElement(DestinationsTable, {
            props: {
              tableOpen: vueData.tableOpen,
              filters: vueData.filters,
              scrollTo: vueData.scrollTo,
              chains: vueData.chains,
            },
            on: {
              toggleTable(event){ handleTableToggle(event)},
              onSupplierMouseEnter(event){ vm.onHotelItemMouseEnter(event.id)},
              onSupplierMouseLeave(){ vm.onHotelItemMouseLeave()},
              onRemoveFromBidManager(event){ removeFromBidManager(event.supplier)},
              onAddToBidManager(event){ addToBidManager(event.supplier)},
              onPanMapTo(event) {vm.panMapTo(event.coordinates)},
              filtersChanged (event) { handleFiltersChanged(event)},
              saveFilters(event) { handleSaveFilters(event)},
              done() { vm.onDone( {$event:{} } )},
              setDefaultFilters(event) { handleSetDefaultFilters(event)}
            },
          })
        }
      })
    }
  }
}
