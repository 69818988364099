import { debounce, isEqual, orderBy } from 'lodash';
import { mainAPI, notificationUtils } from 'root/angular-injector-provider.js';
import { derived, get, writable } from 'svelte/store';
import { initialize } from '../../../bid-manager/main/stores/bid-manager';

export const defaultState = Object.freeze({
  page: 0,
  total: 0,
  lastPage: false,
  loading: true,
  data: [],
  selections: [],
  selectionPercentages: {}
});

let lastRequest = null;

export const dataStore = writable({...defaultState})
export const selectionPercentage = derived(dataStore, $store => $store.selections.length / $store.data.length);
export const formattedData = derived(dataStore, ($store) => {
  return $store.data.map(data => ({
    ...data,
    _id: data._id || 'undefined',
    id: data._id || 'undefined',
    label: data.label || !data._id && 'Undefined',
    selected: $store.selectionPercentages[data._id],
    rfps: data.rfps && [...new Set(data.rfps.flat())] || []
  }))
});

function loadData(groups, sortBy, page, bidTypes, assignments, loadRecent, force) {
  const request = [groups, sortBy, page, bidTypes, assignments, loadRecent];
  if(force || !isEqual(lastRequest, request)) {
    dataStore.set(defaultState);
    lastRequest = request;
    return sendRequest(
        groups,
        sortBy,
        page,
        bidTypes,
        assignments,
        loadRecent
      ).then(({data}) => ({...defaultState, ...data, loading: false }))
        .catch(() => { lastRequest = []; });
  }

  return Promise.reject();
}

function sendRequest(groups, sortBy, page, bidTypes, assignments, loadRecent) {
  return notificationUtils()
      .onLoad(() => mainAPI().fetchChainPortalData(
        groups,
        sortBy,
        page,
        bidTypes,
        assignments,
        loadRecent
      ))
}

export const loadPage = debounce(async (groups, sortBy, page = 1, bidTypes, assignments, loadRecent, force, getBrands) =>  {
  const brands = await getBrands();
  return loadData(groups, sortBy, page, bidTypes, assignments, loadRecent, force)
    .then(data => dataStore.set(createData(data))).catch(() => {});

  function createData(res) {
    if(!brands || brands.length === 0) {
      return res;
    }

    const dataGroups = res.data.reduce((acc, a) => ({...acc, [a._id]: a}), {});
    const data = brands.map(brand => {
      const result = dataGroups[brand.id] || {_id: brand.id};

      return {
        brand,
        supplier: { company: { chain: brand } },
        ...result,
        label: brand.name,
      }
    });
    return {
      ...res,
      data: orderBy(data, [sortBy.key], [sortBy.direction === 'up'? 'asc': 'desc'])
    }
  }
}, 500);

export function reloadData(getBrands) {
  return loadPage(...lastRequest, true, getBrands);
}

export const subRowsSelectedStore = writable([]);
export const subRowsStore = writable([], () => {
  const selectUnsubscribe = subRowsSelectedStore.subscribe($srSelected => {
    subRowsStore.update($srStore => $srStore.map(r => ({ ...r, selected: $srSelected.indexOf(r._id) > -1})));
  });

  return () => selectUnsubscribe();
});

export const loadRfpSubRows = debounce(({_id}, groups, bidTypes, loadRecent, cb) => {
  return sendRequest([...makeNewGroups(), {group: 'RFP'}], {key: 'rfp.specifications.programYear', direction: 'down'}, 1, bidTypes, false, loadRecent)
    .then(({data}) => {
      const $selected = get(subRowsSelectedStore);
      subRowsStore.set(data.data.map(r => ({
        ...r,
        sub: true,
        parentId: _id,
        selected: $selected.indexOf(r._id) > -1
      })));
    })
    .then(cb);

  function makeNewGroups() {
    return groups.map(g => {
      if(g.group === 'COMPANY') {
        g.value = _id;
      }

      return g;
    });
  }
}, 500);

export const initialiseBidManager = initialize;

export function select(row) {
  (row.sub ? selectSub : selectRoot)(row)
}

function selectSub(row){
  subRowsSelectedStore.update($srSelected => {
    const
      subsSelections = [...$srSelected],
      subsSelectionsIndex = subsSelections.indexOf(row._id);

    if(subsSelectionsIndex === -1){
      subsSelections.push(row._id)
    } else {
      subsSelections.splice(subsSelectionsIndex, 1);
    }

    dataStore.update($values => {
      const
        selectionPercentages = {...$values.selectionPercentages},
        parentRowRfps = $values.data.find(o => o._id === row.parentId).rfps ,
        selectedParentRowRfpsLength = subsSelections.filter(_id => parentRowRfps.indexOf(_id) > -1).length;

      if(selectedParentRowRfpsLength === 0){
        delete selectionPercentages[row.parentId]
      } else {
        selectionPercentages[row.parentId] = selectedParentRowRfpsLength / parentRowRfps.length;
      }

      return {...$values, selectionPercentages, selections: Object.keys(selectionPercentages)};
    })

    return subsSelections;
  })
}

function selectRoot(row){
  const id = row._id
  dataStore.update($values => {
    const selectionPercentages = {...$values.selectionPercentages};
    if(selectionPercentages[id]){
      delete selectionPercentages[id];
      subRowsSelectedStore.update($srSelected => $srSelected.filter(s => row.rfps.indexOf(s) === -1))
    } else {
      selectionPercentages[id] = 1;
      subRowsSelectedStore.update($srSelected => {
        const result = [...$srSelected];
        if(row.rfps){
          row.rfps.forEach(rfpId => {
            if(result.indexOf(rfpId) === -1) result.push(rfpId);
          });
        }

        return result;
      });
    }

    return {...$values, selectionPercentages, selections: Object.keys(selectionPercentages)};
  })
}

export function selectAll() {
  const sp = get(selectionPercentage);
  dataStore.update($values => {
    const accumulator = { selectionPercentages: {}, subsSelections: [] }
    const {selectionPercentages, subsSelections} = sp > 0
      ?  accumulator
      :  $values.data.reduce( (acc, o) => {
        acc.selectionPercentages[o._id] = 1;
        acc.subsSelections = [...acc.subsSelections, ...o.rfps]
        return acc;
      }, accumulator);

    subRowsSelectedStore.update(() => subsSelections);
    return {...$values, selectionPercentages, selections: Object.keys(selectionPercentages)}
  });
}

export function fetchOverDueBidsCount() {
  return notificationUtils().onLoad(() => mainAPI().fetchOverDueBidsCount()).then(({data}) => data);
}

export function loadBrands() {
  return notificationUtils().onLoad(() => mainAPI().loadBrands()).then(({data}) => data)
}
