/**
 * Created by DejanK on 3/8/2017.
 */
import angular from 'angular'
import rbsHeader from './rbs-headers/rbs-header.cmp';
import rbsPublicHeader from './rbs-headers/rbs-public-header.cmp';
import rbsGuestHeader from './rbs-headers/rbs-guest-header.cmp';
import rbsTravelDestinationsMapHeader from './rbs-headers/rbs-travel-destinations-map-header.cmp';
import rbsEmptyHeader from './rbs-headers/rbs-empty-header.cmp';
import rbsBidManagerHeader from './rbs-headers/rbs-bid-manager-header.cmp';

export default angular.module('rb.core.ui.headers', [ ])
  .component('rbsHeader', rbsHeader)
  .component('rbsPublicHeader', rbsPublicHeader)
  .component('rbsGuestHeader', rbsGuestHeader)
  .component('rbsEmptyHeader', rbsEmptyHeader)
  .component('rbsTravelDestinationsMapHeader', rbsTravelDestinationsMapHeader)
  .component('rbsBidManagerHeader', rbsBidManagerHeader)
