import { writable } from 'svelte/store';

const store = writable({});

export default store;

export function initialize(signUpData, source){
  store.set({
    ...signUpData,
    initialized: true,
    source
  });
}