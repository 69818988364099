<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <div
      slot="header"
      :class="$style.header"
    >
      <i class="material-icons">info_outline</i>
      <div>
        <div :class="$style.title">
          New Company Added
        </div>
        <div :class="$style.subTitle">
          {{ name }}
        </div>
      </div>
    </div>
    <div
      slot="body"
      class="vModalDefaultBody"
      :class="$style.body"
    >
      You have just added {{ name }} to your company list. You can now proceed to create a new RFP or visit Company Profile.
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        @click="action = 'manageCompany'"
      >
        Manage Company
      </button>
      <button
        class="rbButton mainButton"
      >
        <i class="material-icons">create_new_folder</i>
        Create Rfp
      </button>
    </div>
  </modal-form>
</template>

<script>

  import ModalForm from '../../../../_core/RbModalForm.vue'
  import DialogMixin from '../../../../_mixins/DialogMixin.vue'
  import RbFormMixin from '../../../../_mixins/RbFormMixin.vue'

  export default {
    name: 'CompanyCreatedDialog',
    components: { ModalForm },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return { action: 'createRfp'}
    },
    methods: {
      onSubmit(){
        return {action: this.action};
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 450px;
    overflow visible
  }

  .body{
    line-height: 2;
    font-size: 12px;
    color: #637b85;
  }

  .header{
    display flex;
    align-items center;
  }

  .header i{
    font-size 45px;
    color #00b4a6;
    margin-right: 10px;
  }

  .header > .title{
    font-size 15px;
  }

  .header .subTitle{
    font-size 17px;
    font-weight: normal;
  }
</style>
