/**
 * Created by DejanK on 7/3/2017.
 */
import HTML from './rb-negotiations-view.html';
import './rb-negotiations-view.scss';

export default {
  template: HTML,
  bindings: {
    negotiations: '<',
    side: '<',
    finalized: '<',
  },
  controller: Ctrl
};

Ctrl.$inject = [];
function Ctrl() {
  const
    vm = this;
  vm.communicationSortedByTimeDescending = [];

  this.$onChanges = ( changes )=> {
    if( (changes.side || changes.negotiations) && vm.side && vm.negotiations ){
      init();
    }
  };

  function init() {
    vm.negotiationElementWidth = vm.negotiations.calculateViewNegotiationElementWidth();
    vm.communicationSortedByTimeDescending = [...vm.negotiations.communication].reverse();
  }
}
