const UTF8_BOM = '\uFEFF';

export function generateCsv(headers, data){
  return UTF8_BOM + data.reduce((acc, dRow) => {
    acc.push(headers.map(h => escapeCsv(dRow[h])).join(','));
    return acc;
  }, [headers.map(h => escapeCsv(h)).join(',')])
    .join('\n');
}

function escapeCsv(input) {
  if(input == null) return '';
  // getting crazy things for functions and objects is expected.
  return typeof input === 'string' ? escapeCsvString(input) : input;
}

function escapeCsvString(inputString) {
  // Check if the input string contains a comma, double quotes, or new line characters
  if (inputString.includes(',') || inputString.includes('"') || inputString.includes('\n')) {
    // If it does, enclose the string in double quotes and escape any double quotes inside the string
    return `"${ inputString.replace(/"/g, '""') }"`;
  } else {
    // If it doesn't, return the input string as is
    return inputString;
  }
}
