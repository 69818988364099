import { writable } from 'svelte/store';
import {debounce} from 'lodash';
import {GoogleMapsGeocode, GooglePlacesAutocomplete} from 'root/shared/tools/google/googleProvider';
import {parseGooglePlace} from 'root/shared/tools/google/googlePlaceParser';
import {notificationUtils} from 'root/angular-injector-provider';

const
  initial = {
    query: '',
    results: {
      '': {
        page: 1,
        suggestions: [],
      }
    }
  },
  searchStore = writable(initial);

export default searchStore;

export function initialize(entityType){
  searchStore.set({...initial, entityType});
}

export const search = debounce(function (query = ''){
  searchStore.update(v => {
    if(v.results[query]){
      return {...v, query};
    } else {
      const page = 1;

      GooglePlacesAutocomplete({ input: query, types: [ 'address' ]})
        .then( predictions => {
          searchStore.update(v1 => ({
            ...v1,
            results: {
              ...v1.results,
              [query]: {
                loading: false,
                suggestions: (predictions || []).map(r => ({placeId: r.place_id, address: r.description})),
                hasMore: false,
                page,
              }},
          }));
        })
        .catch( () => {
          searchStore.update(v1 => ({
            ...v1,
            results: {
              ...v1.results,
              [query]: {
                loading: false,
                suggestions: [],
                hasMore: false,
                page,
              }},
          }));
        });

      return {...v, query, results: {...v.results, [query]: {loading: true} }};
    }
  });
}, 200);

export function getDetails(suggestion){
  return notificationUtils().onLoadWithNotification(
    () => GoogleMapsGeocode({'placeId': suggestion.placeId})
    .then(details => parseGooglePlace(details[0]))
      .catch(() => ({})), {
      workingDialogLabel: 'Loading...'
    });
}