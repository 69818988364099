<template>
  <modal-form
    :class="{[$style.expired]: !!directory.explorationStartDate, [$style.optinDialog]: true}"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <div
      slot="header"
      :class="$style.header"
    >
      <i
        class="material-icons"
        :class="$style.warning"
      >error_outline</i>
      <div
        v-if="!directory.explorationStartDate"
        :class="$style.texts"
      >
        <div>{{ user.firstName }}, welcome to your</div>
        <div :class="$style.subHeader">Hotel Directory</div>
      </div>
      
      <div
        v-else
        :class="$style.texts"
      >
        <div>{{ user.firstName }}, your Hotel Directory</div>
      
        <div :class="$style.subHeader">
          Trial has Expired
        </div>
      </div>
    </div>
    
    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
    >
      <template v-if="!directory.explorationStartDate">
        <p :class="$style.p">
          Hi <b>{{ user.firstName }},</b>
        </p>

        <p :class="$style.p">
          Welcome to the <b>{{ account.name }}</b> {{ year }} Global Hotel Directory.
          The hotels within are a compilation of your hard earned negotiated and finalized hotel agreements.
        </p>

        <p :class="$style.p">
          {{ user.firstName }}, You are welcome to peruse the directory for <b>5 business Days</b>.
          After 5 days, if you would like to take advantage of all the directory has to offer, you will need to agree to
          activate and pay <b>$49 per month</b>
        </p>

        <p :class="$style.p">
          We really appreciate your patronage {{ user.firstName }} and hope you will choose to opt in.
        </p>
      </template>
      
      <template v-else>
        <p :class="$style.p">
          Your hotel directory exploration period has expired.
          In order to continue using the hotel directory, you will need to opt in for the <b>$49</b> monthly fee below.
        </p>
      </template>
      
      <p :class="$style.p">
        ReadyBid Support
      </p>
    </div>
    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <template v-if="!directory.explorationStartDate">
        <button
          class="rbButton"
          :class="$style.left"
          type="button"
          @click="takeAction('explore')"
        >
          I'll Just Look Around
        </button>
        <!--<button class="rbButton" type="button" @click="demo">-->
        <!--<i class="material-icons">play_circle_filled</i>-->
        <!--Watch Demo-->
        <!--</button>-->
      </template>
      <button
        v-else-if="!directory.trialExtended"
        class="rbButton"
        :class="$style.left"
        type="button"
        @click="takeAction('extend')"
      >
        Extend Trial by Another 7 days
      </button>
      <button
        class="rbButton mainButton"
        type="button"
        @click="takeAction('optIn')"
      >
        Activate only <b>$49</b>/month
      </button>
    </div>
  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'

  export default {
    name: 'DirectoryPaymentOptInDialog',
    
    components: { ModalForm },
    
    mixins: [ RbFormMixin, DialogMixin ],
    
    props: {
      directory: {
        type: Object,
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
      account: {
        type: Object,
        required: true,
      },
    },
    
    data(){
      return {
        action: null
      }
    },
    
    computed: {
      year(){
        return (new Date()).getFullYear();
      }
    },
    
    methods: {
      onSubmit() {
        return {action: this.action};
      },
    
      takeAction(action) {
        this.action = action;
        this.$nextTick(this.$formSubmitHandler);
      },
    
      demo(){}
    }
  }
</script>

<style lang="stylus" module>
  .optinDialog p{
    font-size: 12px;
    color: #78909c;
    margin: 10px 0;
    line-height: 2;
  }

  .expired{
    width 500px;
  }

  .subHeader{
    font-size 25px;
    line-height 14pt;
    color #78909c;
    font-weight lighter;
  }

  .header{
    display flex;
    font-weight lighter;
  }

  .warning{
    height: 50px;
    width: 50px;
    font-size: 50px;
    color: #00b8ab;
    margin-right 10px;
  }

  .texts{
    padding 0 5px;
    font-size: 16px;
    color: #78909c;
  }

  .left{
    float left;
  }
</style>
