import HTML from './bid-preview.html';
import noop from 'lodash/noop';
import {SvelteDialog} from 'root/v-app/rbServices';
import HotelRfpSpecificationsReportDialog from 'root/rfp-hotel/main/bid/dialogs/HotelRfpSpecificationsReportDialog.svelte';
import scrollToAttachments from 'root/rfp-hotel/attachments/scroll-to-attachments.service';
import {HOTEL_RFP_BID_UPDATE_RESPONSE} from 'root/states';

export { HTML, BidPreviewController as Controller};

BidPreviewController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state', 'MainAPI', 'CurrentUser'];
function BidPreviewController(utils, RfpHotelBidRepository, $state, mainAPI, currentUser){
  const vm = this;

  $onInit();

  function $onInit(){
    vm.bidId = $state.params.bidId;
    vm.bid = 'loading';
    vm.isStandardBid = false;
    vm.isRateQuickBid = false;
    vm.viewBidPackage = () => SvelteDialog.show(HotelRfpSpecificationsReportDialog, {bidId: vm.bidId}).catch(noop);
    vm.exportBidPackage = () => mainAPI.exportBidPackage(vm.bidId);

    vm.toAttachments = () => scrollToAttachments();
    vm.updateResponse = false;

    currentUser.get()
      .then(cu => {
        vm.updateResponse = cu.getAccount().isBuyer('HOTEL') ? false : () => $state.go(HOTEL_RFP_BID_UPDATE_RESPONSE);
      })
      .catch( noop );

    utils.onLoad( () => RfpHotelBidRepository.getBidPreview(vm.bidId, 0), vm, 'bid' )
      .then( () => {
        const subType = vm.bid.data.specifications.subType;
        vm.isStandardBid = !subType || subType === 'STANDARD';
        vm.isRateQuickBid = subType === 'RATE_QUICK' || subType === 'PER_DIEM';
      })
      .catch( noop )
  }
}
