<script>
// ORIGINAL SCRIPT IS IN VirtualListTiny.svelte

import SizeAndPositionManager from './SizeAndPositionManager';
import {
  DIRECTION,
  SCROLL_CHANGE_REASON,
  SCROLL_PROP,
  SCROLL_PROP_LEGACY,
} from './constants';

const thirdEventArg = (() => {
  let result = false;

  try {
    const arg = Object.defineProperty({}, 'passive', {
      get() {
        result = { passive: true };
        return true;
      },
    });

    window.addEventListener('testpassive', arg, arg);
    window.remove('testpassive', arg, arg);
  } catch (e) { /* */
  }

  return result;
})();

export default {
  name: 'VirtualListTiny',

  props: {
    height: {
      default: 800
    },
    width: {
      default: '100%'
    },
    itemCount: {
      default: 0
    },
    itemSize: {
      default: null,
    },
    estimatedItemSize: {
      default: null,
    },
    stickyIndices: {
      default: null
    },
    getKey: {
      default: null
    },
    scrollDirection: {
      default: DIRECTION.VERTICAL
    },
    scrollOffset: {
      default: 0
    },
    scrollToIndex: {
      default: 0
    },
    scrollToAlignment: {
      default: 0
    },
    scrollToBehaviour: {
      default: 'instant'
    },
    overscanCount: {
      default: 3
    }
  },

  data: () => ({
    isMounted: false,
    items: [],
    prevState: {},
    prevProps: {},
    styleCache: {},
    wrapperStyle: '',
    innerStyle: '',
    state: {},
  }),

  created() {
    this.$watch('scrollToIndex', () => this.propsUpdated());
    this.$watch('scrollToAlignment', () => this.propsUpdated());
    this.$watch('scrollOffset', () => this.propsUpdated());
    this.$watch('itemCount', () => this.propsUpdated());
    this.$watch('itemSize', () => this.propsUpdated());
    this.$watch('estimatedItemSize', () => this.propsUpdated());

    this.$watch('state', () => this.stateUpdated());

    this.$watch('height', () => this.recomputeSizesOnChange());
    this.$watch('width', () => this.recomputeSizesOnChange());
    this.$watch('stickyIndices', () => this.recomputeSizesOnChange());
  },

  mounted() {
    this.isMounted = true;

    this.sizeAndPositionManager = new SizeAndPositionManager({
      itemCount: this.itemCount,
      itemSize: this.isMounted,
      estimatedItemSize: this.getEstimatedItemSize(),
    });

    this.state = {
      offset: this.scrollOffset || (this.scrollToIndex != null && this.items.length && this.getOffsetForIndex(this.scrollToIndex)) || 0,
      scrollChangeReason: SCROLL_CHANGE_REASON.REQUESTED,
    };
    this.prevState = JSON.parse(JSON.stringify(this.state));


    this.$refs.wrapper.addEventListener('scroll', this.handleScroll, thirdEventArg);

    if(this.scrollOffset != null){
      this.scrollTo(this.scrollOffset);
    } else if (this.scrollToIndex != null){
      this.scrollTo(this.getOffsetForIndex(this.scrollToIndex))
    }

    this.propsUpdated();
    this.refresh();
  },

  destroyed() {
    if(this.isMounted && this.$refs.wrapper) {
      this.$refs.wrapper.removeEventListener('scroll', this.handleScroll);
    }
  },

  methods: {
    propsUpdated() {
      if(!this.isMounted) return;

      const scrollPropsHaveChanged =
        this.prevProps.scrollToIndex !== this.scrollToIndex ||
        this.prevProps.scrollToAlignment !== this.scrollToAlignment;
      const itemPropsHaveChanged =
        this.prevProps.itemCount !== this.itemCount ||
        this.prevProps.itemSize !== this.itemSize ||
        this.prevProps.estimatedItemSize !== this.estimatedItemSize;

      if (itemPropsHaveChanged) {
        this.sizeAndPositionManager.updateConfig({
          itemSize: this.itemSize,
          itemCount: this.itemCount,
          estimatedItemSize: this.getEstimatedItemSize(),
        });

        this.recomputeSizes();
      }


      if (this.prevProps.scrollOffset !== this.scrollOffset) {
        this.state = {
          offset:             this.scrollOffset || 0,
          scrollChangeReason: SCROLL_CHANGE_REASON.REQUESTED,
        };
      } else if (
        typeof this.scrollToIndex === 'number' &&
        (scrollPropsHaveChanged || itemPropsHaveChanged)
      ) {
        this.state = {
          offset: this.getOffsetForIndex(
            this.scrollToIndex,
            this.scrollToAlignment,
            this.itemCount,
          ),

          scrollChangeReason: SCROLL_CHANGE_REASON.REQUESTED,
        };
      }

      this.prevProps = JSON.parse(JSON.stringify({
        scrollToIndex: this.scrollToIndex,
        scrollToAlignment: this.scrollToAlignment,
        scrollOffset: this.scrollOffset,
        itemCount: this.itemCount,
        itemSize: this.itemSize,
        estimatedItemSize: this.estimatedItemSize,
      }));
    },

    stateUpdated() {
      if (!this.isMounted) return;

      const offset = this.state.offset,
        scrollChangeReason = this.state.scrollChangeReason;

      if (
        this.prevState.offset !== offset ||
        this.prevState.scrollChangeReason !== scrollChangeReason
      ) {
        this.refresh();
      }

      if (this.prevState.offset !== offset && scrollChangeReason === SCROLL_CHANGE_REASON.REQUESTED) {
        this.scrollTo(offset);
      }

      this.prevState = JSON.parse(JSON.stringify(this.state));
    },

    refresh() {
      const { offset } = this.state;
      const { start, stop } = this.sizeAndPositionManager.getVisibleRange({
        containerSize: Number(this.scrollDirection === DIRECTION.VERTICAL ? this.height : this.width),
        offset,
        overscanCount: this.overscanCount,
      });

      const updatedItems = [];

      const totalSize = this.sizeAndPositionManager.getTotalSize();

      if (this.scrollDirection === DIRECTION.VERTICAL) {
        this.wrapperStyle = `height:${this.height}px;width:${this.width};`;
        this.innerStyle = `flex-direction:column;height:${totalSize}px;`;
      } else {
        this.wrapperStyle = `height:${this.height};width:${this.width}px`;
        this.innerStyle = `min-height:100%;width:${totalSize}px;`;
      }

      const hasStickyIndices = this.stickyIndices != null && this.stickyIndices.length !== 0;
      if (hasStickyIndices) {
        for (let i = 0; i < this.stickyIndices.length; i++) {
          const index = this.stickyIndices[i];
          updatedItems.push({
            index,
            style: this.getStyle(index, true),
          });
        }
      }

      if (start !== undefined && stop !== undefined) {
        for (let index = start; index <= stop; index++) {
          if (hasStickyIndices && this.stickyIndices.includes(index)) {
            continue;
          }

          updatedItems.push({
            index,
            style: this.getStyle(index, false),
          });
        }

        this.$emit('itemsUpdated', {
          start,
          end: stop,
        });
      }

      this.items = updatedItems;
    },

    scrollTo(value) {
      const wrapper = this.$refs.wrapper;
      if ('scroll' in wrapper) {
        wrapper.scroll({
          [SCROLL_PROP[this.scrollDirection]]: value,
          behavior:                       this.scrollToBehaviour,
        });
      } else {
        wrapper[SCROLL_PROP_LEGACY[this.scrollDirection]] = value;
      }
    },

    recomputeSizes(startIndex = 0) { // TODO: EXPORT !!!
      this.styleCache = {};
      this.sizeAndPositionManager.resetItem(startIndex);
      this.refresh();
    },

    recomputeSizesOnChange(){
      if(this.isMounted) this.recomputeSizes(0);
    },

    getOffsetForIndex(index, align = this.scrollToAlignment, _itemCount = this.itemCount) {
      if (index < 0 || index >= _itemCount) {
        index = 0;
      }

      return this.sizeAndPositionManager.getUpdatedOffsetForIndex({
        align,
        containerSize: this.scrollDirection === DIRECTION.VERTICAL ? this.height : this.width,
        currentOffset: this.state.offset || 0,
        targetIndex:   index,
      });
    },

    handleScroll(event) {
      const offset = this.getWrapperOffset();

      if (offset < 0 || this.state.offset === offset || event.target !== this.$refs.wrapper) return;

      this.state = {
        offset,
        scrollChangeReason: SCROLL_CHANGE_REASON.OBSERVED,
      };

      this.$emit('afterScroll', {
        offset,
        event,
      });
    },

    getWrapperOffset() {
      return this.$refs.wrapper[SCROLL_PROP_LEGACY[this.scrollDirection]];
    },

    getEstimatedItemSize() {
      return (
        this.estimatedItemSize ||
        (typeof this.itemSize === 'number' && this.itemSize) ||
        50
      );
    },

    getStyle(index, sticky) {
      if (this.styleCache[index]) return this.styleCache[index];

      const { size, offset } = this.sizeAndPositionManager.getSizeAndPositionForIndex(index);

      let style;

      if (this.scrollDirection === DIRECTION.VERTICAL) {
        style = `left:0;width:100%;height:${size}px;`;

        if (sticky) {
          style += `position:sticky;flex-grow:0;z-index:1;top:0;margin-top:${offset}px;margin-bottom:${-(offset + size)}px;`;
        } else {
          style += `position:absolute;top:${offset}px;`;
        }
      } else {
        style = `top:0;width:${size}px;`;

        if (sticky) {
          style += `position:sticky;z-index:1;left:0;margin-left:${offset}px;margin-right:${-(offset + size)}px;`;
        } else {
          style += `position:absolute;height:100%;left:${offset}px;`;
        }
      }

      this.styleCache[index] = style;
      return style;
    },
  }
}

</script>

<template>
  <div
    ref="wrapper"
    :class="$style.virtualListWrapper"
    :style="wrapperStyle"
  >
    <slot name="header" />

    <div
      :class="$style.virtualListInner"
      :style="innerStyle"
    >
      <template v-for="item in items">
        <div
          :key="getKey ? getKey(item.index) : item.index"
          :style="item.style"
        >
          <slot
            :index="getKey ? getKey(item.index) : item.index"
          >
            Missing Template
          </slot>
        </div>
      </template>
    </div>

    <slot name="footer" />
  </div>
</template>

<style module lang="stylus">
.virtualListWrapper {
  overflow:                   auto;
  will-change:                transform;
  -webkit-overflow-scrolling: touch;
}

.virtualListInner {
  position:   relative;
  display:    flex;
  width:      100%;
}
</style>
