<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import YesNoButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
  import noop from 'lodash/noop';

  export let hide, cancel, bids, rfpId;

  let
    destinationsPromise,
    filter = '',
    selected;

  $: destinationsPromise = new Promise(((resolve, reject) => {
    notificationUtils().onLoad(() => mainAPI().listRfpTravelDestinations(rfpId))
      .then(({data}) => resolve(data))
      .catch(reject)
  }));

  function applyFilter(destinations = [], filter = '') {
    if(!filter) {
      return destinations;
    } else {
      const r = new RegExp(filter, 'iu')
      return destinations.filter(d => r.test(d.name) || r.test(d.location.fullAddress));
    }
  }

  function save(){
    notificationUtils().onSave(() => mainAPI().updateBidsTravelDestination(bids.map(b => b._id), selected))
      .then(r => {
        console.log(r)
        hide(r)
      })
    .catch(noop)
  }
</script>

<ClosableModal close="{cancel}" width="{'500px'}">
  <div slot="heading">
    <ModalTitle title="Change Travel Destination" />
  </div>

  {#await destinationsPromise}
    <div class="Container layout-column flex-center">
      <Spinner />
    </div>
  {:then destinations}
    <div class="Container">
      <div class="Description">Please select Travel Destination to move {bids.length === 1 ? 'the hotel' : `${bids.length} hotels`} to:</div>

      <div>
        <div class="InputContainer">
          <input id="filter"
                 class="Input"
                 placeholder="Search Destinations"
                 class:NotEmpty="{filter.length}"
                 bind:value={filter}
                 maxlength="200"
          />
        </div>

        <div class="Destinations">
          {#each applyFilter(destinations, filter) as destination}
            <div class="Destination"
                 class:Selected={selected === destination.id}
                 on:click={() => selected = destination.id}
            >
              <div class="DestinationName ellipsis">{destination.name}</div>
              <div class="DestinationAddress ellipsis">{destination.location.fullAddress}</div>
            </div>
          {:else}
            <div class="Destination DestinationName NoMatches">No Travel Destinations found for filter: {filter}</div>
          {/each}
        </div>
      </div>
    </div>
  {/await}

  <YesNoButtons
    noAction="{cancel}"
    yesAction="{save}"
    disabled="{!selected}"
  />
</ClosableModal>

<style lang="stylus">.Container {
  height: 320px;
}
.Description {
  padding: 0 20px;
  font-size: 14px;
}
.Destinations {
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
  margin: 20px;
  border: 1px solid #b0bec5;
  border-radius: 3px;
}
.Destination {
  height: 42px;
  box-sizing: border-box;
  padding: 5px 10px;
  color: #3d4d55;
  background: #fff;
  border-bottom: 1px solid #dcdee0;
  cursor: pointer;
}
.Destination.Selected {
  background: #00a99d;
  color: #fff;
  cursor: default;
}
.Destination.NoMatches {
  display: flex;
  align-items: center;
  cursor: default;
  min-height: 42px;
  height: auto;
  padding: 5px 10px !important;
}
.DestinationName {
  line-height: 16px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
}
.DestinationAddress {
  line-height: 16px;
  font-size: 13px;
  padding: 0 10px;
}
.InputContainer {
  margin: 20px;
}
.Input {
  font-family: 'Noto Sans', sans-serif;
  position: relative;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  padding: 0 10px;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
  font-size: 14px;
  color: #b0bec5;
  background: transparent;
  display: flex;
  align-items: center;
}
.Input:focus {
  border: 1px solid #00a99d;
}
.Input.NotEmpty {
  color: #546e7a;
}
</style>