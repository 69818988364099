import SupplierContactChangeModule from './supplierContactChangeInvitationsHandler.vue';
const SUPPLIER_CONTACT_CHANGE = 'SUPPLIER_CONTACT_CHANGE';

export default {
  id: SUPPLIER_CONTACT_CHANGE,
  component: SupplierContactChangeModule,
}

export {
  SUPPLIER_CONTACT_CHANGE
};
