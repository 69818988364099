<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <div
      slot="header"
      :class="$style.header"
    >
      <div :class="$style.logo">
        <img
          v-if="account.logo"
          :src="logo"
          alt="Account Logo"
        >
        <div v-else>
          <i class="material-icons">business</i>
        </div>
      </div>
      <div>
        <div :class="$style.title">
          Assign an account manager
        </div>
        <div :class="$style.subTitle">
          to {{ account.name }}
        </div>
      </div>
    </div>
    <div
      slot="body"
      class="vModalDefaultBody"
      :class="$style.body"
    >
      <div :class="$style.users">
        <div
          v-for="user in sortedUsers"
          :key="user.id"
          :class="{ [$style.user]: true, [$style.selected]: selected(user), [$style.originallySelected]: originallySelected(user) }"
          @click="setAgent(user.id)"
        >
          <rb-radio
            v-model="primaryAgent"
            :option="user.id"
            :class="$style.radio"
          />
          <span> {{ user.fullName }} </span>
          <div v-if="user.accountsManaged.length > 0">
            Managing {{ user.accountsManaged.length }} {{ user.accountsManaged.length !== 1? 'Companies': 'Company' }}
            <div
              class="rbTooltip simple"
              tooltip="placement:top-start"
            >
              <b>Accounts Managed</b><br>
              <div
                v-for="{accountDetails, id} in user.accountsManaged"
                :key="id"
              >
                {{ accountDetails.name }}
              </div>
            </div>
          </div>
          <div
            v-else
            :class="$style.unAssigned"
          >
            Not Assigned
            <i class="material-icons">warning</i>
          </div>
        </div>
      </div>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        class="rbButton mainButton"
      >
        <i class="material-icons">account_circle</i>
        Apply Changes
      </button>
    </div>
  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue';
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue';
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue';
  import RbRadio from 'vRoot/_core/RbRadio.vue';
  import {orderBy} from 'lodash';
  import {PENDING_USER_TYPE} from 'vRoot/account-profile/modules/manage-users/services/account-manage-users-list.service';

  export default {
    name: 'ManageCompanyAssignmentsDialogs',
    components: { ModalForm, RbRadio },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      account: {
        type: Object,
        required: true
      },
      users: {
        type: Array,
        required: true
      }
    },
    data() {
      return { primaryAgent: this.account.primaryAgent.userId }
    },
    computed: {
      sortedUsers() {
        return orderBy(this.users, [this.originallySelected], ['desc'])
      },
      logo() { return `/images/logos/${this.account.logo}` },
    },
    methods: {
      onSubmit(){
        const user = this.users.find(u => u.id === this.primaryAgent);
        return {
          user: {
            userId: user.id,
            isUser: user.type !== PENDING_USER_TYPE,
            accountId: this.account.id
          }
        }
      },
      setAgent(id) {
        this.primaryAgent = id;
      },
      originallySelected(user) {
        return this.account.primaryAgent.userId === user.id;
      },
      selected(user) {
        return this.primaryAgent === user.id;
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 450px;
    overflow visible
  }

  .body{
    line-height: 2;
    font-size: 12px;
    color: #637b85;
  }

  .header{
    align-items center;
    display flex;
  }

  .header > .title{
    font-size 12px;
  }

  .header .subTitle{
    font-size 11px;
    font-weight: normal;
  }

  .users{
    border: 1px solid #B0BEC5;
    max-height 250px;
    overflow: auto;
  }

  .user{
    padding 10px 5px;
    height 42px;
    border-bottom 1px solid #ECEFF1;
    display flex;
    align-items center;
    font-size 12px;
    cursor pointer;
    box-sizing border-box;
  }

  .user span{
    flex 1;
  }

  .radio:hover{
    border-color: transparent;
  }

  .unAssigned{
    display flex;
    color #f25b26;
    align-items center;
  }

  .unAssigned i{
    font-size 14px;
  }

  .selected{
    background rgba(252, 238, 33, 0.24);
  }

  .originallySelected{
    border-bottom 3px solid #CFD8DC;
  }

  .logo {
    position relative;
    margin-right 10px;
  }

  .logo > img, .logo > div {
    height: 65px;
    width: 110px;
    object-fit: contain;
    border-radius 3px;
    border 1px solid #ECEFF1;
    background: #ECEFF1;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .logo :global i.material-icons{
    font-size 50px;
  }

  .logo > img {
    background transparent;
    border none;
  }
</style>
