<script>
import { Dialog } from 'root/v-app/rbServices';
import hotelDirectoryClientStore from '../modules/hotel-directory-client-store';

import EditColumn from 'vRoot/hotel-directory/dialogs/EditColumnsDialog.vue';
import HdSelect from 'vRoot/hotel-directory/_core/hd-select.vue';
import RbvLoading from 'vRoot/_core/RbvLoading.vue';
import RbRating from 'vRoot/hotel-directory/_core/rating.vue';
import ResponsiveTable from './ResponsiveTable.vue';
import DirectoryPreviewDialog from 'vRoot/hotel-directory/dialogs/tutorialDialog.vue';

import {orderBy, fill, get} from 'lodash';

export default {
    name: 'HotelDirectoryHotelsMixin',
    components: { HdSelect, RbvLoading, RbRating },
    filters: {
        distance(value) {
            return Math.round(value * 100) / 100;
        }
    },
    mixins: [ResponsiveTable],
    data(){
        let columns = hotelDirectoryClientStore.getManageHotelColumns();
        const defCols = hotelDirectoryClientStore.getDefaultManageHotelsColumns().map(column => column.id);
        if(!columns || defCols.length !== columns.length || columns.indexOf('code') === -1){
            columns = defCols;
            hotelDirectoryClientStore.setManageHotelColumns(columns);
        }

        const vm = this;
        return {
            columns,
            openedRow: null,
            groupers: [
                {
                    id: null,
                    text: 'None',
                },
                {
                    id: 'td',
                    text: 'Travel Destinations',
                    key: 'travelDestination.name'
                },
                {
                    id: 'city',
                    text: 'Hotel City',
                    key: 'company.address.city'
                },
                {
                    id: 'state',
                    text: 'State/Region',
                    key: ['company.address.state', 'company.address.region']
                },
                {
                    id: 'country',
                    text: 'Country',
                    key: 'company.address.countryName'
                },
                {
                    id: 'brand',
                    text: 'Hotel Brand',
                    key: 'company.chain.name'
                },
                {
                  id: 'chain',
                  text: 'Hotel Chain',
                  key: 'company.chain.masterChainName'
                },
                {
                  id: 'programYear',
                  text: 'Program Year',
                  get({travelDestination: {rfpId}}){
                    const {programYear} = vm.rfps.find(({id}) => id === rfpId);
                    return programYear;
                  }
                },
                {
                  id: 'rfpId',
                  text: 'RFP',
                  get({travelDestination: {rfpId}}){
                    const {name, programStartDate, programEndDate} = vm.rfps.find(({id}) => id === rfpId);
                    return `${name} (${programStartDate} - ${programEndDate})`;
                  }
                },
                {
                    id: 'rating',
                    text: 'Hotel Type',
                    key: 'rating'
                },
                {
                    id: 'distance',
                    text: 'Distance',
                    key: 'analytics.distanceMi'
                },
                {
                  id: 'validity',
                  text: 'Valid/Invalid',
                  key: 'valid'
                },
                {
                  id: 'added',
                  text: 'Added/Not Added to Directory',
                  key: 'added'
                }
            ],
            groupBy: 'city',
            sortBy: {id: null},
            filterBy: '',
            addedHotels: [],
            hotelTypes: [
                { id: 'E', name: 'Economy Hotels' },
                { id: 'M', name: 'Midscale Hotels' },
                { id: 'UM', name: 'Upper Midscale Hotels' },
                { id: 'U', name: 'Upscale Hotels' },
                { id: 'UU', name: 'Upper Upscale Hotels' },
                { id: 'L', name: 'Luxury Hotels' },
            ],
            shownRate: 'SS',
            rates: [
                {id: 'SS', text: 'Standard Single Rate', name: 'SS Rate'},
                {id: 'SD', text: 'Standard Double Rate', name: 'SD Rate'},
                {id: 'US', text: 'Upgraded Single Rate', name: 'US Rate'},
                {id: 'UD', text: 'Upgraded Double Rate', name: 'UD Rate'},
                {id: 'DS', text: 'Deluxe Single Rate', name: 'DS Rate'},
                {id: 'DD', text: 'Deluxe Double Rate', name: 'DD Rate'},
                {id: 'DYN', text: 'Dynamic Rate', name: 'DYN Rate'},
            ],
            showHotelsError: true,
            propertyCodes: [
              {id: 'AMADEUS_PROPCODE', text: 'Amadeus Code', name: 'Amadeus'},
              {id: 'APOLLO_PROPCODE', text: 'Apollo Code', name: 'Apollo'},
              {id: 'SABRE_PROPCODE', text: 'Sabre Code', name: 'Sabre'},
              {id: 'WRLDSPAN_PROPCODE', text: 'WorldSpan Code', name: 'WorldSpan'}
            ],
            propertyCodeShown: 'SABRE_PROPCODE'
        }
    },
    computed: {
        grouper(){
            return this.groupers.find(group => group.id === this.groupBy);
        },
        sorter() {
          const s = this.groupers.find(group => group.id === this.sortBy.id);
          s.order = this.sortBy.order;
          return s;
        },
        rate() {
            return this.rates.find(rate => rate.id === this.shownRate);
        },
        propertyCode() {
          return this.propertyCodes.find(code => this.propertyCodeShown === code.id);
        },
        validFilteredHotels() {
          return this.filteredHotels.filter(hotel => hotel.valid);
        },
        filter(){
            const filter = this.filters.find(f => f.id === this.filterBy);
            return filter? filter: {
                id: null,
                text: 'None'
            }
        },

        filteredHotels(){
          if(this.filter.id === null) return this.hotels;

          const
            getHotelValueFunction = this.grouper.get ? this.grouper.get : get,
            grouperKey = this.grouper.key,
            filterValue = this.filter.value,
            eqFn = h => getHotelValueFunction(h, grouperKey) === filterValue;

          return this.hotels.filter(eqFn);
        },

        filters(){
            let filters = [];
            if(this.grouper.id !== null && this.hotels){
                filters = orderBy(this.sortAndGroup(false).filter(({type}) => type === 'group'),['text'], ['asc']);
            }
            return [{
                id: null,
                text: 'None',
            }, ...filters];
        },
        sortedAndGrouped(){
            return this.sortAndGroup(true);
        },
        loading(){
            return this.hotels === null;
        },
        empty(){
            return !this.hotels || this.hotels.length === 0;
        },
    },
    methods: {
        getColumn(id){
            return hotelDirectoryClientStore.getDefaultManageHotelsColumn(id);
        },
        openRow(id) {
          this.openedRow = id;
        },
        editColumns(){
            Dialog.show(EditColumn, {
                columns: Object.assign([], this.columns),
                otherColumns: hotelDirectoryClientStore.getDefaultManageHotelsColumns(),
                get: hotelDirectoryClientStore.getDefaultManageHotelsColumn,
                canRemove: false,
                visibleColumns: this.visibleColumns
            }).then(data => {
                this.columns = data.columns;
                hotelDirectoryClientStore.setManageHotelColumns(this.columns);
            });
        },
        sort(hotels){
            if(this.sorter.key){
                const key = Array.isArray(this.sorter.key)? this.sorter.key: [this.sorter.key];
                hotels = orderBy(hotels, key, fill(Array(key.length), this.sorter.order))
            }
            return hotels;
        },

        sortAndGroup(filter = true) {
            if(this.hotels && this.grouper.id) {
                const
                  sortedHotels = this.sort(filter? this.filteredHotels: this.hotels),
                  groups = this.createGroups(sortedHotels);

                return Object.keys(groups).reduce((acc, key) => {
                    const group = groups[key];
                    acc.push({ key, type: 'group', id: group.id, value: group.value, text: group.text });
                    acc.push(...group.items);
                    return acc;
                }, []);
          }else{
                return this.hotels || [];
            }
        },

        createGroups(hotels){
          const makeFilterFn = this.getMakeFilterFn();
          return hotels.reduce((acc, hotel, index) => {
            const { value, text } = makeFilterFn(hotel);
            let group = acc[value];

            if(!group) {
              acc[value] = {
                id: (value !== null && value !== undefined? value.toString(): '') + index,
                value,
                text,
                items: []
              };
              group = acc[value];
            }

            hotel.key = value;
            group.items.push(hotel);

            return acc;
          }, {});
        },

        getMakeFilterFn(){
          const valueGetter = this.grouper.get? this.grouper.get: get,
            grouperKey = this.grouper.key,
            readValueFn = hotel => valueGetter(hotel, grouperKey);

          switch (this.grouper.id) {
            case 'distance':
              return hotel => {
                const value = `${Math.round(readValueFn(hotel) * 100) / 100} mi`;
                return { text: value, value };
              };

            case 'rating':
              return hotel => {
                const value = readValueFn(hotel);
                const type = this.hotelTypes.find(t => t.id === value);
                return { text: type ? type.name : 'Hotel Property', value };
              };

            case 'validity':
              return hotel => {
                const value = readValueFn(hotel);
                return { text: value ? 'Valid' : 'With Errors', value };
              };

            case 'added':
              return hotel => {
                const value = readValueFn(hotel);
                return { text: value ? 'Added To Directory' : ' Not Yet on Directory', value };
              };

            default:
              return hotel => {
                const value = readValueFn(hotel);
                return {text: value, value};
              };
          }
        },

        groupItems(k) {
            return this.sortedAndGrouped.filter(({key, type}) => type !== 'group' && `${key}` === k);
        },
        allAddedInGroup(k) {
          const items = this.groupItems(k);
          const validAdded = items.filter(({added, valid}) => valid && added).length;
          return validAdded === items.filter(({valid}) => valid).length && validAdded > 0;
        },
        someAddedInGroup(k) {
          const items = this.groupItems(k);
          return items.filter(hotel => hotel.valid && hotel.added).length > 0;
        },
        showTutorialDialog(user, cb) {
          Dialog.show(DirectoryPreviewDialog, {
            user,
            title: 'Manage Hotels',
            message: 'By checking and unchecking the boxes next to hotels on this page, you can add or remove them from the hotel directory.'
          }).then(cb);
        }
    }
}
</script>

<style lang="stylus" module>
    .title{
        color #fff;
        display flex;
        align-items center;
        margin-bottom 4px;
    }

    .titleMain{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    .titleText{
        padding 0 10px;
    }

    .titleSubText{
        padding 0 10px;
        font-size: 12px;
        color: #acbec5;
    }

    .titleIcon{
        color: #2db8aa;
        background: #263238;
        border-radius: 100%;
        height: 30px;
        width: 30px;
        padding: 5px;
        box-sizing: border-box;
    }

    .controls{
        display flex;
        justify-content flex-end;
        flex 1;
        align-items center;
    }

    .verticalSeparator{
        height: 36px;
        border: 1px solid #263238;
        display: inline;
        vertical-align: middle;
        margin: 0 10px 0 5px;
    }

    .table{
        overflow: visible;
    }

    .fullTable{
      height: calc(100vh - 149px);
      overflow: auto;
      box-sizing border-box;
    }

    .row {
        display: table-row;
    }

    .head {
        box-sizing: border-box;
        vertical-align: bottom;
        padding: 0 10px 10px 5px;
        display flex
        align-items center
        cursor pointer
        height: 38px
    }

    .checkboxHead{
        composes head;
        padding 10px 0 5px 15px;
    }

    .tableHead {
        composes row
        background: #313c41;
        font-size: 12px;
        color: #acbec5;
        position sticky
        top -1px
        z-index 1
        border-bottom: 1px solid #D9E0E3;
    }

    .empty{
        width 100%;
        height calc(100vh - 198px);
        background-color #ECEFF1;
        text-align center;
        display flex;
        flex-direction column;
        justify-content center;
        color #263238;
        & > * {
            margin 5px 0;
        }
    }

    .emptyTitle{
        color #263238;
        font-size 20px;
    }

    .emptySubTitle{
        color #546E7A;
        font-size 13px;
        max-width 400px;
        margin 0 auto;
    }

    .overflow{
        height 30px;
        display table-row;
    }

    .grouper{
        padding: 5px 15px;
        color: #78909c;
        position: absolute;
    }

    .loader{
        padding 100px;
    }

    .rateColumn{
        overflow visible !important;
        position relative;
    }

    .rateDropdown{
        position unset !important;
        height 25px !important;
    }

    .rateDropdown > div > div > div{
      height: 25px !important;
      max-width: 120px !important;
      padding: 0 5px !important;
    }

    .rateDropdown > div > div > div i:last-child{
      margin 0 !important;
      padding 0 10px 0 0 !important;
    }

    .rateDropdown > div > div{
        padding 0 5px !important;
        height 25px !important;
    }

    .rateDropdown i{
        padding: 5px !important;
    }

    .rateDropdown i:last-child{
        padding: 0 5px !important;
    }

    .errorInfo{
      position: fixed;
      right: 10px;
      bottom: 10px;
      width: 300px;
      height: 180px;
      background-color: #fff;
      padding: 10px;
      box-sizing: border-box;
      box-shadow: 1px 1px 5px 1px #78909c;
      border-radius: 3px;
      z-index 9;
    }

    .errorInfoHeader{
      display flex;
      align-items center;
      color #546E7A;
      font-size 17px;
      font-weight lighter;
    }

    .errorInfoHeader .info{
      color #ec5c0b;
      font-size 50px !important;
    }

    .errorButton{
      position: absolute;
      bottom: 10px;
      right: 10px;
      background-image: unset !important;
      background-color: #f15a24;
      color: #fff !important;
      border 0 !important;
    }

    .errorText{
      color #82909d;
    }

    .errorClose{
      position absolute;
      right 10px;
      top 5px;
      color #82909d;
      cursor pointer;
    }
</style>
