<script>
  import get from 'lodash/get';

  export let
    id='sabreCode',
    label='Sabre Code',
    values = {},
    errors = {},
    required = null,
    maxLength = 10,
    uniqueSabre = true;

  let error, value, inputElement;

  $: value = get(values, id, '') || (inputElement ? inputElement.value : '');
  $: error = errors ? errors[id] : '';

  function allowOnlyNumbers(event){
    const v = event.target.value || '';
    event.target.value = v.replace(/\D/g,'');
  }
</script>

<div class="Input">
  <input
    type="text"
    id="{id}"
    name="{id}"
    placeholder="{label}"
    autocomplete="off"
    required="{required}"
    maxlength="{maxLength}"
    value="{value}"
    unique-sabre="{uniqueSabre || null}"
    on:input={allowOnlyNumbers}
    bind:this={inputElement}
  />
  <label>{label}</label>
  <div class="Error">{error || ''}</div>
</div>
