import noop from 'lodash/noop';
import {SvelteDialog} from 'root/v-app/rbServices';
import {statusMatches, getBidsIds} from 'rfp/bid-manager/main/core/actions/bid-manager-actions-factory-helpers';
import DeclineBidsDialog
  from './DeclineBidsDialog.svelte';
import SendNotInterestedActionReport
  from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendNotInterestedActionReport.vue';

export default function CreateDeclineBid(bidManagerService){
  return function DeclineBid(report){
    return {
      label: 'Decline to Bid',
      icon: 'delete',
      isAvailable: () => (report.bids.length === 1 || report.sharedRfp) && statusMatches(report.statuses, ['SENT', 'RECEIVED']),
      action: () => {
        SvelteDialog.show(DeclineBidsDialog, {bid: report.bids[0], bidsIds: getBidsIds(report.bids)})
          .then(actionReport => bidManagerService.handleActionResult( actionReport, report.bids, SendNotInterestedActionReport))
          .catch(noop)
      }
    }
  }
}
