/**
 * Created by DejanK on 1/20/2017.
 */
import { noop, get } from 'lodash'
import './hotel-profile.scss'
import HTML from './hotel-profile.html'
import PHOTO_NOT_AVAILABLE from 'images/ui/no-hotel-photo-available.gif'

const NAME = 'destinationManagerHotelProfile'
export { NAME, HTML, DestinationsManagerHotelProfileController as Controller }

DestinationsManagerHotelProfileController.$inject = ['NotificationUtils', 'SupplierHotelId', 'DataServer', 'TravelDestinationManagerService', 'rbDialog']
function DestinationsManagerHotelProfileController(utils, SupplierHotelId, dataServer, ManagerService, dialog){
  const vm = this

  vm.cancel = dialog.cancel;
  vm.addToBidManager = addToBidManager;
  vm.removeFromBidManager = removeFromBidManager;

  $onInit();

  function $onInit(){
    vm.supplier = ManagerService.getSupplierByHotelId(SupplierHotelId);
    vm.supplierImageUrl = getImage(get(vm.supplier, 'image.url'));
    utils.onLoad( () => dataServer.getAmenities(), vm, 'amenities' ).catch( noop )
    ManagerService.scrollListTo(vm.supplier.id);
  }

  function addToBidManager(){
    ManagerService.addToTheBidManager(vm.supplier.id);
  }

  function removeFromBidManager(){
    ManagerService.removeFromBidManager(vm.supplier.id);
  }

  function getImage(image) {
    return image ? (image.startsWith('http') ? image : `/images/entities/${image}`): PHOTO_NOT_AVAILABLE;
  }
}
