<script>
  /* eslint-disable no-unused-vars,no-unused-labels */
  import {onDestroy, onMount} from 'svelte';
  import {DEFAULT_BRAND_ID} from 'config/defaults'

  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import Form from 'root/core/ui/form/Form';
  import InputEmailAddress from 'root/core/ui/form/InputEmailAddress.svelte';
  import InputText from 'root/core/ui/form/InputText.svelte';
  import InputSabre from 'root/core/ui/form/InputSabre.svelte';
  import Buttons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import TheAddressForm from 'root/core/ui/form/TheAddressForm.svelte';

  import {mainAPI, notificationUtils} from 'root/angular-injector-provider';

  export let cancel, hide;

  const
    form = new Form({type: 'HOTEL', industry: 'TRAVEL_AND_HOSPITALITY', brandId: DEFAULT_BRAND_ID}),
    errorsStore = form.errorsStore,
    valuesStore = form.valuesStore;

  let formElement;

  // $: console.log('STORE VALUES', $valuesStore)
  // $: console.log('STORE ERRORS', $errorsStore)

  onMount(() => {
    form.setElement(formElement);
  })

  onDestroy(() => {
    if (form) form.destroy();
  })

  function sendRequest() {
    // eslint-disable-next-line no-undef
    if ($errorsStore.$$isValid) {
      notificationUtils()
        .onSave(() => mainAPI().chainPortal.createAddHotelRequest(form.getModel()), { customErrorHandlers: { '400' (e) {
              notificationUtils().showErrorDialog({dialogData: {message: e.data.message, heading: 'Sabre Code'}})
            }}})
        .then(hide)
    } else {
      form.touchAll();
    }
  }
</script>


<ClosableModal close="{cancel}" width="700px">
  <div slot="heading">
    <div class="Header">
      <div class="HeaderTitle">
        We are happy to add it for you
      </div>
    </div>
  </div>

  <form bind:this={formElement} class="Content RbForm" novalidate>
    <div>
      <div class="Columns66">
        <InputText
          id="name"
          label="Hotel Name"
          values="{$valuesStore}"
          errors="{$errorsStore}"
          required="{true}"
        />

        <div class="SabreCodeContainer">
          <InputSabre
            id="sabre"
            label="Sabre Code (optional)"
            values="{$valuesStore}"
            errors="{$errorsStore}"
            uniqueSabre="{false}"
          />
        </div>
      </div>

      <InputEmailAddress
        label="Hotel Contact Email Address (optional)"
        values="{$valuesStore}"
        errors="{$errorsStore}"
      />

      <TheAddressForm
        values="{$valuesStore}"
        setValues="{val => form.setValue('location', val)}"
        errors="{$errorsStore}"
      />
    </div>
  </form>

  <Buttons
    yesLabel="Send Request"
    yesAction="{sendRequest}"
    noAction="{cancel}"
  />
</ClosableModal>

<style lang="stylus">.Header {
  padding-left: 14px;
  display: flex;
  height: 63px;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  line-height: 20px;
  color: #546e7a;
}
.HeaderTitle {
  font-weight: bold;
  font-size: 16px;
  color: #455a64;
}
.Content {
  padding: 0 14px;
  margin-bottom: 20px;
  overflow: auto;
  min-height: 600px;
  max-height: 600px;
}
.Columns66 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 10px;
}
</style>
