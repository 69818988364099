<script>
  import InputText from './InputText.svelte';
  import countries from 'data/countries';
  import states from 'data/states';
  import Select from './Select.svelte';
  import Map from './Map.svelte';
  import get from 'lodash/get';

  export let
    ids = {
      address1: 'location.address.address1',
      address2: 'location.address.address2',
      county: 'location.address.county',
      city: 'location.address.city',
      state: 'location.address.state',
      region: 'location.address.region',
      country: 'location.address.country',
      zip: 'location.address.zip',
      lat: 'location.coordinates.lat',
      lng: 'location.coordinates.lng'
    },
    values = {},
    errors = {};


  let filteredStates = [], selectedCountry, setStateValue;

  $: selectedCountry = get(values, ids.country, undefined);
  $: filteredStates = selectedCountry ? states.filter(s => s.country === selectedCountry) : [];
  $: purgeStateOnCountryChange(selectedCountry)

  function purgeStateOnCountryChange(selectedC){
    if(selectedC){
      const selectedState = get(values, ids.state, undefined);
      if(selectedState) {
        const selectedStateOption = states.find(s => s.id === selectedState && s.country === selectedC);
        if(!selectedStateOption){
          setStateValue('');
        }
      }
    }
  }
</script>

<Select
  id="{ids.country}"
  label="Country"
  options="{countries.map(c => ({id: c.id, label: c.name}))}"
  {values}
  {errors}
  required="{true}"
/>

{#if selectedCountry === 'US' || selectedCountry === 'CA'}
  <div class="Columns">
    <div >
      <Select
        id="{ids.state}"
        label="State"
        options="{filteredStates}"
        {values}
        {errors}
        required="{true}"
        bind:setValue={setStateValue}
      />
    </div>

    {#if selectedCountry === 'US'}
      <div>
        <InputText
          id="{ids.county}"
          label="County (optional)"
          {values}
          {errors}
        />
      </div>
    {/if}
  </div>
{:else}
  <div class="Input">
    <InputText
      id="{ids.region}"
      label="Region/Province (optional)"
      {values}
      {errors}
    />
  </div>
{/if}

<div class="Columns">
  <div>
    <InputText
      id="{ids.city}"
      label="City"
      required="{true}"
      {values}
      {errors}
    />
  </div>

  <div>
    <InputText
      id="{ids.zip}"
      label="Postal code"
      maxLength="20"
      {values}
      {errors}
    />
  </div>
</div>

<InputText
  id="{ids.address1}"
  label="Address (1st line)"
  required="{true}"
  {values}
  {errors}
/>

<InputText
  id="{ids.address2}"
  label="Address (2nd line - optional)"
  {values}
  {errors}
/>

<Map
  {values}
  {errors}
/>
