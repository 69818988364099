<Navigation id="{bidId}" {title} {subtitle} {menu} before="{RfpSpecificationMenuItem}"/>


<script>
  import RfpSpecificationMenuItem from './HotelRfpBidRfpSpecificationMenuItem.svelte';
  import Navigation from 'root/components/organisms/SideNavigation/SideNavigation.svelte';
  import { $state as stateProvider } from 'root/angular-injector-provider.js';
  import { onDestroy } from 'svelte';
  import { createCoverLetter, createQuestionnaireNavigation, createFinalAgreement  } from 'root/components/organisms/SideNavigation/MenuItemFactory.js';

  export let bidId, attachments = {};

  const
    title = 'Bid Details',
    subtitle = 'Manage your Bid';

  let menu;

  $: menu = createMenu(attachments);

  function createMenu(attachments){
    const currentState = stateProvider().current.name;

    return [
      createCoverLetter({
        currentState,
        directLetter: { state: 'hotelRfp.bid.coverLetter.view', attachments: attachments.coverLetter }
      }),
      createQuestionnaireNavigation(currentState, 'hotelRfp.bid.questionnaire.view', reloadMenu, onDestroy),
      createFinalAgreement(currentState, 'hotelRfp.bid.finalAgreement.send', attachments.finalAgreement),
    ];
  }


  function reloadMenu(){
    menu = [...menu];
  }
</script>
