import angular from 'angular'
import rfpHotel from './main/rfp-hotel.module'
import ManageAttachments from './attachments/manage-attachments.cmp';
import ViewAttachments from './attachments/view-attachments.cmp';
import CompetitiveBidView from './competitive-bids/view/competitive-bid-view.cmp';

export default angular.module('rb.rfpHotel', [ rfpHotel.name ])
  .component('rbManageAttachments', ManageAttachments)
  .component('rbViewAttachments', ViewAttachments)
  .component('rbCompetitiveBidView', CompetitiveBidView)
