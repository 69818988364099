emailAddressDeliverable.$inject = ['MainAPI', '$q'];
function emailAddressDeliverable(MainAPI, $q) {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$asyncValidators.deliverable = function(modelValue, viewValue) {
        return MainAPI.emailAddress.isDeliverable(viewValue)
          .then(r => r || $q.reject())
      };
    }
  };
}

export default emailAddressDeliverable;
