/**
 * Created by DejanK on 1/9/2017.
 */
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import HTML from './map-destinations.html';
import './map-destinations.scss';
import {initialize as initializeSupplierStore, reload as reloadSuppliers} from './suppliers.store';

export { HTML, RfpDestinationsMapController as Controller };

RfpDestinationsMapController.$inject = ['NotificationUtils', 'TravelDestinationManagerService', 'RfpDestinationsViewService', 'TravelDestinationManagerFiltersService', '$scope', '$state'];
function RfpDestinationsMapController(NotificationUtils, ManagerService, DestinationsViewService, filtersService, $scope, $state){
  const vm = this;

  vm.toggleTable = toggleTable;

  vm.onFiltersChanged = onFiltersChanged;
  vm.saveFilters = saveFilters;
  vm.setDefaultFilters = setDefaultFilters;
  vm.onPanMapTo = onPanMapTo;
  vm.onDone = onDone;

  vm.tableExpanded = false;

  $onInit();

  function $onInit(){
    vm.rfpId = $state.params.rfpId;
    vm.destinationId = $state.params.destinationId;
    initializeSupplierStore(vm.rfpId, vm.destinationId);

    loadDestination(vm.rfpId, vm.destinationId);
  }

  function loadDestination(rfpId, destinationId) {
    NotificationUtils.onLoad(() => ManagerService.getRfpTravelDestination(rfpId, destinationId), vm, 'destination')
      .then(getSuppliers)
      .catch( noop )
  }

  function getSuppliers() {
    reloadSuppliers(vm.destination.data.filter)
  }

  function toggleTable(event) {
    $scope.$timeout(() => { vm.tableExpanded = event.state })
  }

  function onFiltersChanged({filters, apply}){
    if(apply && !isEqual(get(vm, 'destination.data.filter'), filters)){
      set(vm, 'destination.data.filter', filters);
      getSuppliers();
    }
  }

  function saveFilters($event) {
    NotificationUtils.onSave(() => filtersService.saveFilter(vm.rfpId, vm.destinationId, $event.filters))
      .catch(noop)
  }

  function setDefaultFilters($event){
    NotificationUtils.onSave(() => filtersService.setDefaultFilters(vm.rfpId, $event.filters))
      .catch(noop)
  }

  function onDone(){
    const param = $state.params.from;
    if(param && NotificationUtils.goToEncodedState(param)){
      return;
    }
    $state.go('hotelRfp.rfp.destinations.view', {rfpId: vm.rfpId});
  }

  function onPanMapTo($event){
    vm.mapCenter = $event.coordinates;
  }
}
