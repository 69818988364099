/**
 * Created by DejanK on 7/3/2017.
 */
import Logger from 'root/log/log.js';
import HTML from './rb-negotiations.html';
import './rb-negotiations.scss';
import {default as bidManagerStore, closeNegotiationsPanel} from 'rfp/bid-manager/main/stores/bid-manager'

import NegotiationsFormService from 'vRoot/rfp-hotel/negotiations/NegotiationsFormService.js';

export default {
  template: HTML,
  controller: Ctrl
};


Ctrl.$inject = ['$scope', 'NegotiationsService'];
function Ctrl($scope, NegotiationsService) {
  const vm = this;

  const loadNegotiations = function (){
    return NegotiationsService
      .getNegotiations(vm.bid._id)
      .then(
        negotiations => {
          vm.width = `max-width: ${calculateWidth(negotiations)}px`;
          vm.rawNegotiations = negotiations;
          vm.currency = negotiations.config.currency;
          recalculateNegotiationsAmounts();
          $scope.$applyAsync();
        },

        err => { Logger.fatal(err); } );

    function calculateWidth(neg){
      if(vm.panelMode === 'VIEW') {
        return neg.calculateViewNegotiationElementWidth();
      } else {
        return neg.getNegotiationsTableWidth();
      }
    }
  }

  const bmsUnsubscribe = bidManagerStore.subscribe($bms => {
    if($bms.negotiationPanelOpened) {
      vm.panelMode = 'LOADING';

      setSide($bms.view.side);
      setBid($bms.negotiatedBid)
        .then(() => {
          setCurrencyConversionFromBidManager($bms.convertCurrency);
          displayEditorForStatus($bms.view.side, $bms.negotiatedBid.state.status);
        })
    }
  });

  this.$onDestroy = () => bmsUnsubscribe();

  vm.closePanel = closeNegotiationsPanel;
  vm.onEdit = openNegotiationEditor;

  vm.areNegotiationsFinalized = false;

  vm.conversionBase = vm.conversionBase || 'USD';
  vm.conversionOn = vm.conversionOn || false;
  vm.onConversionChanged = onConversionChanged;

  function openNegotiationEditor(){
    displayEditorForStatus(vm.side, vm.bid.state.status);
  }

  function openEditor(mode){
    vm.editorConfig = {
      negotiations: vm.rawNegotiations,
      side: vm.side,
      mode
    };

    vm.panelMode = 'EDIT';
    NegotiationsFormService.init(vm.negotiations, vm.side, mode, closeEditor);
    vm.negotiations.getNegotiationsTableWidth()
  }

  function closeEditor(){
    vm.panelMode = 'VIEW';
    vm.editorConfig = null;
    vm.negotiations.calculateViewNegotiationElementWidth()
    $scope.$applyAsync();
  }

  function setSide(side){
    if(side && vm.side !== side){
      vm.side = side;
    }
  }

  function setBid(bid){
    if(bid){
      vm.bid = bid;
      vm.areNegotiationsFinalized = areNegotiationsFinalized(vm.bid.state.status);
      return loadNegotiations();
    }
    return Promise.reject();
  }

  function areNegotiationsFinalized(bidStatus){
    return [ 'NEGOTIATION_FINALIZED', 'FINAL_AGREEMENT' ].indexOf(bidStatus) !== -1;
  }

  function setCurrencyConversionFromBidManager(bidManagerCurrencyConversion){
    if(bidManagerCurrencyConversion){
      onConversionChanged(bidManagerCurrencyConversion)
    }
  }

  function onConversionChanged(settings){
    if(settings){
      vm.conversionSettings = settings;
      vm.conversionBase = settings.base;
      vm.conversionOn = true;
      vm.convertedTo = settings.base;
    } else {
      vm.conversionOn = false;
      vm.conversionSettings = undefined;
      vm.convertedTo = undefined;
    }

    recalculateNegotiationsAmounts();
    $scope.$applyAsync();
  }

  function recalculateNegotiationsAmounts(){
    if(!vm.rawNegotiations) return;

    if(!vm.conversionSettings || vm.conversionSettings.base === vm.currency) {
      vm.negotiations = vm.rawNegotiations;
      return;
    }

    const rate = vm.conversionSettings.rates[vm.currency];

    vm.negotiations = vm.rawNegotiations.convertCurrency(rate);
  }

  function displayEditorForStatus(side, status){
    if(['BUYER', 'SUPPLIER'].indexOf(side) === -1) return closeEditor();
    const isBuyer = side === 'BUYER';
    const createNegotiation = () => openEditor('CREATE');
    const editNegotiation = () => openEditor('EDIT');

    switch(status) {
      case 'RESPONDED':
        return createNegotiation();
      case 'NEGOTIATION_SENT':
        return isBuyer ? editNegotiation() : createNegotiation();
      case 'NEGOTIATION_RESPONDED':
      case 'NO_LONGER_INTERESTED':
        return isBuyer ? createNegotiation() : editNegotiation();

      default:
        return closeEditor();
    }
  }
}
