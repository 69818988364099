/**
 * Created by DejanK on 6/29/2017.
 */
import {openNegotiationsPanel, closeNegotiationsPanel, setNegotiatedBid} from '../../stores/bid-manager';

export default NegotiationsPanelMessagingService;

NegotiationsPanelMessagingService.$inject = [];
function NegotiationsPanelMessagingService(){
  const $service = this;

  $service.openNegotiationsPanel = function openPanel(bidId){
    openNegotiationsPanel(bidId);
  };

  $service.changeBid = function changeBid(bidId){
    setNegotiatedBid(bidId);
  };

  $service.closeNegotiationsPanel = function closePanel(){
    closeNegotiationsPanel();
  }
}
