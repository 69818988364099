
import HTML from './ask-to-confirm-rfp-contract.html'

const NAME = 'askToConfirmRfpContract'

export { NAME, HTML, AskToConfirmRfpContract as Controller }

AskToConfirmRfpContract.$inject = ['rbDialog', 'CurrentUser'];
function AskToConfirmRfpContract(dialog, CurrentUser){
    const vm = this

    vm.confirm = false
    vm.error = false
    vm.submit = submit
    vm.cancel = dialog.cancel
    vm.isBuyer = null;

    onInit();

    function onInit() {
      if(vm.asSupplier){
        vm.isBuyer = false
      } else {
        CurrentUser.get().then(user => {
          vm.isBuyer = user.isBuyer('HOTEL_RFP');
        });
      }
    }

    function submit(){
        if(vm.confirm){
            dialog.hide()
        } else {
            vm.error = true
        }
    }
}
