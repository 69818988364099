import HTML from './bid-response.html';
import get from 'lodash/get';
import saveResponseService from './save-response.service'
import {HOTEL_RFP_BID_UPDATE_RESPONSE} from 'root/states';
import {SvelteDialog} from 'root/v-app/rbServices.js';
import ReasonForUpdatingBidResponseDialog from './ReasonForUpdatingBidResponseDialog.svelte';
const
  // states
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR';

export { HTML, BidResponsePreloadController as Controller }

BidResponsePreloadController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state', 'CurrentUser', 'TutorialsService', 'MainAPI'];
function BidResponsePreloadController(utils, rfpHotelBidRepository, $state, currentUser, tutorials, mainAPI) {
  const
    vm = this;

  vm.isLoading = () => vm.state === LOADING;
  vm.isLoaded = () => vm.state === LOADED;

  $onInit();

  function $onInit() {
    vm.state = LOADING;

    utils.onLoad(() => rfpHotelBidRepository.getBidResponseDraft($state.params.bidId))
      .then(httpResponse => {
        const bid = httpResponse.data;
        vm.options = getOptions(bid);
        vm.bid = bid;
        vm.state = LOADED;
      })
      .catch(() => {
        vm.state = ERROR;
      });
  }

  function getOptions(bid) {
    return {
      header: {
        pretitle: get(bid, 'specifications.name', ''),
        title: 'Update Response',
        subtitle: get(bid, 'supplierCompany.name', ''),
      },

      savedResponseSource: 'response',

      stateName: HOTEL_RFP_BID_UPDATE_RESPONSE,

      showCoverLetter: false,

      buttons: {
        cancel: {
          label: 'Cancel',
          action: saveResponseService.toBidManager
        },

        draft: false,

        submit: {
          label: 'Update Response',
          action: response => saveResponseService.saveResponse(response, r => {
            return SvelteDialog.show(ReasonForUpdatingBidResponseDialog)
              .then( reason => {
                return utils.onSave(() => mainAPI.updateBidResponse(bid.id, r.answers, reason));
              });
          })
        },
      }
    };
  }
}
