<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import hotelDirectoryService from '../../hotel-directory-service';
  import loadLocations from 'vRoot/hotel-directory/modules/manageLocations/loactions-loader';

  import LocationsUploadTemplate from 'root/assets/files/locations-upload-template.xlsx';

  export let cancel, hide;

  let files, uploadError, warnings = [], status = 'SELECT_FILE';

  $: onFileSelected(files);

  function onFileSelected (){
    if(!files) return;
    try {
      status = 'SAVING_FILE';

      loadLocations(files)
        .then(result => {
          warnings = result.warnings;
          const locations = result.locations;
          locations.sort((a,b) => a.name.localeCompare(b.name, {sensitivity: 'base'}))
          return hotelDirectoryService.saveLocations(locations)
        })
        .then(() => {
          status = 'SAVED'
        })

        .catch(err => {
          status = 'ERROR'
          uploadError = err;
        });

    } catch (err) {
      status = 'ERROR'
      uploadError = err;
    }
  }

</script>

<ClosableModal close="{files ? false : cancel}" width="800px">
  <div slot="heading">
    <div class="Header">Upload Locations</div>
  </div>

  {#if status === 'SELECT_FILE'}
    <div class="ContentHtml">
      <div>Upload Locations that can be used by travelers to easily find hotels near them. Uploaded locations will REPLACE all currently loaded locations!</div>
      <div>
        Upload Excel or CSV file with these 4 columns:<br />
        <b>NAME</b> (required): Location name that travelers will see and can search by. Will be truncated to 100 characters if longer.<br />
        <b>ADDRESS</b> (optional): Location address that will be visible on Manage Locations list. Will be truncated to 200 characters if longer.<br />
        <b>LATITUDE</b> (required): Location latitude. It will be set to 0 if not provided.<br />
        <b>LONGITUDE</b> (required): Location longitude. It will be set to 0 if not provided.<br />
      </div>
    </div>

    <div class="Actions">
      <div>
        <a class="aBtn atLight asLarge"
           target="_self"
           href="{LocationsUploadTemplate}"
           download="Locations Upload Template.xlsx">
          <i class="fa fa-file-excel-o" aria-hidden="true"></i> Template
        </a>
      </div>

      <div class="layout-row">
        <div>
          <label for="fileUploader" class="aBtn atLight asLarge asMain">
            <i class="material-icons">file_upload</i><span>Upload File</span>
          </label>

          <input type="file"
                 id="fileUploader"
                 accept=".csv, .txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                 bind:files
                 class="hidden"
          />
        </div>
        <button type="button" class="aBtn atLight asLarge" on:click={cancel}>Cancel</button>
      </div>
    </div>

  {:else}
    <div class="Content">
      <div class="ProgressStatus">
        {#if status === 'SAVING_FILE'}
          Upload in progress...
        {:else if status === 'SAVED'}
          Upload Successful!
        {:else if status === 'ERROR'}
          Upload Failed!
          <div class="error">{ uploadError }</div>
        {/if}
      </div>

      <div class="WarningContainer">
        {#each warnings as warning}
          <div class="Warning">
            <div>{warning}</div>
          </div>
        {/each}
      </div>
    </div>

    <div class="Actions">
      <div></div>
      <div>
        <button type="button" class="aBtn atLight asLarge asMain" on:click={hide} disabled="{status === 'SAVING_FILE'}">Close</button>
      </div>
    </div>
  {/if}
</ClosableModal>

<style lang="stylus">.Header {
  padding: 9px 13px 0;
  color: #37474f;
  font-size: 16px;
}
.Content,
.ContentHtml {
  padding: 0 13px;
  color: #546e7a;
  font-size: 13px;
  margin: 10px 0 20px;
  max-height: 400px;
  overflow: auto;
}
.ContentHtml :global(div) {
  margin: 20px 0;
}
.ProgressStatus {
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
  position: sticky;
  background: #fff;
  z-index: 1;
  top: 0;
}
.ProgressStatus .error {
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  padding: 20px 0;
}
.WarningContainer {
  overflow: auto;
}
.Warning {
  margin-bottom: 5px;
}
.Actions {
  padding: 0 18px 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
a.aBtn {
  display: block;
  line-height: 34px;
  text-decoration: none;
}
</style>
