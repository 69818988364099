<template>
  <modal-form
    :class="addressFormVisible > 0? $style.long: $style.dialog"
    @cancel="$formCancelHandler"
    @submit="submitHandler"
  >
    <span slot="header">
      <div v-if="company && company.id">
        Edit Company
      </div>
      <div v-else>
        Create New Company
      </div>
    </span>
    <div
      slot="body"
      class="vModalDefaultBody"
      :class="$style.body"
    >
      <rb-input
        :for-id="'accountId'"
        :error="$showError('name')"
      >
        <span slot="label">Company Name*</span>
        <input
          id="name"
          v-model="data.name"
          v-validate="'required'"
          class="rbInput"
          name="name"
          data-vv-as="Company Name"
          placeholder="Company Name"
          autofocus
        />
      </rb-input>
      <div :class="$style.group">
        <rb-input
          :for-id="'accountId'"
          :error="$showError('industry')"
        >
          <span slot="label">Company Industry*</span>
          <rb-select-new
            id="industry"
            v-model="data.industry"
            :options="industries"
            class="simple"
            name="industry"
            data-vv-as="Company Industry"
            placeholder="Select..."
          />
        </rb-input>
        <rb-input
          :for-id="'accountId'"
          :error="$showError('website')"
        >
          <span slot="label">Company Website URL*</span>
          <rbv-input-url id="website"
                         v-model="data.website"
                         v-validate="{url: true, required: true}"
                         placeholder="http://yoursiteishere.com"
                         class="rbInput"
                         name="website"
                         data-vv-as="Company Website URL"/>
        </rb-input>
      </div>
      <template v-if="!company || !company.id">
        <div :class="$style.group">
          <rb-input
            :for-id="'primaryContactFirstName'"
            :error="$showError('primaryContactFirstName')"
          >
            <span slot="label">Company Contact First Name</span>
            <input
              id="primaryContactFirstName"
              v-model="primaryContact.firstName"
              class="rbInput"
              name="primaryContactFirstName"
              placeholder="Company Contact First Name"
            />
          </rb-input>
          <rb-input
            :for-id="'primaryContactLastName'"
            :error="$showError('primaryContactLastName')"
          >
            <span slot="label">Company Contact Last Name</span>
            <input
              id="primaryContactLastName"
              v-model="primaryContact.lastName"
              class="rbInput"
              name="primaryContactLastName"
              placeholder="Company Contact Last Name"
            />
          </rb-input>
        </div>
        <div :class="$style.group">
          <rb-input
            :for-id="'primaryContactEmail'"
            :error="$showError('primaryContactEmail')"
          >
            <span slot="label">Company Contact Email Address</span>
            <input
              id="primaryContactEmail"
              v-model="primaryContact.emailAddress"
              class="rbInput"
              name="primaryContactEmail"
              placeholder="Company Contact Email Address"
            />
          </rb-input>
          <rb-input
            :for-id="'primaryContactPhone'"
            :error="$showError('primaryContactPhone')"
          >
            <span slot="label">Company Contact Phone Number</span>
            <input
              id="primaryContactPhone"
              v-model="primaryContact.phone"
              class="rbInput"
              name="primaryContactPhone"
              placeholder="Company Contact Phone Number"
            />
          </rb-input>
        </div>

      </template>

      <rbv-input-address-with-coordinates
        v-model="data.location"
        v-validate="{required: true}"
        name="location"
        :form-opened="addressFormVisible"
        :class="$style.addressForm"
        @formOpened="onFormOpened"
      />
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button
        class="rbButton mainButton"
      >
        <i class="material-icons">save</i>
        <template v-if="company.id">
          Save
        </template>
        <template v-else>
          Create New Company
        </template>
      </button>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from '../../../../_core/RbModalForm.vue'
  import DialogMixin from '../../../../_mixins/DialogMixin.vue'
  import RbFormMixin from '../../../../_mixins/RbFormMixin.vue'
  import RbInput from '../../../../_core/RbInput.vue'
  import RbSelectNew from '../../../../_core/rbv-input/RbSelectNew.vue'
  import RbvInputAddressWithCoordinates from '../../basic-information/RbvInputAddressWithCoordinates.vue'
  import {noop} from 'lodash';
  import RbvInputUrl from 'vRoot/_core/rbv-input/RbvInputUrl.vue';

  export default {
    name: 'CreateNewCompanyDialog',
    components: { ModalForm, RbInput, RbSelectNew, RbvInputAddressWithCoordinates, RbvInputUrl },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      industries: {
        type: Array,
        required: true
      },
      company: {
        type: Object,
        default: () => ({}),
      }
    },
    provide () {
      return {
        validator: this.$validator
      }
    },
    data() {
      return {
        data: {
          name: this.company.name,
          type: this.company.type || 'COMPANY',
          industry: this.company.industry,
          website: this.company.website,
          location: this.company.location || {},
        },
        primaryContact: {
          firstName: this.company.primaryContact && this.company.primaryContact.firstName,
          lastName: this.company.primaryContact && this.company.primaryContact.lastName,
          emailAddress: this.company.primaryContact && this.company.primaryContact.emailAddress,
          phone: this.company.primaryContact && this.company.primaryContact.phone,
        },
        addressFormVisible: 0
      }
    },
    methods: {
      onFormOpened() {
        this.addressFormVisible++;
      },
      onSubmit() {
        return { entity: this.data, primaryContact: this.primaryContact };
      },
      submitHandler () {
        this.addressFormVisible++;
        this.$nextTick()
          .then(() => this.$validator.validateAll())
          .then(result => {
            if (result) {
              return Promise.resolve()
            } else {
              this.$touchAllFields();
              return Promise.reject()
            }
          })
          .then( this.$formSubmitHandler )
          .catch(noop)
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    min-width: 620px;
    overflow visible;
  }

  .long{
    composes dialog;
    overflow auto;
  }

  .body{
    height auto
  }

  .companies{
    color: #7f909c;
    border: 1px solid;
    max-height 270px;
    overflow auto;
  }

  .group{
    display flex;
  }

  .group :global .rbInputContainer{
    flex: 1;
  }

  .group > div:last-child{
    margin-left 10px;
  }

  .addressForm :global .rbv-map-fields{
    flex-direction column-reverse;
  }

  .addressForm :global .rbv-map-fields .rbv-map{
    min-height 250px;
  }

  .addressForm :global .rbvDropDown{
    max-width 100%;
  }

  .searchInput:focus{
    border-color: #09c1b2;
  }
</style>
