import HTML from './competitive-bid-view.html';

export { HTML, PublicBidPreviewController as Controller };

PublicBidPreviewController.$inject = ['MainAPI', '$state', 'CurrentUser'];
function PublicBidPreviewController(mainAPI, $state, currentUser){
  const vm = this;
  let recipientEmailAddress;
  currentUser.get().then(cu => { recipientEmailAddress = cu.emailAddress; });

  vm.loadBidFunction = () => mainAPI.hotelRfpCompetitiveBidPreview($state.params.bidId)
  vm.recipientEmailAddressFn = () => recipientEmailAddress
}
