<script>
import Hotel from './hotel.vue';
import RbRating from 'vRoot/hotel-directory/_core/rating.vue';
import BulkCheckBox from 'vRoot/hotel-directory/modules/preview/BulkCheckbox.vue';

export default {
  name: 'HotelListRow',
  components: {BulkCheckBox, Hotel, RbRating, },
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
    previewMode: {
      type: Boolean
    },
    updatingList: {
      type: Array,
      required: true
    },
    groupBy: {
      type: String,
      required: true
    }
  },
  mounted() {
    if(this.item.type === 'group') return;

    const vm = this;
    this.resizeObserver = new ResizeObserver(obs => {
      vm.$emit('resized', {index: this.index, height: obs[0].contentRect.height});
    });

    this.resizeObserver.observe(this.$el);
  },

  beforeDestroy() {
    if(this.resizeObserver){
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  },
}
</script>

<template>
  <div
    v-if="item.type === 'group'"
    :key="item.key"
    :class="{[$style.grouper]: previewMode, [$style.editModeGrouper]: !previewMode}"
  >
    <bulk-check-box
      v-if="!previewMode"
      :ids="item.ids"
      :view="view"
      :updating-list="updatingList"
      @toggle="$emit('toggle-bulk', $event)"
    />


    <rb-rating
      v-if="groupBy === 'rating'"
      :rate="(item.key || '').toUpperCase()"
    />
    <span v-else>{{ item.key || "__" }}</span>
  </div>

  <hotel
    v-else
    :key="item.id"
    :hotel="item"
    :view="view"
    :previewing="previewMode"
    :updating="updatingList.includes(item.bidId)"
    @toggle="$emit('toggle', item)"
  />
</template>

<style module lang="stylus">
.grouper {
  padding: 0 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  box-sizing: border-box;
  height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size 14px;
}

.editModeGrouper {
  composes grouper;
  padding-left 1px;
}

.grouper > div {
  height: 20px;
  overflow: hidden;
}
</style>
