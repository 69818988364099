<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import YesNoButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
  import noop from 'lodash/noop';

  export let hide, cancel, bids, resend = false;

  let actionTitle;

  $: actionTitle = resend ? 'Resend' : 'Send';

  function send(){
    notificationUtils().onSave(() => mainAPI().rateLoading.sendRequests(bids.map(b => b._id)))
      .then(hide)
      .catch(noop)
  }
</script>

<ClosableModal close="{cancel}" width="{'500px'}">
  <div slot="heading">
    <ModalTitle title="{actionTitle + ' Rate Loading Request?'}">
      {actionTitle} Rate Loading Request to {bids.length === 1 ? 'the selected hotel' : `${bids.length} selected hotels`}?
    </ModalTitle>
  </div>

  <YesNoButtons
    noAction="{cancel}"
    noLabel="Cancel"
    yesAction="{send}"
    yesIcon="send"
    yesLabel="{actionTitle}"
  />
</ClosableModal>

