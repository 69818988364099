/**
 * Created by DejanK on 4/5/2017.
 */
import * as DeleteBids from './delete-bids/delete-bids.ctrl'
import * as ForwardToNationalSales from './forward-to-national-sales/forward-to-national-sales.ctrl'
import * as BidManagerHotelProfile from './bid-manager-hotel-profile/bid-manager-hotel-profile.ctrl'
import * as DueDateExpiredNotification from './due-date-expired/due-date-expired.ctrl'
import * as UploadHotelRfpResponses from './upload-responses/upload-responses.ctrl'
import * as CreateNewSupplierContact from './create-new-supplier-contact/create-new-supplier-contact.ctrl';

export default Dialogs

Dialogs.$inject = ['rbDialogProvider'];
function Dialogs(rbDialogProvider) {
  rbDialogProvider
    .addDialog(DeleteBids)
    .addDialog(ForwardToNationalSales)
    .addDialog(BidManagerHotelProfile)
    .addDialog(DueDateExpiredNotification)
    .addDialog(UploadHotelRfpResponses)
    .addDialog(CreateNewSupplierContact)
}

