export default angularLockerConfig

angularLockerConfig.$inject = ['lockerProvider'];
function angularLockerConfig(lockerProvider){
  lockerProvider.defaults({
    driver: 'local',
    namespace: 'rb',
    separator: '.'
  });
}
