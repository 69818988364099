/**
 * Created by DejanK on 7/3/2017.
 */
import HTML from './rb-negotiations-panel-view-actions.html';
import './rb-negotiations-panel-view-actions.scss'

export default {
  template: HTML,
  bindings: {
    onEdit: '&',
    side: '<',
    bid: '<',
    currency: '<',
    convertedTo: '<'
  },
  controller: Ctrl
};

function Ctrl() {
  const vm = this;

  vm.$onChanges = ( changes ) => {
    if(changes.bid && changes.bid.currentValue){
      vm.bid = changes.bid.currentValue;
      vm.editButtonLabel = getEditButtonLabel(vm.side, vm.bid.state.status);
    }
  };

  function getEditButtonLabel(side, status){
    if(['BUYER', 'SUPPLIER'].indexOf(side) === -1) return null;
    const isBuyer = side === 'BUYER';

    switch(status) {
      case 'RESPONDED':
        return 'Start Negotiations';
      case 'NEGOTIATION_SENT':
        return isBuyer ? 'Edit Request' : 'Negotiate';
      case 'NEGOTIATION_RESPONDED':
      case 'NO_LONGER_INTERESTED':
        return isBuyer ? 'Negotiate' : 'Edit Response';

      default:
        return null;
    }
  }
}
