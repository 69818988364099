import angular from 'angular'
import rbDropdown from './rb-dropdown/rb-dropdown.cmp'
import rbProgressBar from './rb-progress-bar/rb-progress-bar.cmp'

export default angular.module('rb.components', [])
  .component('rbLoading', require('./rb-loading/rb-loading.cmp'))
  .component('rbError', require('./rb-error/rb-error.cmp'))
  .component('rbDropdown', rbDropdown)
  .component('rbIcon', require('./rb-icon/rb-icon.cmp'))
  .component('rbProgressBar', rbProgressBar)





