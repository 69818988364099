/**
 * Stores user config on local storage
 */

import get from 'lodash/get';
import {writable, get as getStore} from 'svelte/store';
import {currentUser} from 'root/angular-injector-provider';
import {default as rawViewStore, initialize as initializeViewRawStore} from 'rfp/bid-manager/main/stores/view-raw';
import bmTableColumns from 'root/assets/data/view-headings';


const store = writable({}, () => {
  const rwsUnsubscribe = rawViewStore.subscribe($rvs => {
    if (!$rvs.initialized) return;

    store.update($s => ({
      ...$s,
      $viewRaw: $rvs,
      columns: prepareColumns($rvs.columns, $s.settings),
      initialized: true
    }));
  });

  return () => {
    rwsUnsubscribe();
    store.set({});
  }
});

export default store;

export function initialize(config){
  return currentUser().get()
    .then(cu => {
      const key = `${cu.id}:BID_MANAGER_LOCAL_CONFIG`,
        settings = loadSettings(key);
      store.update($s => ({...$s, key, settings}))
    })
    .then(() => initializeViewRawStore(config))
}

export function replaceColumns(switchId, targetId){
  updateSettings({[switchId]: targetId});
}

export function toggleAverageRatesShown(){
  const $s = getStore(store);
  updateSettings({averagesShown: !$s.settings.averagesShown});
}

function loadSettings(key) {
  let settings;
  try {
    settings = JSON.parse(localStorage.getItem(key));
  } catch (e) {
    //ignore
  }

  return settings || {columns: {}};
}

function updateSettings(settings){
  store.update($s => {
    const newSettings = { ...$s.settings, ...settings };
    localStorage.setItem($s.key, JSON.stringify(newSettings));
    return {
      ...$s,
      settings: newSettings,
      columns: prepareColumns($s.$viewRaw.columns, newSettings)
    };
  });
}


function prepareColumns(columnsIds, settings){
  const initialized = [];
  columnsIds.forEach(id => {
    const cellId = applyWeightedAverage(id, settings);
    const c = applySwitch(getCell(cellId), settings);
    if(c) initialized.push({...c}); // must create a copy in case there are duplicates (for ng-repeat not to throw)
  });
  return initialized;

}

function applySwitch(cell, settings){
  if(cell.switch){
    const cc = settings[cell.switch.id];
    if(cc && cc !== cell.id) return getCell(cc);
  }

  return cell;
}

const
  LRA = 'hotelRfpBid.lra',
  NLRA = 'hotelRfpBid.nlra',
  AVG_LRA = 'hotelRfpBid.lraAvg',
  AVG_NLRA = 'hotelRfpBid.nlraAvg';

function applyWeightedAverage(cellId, settings){
  if(settings.averagesShown){
    if(cellId === LRA) return AVG_LRA;
    if(cellId === NLRA) return AVG_NLRA;
  }

  return cellId;
}

function getCell(cellId){
  const cell = get(bmTableColumns, cellId);
  if(!cell) console.error(`Cell not found: ${cellId}`);
  return cell;
}
