<template>
  <div>

    <questionnaire-edit-section-group
      :model="roomTypesModel"
      :preview="preview"
      :template="groupTemplate"
    />

    <div>
      <div class="questionnaireEditTableHeader">
        <span class="questionnaireEditTableHeaderText">{{ tableTemplate.name }} Table</span>
      </div>

      <div class="questionnaireEditTableContainer" :class="tableTemplate.classes">

        <div v-if="!preview">
          <button class="rb-buttons small main" @click="configureRateGrid()">
            <i class="material-icons">grid_on</i> Configure
          </button>
        </div>

        <div class="layout-row">
          <div class="questionnaireEditTableWrapper">
            <table class="questionnaireEditTable">
              <questionnaire-table-row
                v-for="cell in cellsToView" :key="cell.id"
                :template="cell"
                :model="tableConfig"
              />
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { cloneDeep, noop } from 'lodash'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  import { Dialog } from "root/v-app/rbServices";

  import QuestionnaireEditSectionGroup from './QuestionnaireEditSectionGroup.vue'
  import QuestionnaireTableRow from '../view/QuestionnaireViewSectionTableRow.vue'
  import QuestionnaireConfigureRateGridDialog from './HotelRfpQuestionnaireEditSectionRateGridConfigureDialog.vue'

  export default {
    name: 'rb-questionnaire-edit-section',

    mixins: [ RbEventsMixin, QuestionnaireMixin ],

    components: {
      QuestionnaireEditSectionGroup,
      QuestionnaireTableRow,
      QuestionnaireConfigureRateGridDialog
    },

    props: [ 'config', 'model', 'preview', 'template' ],

    computed: {
      groupTemplate () { return this.getTemplate('RTD') },

      rateGridConfig () { return this.config.find( c => c.id === 'RT') },

      roomTypesModel () {
        const model = { cells: []}

        this.rateGridConfig.data.roomType.forEach( ( val ) => {
          model.cells.push( {ord: 67 + (val-1) * 2, id: `ROOMTYPE${val}DEFINE`} )
          model.cells.push( {ord: 68 + (val-1) * 2, id: `ROOMTYPE${val}NUMBER`} )
        })
        return model
      },

      tableTemplate () { return this.getTemplate('RT') },

      cellsToView() { return this.tableTemplate.cells.slice(0, 3)},

      tableConfig () {
        const config = cloneDeep(this.rateGridConfig)
        if(config) {
          config.filters = config.data || this.tableTemplate.defaultFilters
        }
        return config
      },
    },


    methods: {
      onSectionMounted () { this.$emit('mounted') },

      configureRateGrid(){
        Dialog.show(QuestionnaireConfigureRateGridDialog, { template: this.template, config: this.rateGridConfig })
          .then( reason => this.$rbEmit({
            name: 'specialsChanged',
            type: 'hotelRFpRateGridConfigure',
            sectionId: this.template.id,
            config: reason
          }))
          .catch( noop )
      },

    }
  }
</script>
