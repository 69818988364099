<script>
  import Spinner from 'root/components/atoms/Spinner.svelte';

  export let isLoading = false;
</script>

{#if isLoading}
  <div class="LoadingMore">
    <Spinner />
  </div>
{/if}

<style lang="stylus">.LoadingMore {
  display: flex;
  justify-content: center;
}
</style>