export default BidManagerRepo
BidManagerRepo.$inject = ['MainServer', '$q'];
function BidManagerRepo(mainServer, $q){
    const $repo = this;

    $repo.getUserViews = () => mainServer.get(`bid-managers`);

    $repo.getRfpTravelDestinations = rfpId => rfpId
        ? mainServer.get(`bid-managers/travel-destinations/rfps/${rfpId}`)
        : $q.reject({status: 404});

    $repo.getTravelDestinations = () => mainServer.get(`bid-managers/travel-destinations`);

    $repo.getView = viewId => viewId
        ? mainServer.get(`bid-managers/${viewId}`)
        : $q.reject({status: 404});

    $repo.getViewByRfpId = rfpId => rfpId
        ? mainServer.get(`bid-managers/rfps/${rfpId}`)
        : $q.reject({status: 404});

    $repo.queryBids = query => {
        const t = new Date().getTime();

        return mainServer.post('rfps/bids/query', query)
            .then( response => {
                console.log('bid-manager.repo.js: ', response.length, ' BIDS QUERY TIME: ', new Date().getTime() - t );
                return { data: response }
            })
    };

    $repo.getBidContactLastActive = bidId => bidId
        ? mainServer.get(`rfps/hotel/representatives/bid/${bidId}/last-active`)
        : $q.reject({status: 400});

    $repo.getAccountIdFromBidManagerViewId = viewId => viewId
        ? mainServer.get(`bid-managers/${viewId}/get-account`)
        : $q.reject({status: 400});
}
