import areAllCompetitiveBidOffers from 'rfp/bid-manager/main/core/actions/action/CompetitiveBidIsOffer.service';
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
import noop from 'lodash/noop';

export default function CreateRefuseCompetitiveBid(bidManagerService){
  return  function RefuseCompetitiveBid(report){
    return {
      label: 'Decline',
      icon: 'close',
      isAvailable: () => areAllCompetitiveBidOffers(report.bids),
      action: () => {
        notificationUtils().onSave(() => mainAPI().hotelRfpCompetitiveBidOfferRefused(report.bids.map(b => b._id)))
          .then(actionReport => bidManagerService.handleActionResult( actionReport, report.bids))
          .catch(noop);
      }
    }
  }
}