/**
 * Created by DejanK on 4/27/2017.
 *
 * Bid Manager Actions List
 */

import template from './rb-bid-manager-actions-list.html';

export default {
  template,
  controller: ActionsListController,
  bindings: {
    actions: '<',
    onAction: '&'
  }
}

function ActionsListController(){
  let vm = this;

  vm.executeAction = (action) => {
    vm.onAction({action});
  }
}
