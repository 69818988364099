<template>
  <div class="rbvQuestionnaireQuestionListTypeOptions">
    <checkbox-button v-for="option in template.options" :key="option.value"
                     :checkBoxLabel="createOptionLabel(option.label, option.value, isUserDefined)"
                     :checkBoxValue="option.value"
                     :value="value"
                     :readonly="readonly"
                     :isUserDefined="isUserDefined"
                     :style="hasChanged ? 'background: rgba(0, 169, 157, 0.1); border: 1px solid rgba(0, 169, 157, 1);' : ''"
                     @input="onChange"
    />
  </div>
</template>

<script>
  import store from 'root/rfp-hotel/main/rfp/rbs-rfp-details-navigation/revert-revise-response.store';
  import checkboxButton from 'root/v-components/_core/RbCheckboxButton.vue'

  export default {
    name: 'rb-questionnaire-form-question-list',

    components: { checkboxButton },

    props: {
      id: {default: undefined},
      readonly: {
        "default": false,
        "type": Boolean
      },
      template: { required: true },
      value: { "default": undefined },
      isUserDefined: {
        "default": false,
        "type": Boolean
      }
    },

    data: () => ({hasChanged: false}),

    mounted () {
      this.unsubscribe = store.subscribe(this.changesListener)
    },

    destroyed () {
      if(this.unsubscribe) this.unsubscribe();
    },

    methods: {
      createOptionLabel(label, value, isUserDefined) {
        return isUserDefined ? `${label} (${value})` : label;
      },
      onChange ( value ){ this.$emit('input', value) },
      changesListener ($store) {
        if($store) {
          this.hasChanged = $store.changes.includes(this.id);
        }
      }
    }
  }
</script>

<style lang="stylus">

  .rbvQuestionnaireQuestionListTypeOptions {
    display: block;
    white-space: nowrap;
  }

</style>
