<script>
  import {$state as stateProvider} from 'root/angular-injector-provider';
  import {PAGE_CURRENT_USER_PROFILE} from 'root/states';

  export let user;

  function openUserProfile(){
    stateProvider().inNew(PAGE_CURRENT_USER_PROFILE);
  }
</script>

<div class="Component">
  <div class="UserSection">
    <div class="Name">Hi {user.firstName || ''} {user.lastName || ''}</div>
    <div class="JobTitle">{user.currentUserAccount.jobTitle || ''} @ {user.currentUserAccount.account.name}</div>
  </div>

  <div class="DetailsSection">
    <div class="Details">
      <div class="Detail" data-icon-before="mail">{user.emailAddress}</div>
      <div class="Detail" data-icon-before="phone">{user.phone || 'NOT PROVIDED'}</div>
    </div>

    <div class="DetailsActions">
      <button class="aBtn asSmall asIcon"
              data-icon="edit"
              on:click={openUserProfile}
      />
    </div>
  </div>
</div>

<style lang="stylus">.Component {
  padding: 15px;
  color: #546e7a;
  font-size: 13px;
  line-height: 1.4;
  display: flex;
  align-items: center;
}
.UserSection {
  flex: 1;
}
.DetailsSection {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background: #00a99d;
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  position: relative;
  box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.2);
}
.Details {
  flex: 0 0 auto;
}
.DetailsActions {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 20px;
}
.DetailsActions .aBtn {
  color: #00a99d;
}
.DetailsActions .aBtn:hover {
  transform: scale(1.1, 1.1);
}
.Detail {
  display: flex;
}
.Detail:before {
  font-size: 16px;
  margin-right: 5px;
}
.Name {
  font-size: 18px;
  font-weight: bold;
  color: #546e7a;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}
.JobTitle {
  font-size: 13px;
}
</style>