<script>
  export let
    icon = null,
    label = 'Close',
    action = () => {},
    asMain = false,
    asSmall = false,
    disabled = false;
</script>


<div class="Buttons">
  <button class="aBtn asLarge atLight"
          class:asLarge={!asSmall}
          class:asSmall={asSmall}
          class:asMain="{asMain}"
          data-icon="{icon}"
          disabled="{disabled ? 'disabled' : null}"
          on:click={() => action()}>
    {label}
  </button>
</div>

<style lang="stylus">.Buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 20px;
}
</style>
