/**
 * Created by DejanK on 4/27/2017.
 *
 * Bid Manager Actions
 */

import './rb-bid-manager-actions.scss';
import HTML from './rb-bid-manager-actions.html';
import {default as actionsStore, unselectAllBids} from 'rfp/bid-manager/main/stores/actions';

export default {
  template: HTML,
  controller: RbBidManagerActions
};

RbBidManagerActions.$inject = ['$scope'];
function RbBidManagerActions($scope) {
  const vm = this;
  const asUnsubscribe = actionsStore.subscribe($as => {
    vm.actions = $as.actions;
    vm.actionsCount = $as.actionsCount;
    vm.noSelectedBids = $as.noneSelected;
    $scope.$applyAsync();
  });
  vm.executeAction = executeAction;

  vm.$onDestroy = () => {
    asUnsubscribe();
  };

  function executeAction(action){
    if(action) {
      action();
      unselectAllBids();
    }
  }
}
