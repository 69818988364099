<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'RbvHotelRfpBidManagerSendReminderActionReport',
    mixins: [ ActionReportMixin ],
    data () {
      return {
        messages: {
          'OK': 'Reminder Sent',
          'NOT_FOUND': `Bid was not found - Reminder not sent`,
          'STATUS_CHANGED': 'Bid status was changed - Reminder not sent',
          // 'ERROR': 'Reminder not sent due to unknown error'
        }
      }
    },
    computed: {
      title () { return this.items.length === 1 ? 'Reminder not sent!' : 'Failed to send some reminders!' },
      message () {
        return this.items.length === 1
          ? 'We were not able to send reminder because of the reason listed below:'
          : `${this.items.length - this.failures} of ${this.items.length} reminders were successfully sent.<br /> We were not able to send other because of the reasons listed below:`
      }
    }
  }
</script>
